import * as React from 'react';
import styles from 'components/report/pivotTable/styles.module.scss';
import { renderFieldDisplayName, renderValueCell, onClickCell, getDurationValue } from 'helpers/report/report';
import { IS_UPDATE_CHART } from 'globalState/report';

/**
 * Описание: отчёт Pivot
 * Параметры:
 * data {
 *     rows, columns, - массивы данных о строках/колонках, содержат название, суммарное значение и url
 *     tableData, - двумерный массив со значениями яйчеек таблицы
 *     total, - суммарное значение
 *     totalUrl - url для яйчейки с суммарным значением
 * }
 * name - название отчёта
 * columnFieldName, rowFieldName - название полей, на основании который формируются столбцы и строки таблицы
 * aggregationType - способ агрегирования
 * displayAggregationType - имя для отображения способа агрегирования
 * */
export default class PivotTable extends React.Component {
    tableRef = React.createRef();

    constructor(props) {
        super(props);
        this.reportState = props.reportState;
    }

    componentDidUpdate() {
        if (this.props.isUpdateChart) {
            this.reportState.stateFlags = this.reportState.stateFlags & ~IS_UPDATE_CHART;
        }
    }

    getPrecision() {
        return this.reportState && this.reportState.styleOptions &&
            this.reportState.styleOptions.decimalPrecision;
    }

    renderHeader(colFieldName, rowFieldName, columns, rowsAmount) {
        return <>
            <tr>
                <th />
                <th colSpan={ columns.length + 2 } className={ styles.colsFieldName }>
                    { colFieldName }
                </th>
            </tr>
            <tr>
                <th rowSpan={ rowsAmount + 2 } className={ styles.rowsFieldName }>
                <span>
                    { rowFieldName }
                </span>
                </th>
                <th className={ styles.colHeader + ' ' + styles.rowHeader } />
                {
                    columns.map(column =>
                        <th key={ column.name } className={ styles.colHeader }>
                            { renderFieldDisplayName(column.name) }
                        </th>)
                }
                <th className={ styles.colTotal + ' ' + styles.colHeader }>
                    { this.props.displayAggregationType }
                </th>
            </tr>
        </>;
    }

    renderDataRow(row, columns, tableData) {
        row.displayValue = this.reportState.isDurationAverage ? getDurationValue(row.value, true) : row.value;
        return <tr key={ row.name }>
            <th className={ styles.rowHeader }>
                { renderFieldDisplayName(row.name) }
            </th>
            {
                columns.map(column => {
                    tableData[column.dbName][row.dbName].displayValue = this.reportState.isDurationAverage ? getDurationValue(tableData[column.dbName][row.dbName].value, true) : tableData[column.dbName][row.dbName].value;
                    return (
                        <td key={ row.name + '_' + column.name } onClick={ onClickCell(tableData[column.dbName][row.dbName]) }>
                            {
                                renderValueCell(tableData[column.dbName][row.dbName],
                                    this.getPrecision())
                            }
                        </td>
                    );
                })
            }
            <td className={ styles.colTotal } key={ row.name + '_total' } onClick={ onClickCell(row) }>
                {
                    renderValueCell(row, this.getPrecision())
                }
            </td>
        </tr>;
    }

    renderTotalsRow(columns, total) {
        total.displayValue = this.reportState.isDurationAverage ? getDurationValue(total.value, true) : total.value;
        return (
            <tr key="total">
                <th className={ styles.rowTotal + ' ' + styles.rowHeader }>
                    { this.props.displayAggregationType }
                </th>
                {
                    columns.map(column => {
                        column.displayValue = this.reportState.isDurationAverage ? getDurationValue(column.value, true) : column.value;
                        return (
                            <td className={ styles.rowTotal } key={ 'total_' + column.name } onClick={ onClickCell(column) }>
                                {
                                    renderValueCell(column, this.getPrecision())
                                }
                            </td>
                        );
                    })
                }
                <td className={ styles.colTotal + ' ' + styles.rowTotal } key="total" onClick={ onClickCell(total) }>
                    {
                        renderValueCell(total, this.getPrecision())
                    }
                </td>
            </tr>
        );
    }

    render() {
        const {
            columnFieldName,
            rowFieldName,
            data,
        } = this.props;

        const {
            columns,
            rows,
            total,
            tableData,
        } = data;

        return <div className={ styles.pivot }>
            <table ref={ this.tableRef }>
                <tbody>
                {
                    this.renderHeader(columnFieldName, rowFieldName, columns, rows.length)
                }
                {
                    rows.map(row =>
                        this.renderDataRow(row, columns, tableData))
                }
                {
                    this.renderTotalsRow(columns, total)
                }
                </tbody>
            </table>
        </div>;
    }
}
