import apiRequest from 'lib/apiRequest';
import { handleResponse } from 'helpers/action';

export const fetchBoard = async (params) => {
    const response = await new apiRequest('GET /task-board').qs(params).send();
    return handleResponse(response);
};

export const fetchBoardCards = async (params) => {
    const response = await new apiRequest('GET /task-board/cards').qs(params).send();
    return handleResponse(response);
};

/**
 * @param params - card_id, order
 * @returns {Promise<{data: *, response: *, isOkStatus: boolean}|{data: {}, response: *, isOkStatus: boolean}>}
 */
export const updateCardOrder = async (params) => {
    const response = await new apiRequest('PUT /task-board/card-order').sendJSON(params);
    return handleResponse(response);
};

/**
 * @param params - lane_id, order
 * @returns {Promise<{data: *, response: *, isOkStatus: boolean}|{data: {}, response: *, isOkStatus: boolean}>}
 */
export const updateLaneOrder = async (params) => {
    const response = await new apiRequest('PUT /task-board/lane-order').sendJSON(params);
    return handleResponse(response);
};

/**
 * @param params - lane_id, card_id, order
 * @returns {Promise<{data: *, response: *, isOkStatus: boolean}|{data: {}, response: *, isOkStatus: boolean}>}
 */
export const updateCardLaneOrder = async (params) => {
    const response = await new apiRequest('PUT /task-board/card-move').sendJSON(params);
    return handleResponse(response);
};

/**
 * @param params - record_id, board_id
 * @returns {Promise<{data: *, response: *, isOkStatus: boolean}|{data: {}, response: *, isOkStatus: boolean}>}
 */
export const createCard = async (params) => {
    const response = await new apiRequest('PUT /task-board/create-card').sendJSON(params);
    return handleResponse(response);
};

/**
 * @param params - record_id, board_id
 * @returns {Promise<{data: *, response: *, isOkStatus: boolean}|{data: {}, response: *, isOkStatus: boolean}>}
 */
export const getCard = async (params) => {
    const response = await new apiRequest('GET /task-board/get-card').qs(params).send();
    return handleResponse(response);
};
