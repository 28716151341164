export class EventEmitter {
    private readonly events: Record<string, Set<() => any>> = {};

    // eventName can be also array of event names
    async emit(eventName: string | string[], ...restParams) {
        let eventNames = eventName;
        if (!Array.isArray(eventName)) eventNames = [ eventName ];

        for (const eventName of eventNames) {
            const events = this.events[eventName];
            if (events) {
                const _events = Array.from(events);
                for (let i = 0; i < _events.length; i++) {
                    try {
                        await _events[i].call(null, ...restParams);
                    } catch (error) {
                        console.error(error);
                    }
                }
            }
        }
    }

    // eventName can be also array of event names
    subscribe(eventName: string | string[], fn: any) {
        let eventNames = eventName;
        if (!Array.isArray(eventName)) eventNames = [ eventName ];

        for (const eventName of eventNames) {
            if (!this.events[eventName]) {
                this.events[eventName] = new Set();
            }

            const events = this.events[eventName];

            events.add(fn);
        }

        // or use unsubscribe function
        return this.unsubscribe.bind(this, eventName, fn);
    }

    // eventName can be also array of event names
    unsubscribe(eventName: string | string[], fn: any) {
        let eventNames = eventName;
        if (!Array.isArray(eventName)) eventNames = [ eventName ];

        for (const eventName of eventNames) {
            const events = this.events[eventName];

            if (events) events.delete(fn);
        }
    }
}

export const globalEventEmitter = new EventEmitter();
