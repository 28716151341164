import { observable, makeObservable } from 'mobx';
import * as _ from 'lodash';
import { fetchPreferencesSettings } from 'actions/preferences';
import { PreferenceField, SettingsProperties } from "types/actions/preferences";

class ListboxState {
    isOpened = false;
    data: null | SettingsProperties = null;
    dateFormat = 'YYYY-MM-DD';

    constructor() {
        makeObservable(this, {
            isOpened: observable,
            data: observable,
            dateFormat: observable,
        });
    }

    toggleOpenedState() {
        this.isOpened = !this.isOpened;
    }

    setOpenedState(boolean: boolean) {
        this.isOpened = boolean;
    }

    async setData() {
        const { isOkStatus, data } = await fetchPreferencesSettings();
        if (isOkStatus) {
            this.data = data;
            this.dateFormat = this.findDateFormat(data);
        }
    }

    getData(): null | SettingsProperties {
        return this.data;
    }

    getDateFormat(): string {
        return this.dateFormat;
    }

    findDateFormat(data: SettingsProperties): string {
        const defaultFormat = 'YYYY-MM-DD';

        if (!data) {
            return defaultFormat;
        }

        let dateFormatField;
        _.forEach(data.sections, section => {
            const findField = _.find(section.fields, field => field.preference_name === 'date_format_id');
            if (findField) {
                dateFormatField = findField;
            }
        });
        if (!dateFormatField) {
            return defaultFormat;
        }
        const dateFormat = _.find(dateFormatField.options, option => option.database_value === dateFormatField.database_value);
        if (!dateFormat) {
            return defaultFormat;
        }
        return dateFormat.display_value;
    }

    getField(preferenceName: string): PreferenceField | null {
        const userFields = !_.isEmpty(this.data?.sections) ? this.data?.sections[0].fields : [];
        const findField = _.find(userFields, field => field.preference_name === preferenceName);
        return findField ? findField : null;
    }
}


export default new ListboxState();
