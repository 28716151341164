import * as React from 'react';
import styles from 'components/dynamicForms/view/field/template/templateRow/styles.module.scss';
import TemplateField from 'components/dynamicForms/view/field/template/templateField';
import { showField } from 'helpers/condition';
import Button from 'components/button';
import IconX from 'assets/img/icons/close-x.svg';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { templateTypes } from 'constants/template';

class TemplateRow extends React.Component {
    choiceOptions = [];
    timeout = null;

    handleChangeValue = async ({ value, isValid }) => {
        const { row, templateState, onChange } = this.props;
        await row.changeTemplateValue(value, templateState);
        if (onChange) {
            onChange(isValid);
        }
    };

    handleChangeTemplate = async (value, referencedTableId) => {
        const { row, tableId, templateState, onChange } = this.props;
        templateState.removeResult(row.getId());
        await row.changeTemplateField(value, referencedTableId || tableId, templateState);
        row.changeTemplateValue(row.getValue(), templateState);
        if (onChange) {
            onChange();
        }
    };

    setActive = (state) => {
        if (state) {
            this.props.setActive(this.props.row.getId());
        } else {
            this.props.setActive(null);
        }
    };

    renderFilterFieldValue = () => {
        const { readOnly, row } = this.props;

        const special = {
            table_name: row.getField().referenced_table_name,
            table_id: row.getField().referenced_table_id,
        };
        const showElement = templateTypes.find(item => item.type === row.getField().column_type);
        const resultShowElement = showElement ? showElement.show_element : 'text';
        const props = {
            readOnly: readOnly,
            validate: row.getField().validate,
            sysColumnName: 'template-row',
        };
        return (
            <div className={ styles.Field }>
                { showField(
                    resultShowElement,
                    row.getValue(),
                    this.handleChangeValue,
                    this.handleChangeValue,
                    row.getTypeOptions(),
                    special,
                    props
                ) }
            </div>
        );
    };

    renderRemoveButton = () => {
        const { readOnly, lastIndex, index, row } = this.props;
        if (index === lastIndex) {
            return null;
        }
        return (
            <Button
                className={ styles.Button }
                onClick={ () => this.props.onRemove(row.getId()) }
                buttonType="icon"
                svg={ IconX }
                disabled={ readOnly }
            />
        );
    };

    constructor(props) {
        super(props);

        makeObservable(this, {
            choiceOptions: observable,
            timeout: observable,
        });
    }

    render() {
        const { row, readOnly, isActive } = this.props;
        return (
            <div className={ styles.Row }>
                <div className={ styles.Fields }>
                    <div className={ styles.Field }>
                        <TemplateField
                            data={ this.props.data }
                            onChange={ this.handleChangeTemplate }
                            value={ row.getField() }
                            readOnly={ readOnly }
                            setActive={ this.setActive }
                            isActive={ isActive }
                        />
                    </div>
                    { this.renderFilterFieldValue() }
                </div>
                <div className={ styles.Remove }>
                    { this.renderRemoveButton() }
                </div>
            </div>
        );
    }
}

export default observer(TemplateRow);
