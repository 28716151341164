/**
 * Описание: Класс Активность - элемент диаграммы рабочих процессов
 * Параметры:
 * sys_id - внутренний идентификатор объекта (хранится в БД)
 * type_id - тип активности
 * type_name - название типа активности
 * x - положение объекта на диаграмме
 * y - положение объекта на диаграмме
 * height - высота объекта на диаграмме
 * width - ширина объекта на диаграмме
 * fact_height - высота объекта + сумма высот выходов
 * name - наименование активности
 * description - описание активности
 * exits - массив выходов
 * updatePosition - метод обновления положения объекта в state после его перетаскивания
 * selectObject - метод переключения текущего объекта (выделен/не выделен).
 * selected - выделенный объект на диаграмме объект
 * activeExit - выделенный на диаграмме выход активности
 * setActiveExit - установить выход выделенным на диаграмме
 * isActive - активность выбрана, как конечная, при создании связи
 * setActiveActivity - выбрать активность, как конечную, при создании свзяи
 * togglePropertiesPanel - метод открытия панели свойств
 * getIcon - метод получения иконки (по типу активности)
 * onContextMenu - обработчик события выхова контекстного меню
 * isNew - выход ещё не сохранён в БД
 * readOnly - запрет редатирования
 * state - статус активности (для workflow viewer)
 * executedOrder - порядковый номер выполненной активности (для workflow viewer)
 * isPanMode - режим панорамирования
 * updateExit - сохранить выход с новым порядковым номером на сервере
 * editCompleted - необходиме данные заполнены пользователем
 * */
import * as React from 'react';
import { Rect, Group, Text, Image, Circle } from 'react-konva';
import Exit from './exit';
import { observable, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import _ from 'lodash';

const regularColor = '#FFFFFF';
const errorColor = '#FF5C5C';
const completedColor = '#57EBA3';
const canceledColor = '#DFE1E5';
const runningColor = '#0F8AF9';
const waitingColor = '#FEED73';
const inactiveColor = '#DFE1E5';

const regularNameColor = '#17181D';
const alterNameColor = '#FFFFFF';
const inactiveNameColor = '#A6A7B5';

const regularDescColor = '#6E728F';
const alterDescColor = '#FFFFFF';
const inactiveDescColor = '#A6A7B5';

const regularImageColor = '#8736DB';
const alterImageColor = '#FFFFFF';
const inactiveImageColor = '#A6A7B5';

const beginColor = '#05C270';
const selectedColor = '#5927d3';
const circleColor = '#8736db';

class Activity extends React.Component {

    icon = null;
    isExitSorting; // режим сортировки выходов
    exits; // выходы активности в их текущем порядке (порядок может меняться через drag-n-drop)
    sys_id;
    color;
    textColor;
    imageColor;
    descColor;

    constructor(props) {
        super(props);

        makeObservable(this, {
            icon: observable,
            isExitSorting: observable,
            exits: observable,
            sys_id: observable,
            moveExit: action,
            saveExitPosition: action,
        });

        this.chooseColors();
        this.updateIcon();
        this.exits = _.cloneDeep(this.props.exits); //для сохранения временного изменения порядка выходов (до drop)
    }

    componentDidUpdate(prevProps) {
        if (this.props.isNew !== prevProps.isNew || this.props.state !== prevProps.state || this.props.editCompleted !== prevProps.editCompleted) {
            this.chooseColors();
            this.updateIcon();
        }

        if (!_.isEqual(this.props.exits, prevProps.exits)) { // только, если обновление массива выходов через props.
            //обновляем внутренний массив выходов (например, если пришли новые идентификаторы выходов при создании активности)
            this.exits = _.cloneDeep(this.props.exits);
        }
    }

    handleClick = (event) => {
        event.cancelBubble = true;
        if (event.evt.button === 2) // правая кнопка мыши
        {
            this.props.selectObject('wf_activity', this.props.sys_id, true);
        }// context menu selects object
        else {
            this.props.selectObject('wf_activity', this.props.sys_id);
        } // toggle selection
        event.cancelActivityType = true;
    };

    handleDblClick = (event) => {
        event.cancelBubble = true;
        if (event.evt.button === 0) { // левая кнопка мыши
            if (this.props.type_name === 'Begin') {
                return false;
            }
            this.props.selectObject('wf_activity', this.props.sys_id, true); // select & show properties
            this.props.togglePropertiesPanel(true);
        }
        event.cancelActivityType = true;
    };

    async updateIcon() {
        this.icon = await this.props.getIcon(this.props.type_id, this.imageColor);
    }

    setActiveExit = (sys_id) => {
        this.props.setActiveExit(sys_id);
    };

    isSelected = () => {
        return this.props.selected.type === 'wf_activity' && this.props.selected.sys_id === this.props.sys_id;
    };

    chooseColors() {
        if (!this.props.editCompleted) {
            this.color = inactiveColor;
            this.textColor = inactiveNameColor;
            this.imageColor = inactiveImageColor;
            this.descColor = inactiveDescColor;
        }
        else {
            switch (this.props.state) {
                case 'running':
                    this.color = runningColor;
                    this.textColor = alterNameColor;
                    this.imageColor = alterImageColor;
                    this.descColor = alterDescColor;
                    break;
                case 'waiting':
                    this.color = waitingColor;
                    this.textColor = regularNameColor;
                    this.imageColor = regularImageColor;
                    this.descColor = regularDescColor;
                    break;
                case 'finished':
                    this.color = completedColor;
                    this.textColor = regularNameColor;
                    this.imageColor = regularImageColor;
                    this.descColor = regularDescColor;
                    break;
                case 'error':
                    this.color = errorColor;
                    this.textColor = alterNameColor;
                    this.imageColor = regularImageColor;
                    this.descColor = alterDescColor;
                    break;
                case 'cancelled':
                    this.color = canceledColor;
                    this.textColor = regularNameColor;
                    this.imageColor = regularImageColor;
                    this.descColor = regularDescColor;
                    break;
                default:
                    if (this.props.type_name === 'Begin') {
                        this.color = beginColor;
                        this.textColor = alterNameColor;
                        this.imageColor = alterImageColor;
                        this.descColor = alterDescColor;
                    }
                    else {
                        this.color = regularColor;
                        this.textColor = regularNameColor;
                        this.imageColor = regularImageColor;
                        this.descColor = regularDescColor;
                    }
            }
        }
    }

    componentDidCatch(error, info) {
        console.error(error, info);
    }

    handleDragMove = (e) => {
        if (!this.isExitSorting) {
            this.props.updatePosition(
                this.props.sys_id,
                {
                    x: Math.round(e.target.x()),
                    y: Math.round(e.target.y()),
                },
                false,
            );
        }
        e.cancelBubble = true;
    };

    handleDragEnd = (e) => {
        if (!this.isExitSorting) {
            this.props.updatePosition(
                this.props.sys_id,
                {
                    x: Math.round(e.target.x()),
                    y: Math.round(e.target.y()),
                },
                true,
            );
        }
        e.cancelBubble = true;
    };

    setExitSortingMode = (state) => { //перейти в режим сортировки выходов
        this.isExitSorting = state;
    };

    //Вычисление новых Y координат для выходов активности при перетаскивании одного из выходов
    moveExit = (y, sys_id) => {
        // y - новое положение выхода
        // sys_id - идентификатор выхода
        const correctedY = y - this.props.height;
        const neigbourExitIndex = this.exits.findIndex((exit) => (exit.sys_id !== sys_id && correctedY >= exit.y && correctedY < exit.y + exit.height));
        if (neigbourExitIndex > -1) {
            const draggingExitIndex = this.exits.findIndex((exit) => (exit.sys_id === sys_id));
            this.exits[neigbourExitIndex] = this.exits.splice(draggingExitIndex, 1, this.exits[neigbourExitIndex])[0]; //обмен элементов массива
            this.exits.forEach((exit, index) => {
                exit.y = exit.height * index;
            }); //положение по y в соответствии с новым порядком
        }
    };

    saveExitPosition = () => {
        this.exits.forEach((exit, index) => { // вычисление новых порядковых номеров для сохранения
            if (!isNaN(exit.order) && this.exits[index - 1] && !isNaN(this.exits[index - 1].order)
                && exit.order <= this.exits[index - 1].order) { // если порядок значений order не отражает реальный порядок выходов
                exit.order = this.exits[index - 1].order + 1;
                this.props.updateExit(exit.sys_id, exit); // сохраняем выход с новым порядковым номером на сервере
            }
        });
    };

    render() {
        let exits = [];
        if (this.exits && this.exits.length > 0) {
            exits = this.exits.map((elem, index) => {
                return <Exit
                    key={ elem.sys_id }
                    sys_id={ elem.sys_id }
                    x={ 0 }
                    y={ this.props.height + elem.y }
                    width={ this.props.width }
                    height={ elem.height }
                    name={ elem.name }
                    activeExit={ this.props.activeExit }
                    setActiveExit={ this.setActiveExit }
                    cornerRadius={ (index === this.exits.length - 1) ? [
                        0,
                        0,
                        8,
                        8,
                    ] : 0 }
                    selectObject={ this.props.selectObject }
                    onContextMenu={ this.props.onContextMenu }
                    selected={ this.props.selected }
                    togglePropertiesPanel={ this.props.togglePropertiesPanel }
                    readOnly={ this.props.readOnly }
                    linked={ elem.linked }
                    isNew={ elem.isNew }
                    setExitSortingMode={ this.setExitSortingMode }
                    yRange={ {
                        start: this.props.height,
                        end: this.props.fact_height - elem.height,
                    } }
                    moveExit={ this.moveExit }
                    saveExitPosition={ this.saveExitPosition }
                    state={ elem.state }
                    isPanMode={ this.props.isPanMode }
                    editCompleted={ this.props.editCompleted }
                />;
            });
        }

        const number = !!this.props.executedOrder &&
            <>
                <Circle
                    x={ this.props.width - 17 }
                    y={ 19 }
                    radius={ 11 }
                    fill={ circleColor }
                    listening={ false }
                />
                <Text
                    x={ this.props.width - 24 }
                    y={ 13 }
                    width={ 14 }
                    height={ 14 }
                    text={ this.props.executedOrder }
                    fontSize={ 11 }
                    fontFamily='Roboto'
                    fontStyle='bold'
                    letterSpacing={ 0.18 }
                    fill={ regularColor }
                    align='center'
                    verticalAlign='middle'
                    listening={ false }
                />
            </>;

        return (
            <Group
                draggable={ !this.props.activeExit && !this.props.readOnly && !this.isExitSorting }
                onDragMove={ this.handleDragMove }
                onDragEnd={ this.handleDragEnd }
                x={ this.props.x }
                y={ this.props.y }
                width={ this.props.width + 2 }
                height={ this.props.fact_height + 2 }
                id={ 'wf_activity' + this.props.sys_id }
                onMouseOver={ () => {
                    this.props.setActiveActivity(this.props.sys_id);
                }
                }
                onMouseOut={ () => {
                    this.props.setActiveActivity(null);
                } }
            >
                <Group
                    x={ 0 }
                    y={ 0 }
                    width={ this.props.width + 2 }
                    height={ this.props.fact_height + 2 }
                    onDblClick={ this.handleDblClick }
                    onClick={ this.handleClick }
                    onContextMenu={ (e) => {
                        this.props.onContextMenu(e);
                    } }

                >
                    <Rect
                        x={ 0 }
                        y={ 0 }
                        width={ this.props.width + 2 }
                        height={ this.props.fact_height + 2 }
                        fill={ !!this.isSelected() || this.props.isActive ? selectedColor : null }
                        cornerRadius={ 9 }
                    />
                    <Rect
                        x={ 1 }
                        y={ 1 }
                        width={ this.props.width }
                        height={ this.props.height }
                        fill={ this.color }
                        strokeWidth={ 1 }
                        cornerRadius={ this.props.exits && this.props.exits.length > 0 ? [
                            8,
                            8,
                            0,
                            0,
                        ] : 8 }
                        listening={ false }
                    />
                    { this.icon && <Image image={ this.icon }
                                          x={ 12 }
                                          y={ 12 }
                                          scale={ {
                                              x: .7,
                                              y: .7,
                                          } }
                                          listening={ false }
                    /> }
                    <Text
                        x={ 38 }
                        y={ 12 }
                        width={ this.props.executedOrder ? this.props.width - 80 : this.props.width - 62 }
                        height={ 20 }
                        lineHeight={ 1.333 }
                        text={ this.props.name }
                        fontSize={ 15 }
                        fontStyle={ '600' }
                        fontFamily='Open Sans'
                        fill={ this.textColor }
                        listening={ false }
                        ellipsis={ true }
                        wrap={ 'none' }
                    />
                    <Text
                        x={ 38 }
                        y={ 36 }
                        width={ this.props.width - 66 }
                        height={ 16 }
                        lineHeight={ 1.333 }
                        text={ this.props.description }
                        fontSize={ 12 }
                        fontFamily='Open Sans'
                        fill={ this.descColor }
                        listening={ false }
                        ellipsis={ true }
                        wrap={ 'none' }
                    />
                    { number }
                </Group>
                { exits }
            </Group>
        );
    }
}

export default observer(Activity);
