import * as React from 'react';
import { observer } from 'mobx-react';
import GlobalPortal from 'components/globalPortal';
import styles from './styles.module.scss';
import { observable, makeObservable } from 'mobx';
import { ATTRIBUTES } from 'constants/attributesForTests';

/***
 * Описание: Hint
 *
 * Параметры:
 * refParent - элемент относительно которого появляется хинт
 *
 */
class Hint extends React.Component {
    refHint = React.createRef();
    showHint = false;
    timeout = null;

    constructor(props) {
        super(props);

        makeObservable(this, {
            showHint: observable,
        });
    }

    componentDidMount() {
        const parentEl = this.props.refParent?.current;
        if (parentEl) {
            parentEl.addEventListener('mouseenter', this.onMouseEnter);
            parentEl.addEventListener('mouseleave', this.onMouseLeave);
            parentEl.addEventListener('click', this.onMouseLeave);
        }
    }

    componentWillUnmount() {
        const parentEl = this.props.refParent?.current;
        if (parentEl) {
            parentEl.removeEventListener('mouseenter', this.onMouseEnter);
            parentEl.removeEventListener('mouseleave', this.onMouseLeave);
            parentEl.removeEventListener('click', this.onMouseLeave);
        }
    }

    onMouseEnter = () => {
        this.showHint = true;
        this.timeout = setTimeout(this.setHintPosition, 400);
    };

    onMouseLeave = () => {
        this.showHint = false;
        clearTimeout(this.timeout);
    };

    setHintPosition = () => {
        const offset = 4;
        const { refParent: { current: parentEl } } = this.props;
        const { current: hintEl } = this.refHint;

        if (!parentEl || !hintEl) return;

        const windowHeight = document.documentElement.clientHeight;
        const windowWidth = document.documentElement.clientWidth;
        const scrollTop = window.pageYOffset;
        const scrollLeft = window.pageXOffset;
        const parentRect = parentEl.getBoundingClientRect();
        const hintRect = hintEl.getBoundingClientRect();

        let left = scrollLeft + parentRect.left + parentRect.width / 2 - hintRect.width / 2;
        if (left < 0) {
            left = offset;
        }
        else if (left + hintRect.width + offset > windowWidth) {
            left = left - (left + hintRect.width + offset - windowWidth);
        }

        let top = scrollTop + parentRect.top + parentRect.height + offset;
        if (parentRect.top + parentRect.height + offset + hintRect.height > windowHeight) {
            top = scrollTop + parentRect.top - (hintRect.height + offset);
        }

        hintEl.style.left = `${ left }px`;
        hintEl.style.top = `${ top }px`;
        hintEl.style.opacity = `1`;
    };

    render() {
        if (!this.showHint) return null;

        return (
            <GlobalPortal>
                <div className={ styles.Hint } ref={ this.refHint } data-test={ ATTRIBUTES.hint } >
                    { this.props.children }
                </div>
            </GlobalPortal>
        );
    }
}

export default observer(Hint);
