import * as React from 'react';

import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';

import FieldWrapper from 'components/dynamicForms/view/fieldWrapper';
import CodeMirror from '@uiw/react-codemirror';
import { langs } from '@uiw/codemirror-extensions-langs';
import * as events from '@uiw/codemirror-extensions-events';
import styles from 'components/dynamicForms/view/field/codeMirror/styles.module.scss';
import CodeMirrorModel from 'components/dynamicForms/model/field/CodeMirrorModel';
import { getTestNameField } from 'helpers/data';
import { ATTRIBUTES } from 'constants/attributesForTests';
import Button from 'components/button';
import IconMaximize from 'assets/img/icons/size-maximize-2.svg';
import IconMinimize from 'assets/img/icons/size-minimize-2.svg';
import _ from 'lodash';
import { isChanged } from 'helpers/form';

require('components/dynamicForms/view/field/codeMirror/codemirror.css');

/**
 * Описание: компонент CodeMirror
 * Параметры:
 * onChange: {required, type: function} - метод для изменения значения
 * className: {type: string} - class css
 * column_id: {type: string}
 * value: {type: string}
 * readOnly: {type: boolean}
 * options: {type: object} - параметры для CodeMirror
 */
class CodeMirrorComponent extends React.Component {
    model;
    key = 1;
    isFocused = false;
    isFullScreen = false;

    constructor(props) {
        super(props);

        makeObservable(this, {
            model: observable,
            key: observable,
            isFullScreen: observable,
        });

        if (props.model) {
            this.model = props.model;
        }
        else {
            this.model = new CodeMirrorModel(props);
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.onDocumentKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onDocumentKeyDown);
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props, prevProps)) {
            if (this.props.model) {
                this.model = this.props.model;
            }
            else {
                this.model.mergeData(this.props);
            }
        }
    }

    onDocumentKeyDown = (e) => {
        if (e.key === 'F11' && this.isFocused) {
            e.preventDefault();
            this.toggleFullScreen(!this.isFullScreen);
        }

        if (e.key === 'Escape' && this.isFullScreen) {
            e.preventDefault();
            this.toggleFullScreen(false);
        }
    };

    toggleFullScreen = (boolean) => {
        const cm = this.model.ref.current;
        const wrap = cm.editor;
        const { body } = document;

        if (boolean) {
            wrap.classList.add(styles.FullScreen);
            body.classList.add(styles.Freeze);
        }
        else {
            wrap.classList.remove(styles.FullScreen);
            body.classList.remove(styles.Freeze);
        }

        this.isFullScreen = boolean;
    };

    onChange = (value) => {
        this.model.value = value;
        this.model.changed = isChanged(this.model.defaultValue, this.model.value);
        if (this.props.onChange) {
            this.props.onChange(this.model);
        }
    };

    onFocusChange = (bool) => {
        this.isFocused = bool;
    };

    handleEnterPress = () => {
        if (this.model.save && !this.model.readonly) {
            this.model.save();
        }
    };

    renderFullScreenButton = () => {
        return (
            <Button
                buttonType={ 'icon' }
                svg={ this.isFullScreen ? IconMinimize : IconMaximize }
                className={ `${ styles.FSButton } ${ this.isFullScreen ? styles.fixed : '' }` }
                onClick={ () => {
                    this.toggleFullScreen(!this.isFullScreen);
                } }
            />
        );
    };

    render() {
        let stylesStr = [styles.Script];
        if (this.model.className) {
            stylesStr.push(this.model.className);
        }

        const eventExt = events.content({
            focus: () => {
               this.onFocusChange(true);
            },
            blur: () => {
                this.onFocusChange(false);
            },
            keydown: (evt) => {
                if (evt.key === 'Enter' && this.model.cellEditMode) {
                    this.handleEnterPress();
                }
            },
        });

        return (
            <FieldWrapper model={ this.model }>
                {/* key={this.key} is for re-render when we change value from ui-methods */ }
                <div
                    className={ stylesStr.join(' ') }
                    data-test={ `${ getTestNameField(this.model) }-${ ATTRIBUTES.fieldScript }` }
                    data-test-visible={ this.model.isVisible }
                    data-test-mandatory={ this.model.isMandatory }
                    data-test-readonly={ this.model.readonly }
                    data-test-field-type={ this.model.sys_column_name ? this.model.column_type : undefined }
                    data-test-field-name={ this.model.sys_column_name }
                >
                    <CodeMirror
                        ref={ this.model.ref }
                        key={ `${ this.model.column_id || '' }_${ this.key }` }
                        className={ this.model.readonly ? 'disabled' : '' }
                        basicSetup={{
                            lineNumbers: true,
                        }}
                        height={this.isFullScreen ? '100vh' : '300px'}
                        readOnly={this.model.readonly}
                        onChange={ this.onChange }
                        extensions={[eventExt, langs.javascript()]}
                        value={ this.model.value }
                    />
                    { this.renderFullScreenButton() }
                </div>
            </FieldWrapper>
        );
    }
}

export default observer(CodeMirrorComponent);
