import React from 'react';
import styles from 'components/groupedTable/styles.module.scss';
import Thead from 'components/groupedTable/thead/index';
import GlobalPortal from "components/globalPortal";
import { FixedHeadTableProps } from 'types/components/table/fixedHead/index';

export default class FixedHeadTable extends React.Component<FixedHeadTableProps> {
    render() {
        const {
            isBlankMode, classes, isMobile, filterFields, sortingFields,
            toggleSearchRowVisibility, isSearchRowVisible, isFixedHeadVisible,
            fixedHeadRef, fixedContainerRef, fixedHeadRowRef, table,
        } = this.props;

        const isVisibleHead = isFixedHeadVisible && !isMobile;
        return (
            <GlobalPortal>
                <div
                    ref={ fixedHeadRef }
                    className={ `${ styles.fixedHead } ${ isVisibleHead ? styles.visible : '' } ${ classes && classes.fixedHead }` }
                >
                    { isVisibleHead &&
                        <div className={ styles.container } ref={ fixedContainerRef }>
                            <table className={ styles.Table }>
                                <Thead
                                    reference={ fixedHeadRowRef }
                                    table={ table }
                                    filterFields={ filterFields }
                                    sortingFields={ sortingFields }
                                    onSearchClick={ toggleSearchRowVisibility }
                                    isSearchRowVisible={ isSearchRowVisible }
                                    isBlankMode={ isBlankMode }
                                    className={ classes && classes.Header }
                                    isFixedHead
                                />
                            </table>
                        </div>
                    }
                </div>
            </GlobalPortal>
        );
    }
}
