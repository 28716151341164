import * as React from 'react';
import { observer } from 'mobx-react';
import IconChevronRight from 'assets/img/icons/chevron-right.svg';
import IconChevronLeft from 'assets/img/icons/chevron-left.svg';
import IconCheck from 'assets/img/icons/check.svg';
import IconArrowR from 'assets/img/icons/arrow-right.svg';
import styles from './styles.module.scss';
import { observable, makeObservable } from 'mobx';
import { isEqual } from 'helpers/data';

class StatesFlow extends React.Component {
    dragData = {};
    isDragOn = false;
    isSliderOn = false;
    isTransitionOn = false;
    refList = React.createRef();
    refWrap = React.createRef();
    refActive = React.createRef();

    constructor(props) {
        super(props);

        makeObservable(this, {
            isDragOn: observable,
            isSliderOn: observable,
            isTransitionOn: observable,
        });
    }

    init = () => {
        document.addEventListener('mouseup', this.onMouseUp);
        document.addEventListener('mousemove', this.onMouseMove);
        window.addEventListener('resize', this.checkListSizes);
        this.checkListSizes();
        setTimeout(this.checkActiveTabVisibility, 100);
    };

    componentDidMount() {
        const { states } = this.props;
        if(states){
            this.init();
        }
    }

    componentDidUpdate(prevProps) {
        const { states } = this.props;
        if(!isEqual(states,prevProps.states)){
            this.init();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mouseup', this.onMouseUp);
        document.removeEventListener('mousemove', this.onMouseMove);
        window.removeEventListener('resize', this.checkListSizes);
    }

    onMouseDown = (e) => {
        e.preventDefault();
        if (!this.isSliderOn) return;

        const { current: list } = this.refList;
        if (!list) return;

        this.dragData.isMouseDown = true;
        this.dragData.downX = e.pageX;
        this.dragData.listX = list.getBoundingClientRect().left - parseInt(list.style.left ? list.style.left : 0);
        this.dragData.shiftX = e.pageX - list.getBoundingClientRect().left;
    };

    onMouseMove = (e) => {
        e.preventDefault();
        if (!this.dragData.isMouseDown) return;

        const { current: list } = this.refList;
        const { current: wrap } = this.refWrap;
        if (!list || !wrap) return;

        this.isTransitionOn = false;
        this.isDragOn = true;
        const left = e.pageX - this.dragData.shiftX - this.dragData.listX;
        const properLeft = this.getProperLeft(left);
        list.style.left = properLeft + 'px';
    };

    onMouseUp = () => {
        this.dragData.isMouseDown = false;
        this.isDragOn = false;
        this.isTransitionOn = true;
    };

    checkListSizes = () => {
        const { current: list } = this.refList;
        const { current: wrap } = this.refWrap;
        if (!list || !wrap) return;

        this.isTransitionOn = false;
        this.isSliderOn = wrap.getBoundingClientRect().width < list.getBoundingClientRect().width;
        const left = this.isSliderOn ? parseInt(list.style.left ? list.style.left : 0) : 0;
        const properLeft = this.getProperLeft(left);
        list.style.left = properLeft + 'px';
        setTimeout(() => {
            this.isTransitionOn = true;
        }, 100);
    };

    getProperLeft = (left) => {
        const { current: list } = this.refList;
        const { current: wrap } = this.refWrap;
        if (!list || !wrap) return;

        const rightEdge = wrap.getBoundingClientRect().width - list.getBoundingClientRect().width;
        if (left > 0) {
            return 0;
        }
        else if (left < rightEdge && rightEdge < 0) {
            return Math.round(rightEdge);
        }
        else {
            return Math.round(left);
        }
    };

    moveList = (direction) => () => {
        const { current: list } = this.refList;
        const { current: wrap } = this.refWrap;
        if (!list || !wrap) return;

        const listItems = list.children;
        const wrapRect = wrap.getBoundingClientRect();
        let left = parseInt(list.style.left ? list.style.left : 0);
        const offset = 0;

        if (direction === 'left') {
            for (let i = 0, itemsLength = listItems.length; i < itemsLength; i++) {
                const item = listItems[i];
                const itemRect = item.getBoundingClientRect();
                if (Math.round(itemRect.left) + Math.round(itemRect.width) > Math.round(wrapRect.left) + Math.round(wrapRect.width)) {
                    left = left - Math.round(itemRect.left - wrapRect.left) + offset;
                    break;
                }
            }
        }
        else {
            for (let i = listItems.length - 1; i >= 0; i--) {
                const item = listItems[i];
                const itemRect = item.getBoundingClientRect();
                if (Math.round(itemRect.left) < Math.round(wrapRect.left)) {
                    left = left + Math.round(wrapRect.width - itemRect.width) + Math.round(wrapRect.left - itemRect.left) - offset;
                    break;
                }
            }
        }

        const properLeft = this.getProperLeft(left);
        list.style.left = properLeft + 'px';
    };

    checkActiveTabVisibility = () => {
        if (!this.isSliderOn) return;

        const listEl = this.refList ? this.refList.current ? this.refList.current : null : null;
        const wrapEl = this.refWrap ? this.refWrap.current ? this.refWrap.current : null : null;
        const tabEl = this.refActive ? this.refActive.current ? this.refActive.current : null : null;

        if (!tabEl || !wrapEl || !listEl) return;

        const tabRect = tabEl.getBoundingClientRect();
        const wrapRect = wrapEl.getBoundingClientRect();
        let listLeft = parseInt(listEl.style.left ? listEl.style.left : 0);
        const tabLeft = Math.round(tabRect.left);
        const tabRight = tabLeft + Math.round(tabRect.width);
        const wrapLeft = Math.round(wrapRect.left);
        const wrapRight = wrapLeft + Math.round(wrapRect.width);

        if (tabLeft < wrapLeft) {
            listLeft = listLeft + (wrapLeft - tabLeft);
        }
        else if (tabRight > wrapRight) {
            listLeft = listLeft - (tabRight - wrapRight);
        }

        listEl.style.left = `${ this.getProperLeft(listLeft) }px`;
    };

    renderList = (items) => {
        return items.map((item, index) => {
            const ref = item.state === 'current' ? this.refActive : null;
            return (
                <div
                    ref={ ref }
                    key={ `li-${ index }` }
                    className={ `${ styles.ListItem } ${ item.state ? styles[item.state] : '' }` }
                >
                    { item.title }
                    { item.state === "complete" && <div dangerouslySetInnerHTML={{__html: IconCheck}} className={styles.IconCheck}/> }
                    { index < items.length - 1 && <div dangerouslySetInnerHTML={{__html: IconArrowR}} className={styles.IconArrow}/> }
                </div>
            );
        });
    };

    renderSkeleton = () => {
        return (
            <div className={ styles.StatesWrap }>
                <div className={ `${styles.States} ${styles.Skeleton}` }>
                    <div className={ styles.Wrapper }>
                        <div className={ styles.List }>
                            <div className={ styles.ListItem }>
                                <div className={ styles.SkeletonItem }/>
                                <div dangerouslySetInnerHTML={{__html: IconArrowR}} className={styles.IconArrow}/>
                            </div>
                            <div className={ styles.ListItem }>
                                <div className={ styles.SkeletonItem }/>
                                <div dangerouslySetInnerHTML={{__html: IconArrowR}} className={styles.IconArrow}/>
                            </div>
                            <div className={ styles.ListItem }>
                                <div className={ styles.SkeletonItem }/>
                                <div dangerouslySetInnerHTML={{__html: IconArrowR}} className={styles.IconArrow}/>
                            </div>
                            <div className={ styles.ListItem }>
                                <div className={ styles.SkeletonItem }/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { states } = this.props;

        if(!states) return this.renderSkeleton();

        return (
            <div className={ styles.StatesWrap }>
                <div className={ `${ styles.States } ${ this.isSliderOn ? styles.SliderIsOn : '' }` }>
                    <div className={ styles.Wrapper } ref={ this.refWrap }>
                        <div className={ `${ styles.List } ${ this.isTransitionOn ? styles.TransitionLeft : '' }` } onMouseDown={ this.onMouseDown } ref={ this.refList }>
                            { this.renderList(states) }
                        </div>
                    </div>
                    { this.isSliderOn && (
                        <div className={ styles.Controls }>
                            <div
                                onClick={ this.moveList('right') }
                                className={ styles.Arrow }
                                dangerouslySetInnerHTML={ { __html: IconChevronLeft } }
                            />
                            <div
                                onClick={ this.moveList('left') }
                                className={ styles.Arrow }
                                dangerouslySetInnerHTML={ { __html: IconChevronRight } }
                            />
                        </div>
                    ) }
                    { this.isDragOn && <div className={ styles.Overlay } /> }
                </div>
            </div>
        );
    }
}

export default observer(StatesFlow);
