import { FormSplitType } from 'types/components/dynamicForms/formSplit';

/**
 * Базовый класс модели разделителя формы
 */
export default class FormSplitModel implements FormSplitType{
    split;
    title;

    constructor(data) {
        this.split = data.split;
        this.title = data.title;
    }

    isSplit() {
        return true;
    }
}