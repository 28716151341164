import * as React from 'react';
import { observer } from 'mobx-react';
import styles from 'components/dateTimePicker/styles.module.scss';
import langStore from 'globalState/lang';
import { ATTRIBUTES } from 'constants/attributesForTests';

/**
 * Описание: Годы датапикера
 * Параметры:
 * visibleDay - выбранный день
 * onItemClick - метод при выборе
 * onHeaderClick - метод при клике на шапку
 * isHidden - флаг скрыт ли блок
 */
class DatePickerYears extends React.Component {
    refCont = React.createRef();
    refYearsListCurrent = React.createRef();

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.setYearsListScrollTop();
    }

    componentDidUpdate(prevProps) {
        const { isHidden } = this.props;
        if (isHidden !== prevProps.isHidden && !isHidden) {
            this.setYearsListScrollTop();
        }
    }

    setYearsListScrollTop = () => {
        const { current: cont } = this.refCont;
        const { current: currentItem } = this.refYearsListCurrent;
        if (!cont || !currentItem) return;
        cont.scrollTop = currentItem.getBoundingClientRect().top + cont.scrollTop - cont.getBoundingClientRect().top;
    };

    getYearsList = (min = 1900, max = 2100) => {
        const items = [];
        for (let index = min; index <= max; index++) {
            const isActive = index === this.props.visibleDay.year();
            const ref = isActive ? this.refYearsListCurrent : null;
            items.push(
                <div className={ styles.HeaderYearsCol } key={ index } ref={ ref }>
                    <button
                        type={ 'button' }
                        className={ `${ styles.HeaderButton } ${ isActive ? styles.active : '' }` }
                        onClick={ this.onItemClick(index) }
                        data-test={ ATTRIBUTES.fieldDatePickerHeaderYearItem }
                    >
                        { index }
                    </button>
                </div>,
            );
        }
        return <div className={ styles.HeaderYears }>{ items }</div>;
    };

    onItemClick = (index) => () => {
        const { onItemClick } = this.props;
        if (onItemClick) {
            onItemClick(index);
        }
    };

    onHeaderClick = (index) => () => {
        const { onHeaderClick } = this.props;
        if (onHeaderClick) {
            onHeaderClick(index);
        }
    };

    render() {
        const { data_picker_titles } = langStore.getTranslate();
        return (
            <div
                className={ `${ styles.HeaderPopover } ${ this.props.isHidden ? styles.hidden : '' }` }
                data-test={ ATTRIBUTES.fieldDatePickerHeaderYearsPopover }
            >
                <div className={ styles.HeaderPopoverTop }>
                    <span className={ styles.HeaderLink } onClick={ this.onHeaderClick(false) }>{ data_picker_titles && data_picker_titles.year }</span>
                </div>
                <div className={ styles.HeaderPopoverCont } ref={ this.refCont }>
                    { this.getYearsList() }
                </div>
            </div>
        );
    }
}

export default observer(DatePickerYears);
