import * as React from 'react';
import ParsedComponent from 'components/widget/parsedComponent';
import { WidgetData } from 'globalState/widgets/widgetData';
import widgetsDataState from 'globalState/widgets';
import SimpleWidget from 'ui-actions-scripts/simple-widget';
import { evalScript, getInitializeParams, initCustomWidget } from 'helpers/widget';
import ChildrenComponent from 'components/widget/childrenComponent';
import { observer } from 'mobx-react';
import SimpleWidgets from 'ui-actions-scripts/simple-widgets';
import { withRouter } from 'react-router-dom';
import EventBusState from 'globalState/eventBus';
import { eventType } from 'constants/eventBusTypes';
import _ from 'lodash';

/**
 * Описание: компонент Widget
 * Параметры:
 * widget_instance_id: {required, type: string} - id виджета
 * template: {required, type: string} - html шаблон
 * client_script: {type: string} - клиентские скрипты
 * css: {type: string} - стили css
 * options: {type: object} - объект с параметрами
 */

const DIRECTIVE_SWITCH = 'simple-switch';
const DIRECTIVE_SWITCH_VALUE = 'simple-switch-value';
const DIRECTIVE_CASE = 'simple-case';
const DIRECTIVE_DEFAULT = 'simple-default';

class Widget extends React.Component {
    widgetData = {};

    constructor(props) {
        super(props);
        initCustomWidget(props.widget_instance_id);
        this.widgetData = new WidgetData(this.getWidgetInstanceId(), props.client_script);
        widgetsDataState.addWidget(this.widgetData);
        const params = getInitializeParams(props.template);
        this.initSWidget(params);
        if (!window.s_widgets) {
            window.s_widgets = new SimpleWidgets();
        }
        if (!props.isWait) {
            this.widgetData.parse(props.template);
            this.widgetData.setOptions(props.options);
        }
    }

    componentDidMount() {
        const { portalState } = this.props;
        this.evalScript();
        if (portalState) {
            portalState.setTimeout(this.emitAfterLoadWidgets);
        }
    }

    componentDidUpdate(prevProps) {
        const { client_script, portalState, isWait } = this.props;
        if (client_script && client_script !== prevProps.client_script) {
            this.evalScript();
        }
        if (this.props.template !== prevProps.template) {
            this.widgetData.parse(this.props.template);
        }
        if (!_.isEqual(this.props, prevProps) && portalState) {
            portalState.setTimeout(this.emitAfterLoadWidgets);
        }
        if (!isWait && isWait !== prevProps.isWait) {
            this.loadWidgetData();
        }
    }

    componentWillUnmount() {
        this.widgetData.clear();
    }

    getWidgetInstanceId = () => {
        const { isModal, widget_instance_id } = this.props;
        return isModal ? `modal${ widget_instance_id }` : widget_instance_id;
    }

    initSWidget = (params) => {
        const { client_script, isWait } = this.props;
        if (!window.s_widget) {
            window.s_widget = new SimpleWidget(this.getWidgetInstanceId(), !!client_script, params);
        }
        else if (client_script && !isWait) {
            window.s_widget.serverUpdate(this.getWidgetInstanceId(), params);
        }
    }

    loadWidgetData = async () => {
        const { template, params, options } = this.props;
        await window.s_widget.serverUpdate(this.getWidgetInstanceId(), params);
        await this.widgetData.parse(template);
        await this.widgetData.setOptions(options);
        this.evalScript();
    };

    emitAfterLoadWidgets = () => {
        EventBusState.emit(eventType.AFTER_LOAD_WIDGETS, true);
    };

    evalScript = () => {
        const { client_script, isWait } = this.props;
        if (!isWait) {
            evalScript(client_script, this.getWidgetInstanceId());
        }
    };

    filterChildrenByCase = (children, value) => {
        let filteredChildren = _.filter(children, (child) => {
            if (child.getType() && child.getType() !== 'text') { // является parsed component
                const childCase = this.getDirectiveValue(child.getProps(), DIRECTIVE_CASE);
                if (childCase) {
                    return (childCase.value === value);
                }
            }
            return false; // исключаем детей без директивы case
        });
        if (!filteredChildren.length) {
            const defaultChild = _.find(children, (child) => {
                if (child.props.type) { // является parsed component
                    return this.getDirectiveValue(child.getProps(), DIRECTIVE_DEFAULT);
                }
                return false;
            });
            if (defaultChild) {
                filteredChildren.push(defaultChild);
            }
        }
        return filteredChildren;
    };

    getLocation() {
        const { pathname } = this.props;
        return pathname ? {
            pathname,
        } : null;
    }

    getChildrenComponent = (innChildren, parentProps) => {
        if (!innChildren || innChildren.length === 0) {
            return null;
        }
        let filteredChildren;
        if (parentProps && parentProps.hasOwnProperty(DIRECTIVE_SWITCH_VALUE)) {
            filteredChildren = this.filterChildrenByCase(innChildren, parentProps[DIRECTIVE_SWITCH_VALUE]);
        }
        else {
            filteredChildren = innChildren;
        }

        return _.map(filteredChildren, child => {
            if (!child || !child.getType()) {
                return null;
            }
            if (child.getType() && child.getType() !== 'text') {
                const props = { ...child.getProps() };
                let specialProps = {};
                const switchDirective = this.getDirectiveValue(props, DIRECTIVE_SWITCH);
                if (switchDirective) {
                    specialProps[DIRECTIVE_SWITCH_VALUE] = switchDirective.value;
                }
                const location = this.getLocation();
                if (location) {
                    props._location = location;
                }
                props.parentformsectionmodel = this.props.parentFormSectionModel;
                let children = [];
                if (child.getData()) {
                    children.push(
                        <ChildrenComponent
                            key={ child.getKey() }
                            data={ this.widgetData.getData() }
                            options={ this.widgetData.getOptions() }
                        >
                            { child.getData() }
                        </ChildrenComponent>,
                    );
                }
                const childrenCmp = this.getChildrenComponent(child.getChildren(), specialProps);
                if (childrenCmp) {
                    children.push(childrenCmp);
                }
                return (
                    <ParsedComponent
                        { ...props }
                        type={ child.getType() }
                        key={ child.getKey() }
                        widgetDataState={ this.widgetData }
                        isServicePortal={ this.props.isServicePortal }
                        dictionary={ this.props.dictionary }
                        tableName={ this.props.tableName }
                        recordId={ this.props.recordId }
                    >
                        { !_.isEmpty(children) ? children : null }
                    </ParsedComponent>
                );
            }
            if (!child.getData()) {
                return null;
            }
            return (
                <ChildrenComponent
                    key={ child.getKey() }
                    data={ this.widgetData.getData() }
                    options={ this.widgetData.getOptions() }
                >
                    { child.getData() }
                </ChildrenComponent>
            );
        });
    };

    getReactElements = () => {
        const elements = this.widgetData.getElements();
        if (_.isEmpty(elements)) {
            return null;
        }
        return this.getChildrenComponent(elements);
    };

    getDirectiveValue = (attrs, directive) => {
        let result;
        if (attrs && attrs.hasOwnProperty(directive)) {
            const f = new Function('"use strict";return (' + attrs[directive] + ')');
            try {
                result = { value: f() };
            }
            catch (e) {
                console.error(e);
            }
        }
        return result;
    };

    render() {
        const { css } = this.props;
        const stylesHtml = css ? <style>{ css }</style> : '';
        return (
            <>
                { stylesHtml }
                { this.getReactElements() }
            </>
        );
    }
}

export default withRouter(observer(Widget));
