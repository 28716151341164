import { action, observable, makeObservable } from 'mobx';
import { fetchChoices, fetchDynamics, getConditionString, getConditionValue } from 'helpers/condition';
import { fetchConditionParse, fetchDotWalkList } from 'actions/conditions';
import * as _ from 'lodash';
import { FilteringRowState } from 'globalState/conditions/condition/filteringRow';
import { SortingRowState } from 'globalState/conditions/condition/sortingRow';
import { GroupingRowState } from 'globalState/conditions/condition/groupingRow';
import langStore from 'globalState/lang';


export class Condition {
    _isShowSorting = false;
    _isShowGrouping = false;
    _sortingFields = [];
    _groupingFields = [];
    _keySortIndex = 0;
    _keyGroupIndex = 0;
    _isShowFilter = false;
    _filterFields = [];
    _keyFilterIndex = 0;
    _conditionData = {};
    _block = 1;
    _breadcrumbSortingFields = [];
    _breadcrumbGroupingFields = [];
    _breadcrumbFilteringFields = [];
    _dotWalkList = [];                     // main list of data for condition field
    _columnsDotWalkList = [];              // additional list of data for table head, when dot walk level is more 1 (ex. table_id.name)
    _isRunParse = '';                      // check for run now parse for condition or not
    _relatedLists = {};
    _columns = [];
    _fixedCondition = '';
    showLoader = false;
    isHideOr = false;

    constructor() {
        makeObservable(this, {
            _isShowSorting: observable,
            _isShowGrouping: observable,
            _sortingFields: observable,
            _groupingFields: observable,
            _keySortIndex: observable,
            _keyGroupIndex: observable,
            _isShowFilter: observable,
            _filterFields: observable,
            _keyFilterIndex: observable,
            _conditionData: observable,
            _block: observable,
            _breadcrumbSortingFields: observable,
            _breadcrumbGroupingFields: observable,
            _breadcrumbFilteringFields: observable,
            _dotWalkList: observable,
            _columnsDotWalkList: observable,
            _isRunParse: observable,
            _fixedCondition: observable,
            showLoader: observable,
            isHideOr: observable,
            setFixedCondition: action,
            setRelatedListCondition: action,
            clearRelatedListsCondition: action,
            setDotWalkList: action,
            setColumnsDotWalkList: action,
            addFiltering: action,
            addSorting: action,
            addGrouping: action,
            removeFilteringRow: action,
            removeSortingRow: action,
            removeGroupingRow: action,
            removeConditionsByDbColumnName: action,
            removeConditionBreadcrumbField: action,
            removeAllFilterOut: action,
            changeConditionFieldValue: action,
            changeConditionFieldOptions: action,
            clearData: action,
            clearWithoutNotEdit: action,
            clearSorting: action,
            clearGrouping: action,
            parseConditionString: action,
            parseFilteringFields: action,
            parseSortingFields: action,
            parseGroupingFields: action,
            setLineBlock: action,
            refreshBreadcrumbs: action,
            setSortingColumns: action,
            sortingBreadcrumb: action,
            groupingBreadcrumb: action,
            findAndSetAddColumnsDotWalkList: action,
            fetchColumnsDotWalkList: action,
        });
    }

    getFixedCondition = () => {
        return this._fixedCondition;
    };

    setFixedCondition = (fixedCondition) => {
        if (this._fixedCondition) {
            return;
        }
        this._fixedCondition = fixedCondition;
    };

    isShowSorting = () => {
        return this._isShowSorting;
    };

    isShowGrouping = () => {
        return this._isShowGrouping;
    };

    isShowFilter = () => {
        return this._isShowFilter;
    };

    getFilterFields = () => {
        return this._filterFields;
    };

    getSortingFields = () => {
        return this._sortingFields;
    };

    getGroupingFields = () => {
        return this._groupingFields;
    };

    getBreadcrumbFilterFields = () => {
        return this._breadcrumbFilteringFields;
    };

    getBreadcrumbSortingFields = () => {
        return this._breadcrumbSortingFields;
    };

    getBreadcrumbGroupingFields = () => {
        return this._breadcrumbGroupingFields;
    };

    getKeyFilterIndex = () => {
        return this._keyFilterIndex;
    };

    getKeySortIndex = () => {
        return this._keySortIndex;
    };

    getKeyGroupIndex = () => {
        return this._keyGroupIndex;
    };

    getRelatedListsCondition = () => {
        return this._relatedLists;
    };

    setRelatedListCondition = (name, condition) => {
        this._relatedLists[name] = condition;
    };

    clearRelatedListsCondition = () => {
        this._relatedLists = {};
    };

    getDotWalkList = () => {
        return this._dotWalkList;
    };

    setDotWalkList = (value) => {
        this._dotWalkList = value;
    };

    getColumnsDotWalkList = () => {
        return this._columnsDotWalkList;
    };

    setColumnsDotWalkList = (value) => {
        this._columnsDotWalkList = value;
    };

    addFiltering = (type, block, index) => {
        if (type === 'OR' && !block) {
            this._block++;
        }
        this._isShowFilter = true;
        this._keyFilterIndex++;
        const filteringRow = new FilteringRowState({
            type: type === 'OR' && !block ? 'AND' : type,
            block: block ? block : (type === 'OR' ? this._block : this.getMinBlockNumber()),
            id: `filter${ this._keyFilterIndex }`,
            field: '',
            operator: '',
            value: '',
            operatorOptions: [],
            choiceOptions: [],
        });
        this._filterFields = this.setReorderFilterFields(filteringRow, index);
        return filteringRow;
    };

    addSorting = () => {
        const { filter_titles } = langStore.getTranslate();
        this._isShowSorting = true;
        this._keySortIndex++;
        const sortingRow = new SortingRowState({
            id: `sorting${ this._keySortIndex }`,
            field: '',
            direction: filter_titles?.a_to_z,
        });
        this._sortingFields = [
            ...this._sortingFields,
            sortingRow,
        ];
        return sortingRow;
    };

    addGrouping = () => {
        this._isShowGrouping = true;
        this._keyGroupIndex++;
        const groupingRow = new GroupingRowState({
            id: `grouping${ this._keyGroupIndex }`,
            field: '',
            direction: langStore.getConditionGroupOptions()[0],
        });
        this._groupingFields = [
            ...this._groupingFields,
            groupingRow,
        ];
        return groupingRow;
    };

    removeFilteringRow = (id) => {
        const newFilterFields = this._filterFields.filter(field => field.getId() !== id);
        _.map(newFilterFields, (row, index) => {
            row.setIndex(index);
            return row;
        });
        this._filterFields = newFilterFields.sort(this.compareFilterRows);
        if (this._filterFields.length === 0) {
            this._isShowFilter = false;
        }
    };

    removeSortingRow = (id) => {
        this._sortingFields = this._sortingFields.filter(field => field.getId() !== id);
        if (this._sortingFields.length === 0) {
            this._isShowSorting = false;
        }
    };

    removeGroupingRow = (id) => {
        this._groupingFields = this._groupingFields.filter(field => field.getId() !== id);
        if (this._groupingFields.length === 0) {
            this._isShowGrouping = false;
        }
    };

    removeConditionsByDbColumnName = (dbColumnName) => {
        this._breadcrumbSortingFields = this._breadcrumbSortingFields.filter(row => row.getField() && row.getField().dot_walking_attribute.indexOf(dbColumnName + '.') !== 0);
        this._filterFields = this._filterFields.filter(row => row.getField() && row.getField().dot_walking_attribute.indexOf(dbColumnName + '.') !== 0);
    };

    removeConditionBreadcrumbField = (id) => {
        if (this.isSorting(id)) {
            this._breadcrumbSortingFields = this._breadcrumbSortingFields.filter(row => row.getId() !== id);
            return;
        }
        this._breadcrumbFilteringFields = this._breadcrumbFilteringFields.filter(row => row.getId() !== id);
    };

    removeAllFilterOut = () => {
        this._filterFields = this._filterFields.filter(row => {
            const operator = row.getOperator();
            if (operator.database_value) {
                return operator.database_value !== '!=';
            }
            return operator !== '!=';
        });
    };

    updateChoiceOptions = async (field, tableId) => {
        let choiceOptions = [];
        if (field.column_id && field.condition_type === 'choice') {
            choiceOptions = await fetchChoices(field.column_id, tableId);
        }
        if (field.column_id && [
            'list',
            'reference',
        ].includes(field.condition_type)) {
            if (field.dot_walking_attribute === 'sys_id' && tableId) {
                choiceOptions = await fetchDynamics(field.column_id, tableId);
            }
            else {
                choiceOptions = await fetchDynamics(field.column_id);
            }
        }
        return choiceOptions;
    };

    changeConditionFieldValue = (row, value) => {
        if (row.operator && row.operator.show_element) {
            if (typeof value === 'string' && row.operator.show_element === 'reference' && row.field.referenced_table_name) {
                this.fetchReferenceValue(row, value);
            }
            else {
                row.value = getConditionValue(row.operator.show_element, value);
            }
        }
        else {
            row.value = value;
        }
    };

    changeConditionFieldOptions = (id, options) => {
        this._filterFields = this._filterFields.map((field) => {
            if (field.id === id) {
                field.operatorOptions = options;
            }
            return field;
        });
    };

    clearData = () => {
        this._isShowSorting = false;
        this._sortingFields = [];
        this._keySortIndex = 0;
        this._isShowGrouping = false;
        this._groupingFields = [];
        this._keyGroupIndex = 0;
        this._isShowFilter = false;
        this._filterFields = _.filter(this._filterFields, filteringRow => filteringRow.getDisabled());
        this.isHideOr = !!_.find(this._filterFields, filteringRow => filteringRow.getDisabled());
        this._keyFilterIndex = this.isHideOr ? this._keyFilterIndex : 0;
        this._conditionData = {};
        this._block = 1;
        this._breadcrumbSortingFields = [];
        this._breadcrumbGroupingFields = [];
        this._breadcrumbFilteringFields = _.filter(this._breadcrumbFilteringFields, filteringRow => filteringRow.getDisabled());
    };

    clearWithoutNotEdit = () => {
        this._isShowSorting = false;
        this._sortingFields = [];
        this._keySortIndex = 0;
        this._isShowGrouping = false;
        this._groupingFields = [];
        this._keyGroupIndex = 0;
        this._isShowFilter = false;
        this._keyFilterIndex = 1;
        this._conditionData = {};
        this._block = 1;
        this._breadcrumbSortingFields = [];
        this._breadcrumbGroupingFields = [];
        this._breadcrumbFilteringFields = this._breadcrumbFilteringFields.filter((f, index) => index === 0);
        this._filterFields = this._filterFields.filter((f, index) => index === 0);
    };

    clearSorting = () => {
        this._sortingFields = [];
        this._keySortIndex = 0;
    };

    clearGrouping = () => {
        this._groupingFields = [];
        this._keyGroupIndex = 0;
    };

    getIsHideOr = () => {
        return this.isHideOr;
    }

    parseFixedCondition = async (condition, tableName, tableId) => {
        const params = {
            condition: condition,
        };
        if (tableId) {
            params.table_id = tableId;
        }
        else {
            params.table_name = tableName;
        }
        const response = await fetchConditionParse(params);
        const data = response.isOkStatus && response.data ? response.data : {};
        this.parseFilteringFields(data, tableId, true);
        this.isHideOr = true;
    };

    parseConditionString = async (condition, tableName, tableId) => {
        if (condition && (condition === this._isRunParse || condition === this._fixedCondition)) {  // проверка на несколько запусков почти одновременно, это в relatedList или fixedCondition
            return;
        }
        this._isRunParse = condition;
        if (condition && (tableName || tableId)) {
            const params = {
                condition: condition,
            };
            if (tableId) {
                params.table_id = tableId;
            }
            else {
                params.table_name = tableName;
            }
            const response = await fetchConditionParse(params);
            this._conditionData = response.isOkStatus && response.data ? response.data : {};
            this.parseFilteringFields(this._conditionData, tableId);
            this.parseGroupingFields(this._conditionData);
            this.parseSortingFields(this._conditionData);
        }
        else if (!condition) {
            this.clearData();
            this.addFiltering('AND');
        }
        this._isRunParse = '';
    };

    getBlock = (parent, index) => {
        if (parent.operator === 'AND') {
            return 1;
        }
        return index + 1;
    };

    getMinBlockNumber() {
        if (this._filterFields.length === 0) {
            return 1;
        }
        return Math.min(...this._filterFields.map(item => item.getBlock()));
    }

    cleanFilterFields = () => {
        this._filterFields = [];
    };

    // функция убирает из row лишний параметр, который не приходит в parse
    getRowValue = (row) => {
        if (row.getField().column_type === 'choice') {
            const value = row.getValue();
            delete value.values;
            return value;
        }
        return row.getValue();
    };

    cleanSortingFields = (conditionData) => {
        this._sortingFields = _.filter(this._sortingFields, row => {
            const findRow = _.find(conditionData.order_by, (orderRow, key) => {
                return row.getField().dot_walking_attribute === key && row.getDirection() === orderRow.sort;
            });
            return !!findRow;
        });
    };

    cleanGroupingFields = (conditionData) => {
        this._groupingFields = _.filter(this._groupingFields, row => {
            const findRow = _.find(conditionData.group_by, (groupRow, key) => {
                return row.getField().dot_walking_attribute === key && row.getDirection().database_value === groupRow.operator;
            });
            return !!findRow;
        });
    };

    getParseRows = (conditionData) => {
        const parseRows = [];
        if (conditionData && !_.isNil(conditionData.where)) {
            const parent = conditionData.where;
            if (!parent.operands) {
                parseRows.push(parent);
            }
            if (parent.operands && parent.operands.length) {
                parent.operands.forEach((line) => {
                    if (!line.operands) {
                        parseRows.push(line);
                    }
                    if (line.operands) {
                        this.getInnerParseRows(line, parseRows);
                    }
                });
            }
        }
        return parseRows;
    };

    getInnerParseRows = (parent, parseRows) => {
        parent.operands.forEach((line) => {
            if (!line.operands) {
                parseRows.push(line);
            }
            if (line.operands) {
                this.getInnerParseRows(line, parseRows);
            }
        });
    };

    parseFilteringFields = (conditionData, tableId, isAllRowDisabled) => {
        if (conditionData && !_.isNil(conditionData.where)) {
            this.cleanFilterFields();
            this._isShowFilter = true;
            const parent = conditionData.where;
            if (!parent.operands) {
                this.setLineBlock({}, parent, 1, 0, 'AND', tableId);
                return;
            }
            let counter = { index: 0 };
            // parent это верхний уровень, блоки разбиваются по OR в parent
            parent.operands.forEach((line, parentIndex) => {
                const block = this.getBlock(parent, parentIndex);
                if (!line.operands) {
                    this.setLineBlock(parent, line, block, counter.index, 'AND', tableId, isAllRowDisabled);
                }
                if (line.operands) {
                    this.setInnerBlocks(line, block, tableId, counter, 'AND', isAllRowDisabled);
                }
                counter.index++;
            });
        }
        else {
            this._filterFields = [];
            this._breadcrumbFilteringFields = [];
            this.addFiltering('AND');
        }
    };

    parseSortingFields = (conditionData) => {
        if (conditionData && !_.isNil(conditionData.order_by)) {
            this.cleanSortingFields(conditionData);
            this._isShowSorting = true;
            const sorts = Object.keys(conditionData.order_by);
            sorts.forEach(sortKey => {
                const directionValue = conditionData.order_by[sortKey].sort;
                const fieldValue = {
                    dot_walking_attribute: sortKey,
                };
                const existRow = this.hasRowInSorting(fieldValue, directionValue);
                if (existRow) {
                    //Обновляем существующее условие
                    existRow._displayValue.display_column_name = conditionData.order_by[sortKey].display_column_name;
                    existRow._displayValue.is_dwa = conditionData.order_by[sortKey].is_dwa;
                    existRow._direction = directionValue;
                    this._breadcrumbSortingFields = _.cloneDeep(this._sortingFields);
                    return;
                }
                this._keySortIndex++;
                const sortingRow = new SortingRowState({
                    id: `sorting${ this._keySortIndex }`,
                    field: fieldValue,
                    displayValue: { // Перевод поля в зависимости от языка
                        display_column_name: conditionData.order_by[sortKey].display_column_name,
                        is_dwa: conditionData.order_by[sortKey].is_dwa,
                    },
                    direction: directionValue,
                });
                this.checkIsFixed(fieldValue, sortingRow);
                this._sortingFields = [
                    ...this._sortingFields,
                    sortingRow,
                ];
                this._breadcrumbSortingFields = _.cloneDeep(this._sortingFields);
            });
        }
        else {
            this._sortingFields = [];
            this._breadcrumbSortingFields = [];
        }
    };

    parseGroupingFields = (conditionData) => {
        if (conditionData && !_.isNil(conditionData.group_by)) {
            this.cleanGroupingFields(conditionData);
            this._isShowGrouping = true;
            const groups = Object.keys(conditionData.group_by);
            groups.forEach(groupKey => {
                const database_value = conditionData.group_by[groupKey].operator;
                let display_value = '';
                if (database_value){
                    const option = langStore.getConditionGroupOptions(database_value);
                    display_value = option && option.display_value;
                }
                const directionValue = {
                    database_value: database_value,
                    display_value: display_value,
                };
                const fieldValue = {
                    dot_walking_attribute: groupKey,
                };
                const existRow = this.hasRowInGrouping(fieldValue, directionValue);
                if (existRow) {
                    //Обновляем существующее условие
                    existRow._displayValue.display_column_name = conditionData.group_by[groupKey].display_column_name;
                    existRow._displayValue.is_dwa = conditionData.group_by[groupKey].is_dwa;
                    existRow._direction = directionValue;
                    this._breadcrumbGroupingFields = _.cloneDeep(this._groupingFields);
                    return;
                }
                this._keyGroupIndex++;
                const groupingRow = new GroupingRowState({
                    id: `grouping${ this._keyGroupIndex }`,
                    field: fieldValue,
                    displayValue: { // Перевод поля в зависимости от языка
                        display_column_name: conditionData.group_by[groupKey].display_column_name,
                        is_dwa: conditionData.group_by[groupKey].is_dwa,
                    },
                    direction: directionValue,
                });
                this.checkIsFixed(fieldValue, groupingRow);
                this._groupingFields = [
                    ...this._groupingFields,
                    groupingRow,
                ];
                this._breadcrumbGroupingFields = _.cloneDeep(this._groupingFields);
            });
        }
        else {
            this._groupingFields = [];
            this._breadcrumbGroupingFields = [];
        }
    };

    checkIsFixed = (fieldValue, row) => {
        if (this._fixedCondition && fieldValue.dot_walking_attribute && this._fixedCondition.includes(fieldValue.dot_walking_attribute)) {
            row.setDisabled(true);
        }
    };

    hasRowInSorting = (fieldValue, directionValue) => {
        const findRow = _.find(this._sortingFields, row => {
            return row.getField().dot_walking_attribute === fieldValue.dot_walking_attribute && row.getDirection() === directionValue;
        });
        return findRow;
    };

    hasRowInGrouping = (fieldValue, directionValue) => {
        const findRow = _.find(this._groupingFields, row => {
            return row.getField().dot_walking_attribute === fieldValue.dot_walking_attribute && row.getDirection().database_value === directionValue.database_value;
        });
        return findRow;
    };

    getLineValue = (line) => {
        const firstValue = line.values.length === 0 ? '' : line.values[0];
        return line.values.length > 1 ? line.values : firstValue;
    };

    setReorderFilterFields = (filteringRow, index) => {
        let newFields =
            typeof index !== 'undefined' ?
                [
                    ...this._filterFields.filter(row => row.getIndex() <= index),
                    filteringRow,
                    ...this._filterFields.filter(row => row.getIndex() > index),
                ] : [
                    ...this._filterFields,
                    filteringRow,
                ];
        newFields = _.map(newFields, (row, index) => {
            row.setIndex(index);
            return row;
        });
        return newFields.sort(this.compareFilterRows);
    };

    setLineBlock = async (parent, line, block, index, type, tableId, isAllRowDisabled) => {
        this._keyFilterIndex++;
        const fieldValue = typeof line.attribute === 'object' ? line.attribute : {
            dot_walking_attribute: line.attribute,
            condition_type: line.type,
        };
        const newValue = this.getLineValue(line);
        const filteringRow = new FilteringRowState({
            index,
            type: index === 0 ? 'AND' : type || parent.operator,
            block: block,
            id: `filter${ this._keyFilterIndex }`,
            field: fieldValue,
            operator: line.operator,
            value: newValue,
            operatorOptions: [],
            choiceOptions: [],
            disabled: isAllRowDisabled,
        });
        this.checkIsFixed(fieldValue, filteringRow);

        this._filterFields = this.setReorderFilterFields(filteringRow, index);
        await filteringRow.updateValueOpts(tableId);
        this._breadcrumbFilteringFields = _.cloneDeep(this._filterFields);
        // если есть dot_walking_attribute не делать лишний запрос, а подождать и сделать в filteringRow
        if (line?.attribute?.dot_walking_attribute.includes('.')) return;
        // в конец метода, чтобы не тормозило работу
        const choiceOptions = fieldValue && fieldValue.column_id
            ? await this.updateChoiceOptions(fieldValue, tableId)
            : [];
        filteringRow.setChoiceOptions(choiceOptions);
    };

    refreshBreadcrumbs() {
        this._breadcrumbFilteringFields = _.cloneDeep(this._filterFields);
        this._breadcrumbSortingFields = _.cloneDeep(this._sortingFields);
        this._breadcrumbGroupingFields = _.cloneDeep(this._groupingFields);
    }

    setInnerBlocks = (parent, block, tableId, counter, type, isAllRowDisabled) => {
        parent.operands.forEach((line, index) => {
            const localType = index === 0 && counter.index !== 0 ? type : parent.operator;
            if (!line.operands) {
                this.setLineBlock(parent, line, block, counter.index, localType, tableId, isAllRowDisabled);
            }
            if (line.operands) {
                this.setInnerBlocks(line, block, tableId, counter, localType, isAllRowDisabled);
            }
            counter.index++;
        });
    };

    getConditionString = () => {
        return getConditionString(this._filterFields, this._sortingFields, this._groupingFields);
    };

    isSorting = (id) => {
        return id.includes('sorting');
    };

    isGrouping = (id) => {
        return id.includes('grouping');
    };

    compareFilterRows = (row1, row2) => {
        if (row1.getBlock() < row2.getBlock()) {
            return -1;
        }
        if (row1.getBlock() > row2.getBlock()) {
            return 1;
        }
        if (row1.getIndex() < row2.getIndex()) {
            return -1;
        }
        if (row1.getIndex() > row2.getIndex()) {
            return 1;
        }
        return 0;
    };

    setSortingColumns = (columns) => {
        this._columns = columns;
    };

    sortingBreadcrumb = () => {
        const newBSortingFields = [];
        const nonSortingFields = [];
        this._columns.forEach(column => {
            const findBreadcrumb = this._breadcrumbSortingFields.find(row => row.getField().dot_walking_attribute === column.attribute);
            if (findBreadcrumb) {
                newBSortingFields.push(findBreadcrumb);
            }
        });
        this._breadcrumbSortingFields.forEach(breadcrumb => {
            const findBreadcrumb = newBSortingFields.find(row => row.getField().dot_walking_attribute === breadcrumb.getField().dot_walking_attribute);
            if (!findBreadcrumb) {
                nonSortingFields.push(breadcrumb);
            }
        });
        this._breadcrumbSortingFields = [
            ...newBSortingFields,
            ...nonSortingFields,
        ];
    };

    groupingBreadcrumb = () => {
        const newGroupingFields = [];
        const nonGroupingFields = [];
        this._columns.forEach(column => {
            const findBreadcrumb = this._breadcrumbGroupingFields.find(row => row.getField().dot_walking_attribute === column.attribute);
            if (findBreadcrumb) {
                newGroupingFields.push(findBreadcrumb);
            }
        });
        this._breadcrumbGroupingFields.forEach(breadcrumb => {
            const findBreadcrumb = newGroupingFields.find(row => row.getField().dot_walking_attribute === breadcrumb.getField().dot_walking_attribute);
            if (!findBreadcrumb) {
                nonGroupingFields.push(breadcrumb);
            }
        });
        this._breadcrumbGroupingFields = [
            ...newGroupingFields,
            ...nonGroupingFields,
        ];
    };

    findAndSetAddColumnsDotWalkList = async (columns) => {
        _.forEach(columns, column => {
            const values = column.attribute.split('.');
            if (values.length > 1) {
                this.searchAddDotWalkList(values);
            }
        });
    };

    searchAddDotWalkList = async (values) => {
        let compValue = values[0];
        let tempDotWalkList = [];
        const findLi = this._dotWalkList.find(li => li.dot_walking_attribute === compValue);
        tempDotWalkList = await this.fetchColumnsDotWalkList(findLi);
        if (values.length > 1) {
            for (let index = 1; index < values.length; index++) {
                compValue = compValue ? `${ compValue }.${ values[index] }` : values[0];
                const findLi = tempDotWalkList.find(li => li.dot_walking_attribute === compValue);
                if (findLi) {
                    tempDotWalkList = await this.fetchColumnsDotWalkList(findLi);
                }
            }
        }
    };

    fetchColumnsDotWalkList = async (li) => {
        if (!li?.referenced_table_id) {
            return [];
        }
        const params = {
            referenced_table_id: li.referenced_table_id,
            dot_walking_attribute: li.dot_walking_attribute,
        };
        const response = await fetchDotWalkList(params);
        const data = response.isOkStatus && response.data ? response.data : {};
        const items = data.items || data;
        this._columnsDotWalkList = [
            ...this._columnsDotWalkList,
            items,
        ];
        return items;
    };

    isEmpty = () => {
        if (_.isEmpty(this._filterFields) && _.isEmpty(this._sortingFields) && _.isEmpty(this._groupingFields)) {
            return true;
        }
        let isEmptyFilterFields = true;
        if (!_.isEmpty(this._filterFields)) {
            _.forEach(this._filterFields, row => {
                if (!_.isEmpty(row._field) || !_.isEmpty(row._operator) || !_.isEmpty(row._value)) {
                    isEmptyFilterFields = false;
                }
            });
        }
        let isEmptySortingFields = true;
        if (!_.isEmpty(this._sortingFields)) {
            _.forEach(this._sortingFields, row => {
                if (!_.isEmpty(row._field)) {
                    isEmptySortingFields = false;
                }
            });
        }
        let isEmptyGroupingFields = true;
        if (!_.isEmpty(this._groupingFields)) {
            _.forEach(this._groupingFields, row => {
                if (!_.isEmpty(row._field)) {
                    isEmptyGroupingFields = false;
                }
            });
        }
        return isEmptyFilterFields && isEmptySortingFields && isEmptyGroupingFields;
    }
}


export const conditionStateClass = Condition;

export default new Condition();
