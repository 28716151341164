import { action, observable, makeObservable, runInAction } from 'mobx';
import { SubFormStateType } from 'types/components/subForm';
import SimpleForm from 'ui-actions-scripts/simple-form';
import { fetchRecord, fetchRecordUiActions } from 'actions/record';
import { generateRandomString } from 'helpers/data';

class SubFormState implements SubFormStateType {
    private _isShow = false;
    private _tableName = '';
    private _title = '';
    private _sysId = '';
    private _recordData = null;
    private _actionsData = null;
    private _isLoading = false;
    private _isServicePortal = false;
    private _columnId: string | null = null;
    private _columnName = '';
    private _showAttachPopup = false;
    private _attachmentsLength = 0;
    private _editCell = null;
    private _editField = null;
    private _dynamicFormId = '';

    constructor(tableName = '', sysId = '') {
        makeObservable(this, {
            _isShow: observable,
            _tableName: observable,
            _title: observable,
            _sysId: observable,
            _recordData: observable,
            _actionsData: observable,
            _isLoading: observable,
            _isServicePortal: observable,
            _columnId: observable,
            _columnName: observable,
            _showAttachPopup: observable,
            _attachmentsLength: observable,
            _editCell: observable,
            _editField: observable,
            _dynamicFormId: observable,
            setEditCell: action,
            setColumnId: action,
            setDynamicFormId: action,
        });

        runInAction(() => {
            this._tableName = tableName;
            this._sysId = sysId;
            this._dynamicFormId = generateRandomString();
        });
    }

    getIsShow(): boolean {
        return this._isShow;
    }

    setIsShow(value: boolean) {
        this._isShow = value;
    }

    getIsLoading(): boolean {
        return this._isLoading;
    }

    setIsLoading(value: boolean) {
        this._isLoading = value;
    }

    getTableName(): string {
        return this._tableName;
    }

    setTableName(value: string) {
        this._tableName = value;
    }

    getColumnName(): string {
        return this._columnName;
    }

    setColumnName(value: string) {
        this._columnName = value;
    }

    getIsServicePortal(): boolean {
        return this._isServicePortal;
    }

    setIsServicePortal(value: boolean) {
        this._isServicePortal = value;
    }

    getSysId(): string {
        return this._sysId;
    }

    setSysId(value: string) {
        this._sysId = value;
    }

    getRecordData(): any {
        return this._recordData;
    }

    getActionsData(): any {
        return this._actionsData;
    }

    getShowAttachPopup(): boolean {
        return this._showAttachPopup;
    }

    setShowAttachPopup(value: boolean) {
        this._showAttachPopup = value;
    }

    getAttachmentsLength(): number {
        return this._attachmentsLength;
    }

    setAttachmentsLength(value: number) {
        this._attachmentsLength = value;
    }

    getTitle(): string {
        return this._title;
    }

    setTitle(value: string) {
        this._title = value;
    }

    getEditCell(): any {
        return this._editCell;
    }

    setEditCell(value: any) {
        this._editCell = value;
    }

    getEditField(): any {
        return this._editField;
    }

    setEditField(value: any) {
        this._editField = value;
    }

    getColumnId(): string | null {
        return this._columnId;
    }

    setColumnId(value: string | null) {
        this._columnId = value;
    }

    getDynamicFormId(): any {
        return this._dynamicFormId;
    }

    setDynamicFormId(value: any) {
        this._dynamicFormId = value;
    }

    async fetchData({
                      tableName,
                      sysId,
                      view = null,
                      uiActions: isUIActions,
                      isServicePortal,
                  }) {
        if (!tableName) {
            return;
        }
        this._isLoading = true;
        const fetch = async ({ table, sysId, view = null, isServicePortal, isUIActions = true }) => {
            let params = {
                open_first_rel_list: 0,
                is_service_portal: isServicePortal ? 1 : 0,
                is_widget: 1,
            };
            if (view) {
                params['form_view'] = view;
            }

            const fetchUIActions = isUIActions ? fetchRecordUiActions : async (): Promise<any> => {};
            const [record, action] = await Promise.all([
                fetchRecord(table, sysId, params),
                fetchUIActions(table, sysId, params),
            ]);
            return {
                recordData: record.data,
                actionsData: action.data,
            };
        };

        if ((window as any).s_form) {
            (window as any).s_form.modalForm = new SimpleForm(tableName, sysId || undefined, '', 'subForm', this.getDynamicFormId());
        } else {
            (window as any).s_form = {
                modalForm: new SimpleForm(tableName, sysId || undefined, '', 'subForm', this.getDynamicFormId()),
            };
        }
        const { recordData, actionsData } = await fetch({
            table: tableName,
            sysId: sysId,
            view: view,
            isServicePortal,
            isUIActions,
        });

        this._recordData = recordData;
        this._actionsData = actionsData;
        this._isLoading = false;
    }

    clear() {
        this._isShow = false;
        this._title = '';
        this._sysId = '';
        this._recordData = null;
        this._actionsData = null;
        this._isLoading = false;
        this._isServicePortal = false;
        this._showAttachPopup = false;
        this._attachmentsLength = 0;
    }
}

export const subFormState = SubFormState;

export default new SubFormState();
