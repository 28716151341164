/**
 * Описание: Список workflow
 * Для выбора workflow из списка
 * Параметры:
 * workflows - список workflow
 * loadWorkflow - метод для загрузки workflow
 * */
import * as React from 'react';
import styles from './styles.module.scss';
import IconWorkflow from 'assets/img/icons/workflow.svg';
import IconCheckedOutWorkflow from 'assets/img/icons/external-link.svg';
import userState from 'globalState/user';
import langStore from 'globalState/lang';

export default class WorkflowList extends React.Component {

    constructor(props) {
        super(props);
    }

    getHint = (sys_id, name) => {
        const { user } = userState;
        let hint;
        const { workflow_titles } = langStore.getTranslate();
        if (sys_id) {
            if (user.sys_id === sys_id) {
                hint = workflow_titles && workflow_titles.checked_out_by_me;
            }
            else {
                hint = workflow_titles && workflow_titles.checked_out_by + ' ' + name;
            }
        }
        else {
            hint = '';
        }
        return hint;
    };

    componentDidCatch(error, info) {
        console.error(error, info);
    }

    render() {
        return (
            <div className={ styles.WorkflowList }>
                <div className={ styles.WorkflowListMenu }>
                    {
                        this.props.workflows.map((item) => {
                            return <div key={ item.sys_id }
                                        className={ styles.WorkflowListMenuItem }
                                        onClick={ () => this.props.loadWorkflow(item.sys_id) }
                                        title={ this.getHint(item.checked_out_by, item.checked_out_by_display_value) }>
                                { item.checked_out_by ? <span className={ `${ styles.Icon } ${ styles.IconCheckedOutWorkflow }` } dangerouslySetInnerHTML={ { __html: IconCheckedOutWorkflow } } />
                                    : <span className={ `${ styles.Icon } ${ styles.IconWorkflow }` } dangerouslySetInnerHTML={ { __html: IconWorkflow } } />
                                }
                                <span>{ item.name }</span>
                            </div>;
                        })
                    }
                </div>
            </div>
        );
    }
}