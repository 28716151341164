import BaseFormFieldModel from 'components/dynamicForms/model/field/BaseFormFieldModel';

import CodeMirrorModel from 'components/dynamicForms/model/field/CodeMirrorModel';
import DocumentIdModel from 'components/dynamicForms/model/field/DocumentIdModel';
import FieldNameModel from 'components/dynamicForms/model/field/FieldNameModel';
import StringInputModel from 'components/dynamicForms/model/field/StringInputModel';
import TextAreaModel from 'components/dynamicForms/model/field/TextAreaModel';
import ImageModel from 'components/dynamicForms/model/field/ImageModel';
import SelectModel from 'components/dynamicForms/model/field/SelectModel';
import CheckboxModel from 'components/dynamicForms/model/field/CheckboxModel';
import RichTextModel from 'components/dynamicForms/model/field/RichTextModel';
import DateTimeInputModel from 'components/dynamicForms/model/field/DateTimeInputModel';
import ReferenceModel from 'components/dynamicForms/model/field/ReferenceModel';
import ListModel from 'components/dynamicForms/model/field/ListModel';
import TemplateModel from 'components/dynamicForms/model/field/TemplateModel';
import ColorModel from 'components/dynamicForms/model/field/ColorModel';
import ConditionsModel from 'components/dynamicForms/model/field/ConditionsModel';
import DaysOfWeekModel from 'components/dynamicForms/model/field/DaysOfWeekModel';
import DurationModel from 'components/dynamicForms/model/field/DurationModel';
import URLModel from 'components/dynamicForms/model/field/URLModel';
import HtmlInputModel from 'components/dynamicForms/model/field/HtmlInputModel';
import WidgetModel from 'components/dynamicForms/model/field/WidgetModel';

export default {
    unavailable: BaseFormFieldModel,
    string: StringInputModel,
    varchar: StringInputModel,
    translated_text: TextAreaModel,
    password: StringInputModel,
    integer: StringInputModel,
    smallinteger: StringInputModel,
    biginteger: StringInputModel,
    float: StringInputModel,
    decimal: StringInputModel,
    percent_complete: StringInputModel,
    char: StringInputModel,
    json: StringInputModel,
    image: ImageModel,
    phone: StringInputModel,
    enum_form_split_element_type: StringInputModel,
    text: TextAreaModel,
    journal_input: TextAreaModel,
    choice: SelectModel,
    boolean: CheckboxModel,
    date: DateTimeInputModel,
    datetime: DateTimeInputModel,
    datetime_specific: DateTimeInputModel,
    time: DateTimeInputModel,
    timestamp: DateTimeInputModel,
    days_of_week: DaysOfWeekModel,
    url: URLModel,
    reference: ReferenceModel,
    list: ListModel,
    script: CodeMirrorModel,
    id: DocumentIdModel,
    html: HtmlInputModel,
    duration: DurationModel,
    conditions: ConditionsModel,
    field_name: FieldNameModel,
    encrypted_password: StringInputModel,
    widget: WidgetModel,
    template: TemplateModel,
    color: ColorModel,
    record_class: ReferenceModel,
    rich_text: RichTextModel,
};
