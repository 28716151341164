import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';

import styles from './styles.module.scss';
import apiRequest from 'lib/apiRequest';

class AutoSuggest extends React.Component {
    suggested;
    values;
    active;
    term;
    refContainer = React.createRef();
    timeout = null;

    constructor(props) {
        super(props);

        makeObservable(this, {
            suggested: observable,
            values: observable,
            active: observable,
            term: observable,
        });

        this.active = {
            item: null,
            index: null,
        };
        this.suggested = [];

        if (this.props.term)  {
            this.setTerm();
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.term !== this.props.term) {
            this.setTerm();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    handleClickOutside = (e) => {
        const { current: el } = this.refContainer;

        if (!el) return false;

        if (!el.contains(e.target)) {
            this.suggested = [];
        }
    };

    handleKeyDown = (e) => {
        if (this.suggested.length === 0) return;

        // клавиши вверх/вниз
        if (e.keyCode === 40 || e.keyCode === 38) {
            e.preventDefault();

            const change = e.keyCode === 40 ? 1 : -1;
            let nextIndex;

            if (this.active.index !== null) {
                if (this.active.index + change < 0) {
                    nextIndex = this.suggested.length - 1;
                }
                else if (this.active.index + change > this.suggested.length - 1) {
                    nextIndex = 0;
                }
                else {
                    nextIndex = this.active.index + change;
                }
            }
            else {
                nextIndex = 0;
            }

            this.active.index = nextIndex;
            this.active.item = this.suggested[nextIndex];
            this.values = JSON.parse(JSON.stringify(this.active.item));
        }

        // клавиша ввода
        if (e.keyCode === 13) {
            e.preventDefault();
            if (this.active.item !== null) {
                this.handleItemClick(this.active.item);
            }
        }

        // клавиша esc
        if (e.keyCode === 27) {
            e.preventDefault();
            this.active = {
                item: null,
                index: null,
            };
            this.suggested = [];
        }
    };

    setTerm = () => {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.getData();
        }, 300);
    };

    handleItemClick(item) {
        this.values = item;
        this.suggested = [];
        this.active = {
            item: null,
            index: null,
        };

        this.props.updateData(item);
    }

    handleItemMouseEnter(item, index) {
        this.active = {
            item: item,
            index: index,
        };
    }

    handleItemMouseLeave() {
        this.active = {
            item: null,
            index: null,
        };
    }

    async getData() {
        const { fetchCondition } = this.props;
        this.active = {
            item: null,
            index: null,
        };
        const { reference_id, term, reference_qualifier_condition } = this.props;
        if (!reference_id || !term) {
            return;
        }
        let condition = reference_qualifier_condition;
        if (fetchCondition) {
            condition = await fetchCondition();
        }
        const params = {
            reference_id,
            term,
        };
        if (condition) {
            params.reference_qualifier_condition = condition;
        }
        const response = await new apiRequest(`GET /autosuggest/reference/`).qs(params).send();

        this.suggested = response.getData();
    }

    renderList() {
        if (this.suggested.length === 0) return null;

        const items = this.suggested.map((item, index) => {
            const classList = [ styles.item ];
            if (item === this.active.item) {
                classList.push(styles.active);
            }
            return <div key={ index }
                        onMouseEnter={ () => this.handleItemMouseEnter(item, index) }
                        onMouseLeave={ () => this.handleItemMouseLeave() }
                        onClick={ () => this.handleItemClick(item) }
                        className={ classList.join(' ') }>
                { item.display_value }
            </div>;
        });

        return <div className={ styles.list }>
            { items }
        </div>;
    }

    render() {
        return (
            this.props.term ? <div className={ `${ this.props.usedByList ? styles.listContainer : styles.input_container }` } ref={ this.refContainer }>
                { this.renderList() }
            </div> : null
        );
    }
}

export default observer(AutoSuggest);
