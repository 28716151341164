import * as React from 'react';
import { observer } from 'mobx-react';

import styles from 'components/dynamicForms/view/form/styles.module.scss';

import Activities from 'components/activities';
import FieldFactory from 'components/dynamicForms/view/form/fieldFactory';
import FormSectionModel from 'components/dynamicForms/model/FormSectionModel';
import FormsState from 'globalState/forms';
import _ from 'lodash';

const TYPE_ACTIVITIES = 'Activities (filtered)';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { beforeUnload } from 'helpers/form';
import Heading from 'components/heading';
import activityFeeds from 'globalState/activityFeeds';
import { FormSectionProps } from 'types/components/dynamicForms/formSection';


export const FormSection = observer(class FormSection extends React.Component<FormSectionProps>{
    /**
     * модель формы
     * @type FormSectionModel
     */
    model;

    constructor(props: FormSectionProps) {
        super(props);
        if (this.props.model) {
            this.model = this.props.model;
        }
        else {
            this.model = new FormSectionModel(this.props);
        }
    }


    componentDidMount() {
        this.model.setGlobalForms();

        window.addEventListener('beforeunload', beforeUnload);
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props.fields, prevProps.fields)) { //если изменился набор полей
            this.model.setData(this.props);
        } else if (!_.isEqual(this.props, prevProps)) {
            this.model.mergeData(this.props, ['elements']);
        }
        const dynamicFormId = this.model.parentFormModel?.id || this.model.parentFormId || this.model.id;
        const sectionState = FormsState.getSection(dynamicFormId, this.model.id);
        if (!sectionState) {
            this.model.setGlobalForms();
        }
    }

    componentWillUnmount() {
        const { id, parentFormModel, parentFormId } = this.model;
        const dynamicFormId = parentFormModel?.id || parentFormId || id;
        FormsState.deleteSection(dynamicFormId, id);
        FormsState.clearChangedFields(id);


        window.removeEventListener('beforeunload', beforeUnload);
    }

    disableUiActionOnSaveForm = (condition) => {
        if (this.props.disableUiActionOnSaveForm) {
            this.props.disableUiActionOnSaveForm(condition);
        }
    };

    /**
     *
     * @param field {BaseFormFieldModel}
     * @param id
     * @param isActivityWidget
     * @returns {*}
     */
    getFormFieldNode = (field, id, isActivityWidget) => {
        const { name } = this.model;
        const { className, formName, widgetId } = this.props;
        if (isActivityWidget && field.column_type === 'journal_input' && this.model.sysId) {
            return null;
        }
        field.parentFormSectionModel = this.model;
        field.forReference = this.model.forReference;
        field.formName = formName;
        field.widgetId = widgetId;

        return (
            <div className={ styles.field } key={ `${ name }-${ id }-${ field.name}` }>
                <FieldFactory
                    id={ id }
                    className={ className }
                    onChange={ this.props.onChangeField }
                    model={ field }
                    formName={ formName }
                    widgetId={ widgetId }
                />
            </div>
        );
    };

    getActivitiesComponent = () => {
        const sysId = this.model.sysId;
        const tableName = this.model.getTableName();

        return (
            // @ts-ignore
            <div key={ sysId } className={ styles.field }><Activities essence={ tableName } recordId={ sysId } /></div>
        );
    };

    /**
     *
     * @param cols
     * @param key
     * @param title
     * @param isActivityWidget
     * @returns {*}
     */
    getFieldsRow = (cols, key, title, isActivityWidget) => {
        return (
            <div key={ `row_${ key }` }>
                {
                    this.model.isServicePortal && title &&
                    <Heading size={ 'h3' } className={ styles.title } noMargin isBold data-test={ ATTRIBUTES.splitHeading }>{ title }</Heading>
                }
                <div key={ `split_block_${ key }` } className={ styles.row } data-test={ `${ ATTRIBUTES.splitRow }` }>
                    {
                        _.map(cols, (col, index) => {
                            return (
                                <div key={ `split_block_${ key }-${ index }` } style={{ width: `${ 100/cols.length }%`}} className={ styles.col } data-test={ `${ ATTRIBUTES.splitCol }` }>
                                    { col.map((field, index) => {
                                        if (field.rows && Array.isArray(field.rows)) {
                                            return this.getFieldsRow(field.rows, index, field.title, isActivityWidget);
                                        }
                                        return this.getFormFieldNode(field, index, isActivityWidget);
                                    }) }
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    };

    isActivityFeedWidget = () => {
        if (!this.model.getSysId()) {
            return false;
        }
        return this.model.getIsActivityFeed() || activityFeeds.hasItem(this.model.getTableName(), this.model.getSysId());
    };

    getNodes = (elements) => {
        const isActivityWidget = this.isActivityFeedWidget();
        return _.map(elements, (field, key) => {
            if (field.rows && Array.isArray(field.rows)) {
                return this.getFieldsRow(field.rows, key, field.title, isActivityWidget);
            }
            if (isActivityWidget && field.column_type === 'journal_input' && this.model.sysId) {
                return null;
            }
            if (field.formatter === TYPE_ACTIVITIES && !isActivityWidget) {
                return this.getActivitiesComponent();
            }
            return this.getFormFieldNode(field, key, isActivityWidget);
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.model.save();
    };

    render(){
        let classes: string[] = [];
        if (this.props.className && this.props.className) {
            classes.push(this.props.className);
        }
        const nodes = this.getNodes(this.model.getFormattedElements());
        let formOutput;
        if (this.model.remId !== undefined) {
            formOutput = <>{ nodes }</>; //отображение атрибутов модели REM
        }
        else {
            formOutput = (
                <form key={ this.model.getFormId() } className={ classes.join(' ') } ref={ this.model.ref } onSubmit={ this.handleSubmit } data-test={ ATTRIBUTES.form }>
                    { nodes }
                </form>
            );
        }
        return formOutput;
    }
});
