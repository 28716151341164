import {
    Activity,
    ActivityStateProps,
    ActivityType,
    CommentTextTab,
    HistoryField,
} from 'types/components/activityFeed';
import * as _ from 'lodash';
import formsState from 'globalState/forms';
import { BaseFormFieldType } from 'types/components/dynamicForms/baseFormField';
import { MaskedInputModelTypes } from 'types/components/dynamicForms/maskInput';

export const getFilteredTypeTabs = (activityTypes: ActivityType[], tableName, sysId): CommentTextTab[] => {
    const filteredTypes = _.filter(activityTypes, activityType => activityType.name !== 'all' && activityType.enable_create);
    const mandatoryTypes: string[] = [];
    const form = formsState.getDynamicFormBySysId(tableName, sysId);
    const typeTabs: CommentTextTab[] = _.map(filteredTypes, (type: ActivityType, index: number) => {
        if (form) {
            const journalField = _.find(form.getFields(), (field: BaseFormFieldType) => field.columnId === type.journal_input_column_id);
            if (journalField) {
                journalField.isMandatory = type.mandatory;
                (journalField as MaskedInputModelTypes).is_mandatory = type.mandatory;
            }
        }
        if (type.mandatory) {
            mandatoryTypes.push(type.journal_input_column_id);
        }
        return {
            id: type.sys_id || '',
            column_name: type.column_name,
            journalInputId: type.journal_input_column_id,
            comment: '',
            isRequired: type.mandatory,
            title: type.title,
            name: type.name,
            isActive: index === 0,
            isReadOnly: false,
        };
    });
    return _.isEmpty(mandatoryTypes)
        ? typeTabs
        : _.map(typeTabs, tab => {
            return { ...tab, isActive: tab.journalInputId === mandatoryTypes[0] };
        });
};

export const getFilteredHistoryFields = (activityState: ActivityStateProps) => {
    return _.isEmpty(activityState.getColumnFilter())
        ? activityState.getActiveHistoryMenuItems()
        : _.filter(activityState.getActiveHistoryMenuItems(), field =>
            activityState.getColumnFilter().includes(field.column_name));
};

export const getFilteredActivities = (
    activities: Activity[], activityState: ActivityStateProps): Activity[] => {
    const filteredHistoryFields: HistoryField[] = getFilteredHistoryFields(activityState);
    const journalFilter = activityState.getJournalFilter();
    return _.filter(activities, activity => {
        if ('operation' in activity.content) {
            if (!_.isEmpty(journalFilter) && !journalFilter.includes('history')) {
                return false;
            }
            const filteredItems = _.filter(activity.content.items, item =>
                !!_.find(filteredHistoryFields, filteredField => filteredField.column_name === item.column_name
                    && filteredField.checked)
            );
            if (filteredItems.length === 0) {
                return false;
            }
        }
        if ('message' in activity.content) {
            const filteredItems = _.filter(filterTypes(activityState), activityType => {
                if (activityType.name === 'all') {
                    return false;
                }
                const isActivityType = activity.type_id === activityType.sys_id || !activityType.sys_id;
                if (!_.isEmpty(journalFilter)) {
                    return isActivityType && !!_.find(journalFilter, activityTypeName => activityTypeName === activityType.name);
                }
                return isActivityType;
            });
            if (filteredItems.length === 0) {
                return false;
            }
        }
        return true;
    });
};

export const filterTypes = (activityState: ActivityStateProps) => {
    const journalFilter = activityState.getJournalFilter();
    const columnFilter = activityState.getColumnFilter();
    let types: ActivityType[] = [ ...activityState.getActivityTypes() ];
    if (_.isEmpty(journalFilter) && _.isEmpty(columnFilter)) {
        return types;
    }
    if (!_.isEmpty(journalFilter)) {
        const otherTypes = _.uniq(_.filter(types, type => [ 'all' ].includes(type.name)));
        // порядок
        const newTypes: ActivityType[] = [];
        _.forEach(journalFilter, (typeName: string) => {
            if (typeName === 'all') {
                return;
            }
            const findType: ActivityType | undefined = _.find(types, (type: ActivityType) => type.name === typeName
                || (!!type.column_name && `${ type.column_name }.${ activityState.getTableName() }` === typeName));
            if (findType) {
                newTypes.push(findType);
            }
        });
        types = [ ...otherTypes, ...newTypes ];
    }
    return [ ...types ];
};

export const getClassNameAF = (isNotStylized: boolean, defaultClassName = '', customClassName = ''): string => {
    const className = isNotStylized ? '' : defaultClassName;
    if (customClassName) {
        return `${ className } ${ customClassName }`;
    }
    return className;
};
