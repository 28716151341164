import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import styles from 'components/login/styles.module.scss';
import brandingState from 'globalState/branding/index';
import langStore from 'globalState/lang/index';
import FormServerErrors from 'components/formItems/serverErrors';
import SmartTitle from 'components/smartTitle';
import LoginInput from 'components/login/input';
import LoginButton from 'components/login/button';
import Select from 'components/login/select';
import Help from 'components/login/help';
import { fetchNewPassword } from 'actions/auth';
import FormValidator from 'helpers/formValidator';
import { helperRedirect } from 'helpers/history';
import AuthHOC from 'helpers/hoc/authHoc';
import {AuthPagesProps, FormFields} from "types/page/loginPage";

class SetNewPasswordPage extends React.Component<AuthPagesProps> {
    formFields: FormFields = {
        password: FormValidator.createFormFieldObj(),
        token: FormValidator.createFormFieldObj(),
    };
    serverError = '';

    constructor(props) {
        super(props);

        makeObservable(this, {
            formFields: observable,
            serverError: observable,
        });

        this.formFields.token.value = props.match.params.token;
    }

    // Validate form and submit
    validateAndSubmit = async () => {
        const { langData } = this.props;
        const fv = new FormValidator(this.formFields);
        const promises: Array<Promise<void>> = [];

        // Validate password
        promises.push(
            fv.validateField(this.formFields.password, () => {
                //if (val.length < 3) return `Password must contain not less than 3 symbols`;
            }),
        );

        // Await for validations
        await Promise.all(promises);

        const isValid = fv.isFieldsValid();

        if (!isValid) return false;

        try {
            const data = fv.getFieldsData();
            const language = langData.langValue;
            const { isOkStatus } = await fetchNewPassword({ language }, data);
            if (isOkStatus) {
                // Success
                helperRedirect('/');
            }
        }
        catch (e) {
            const errorsParsed = fv.applyServerValidationErrors(e.response.data);
            if (!errorsParsed) this.serverError = fv.serverErrorMessage || e.message;
        }
    };

    handleSwitchLang = (lang) => {
        const { onFetchTranslate } = this.props;

        this.serverError = '';
        for (const field in this.formFields) {
            if (this.formFields.hasOwnProperty(field)) {
                this.formFields[field].errorMessage = '';
            }
        }
        if (onFetchTranslate) {
            onFetchTranslate(lang);
        }
    };

    handleValueChange = (e) => {
        const { name, value } = e.target;
        this.formFields[name].value = value;
    };

    render() {
        const { new_password_page, placeholders, help } = langStore.getTranslate();
        const { langData } = this.props;
        return (
            <div className={ styles.Form }>
                <SmartTitle hidden>{ new_password_page?.title }</SmartTitle>
                <Select
                    onChange={ this.handleSwitchLang }
                    databaseValue={ langData.langValue }
                    options={ langData.langSpecial }
                />
                <Help title={ help?.title } children={ help?.content } />
                <div className={ styles.Logo }>
                    <img src={ brandingState.getPlatformLogo() } />
                </div>
                <div className={ styles.TextField }>{ new_password_page?.text }</div>
                <div className={ styles.Field }>
                    <LoginInput
                        placeholder={ placeholders?.password }
                        type="password"
                        name="password"
                        onChange={ this.handleValueChange }
                        msg={ this.formFields.password.errorMessage }
                        autoFocus
                    />
                </div>
                <div className={ styles.Field }>
                    <LoginButton
                        onClick={ this.validateAndSubmit }
                        disabled={ !this.formFields.password.value }
                        buttonType='primary'
                    >
                        { new_password_page?.submit_button }
                    </LoginButton>
                </div>
                <FormServerErrors msg={ this.serverError } />
            </div>
        );
    }
}

export const SetNewPasswordComponent = SetNewPasswordPage;
export default AuthHOC(observer(SetNewPasswordPage));
