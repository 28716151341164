export const templateTypes = [
    {
        type: 'list',
        show_element: 'list',
    },
    {
        type: 'reference',
        show_element: 'reference',
    },
    {
        type: 'field_name',
        show_element: 'text',
    },
    {
        type: 'numeric',
        show_element: 'text',
    },
    {
        type: 'decimal',
        show_element: 'text',
    },
    {
        type: 'precent_decimal',
        show_element: 'text',
    },
    {
        type: 'float',
        show_element: 'text',
    },
    {
        type: 'smallinteger',
        show_element: 'text',
    },
    {
        type: 'integer',
        show_element: 'text',
    },
    {
        type: 'biginteger',
        show_element: 'text',
    },
    {
        type: 'percent_complete',
        show_element: 'text',
    },
    {
        type: 'boolean',
        show_element: 'select',
    },
    {
        type: 'choice',
        show_element: 'select',
    },
    {
        type: 'string',
        show_element: 'text',
    },
    {
        type: 'text',
        show_element: 'multiText',
    },
    {
        type: 'datetime',
        show_element: 'datetime',
    },
    {
        type: 'datetime_specific',
        show_element: 'datetime',
    },
    {
        type: 'date',
        show_element: 'date',
    },
    {
        type: 'time',
        show_element: 'time',
    },
    {
        type: 'timestamp',
        show_element: 'timestamp',
    },
    {
        type: 'script',
        show_element: 'multiText',
    },
    {
        type: 'html',
        show_element: 'multiText',
    },
    {
        type: 'id',
        show_element: 'documentId',
    },
    {
        type: 'translated_text',
        show_element: 'multiText',
    },
    {
        type: 'char',
        show_element: 'text',
    },
    {
        type: 'keywords',
        show_element: 'text',
    },
    {
        type: 'journal_input',
        show_element: 'text',
    },
    {
        type: 'url',
        show_element: 'text',
    },
    {
        type: 'phone',
        show_element: 'text',
    },
    {
        type: 'duration',
        show_element: 'text',
    },
    {
        type: 'color',
        show_element: 'text',
    },
    {
        type: 'days_of_week',
        show_element: 'text',
    },
    {
        type: 'password',
        show_element: 'text',
    },
    {
        type: 'json',
        show_element: 'text',
    },
];
