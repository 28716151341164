import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, reaction, makeObservable } from 'mobx';
import moment from 'moment-timezone';
import styles from './styles.module.scss';
import Button from 'components/button';
import langStore from 'globalState/lang';
import DatePickerHeader from 'components/dateTimePicker/datePickerHeader';
import DatePickerDaysRow from 'components/dateTimePicker/datePickerDaysRow';
import DatePickerRow from 'components/dateTimePicker/datePickerRow';
import DatePickerTime from 'components/dateTimePicker/datePickerTime';
import DatePickerDayModel from 'components/dateTimePicker/datePickerDayModel';
import { getUserTimezone } from 'helpers/getUserTimeZone';
import { ATTRIBUTES } from 'constants/attributesForTests';

const WEEK = 7;


/**
 * Описание: Датапикер
 *
 * Параметры:
 * @param {string} visibleDay - выбранный день
 * @param {string} [beginDate] - начальная дата
 * @param {string} [endDate] - конечная дата
 * @param {string} pickertype - тип датапикера
 * @method update - метод обновления
 * @method onClose - метод закрытия
 * @param {boolean} [isHideFooter]
 * @param {boolean} [readonly]
 */

class State {
    _date;

    constructor() {
        makeObservable(this, {
            _date: observable,
        });
    }

    set date(v) {
        switch (true) {
            case v instanceof moment:
                this._date = v;
                break;

            case v instanceof Date:
            case typeof v === 'string':
            case typeof v === 'number':
                this._date = moment.tz(v, getUserTimezone());
                break;
        }
    }


    get date() {
        return this._date;
    }
}


export const DateTimePicker = observer(class DateTimePicker extends React.Component {
    visibleDay = null;
    beginDate = null;
    endDate = null;
    preventUpdate = false;

    constructor(props) {
        super(props);

        makeObservable(this, {
            visibleDay: observable,
            beginDate: observable,
            endDate: observable,
        });

        this.state = new State;

        this.state.date = props.initialValue || new Date;
        this.visibleDay = this.state.date.clone();
        this.beginDate = this.getDate(props.beginDate);
        this.endDate = this.getDate(props.endDate);

        reaction(
            () => this.state.date,
            (date) => {
                if (this.props.update) {
                    if(this.preventUpdate) {
                        this.preventUpdate = false;
                        return;
                    }
                    this.props.update(date);
                }
                this.visibleDay = date;
            },
        );

        this.daysNames = moment.weekdaysMin(true);
    }

    componentDidUpdate(prevProps) {
        const { beginDate, endDate, initialValue } = this.props;
        if (beginDate !== prevProps.beginDate) {
            this.beginDate = this.getDate(beginDate);
        }
        if (endDate !== prevProps.endDate) {
            this.endDate = this.getDate(endDate);
        }
        if (initialValue !== prevProps.initialValue) {
            this.preventUpdate = true;
            this.state.date = this.getDate(initialValue);
            this.visibleDay = this.getDate(initialValue);
        }
    }

    getDate = (date) => {
        let result = null;
        if (!date) {
            return result;
        }
        switch (true) {
            case date instanceof moment:
                result = date;
                break;

            case date instanceof Date:
            case typeof date === 'number':
                result = moment(date);
                break;
            case typeof date === 'string':
                result = moment(date, 'YYYY-MM-DD');
                if (!result.isValid()) {
                    throw new Error(result.format('YYYY-MM-DD'));
                }
                break;
        }
        return result;
    };

    onApplyClick = () => {
        const { onClose } = this.props;
        if (onClose) {
            this.state.date = this.state.date.clone();
            onClose();
        }
    };

    toToday = (e) => {
        e.preventDefault();
        this.state.date = new Date;

        const { onClose } = this.props;
        if (onClose) {
            onClose();
        }
    };


    /**
     *
     * @param index
     * @returns {T[]}
     * @private
     */
    _getWeek(index) {
        return this.days.slice(index * WEEK, index * WEEK + WEEK);
    }


    /**
     *
     * @returns {Array}
     * @private
     */
    _calcDaysArray() {
        const start = this.visibleDay.clone().startOf('month').startOf('isoWeek');
        const end = this.visibleDay.clone().endOf('month').endOf('isoWeek');
        const days = [];

        while (start.unix() < end.unix()) {
            days.push(new DatePickerDayModel(start, this.state.date, this.visibleDay, this.beginDate, this.endDate));
            start.add(1, 'day');
        }

        return days;
    }

    renderFooter = (buttonType) => {
        const { data_picker_titles } = langStore.getTranslate();
        const { isHideFooter } = this.props;
        if (isHideFooter) {
            return null;
        }
        return (
            <div className={ styles.Footer }>
                <Button
                    buttonType={ buttonType }
                    className={ styles.FooterButton }
                    onClick={ this.onApplyClick }
                    data-test={ ATTRIBUTES.fieldDatePickerApplyButton }
                >
                    { data_picker_titles && data_picker_titles.apply }
                </Button>
            </div>
        );
    };

    render() {
        const { readonly, isHiddenNowBtn } = this.props;
        const { data_picker_titles } = langStore.getTranslate();
        this.days = this._calcDaysArray();

        let layout = null;

        if (this.props.pickertype === 'time') {
            layout = (
                <>
                    <div className={ styles.TimerWrap }>
                        { !readonly && <a
                            className={ styles.TodayButton }
                            onClick={ this.toToday }
                            data-test={ ATTRIBUTES.fieldDatePickerToToday }
                        >
                            { data_picker_titles && data_picker_titles.now }
                        </a> }
                        <DatePickerTime picker={ this } />
                    </div>
                    { !readonly && this.renderFooter() }
                </>
            );
        }
        else {
            layout = (
                <>
                    <div>
                        <DatePickerHeader picker={ this } />
                        <DatePickerDaysRow days={ this.daysNames } />
                        <div className={ styles.DaysWrap }>
                            {
                                (new Array(this.days.length / WEEK)).fill(null).map((v, i) => {
                                    return (
                                        <DatePickerRow
                                            key={ i }
                                            days={ this._getWeek(i) }
                                            picker={ this }
                                            beginDate={ this.beginDate }
                                            endDate={ this.endDate }
                                            rowIndex={ i }
                                        />
                                    );
                                })
                            }
                        </div>
                    </div>
                    { !readonly && !isHiddenNowBtn && this.props.pickertype !== 'date' ? <div className={ styles.TimerWrap }>
                        <a
                            className={ styles.TodayButton }
                            onClick={ this.toToday }
                            data-test={ ATTRIBUTES.fieldDatePickerToToday }
                        >
                            { data_picker_titles?.now }
                        </a>
                        <DatePickerTime picker={ this } />
                    </div> : this.props.pickertype !== 'date' ? <div className={ styles.BottomSpacer } /> : null }
                    { readonly && this.props.pickertype === 'date' && <div className={ styles.BottomSpacer } /> }
                    { !readonly && this.renderFooter('primary') }
                </>
            );
        }

        return (
            <div className={ `${ styles.DateTimePicker }` } data-test={ ATTRIBUTES.fieldDateTimePicker }>
                { layout }
            </div>
        );
    }
});
