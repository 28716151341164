export const ROUTES = {
    LOGIN: '/login',
    RESET_PASSWORD: '/reset-password',
    NEW_PASSWORD: '/new-password/:token',
    SIGNUP: '/signup',
    SIDE_DOOR: '/side-door',
    LOGOUT: '/logout',
    AUTH_SSO: '/auth-sso',
    SEARCH: '/search/:global_search_param',
    FORM_LAYOUT: "/form-layout/:table_name",
    LIST_LAYOUT: "/list-layout/:table_name",
    LIST_LAYOUT_PERSONAL: "/list-layout-personal/:table_name",
    LIST_LAYOUT_WITH_FORM: "/list-layout/:table_name/:form_id",
    RELATED_LIST_EDIT: "/related-list-edit/:record_id/:related_list_element_id",
    RELATED_LIST: "/related-list/:table_name",
    VISUAL: "/visual/dependency",
    SLAM_CHART: "/chart/slam-chart",
    HEAP_SELECT: "/heap-select/:table_name",
    ATTACHMENTS_ROUTE: "/attachments/:attachment_id",
    ATTACHMENTS: "/attachments",
    SP_NEW: "/sp_new/:portalSuffix?/:pageTemplatePathName?",
    V1_RECORD: "/v1/record/:table_name?/:number?",
    RECORD: "/record/:table_name/:number?",
    LIST: "/list/:table_name",
    DICTIONARY_ROUTE: "/dictionary/:table_name",
    DICTIONARY: "/dictionary",
    CHART: "/chart/:table_name/:column_name/:chart_type",
    WIDGET: "/widget",
    REPORT: "/report/:report_id?",
    WORKFLOW: "/workflow/:sys_id?",
    WORKFLOW_VIEWER: "/workflow-viewer/:essence/:record_id",
    URL_ACTION: "/url-action/run",
    UNTRANSLATED: "/untranslated",
    PORTAL_DESIGNER: "/portal-designer",
    LDAP: "/ldap/browse/:sys_id",
    TASK_BOARD: "/task-board/:id",
    PAGE: "/page/:pageTemplatePathName",
    PAGE_ID: "/page_id/:pageId",
    PORTAL_SUFFIX: "/:portalSuffix/:pageTemplatePathName",
    NOT_FOUND: '/404',
    FORBIDDEN: '/403',
    PAGE_TEMPLATE: "/:pageTemplatePathName",
} as const;