import { observable, makeObservable } from 'mobx';


class ComponentsUpdater {
    activitiesUpdater = 0;

    constructor() {
        makeObservable(this, {
            activitiesUpdater: observable,
        });
    }
}

const componentsUpdaterState = new ComponentsUpdater();

export default componentsUpdaterState;
