import * as React from 'react';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import styles from './styles.module.scss';
import SmartTitle from 'components/smartTitle';
import PageLoader from 'components/pageLoader';
import BurgerMenu from 'components/burgerMenu';
import langStore from 'globalState/lang';
import {fetchErrorPageData} from "actions/errorPage";
import {CODE_404_NOTFOUND, CODE_403_FORBIDDEN} from 'lib/apiRequest';
import {ATTRIBUTES} from 'constants/attributesForTests';
import {ButtonsType, ErrorPageProps, ErrorPageResponseData, TemplateData} from "types/page/ErrorPage";
import {Page403Titles, Page404Titles} from "types/translate/index";

class ErrorPage extends React.Component<ErrorPageProps> {
    data: ErrorPageResponseData;
    codeError: number;

    constructor(props) {
        super(props);

        makeObservable(this, {
            data: observable,
            codeError: observable,
        });
    }

    componentDidMount() {
        this.fetchData().catch(this.errorFetchData);
        const {codeError} = this.props;
        this.codeError = codeError || Number(location.pathname.split('/')[1]);
    }

    componentDidUpdate() {
        const {codeError} = this.props;
        this.codeError = codeError || Number(location.pathname.split('/')[1]);
    }

    fetchData = async () => {
        const {isOkStatus, data} = await fetchErrorPageData();
        if (isOkStatus) {
            this.data = data;
        }
    };

    errorFetchData = (e) => {
        console.error(e.message);
    };

    getTemplate = (): TemplateData | {} => {
        const {page_403_titles, page_404_titles} = langStore.getTranslate();
        const {service_portal_button, main_page_button} = this.data.buttons;

        switch (this.codeError) {
            case CODE_403_FORBIDDEN:
                return {
                    ...page_403_titles,
                    ...service_portal_button,
                };
            case CODE_404_NOTFOUND:
                return {
                    ...page_404_titles,
                    ...main_page_button,
                };
            default:
                return {};
        }
    };

    render() {
        if (!this.data) return <PageLoader />;
        const errorTemplate = this.getTemplate();
        const {pageTitle, burgerMenuItems, headerTitle = ''} = this.props;
        const page_title = pageTitle || (errorTemplate as Page404Titles | Page403Titles).page_title;
        return (
            <>
                {
                    (burgerMenuItems || headerTitle) &&
                    <div className={styles.FixedHeader}>
                        <div>
                            {
                                <BurgerMenu data={burgerMenuItems} isDisable={!burgerMenuItems || !burgerMenuItems.length}/>
                            }
                            <span className={styles.ViewTitle} dangerouslySetInnerHTML={{__html: headerTitle}}/>
                        </div>
                    </div>
                }
                <div className={ styles.Content }>
                    <SmartTitle hidden>{ (errorTemplate as Page404Titles | Page403Titles).page_title }</SmartTitle>
                    <div className={ styles.Screen } data-test={ ATTRIBUTES.errorScreen }>{ this.codeError }</div>
                    { page_title && <div className={ styles.Title } data-test={ ATTRIBUTES.errorTitle }>{ page_title }</div>}
                    { (errorTemplate as Page403Titles).page_text && <div className={ styles.Text }>{ (errorTemplate as Page403Titles).page_text }</div> }
                    <div className={ styles.LinkWrap } data-test={ ATTRIBUTES.errorLink }>
                        <Link to={ (errorTemplate as ButtonsType).url }>{ (errorTemplate as ButtonsType).name }</Link>
                    </div>
                </div>
            </>
        );
    }
}

export default observer(ErrorPage);
