import * as React from 'react';
import styles from './styles.module.scss';
import ActionsPanel from 'components/actionsPanel';
import TaskBoardLanes from 'components/taskBoard/taskBoardLanes';
import { observer } from 'mobx-react';
import SmartTitle from 'components/smartTitle';

/**
 * Компонент Доска задач
 * @param {Object} board - инфо по доске
 * @param {boolean} loadingCards
 */
class TaskBoard extends React.Component {
    renderActionsPanel = () => {
        const { board } = this.props;
        return (
            <ActionsPanel
                pageName={ board.taskboard_name }
                hideBurgerMenu
            />
        );
    };

    render() {
        const { board, loadingCards } = this.props;
        return (
            <div className={ styles.Container }>
                <SmartTitle hidden>{ board.taskboard_name }</SmartTitle>
                { this.renderActionsPanel() }
                <TaskBoardLanes
                    boardId={ board.sys_id }
                    tableName={ board.table_name }
                    loadingCards={ loadingCards }
                    viewName={ board.view_name }
                    canUpdate={board.update_access}
                    canCreate={board.create_access}
                />
            </div>
        );
    }
}

export default observer(TaskBoard);
