import { observable, action, makeObservable } from 'mobx';
import GroupDataState from 'globalState/table/groupData';
import langStore from 'globalState/lang';
import type { SelectedByList, IPagination, TableStateType } from 'types/components/table';
import type { TableColumns, TableGroupType } from 'types/components/table/groups';

class TableState implements TableStateType {
    private _name = '';
    private _columns: TableColumns = [];
    private _rows: any[] = [];
    private _pagination: IPagination = {
        total: 0,
        per_page: 20,
        page: 1,
    };
    private _groups: TableGroupType[] = [];
    private _isShowListGroups = false;
    private _isShowGroups = false;
    private _scrollLeft = 0;
    private _checked: Set<string> = new Set();
    private _selectedByList: SelectedByList[] = [];
    private _tableId = '';
    private _recordId = '';
    private _recordColumnName = '';

    constructor() {
        makeObservable(this, {
            _name: observable,
            _columns: observable,
            _rows: observable,
            _pagination: observable,
            _groups: observable,
            _isShowListGroups: observable,
            _isShowGroups: observable,
            _scrollLeft: observable,
            _checked: observable,
            _selectedByList: observable,
            setScrollLeft: action,
            setName: action,
            setRows: action,
            setPagination: action,
            setIsShowListGroups: action,
            setCellValue: action,
            changePaginationTotal: action,
            changePaginationPerPage: action,
            updateCell: action,
            clear: action,
            setColumns: action,
            deleteRow: action,
            setRowByID: action,
            setGroups: action,
            addGroup: action,
            setIsShowGroups: action,
            addChecked: action,
            deleteChecked: action,
            clearChecked: action,
            addSelectByList: action,
            setSelectByList: action,
        });
    }

    setScrollLeft = (value) => {
        this._scrollLeft = value;
    };

    getScrollLeft = () => {
        return this._scrollLeft;
    };

    setName = (name) => {
        this._name = name;
    };

    getName = () => {
        return this._name;
    }

    getRows = () => {
        return this._rows;
    };

    getRowsLength = () => {
        return this._rows.length;
    };

    setRows = (rows) => {
        this._rows = rows;
    };

    getPagination = () => {
        return this._pagination;
    };

    setPagination = (pagination) => {
        this._pagination = pagination;
    };

    getIsShowListGroups(): boolean {
        return this._isShowListGroups;
    }

    setIsShowListGroups(value: boolean) {
        this._isShowListGroups = value;
    }

    getIsShowGroups(): boolean {
        return this._isShowGroups;
    }

    setIsShowGroups(value: boolean) {
        this._isShowGroups = value;
    }

    getCellValue = (rowIndex, field) => {
        if (this._rows[rowIndex] && this._rows[rowIndex][field]) {
            return this._rows[rowIndex][field].value; // todo
        }
        return null;
    };

    setCellValue = (rowIndex, field, value) => {
        if (this._rows[rowIndex] && this._rows[rowIndex][field]) {
            this._rows[rowIndex][field].value = value; // todo
        }
    };

    updateCell = (rowIndex, field, value) => {
        if (this._rows[rowIndex] && this._rows[rowIndex][field]) {
            this._rows[rowIndex][field] = value;
        }
    };

    changePaginationTotal = (total: number) => {
        this._pagination = { ...this._pagination, total };
    };

    changePaginationPerPage = (perPage: number) => {
        this._pagination = { ...this._pagination, per_page: perPage };
    };

    getChecked = (): Set<string> => {
        return this._checked;
    }

    addChecked = (sysId) => {
        this._checked.add(sysId);
    }

    deleteChecked = (sysId) => {
        this._checked.delete(sysId);
    }

    clearChecked = () => {
        this._checked.clear();
    }

    addSelectByList = (item) => {
        this._selectedByList.push(item);
    }

    setSelectByList = (items) => {
        this._selectedByList = items;
    }

    getSelectByList = () => {
        return this._selectedByList;
    }

    clear = () => {
        this._rows = [];
        this._name = '';
        this._tableId = '';
        this._recordId = '';
        this._recordColumnName = '';
        this._columns = [];
        this._groups = [];
    };

    getTableId = () => {
        return this._tableId;
    };

    setTableId = (value) => {
        this._tableId = value;
    };

    getRecordId = () => {
        return this._recordId;
    };

    setRecordId = (recordId) => {
        this._recordId = recordId;
    };

    getRecordColumnName = () => {
        return this._recordColumnName;
    };

    setRecordColumnName = (recordColumnName) => {
        this._recordColumnName = recordColumnName;
    };

    getGroups(): TableGroupType[] {
        return this._groups;
    }

    setGroups(value: TableGroupType[]) {
        this._groups = value;
    }

    addGroup(value: TableGroupType) {
        this._groups = [...this._groups, value];
    }

    getColumns(): TableColumns {
        return this._columns;
    }

    setColumns(value: TableColumns) {
        this._columns = value;
    }

    deleteRow(id) {
        this._rows = this._rows.filter((row: any) => row.sys_id !== id);
    }

    setRowByID = (id, row) => {
        let index = this._rows.findIndex((row: any) => (row.sys_id === id));
        if (index >= 0) {
            this._rows[index] = row;
        }
    };

    updateGroupCells = (id, items) => {
        const findRow = items.find(el => el.sys_id === id);
        const tableGroups = this.getGroups();

        if (!findRow) {
            return;
        }

        tableGroups.forEach((group) => {
            const groupRows = group.getRows();
            const newRows = groupRows.map((el) => {
                if (findRow.sys_id === el.sys_id) {
                    return findRow;
                } else {
                    return el;
                }
            });
            group.setRows([...newRows]);
        });
    }

    getCurrentPagination = () => {
        return GroupDataState.getGroupsCount() > 0 ? {
            pageTotal: GroupDataState.getGroupsCount(),
            page: GroupDataState.getPage(),
            perPage: GroupDataState.getPerPage(),
            total: this._pagination?.total || 0,
            hiddenEntries: GroupDataState.haveHiddenEntries(),
            enabledPagesCalculation: this._pagination?.enabled_pages_calculation || false,
        } : {
            pageTotal: this._pagination?.total || 0,
            page: this._pagination?.page || 1,
            perPage: this._pagination?.per_page || 20,
            total: this._pagination?.total || 0,
            hiddenEntries: this._pagination?.hidden_entries || false,
            enabledPagesCalculation: this._pagination?.enabled_pages_calculation || false,
        };
    }

    isDisablePageCalculation = () => {
        const pagination = this.getCurrentPagination();
        const { enabledPagesCalculation, total, pageTotal } = pagination;
        if (total) {
            return !enabledPagesCalculation && total < 0;
        }
        return !enabledPagesCalculation && pageTotal < 0;
    }

    getTotals = () => {
        const { list_titles = {} } = langStore.getTranslate();
        const { pageTotal, total, enabledPagesCalculation } = this.getCurrentPagination();
        let totalString = '';
        let totalNumber = 0;
        if (this.isDisablePageCalculation()) {
            totalString = `${list_titles.total_items}: ${Math.abs(total ? total : pageTotal)}+`;
            totalNumber = Math.abs(total ? total : pageTotal);
        } else if (!this.getRowsLength() && !enabledPagesCalculation) {
            totalString = '';
        } else {
            totalString = `${list_titles.total_items}: ${total || pageTotal}`;
            totalNumber = total || pageTotal;
        }
        return {
            totalString,
            totalNumber,
        };
    }
}

export const tableStateClass = TableState;

export default new TableState();
