import * as React from 'react';
import { observer } from 'mobx-react';
import GlobalPortal from 'components/globalPortal';
import styles from './styles.module.scss';
import { ATTRIBUTES } from 'constants/attributesForTests';

/**
 *  Описание: Выпадашка
 *  Параметры:
 *  @param {ref} refParent - ссылка на элемент, относительно котрого позиционируется выпадашка
 *  @method getRef - метод который возвращает ссылку на выпадашку
 *  @param {boolean} floatRight - если надо позиционировать выпадашку относительно правого края элемента
 *  @param {boolean} [disableMinWidth] - запрещает установку минимальной ширины выпадашки равную ширине элемента, относительно котрого позиционируется выпадашка
 *  @param {string} [defaultDirection] - направление появления dropdown по умолчанию
 *  @param {string} [minWidth]
 *  @param {number} [offsetTop]
 *  @param {number} [offsetLeft]
 */

class DropDownComponent extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.setPosition();
    }

    componentDidUpdate() {
        this.setPosition();
    }

    isMiddle = () => {
        const { defaultDirection } = this.props;
        return defaultDirection && defaultDirection.includes('middle');
    };

    isRight = () => {
        const { defaultDirection } = this.props;
        return defaultDirection && defaultDirection.includes('right');
    };

    setPosition = () => {
        const { refParent, refDropdown, offsetTop = 0, offsetLeft = 0 } = this.props;
        const parentEl = refParent ? refParent.current : null;
        const dropEl = refDropdown ? refDropdown.current : null;
        if (!parentEl || !dropEl) return;

        const offset = 4;
        const { floatRight, disableMinWidth, minWidth } = this.props;
        const windowHeight = document.documentElement.clientHeight;
        const windowWidth = document.documentElement.clientWidth;
        let scrollTop = window.pageYOffset;
        const bodyStyle = document.body.style;

        // для случаев когда мы находимся внутри попапа на мобильных разрешениях
        if (!scrollTop && bodyStyle.position === 'fixed' && bodyStyle.top) {
            scrollTop = parseInt(bodyStyle.top) * -1;
        }

        const scrollLeft = window.pageXOffset;
        const parentRect = parentEl.getBoundingClientRect();
        if (!disableMinWidth) {
            dropEl.style.minWidth = (minWidth ? minWidth : parentRect.width) + 'px';
        }
        const dropRect = dropEl.getBoundingClientRect();

        // calculate top
        let top;
        if (this.isMiddle() && (parentRect.top + dropRect.height + offset < windowHeight)) {
            top = parentRect.top + scrollTop + offsetTop;
        }
        else if (parentRect.top + parentRect.height + dropRect.height + offset < windowHeight) {
            top = parentRect.top + scrollTop + parentRect.height + offset + offsetTop;
        }
        else if (parentRect.top > dropRect.height + offset) {
            top = parentRect.top + scrollTop - (dropRect.height + offset) + offsetTop;
        }
        else {
            const initTop = parentRect.top + parentRect.height + offset;
            top = initTop + scrollTop - (initTop + dropRect.height - windowHeight) - offset + offsetTop;
        }

        // calculate left
        let left;
        if (floatRight) {
            left = parentRect.left - (dropRect.width - parentRect.width) + scrollLeft + offsetLeft;
            if (left < 0) {
                left = parentRect.left + scrollLeft;
            }
            if (left + dropRect.width + offset > windowWidth) {
                left = parentRect.left - ((parentRect.left + dropRect.width + offset) - windowWidth) + scrollLeft + offsetLeft;
            }
        }
        else {
            left = parentRect.left + scrollLeft + offsetLeft;
            if (this.isRight() && (parentRect.left + dropRect.width * 2 + offset < windowWidth)) {
                left = parentRect.left + dropRect.width - offset * 2 + scrollLeft + offsetLeft;
            }
            if (parentRect.left + dropRect.width + offset > windowWidth) {
                left = parentRect.left - ((parentRect.left + dropRect.width + offset) - windowWidth) + scrollLeft + offsetLeft;
            }
        }

        // applying styles
        dropEl.style.left = left + 'px';
        dropEl.style.top = top + 'px';
        dropEl.style.position = 'absolute';
    };

    render() {
        return (
            <GlobalPortal>
                <div
                    className={ styles.Dropdown }
                    ref={ this.props.refDropdown }
                    data-test={ this.props['data-test'] ? this.props['data-test'] : ATTRIBUTES.dropdown }
                >
                    { this.props.children }
                </div>
            </GlobalPortal>
        );
    }
}

const ObserverComponent = observer(DropDownComponent);
export default React.forwardRef((props, ref) => <ObserverComponent { ...props } refDropdown={ ref } />);
