import apiRequest from 'lib/apiRequest';
import { handleResponse } from 'helpers/action';
import {HandleResponse} from "types/actions/main";
import {SelectionListResponse, SectionListParams} from "types/actions/portalDesigner";

/**
 *
 * @param sysId {string}
 * @returns {Promise<*>}
 */
export async function fetchPortalPageData(sysId: string): Promise<HandleResponse> {
    const response = await new apiRequest(`GET /page-by-id/${ sysId }`).send();
    return handleResponse(response);
}

/**
 *
 * @param requestOptions {object}
 * @returns {Promise<*>}
 */
export async function fetchSelectionList(requestOptions: SectionListParams): Promise<SelectionListResponse> {
    const response = await new apiRequest('GET /selection/list').qs(requestOptions).caching().send();
    return handleResponse(response);
}

/**
 *
 * @param table string
 * @param sysId string
 * @returns {Promise<*>}
 */
export async function fetchItemRecord(table: string, sysId: string): Promise<HandleResponse> {
    const response = await new apiRequest(`GET /record/${ table }/${ sysId }`).qs({open_first_rel_list: 0}).send();
    return handleResponse(response);
}

/**
 *
 * @param table string
 * @param sysId string
 * @param record {*}
 * @returns {Promise<*>}
 */
export async function saveItemRecord(table: string, sysId: string, record: any): Promise<HandleResponse> {
    const response = await new apiRequest(`POST /record/${ table }/${ sysId }`).sendJSON({ record });
    return handleResponse(response);
}

/**
 * Метод создает новый элемент
 * @param params {{
 *     name string              тип блока
 *     parent_id String         sys_id родительского блока
 *     sys_id string            sys_id текущего блока. Указывается при перетаскивание, null для нового
 *     next_record_id string    sys_id блока перед которым происходит вставка
 * }}
 *
 * @returns {Promise<*>}
 */
export async function fetchLayout(params): Promise<HandleResponse> {
    const response = await new apiRequest('PUT /portal/layout').qs(params).send();
    return handleResponse(response);
}


/**
 * Метод изменяет позицию элемента
 * @param params {{
 *     name string              тип блока
 *     parent_id String         sys_id родительского блока
 *     sys_id string            sys_id текущего блока. Указывается при перетаскивание, null для нового
 *     next_record_id string    sys_id блока перед которым происходит вставка
 * }}
 *
 * @returns {Promise<*>}
 */
export async function moveItem(params): Promise<HandleResponse> {
    const response = await new apiRequest('PUT /portal/move').qs(params).send();
    return handleResponse(response);
}

/**
 *
 * @param pathName адрес портала
 * @param params дополнительные параметры запроса
 * @returns {Promise<*>}
 */
export async function fetchPortal(pathName: string, params = {}): Promise<HandleResponse> {
    const response = await new apiRequest(`GET /portal/${pathName}`).qs(params).send();
    return handleResponse(response);
}
