import { showScriptInfo } from 'helpers/uiActionsHelper';
import { fetchAjaxRunScript, fetchAjaxAdminRunScript, fetchAjaxGetXML } from 'actions/ajax';

export default class SimpleAjax {
    constructor(script) {
        this.params = { 'sysparm_processor': script };
    }

    addParam(param, value) {
        this.params[param] = value;
    }

    async getXML(callback) {
        if (!this.params.sysparm_name) {
            return alert('`sysparm_name` param is required');
        }

        const response = await fetchAjaxGetXML(this.params);
        if (response && callback) {
            response.responseXML = response;
            callback(response);
        }
    }

    async runScript(value, tableName, callback) {
        const {
            isOkStatus,
            response,
        } = await fetchAjaxRunScript({ script: value });
        if (isOkStatus) {
            showScriptInfo(response, tableName);
            if (callback && typeof callback === 'function') {
                callback(response);
            }
        }
        else {
            callback();
        }
    }

    async runAdminScript(value, tableName, callback) {
        const {
            isOkStatus,
            response,
        } = await fetchAjaxAdminRunScript({ script: value });
        if (isOkStatus) {
            showScriptInfo(response, tableName);
            if (callback && typeof callback === 'function') {
                callback(response);
            }
        }
        else {
            callback();
        }
    }

    getXMLWait(callback) {
        this.getXML(callback);
    }
}

