/**
 * Описание: Класс Переход - элемент диаграммы рабочих процессов
 * Для редактирования свойств связи соединяющей активности
 * Параметры:
 * sys_id - идентификатор
 * start - объект начальной активности
 * exit - объект выхода начальной активности
 * end - объект конечной активности
 * selectObject - метод переключения текущего объекта (выделен/не выделен).
 * selected - выделена ли данная связь?
 * togglePropertiesPanel - открыть/закрыть панель свойств
 * readOnly - запрет изменения
 * state - выполнено ли прохождение связи (для режима viewer)
 * setActiveTransition - установить данную связь активной
 * isViewer - режим просмотра
 * */

import * as React from 'react';
import { Arrow, Group } from 'react-konva';

const selectedColor = '#5927d3';
const completedColor = '#05c270';
const regularColor = '#6E728F';
const pointerSize = 12; // размер стрелки связи

export default class Transition extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidCatch(error, info) {
        console.error(error, info);
    }

    getPath = (block1, exit, block2) => {
        // Определение точки старта
        const start = {
            x: block1.x + block1.width,
            y: block1.y + block1.height + exit.y + exit.height / 2,
        };
        //Определение точки конца линии
        let end = {},
            path = [];
        const margin = 16;

        if (start.x < block2.x - 2 * margin) { //выход А1 слева от А2
            end = {
                x: block2.x,
                y: block2.y + block2.height / 2,
            };
            //E - вход на левой грани A2
            //path - right, up/down, right
            path = [
                start,
                {
                    x: Math.max(start.x + (end.x - start.x) / 2, start.x + margin),
                    y: start.y,
                },
                {
                    x: Math.max(start.x + (end.x - start.x) / 2, start.x + margin),
                    y: end.y,
                },
                end,
            ];
        }
        else if (start.x < block2.x) { //прямая
            end = {
                x: block2.x,
                y: block2.y + block2.height / 2,
            };
            path = [
                start,
                end,
            ];
        }

        if (start.y < block2.y) { //выход А1 над А2
            if (start.x >= block2.x - 2 * margin && start.x <= block2.x + block2.width / 2 - margin) { //выход A1 между левой границей и серединой A2
                //S - вход на верхней грани A2
                //path - right, down
                end = {
                    x: block2.x + block2.width / 2,
                    y: block2.y,
                };
                path = [
                    start,
                    {
                        x: end.x,
                        y: start.y,
                    },
                    end,
                ];
            }
            if (start.x > block2.x + block2.width / 2 - margin) { //выход A1 правее середины A2
                end = {
                    x: block2.x + block2.width + 2,
                    y: block2.y + block2.height / 2,
                };
                //SW - вход на правой грани
                // path - right, down, left
                path = [
                    start,
                    {
                        x: Math.max(start.x + margin, end.x + margin),
                        y: start.y,
                    },
                    {
                        x: Math.max(start.x + margin, end.x + margin),
                        y: end.y,
                    },
                    end,
                ];
            }
        }
        if (start.y >= block2.y && start.y <= block2.y + block2.fact_height + margin) { //выход A1 между верхней и нижней границей A2
            if (start.x > block2.x + block2.width) { //выход A1 правее A2
                end = {
                    x: block2.x + block2.width / 2,
                    y: block2.y,
                };
                //W
                //path - right, up, left, down
                path = [
                    start,
                    {
                        x: start.x + margin,
                        y: start.y,
                    },
                    {
                        x: start.x + margin,
                        y: Math.min(block1.y - margin, end.y - margin),
                    },
                    {
                        x: end.x,
                        y: Math.min(block1.y - margin, end.y - margin),
                    },
                    end,
                ];
            }
        }
        if (start.y > block2.y + block2.fact_height + margin) { //выход A1 ниже от A2
            if (start.x >= block2.x - 2 * margin && start.x <= block2.x + block2.width / 2 - margin) { //выход A1 между левой границей и серединой A2
                //N - вход на нижней грани A2
                //path - right, up
                end = {
                    x: block2.x + block2.width / 2,
                    y: block2.y + block2.fact_height + 2,
                };
                path = [
                    start,
                    {
                        x: end.x,
                        y: start.y,
                    },
                    end,
                ];
            }
            if (start.x > block2.x + block2.width / 2 - margin) { //выход A1 правее A2
                if (block1.y - margin > block2.y + block2.height / 2) { // не мешает ли A1 повороту стрелки налево?
                    //NW  - вход на правой грани A2
                    // path - right, up, left
                    end = {
                        x: block2.x + block2.width + 2,
                        y: block2.y + block2.height / 2,
                    };
                    path = [
                        start,
                        {
                            x: Math.max(start.x + margin, end.x + margin),
                            y: start.y,
                        },
                        {
                            x: Math.max(start.x + margin, end.x + margin),
                            y: end.y,
                        },
                        end,
                    ];
                }
                else {
                    end = {
                        x: block2.x + block2.width / 2,
                        y: block2.y - 1,
                    };
                    //W - вход на верхней грани A2
                    //path - right, up, left, down
                    path = [
                        start,
                        {
                            x: start.x + margin,
                            y: start.y,
                        },
                        {
                            x: start.x + margin,
                            y: Math.min(block1.y - margin, end.y - margin),
                        },
                        {
                            x: end.x,
                            y: Math.min(block1.y - margin, end.y - margin),
                        },
                        end,
                    ];
                }
            }
        }

        const points = [];
        path.forEach(elem => {
            points.push(elem.x);
            points.push(elem.y);
        });
        return points;
    };

    handleClick = (evt) => {
        this.props.selectObject('wf_transition', this.props.sys_id);
        evt.cancelBubble = true;
    };

    handleDblClick = (evt) => {
        this.props.selectObject('wf_transition', this.props.sys_id, true); // select & show properties
        this.props.togglePropertiesPanel(true);
        evt.cancelBubble = true;
    };

    smoothPath = (initPath) => { // для скругления полилинии составленной из перпендикулярных участков
        let A,
            B,
            C;
        const newPath = [];
        const radius = 8;
        const center = {
            x: 0,
            y: 0,
        }; // центр вокруг которого будем рисовать окружность
        const path = initPath.map((elem) => (Math.round(elem)));
        if (path.length >= 6) { // будем скруглять, если есть минимум три точки
            newPath.push(path[0]); // первая точка - x
            newPath.push(path[1]); // первая точка - y
            for (let i = 0; i + 6 <= path.length; i = i + 2) {
                A = {
                    x: path[i],
                    y: path[i + 1],
                }; // начальная точка линии
                B = {
                    x: path[i + 2],
                    y: path[i + 3],
                }; // средняя точка
                C = {
                    x: path[i + 4],
                    y: path[i + 5],
                }; // конечная точка
                if ((Math.abs(A.x - B.x) >= radius || Math.abs(A.y - B.y) >= radius)
                    && (Math.abs(C.x - B.x) >= radius || Math.abs(C.y - B.y) >= radius)
                    && Math.abs(C.x - A.x) >= 2 * radius && Math.abs(C.y - A.y) >= 2 * radius
                ) { // достаточно места для рисования окружности
                    const B0 = {
                        x: 0,
                        y: 0,
                    }; // точка перед скруглением
                    const B1 = {
                        x: 0,
                        y: 0,
                    }; // точка после скругления
                    if (B.x - A.x !== 0) { // изменение по X в отрезке AB
                        if (B.x - A.x > 0) {
                            center.x = B.x - radius;
                        }
                        else // B.x-A.x < 0
                        {
                            center.x = B.x + radius;
                        }
                        B0.x = center.x;
                        B1.x = B.x;
                    }
                    else { // C.x-B.x !== 0 // изменение по X в отрезке BC
                        if (C.x - B.x > 0) // вправо
                        {
                            center.x = B.x + radius;
                        }
                        else // C.x-B.x < 0 // влево
                        {
                            center.x = B.x - radius;
                        }
                        B1.x = center.x;
                        B0.x = B.x;
                    }

                    if (B.y - A.y !== 0) { // изменение по Y в отрезке AB
                        if (B.y - A.y > 0) {
                            center.y = B.y - radius;
                        }
                        else // B.y-A.y < 0
                        {
                            center.y = B.y + radius;
                        }
                        B0.y = center.y;
                        B1.y = B.y;
                    }
                    else { // C.y-B.y !== 0 // изменение по Y в отрезке BC
                        if (C.y - B.y > 0) // вниз
                        {
                            center.y = B.y + radius;
                        }
                        else // C.y-B.y < 0 // вверх
                        {
                            center.y = B.y - radius;
                        }
                        B1.y = center.y;
                        B0.y = B.y;
                    }

                    newPath.push(B0.x);
                    newPath.push(B0.y);
                    const addPoint = (angle, center, radius) => {
                        newPath.push(center.x + radius * Math.cos(angle * Math.PI / 180));
                        newPath.push(center.y + radius * Math.sin(angle * Math.PI / 180));
                    };

                    if (C.x > A.x && C.y > A.y && A.y === B.y) { // вправо-вниз
                        for (let j = 270; j <= 360; j = j + 10)
                            addPoint(j, center, radius);
                    }
                    if (C.x > A.x && C.y > A.y && A.x === B.x) { // вниз-вправо
                        for (let j = 180; j >= 90; j = j - 10)
                            addPoint(j, center, radius);
                    }
                    if (C.x > A.x && C.y < A.y && A.y === B.y) { // вправо-вверх
                        for (let j = 90; j >= 0; j = j - 10)
                            addPoint(j, center, radius);
                    }
                    if (C.x > A.x && C.y < A.y && A.x === B.x) { // вверх-вправо
                        for (let j = 180; j <= 270; j = j + 10)
                            addPoint(j, center, radius);
                    }
                    if (C.x < A.x && C.y > A.y && A.y === B.y) { // влево-вниз
                        for (let j = 270; j >= 180; j = j - 10)
                            addPoint(j, center, radius);
                    }
                    if (C.x < A.x && C.y > A.y && A.x === B.x) { // вниз-влево
                        for (let j = 0; j <= 90; j = j + 10)
                            addPoint(j, center, radius);
                    }
                    if (C.x < A.x && C.y < A.y && A.y === B.y) { // влево-вверх
                        for (let j = 90; j <= 180; j = j + 10)
                            addPoint(j, center, radius);
                    }
                    if (C.x < A.x && C.y < A.y && A.x === B.x) { // вверх-влево
                        for (let j = 360; j >= 270; j = j - 10)
                            addPoint(j, center, radius);
                    }
                    newPath.push(B1.x);
                    newPath.push(B1.y);
                }
                else {
                    newPath.push(B.x);
                    newPath.push(B.y);
                }
            }
            newPath.push(path[path.length - 2]);
            newPath.push(path[path.length - 1]);
        }
        else {
            return initPath;
        }
        return newPath;
    };

    handleArrowOver = () => {
        if (!this.props.readOnly) {
            this.props.setActiveTransition(this.props.sys_id);
        }
    };

    handleArrowOut = () => {
        if (!this.props.readOnly) {
            this.props.setActiveTransition(null);
        }
    };

    render() {
        const path = this.getPath(this.props.start, this.props.exit, this.props.end);
        const smoothPath = this.smoothPath(path);
        const color = this.props.selected ? selectedColor : (this.props.state ? completedColor : regularColor);

        return (
            <Group
                id={ 'wf_transition' + this.props.sys_id }
                onClick={ this.handleClick }
                onDblClick={ this.handleDblClick }
            >
                <Arrow
                    id={ this.props.sys_id }
                    stroke={ color }
                    fill={ color }
                    lineCap='round'
                    lineJoin='round'
                    cornerRadius={ 6 }
                    points={ smoothPath }
                    strokeWidth={ 2 }
                    hitStrokeWidth={ 9 }
                    pointerLength={ pointerSize }
                    pointerWidth={ pointerSize }
                    onMouseOver={ this.handleArrowOver }
                    onMouseOut={ this.handleArrowOut }
                />

            </Group>
        );
    }
}
