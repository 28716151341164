import apiRequest from 'lib/apiRequest';
import {handleResponse} from 'helpers/action';
import type {
    ExportsResponse,
    DownloadUrlResponse,
    ExportIDsParams,
    ExportsParams,
    CreateExportParams,
    CreateExportResponse,
} from "types/components/exportManager";
import type {HandleResponse} from "types/actions/main";

export const fetchExport = async (params: ExportsParams): Promise<ExportsResponse> => {
    const response = await new apiRequest(`GET /exports`).qs(params).send();
    return handleResponse(response);
};

export const fetchExportTable = async (data: CreateExportParams): Promise<CreateExportResponse> => {
    const response = await new apiRequest(`POST /exports`).sendJSON(data);
    return handleResponse(response);
};

export const fetchDeleteExport = async (data: ExportIDsParams): Promise<HandleResponse> => {
    const response = await new apiRequest(`POST /exports/delete`).sendJSON(data);
    return handleResponse(response);
};

export const fetchDownloadUrl = async (data: ExportIDsParams): Promise<DownloadUrlResponse> => {
    const response = await new apiRequest(`POST /exports/download-url`).sendJSON(data);
    return handleResponse(response);
};
