import * as React from 'react';
import { observer } from 'mobx-react';
import styles from 'components/dateTimePicker/styles.module.scss';
import { observable, makeObservable } from 'mobx';
import IconChevronL from 'assets/img/icons/chevron-left.svg';
import IconChevronR from 'assets/img/icons/chevron-right.svg';
import DatePickerMonths from 'components/dateTimePicker/datePickerMonths';
import DatePickerYears from 'components/dateTimePicker/datePickerYears';
import { ATTRIBUTES } from 'constants/attributesForTests';

/**
 * Описание: Хэдер датапикера
 * Параметры:
 * picker - ссылка на датапикер
 */
class DatePickerHeader extends React.Component {
    showYearsFlag = false;
    showMonthsFlag = false;

    constructor(props) {
        super(props);

        makeObservable(this, {
            showYearsFlag: observable,
            showMonthsFlag: observable,
        });
    }

    toMonth = (i) => {
        return () => {
            this.props.picker.visibleDay = this.props.picker.visibleDay.clone().add(i, 'month');
        };
    };

    showYears = (flag) => {
        this.showYearsFlag = flag;
    };

    changeYear = (value) => {
        this.props.picker.visibleDay = this.props.picker.visibleDay.clone().set('year', value);
        this.showYears(false);
    };

    showMonths = (flag) => {
        this.showMonthsFlag = flag;
    };

    changeMonth = (value) => {
        this.props.picker.visibleDay = this.props.picker.visibleDay.clone().set('month', value);
        this.showMonths(false);
    };

    render() {
        const { readonly } = this.props.picker.props;

        return (
            <div>
                <div
                    className={ styles.Header }
                    data-test={ ATTRIBUTES.fieldDatePickerHeader }
                >
                    { !readonly && <div
                        className={ styles.Chevron }
                        onClick={ this.toMonth(-1) }
                        dangerouslySetInnerHTML={ { __html: IconChevronL } }
                        data-test={ ATTRIBUTES.fieldDatePickerPrevMonth }
                    />}
                    <div className={ styles.HeaderTitle }>
                        <span
                            className={ readonly ? styles.HeaderLinkReadonly : styles.HeaderLink }
                            onClick={ () => {
                                if(readonly) return false;
                                this.showMonths(true);
                            } }
                            data-test={ ATTRIBUTES.fieldDatePickerHeaderMonths }
                        >
                            { this.props.picker.visibleDay.format('MMMM') }
                        </span>
                        <span
                            className={ readonly ? styles.HeaderLinkReadonly : styles.HeaderLink }
                            onClick={ () => {
                                if(readonly) return false;
                                this.showYears(true);
                            } }
                            data-test={ ATTRIBUTES.fieldDatePickerHeaderYears }
                        >
                            { this.props.picker.visibleDay.format('YYYY') }
                        </span>
                    </div>
                    {!readonly && <div
                        className={ styles.Chevron }
                        onClick={ this.toMonth(1) }
                        dangerouslySetInnerHTML={ { __html: IconChevronR } }
                        data-test={ ATTRIBUTES.fieldDatePickerNextMonth }
                    />}
                </div>
                <DatePickerMonths isHidden={ !this.showMonthsFlag } onItemClick={ this.changeMonth } onHeaderClick={ this.showMonths } visibleDay={ this.props.picker.visibleDay } />
                <DatePickerYears isHidden={ !this.showYearsFlag } onItemClick={ this.changeYear } onHeaderClick={ this.showYears } visibleDay={ this.props.picker.visibleDay } />
            </div>
        );
    }
}

export default observer(DatePickerHeader);
