import _ from 'lodash';
import {observable, makeObservable} from "mobx";
import BaseFormFieldModel from './BaseFormFieldModel';
import { LexicalEditor } from "lexical";

export default class RichTextModel extends BaseFormFieldModel {

    columnType = 'richText';

    /* Объект Lexical Editor */
    rawEditor: LexicalEditor | null = null;

    /* Флаг расширенного режима */
    isAdditional = false;

    constructor(data, parentFormSectionModel) {
        super(data, parentFormSectionModel);
        makeObservable(this, {
            isAdditional: observable,
        });

        super.mergeData(data);
    }

    setRawEditor = (editor: LexicalEditor) => {
        this.rawEditor = editor;
    }

    uiGetDisplayValue() {
        const rootElement = this.rawEditor?.getRootElement();
        return rootElement?.textContent;
    }

    uiSetValue(value: string) {
        if (value === '') {
            return this.uiClearValue();
        }
        if (_.isEqual(this.defaultValue, value)) {
            this.changed = false;
        } else if (!_.isEqual(this.value, value)) {
            this.changed = true;
        }
        this.value = value;

        if (this.rawEditor) {
            const editorState = this.rawEditor.parseEditorState(JSON.parse(value));
            this.rawEditor.setEditorState(editorState);
        }
    }
}
