import * as React from 'react';

import styles from './styles.module.scss';
import { ButtonPropsTypes } from 'types/components/button';
import Hint from 'components/hint';

function Button({ innerRef, buttonType, buttonSize, className, attachmentsLength, svg, hint, disabled, ...props }: ButtonPropsTypes) {
    let btnClass;
    switch (buttonType) {
        case 'primary':
            btnClass = styles.Primary;
            break;
        case 'secondary':
            btnClass = styles.Secondary;
            break;
        case 'destructive':
            btnClass = styles.Destructive;
            break;
        case 'approve':
            btnClass = styles.Approve;
            break;
        case 'icon':
            btnClass = styles.Icon;
            break;
        case 'icon-primary':
            btnClass = styles.IconPrimary;
            break;
        case 'icon-mini':
            btnClass = styles.IconMini;
            break;
        case 'icon-border':
            btnClass = styles.IconBorder;
            break;
        case 'attach':
            btnClass = styles.Attach;
            break;
        case 'expand':
            btnClass = styles.Expand;
            break;
        default:
            btnClass = styles.Default;
    }
    if (buttonSize === 'sm') {
        btnClass += ' ' + styles.SizeSmall;
    }
    if (className) {
        btnClass += ' ' + className;
    }


    const attachmentsCounter = (buttonType === 'attach' && attachmentsLength && attachmentsLength > 0) ?
        <span className={ styles.attachmentsCount }>{ attachmentsLength }</span> : null;
    const getIconClass = () => {
        //если иконка находится внутри кнопки с текстом - навешивеам на неё класс для стилизации
        return !buttonType || !['icon', 'icon-primary', 'icon-border', 'attach', 'icon-mini'].includes(buttonType) ? styles.TextIcon : '';
    };
    const svgIcon = svg ? <div className={getIconClass()} dangerouslySetInnerHTML={ { __html: svg } } /> : null;

    const handleClick = (e) => {
        const { onClick } = props;
        if (disabled) {
            e.preventDefault();
            return;
        }
        if (onClick) {
            onClick(e);
        }
    };
    if (typeof props.isVisible !== 'undefined' && !props.isVisible) {
        return null;
    }

    delete props.isServicePortal;
    if (disabled) props.tabIndex = -1; // отключаем фокус по нажатию на tab

    const dataTest = `${ buttonType }-test-button`;
    const btnRef = !innerRef ? React.createRef<HTMLButtonElement>() : innerRef;

    return (<>
        <button
            type='button'
            className={ btnClass }
            { ...props }
            data-disabled={ disabled ? 'true' : 'false' } // используем data-disabled вместо disabled для того чтобы eventListener работал и мы могли отображать hint
            data-test={ props['data-test'] ? props['data-test'] : dataTest }
            ref={ btnRef }
            onClick={ handleClick }
        >
            { svgIcon }
            { props.children }
            { attachmentsCounter }
        </button>
        { hint && <Hint refParent={ btnRef }>{ hint }</Hint> }
    </>);
}


export default React.forwardRef((props: ButtonPropsTypes, ref?: React.Ref<HTMLButtonElement> | null) => <Button innerRef={ ref } { ...props } />);
