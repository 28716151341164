import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import FormServerErrors from 'components/formItems/serverErrors';
import FormValidator from 'helpers/formValidator';
import apiRequest from 'lib/apiRequest';
import LoginInput from 'components/login/input';
import LoginButton from 'components/login/button';

import styles from 'components/login/styles.module.scss';
import SmartTitle from 'components/smartTitle';
import langStore from 'globalState/lang';
import Select from 'components/login/select';
import Help from 'components/login/help';

/*
* Props:
* onTogglePassword: { type: function } - метод для смены флага формы
* onFetchTranslate: { type: function } - метод для изменения языка
* langData: { type: object } - данные по языку
* logo: { type: string } - логотип
* isSwitchLang: { type: boolean } - флаг показа изменения языка
* */
class ResetPassword extends React.Component {
    formFields = {
        email: FormValidator.createFormFieldObj(),
    };
    serverError = '';
    isFetching = false;

    // Validate form and submit
    validateAndSubmit = async () => {
        if (this.isFetching) {
            return;
        }
        this.isFetching = true;

        const fv = new FormValidator(this.formFields);
        const promises = [];

        // Validate username
        promises.push(
            fv.validateField(this.formFields.email, () => {
                // if (!validateEmail) return 'Invalid email format';
            }),
        );

        // Await for validations
        await Promise.all(promises);

        const isValid = fv.isFieldsValid();

        if (!isValid) {
            this.isFetching = false;
            return;
        }

        try {
            const data = fv.getFieldsData();
            data.language = this.props.langData.langValue;
            let result = await new apiRequest('POST /reset/request', false).qs({
                language: data.language,
            }).sendJSON(data);
            if (result) {
                // Success
                this.isFetching = false;
            }
        }
        catch (error) {
            const errorsParsed = fv.applyServerValidationErrors(error.response.data);
            if (!errorsParsed) {
                this.serverError = fv.serverErrorMessage || error.message;
            }
            this.isFetching = false;
        }
    };

    handleValueChange = (event) => {
        const { name, value } = event.target;
        this.formFields[name].value = value;
        this.formFields[name].errorMessage = '';
    };

    onFormKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.validateAndSubmit().catch(console.error);
        }
    };

    renderLogo = () => {
        const { logo } = this.props;
        if (!logo) {
            return null;
        }
        return (
            <div className={ styles.Logo }>
                <img src={ logo } />
            </div>
        );
    };

    renderSwitchLang = () => {
        const { onFetchTranslate, langData, isSwitchLang } = this.props;
        if (!isSwitchLang) {
            return null;
        }
        return (
            <Select
                onChange={ onFetchTranslate }
                databaseValue={ langData.langValue }
                options={ langData.langSpecial }
            />
        );
    };

    constructor(props) {
        super(props);

        makeObservable(this, {
            formFields: observable,
            serverError: observable,
            isFetching: observable,
        });
    }

    render() {
        const { reset_page, placeholders, help } = langStore.getTranslate();

        return (
            <div className={ styles.Form } onKeyPress={ this.onFormKeyPress }>
                <SmartTitle hidden>{ reset_page ? reset_page.title : '' }</SmartTitle>
                { this.renderSwitchLang() }
                <Help title={ help && help.title } children={ help && help.content } />
                { this.renderLogo() }
                <div className={ styles.TextField }>{ reset_page && reset_page.text }</div>
                <div className={ styles.Field }>
                    <LoginInput
                        placeholder={ placeholders && placeholders.email }
                        name="email"
                        onChange={ this.handleValueChange }
                        msg={ this.formFields.email.errorMessage }
                        autoFocus
                        isDisabled={ this.isFetching }
                    />
                </div>
                <div className={ styles.Field }>
                    <LoginButton
                        isLoading={ this.isFetching }
                        onClick={ this.validateAndSubmit }
                        buttonType='primary'
                        disabled={ !this.formFields.email.value }
                    >
                        { reset_page && reset_page.submit_button }
                    </LoginButton>
                </div>
                <FormServerErrors msg={ this.serverError } />
                <div className={ `${ styles.LinksField } ${ styles.alignCenter }` }>
                    <div>
                        <span className={ styles.Link } onClick={ this.props.onTogglePassword }>{ reset_page && reset_page.login_link }</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default observer(ResetPassword);
