import { DataDynamicMenuType, MenuItemType } from 'types/globalState/menu';
import _ from 'lodash';

export const getIsSubmenuActive = (item: MenuItemType) => {
    if (!item.getItems().length) {
        return false;
    }
    return !!_.find(item.getItems(), subItem => {
        return subItem.getIsActive() || getIsSubmenuActive(subItem);
    });
};

export const getItems = (categoriesOrItems, isItems = false) => {
    return _.map(categoriesOrItems, (item: DataDynamicMenuType) => {
        let order = 0;
        const parseData = (item: DataDynamicMenuType, isMenuItem = false) => {
            return {
                isCategory: !isMenuItem,
                isItem: isMenuItem,
                default_opened: false,
                edit_access: false,
                edit_url: false,
                is_favorite: false,
                label: item.displayName,
                order: order++,
                sys_id: item.id,
                url: item.url,
                dynamic: false,
                icon: item.icon,
                items: [
                    ..._.map(item.categories, category => parseData(category, false)),
                    ..._.map(item.items, newItem => parseData(newItem, true)),
                ],
            };
        };

        return parseData(item, isItems);
    });
};

export const isEmptyMenu = (items) => {
    let emptyCountMenu = 0;
    if (!_.isEmpty(items)) {
        _.forEach(items, item => {
            if (!item.getIsShow()) {
                emptyCountMenu++;
            }
        });
    }
    return emptyCountMenu === items.length;
};