import * as React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';


import styles from 'components/dynamicForms/view/field/duration/styles.module.scss';

import StringInput from 'components/dynamicForms/view/field/stringInput';
import FieldWrapper from 'components/dynamicForms/view/fieldWrapper';
import langStore from 'globalState/lang';
import DurationModel from 'components/dynamicForms/model/field/DurationModel';
import { getTestNameField } from 'helpers/data';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { observable, makeObservable } from "mobx";
import { isChanged } from 'helpers/form';

/**
 * Описание: компонент Duration
 * Параметры:
 * onChange: {required, type: function} - метод для изменения
 * value: {type: ms}
 * column_id: {type: string}
 */
class Duration extends React.Component {
    model;

    constructor(props) {
        super(props);

        makeObservable(this, {
            model: observable,
        });

        if (props.model) {
            this.model = props.model;
        } else {
            this.model = new DurationModel(props);
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props, prevProps)) {
            if (this.props.model) {
                this.model = this.props.model;
            } else {
                this.model.mergeData(this.props);
            }
        }
    }

    onChange = (id) => (e) => {
        this.model.set(id, e.value);
        this.model.changed = isChanged(this.model.defaultValue, this.model.value);
        if (this.props.onChange) {
            this.props.onChange({ ...this.model, value: this.model.getValueForSave().toString() });
        }
    };

    render() {
        const { duration_titles } = langStore.getTranslate();

        const fieldName = getTestNameField(this.model);
        const dataTest = (fieldName !== 'unknown') ? `${ fieldName }-${ ATTRIBUTES.fieldDuration }` : `${ ATTRIBUTES.fieldDuration }`;
        return (
            <FieldWrapper model={ this.model }>
                <div
                    className={ styles.Duration }
                    data-test={ this.props['data-test'] ? this.props['data-test'] : dataTest }
                    data-test-visible={ this.model.isVisible }
                    data-test-mandatory={ this.model.isMandatory }
                    data-test-readonly={ this.model.readonly }
                    data-test-field-type={ this.model.sys_column_name ? this.model.column_type : undefined }
                    data-test-field-name={ this.model.sys_column_name }
                >
                    {
                        _.map(this.model.value, (value, key) => {
                                return key === 'ms' ? null : (
                                    <label key={ `${ this.model.column_id }_${ key }_label` } className={ styles.DurationLabel }>
                                        <StringInput
                                            onChange={ this.onChange(key) }
                                            id={ key }
                                            key={ `${ this.model.column_id }_${ key }` }
                                            type={ 'number' }
                                            value={ value.toString() }
                                            column_type={'integer'}
                                            readonly={ this.model.readonly }
                                            ref={ this.model.ref }
                                        />
                                        <span>{ duration_titles && duration_titles[key] }</span>
                                    </label>
                                );
                            },
                        )
                    }
                </div>
            </FieldWrapper>
        );
    }
}

export default observer(Duration);
