import { getUserAccessToken } from 'lib/apiRequest';

function AttachmentRedirect (props) {
    const userAccessToken = getUserAccessToken();
    window.location = `/v1/attachments/download/${props.match.params.attachment_id}?access-token=${userAccessToken}`;

    return null;
}

export default AttachmentRedirect;
