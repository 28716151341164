import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import Dropdown from 'components/dropdown';
import styles from './styles.module.scss';
import IconQuestion from 'assets/img/icons/help-circle.svg';
import { ATTRIBUTES } from 'constants/attributesForTests';

/***
 * Описание: Help
 * Праметры:
 * btnText - текст кнопки
 * children - содержимое поповера
 */
class Help extends React.Component {
    isOpened = false;
    refDropdown = React.createRef();
    refText = React.createRef();

    constructor(props) {
        super(props);

        makeObservable(this, {
            isOpened: observable,
        });
    }

    componentDidMount() {
        window.addEventListener('scroll', () => this.isOpened = false);
        document.addEventListener('click', this.onDocumentClick);

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', () => this.isOpened = false);
        document.removeEventListener('click', this.onDocumentClick);
    }

    onTextClick = () => {
        this.isOpened = !this.isOpened;
    };

    onDocumentClick = (e) => {
        const dropdownEl = this.refDropdown ? this.refDropdown.current : null;
        const textEl = this.refText.current;
        if (!dropdownEl || !textEl) return false;

        if (!dropdownEl.contains(e.target) && !textEl.contains(e.target)) {
            this.isOpened = false;
        }
    };

    render() {
        if (!this.props.children || !this.props.title) return null;

        return (
            <div className={ styles.wrap } data-test={ this.props['data-test'] ? this.props['data-test'] : ATTRIBUTES.helpElement }>
                <div className={ `${ styles.container }` }>
                    <div
                        className={ `${ styles.title }` }
                        onClick={ this.onTextClick }
                        ref={ this.refText }
                        data-test={ ATTRIBUTES.helpClickElement }
                    >
                        { this.props.title }
                        <div dangerouslySetInnerHTML={ { __html: IconQuestion } } className={ styles.helpIcon } />
                    </div>
                    { this.isOpened && (
                        <Dropdown floatRight refParent={ this.refText } ref={ this.refDropdown }>
                            <div className={ `${ styles.helpContent }` }>
                                <div
                                    className={ styles.helpText }
                                    dangerouslySetInnerHTML={ { __html: this.props.children } }
                                    data-test={ ATTRIBUTES.helpText }
                                />
                            </div>
                        </Dropdown>
                    ) }
                </div>
            </div>
        );
    }
}

export default observer(Help);
