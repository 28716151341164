import React, {useCallback, useState, useEffect, useRef, FC} from 'react';
import {createPortal} from "react-dom";
import {$getSelection, $isRangeSelection, $createParagraphNode, FORMAT_TEXT_COMMAND} from "lexical";
import {mergeRegister} from '@lexical/utils';
import {TOGGLE_LINK_COMMAND} from '@lexical/link';
import {$createQuoteNode, HeadingTagType, $createHeadingNode} from '@lexical/rich-text';
import {INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND} from '@lexical/list';
import {$wrapNodes} from '@lexical/selection';
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import styles from './styles.module.scss';
import {ToolbarItem} from "./ToolbarItem";
import {FloatingLinkEditor} from "../LinkPlugin";
import {TOGGLE_MODAL_IMAGE_COMMAND} from "../ImagePlugin";
import DropDown from "components/dropdown";
import Button from "components/button";
import {isMedia} from "helpers/html";
import langStore from "globalState/lang";
import IconBold from "assets/img/icons/text-format-bold.svg";
import IconItalic from "assets/img/icons/text-format-italic.svg";
import IconUnderline from "assets/img/icons/text-format-underline.svg";
import IconStrikethrough from "assets/img/icons/text-format-strikethrough.svg";
import IconList from "assets/img/icons/text-format-list.svg";
import IconOrdered from "assets/img/icons/text-format-ordered-list.svg";
import IconBlockquote from "assets/img/icons/text-format-blockquote.svg";
import IconText from "assets/img/icons/text-format-type.svg";
import IconHeading1 from "assets/img/icons/text-format-heading1.svg";
import IconHeading2 from "assets/img/icons/text-format-heading2.svg";
// import IconToggleComment from "assets/img/icons/message-square.svg";
// import IconAddComment from "assets/img/icons/message-field.svg";
import IconLink from "assets/img/icons/link-2.svg";
import IconImage from "assets/img/icons/image.svg";
import IconKebab from 'assets/img/icons/more-horizontal-kebab.svg';
// import { INSERT_INLINE_COMMAND } from "../CommentPlugin";
import {ATTRIBUTES} from 'constants/attributesForTests';
import {KeyboardKeys} from "types/components/dynamicForms/maskInput";
import type {ToolbarItemsProps} from 'types/components/textEditor/toolbarPlugin';

export const Divider = () => {
    return <div className={styles.Divider}/>;
};

export const ToolbarItems: FC<ToolbarItemsProps> = ({
                                                        isShowMainItems,
                                                        containItems = 99,
                                                        blockType,
                                                        isBold,
                                                        isItalic,
                                                        isUnderline,
                                                        isStrikethrough,
                                                        isLink,
                                                        isNeedListen = false,
                                                        isShowLinkItem = false,
                                                        isShowImageItem = false,
                                                        isAdditional = false,
                                                    }) => {
    const [editor] = useLexicalComposerContext();
    const [isSelected, setIsSelected] = useState(false);
    // const [showComments, setShowComments] = useState(false);
    const [showKebabMenu, setShowKebabMenu] = useState(false);
    const refKebabMenu = useRef<HTMLDivElement>(null);
    const refKebabButton = useRef<HTMLButtonElement>(null);
    const {rich_text} = langStore.getTranslate();

    useEffect(() => {
        const onKeyPress = (evt) => {
            evt.stopPropagation();

            if (evt.key === KeyboardKeys.Escape) {
                evt.preventDefault();
                setShowKebabMenu(false);
            }

            if ((evt.ctrlKey || evt.metaKey) && evt.shiftKey) {
                switch (evt.keyCode) {
                    case 88: // Зачёркивание 'x'
                        evt.preventDefault();
                        editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
                        break;
                    case 56: // Нумерованный список '8'
                        evt.preventDefault();
                        formatBulletList();
                        break;
                    case 55:  // Маркированный список '7'
                        evt.preventDefault();
                        formatNumberedList();
                        break;
                    case 57: // Цитата '9'
                        evt.preventDefault();
                        formatQuote();
                        break;
                    case 75: // Вставить изображение 'K'
                        evt.preventDefault();
                        // editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
                        insertImage();
                        break;
                }
            }
        };

        const onDocumentClock = ({target}) => {
            const kebabMenu = refKebabMenu.current;
            const kebabButton = refKebabButton.current;
            if (!kebabMenu || !kebabButton) return;

            if (!kebabMenu.contains(target) && !kebabButton.contains(target)) {
                setShowKebabMenu(false);
            }
        };

        document.addEventListener('click', onDocumentClock);
        if (isNeedListen) {
            // нужно чтоб вешался один слушатель когда переиспользуем компонент
            document.addEventListener('keydown', onKeyPress);
        }
        return () => {
            document.removeEventListener('click', onDocumentClock);
            document.removeEventListener('keydown', onKeyPress);
        };
    }, [blockType]);


    useEffect(() => {
        const updateSelected = () => {
            const nativeSelection = window.getSelection();
            setIsSelected(nativeSelection?.type === "Range");
        };

        editor.update(() => {
            updateSelected();
        });

        return mergeRegister(
            editor.registerUpdateListener(({editorState}) => {
                editorState.read(() => {
                    updateSelected();
                });
            }),
        );
    }, [editor]);

    const insertImage = useCallback(() => {
        editor.dispatchCommand(TOGGLE_MODAL_IMAGE_COMMAND, true);
    }, []);

    // const insertComment = useCallback(() => {
    //     editor.dispatchCommand(INSERT_INLINE_COMMAND, undefined);
    // }, []);

    const insertLink = useCallback(() => {
        if (!isLink) {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, '');
        } else {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
        }
    }, [editor, isLink]);

    const formatBulletList = () => {
        if (blockType !== 'ul') {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
    };

    const formatParagraph = () => {
        editor.update(() => {
            const selection = $getSelection();

            if ($isRangeSelection(selection)) {
                $wrapNodes(selection, () => $createParagraphNode());
            }
        });
    };

    const formatHeading = (headingSize: HeadingTagType) => {
        if (blockType !== headingSize) {
            editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $wrapNodes(selection, () => $createHeadingNode(headingSize));
                }
            });
        }
    };

    const formatNumberedList = () => {
        if (blockType !== 'ol') {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
    };

    const formatQuote = () => {
        editor.update(() => {
            const selection = $getSelection();

            if ($isRangeSelection(selection)) {
                if (blockType !== 'quote') {
                    $wrapNodes(selection, () => $createQuoteNode());
                } else {
                    $wrapNodes(selection, () => $createParagraphNode());
                }
            }
        });
    };

    const LinkButton = isShowLinkItem && (
        <ToolbarItem
            key={'link'}
            onClick={insertLink}
            isActive={isLink}
            icon={IconLink}
            dataTest={ATTRIBUTES.editorLinkIcon}
            disabled={!isSelected}
        >
            {rich_text?.insert_link}
        </ToolbarItem>
    );

    const ImageButton = isShowImageItem && (
        <ToolbarItem
            key={'image'}
            onClick={insertImage}
            isActive={false}
            dataTest={ATTRIBUTES.editorImageIcon}
            icon={IconImage}
        >
            {rich_text?.insert_image} <span>CTRL</span><span>SHIFT</span> <span>K</span>
        </ToolbarItem>
    );

    const heading1Button = isAdditional && (
        <ToolbarItem
            key={'h1'}
            onClick={() => formatHeading('h1')}
            isActive={blockType === 'h1'}
            icon={IconHeading1}
            dataTest={ATTRIBUTES.editorH1Icon}
        >
            {rich_text?.heading}
        </ToolbarItem>
    );

    const heading2Button = isAdditional && (
        <ToolbarItem
            key={'h2'}
            onClick={() => formatHeading('h2')}
            isActive={blockType === 'h2'}
            icon={IconHeading2}
            dataTest={ATTRIBUTES.editorH2Icon}
        >
            {rich_text?.subheading}
        </ToolbarItem>
    );

    const textButton = isAdditional && (
        <React.Fragment key={'text'}>
            <ToolbarItem
                onClick={formatParagraph}
                isActive={blockType === 'paragraph'}
                icon={IconText}
                dataTest={ATTRIBUTES.editorParagraphIcon}
            >
                {rich_text?.normal_text}
            </ToolbarItem>

            <Divider/>
        </React.Fragment>
    );

    // const AddCommentButton = (
    //     <ToolbarItem
    //         key={'addComment'}
    //         onClick={insertComment}
    //         isActive={false}
    //         dataTest={ ATTRIBUTES.editorAddComment }
    //         icon={IconAddComment}
    //     >
    //         Добавить комментарий <span>CTRL</span><span>SHIFT</span> <span>C</span>
    //     </ToolbarItem>
    // );
    //
    // const ToggleCommentButton = (
    //     <ToolbarItem
    //         key={'toggleComment'}
    //         onClick={() => setShowComments(!showComments)}
    //         isActive={showComments}
    //         dataTest={ ATTRIBUTES.editorAddComment }
    //         icon={IconToggleComment}
    //     >
    //         {showComments ? 'Скрыть комментарии' : 'Показать комментарии'}
    //     </ToolbarItem>
    // );

    const renderShortItems = () => {
        return (
            <>
                {LinkButton}
                {ImageButton}
            </>
        );
    };

    const renderAllItems = () => {
        const allMenuItems = [
            <ToolbarItem
                key={'bold'}
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
                }}
                isActive={isBold}
                icon={IconBold}
                dataTest={ATTRIBUTES.editorBoldIcon}
            >
                {rich_text?.bold} <span>CTRL</span> <span>B</span>
            </ToolbarItem>,

            <ToolbarItem
                key={'italic'}
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
                }}
                isActive={isItalic}
                icon={IconItalic}
                dataTest={ATTRIBUTES.editorItalicIcon}
            >
                {rich_text?.italic} <span>CTRL</span> <span>I</span>
            </ToolbarItem>,

            <ToolbarItem
                key={'underline'}
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
                }}
                isActive={isUnderline}
                icon={IconUnderline}
                dataTest={ATTRIBUTES.editorUnderlineIcon}
            >
                {rich_text?.underline} <span>CTRL</span> <span>U</span>
            </ToolbarItem>,

            <React.Fragment key={'strikethrough'}>
                <ToolbarItem
                    onClick={() => {
                        editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
                    }}
                    isActive={isStrikethrough}
                    icon={IconStrikethrough}
                    dataTest={ATTRIBUTES.editorStrikethroughIcon}
                >
                    {rich_text?.strikethrough} <span>CTRL</span><span>SHIFT</span> <span>X</span>
                </ToolbarItem>

                <Divider/>
            </React.Fragment>,

            heading1Button,
            heading2Button,
            textButton,

            <ToolbarItem
                key={'ul'}
                onClick={formatBulletList}
                isActive={blockType === 'ul'}
                icon={IconList}
                dataTest={ATTRIBUTES.editorBulletListIcon}
            >
                {rich_text?.bulleted_list} <span>CTRL</span><span>SHIFT</span> <span>8</span>
            </ToolbarItem>,

            <ToolbarItem
                key={'ol'}
                onClick={formatNumberedList}
                isActive={blockType === 'ol'}
                icon={IconOrdered}
                dataTest={ATTRIBUTES.editorNumberedListIcon}
            >
                {rich_text?.numbered_list} <span>CTRL</span><span>SHIFT</span> <span>7</span>
            </ToolbarItem>,

            <React.Fragment key={'quote'}>
                <ToolbarItem
                    onClick={formatQuote}
                    isActive={blockType === 'quote'}
                    icon={IconBlockquote}
                    dataTest={ATTRIBUTES.editorQuoteIcon}
                >
                    {rich_text?.quote} <span>CTRL</span><span>SHIFT</span> <span>9</span>
                </ToolbarItem>

                <Divider/>
            </React.Fragment>,
            LinkButton,
            ImageButton,
            // AddCommentButton,
            // ToggleCommentButton,
        ];

        const isAllIcons = allMenuItems.length <= containItems || isMedia('sm');
        if (isAllIcons) {
            return allMenuItems;
        }

        const shownItems = allMenuItems.splice(0, containItems);

        return (
            <>
                {shownItems}
                <Button
                    onClick={() => {
                        setShowKebabMenu((showKebabMenu) => !showKebabMenu);
                    }}
                    buttonType='icon'
                    svg={IconKebab}
                    ref={refKebabButton}
                />
                {showKebabMenu && (
                    <DropDown
                        ref={refKebabMenu}
                        refParent={refKebabButton}
                        floatRight
                    >
                        <div className={styles.KebabMenu}>
                            {allMenuItems}
                        </div>
                    </DropDown>
                )}
            </>
        );
    };

    return (
        <>
            {isShowMainItems ? renderAllItems() : renderShortItems()}

            {isLink &&
                createPortal(
                    <FloatingLinkEditor editor={editor}/>,
                    document.body,
                )}
        </>
    );
};