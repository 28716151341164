import _ from 'lodash';
import SimpleForm from '../ui-actions-scripts/simple-form';
import SimpleWidget from '../ui-actions-scripts/simple-widget';
import SimpleWidgets from '../ui-actions-scripts/simple-widgets';
import { CurrentData } from 'types/globalState/forms';
import { ParamsOfScript, ScriptItem, TypeOfScript } from 'types/helpers/scriptClientHelper';
import DynamicFormModel from 'components/dynamicForms/model/DynamicFormModel';
import { fetchMatchConditionClientScript } from 'actions/clientScript';
import SimpleEventBus from '../ui-actions-scripts/simple-eventbus';
import widgetsDataState from 'globalState/widgets';

declare global {
    interface Window {
        s_widget: SimpleWidget;
        s_widgets: SimpleWidgets;
        s_form: SimpleForm;
        form: DynamicFormModel;
        SimpleEventBus: SimpleEventBus;
        s_widget_custom: Record<string, any>;
        isNewRecord: boolean;
    }
}

export const TYPE_ON_LOAD = "onLoad";
export const TYPE_ON_CHANGE = "onChange";
export const TYPE_ON_SUBMIT = "onSubmit";
export const TYPE_ON_CELL_EDIT = "onCellEdit";

export async function runScript(script: string, args: ParamsOfScript | {} = {}) {
    const AsyncFunction = new Function(`return Object.getPrototypeOf(async function(){}).constructor`)();
    try {
        const fn = new AsyncFunction(Object.keys(args), script);
        return await fn.call(args, ...Object.values(args));
        // eval(script);
    } catch (e) {
        console.warn(script);
        console.error(e.message);
    }
}

function filterTypeScripts(scripts: ScriptItem[], type: TypeOfScript, columnId: string | null = null): ScriptItem[] {
    if (!columnId) {
        return scripts.filter(item => item.type === type);
    } else {
        return scripts.filter(item => item.type === type && (item.column_id === columnId || item.attribute_id === columnId));
    }
}

/**
 * Фунция определяет тип клиентского скрипта и выполянет его
 * @param {Array} scripts - клиентский скрипт
 * @param {String} type - типа скрипта
 * @param {String} columnId - id колонки (не обязательное поле)
 * @param {Object} param - переменные, которые можно использовать в клиентских скриптах
 * @param {String} dynamicFormId - идентификатор формы
 * @param {Boolean} isSubForm - признак под-формы
 * @returns {*} - ответ от клиентских скриптов
 */
export async function runOnTypeScripts(scripts: ScriptItem[], type: TypeOfScript, columnId: string | null = null, param: ParamsOfScript | null = null, dynamicFormId?: string, isSubForm = false) {
    const typeScripts = filterTypeScripts(scripts, type, columnId);

    let current = {};
    let tableName = '';
    let sysId = '';

    switch (type) {
        case TYPE_ON_CHANGE:
        case TYPE_ON_LOAD:
        case TYPE_ON_SUBMIT:
            if (_.isEmpty(window.s_form) && _.isEmpty(window.s_widget) && _.isEmpty((window.s_form as any)?.modalForm)) {
                break;
            }
            let form = window.s_form;
            const widgetSimpleForm = widgetsDataState.getFormById(dynamicFormId);
            if (isSubForm) {
                form = (window.s_form as any).modalForm;
                _.forEach(typeScripts, row => {
                    row.script = _.replace(row.script,/s_form\.modalForm\./g, 's_form.'); // для повторного использования
                    row.script = _.replace(row.script,/s_form\./g, 's_form.modalForm.');
                });
            } else if (widgetSimpleForm && !_.isEmpty(window.s_widget)) {
                form = widgetSimpleForm;
            }
            if (!form) {
                break;
            }
            current = form.getAllFields(true).data;

            if (type !== TYPE_ON_LOAD && _.isEmpty(current)){
                break;
            }
            tableName = form.getTableName();
            sysId = form.getUniqueValue();

            if (type === TYPE_ON_SUBMIT) {
                const resultSubmit = await Promise.all(
                    (await executeScripts(typeScripts, current, tableName, sysId) as Array<Promise<any>>),
                );
                return resultSubmit.every(item => !(typeof item === "boolean" && !item));
            } else {
                await executeScripts(typeScripts, current, tableName, sysId);
            }
            break;

        case TYPE_ON_CELL_EDIT:
            if (param) {
                current = (param.current as CurrentData);
                tableName = (param.table as string);
                sysId = (param.sysId as string);
                delete param.current;
            }
            await executeScripts(typeScripts, current, tableName, sysId, param);
            break;
    }
}

/**
 * Фунция выполянет скрипт, если condition совпадает
 * @param {Array<string>} scripts - отфильтрованные клиентские скрипты
 * @param {Object} current - объект поле:значение
 * @param {String} tableName - объект поле:значение
 * @param {String} sysId - объект поле:значение
 * @param {Object} param - переменные используемые в клиентском скрипте
 * @returns {*} - ответ от клиентских скриптов
 */
async function executeScripts(scripts: ScriptItem[], current: CurrentData, tableName: string, sysId: string, param?: ParamsOfScript | undefined | null): Promise<undefined | Array<Promise<any>>> {
    let response: Array<Promise<any>> = [];
    try {
        for (const item of scripts) {
            if (param && !param.isContinue) {
                return;
            }

            if (param && param.recordTableName && !item.inherited) {
                const urlEssence = window.location.pathname.split('/')[2];
                if (urlEssence !== param.recordTableName) {
                    return;
                }
            }

            if (!item.condition) {
                response.push(await runScript(item.script, (param as ParamsOfScript)));
            } else if (await requestConditionExecuteScripts(item.condition, current, tableName, sysId)) {
                response.push(await runScript(item.script, (param as ParamsOfScript)));
            }
        }
        return response;
    } catch (e) {
        console.error(e);
    }
}

/**
 * Фунция проверяет соответсвует condition занчениям в записи
 * @param {String} condition - условие выполнения
 * @param {Object} current - объект поле:значение
 * @param {String} tableName - объект поле:значение
 * @param {String} sysId - объект поле:значение
 * @returns {Boolean} - соответвует ли condition
 */
async function requestConditionExecuteScripts(condition: string, current: CurrentData, tableName: string, sysId: string): Promise<boolean | undefined> {
    const param = {
        current,
        script_condition_string: condition,
    };

    const {isOkStatus, data} = await fetchMatchConditionClientScript(tableName, sysId, param);
    if (isOkStatus) {
        return data.match_condition;
    }
}
