import {fetchI18nChoice, fetchI18nDisplayValue} from "actions/i18n";
import {I18nChoiceParams, I18nDisplayValue, I18nValueParams} from 'types/actions/i18n';

export const getChoiceTranslate = async (params: I18nChoiceParams) => {
    const {isOkStatus, data} = await fetchI18nChoice(params);
    if (isOkStatus) {
        return {
            displayValue: data.choice_translation,
            order: data.choice_order,
        };
    } else {
        return params.database_value;
    }
};

export const getDisplayValueTranslate = async (params: I18nValueParams): Promise<I18nDisplayValue> => {
    const {isOkStatus, data} = await fetchI18nDisplayValue(params);
    if (isOkStatus) {
        return data.value;
    } else {
        return params.value;
    }
};