import React from 'react';
import {observable, makeObservable} from "mobx";
import {observer} from "mobx-react";
import styles from "../styles.module.scss";
import Button from "components/button";
import Tooltip from "components/tooltip";
import langStore from "globalState/lang";
import {getFileDateTime, getFormatSize, isFileLoaded, mapDataType} from "helpers/exports";
import IconCheck from "assets/img/checkbox.svg";
import IconTrash from "assets/img/icons/trash.svg";
import IconDownloaded from "assets/img/icons/download.svg";
import IconLoaded from "assets/img/icons/check-circle-2.svg";
import IconCloseCircle from "assets/img/icons/close-circle.svg";
import IconPending from "assets/img/icons/arrow-clock.svg";
import IconHelp from "assets/img/icons/help-circle.svg";
import {ATTRIBUTES} from "constants/attributesForTests";
import type {CardData, CardProps} from "types/components/exportManager";
import {FILE_STATUS} from "types/components/exportManager";

class CardExportComponent extends React.Component<CardProps> {
    showTooltip = false;
    refIconPending = React.createRef<HTMLSpanElement>();

    constructor(props) {
        super(props);

        makeObservable(this, {
            showTooltip: observable,
        });
    }

    hideTooltip = () => {
        this.showTooltip = false;
    }

    showPopup = () => {
        this.showTooltip = true;
    }

    handleItemMouseEnter = (card: CardData) => () => {
        if (FILE_STATUS.LOADED === card.state) {
            card.state = FILE_STATUS.DOWNLOADED;
        }
    }

    handleItemMouseLeave = (card: CardData) => () => {
        if (FILE_STATUS.DOWNLOADED === card.state) {
            card.state = FILE_STATUS.LOADED;
        }
    }

    handleToggleSelectCard = (card: CardData) => (evt: React.MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();
        card.selected = !card.selected;
    }

    handleRemoveCard = (card: CardData) => async (evt) => {
        const {handleRemoveCard} = this.props;
        evt.stopPropagation();

        await handleRemoveCard(card);
    }

    handleDownloadCard = (card: CardData) => async (evt) => {
        const {handleClickCard} = this.props;
        evt.stopPropagation();

        await handleClickCard(card);
    }

    getCardData = (card: CardData) => {
        const {exports} = langStore.getTranslate();
        const {sysCreatedAt: fileDate, objectSize: fileSize, state: fileStatus} = card;

        switch (fileStatus) {
            case FILE_STATUS.LOADING:
                return exports?.file_preparation;
            case FILE_STATUS.PENDING:
                return (
                    <span className={styles.PendingData}>
                        {exports?.in_queue}
                        <span
                            ref={this.refIconPending}
                            className={styles.PendingIcon}
                            onMouseEnter={this.showPopup}
                            onMouseLeave={this.hideTooltip}
                            dangerouslySetInnerHTML={{__html: IconHelp}}
                        />
                        {this.showTooltip && <Tooltip
                            onClose={this.hideTooltip}
                            parent={this.refIconPending.current}
                        >
                            {exports?.file_preparation_warning}
                        </Tooltip>}
                    </span>
                );
            case FILE_STATUS.FAILED:
                return (
                    <span className={styles.ErrorText}>
                        {exports?.file_preparation_error}
                    </span>
                );
            default:
                return (
                    <>
                        {getFileDateTime(fileDate ?? '')} {getFormatSize(fileSize)}
                    </>
                );
        }
    }

    getStatusIcon = (fileStatus: FILE_STATUS) => {
        switch (fileStatus) {
            case FILE_STATUS.DOWNLOADED:
                return <div className={`${styles.IconStatusDone}`} dangerouslySetInnerHTML={{__html: IconDownloaded}}/>;
            case FILE_STATUS.LOADED:
                return <div className={`${styles.IconStatusDone}`} dangerouslySetInnerHTML={{__html: IconLoaded}}/>;
            case FILE_STATUS.LOADING:
                return <div className={styles.Loader}>
                    <span/>
                    <div/>
                </div>;
            case FILE_STATUS.FAILED:
                return <div className={`${styles.IconStatusError}`}
                            dangerouslySetInnerHTML={{__html: IconCloseCircle}}/>;
            case FILE_STATUS.PENDING:
                return <div className={`${styles.IconStatus}`} dangerouslySetInnerHTML={{__html: IconPending}}/>;
        }
    }

    render() {
        const {card, isMoreThanOne} = this.props;
        const {exports} = langStore.getTranslate();
        const {title: fileName, type: fileFormat, state: fileStatus, sysId, selected} = card;
        const fileStatusIcon = this.getStatusIcon(fileStatus);
        const isLoadedFile = isFileLoaded(fileStatus);

        const cardStyles = [styles.CardContainer];
        if (selected) {
            cardStyles.push(styles.CardSelected);
        }
        if (isLoadedFile) {
            cardStyles.push(styles.CardPointer);
        }
        return (
            <div
                key={sysId}
                className={cardStyles.join(' ')}
                onClick={this.handleDownloadCard(card)}
                onMouseEnter={this.handleItemMouseEnter(card)}
                onMouseLeave={this.handleItemMouseLeave(card)}
            >
                <div className={styles.CardContent}>
                    {fileStatusIcon}
                    <div className={styles.CardText}>
                        <div className={styles.CardTitle}>
                            <span>{fileName}</span>
                        </div>
                        <div className={styles.CardDescription}>
                            <span>{`${exports?.file_format} ${mapDataType(fileFormat)}`}</span>
                        </div>
                        <div className={styles.CardDescription}>
                            <span>{this.getCardData(card)}</span>
                        </div>
                    </div>

                    <div className={styles.CardControls}>
                        {isLoadedFile && isMoreThanOne && (
                            <div
                                className={`${styles.CardCheckbox} ${selected ? styles.ActiveCheckbox : ''}`}
                                onClick={this.handleToggleSelectCard(card)}
                                dangerouslySetInnerHTML={{__html: IconCheck}}
                            />
                        )}
                        <Button
                            buttonType={'icon'}
                            onClick={this.handleRemoveCard(card)}
                            data-test={ATTRIBUTES.exportRemoveButton}
                            svg={IconTrash}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export const CardExport = observer(CardExportComponent);
