/**
 * Описание: Форма создания нового workflow
 * Параметры:
 * saveNewWorkflow - метод сохранения workflow
 * */
import * as React from 'react';
import { observer } from 'mobx-react';
import WorkflowStringInput from './../stringInput';
import WorkflowReference from './../reference';
import langStore from "globalState/lang";

import styles from './styles.module.scss';
import Button from 'components/button';
import { observable, makeObservable } from 'mobx';

class NewWorkflowPanel extends React.Component {

    name = '';
    table = { database_value: null, display_value: null };

    description;

    constructor(props) {
        super(props);

        makeObservable(this, {
            name: observable,
            table: observable,
        });
    }

    handleClick = () => {
        this.props.toggleCreateButton(true);
        this.props.saveNewWorkflow(this.table, this.name, this.description);
    };

    componentDidUpdate(prevProps) {
        if (this.props.isDisabled !== prevProps.isDisabled)
            return;

        if (this.table.display_value && this.name) {
            this.props.toggleCreateButton(false);
        } else {
            this.props.toggleCreateButton(true);
        }
    }


    componentDidCatch(error, info) {
        console.error(error, info);
    }


    render(){
        const { workflow_titles } = langStore.getTranslate();
        return (
            <div className={ styles.Form }>
                <WorkflowReference
                    label={ workflow_titles && workflow_titles.table_field }
                    isMandatory={ true }
                    setValue={ (id, value) => { this.table = { database_value: id, display_value: value} } }
                    value={ this.table }
                />
                <WorkflowStringInput
                    label={ workflow_titles && workflow_titles.name_field }
                    setValue={ (value) => { this.name = value }}
                    isMandatory={ true }
                    value={ this.name }
                />
                <WorkflowStringInput
                    label={ workflow_titles && workflow_titles.description_field }
                    setValue={ (value) => {this.description = value }}
                    isMandatory={ false }
                />

                <div className={ styles.MainPageActionsButton }>
                    <Button
                        onClick={ this.handleClick }
                        disabled={ this.props.isDisabled }
                        buttonType='primary'>
                        { workflow_titles && workflow_titles.button_create }
                    </Button>
                </div>
            </div>
        );
    }
}

export default observer(NewWorkflowPanel);
