import * as React from 'react';
import DynamicForm from 'components/dynamicForms/index';
import { observer } from 'mobx-react';
import { SubFormPropsType } from 'types/components/subForm';
import styles from './styles.module.scss';
import subFormState from 'globalState/subForm/index';
import DragAndDrop from 'components/dragAndDrop/index';

class SubForm extends React.Component<SubFormPropsType> {
    dndDropFuncLink: (e: any) => void;

    constructor(props) {
        super(props);

        this.fetchData(props);
    }

    componentWillUnmount() {
        subFormState.clear();
    }

    fetchData = (props) => {
        subFormState.fetchData({
            tableName: props.tableName,
            sysId: props.sysId,
            view: props.view,
            uiActions: props.uiActions,
            isServicePortal: props.isServicePortal,
        });
    };

    handleCloseAttachPopup = () => {
        subFormState.setShowAttachPopup(false);
    };

    handleShowAttachPopup = () => {
        subFormState.setShowAttachPopup(true);
    };

    handleAttachmentsLengthChange = (length) => {
        subFormState.setAttachmentsLength(length);
    };

    handleDrop = (e) => {
        if (e.dataTransfer.files.length) {
            subFormState.setShowAttachPopup(true);
            this.dndDropFuncLink(e);
        }
    };

    handleDropFunc = (func) => {
        this.dndDropFuncLink = func;
    };

    renderSections() {
        const { tableName, sysId, view, isServicePortal, userScripts } = this.props;
        if (subFormState.getIsLoading()) {
            return (
                <div className={ styles.Loader } />
            );
        }
        const { sections, client_scripts: clientScripts, attachments, is_clip_visible } = subFormState.getRecordData();
        return (
            <>
                <DynamicForm
                    isServicePortal={ isServicePortal }
                    sections={ sections }
                    fields={ sections[0].elements }
                    name={ sections[0].name }
                    sysId={ sysId }
                    tableName={ tableName }
                    clientScripts={ userScripts && clientScripts }
                    key={ `${ tableName }-${ sysId }-${ view }` }
                    silentMode
                    id={ subFormState.getDynamicFormId() }
                    isSubForm={ true }
                />
                <DragAndDrop
                    onClose={ this.handleCloseAttachPopup }
                    onAttachmentsLengthChange={ this.handleAttachmentsLengthChange }
                    show={ subFormState.getShowAttachPopup() }
                    essence={ tableName }
                    recordId=''
                    attachments={ attachments }
                    onDropFunc={ this.handleDropFunc }
                    isAclActive={ is_clip_visible === false }
                />
            </>
        );
    }

    render() {
        if (subFormState.getIsLoading()) {
            return (
                <div className={ styles.Loader } />
            );
        }
        return (
            <div>
                { this.renderSections() }
            </div>
        );
    }
}

export default observer(SubForm);
