import * as React from 'react';
import { observer } from 'mobx-react';
import {makeObservable, observable} from 'mobx';
import Button from 'components/button';
import RecordPopup from '..';
import { InfoButtonProps } from 'types/components/recordPopup/infoButton';

class RecordInfoBtnComponent extends React.Component<InfoButtonProps> {
    isShow = false;
    refButton = React.createRef<HTMLButtonElement>();

    constructor(props) {
        super(props);
        makeObservable(this, {
            isShow: observable,
        });
    }

    handleToggle = (evt) => {
        const { isDisabled, onClick } = this.props;

        if (!isDisabled) {
            this.isShow = !this.isShow;

            if (onClick) {
                onClick(evt);
            }
        }
    };

    recordPopup = () => {
        const { essence, sys_id, isServicePortal, isWindow, refColumnId } = this.props;


        return (
            <RecordPopup
                parent={ this.refButton.current }
                ref="popup"
                essence={ essence }
                sys_id={ sys_id }
                refColumnId={refColumnId}
                isShow={ this.isShow }
                onToggle={ this.handleToggle }
                freezeScreen={ true }
                isServicePortal={ isServicePortal }
                isWindow={ isWindow }
            />
        );
    };

    render() {
        const { buttonType, className, children, svg, hint } = this.props;

        const btnType = buttonType || 'icon';
        const image = svg || '';
        return (
            <>
                <Button
                    buttonType={ btnType }
                    svg={ image }
                    className={ className }
                    onClick={ this.handleToggle }
                    ref={ this.refButton }
                    hint={ hint }
                >
                    { children }
                </Button>
                { this.recordPopup() }
            </>
        );
    }
}

export default observer(RecordInfoBtnComponent);