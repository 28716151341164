import * as React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import StringInput from 'components/dynamicForms/view/field/stringInput';
import FieldWrapper from 'components/dynamicForms/view/fieldWrapper';

import styles from './styles.module.scss';
import { observable, makeObservable } from 'mobx';
import IconClose from 'assets/img/icons/close-circle.svg';
import OptionsInputModel from 'components/dynamicForms/model/field/OptionsInputModel';
import StringInputModel from 'components/dynamicForms/model/field/StringInputModel';

import { ATTRIBUTES } from 'constants/attributesForTests';
import Dropdown from 'components/dropdown';
import IconArrowDown from 'assets/img/icons/chevron-down.svg';
import { getTestNameField } from 'helpers/data';
import { isChanged } from 'helpers/form';

/**
 * Описание: компонент optionsInput
 * Параметры:
 *
 className
 readonly
 value
 placeholder
 valueOpts
 maxLength
 onChange
 onPaste
 onKeyUp
 maxLength

 */
class OptionsInput extends React.Component {
    model;
    stringInputModel;
    isEdit = true; // режим редактирования
    isListOpened = false;

    refField;
    refDropdown = React.createRef();
    stringInputRef;

    constructor(props) {
        super(props);

        makeObservable(this, {
            model: observable,
            stringInputModel: observable,
            isEdit: observable,
            isListOpened: observable,
        });

        if (props.model) {
            this.model = props.model;
        } else {
            this.model = new OptionsInputModel(props);
        }

        const stringInputValue = this.model.value && _.isObject(this.model.value) && this.model.value.display_value || '';

        this.stringInputModel = new StringInputModel({
            readonly: this.model.readonly,
            className: this.getStringInputClass(),
            value: stringInputValue,
            placeholder: this.model.placeholder,
            maxLength: this.model.maxLength,
            showCleanBtn: false,
        });

        this.stringInputRef = this.model.innerRef || React.createRef();
        this.refField = this.model.refField || React.createRef();
        this.isListOpened = this.props.areOptionsShown;
        this.isEdit = !this.model.value.is_option;
    }

    componentDidMount() {
        document.addEventListener('click', this.onDocumentClick);
        window.addEventListener('scroll', this.onModalClose);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onDocumentClick);
        window.removeEventListener('scroll', this.onModalClose);
    }

    onDocumentClick = (e) => {
        const dropdownEl = this.refDropdown ? this.refDropdown.current : null;
        const inputEl = this.refField.current;
        if (!dropdownEl || !inputEl) return false;

        if (!dropdownEl.contains(e.target) && !inputEl.contains(e.target)) {
            this.toggleOptions(false);
        }
    };

    componentDidUpdate(prevProps) {
        if (!_.isEqual(
            _.filter(this.props, (el) => typeof el !== 'function'),
            _.filter(prevProps, (el) => typeof el !== 'function'),
        )) {
            if (this.props.model) {
                this.model = this.props.model;
                this.model.value = this.model.convertValueToOption(this.props.model.value);
            } else {
                this.model.mergeData(this.props, ['value']);
                this.model.value = this.model.convertValueToOption(this.props.value);
            }
        }

        const { value } = this.model;

        const stringInputValue = value && _.isObject(value) && value.display_value || '';

        this.stringInputModel.mergeData({
            readonly: this.model.readonly,
            className: this.getStringInputClass(),
            value: stringInputValue,
            placeholder: this.model.placeholder,
            maxLength: this.model.maxLength,
            showCleanBtn: false,
        });
        if (this.props.areOptionsShown !== prevProps.areOptionsShown){
            this.isListOpened = this.props.areOptionsShown;
        }
    }

    onInputChange = (inputModel) => { // изменение значения stringinput
        this.model.value = {
            display_value: inputModel.value,
            database_value: inputModel.value,
            is_option: false,
        };
        this.props.onChange && this.props.onChange(this.model);
    };

    handleFieldClear = () => { // очитска по нажатию на крестик
        this.model.value = {
                display_value: '',
                database_value: null,
                is_option: false,
            };
        this.stringInputModel.value = '';
        this.model.changed = isChanged(this.model.defaultValue, this.model.value);
        this.toggleOptions(false);
        this.isEdit = true;
        this.props.onChange && this.props.onChange(this.model);
    };

    setReadonlyForStringInput = (value) => {
        this.stringInputModel.readonly = this.model.readonly ? true : value;
    };

    getStringInputClass() {
        const optsMode = Array.isArray(this.model.valueOpts) && this.model.valueOpts.length > 0;
        return `${ styles.StringInput } ${optsMode ? styles.WithOpts : '' }`;
    }

    toggleOptions(state){
        if (state !== undefined){
            this.isListOpened = state;
        } else {
            this.isListOpened = !this.isListOpened;
        }
        this.props.onOptionsToggle && this.props.onOptionsToggle(this.isListOpened);
    }

    onDropDownArrowClick = () => {
        this.toggleOptions();
    };

    onItemClick = (option) => { // выбор элемента из выпадающего меню
        this.model.value = {
            display_value: option.display_value,
            database_value: option.database_value,
            is_option: true,
        };
        this.model.currentOpt = option.database_value;
        this.toggleOptions(false);
        this.isEdit = false;
        this.stringInputModel.value = this.model.value.display_value;
        this.setReadonlyForStringInput(true);
        if (this.props.onChange) {
            this.props.onChange(this.model);
        }
    };

    onItemHover = (database_value) => {
        this.model.currentOpt = database_value;
    };

    handleStringInputClick = () => {
        if (!this.isEdit && !this.model.readonly){
            this.handleFieldClear();
            this.setReadonlyForStringInput(false);
        }
    };

    handlePaste = (evt) => {
        if (this.props.onPaste){
            this.props.onPaste(evt);
        } else {
            evt.stopPropagation();
            evt.preventDefault();
            const clipboardData = evt.clipboardData || window.clipboardData;
            let pastedData = clipboardData.getData('text/plain');
            if (pastedData && this.model.maxLength > 0){
                pastedData = pastedData.substring(0, this.model.maxLength);
            }
            this.model.value = {
                display_value: pastedData,
                database_value: pastedData,
                is_option: false,
            };
            this.props.onChange && this.props.onChange(this.model);
        }
    };

    renderMenu() {
        const items = this.model.valueOpts.map((option, index) => {
            const databaseValueToString = option.database_value ? option.database_value.toString() : option.database_value;
            const className = `${ styles.MenuItem } ${ option.database_value === this.model.currentOpt ? styles.active : '' } ${ this.model.value && databaseValueToString === this.model.value.database_value ? styles.selected : '' }`;
            return (
                <div
                    className={ className }
                    key={ option.database_value + index.toString() }
                    onClick={ () => this.onItemClick(option) }
                    onMouseEnter={ () => this.onItemHover(option.database_value) }
                    data-test={ ATTRIBUTES.customSelectDropdownItem }
                >
                    { option.display_value }
                </div>
            );
        });

        return <div className={ styles.Menu }>{ items }</div>;
    }

    render() {
        const { className } = this.model; //value, special
        const fieldName = getTestNameField(this.model);
        const dataTest = (fieldName !== 'unknown') ? `${ fieldName }-${ ATTRIBUTES.fieldOptionsInput }` : `${ ATTRIBUTES.fieldOptionsInput }`;
        const optsMode = Array.isArray(this.model.valueOpts) && this.model.valueOpts.length > 0;
        const clearButton = !this.model.readonly && !this.model.isEmptyValue() &&
            <div
                onClick={ this.handleFieldClear }
                className={ `${ styles.FieldClear } ${optsMode ? styles.WithOpts : '' }` }
                dangerouslySetInnerHTML={ { __html: IconClose } }
                data-test={ ATTRIBUTES.clearFieldButton }
            />;

        const dropdownButton = !this.model.readonly && optsMode &&
            <div
                onClick={ this.onDropDownArrowClick }
                className={ styles.DropDownArrow }
                dangerouslySetInnerHTML={ { __html: IconArrowDown } }
                data-test={ ATTRIBUTES.optionSelectButton }
            />;

        const stringInput =
            <div onClick={ this.handleStringInputClick }>
                <StringInput
                    model={ this.stringInputModel }
                    ref={ this.stringInputRef }
                    onChange={ this.onInputChange }
                    autofill="off"
                    autoComplete="off"
                    onKeyUp={ this.props.onKeyUp }
                    onPaste={ this.handlePaste }
                />
            </div>;

        return (
            <FieldWrapper model={ this.model }>
                <div className={ ` ${ this.model.readonly ? styles.readOnly : '' } ${ styles.OptionsInput } ${ className ? className : '' } ` }
                     data-test={ this.props['data-test'] ? this.props['data-test'] : dataTest }
                >
                    <div className={ styles.input } ref={ this.refField }>
                        { stringInput }
                        { clearButton }
                        { dropdownButton }
                    </div>
                    { this.isListOpened &&
                    <Dropdown refParent={ this.refField } ref={ this.refDropdown } data-test={ ATTRIBUTES.customSelectDropdownMenu }>
                        { this.renderMenu() }
                    </Dropdown>
                    }
                </div>
            </FieldWrapper>
        );
    }
}

export default observer(OptionsInput);
