import apiRequest from 'lib/apiRequest';
import { handleResponse } from 'helpers/action';
import {
    ActivityChangeColumnData,
    ActivityChangeTypeData,
    ActivityFeedGetResponseParams,
    ActivitySendCommentData,
    ActivitySendCommentParams,
} from 'types/components/activityFeed/index';
import { ActivitiesResponse, ActivityFeedResponse } from 'types/actions/activityFeed';

/**
 * Запрос для получения данных activity feed
 * @param params
 * @returns {Promise<{data: *, response: *, isOkStatus: boolean}|{data: {}, response: *, isOkStatus: boolean}>}
 */
export const fetchActivityFeedResponse = async (params: ActivityFeedGetResponseParams): Promise<ActivityFeedResponse> => {
    const response = await new apiRequest(`GET /activity-feed/get-response`).qs(params).send();
    return handleResponse(response);
};

/**
 * Запрос для получения activities
 * @param params
 * @returns {Promise<{data: *, response: *, isOkStatus: boolean}|{data: {}, response: *, isOkStatus: boolean}>}
 */
export const fetchActivityFeedActivities = async (params: ActivityFeedGetResponseParams): Promise<ActivitiesResponse> => {
    const response = await new apiRequest(`GET /activity-feed/get-activities`).qs(params).send();
    return handleResponse(response);
};

export const sendComment = async (params: ActivitySendCommentParams, data: ActivitySendCommentData): Promise<ActivitiesResponse> => {
    const response = await new apiRequest(`POST /activity-feed/create-thread`).qs(params).sendJSON(data);
    return handleResponse(response);
};

export const changeTypeFilter = async (params: ActivitySendCommentParams, data: ActivityChangeTypeData): Promise<ActivitiesResponse> => {
    const response = await new apiRequest(`POST /activity-feed/change-type-filter`).qs(params).sendJSON(data);
    return handleResponse(response);
};

export const changeColumnFilter = async (params: ActivitySendCommentParams, data: ActivityChangeColumnData): Promise<ActivitiesResponse> => {
    const response = await new apiRequest(`POST /activity-feed/change-column-filter`).qs(params).sendJSON(data);
    return handleResponse(response);
};

export const createActivityType = async (params: ActivitySendCommentParams): Promise<ActivityFeedResponse> => {
    const response = await new apiRequest(`POST /activity-feed/create-activity-type`).qs(params).send();
    return handleResponse(response);
};
