import modalData from 'globalState/modal';
export default class SimpleModal {

    setWidth (width) {
        modalData.setWidth(width);
    }

    setShow (isShow) {
        modalData.setShow(isShow);
    }

    setTitle (title) {
        modalData.setTitle(title);
    }

    setIsLoading (isLoading) {
        modalData.setIsLoading(isLoading);
    }

    renderTemplate (template, script) {
        modalData.renderTemplate(template, script);
    }

    renderPageTemplate (portalSuffix, pageTemplatePathName) {
        modalData.renderPageTemplate(pageTemplatePathName, portalSuffix);
    }

    renderPagePathTemplate (pageTemplatePathName, portalSuffix) {
        modalData.renderPageTemplate(pageTemplatePathName, portalSuffix);
    }
}
