import React, {FC, useEffect, useState, useRef, useCallback} from 'react';
import {Link, useLocation} from "react-router-dom";
import _ from 'lodash';
import {COMMAND_PRIORITY_LOW, createCommand} from "lexical";
import {mergeRegister} from '@lexical/utils';
import {HeadingNode} from '@lexical/rich-text';
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import styles from './styles.module.scss';
import MobileModal from 'components/modalWrapper';
import Button from "components/button";
import Hint from "components/hint";
import langStore from 'globalState/lang';
import {isMedia} from "helpers/html";
import IconChevronRight from 'assets/img/icons/chevron-right.svg';
import IconClose from "assets/img/icons/close-x.svg";
import {ATTRIBUTES} from "constants/attributesForTests";
import type {MenuItem, NavigatorProps} from "types/components/textEditor/navigationPlugin";
import {Tag} from "types/components/textEditor/navigationPlugin";

const LINK_TEMPLATE = 'heading-key-';

const createDOM = HeadingNode.prototype.createDOM;
HeadingNode.prototype.createDOM = function () {
    // eslint-disable-next-line prefer-rest-params
    const dom = createDOM.apply(this, arguments);
    dom.setAttribute('id', `${LINK_TEMPLATE}${this.__key}`);
    return dom;
};

export const TOGGLE_NAVIGATION_COMMAND = createCommand();

export const NavigationPlugin: FC<NavigatorProps> = ({editorRef, fullSize, isAdditional}) => {
    if (!isAdditional) {
        return null;
    }

    const [editor] = useLexicalComposerContext();
    const [data, setData] = useState<MenuItem[]>([]);
    const [showPanel, setShowPanel] = useState(true);
    const [showMenu, setShowMenu] = useState(false);
    const [isMediaSm, setIsMediaSm] = useState(() => isMedia('sm'));
    const [blockIndexScroll, setBlockIndexScroll] = useState(false);
    const btnRef = useRef<HTMLDivElement>(null);
    const location = useLocation();

    const { rich_text } = langStore.getTranslate();

    const checkScrollAndParseText = useCallback((data: MenuItem[]) => (el) => {
        if (blockIndexScroll) return;

        const newData: MenuItem[] = [];
        const offset = 10;

        for (const item of data) {
            const element = document.getElementById(item.link);
            if (!element) {
                newData.push(item);
            } else {
                let newItem = {} as MenuItem;

                if ((el.target.scrollTop + offset) > element?.offsetTop) {
                    newItem = {...item, isActive: true};
                    newData.forEach(el => el.isActive = false);
                } else {
                    newItem = {...item, isActive: false};
                }
                newData.push(newItem);
            }
        }
        setData(newData);
    }, [blockIndexScroll]);

    useEffect(() => {
        const editorEl = editorRef.current;
        const throttleScroll = _.throttle(checkScrollAndParseText(data), 50);

        editorEl?.addEventListener("scroll", throttleScroll);
        return () => editorEl?.removeEventListener("scroll", throttleScroll);
    }, [data, editorRef, blockIndexScroll]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({editorState}) => {
                editorState.read(() => {
                    let parseData: MenuItem[] = [];
                    const nodes = editorState._nodeMap;
                    for (let [key, node] of nodes) {
                        if (node instanceof HeadingNode && [Tag.H1, Tag.H2].includes(node.getTag() as Tag)) {
                            const childNode = node.getFirstChild();
                            if (!childNode) continue;
                            const tag = node.getTag() as Tag;
                            const parseItem = {
                                key: node.getKey(),
                                link: `${LINK_TEMPLATE}${key}`,
                                title: childNode.getTextContent(),
                                tag,
                            };
                            parseData.push(parseItem);
                        }
                    }
                    const root = editorState._nodeMap.get('root');
                    const rootChildren = root?.__children;
                    const sortedData = parseData.sort((a, b) => {
                        const indexA = rootChildren?.indexOf(a.key);
                        const indexB = rootChildren?.indexOf(b.key);
                        return indexA - indexB;
                    });
                    checkScrollAndParseText(sortedData)({target: editorRef.current});
                });
            }),
            editor.registerCommand(
                TOGGLE_NAVIGATION_COMMAND,
                (state: boolean) => {
                    setShowMenu(state);
                    return true;
                },
                COMMAND_PRIORITY_LOW,
            ),
        );
    }, [editor, setShowMenu, blockIndexScroll]);

    useEffect(() => {
        const onResize = () => {
            setIsMediaSm(isMedia('sm'));
        };
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [isMedia]);

    const handleClick = (item: MenuItem) => () => {
        const selectedField = document.getElementById(item.link);
        if (selectedField && !item.isActive) {
            const editorEl = editorRef.current;
            setBlockIndexScroll(true);
            editorEl?.scroll({top: selectedField.offsetTop, behavior: 'smooth'});
            setData(
                (data) =>
                    data.map(i => i.key === item.key ? {...item, isActive: true} : {...i, isActive: false}
                    ));
            setTimeout(() => setBlockIndexScroll(false), 1000);
        }
        setShowMenu(false);
    };

    const handleTogglePanel = () => {
        setShowPanel((prevState) => !prevState);
    };

    const renderLink = (item: MenuItem) => {
        const { search } = location;
        const link = (
            <Link
                key={`Item${item.link}${item.title}${item.key}`}
                to={`${search}#${item.link}`}
                onClick={handleClick(item)}
                data-test={ATTRIBUTES.editorNavTitle}
                className={`${styles.LinkText} ${styles.LinkWrap} ${item.isActive ? styles.LinkActive : ''}`}
            >
                {item.title}
            </Link>
        );

        const subLink = (
            <Link
                key={`Item${item.link}${item.title}${item.key}`}
                to={`${search}#${item.link}`}
                onClick={handleClick(item)}
                data-test={ATTRIBUTES.editorNavSubTitle}
                className={`${styles.LinkWrap} ${styles.SubLink} ${item.isActive ? styles.LinkActive : ''}`}
            >
                <div className={styles.Dot}/>
                <span className={styles.LinkText}>
                    {item.title}
                </span>
            </Link>
        );

        switch (item.tag) {
            case Tag.H1:
                return link;
            case Tag.H2:
                return subLink;
        }
    };

    const renderNotFound = () => (
        <div className={styles.NotFound}>
            {rich_text.not_found}
        </div>
    );

    const isEmptyData = !data.length;

    const classNames = [styles.NavContainer];
    if (showPanel) {
        classNames.push(styles.Show);
    } else {
        classNames.push(styles.Hide);
    }
    if (isEmptyData) {
        classNames.push(styles.NoScroll);
    }
    if (fullSize) {
        classNames.push(styles.FullSize);
    }

    if (isMediaSm) {
        if (!showMenu) return null;

        return (
            <MobileModal>
                <div className={styles.MobileNavContainer}>
                    <div className={styles.MobileHeader}>
                        <div className={styles.MobileTitle}>
                            {rich_text.mobile_title}
                        </div>
                        <Button
                            buttonType={'icon'}
                            svg={IconClose}
                            onClick={() => {
                                setShowMenu((state) => !state);
                            }}
                            className={styles.MobileClose}
                        />
                    </div>
                    <div className={styles.MobileMenu}>
                        {isEmptyData ? renderNotFound() : data.map(renderLink)}
                    </div>
                </div>
            </MobileModal>
        );
    }


    return (
        <>
            <div
                className={`${styles.OpenButton} ${showPanel ? styles.Revert : ''}`}
                dangerouslySetInnerHTML={{__html: IconChevronRight}}
                onClick={handleTogglePanel}
                data-test={ATTRIBUTES.editorOpenNavButton}
                ref={ btnRef }
            />
            <div className={classNames.join(' ')}>
                {isEmptyData ? renderNotFound() : data.map(renderLink)}
            </div>
            <Hint refParent={ btnRef }>{ showPanel ? rich_text.collapse_sections_menu : rich_text.expand_sections_menu }</Hint>
        </>

    );
};

