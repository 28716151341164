import { helperRedirect } from 'helpers/history';
import { beforeUnload } from 'helpers/form';
import FormsState from 'globalState/forms';

let timeout = null;

export default class SimpleGo {
	open(url, target, callback) {
		clearTimeout(timeout);
        if (target) {
            let newWin = window.open(url, target);
            newWin.onload = function() {
                setTimeout(() => {
                    callback(newWin);
                }, 1000);
            };
            return;
        }
		timeout = setTimeout(() => {
			helperRedirect(url, callback);
		}, 100);
	}

	openList(table, query) {
		let url = '/list/' + table;
		if (query) url += "?" + query;
		this.open(url);
	}

	openRecord(table, sys_id) {
		const url = '/record/' + table + '/' + sys_id;
		this.open(url);
	}

	reloadWindow() {
		if (window.location.reload) window.location.reload();
	}

	getURL() {
		return window.location.href;
	}

	back() {
		const unload = beforeUnload({});
		if (unload && !confirm(unload)) {
			return false;
		}
		FormsState.clear();
		window.history.back();
	}
}
