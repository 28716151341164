import {isEmpty} from 'helpers/isEmpty';
import {Duration} from "types/helpers/DurationMsConverter";

export const seconds = 1000;
export const minutes = seconds * 60;
export const hours = minutes * 60;
export const days = hours * 24;

export default class DurationMsConverter {
    static toMs(duration: Duration): string | number {
        if (!duration || duration.days === '' && duration.hours === '' && duration.minutes === '' && duration.seconds === '') return '';

        return (duration.days as number) * days +
            (duration.hours as number) * hours +
            (duration.minutes as number) * minutes +
            (duration.seconds as number) * seconds;
    }

    static toDuration(ms: number): Duration {
        let duration: Duration = {
            days: '',
            hours: '',
            minutes: '',
            seconds: '',
        };

        if (isEmpty(ms)) {
            if (ms === 0) {
                duration.seconds = ms;
            }
            return duration;
        }

        duration.ms = ms;
        duration.days = Math.floor(duration.ms / days);
        let remainder = duration.ms % days;
        duration.hours = Math.floor(remainder / hours);
        remainder = remainder % hours;
        duration.minutes = Math.floor(remainder / minutes);
        remainder = remainder % minutes;
        duration.seconds = Math.floor(remainder / seconds);

        return duration;
    }

    static msToString(ms: number): string {
        const duration = DurationMsConverter.toDuration(ms);
        let result = duration.days === 0 ? '' : duration.days + 'd';
        result += duration.hours === 0 ? '' : ' ' + duration.hours + 'h';
        result += duration.minutes === 0 ? '' : ' ' + duration.minutes + 'm';
        result += duration.seconds === 0 ? '' : ' ' + duration.seconds + 's';
        return result;
    }
}
