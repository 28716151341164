// Поддержка finally для IE и Edge
Promise.prototype.finally = Promise.prototype.finally || {
    finally(fn) {
        const onFinally = value => Promise.resolve(fn()).then(() => value);
        return this.then(
            result => onFinally(result),
            reason => onFinally(Promise.reject(reason)),
        );
    },
}.finally;

// Полифил closest для IE
(function () {
    // проверяем поддержку
    if (!Element.prototype.closest) {
        // реализуем
        Element.prototype.closest = function (css) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            let node = this;
            while (node) {
                if (node.matches(css)) {
                    return node;
                }
                else {
                    node = node.parentElement;
                }
            }
            return null;
        };
    }
})();

// Полифил matches для IE
(function () {
    // проверяем поддержку
    if (!Element.prototype.matches) {
        // определяем свойство
        Element.prototype.matches = Element.prototype.matchesSelector ||
            Element.prototype.webkitMatchesSelector ||
            Element.prototype.mozMatchesSelector ||
            Element.prototype.msMatchesSelector;
    }
})();

// Полифил remove для IE
(function () {
    function remove() {
        this.parentNode && this.parentNode.removeChild(this);
    }

    if (!Element.prototype.remove) Element.prototype.remove = remove;
    if (Text && !Text.prototype.remove) Text.prototype.remove = remove;
})();

window.onstorage = function(){
};
