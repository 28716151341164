import apiRequest from 'lib/apiRequest';
import {handleResponse} from 'helpers/action';
import {HandleResponse} from "types/actions/main";
import {AuthLoginParams, AuthLoginResponse, LoginData, SignupData, UserDataResponse} from "types/globalState/user";
import {
    AuthAccessDataResponse,
    AuthTokenFromIdpResponse,
    AuthTranslateDataResponse,
    DropAccessTokenResponse,
    SsoInfoResponse,
} from "types/actions/auth";

/**
 * Получение данных для формы входа
 * */
export const fetchSsoInfo = async (): Promise<SsoInfoResponse> => {
    const response = await new apiRequest(`GET /sso/login`).send();
    return handleResponse(response);
};

/**
 * Получение токена пользователя из публ. ключа idp
 * */
export const getAuthTokenFromIdp = async (data): Promise<AuthTokenFromIdpResponse> => {
    const response = await new apiRequest(`POST /saml/auth`).sendJSON(data);
    return handleResponse(response);
};

/**
 * Выход пользователя из системы и idp
 * */
export const fetchSsoLogout = async (): Promise<HandleResponse> => {
    const response = await new apiRequest(`POST /sso/logout`).send();
    return handleResponse(response);
};

/**
 * Запрос для удаления токена из БД
 */
export const fetchDropAccessToken = async (): Promise<DropAccessTokenResponse> => {
    const response = await new apiRequest(`POST /user/logout`).send();
    return handleResponse(response);
};

/**
 * Получение данных для формы входа
 * */
export const fetchAuthAccessData = async (): Promise<AuthAccessDataResponse> => {
    const response = await new apiRequest(`GET /auth/access-data`)
        .caching()
        .send();
    return handleResponse(response);
};

/**
 * Получение переводов для формы входа
 * */
export const fetchAuthTranslateData = async (lang: string): Promise<AuthTranslateDataResponse> => {
    const response = await new apiRequest(`GET /auth/dictionary${lang}`)
        .caching()
        .send();
    return handleResponse(response);
};

/**
 * Запрос на авторизацию
 * */
export const fetchAuthLogin = async (params: AuthLoginParams, data: LoginData): Promise<AuthLoginResponse> => {
    const response = await new apiRequest(`POST /auth/login`, false)
        .qs(params)
        .sendJSON(data);
    return handleResponse(response);
};

/**
 * Запрос на авторизацию для sideDoor
 * */
export const fetchAuthLoginSideDoor = async (params: AuthLoginParams, data: LoginData): Promise<AuthLoginResponse> => {
    const response = await new apiRequest(`POST /auth/side-door`, false)
        .caching()
        .qs(params)
        .sendJSON(data);
    return handleResponse(response);
};

/**
 * Запрос на регистрацию
 * */
export const fetchSignUp = async (params: AuthLoginParams, data: SignupData): Promise<AuthLoginResponse> => {
    const response = await new apiRequest(`POST /signup`, false)
        .caching()
        .qs(params)
        .sendJSON(data);
    return handleResponse(response);
};

/**
 * Получение данных пользователя
 * */
export const fetchUser = async (): Promise<UserDataResponse> => {
    const response = await new apiRequest(`GET /user/me`)
        .caching()
        .send();
    return handleResponse(response);
};

/**
 * Сброс пароля пользователя
 * */
export const fetchRestPass = async (params: object, data: object): Promise<HandleResponse> => {
    const response = await new apiRequest(`POST /reset/request`, false)
        .qs(params)
        .sendJSON(data);
    return handleResponse(response);
};

/**
 * Установка нового пароля пользователя
 * */
export const fetchNewPassword = async (params: object, data: object): Promise<HandleResponse> => {
    const response = await new apiRequest(`POST /reset/create-new-password`, false)
        .qs(params)
        .sendJSON(data);
    return handleResponse(response);
};
