import { observable, makeObservable } from 'mobx';
import * as _ from 'lodash';
import {
    ElementMessageType,
    FieldsType,
    FormsStateType,
    SectionsType,
    DynamicFormsType,
    SerializeReturnType,
    WidgetData,
} from 'types/globalState/forms';
import { FormMessages } from 'components/formMessages';
import { RelatedLists } from 'components/relatedLists';
import { Tabs } from 'components/tabs';
import FormSectionModel from 'components/dynamicForms/model/FormSectionModel';
import DynamicFormModel from 'components/dynamicForms/model/DynamicFormModel';
import { isEqualDynamicForm } from 'helpers/form';
import { BaseFormFieldType } from 'types/components/dynamicForms/baseFormField';


const ERROR_MESSAGE = 'error';

export class FormsState implements FormsStateType {
    private _dynamicForms: DynamicFormsType = new Map(); // Массив форм
    private _sections: SectionsType = new Map(); // Массив секций
    private _tabs: Tabs[] = []; // Массив ссылок на компоненты Tabs
    private _relatedLists; // Список связанных списков //todo type // TODO Используется и для хранения компонента RelatedLists и списка связанных листов с индексом name
    private _messages: FormMessages | null; //Ссылка на компонент FormMessages
    private _widgets: WidgetData[] = []; // Список виджетов на форме //todo type

    constructor() {
        makeObservable(this, {
            _dynamicForms: observable,
            _sections: observable,
            _widgets: observable,
        });
    }

    /*
    * Добавить форму
    * */
    addDynamicForm = (dynamicFormId: string, form: DynamicFormModel) => {
        this._dynamicForms.set(dynamicFormId, form);
    };

    /*
     * Удалить форму
     * */
    deleteDynamicForm = (dynamicFormId: string) => {
        this.deleteSections(dynamicFormId);
        this._dynamicForms.delete(dynamicFormId);
    };

    getDynamicForm = (dynamicFormId): DynamicFormModel | undefined => {
        return this._dynamicForms.get(dynamicFormId);
    };

    findDynamicForm = (tableName: string, recordId: string) => {
        const dynamicForms = this.getDynamicForms();
        return dynamicForms.find((form) => form.tableName === tableName && form.sysId === recordId);
    };

    getDynamicForms = (): DynamicFormModel[] => {
        return Array.from(this._dynamicForms.values());
    };

    getDynamicFormBySysId = (tableName: string, sysId = '', view = ''): DynamicFormModel | undefined => {
        const dynamicForms = Array.from(this._dynamicForms.values());
        return _.find(dynamicForms, form => {
            return isEqualDynamicForm(form, tableName, sysId, view);
        });
    };

    getWidgets = (): WidgetData[] => {
        return this._widgets;
    };

    setWidgets = (widgets: WidgetData[]): void => {
        this._widgets = widgets;
    };

    addWidget = (name: string, widget: any): void => {
        this._widgets.push({ ...widget, name });
    };

    removeWidget = (widgetName: string): void => {
        this._widgets = _.filter(this._widgets, w => w.name !== widgetName);
    };

    /**
     * Добавить секцию в список секций для record
     * */
    addSection = (dynamicFormId: string, section: FormSectionModel) => {
        if (!this._sections.get(dynamicFormId)) {
            this._sections.set(dynamicFormId, []);
        }
        const formSections = this._sections.get(dynamicFormId);
        formSections?.push(section);
    };

    /*
     * Получить список всех секций
     * */
    getSections = (dynamicFormId: string): FormSectionModel[] => {
        return this._sections.get(dynamicFormId) || [];
    };

    /*
     * Получить секцию
     * */
    getSection = (dynamicFormId: string, id?: string | null | undefined): FormSectionModel | null | undefined => {
        const sections: FormSectionModel[] = this.getSections(dynamicFormId);
        let result: FormSectionModel | null | undefined = null;
        if (sections) {
            result = id ? sections.find(section => section.id === id) : sections[0];
        }
        return result || null;
    };

    /*
     * Удалить секцию
     * */
    deleteSection = (dynamicFormId: string, id: string) => {
        const formSections = this._sections.get(dynamicFormId) || [];
        const newFormSections = formSections.filter((section) => (section.id !== id));
        this._sections.set(dynamicFormId, newFormSections);
    };

    deleteSections = (dynamicFormId: string) => {
        this._sections.delete(dynamicFormId);
    };

    /*
     * Получить ссылку на объект Tabs
     * Параметры:
     * */
    getTabs = (dynamicFormId: string): Tabs => {
        return this._tabs[dynamicFormId];
    };

    /*
     * Установить ссылку на объект Tabs
     * Параметры:
     * sections - ссылка на объект Tabs
     * */
    setTabs = (dynamicFormId: string, tabs: Tabs) => {
        if (!this._tabs) {
            this._tabs = [];
        }
        this._tabs[dynamicFormId] = tabs;
    };

    /*
     * Удалить ссылку на объект Tabs
     * Параметры:
     * */
    deleteTabs = (dynamicFormId: string) => { // Удалить список секций
        if (this._tabs[dynamicFormId]) {
            delete this._tabs[dynamicFormId];
        }
    };

    /*
     * Получить поля формы по dynamicFormId или все
     * */
    getFields = (dynamicFormId = ''): Record<string, FieldsType> | {} => {
        if (dynamicFormId && _.isEmpty(this._sections.get(dynamicFormId))) {
            return [];
        }

        let sections: FormSectionModel[];
        if (dynamicFormId) {
            sections = this._sections.get(dynamicFormId) || [];
        } else {
            const sectionValues = Array.from(this._sections.values());
            sections = ([] as FormSectionModel[]).concat(...sectionValues);
        }

        return _.reduce(sections, (result, section) => {
            const _fields = {};
            _.forEach(section.elements, (fieldModel: BaseFormFieldType) => {
                if (fieldModel.sysColumnName) {
                    _fields[fieldModel.sysColumnName] = fieldModel;
                }
                if (fieldModel.columnId) {
                    _fields[fieldModel.columnId] = fieldModel;
                }
            });
            return { ...result, ..._fields };
        }, {});
    };

    /*
     * Получить ссылку на компонент поля
     * Параметры:
     * name - название поля
     * */
    getField = (name: string): FieldsType => { //
        const fields = this.getFields();
        return fields[name];
    };

    /*
     * Отчистить флаг change у полей формы
     * Параметры:
     * */
    clearChangedFields = (dynamicFormId: string) => {
        const fields = this.getFields(dynamicFormId);
        _.forEach(fields, (field: FieldsType) => {
            field.changed = false;
        });
    };

    setElementMessage = ({ field, message, type = ERROR_MESSAGE }: ElementMessageType) => {
        let element = this.getField(field);
        if (element) {
            element.notice = { message, type };
        }
    };

    setFieldsMessages = (errors: ElementMessageType[]) => {
        errors.forEach(this.setElementMessage);
    };

    /*
     * Получить массив связанных списков
     * */
    getRelatedLists = () => {
        return this._relatedLists;
    };

    /*
     * Получить ссылку на связанный список
     * Параметры:
     * name - имя связанного списка
     * */
    getRelatedList = (name: string) => {
        return this._relatedLists[name];
    };

    /*
     * Установить ссылку на связанный список
     * Параметры:
     * name - имя связанного списка
     * relatedList - объект связанного списка
     * */
    setRelatedList = (name: string, relatedList) => {
        this._relatedLists[name] = relatedList;
    };

    /*
     * Установить ссылку на массив связанных списков
     * Параметры:
     * relatedLists - массив связанных списков
     * */
    setRelatedLists = (relatedLists: RelatedLists) => {
        this._relatedLists = relatedLists;
    };

    /*
     * Установить ссылку на компонент FormMessages
     * Параметры:
     * messages - компонент FormMessages
     * */
    setMessages = (messages: FormMessages) => {
        this._messages = messages;
    };

    /*
     * Получить ссылку на компонент FormMessages
     * */
    getMessages = (): FormMessages | null => {
        return this._messages;
    };

    /*
     * Получить ссылку на модель (store) форму для отображения модели атрибутов
     * */
    getRemSection = (dynamicFormId: string, tableName: string, sysId: string): FormSectionModel | undefined => {
        const sections = this._sections.get(dynamicFormId) || [];
        return _.find(sections, (section) => !!section.remId && section.sysId === sysId && section.tableName === tableName); // одная запись - одна модель
    };

    serialize = (dynamicFormId: string, isAll: boolean, includeRemAttrs = true): SerializeReturnType => {
        const sections = this._sections.get(dynamicFormId) || [];
        let data = {};
        let error: string[] = [];
        sections.forEach((section) => {
            if (section.remId === undefined || includeRemAttrs && section.remId !== undefined) {
                const sectionFields: SerializeReturnType = section.serialize(isAll);
                data = { ...data, ...sectionFields.data };
                error = [ ...error, ...sectionFields.error ];
            }
        });
        return { data, error };
    };

    clear = (isClearMessage = true) => {
        this._dynamicForms.clear();
        this._sections.clear();
        this._tabs = [];
        this._relatedLists = [];
        if (isClearMessage) {
            this._messages = null;
        }
        this._widgets = [];
    };

    getSubForm = () => {
        let output;
        let subForm;
        this._dynamicForms.forEach((form) => {
            if (form.isSubForm) {
                subForm = form;
            }
        });
        if (subForm) {
            const formSections = this._sections.get(subForm.id) || [];
            output = formSections[0];
        }
        return output;
    };
}

export default new FormsState();
