import * as React from 'react';
import { observer } from 'mobx-react';

import styles from './styles.module.scss';

/***
 * Описание: Radio Buttons
 * Праметры:
 * value: {required: true, type: string} - выбранное значение
 * disabled: {required: false, type: boolean} - readOnly
 * options: {required: true, type: array} - массив доступных значений(database_value/display_value)
 * onChange: {required: true, type: function} - метод вызываемый при изменении выбранного значения(принимает database_value)
 */
class RadioButtons extends React.Component {
    constructor(props) {
        super(props);
    }

    renderOptions = () => {
        const { value, options, disabled } = this.props;

        if(!options) return;

        return options.map((item, index) => {
            const { database_value: itemValue, display_value: itemText } = item;

            let btnClasses = [styles.Button];
            if (itemValue === value) {
                btnClasses.push(styles.checked);
            }

            btnClasses = btnClasses.join(' ');

            let txtClasses = [styles.Text];
            if(disabled){
                txtClasses.push(styles.disabled);
            }

            txtClasses = txtClasses.join(' ');

            let optionClasses = [styles.Option];

            optionClasses = optionClasses.join(' ');

            return (
                <div className={ optionClasses } key={ `${itemValue}-${index}` }>
                    <button
                        type={ 'button' }
                        className={ btnClasses }
                        onClick={ this.onOptionClick(itemValue) }
                        disabled={disabled}
                    />
                    <div className={ txtClasses }><span onClick={ this.onOptionClick(itemValue) }>{ itemText }</span></div>
                </div>
            );
        });
    };

    onOptionClick = (itemValue) => () => {
        const { value, onChange, disabled } = this.props;

        if (disabled || !onChange || itemValue === value ) return;

        onChange(itemValue);
    };

    render() {
        return (
            <div className={ styles.RadioButtons }>
                { this.renderOptions() }
            </div>
        );
    }
}

export default observer(RadioButtons);
