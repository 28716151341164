import apiRequest from 'lib/apiRequest';
import _ from 'lodash';

/**
 * Загрузка image
 * @param inFileData
 * @param { essence<string>, column_id<number>, record_id<number|undefined> } requestParams
 * @return {Promise<{data: *, response: ({}|undefined)}>}
 */
export const uploadFile = async (inFileData, requestParams) => {
    const fileData = {
        ...inFileData,
        errorText: '',
        error: false,
        progress: 0,
        uploadInProgress: true,
        uploaded: false,
    };

    const formData = new FormData();
    formData.append('image', inFileData.originalFileData);

    let requestUrl = `POST /image/upload/${ requestParams.essence }/${ requestParams.column_id }`;
    if (requestParams.record_id) {
        requestUrl += `/${ requestParams.record_id }`;
    }

    const response = await new apiRequest(requestUrl)
        .onUploadProgress((progressPercents) => {
            fileData.progress = progressPercents;
        })
        .send(formData);
    return {
        response,
        data: response.getData(),
    };
};

//Удаление image
export const deleteFile = async (essence, recordId, sysId) => {
    const data = _.omitBy({
        essence: essence,
        record_id: recordId,
    }, _.isEmpty);

    const response = await new apiRequest('POST /image/delete/' + sysId)
        .sendJSON(data)
        .catch(console.error);
    return {
        response,
    };
};