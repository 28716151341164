import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import SLM from 'components/slm';
import Container from 'components/container';
import ErrorWrapperHoc from 'helpers/hoc/errorWrapperHOC';

import apiRequest from 'lib/apiRequest';
import SmartTitle from 'components/smartTitle';


class SLMPage extends React.Component {
    data;
    lastRouteParams = '';

    constructor(props) {
        super(props);

        makeObservable(this, {
            data: observable,
        });

        this.fetchData().catch((err) => console.error(err));
    }

    fetchData = async () => {
        const routeParams = window.location.pathname + window.location.search;
        if (routeParams.localeCompare(this.lastRouteParams) === 0) return false;
        this.lastRouteParams = window.location.pathname + window.location.search;

        const response = await new apiRequest('GET ' + routeParams).send();
        this.data = response.getData().chart_data;

    };

    render() {
        if(!this.data) return null;
        return (
            <Container>
                <SmartTitle hidden>SLM</SmartTitle>

                <SLM data={ this.data } />
            </Container>
        );
    }
}


export default ErrorWrapperHoc(observer(SLMPage));
