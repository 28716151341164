import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, reaction, makeObservable, action } from 'mobx';
import { globalEventEmitter } from 'lib/EventEmitter';
import Menu from './menu';
import Favorite from './favorite';
import styles from './styles.module.scss';
import IconSidebar from 'assets/img/icons/sidebar.svg';
import IconStar from 'assets/img/icons/star.svg';
import IconX from 'assets/img/icons/close-x.svg';
import IconPin from 'assets/img/icons/pin.svg';
import IconPinOff from 'assets/img/icons/no-pin.svg';
import sidebarState from 'globalState/sidebarState';
import langStore from 'globalState/lang';
import { isMedia } from 'helpers/html';
import GlobalPortal from 'components/globalPortal';
import { Link } from 'react-router-dom';
import brandingState from 'globalState/branding';
import Button from 'components/button';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { beforeUnload } from 'helpers/form';
import { deleteFavorite, saveFavorite } from 'actions/menu';
import { DataMenuType, MenuItemType } from 'types/globalState/menu';
import _ from 'lodash';
import { getIsSubmenuActive } from 'helpers/sidebar';
import MenuSkeleton from 'layouts/main/sidebar/skeleton';
import DynamicHeader from 'layouts/main/sidebar/dynamicHeader';
import SidebarTabs from 'layouts/main/sidebar/tabs';

class LayoutSidebar extends React.Component {
    filterValue = '';
    filterLoading = false;
    tempFilterValue = '';
    refFilter: React.RefObject<any> = React.createRef();
    refSidebar: React.RefObject<any> = React.createRef();
    timeout: NodeJS.Timeout;
    timeoutFilter: NodeJS.Timeout;

    constructor(props) {
        super(props);

        makeObservable(this, {
            filterValue: observable,
            filterLoading: observable,
            tempFilterValue: observable,
            onDocumentClick: action,
            handleTabClick: action,
            onPinClick: action,
        });

        const handleImpersonate = () => {
            sidebarState.clear();
            sidebarState.fetchMenuPreferences().catch(console.error);
        };

        globalEventEmitter.subscribe([ 'impersonate', 'deImpersonate' ], handleImpersonate);

        reaction(
            () => sidebarState.isOpened,
            this.updateBodyScroll,
        );
    }

    componentDidMount() {
        sidebarState.fetchMenuPreferences().catch(console.error);
        this.getFilteredItems();

        window.addEventListener('resize', this.onWindowResize);
        document.addEventListener('click', this.onDocumentClick);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
        document.removeEventListener('click', this.onDocumentClick);
    }

    fetchMenuList = async () => {
        const openDynamicMenuId = window.localStorage.getItem('openDynamicMenuId') || '';
        if (!openDynamicMenuId) {
            await sidebarState.fetchMenuList().catch(console.error);
        } else {
            sidebarState.setIsOpenDynamic(true);
            sidebarState.preventCloseNav = true;
            await sidebarState.fetchDynamicMenuList(openDynamicMenuId).catch(console.error);
        }
    }

    updateBodyScroll = (isOpened) => {
        if (isMedia('sm')) { // if mobile
            if (isOpened) {
                this.freezeBodyScroll(true);
            } else {
                this.freezeBodyScroll(false);
            }
        }
    };

    freezeBodyScroll = (boolean) => {
        const { body } = document;

        if (boolean) {
            body.classList.add(styles.Freeze);
            const scrollY = window.scrollY;
            body.style.position = 'fixed';
            body.style.top = `-${ scrollY }px`;
        } else {
            body.classList.remove(styles.Freeze);
            const scrollY = body.style.top;
            body.style.position = '';
            body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
    };

    handleClickDynamicBack = async () => {
        this.cleanFilter();
        const isActiveSubmenuItem = !!_.find(sidebarState.getDynamicMenuItems(), item => item.getIsActive() || getIsSubmenuActive(item));
        if (isActiveSubmenuItem) {
            window.localStorage.setItem('activeDynamicMenuId', window.localStorage.getItem('openDynamicMenuId') || '');
            const findMenuItem = _.find(sidebarState.getMenuItems(), item => item.getSysId() === window.localStorage.getItem('openDynamicMenuId'));
            if (findMenuItem) {
                findMenuItem.setIsActive(true);
            }
        }

        window.localStorage.setItem('openDynamicMenuId', '');
        sidebarState.preventCloseNav = true;
        sidebarState.setIsOpenDynamic(false);
        sidebarState.setDynamicMenuItems([]);
        sidebarState.setDynamicTitle('');
        const menuItemsLength = sidebarState.getMenuItems().length;
        if (!menuItemsLength) {
            await sidebarState.fetchMenuList().catch(console.error);
        }
        this.getFilteredItems();
        const navElement = document.querySelector('[class*="NavContent"]');
        navElement?.scrollTo(0, 0);
    };

    getSubItem = (currItem: MenuItemType, newItem: MenuItemType) => {
        for (let i = 0; i < currItem.getItems().length; i++) {
            const currSubItem = currItem.getItems()[i];
            if (currSubItem.getSysId() === newItem.getSysId() && newItem.getEditUrl() === currSubItem.getEditUrl()) {
                return currSubItem;
            } else {
                if (currSubItem.getItems()) {
                    const findItem = this.getSubItem(currSubItem, newItem);
                    if (findItem) {
                        return findItem;
                    }
                }
            }
        }
    }

    getItem = (newItem: MenuItemType): MenuItemType | undefined => {
        const menuItems = sidebarState.getIsOpenDynamic() ? sidebarState.getDynamicMenuItems() : sidebarState.getMenuItems();
        for (let i = 0; i < menuItems.length; i++) {
            const currItem = menuItems[i];
            if (currItem.getSysId() === newItem.getSysId() && currItem.getEditUrl() === newItem.getEditUrl()) {
                return currItem;
            } else {
                const findItem = this.getSubItem(currItem, newItem);
                if (findItem) {
                    return findItem;
                }
            }
        }
        return undefined;
    }

    toggleFavorites = async (item: MenuItemType) => {
        sidebarState.preventCloseNav = true;
        const url = new URL(item.getUrl(), window.location.origin);
        url.searchParams.delete('menu_id');
        item.setUrl(url.pathname + url.search);
        const favoriteItems: MenuItemType[] = sidebarState.getFavorites();
        const menuItem = this.getItem(item);
        if (!menuItem) {
            return;
        }
        const parseItem = (item: MenuItemType): DataMenuType => {
            return {
                label: item.getLabel(),
                url: item.getUrl(),
                default_opened: item.getIsDefaultOpened(),
                edit_access: item.getIsEditAccess(),
                edit_url: item.getEditUrl(),
                is_favorite: item.getIsFavorite(),
                order: item.getOrder(),
                sys_id: item.getSysId(),
                items: item.getItems().map(subItem => parseItem(subItem)),
            };
        };
        let response;

        if (item.getIsFavorite()) {
            for (const favItem of favoriteItems) {
                const itemKey = item.getUrl() + item.getLabel();
                const favItemKey = (favItem.getUrl() === '#' ? '/' : favItem.getUrl()) + favItem.getLabel();

                if (itemKey === favItemKey) {
                    response = await deleteFavorite(parseItem(favItem));
                    break;
                }
            }
        } else {
            response = await saveFavorite(parseItem(item));
        }
        item.setIsFavorite(!item.getIsFavorite());
        if (response?.isOkStatus) {
            await sidebarState.fetchFavoritesList(false).catch(console.error);
        }
    };

    handleTabClick = (index, closeOnSecondClick = true) => async () => {
        if (sidebarState.activeTabIndex === index && closeOnSecondClick) {
            sidebarState.setTabIndex(-1).catch(console.error);
            sidebarState.setOpenedState(false);
        } else {
            sidebarState.preventCloseNav = true;
            this.refFilter.current.focus();

            sidebarState.setTabIndex(index).catch(console.error);
            sidebarState.setOpenedState(true);
            if (index === 1) {
                sidebarState.setIsOpenDynamic(false);
            }
            if (index === 0 && _.isEmpty(sidebarState.getMenuItems())) {
                await this.fetchMenuList();
            } else if (index === 1 && _.isEmpty(sidebarState.getFavorites())) {
                await sidebarState.fetchFavoritesList().catch(console.error);
            }
            this.getFilteredItems();
        }

        if (sidebarState.isPinned) {
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 500);
        }
    };

    onWindowResize = () => {
        // if (!isMedia('sm')) {
        //     sidebarState.setOpenedState(false);
        // }
    };

    onDocumentClick = ({ target }) => {
        const { current: sidebarEl } = this.refSidebar;
        if (!sidebarEl || sidebarState.isPinned || !sidebarState.isOpened) return;

        if (!sidebarEl.contains(target)) {
            if (sidebarState.preventCloseNav) {
                sidebarState.preventCloseNav = false;
            } else {
                sidebarState.setTabIndex(-1).catch(console.error);
                sidebarState.setOpenedState(false);
            }
        }
    };

    handleChangeFilter = (e) => {
        this.filterLoading = true;
        this.tempFilterValue = e.target.value;
        clearTimeout(this.timeoutFilter);
        this.timeoutFilter = setTimeout(() => {
            this.filterValue = this.tempFilterValue;
            this.getFilteredItems();
        }, 300);
    };

    getFilteredItems = () => {
        if (sidebarState.activeTabIndex === 0) {
            this.getFilteredMenuItems();
        } else if (sidebarState.activeTabIndex === 1) {
            this.getFilteredFavorites();
        }
    };

    getFilteredMenuItems = () => {
        this.filterItems(sidebarState.getIsOpenDynamic() ? sidebarState.getDynamicMenuItems() : sidebarState.getMenuItems(),
            this.filterValue);
    };

    getFilteredFavorites = () => {
        this.filterItems(sidebarState.getFavorites(), this.filterValue);
    };

    handleLogoClick = (evt) => {
        const unload = beforeUnload({});
        if (unload && !confirm(unload)) {
            evt.preventDefault();
            return;
        }
        sidebarState.setOpenedState(false);
    };

    filterItems = (allItems: MenuItemType[], filterValue: string): void => {
        const showAllSubItems = (subItems, isDefaultOpened = false) => {
            if (!_.isEmpty(subItems)) {
                _.forEach(subItems, subItem => {
                    if (isDefaultOpened) {
                        subItem.setIsDefaultOpened(true);
                    }
                    subItem.setIsShow(true);
                    showAllSubItems(subItem.getItems(), isDefaultOpened);
                });
            }
        };
        const filterItemFromItems = (items) => {
            let parentShow = false;
            _.forEach(items, (item) => {
                item.setIsShow(false);
                if (item.getLabel().toLowerCase().includes(filterValue.toLowerCase())) {
                    item.setIsDefaultOpened(true);
                    item.setIsShow(true);
                    parentShow = true;
                    showAllSubItems(item.getItems(), true);
                    return parentShow;
                }
                if (!_.isEmpty(item.getItems())) {
                    const isShowItem = filterItemFromItems(item.getItems());
                    if (isShowItem) {
                        parentShow = true;
                        item.setIsDefaultOpened(true);
                        item.setIsShow(true);
                    }
                }
            });
            return parentShow;
        };
        if (filterValue) {
            filterItemFromItems(allItems);
        } else {
            showAllSubItems(allItems);
        }
        this.filterLoading = false;
    };

    cleanFilter = () => {
        this.filterLoading = true;
        sidebarState.preventCloseNav = true;
        this.tempFilterValue = '';
        this.filterValue = '';
        this.getFilteredItems();
        this.refFilter.current.focus();
        this.filterLoading = false;
    };

    onPinClick = ({ target }) => {
        if (sidebarState.isPinned) {
            sidebarState.setOpenedState(true);
        }
        sidebarState.setPinnedState(!sidebarState.isPinned).catch(console.error);
        target.closest('button').dispatchEvent(new Event('mouseleave'));
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 500);
    };

    getMenuItems = () => {
        if ((sidebarState.getIsLoadingMenu()
            && (!sidebarState.getIsOpenDynamic() || (sidebarState.getIsOpenDynamic()
                && _.isEmpty(sidebarState.getDynamicMenuItems()))))
            || this.filterLoading) {
            return <MenuSkeleton />;
        }
        return (
            <>
                <Menu
                    filterValue={ this.filterValue }
                    items={ sidebarState.getIsOpenDynamic() ? sidebarState.getDynamicMenuItems() : sidebarState.getMenuItems() }
                    toggleFavorites={ this.toggleFavorites }
                />
                {
                    sidebarState.getIsLoadingMenu() && sidebarState.getIsOpenDynamic()
                    && <MenuSkeleton />
                }
            </>

        );
    };

    getFavoriteItems = () => {
        if (sidebarState.getIsLoadingFavorites() || this.filterLoading) {
            return <MenuSkeleton />;
        }
        if (_.isEmpty(sidebarState.getFavorites())) {
            return null;
        }
        return (
            <Favorite
                filterValue={ this.filterValue }
                items={ sidebarState.getFavorites() }
                fetchMenuList={ sidebarState.fetchMenuList }
            />
        );
    };

    render() {
        const { navigation_placeholder, system_buttons_hints } = langStore.getTranslate();

        const logoSrc = brandingState.getPlatformLogo();
        const iconPin = sidebarState.isPinned ? IconPinOff : IconPin;
        const hintPin = sidebarState.isPinned ? system_buttons_hints?.unpin : system_buttons_hints?.pin;

        const isLoadingContent = (!sidebarState.getIsLoadingMenu() && sidebarState.activeTabIndex === 0)
            || (!sidebarState.getIsLoadingFavorites() && sidebarState.activeTabIndex === 1);
        return (
            <GlobalPortal>
                <aside
                    className={ `${ styles.Sidebar } favoriteDropArea` }
                    ref={ this.refSidebar }
                    data-test={ ATTRIBUTES.sidebarBlock }
                >
                    {/*Navigation*/ }
                    <div className={ `${ styles.Nav } ${ sidebarState.isOpened ? styles.active : '' } ${ sidebarState.isPinned && sidebarState.isOpened ? styles.pinned : '' }` }>
                        <div className={ styles.MobileTop }>
                            <Link
                                to='/'
                                className={ `${ styles.MobileLogo }` }
                                onClick={ this.handleLogoClick }
                                data-test={ ATTRIBUTES.sidebarMobileLogo }
                            >
                                <img src={ logoSrc } />
                            </Link>
                            <Button
                                buttonType={ 'icon' }
                                svg={ IconX }
                                onClick={ () => {
                                    sidebarState.setOpenedState(false);
                                } }
                                data-test={ ATTRIBUTES.sidebarMobileClose }
                            />
                        </div>
                        <div className={ styles.NavHeader }>
                            <DynamicHeader handleClickDynamicBack={ this.handleClickDynamicBack } />
                            <div className={ styles.SearchWrap }>
                                <div className={ styles.Search }>
                                    <input
                                        className={ styles.SearchInput }
                                        type="text"
                                        placeholder={ sidebarState.activeTabIndex
                                            ? navigation_placeholder?.favorites_search
                                            : navigation_placeholder?.menu_search }
                                        onChange={ this.handleChangeFilter }
                                        value={ this.tempFilterValue }
                                        ref={ this.refFilter }
                                        data-test={ ATTRIBUTES.sidebarSearchInput }
                                    />
                                    { this.tempFilterValue.trim() !== '' &&
                                        <div data-test={ ATTRIBUTES.sidebarSearchClean }
                                             className={ styles.SearchClean }
                                             dangerouslySetInnerHTML={ { __html: IconX } }
                                             onClick={ this.cleanFilter } /> }
                                </div>
                                <Button
                                    hint={ hintPin }
                                    className={ styles.PinBtn }
                                    buttonType={ 'icon' }
                                    svg={ iconPin }
                                    onClick={ this.onPinClick }
                                    data-test={ ATTRIBUTES.sidebarPinButton }
                                />
                            </div>

                            <div className={ styles.MobileTabs }>
                                <div
                                    onClick={ this.handleTabClick(0, false) }
                                    className={ `${ styles.MobileTabsItem } ${ sidebarState.activeTabIndex === 0 ? styles.active : '' }` }
                                    dangerouslySetInnerHTML={ { __html: IconSidebar } }
                                    data-test={ ATTRIBUTES.sidebarMenuIconMobile }
                                />
                                <div
                                    onClick={ this.handleTabClick(1, false) }
                                    className={ `${ styles.MobileTabsItem } ${ sidebarState.activeTabIndex === 1 ? styles.active : '' }` }
                                    dangerouslySetInnerHTML={ { __html: IconStar } }
                                    data-test={ ATTRIBUTES.sidebarFavoritesIconMobile }
                                />
                            </div>
                        </div>
                        <div className={ styles.NavBody }>
                            <div
                                className={ `${ styles.NavContent } ${ isLoadingContent ? styles.NavContentScroll : '' }` }

                            >
                                <div
                                    className={ `${ styles.NavTab } ${ sidebarState.activeTabIndex === 0 ? styles.active : '' }` }
                                    data-test={ ATTRIBUTES.sidebarMenuTab }
                                >
                                    { this.getMenuItems() }
                                </div>
                                <div
                                    className={ `${ styles.NavTab } ${ sidebarState.activeTabIndex === 1 ? styles.active : '' }` }
                                    data-test={ ATTRIBUTES.sidebarFavoriteTab }
                                >
                                    { this.getFavoriteItems() }
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*Tabs menu*/ }
                    <SidebarTabs handleTabClick={ this.handleTabClick } />
                </aside>
            </GlobalPortal>
        );
    }
}

export default observer(LayoutSidebar);
