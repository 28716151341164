import * as React from 'react';
import {observer} from 'mobx-react';
import {makeObservable, observable, reaction, toJS} from 'mobx';
import styles from './styles.module.scss';
import Tooltip from 'components/tooltip';
import Button from 'components/button';
import Dropdown from 'components/dropdown';
import langStore from 'globalState/lang';
import {fetchDotWalkList} from 'actions/conditions';
import {isEqual} from 'helpers/data';
import {findFieldInDWA, findRemModelName, getRemModelId} from 'helpers/condition';
import IconChevronLeft from 'assets/img/icons/chevron-left.svg';
import IconChevronRight from 'assets/img/icons/chevron-right.svg';
import IconPlusSquare from 'assets/img/icons/plus-square.svg';
import IconMinusSquare from 'assets/img/icons/minus-square.svg';
import IconRem from 'assets/img/icons/rem.svg';
import {ATTRIBUTES} from 'constants/attributesForTests';
import {DELIMITER} from "./config";
import {ClickPath, ConditionFieldProps} from 'types/components/conditionFilters/conditionField';
import {Field, Fields, FilterType, ModelField} from 'types/components/filter/breadcrumbs';

/**
 * Описание: компонент ConditionField
 * Параметры:
 * isActive - компонент активен, т.е. в нём среди других таких же компонентов на странице идёт выбор значения из списка
 * data - список выбора
 * value - значение поля
 * onChange - обработчик для изменения текущего значения поля
 * setActive - установить активным (см. isActive)
 * readOnly - доступен только для чтения
 * className - класс
 */

class ConditionField extends React.Component<ConditionFieldProps> {
    private _filteringData: Fields = [];
    childrenData: Field[][] = [];
    clickPath: ClickPath[] = [];
    stringValue = '';
    isActive: boolean;
    isShowPopup = false;
    hoveredItem: null | Field = null;
    activeTab: FilterType = FilterType.ALL;
    refCondition: React.RefObject<HTMLDivElement> = React.createRef();
    refStringCondition: React.RefObject<HTMLInputElement> = React.createRef();
    refLastUl: React.RefObject<HTMLUListElement>  = React.createRef();
    refDropdown: React.RefObject<HTMLDivElement> = React.createRef();
    refSelectedField: React.RefObject<HTMLSpanElement> = React.createRef();
    refButtonText: React.RefObject<HTMLSpanElement> = React.createRef();
    timeout: NodeJS.Timeout;
    tableId = '';
    isArrowNextClick = false;
    isAnimateRun = false;
    maxLevelFieldList = 1; // максимальный уровень вложенности для полей типа List
    maxLevel = 10; // максимальный уровень вложенности

    constructor(props: ConditionFieldProps) {
        super(props);

        makeObservable(this, {
            _filteringData: observable,
            childrenData: observable,
            clickPath: observable,
            stringValue: observable,
            isActive: observable,
            isShowPopup: observable,
            hoveredItem: observable,
            activeTab: observable,
        });

        if (props.data) {
            this.filteringData = props.data;
            this.setValue(props.data, props.value);
        }
        this.isActive = props.isActive;
    }

    componentDidMount() {
        reaction(() => this.stringValue, this.filterByValue);
        document.addEventListener('keydown', this.onDocumentKeyDown);
    }

    componentDidUpdate(prevProps) {
        const { data, value, isActive } = this.props;
        if (data && !isEqual(data, prevProps.data)) {
            this.stringValue = '';
            this.filteringData = data;
            this.setValue(data, value);
        }
        if (prevProps.isActive !== isActive) {
            if (isActive) {
                this.activeTab = 0;
            }
            this.isActive = isActive;
        }
        this.setFocus();
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onDocumentKeyDown);
    }

    set filteringData(data: Fields) {
        const { value } = this.props;
        const remModelId = getRemModelId(value);
        if (remModelId) {
            this._filteringData = data.map(field => {
                if('sysId' in field && field.sysId === remModelId) {
                    field.isActive = true;
                }
                return field;
            });
        } else {
            this._filteringData = data;
        }
    }

    get filteringData() {
        return this._filteringData;
    }

    isFindField = (field: Field | ModelField, stringValue: string) => 'column_title' in field ? field.column_title.toLowerCase().includes(stringValue.toLowerCase()): false;

    isFindModel = (field: Field | ModelField, stringValue: string) => 'title' in field ? field.title.toLowerCase().includes(stringValue.toLowerCase()): false;

    isFindAttribute = (field: Field | ModelField, stringValue: string) => {
        if('title' in field) {
            const filterAttrFields = field.reAttributes.filter(field => this.isFindField(field, stringValue));
            const isFindFilterAttrFields = !!filterAttrFields.length;
            field.isActive = stringValue ? isFindFilterAttrFields : false;

            if (this.isFindModel(field, stringValue) && this.activeTab !== FilterType.ATTRIBUTE) {
                return true;
            }

            field.reAttributes = [...filterAttrFields];
            return isFindFilterAttrFields;
        }
        return false;
    };

    filterItems = (stringValue: string) => (field: Field | ModelField): boolean => {
        const isFindField = this.isFindField(field, stringValue);
        const isFindModel = this.isFindModel(field, stringValue);
        const isFindAttribute = this.isFindAttribute(field, stringValue);

        switch (this.activeTab) {
            case FilterType.ALL:
                return isFindAttribute || isFindModel || isFindField;
            case FilterType.FIELD:
                return isFindField;
            case FilterType.MODEL:
                return isFindModel;
            case FilterType.ATTRIBUTE:
                return isFindAttribute;
        }
    };

    filterByValue = (stringValue: string) => {
        const cloneFields = toJS(this.props.data);
        this.filteringData = cloneFields.filter(this.filterItems(stringValue));

        let currentList = this.filteringData as Field[];
        if (this.childrenData.length > 0) {
            currentList = this.childrenData[this.childrenData.length - 1].filter(li => li?.column_title.toLowerCase().includes(this.stringValue.toLowerCase()));
        }
        this.hoveredItem = currentList.length > 0 ? currentList[0] : null;
    }

    checkListLevel = () => {
        let result = true;
        const listLevel = this.clickPath.findIndex((elem) => elem.condition_type === 'list');
        const newLevel = this.clickPath.length;
        if (listLevel !== -1 && newLevel - listLevel >= this.maxLevelFieldList) {
            result = false;
        }
        return result;
    };

    onDocumentKeyDown = (e) => {
        const { key } = e;
        const { data, value } = this.props;

        if (!data || !this.isActive || value === null || this.isAnimateRun) {
            return;
        }

        if (key === 'ArrowDown' || key === 'ArrowUp') {
            let currentList = this.filteringData as Field[];

            if (this.childrenData.length > 0) {
                currentList = this.childrenData[this.childrenData.length - 1].filter(li => li?.column_title.toLowerCase().includes(this.stringValue.toLowerCase()));
            }

            if (currentList.length > 0) {
                if (!this.hoveredItem) {
                    this.hoveredItem = currentList[0];
                    return;
                }

                for (let i = 0, length = currentList.length; i < length; i++) {
                    if (currentList[i].column_id === this.hoveredItem.column_id) {
                        if (key === 'ArrowDown') {
                            this.hoveredItem = i + 1 < length ? currentList[i + 1] : currentList[0];
                        }
                        else if (key === 'ArrowUp') {
                            this.hoveredItem = i > 0 ? currentList[i - 1] : currentList[length - 1];
                        }
                        this.updateListScrollTop();
                        break;
                    }
                }
            }
        }

        if (key === 'Enter') {
            if (this.hoveredItem) {
                this.handleClickLi(this.hoveredItem)();
            }
        }

        if (key === 'ArrowRight') {
            if (this.hoveredItem) {
                if (this.childrenData && this.childrenData.length > 0) {
                    if (this.clickPath.length-1 < this.maxLevel && this.hoveredItem.referenced_table_id && this.hoveredItem.condition_type !== 'choice' && this.checkListLevel()) {
                        this.handleClickArrow(this.hoveredItem, this.clickPath.length-1)();
                    }
                }
                else {
                    if (this.hoveredItem.referenced_table_id && this.hoveredItem.condition_type !== 'choice') {
                        this.handleClickArrow(this.hoveredItem, 0)();
                    }
                }
            }
        }

        if (key === 'ArrowLeft') {
            if (this.clickPath.length > 0) {
                this.goToPrevList();
            }
        }

        if (key === 'Escape') {
            this.close();
        }
    };

    updateListScrollTop = () => {
        const list = this.refLastUl ? this.refLastUl.current ? this.refLastUl.current : null : null;
        if (!list) return;

        const hovered = list.getElementsByClassName(styles.Hovered);
        if (!hovered) return;

        const rectHover = hovered[0].getBoundingClientRect();
        const rectList = list.getBoundingClientRect();

        if (rectHover.top + rectHover.height > rectList.top + rectList.height) {
            list.scrollTo(0, list.scrollTop + rectHover.top + rectHover.height - (rectList.top + rectList.height));
        }
        else if (rectHover.top < rectList.top) {
            list.scrollTo(0, list.scrollTop - (rectList.top - rectHover.top));
        }
    };

    searchValue = async (data: Fields, value: Field | null) => {
        const findLi = findFieldInDWA(data, value?.dot_walking_attribute);
        if (findLi) {
            this.props.onChange(findLi, this.tableId);
            return;
        }
        const values = value?.dot_walking_attribute.split('.') || [];
        if (values.length > 1) {
            this.searchSelectedValue(values, data);
        }
    };

    searchSelectedValue = async (values: string[], data: Fields) => {
        let compValue = '';
        for (const index in values) {
            if (values.hasOwnProperty(index)) {
                let _index = Number(index);
                compValue = compValue ? `${ compValue }.${ values[index] }` : values[index];
                if (_index === 0 && values.length > 1) {
                    const findLi: Field = findFieldInDWA(data, values[index]);
                    await this.fetchData(findLi, _index);
                }
                if (_index > 0 && values.length - 1 > _index && this.childrenData.length > 0 && this.childrenData[_index - 1].length > 0) {
                    const findLi = findFieldInDWA(this.childrenData[_index - 1], compValue);
                    await this.fetchData(findLi, _index);
                }
            }
        }
    };

    fetchData = async (li: Field, index: number) => {
        this.isAnimateRun = true;
        const { value, onChange, data: allData} = this.props;
        const params = {
            referenced_table_id: li.referenced_table_id,
            dot_walking_attribute: li.dot_walking_attribute,
        };
        const { data } = await fetchDotWalkList(params);
        this.tableId = data.table_id;
        const items = data.items || data;
        const findLi = findFieldInDWA(items, value?.dot_walking_attribute);

        if (findLi) {
            onChange(findLi, data.table_id);
        }
        const tempData = this.childrenData.filter((data, i) => index > i);
        this.childrenData = [
            ...tempData,
            items,
        ];

        if (this.isActive) {
            const remAttr = li?.dot_walking_attribute?.split(':');
            const isFirstDwa = remAttr.length > 1 && !remAttr[1].includes('.');

            const remTitle = isFirstDwa ? findRemModelName(allData, remAttr[0]) : '';

            this.clickPath.push({
                column_title: li.column_title,
                condition_type: li.condition_type,
                remTitle,
            });
        }

        if (data) {
            this.isAnimateRun = false;
            this.stringValue = '';
            this.hoveredItem = null;
        }
    };

    setValue = (data: Fields, value: Field | null) => {
        if (value) {
            this.searchValue(data, value);
        }
    };

    handleChangeStringValue = (e) => {
        this.stringValue = e.target.value;
    };

    handleClickLi = (li: Field) => () => {
        this.props.onChange({...li}, this.tableId);
        this.handleToggleShow();
    };

    handleMouseEnterLi = (li: Field) => () => {
        this.hoveredItem = li;
    };

    handleClickArrow = (li: Field, index: number) => (e?) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (this.isAnimateRun) return;
        this.isArrowNextClick = true;
        this.fetchData(li, index);
    };

    handleToggleShow = async () => {
        const { value, data, setActive } = this.props;
        if (!this.isActive) {
            setActive(true);
            if (value) {
                const values = value.dot_walking_attribute.split('.');
                if (values.length > 1) {
                    await this.searchSelectedValue(values, data);
                }
            }
            setTimeout(() => {
                const selectedField = this.refSelectedField.current;
                if (selectedField) {
                    selectedField.scrollIntoView({behavior: "smooth", block: 'nearest', inline: 'start'});
                }
            }, 0);


            document.addEventListener('mousedown', this.onOuterClick);
        }
        else {
            this.close(false);
        }
    };

    handleClickTab = (index: FilterType) => () => {
        this.activeTab = index;
        this.filterByValue(this.stringValue);
    }

    toggleRemMenu = (remModel: ModelField) => () => {
        remModel.isActive = !remModel.isActive;
    }

    setFocus = () => {
        this.refStringCondition.current?.focus();
    };

    isDotWalkEquals = (value: Field | null, row) => {
        return value?.dot_walking_attribute === row.dot_walking_attribute;
    };

    isDotWalkIncludes = (value: Field | null, row) => {
        return value?.dot_walking_attribute?.includes(`${ row.dot_walking_attribute }.`);
    };

    onOuterClick = (e) => {
        const conditionEl = this.refCondition.current;
        const dropdownEl = this.refDropdown.current;

        if (!conditionEl || !dropdownEl) return false;

        if (!conditionEl.contains(e.target) && !dropdownEl.contains(e.target)) {
            this.close();
            e.stopPropagation();
        }
    };

    close = (clearChildren = true) => {
        if(clearChildren) {
            this.childrenData = [];
        }
        this.clickPath = [];
        this.isArrowNextClick = false;
        this.stringValue = '';
        this.hoveredItem = null;
        this.filteringData = this.props.data;
        this.props.setActive(false);
        document.removeEventListener('mousedown', this.onOuterClick);
    };

    hidePopup = () => {
        clearTimeout(this.timeout);
        this.isShowPopup = false;
    };

    showPopup = () => {
        if (this.childrenData.length === 0) {
            return;
        }
        this.timeout = setTimeout(() => {
            this.isShowPopup = true;
        }, 300);
    };

    goToPrevList = () => {
        if (this.isAnimateRun) return;

        this.clickPath.pop();
        this.childrenData.pop();
        this.isArrowNextClick = false;
        this.isAnimateRun = false;
        this.stringValue = '';
        this.hoveredItem = null;
    };

    findFullTitle = () => {
        const { data, value } = this.props;
        const attributes = value?.dot_walking_attribute?.split('.') || [];
        const remAttributes = value?.dot_walking_attribute?.split(':') || [];
        let remTitle = '';
        let firstAttr = findFieldInDWA(data, attributes[0]) as Field | undefined;

        if (remAttributes.length > 1) {
            const remModelId = remAttributes[0];
            const remModel = data.find(model => 'sysId' in model && model.sysId === remModelId) as ModelField;
            if (!remModel) return '';
            remTitle = `${remModel.title} ${DELIMITER} `;
            firstAttr = findFieldInDWA(remModel.reAttributes, attributes[0]);
        }

        let result = `${remTitle}${firstAttr ? firstAttr.column_title : ''}`;
        let rAttribute = attributes[0];
        this.childrenData.forEach((child, index) => {
            rAttribute += '.' + attributes[index + 1];
            const findAttr = findFieldInDWA(child, rAttribute);
            result += findAttr ? ' / ' + findAttr.column_title : '';
        });
        return result;
    };

    cutText = (text: string | null, cutLength: number) => {
        if (typeof text === 'string') {
            let textCut = text.slice(0, cutLength);
            if (text.length > textCut.length) {
                textCut = textCut.trim() + '...';
            }
            return textCut;
        }
        else {
            return text;
        }
    };

    renderNotFound = () => {
        const { no_results } = langStore.getTranslate();

        return (
            <div className={styles.NotFound}>
                { no_results }
            </div>
        );
    }

    renderChildren = () => {
        const { value } = this.props;
        if (!this.childrenData || this.childrenData.length === 0) {
            return null;
        }

        return this.childrenData.map((jsonUl, index) => {
            const filteringUl = index === this.childrenData.length - 1 && this.stringValue
                ? jsonUl.filter(li => li && li.column_title.toLowerCase().includes(this.stringValue.toLowerCase()))
                : jsonUl;

            const li = filteringUl.map((jsonLi, liIndex) => {
                const className: string[] = [];
                const isSelected = this.isDotWalkEquals(value, jsonLi) || this.isDotWalkIncludes(value, jsonLi);
                if (isSelected) {
                    className.push(styles.Selected);
                }

                const arrow = this.clickPath.length-1 < this.maxLevel && jsonLi.referenced_table_id && jsonLi.condition_type !== 'choice' && this.checkListLevel() ? (
                    <span className={ styles.ArrowIcon } dangerouslySetInnerHTML={{__html: IconChevronRight}} onClick={ this.handleClickArrow(jsonLi, index + 1) } />
                ) : null;

                return (
                    <span
                        key={ `${ jsonLi.dot_walking_attribute }${ liIndex }` }
                        ref={ isSelected ? this.refSelectedField : null }
                        className={ `${styles.FieldContainer} ${ this.hoveredItem && jsonLi.column_id === this.hoveredItem.column_id ? styles.Hovered : '' }` }
                    >
                        <li
                            className={ `${ className.join(' ') }` }
                            onClick={ this.handleClickLi(jsonLi) }
                            onMouseEnter={ this.handleMouseEnterLi(jsonLi) }
                            onMouseLeave={ () => this.hoveredItem = null }
                        >
                            { jsonLi.column_title }
                        </li>
                        { arrow }
                    </span>
                );
            });

            const ref = index === this.clickPath.length - 1 ? this.refLastUl : null;
            return (
                <ul className={ `${ styles.CondSelectBlockList } ${ styles.slideIn }` } ref={ ref } key={ `ul${ index }` }>
                    { filteringUl.length ? li : this.renderNotFound() }
                </ul>
            );
        });
    };

    renderRemModelField = (remModel: ModelField) => {
        const isOpenModel = remModel.isActive;

        const listArrow = <span className={ styles.ExpandIcon } dangerouslySetInnerHTML={{__html: isOpenModel ? IconMinusSquare : IconPlusSquare}} />;
        const remIcon = <span className={ styles.BaseIcon } dangerouslySetInnerHTML={{__html: IconRem}} />;

        return (
            <React.Fragment key={ remModel.title }>
                <div className={styles.ItemContainer}>
                    <li
                        className={ `${styles.RemModel} ${isOpenModel ? styles.Selected : ''}` }
                        onClick={this.toggleRemMenu(remModel)}
                    >
                        {listArrow}
                        <span className={styles.TextContent}>
                            {remModel.title}
                        </span>
                    </li>
                    { remIcon }
                </div>
                {isOpenModel && (
                    <ul>
                        {this.renderListField(remModel.reAttributes, true)}
                    </ul>
                )}
            </React.Fragment>

        );
    }

    renderTabs = () => {
        const { all_types, only_fields, only_models, only_attributes } = langStore.getTranslate();
        const tabs = [all_types, only_fields, only_models];
        const withRemField = !!this.props.data.find(field => 'reAttributes' in field);

        if (this.clickPath.length || this.childrenData.length || !withRemField) {
            return null;
        }

        if (this.stringValue) {
            tabs.push(only_attributes);
        }

        return (
            <div className={ styles.CondTabsContainer }>
                {tabs.map((tab, i) => {
                        return (
                            <div
                                key={tab}
                                className={ `${styles.CondTab} ${i === this.activeTab ? styles.ActiveTab : ''}` }
                                onClick={this.handleClickTab(i)}
                            >
                                {tab}
                            </div>
                        );
                    }
                )}
            </div>
        );
    }

    renderListField = (fields: Fields, isRemField = false) => {
        const { value } = this.props;

        return fields.map((jsonLi, liIndex) => {
            if ('reAttributes' in jsonLi) {
                return this.renderRemModelField(jsonLi);
            } else {
                const className: string[] = [];
                const isSelected = this.isDotWalkEquals(value, jsonLi) || this.isDotWalkIncludes(value, jsonLi);
                if (isSelected) {
                    className.push(styles.Selected);
                } if (isRemField) {
                    className.push(styles.RemFeild);
                }
                const arrow = jsonLi.referenced_table_id && jsonLi.condition_type !== 'choice' ? (
                    <span className={ styles.ArrowIcon } dangerouslySetInnerHTML={{__html: IconChevronRight}} onClick={ this.handleClickArrow(jsonLi, 0) } />
                ) : null;
                return (
                    <span
                        key={ `${ jsonLi.dot_walking_attribute }${ liIndex }` }
                        ref={ isSelected ? this.refSelectedField : null }
                        className={ `${styles.FieldContainer} ${isRemField ? styles.Inner : ''} ${ this.hoveredItem && jsonLi.column_id === this.hoveredItem.column_id ? styles.Hovered : '' }` }
                    >
                        <li
                            className={ className.join(' ') }
                            onClick={ this.handleClickLi(jsonLi) }
                            onMouseEnter={ this.handleMouseEnterLi(jsonLi) }
                            onMouseLeave={ () => this.hoveredItem = null }
                            data-test={ `${ jsonLi.dot_walking_attribute }-${ ATTRIBUTES.conditionFieldLi }` }
                        >
                            { jsonLi.column_title }
                        </li>
                        {arrow}
                    </span>
                );
            }
        });
    }

    renderDWA = () => {
        if (!this.clickPath.length) {
            return null;
        }
        const remIcon = <span className={ styles.DWAIcon } dangerouslySetInnerHTML={{__html: IconRem}} />;

        return (
            <div title={ this.clickPath.map(elem => elem.column_title).join(' / ') } className={ styles.CondSelectBlockPath }>
                <div className={ styles.container }>
                    {this.clickPath[0]?.remTitle && remIcon}
                    <div className={ styles.TextContent }>
                        { this.clickPath.map(elem => {
                            if (elem.remTitle) {
                                return `${elem.remTitle} ${DELIMITER} ${elem.column_title}`;
                            }
                            return elem.column_title;
                        }).join(' / ') }
                    </div>
                </div>
            </div>
        );
    }

    renderModalField = () => {
        const { data, value, isMobile } = this.props;
        if (!data || !this.isActive || value === null) {
            return null;
        }

        const ref = this.clickPath.length === 0 ? this.refLastUl : null;

        return (
            <Dropdown disableMinWidth={ !isMobile } refParent={ this.refCondition } ref={ this.refDropdown }>
                <div className={ styles.CondSelectBlock }>
                    <div className={ styles.CondSelectBlockHead }>
                        <div className={ styles.CondSelectBlockFilter }>
                            { this.clickPath.length > 0 && <Button
                                buttonType={ 'icon-border' }
                                svg={ IconChevronLeft }
                                className={ styles.button }
                                onClick={ this.goToPrevList }
                                data-test={ ATTRIBUTES.conditionFieldBackButton }
                            /> }
                            <input
                                type="text"
                                className={ styles.input }
                                value={ this.stringValue }
                                onChange={ this.handleChangeStringValue }
                                ref={ this.refStringCondition }
                                data-test={ ATTRIBUTES.conditionFieldSearchInput }
                            />
                        </div>
                        {this.renderTabs()}
                        {this.renderDWA()}
                    </div>
                    <div className={ styles.CondSelectBlockBody }>
                        <ul
                            ref={ ref }
                            className={ styles.CondSelectBlockList }
                            data-test={ this.props['data-test'] && this.props['data-test'].includes('sort') ? ATTRIBUTES.conditionFieldSortingOptions : ATTRIBUTES.conditionFieldFilteringOptions }
                        >
                            { this.filteringData.length ? this.renderListField(this.filteringData) : this.renderNotFound() }
                        </ul>
                        <div className={ styles.CondSelectBlockChildren }>
                            { this.renderChildren() }
                        </div>
                    </div>
                </div>
            </Dropdown>
        );
    };

    getColumnTitle = () => {
        const { data, value } = this.props;
        const { filter_titles } = langStore.getTranslate();

        const attributes = value?.dot_walking_attribute?.split('.') || [];
        const fieldTitle = attributes.length > 1 ? `...${value?.column_title}` : value?.column_title as string;

        const buttonTextEl = this.refButtonText?.current;
        let buttonWidth = 0;
        const maxSymbolCoefficient = 8; // Соотношение размера кнопки и текста, который помещается

        if (buttonTextEl) {
            buttonWidth = buttonTextEl.clientWidth;
        }

        let remTitle: JSX.Element | null = null;

        if (value?.dot_walking_attribute?.includes(':')) {
            const remIcon = <span className={ styles.BaseIcon } dangerouslySetInnerHTML={{__html: IconRem}} />;
            const modelField = findFieldInDWA(data, value?.dot_walking_attribute);

            const fullModelTitle = this.findFullTitle();
            const isContainText = (buttonWidth / fullModelTitle.length) > maxSymbolCoefficient;
            const modelColumnTitle = isContainText && modelField ? fullModelTitle : `...${value?.column_title}`;

            remTitle = (
                <span
                    className={styles.ColumnTitleContainer}
                    title={!isContainText ? `${filter_titles?.model}: ${fullModelTitle}` : ''}
                >
                    {remIcon}
                    <span
                        className={styles.TextContent}
                        ref={this.refButtonText}
                    >
                         {modelColumnTitle}
                    </span>
                </span>
            );
        }
        const columnTitle = remTitle ? remTitle : fieldTitle;

        return value ? columnTitle : filter_titles?.choose_field;
    }

    render() {
        const { value, className = '', readOnly } = this.props;

        return (
            <div
                ref={ this.refCondition }
                className={ `${ styles.ConditionField } ${ className }` }
                data-test={ this.props['data-test'] }
            >
                <button
                    className={ `${ styles.CondSelectButton } ${ !value ? styles.placeholder : '' }` }
                    type="button"
                    onClick={ this.handleToggleShow }
                    disabled={ readOnly }
                    onMouseLeave={ this.hidePopup }
                    onMouseEnter={ this.showPopup }
                >
                    {this.getColumnTitle()}
                </button>
                { this.renderModalField() }
                { this.isShowPopup && <Tooltip
                    onClose={ this.hidePopup }
                    parent={ this.refCondition.current }
                >
                    { this.cutText(this.findFullTitle(), 500) }
                </Tooltip> }
            </div>
        );
    }
}

export default observer(ConditionField);
