import { observer } from 'mobx-react';
import * as React from 'react';
import { observable, makeObservable } from 'mobx';
import styles from './styles.module.scss';
import langStore from 'globalState/lang';
import IconChevronR from 'assets/img/icons/chevron-right.svg';
import IconChevronL from 'assets/img/icons/chevron-left.svg';
import { SM_WIDTH } from 'constants/numbers';
import Button from 'components/button';

/**
 * PaginationWidget
 * page: {required: true, type: number} - номер страницы
 * perPage: {required: false, type: number} - по сколько выводить на странице
 * total: {required: true, type: number} - сколько всего элементов
 * onChange: {required: true, type: function} - метод смены страницы
 */
class PaginationWidget extends React.Component {
    maxPage;
    smallSep = 5;
    middleSep = 2;
    bigSep = 7;

    constructor(props) {
        super(props);

        makeObservable(this, {
            maxPage: observable,
            smallSep: observable,
            middleSep: observable,
            bigSep: observable,
        });

        this.calcParams();
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateWidth);
        this.updateWidth();
    }

    componentDidUpdate(prevProps) {
        const { total, perPage } = this.props;
        if (total !== prevProps.total || perPage !== prevProps.perPage) {
            this.calcParams();
        }
        this.updateWidth();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWidth);
    }

    updateWidth = () => {
        const windowWidth = document.documentElement.clientWidth;
        if (windowWidth < SM_WIDTH) {
            this.bigSep = 2;
            this.smallSep = 2;
            this.middleSep = 1;
        }
        else {
            this.bigSep = 7;
            this.smallSep = 5;
            this.middleSep = 2;
        }
    };

    calcParams() {
        const { isDisablePageCalculation, page } = this.props;

        if (isDisablePageCalculation) {
            this.maxPage = page + 1;
        } else {
            this.maxPage = Math.ceil(this.props.total / this.props.perPage);
        }
    }

    handleChangePage = (toPage) => () => {
        const { page } = this.props;
        if (page === toPage) {
            return;
        }
        this.toPage(toPage);
    };

    toNext = () => {
        const { page } = this.props;
        if (page === this.maxPage) {
            return;
        }
        const nextPage = parseInt(page, 10) + 1;
        this.toPage(nextPage);
    };

    toFirst = () => {
        const { page } = this.props;
        if (page === 1) {
            return;
        }
        this.toPage(1);
    };

    toPage = (page) => {
        const { onChange } = this.props;
        if (page < 1) {
            page = 1;
        }
        if (page > this.maxPage) {
            page = this.maxPage;
        }

        if (onChange) {
            onChange(page);
        }
    };

    renderPage = (index) => {
        const { page } = this.props;
        return (
            <Button
                key={ `page${ index }` }
                buttonType={ page === index ? 'primary' : 'default'}
                className={ styles.PageNumber }
                onClick={ this.handleChangePage(index) }
                data-test={ `page${ index }-pagination` }
            >
                { index }
            </Button>
        );
    };

    render() {
        const { page, total, perPage } = this.props;
        const { list_titles } = langStore.getTranslate();
        if (!total || Math.abs(total) < perPage) {
            return null;
        }
        let items = [];
        if (this.maxPage === 1) {
            // оставляем пустой массив
        }
        else if (this.maxPage <= this.bigSep) {
            for (let i = 1; i <= this.maxPage; i++) {
                items.push(this.renderPage(i));
            }
        }
        else if (page === 1) {
            for (let i = 1; i <= this.smallSep; i++) {
                items.push(this.renderPage(i));
            }
            items.push(<div key={ `page6` } className={ styles.PageMore }>
                ...
            </div>);
            items.push(this.renderPage(this.maxPage));
        }
        else if (page === this.maxPage) {
            items.push(this.renderPage(1));
            items.push(<div key={ `page2` } className={ styles.PageMore }>
                ...
            </div>);
            for (let i = this.maxPage - (this.smallSep - 1); i <= this.maxPage; i++) {
                items.push(this.renderPage(i));
            }
        }
        else if (page <= this.smallSep) {
            for (let i = 1; i <= this.bigSep; i++) {
                items.push(this.renderPage(i));
            }
            items.push(<div key={ `page8` } className={ styles.PageMore }>
                ...
            </div>);
            items.push(this.renderPage(this.maxPage));
        }
        else if (page >= this.maxPage - (this.smallSep - 1)) {
            items.push(this.renderPage(1));
            items.push(<div key={ `page2` } className={ styles.PageMore }>
                ...
            </div>);
            for (let i = this.maxPage - (this.bigSep - 1); i <= this.maxPage; i++) {
                items.push(this.renderPage(i));
            }
        }
        else {
            items.push(this.renderPage(1));
            items.push(<div key={ `page2` } className={ styles.PageMore }>
                ...
            </div>);
            for (let i = page - this.middleSep; i <= page + this.middleSep; i++) {
                items.push(this.renderPage(i));
            }
            items.push(<div key={ `page${ this.maxPage - 1 }` } className={ styles.PageMore }>
                ...
            </div>);
            items.push(this.renderPage(this.maxPage));
        }

        return (
            <div className={ styles.Pagination }>
                <div
                    onClick={ this.toFirst }
                    className={ `${ styles.PageControl } ${ page === 1 ? styles.Disabled : '' }` }
                    data-test={ `to-first-pagination` }
                >
                    <Button disabled={ page === 1 } className={ styles.PageText }>{ list_titles && list_titles.first_page }</Button>
                    <Button disabled={ page === 1 } buttonType={'icon-border'} className={ styles.PageArrow } svg={ IconChevronL } />
                </div>
                { items }
                <div
                    onClick={ this.toNext }
                    className={ `${ styles.PageControl } ${ page === this.maxPage ? styles.Disabled : '' }` }
                    data-test={ `to-next-pagination` }
                >
                    <Button className={ styles.PageText }>{ list_titles && list_titles.next_page }</Button>
                    <Button buttonType={'icon-border'} className={ styles.PageArrow } svg={ IconChevronR } />
                </div>
            </div>
        );
    }
}

export default observer(PaginationWidget);
