import apiRequest from 'lib/apiRequest';
import { handleResponse } from 'helpers/action';

export const fetchData = async (sys_id, filter = '') => {
    const url = (filter) ? `GET /ldap/browse/${ sys_id }/${ filter }` : `GET /ldap/browse/${ sys_id }`;
    const response = await new apiRequest(url).send();
    return handleResponse(response);
};

export const fetchDataLdap = async (sys_id, dn, filter = '') => {
    const url = (filter) ? `GET /ldap/level/${ sys_id }/${ dn }/${ filter }` : `GET /ldap/level/${ sys_id }/${ dn }`;
    const response = await new apiRequest(url).send();
    return handleResponse(response);
};
