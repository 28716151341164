import BasePortalDesignerCollection from './base';
import ContainerPortalDesignerModel from '../model/container';

/**
 * коллекция сонтейнеров
 * @extends BasePortalDesignerCollection
 */
export default class ContainersPortalDesignerCollection extends BasePortalDesignerCollection {
    /**
     *
     * @param containers [ContainerPortalDesignerModel]
     */
    constructor(containers) {
        super(containers, ContainerPortalDesignerModel);
    }
}
