import * as React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment-timezone';
import styles from './styles.module.scss';
import langStore from 'globalState/lang';
import { SortingRow } from 'globalState/conditions/condition/sortingRow';
import { GroupingRow } from 'globalState/conditions/condition/groupingRow';
import { FilteringRow } from 'globalState/conditions/condition/filteringRow';
import { getFormatFromPHP } from 'helpers/date';
import {
    convertServerTimeToISO,
    getDateValueByString,
    getTimeZoneDate,
    getUserTimezone,
    isSameOptions,
} from 'helpers/getUserTimeZone';
import { findFieldInDWA, findRemModelName, getRemModelId } from 'helpers/condition';
import { getLinkDisplayValue } from "helpers/form";
import IconRem from 'assets/img/icons/rem.svg';
import { DEFAULT_DATE_TIME_FORMAT, DEFAULT_TIME_FORMAT } from 'constants/dateTime';
import { LINK_NOT_FOUND, LINK_TITLE_UNAVAILABLE, LINK_UNAVAILABLE } from "constants/strings";
import { DELIMITER } from "components/conditionFilters/conditionField/config";
import type { BreadcrumbsLinkProps, Field } from 'types/components/filter/breadcrumbs';

const BreadcrumbsLink: React.FC<BreadcrumbsLinkProps> = ({ data, delimeter, item }) => {
    const { filter_titles } = langStore.getTranslate();
    let value = '';
    let fullValue = '';
    let fullTitle = '';
    let columnTitle = item.getField().column_title;

    const getArrayValue = (value, result) => {
        let resultValue = result;
        const opts = (item as FilteringRow)._valueOpts;
        value.forEach(v => {
            if (Array.isArray(v)) {
                resultValue = getArrayValue(v, resultValue);
            } else {
                let displayValue;
                if ([LINK_NOT_FOUND, LINK_UNAVAILABLE, LINK_TITLE_UNAVAILABLE].includes(v?.reference_state)){
                    displayValue = getLinkDisplayValue(v, true);
                } else if (v.display_value !== undefined) {
                    displayValue = v.display_value;
                } else if (
                    Array.isArray(opts) &&
                    opts.length > 0 &&
                    typeof value === 'string' &&
                    value.search(/^opt:/) === 0
                ) {
                    displayValue = lookupOptsDisplayValue(v);
                } else {
                    displayValue = getValue(v);
                }
                resultValue += !resultValue
                    ? v.value || displayValue
                    : `, ${v.value || displayValue}`;
            }
        });

        return resultValue;
    };

    const lookupOptsDisplayValue = (value: string) => {
        const opts = (item as FilteringRow)._valueOpts;
        const opt = Array.isArray(opts) && opts.find(opt => opt.database_value === value);

        return opt?.display_value;
    };

    const getValue = value => {
        const { bool } = langStore.getTranslate();
        let resultValue = '';

        if (!value) {
            return resultValue;
        }

        if (item instanceof FilteringRow) {
            if (item.getOperator() && item.getOperator().database_value && isSameOptions(item)) {
                if (value && value.display_value) {
                    return value.display_value;
                }

                const filteredData = findFieldInDWA(data, value);

                return filteredData ? filteredData.column_title : value;
            }
            const opts = item._valueOpts;

            switch (true) {
                case [LINK_NOT_FOUND, LINK_UNAVAILABLE, LINK_TITLE_UNAVAILABLE].includes(value.reference_state):
                    resultValue = getLinkDisplayValue(value, true);
                    break;
                case Array.isArray(value):
                    resultValue = getArrayValue(value, resultValue);
                    break;
                case Array.isArray(opts) &&
                opts.length > 0 &&
                typeof value === 'string' &&
                value.search(/^opt:/) === 0:
                    resultValue = lookupOptsDisplayValue(value);
                    break;
                case typeof value.display_value !== 'undefined':
                    resultValue = value.display_value;
                    break;
                case item.getField().column_type === 'boolean':
                    resultValue = value === '1' ? (bool?.yes as string) : (bool?.no as string);
                    break;
                case item.getField().column_type === 'id':
                    resultValue = value.record_title;
                    break;
                case item.getField().column_type === 'datetime':
                    const validateParams = item.getField().validate;
                    const format = getFormatFromPHP(validateParams.format);
                    const isValidDate = moment(value, DEFAULT_DATE_TIME_FORMAT, true).isValid();
                    const excludeOperators = [
                        'LIKE',
                        'NOTLIKE',
                        'YEAR_IS',
                        'WEEK_IS',
                        'QUARTER_IS',
                        'DAY_IS',
                        'HOUR_IS',
                        'DAY_OF_WEEK_IS',
                        'MONTH_IS',
                    ];
                    if (
                        excludeOperators.includes(item.getOperator().database_value) ||
                        !isValidDate
                    ) {
                        resultValue = value;
                    } else {
                        resultValue = getDateValueByString(value, getUserTimezone(), format);
                    }
                    break;
                case item.getField().column_type === 'time':
                    const time = convertServerTimeToISO(value);
                    resultValue = getTimeZoneDate(time).format(DEFAULT_TIME_FORMAT);
                    break;
                default:
                    resultValue = value;
            }

            return resultValue;
        }
    };

    const getTitle = (columnTitle: string) => {
        let dotWalkLevels;
        let refFieldDBName;
        // Если используется dotwalking, находим имя reference колонки (крайней слева)
        if (item.getField().dot_walking_attribute?.includes('.')) {
            dotWalkLevels = item.getField().dot_walking_attribute.split('.');
            refFieldDBName = dotWalkLevels[0];
        }

        const dwaField = refFieldDBName ? findFieldInDWA(data, refFieldDBName) : null;
        if (dwaField) {
            const refFieldName =
                dwaField.column_title.charAt(0).toUpperCase() + dwaField.column_title.slice(1);
            const fieldsDelimiter =
                dotWalkLevels.length === 2 ? ' / ' : dotWalkLevels.length > 2 ? ' /.../ ' : ' ';

            return `${refFieldName}${fieldsDelimiter}${columnTitle}`;
        }

        return columnTitle;
    };

    const getSortingValue = (item: SortingRow) => {
        const prefix = item.getDisplayValue().is_dwa ? '... ' : '';
        const direction = item.getDirection();
        return `${prefix}${item.getDisplayValue().display_column_name || columnTitle}${delimeter}${direction}`;
    };

    const getGroupingValue = (item: GroupingRow) => {
        const prefix = item.getDisplayValue().is_dwa ? '... ' : '';
        return `${prefix}${filter_titles.group}${delimeter}${item.getDisplayValue().display_column_name || columnTitle}`;
    };

    const getFilteringValue = (item: FilteringRow) => {
        const { filter_titles } = langStore.getTranslate();
        let title = getTitle(columnTitle);

        const remModelSysId = getRemModelId(item.getField() as Field);
        if (remModelSysId) {
            const MAX_SYMBOLS = 20;

            const remModelName = findRemModelName(data, remModelSysId);
            fullTitle = `${remModelName} ${DELIMITER} ${title}`;

            const isContainText = fullTitle.length < MAX_SYMBOLS;
            title = isContainText ? fullTitle : `...${title}`;
            fullTitle = `${filter_titles?.model}: ${fullTitle}`;
        }

        const template = `${delimeter}${item.getOperator().display_value || item.getOperator()}${delimeter}${getValue(item.getValue())}`;
        fullValue = `${fullTitle}${template}`;

        return `${title}${template}`;
    };

    if (item instanceof SortingRow) {
        value = getSortingValue(item);
    } else if (item instanceof GroupingRow) {
        value = getGroupingValue(item);
    } else {
        value = getFilteringValue(item);
    }

    if (!item) {
        return null;
    }

    const remValue = (
        <span className={styles.LinkContainer}>
            <span className={styles.BaseIcon} dangerouslySetInnerHTML={{ __html: IconRem }} />
            <span className={styles.TextContent}>{value}</span>
        </span>
    );

    return <span title={fullTitle ? fullValue : value}>{fullTitle ? remValue : value}</span>;
};

export default observer(BreadcrumbsLink);