import * as React from 'react';

import styles from 'components/dynamicForms/view/form/LoaderField/styles.module.scss';
import FieldWrapper from 'components/dynamicForms/view/fieldWrapper';
import BaseFormFieldModel from 'components/dynamicForms/model/field/BaseFormFieldModel';

/***
 * Описание: Поле отображаемое при загрузке данных, пока не будут получены все данные
 */

export default class LoaderField extends React.Component {
    constructor(props) {
        super(props);

        if (props.model) {
            this.model = props.model;
        }
        else {
            this.model = new BaseFormFieldModel(props);
        }
    }

    render() {
        this.model.loader = <div className={ styles.Loader } />;

        return (
            <FieldWrapper model={ this.model }>
                <div className={ styles.Input } />
                <div className={ styles.Shadow } />
            </FieldWrapper>
        );
    }
}
