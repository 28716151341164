import * as React from 'react';
import styles from './styles.module.scss';
import IconComment from 'assets/img/icons/message-circle.svg';
import DefaultAvatar from 'assets/img/user-ava.svg';
import IconSendComment from 'assets/img/activeFeed/comment-send.svg';
import IconChevronDown from 'assets/img/icons/chevron-down.svg';
import IconChevronUp from 'assets/img/icons/chevron-up.svg';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import TextArea from 'components/dynamicForms/view/field/textArea';
import * as _ from 'lodash';
import { CommentContentActivity, CommentItemProps } from 'types/components/activityFeed';
import { getDateValueByString, getTranslateDateFormat, getUserTimezone } from 'helpers/getUserTimeZone';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { getClassNameAF } from 'helpers/activityFeed';

/**
 * Описание: компонент дополнительных комментариев в activity feed
 * Параметры:
 * text: {type: string} - комментарий
 * tableName: {type: string} - имя таблицы
 * recordId: {type: scting} - id записи
 * sysId: {type: scting} - id комментария
 * widgetId: {type: string} - id виджета
 * dictionary: {type: object} - объект с локализацией
 * title: {type: string} - заголовок
 */
class CommentItem extends React.Component<CommentItemProps> {
    comment = '';
    sendDisabled = false;
    showAll = false;
    hasOverflow = false;
    refTextArea: React.RefObject<any> = React.createRef();
    resizeTimeout: any = null;

    componentDidMount() {
        window.addEventListener('resize', this.onWindowResize);
        this.checkTextHeight();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
    }

    checkTextHeight = () => {
        const textArea = this.refTextArea?.current?.model?.ref?.current;
        const textAreaParent = textArea?.parentNode;

        if (!textArea || !textAreaParent) return;

        // получаем максимальную высоту для обёртки textarea
        const textAreaParentClone = textAreaParent.cloneNode();
        textAreaParentClone.classList.remove(styles.opened);
        textAreaParent.append(textAreaParentClone);
        const textAreaParentStyle = window.getComputedStyle(textAreaParentClone);
        const maxHeight = parseInt(textAreaParentStyle.getPropertyValue('max-height'));
        textAreaParent.removeChild(textAreaParentClone);

        this.hasOverflow = textArea.getBoundingClientRect().height > maxHeight;
    };

    onWindowResize = () => {
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(this.checkTextHeight,200);
    };

    handleChangeComment = ({ value }) => {
        this.comment = value;
    };

    handleToggleShowAll = () => {
        this.showAll = !this.showAll;
    };

    sendComment = async (event) => {
        const { tableName, recordId, item, widgetId } = this.props;
        event.stopPropagation();
        event.preventDefault();
        if (this.comment && !this.sendDisabled) {
            this.sendDisabled = true;
            const data = {
                table_name: tableName,
                record_id: recordId,
                operation_code: 'create_comment',
                parent_id: item.sys_id,
                message: this.comment,
            };
            (window as any).s_widget.serverUpdate(widgetId, data);
            this.sendDisabled = false;
            this.comment = '';
        }
    };

    renderAvatar = (user) => {
        const { isNotStylized, classes } = this.props;
        if (!user || !user.avatar) {
            return (
                <div
                    dangerouslySetInnerHTML={ { __html: DefaultAvatar } }
                    className={ getClassNameAF(isNotStylized, styles.CommentUserAvatar, classes?.CommentUserAvatar) }
                    data-test={ ATTRIBUTES.activityFeedAvatar }
                />
            );
        }
        return (
            <div
                className={ getClassNameAF(isNotStylized, styles.CommentUserAvatar, classes?.CommentUserAvatar) }
                style={{ backgroundImage: `url("${user.avatar}")` }}
                data-test={ ATTRIBUTES.activityFeedAvatar }
            />
        );
    };

    renderAdditionalComments = () => {
        const { childrenComments, dictionary, isNotStylized, classes } = this.props;
        if (_.isEmpty(childrenComments)) {
            return null;
        }
        const comments = _.map(childrenComments, comment => {
            return (
                <div
                    key={ `childComment${ comment.sys_id }` }
                    className={ getClassNameAF(isNotStylized, styles.SubComment, classes?.SubComment) }
                >
                    <div className={ getClassNameAF(isNotStylized, styles.SubCommentUserAvatar, classes?.SubCommentUserAvatar) }>
                        { comment.author.avatar && <img src={ comment.author.avatar } alt={ comment.author.name } /> }
                    </div>
                    <div className={ getClassNameAF(isNotStylized, styles.SubCommentBlock, classes?.SubCommentBlock) }>
                        <div className={ getClassNameAF(isNotStylized, styles.SubCommentHeader, classes?.SubCommentHeader) }>
                            <div className={ getClassNameAF(isNotStylized, styles.SubAuthorHeader, classes?.SubAuthorHeader) }>
                                { comment.author.name }
                            </div>
                            <div className={ getClassNameAF(isNotStylized, styles.SubCommentUpdateDate, classes?.SubCommentUpdateDate) }>
                                { comment.timestamp }
                            </div>
                        </div>
                        <div className={ getClassNameAF(isNotStylized, styles.SubCommentText, classes?.SubCommentText) }>
                            { comment.content.message }
                        </div>
                    </div>
                </div>
            );
        });
        return (
            <div className={ getClassNameAF(isNotStylized, styles.AdditionalComments, classes?.SubComments) }>
                <div className={ getClassNameAF(isNotStylized, styles.CommentsHeader, classes?.SubCommentsHeader) }>
                    { dictionary.item.comments_title }
                </div>
                { comments }
            </div>
        );
    };

    renderSendComment = () => {
        const { dictionary, childrenComments, isNotStylized, classes } = this.props;
        if (childrenComments.length === 0) {
            return null;
        }
        return (
            <div className={ getClassNameAF(isNotStylized, styles.SendCommentBlock, classes?.SendSubCommentBlock) }>
                <TextArea
                    className={ getClassNameAF(isNotStylized, styles.SendSubCommentText, classes?.SendSubCommentText) }
                    placeholder={ dictionary.item.comment_placeholder }
                    onChange={ this.handleChangeComment }
                    value={ this.comment }
                    maxHeight={ 120 }
                />
                <div
                    className={ getClassNameAF(isNotStylized, styles.SendSubCommentButton, classes?.SendSubCommentButton) }
                    onClick={ this.sendComment }
                    dangerouslySetInnerHTML={ { __html: IconSendComment } }
                    data-test={ ATTRIBUTES.activityFeedSendCommentButton }
                />
            </div>
        );
    };

    renderShowAll = () => {
        const { dictionary, isNotStylized, classes } = this.props;

        if (!this.hasOverflow) {
            return null;
        }
        const text = !this.showAll ? `${ dictionary.item.show_comments_button }` : dictionary.item.hide_information_button;
        return (
            <div
                className={ getClassNameAF(isNotStylized, styles.CommentShowAllButton, classes?.CommentShowAllButton) }
                onClick={ this.handleToggleShowAll }
                data-test={ ATTRIBUTES.activityFeedToggleShowAll }
            >
                <span>{ text }</span>
                <span
                    className={getClassNameAF(isNotStylized, styles.CommentShowAllChevron, classes?.CommentShowAllChevron) }
                    dangerouslySetInnerHTML={{__html: this.showAll ? IconChevronUp : IconChevronDown}}
                />
            </div>
        );
    };

    constructor(props: CommentItemProps) {
        super(props);

        makeObservable(this, {
            comment: observable,
            sendDisabled: observable,
            showAll: observable,
            hasOverflow: observable,
            refTextArea: observable,
        });
    }

    render() {
        const { item, title, style, isNotStylized, classes } = this.props;
        const dateValue = getDateValueByString(item.timestamp, getUserTimezone(), `${ getTranslateDateFormat() } HH:mm:ss`);
        return (
            <div
                className={ getClassNameAF(isNotStylized, styles.CommentItemBlock, classes?.CommentItemBlock) }
                data-test={ ATTRIBUTES.activityFeedItemCommentBlock }
            >
                <div
                    className={ getClassNameAF(isNotStylized, styles.CommentItem, classes?.CommentItem) }
                    data-test={ `${ATTRIBUTES.activityFeedItem }-${ title }` }
                >
                    <div className={getClassNameAF(isNotStylized, styles.CommentHead, classes?.CommentHead)}>
                        <div className={ getClassNameAF(isNotStylized, styles.CommentUser, classes?.CommentUser) }>
                            { this.renderAvatar(item.author) }
                            <div className={getClassNameAF(isNotStylized, styles.CommentContent, classes?.CommentContent)}>
                                <div
                                    className={getClassNameAF(isNotStylized, styles.CommentUserTitle, classes?.CommentUserTitle)}
                                    data-test={ ATTRIBUTES.activityFeedItemAuthor }
                                >
                                    { item.author.name }
                                </div>
                                <div
                                    className={ getClassNameAF(isNotStylized, styles.CommentDate, classes?.CommentDate) }
                                    data-test={ ATTRIBUTES.activityFeedItemDate }
                                >
                                    { dateValue }
                                </div>
                            </div>
                        </div>
                        <div className={ getClassNameAF(isNotStylized, styles.CommentTitleHead, classes?.CommentTitleHead) } style={ style }>
                            <style>{ `.IconColor${ item.sys_id } > svg > path { fill: ${ style && style.color } !important; }` }</style>
                            <div
                                dangerouslySetInnerHTML={ { __html: IconComment } }
                                className={ `${ getClassNameAF(isNotStylized, styles.CommentIcon, classes?.CommentIcon) } IconColor${ item.sys_id }` }
                            />
                            <div
                                className={getClassNameAF(isNotStylized, styles.CommentTitle, classes?.CommentTitle)}
                                data-test={ ATTRIBUTES.activityFeedItemTitle }
                            >
                                { title }
                            </div>
                        </div>
                    </div>

                    <TextArea
                        value={(item.content as CommentContentActivity).message}
                        className={ `${getClassNameAF(isNotStylized, styles.CommentText, classes?.CommentText)} ${this.hasOverflow ? styles.OverflowGradient : ''} ${this.showAll ? styles.opened : ''}` }
                        data-test={ ATTRIBUTES.activityFeedCommentItemText }
                        readonly
                        disableMaxHeight
                        ref={this.refTextArea}
                    />

                </div>
                { this.renderAdditionalComments() }
                { this.renderSendComment() }
                { this.renderShowAll() }
            </div>
        );
    }
}

export default observer(CommentItem);
