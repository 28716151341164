export const isMedia = (size) => {
    let screen = 0;
    switch (size) {
        case 'lg':
            screen = 1199;
            break;
        case 'md':
            screen = 991;
            break;
        case 'sm':
            screen = 767;
            break;
        case 'xs':
            screen = 575;
            break;
    }
    return window.innerWidth <= screen;
};

export const setPageScroll = (top) => {
    const { body, documentElement } = document;
    body.scrollTop = top;
    documentElement.scrollTop = top;
};

export const updateMenuScrollTop = (menuEl, activeItemEl) => {
    if (!menuEl || !activeItemEl) return;

    const menuRect = menuEl.getBoundingClientRect();
    const itemRect = activeItemEl.getBoundingClientRect();
    const itemTop = itemRect.top;
    const menuTop = menuRect.top;
    const itemBottom = itemTop + itemRect.height;
    const menuBottom = menuTop + menuRect.height;

    if (itemBottom > menuBottom) {
        menuEl.scrollTop = menuEl.scrollTop + (itemBottom - menuBottom);
    }
    else if (itemTop < menuTop) {
        menuEl.scrollTop = menuEl.scrollTop - (menuTop - itemTop);
    }
};