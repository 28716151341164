import { LocalStorage } from 'ttl-localstorage';
import _ from 'lodash';

import { simpleHash } from 'helpers/misc';
import { getNow } from 'helpers/date';

/**
 *
 * ttl - время жизни cache
 * key - ключ, должен приходить в виде строки;
 *
 * formationKey - метод формирует хэшированый ключ (параметры: method - метод запроса, pathname - путь на страницу, parameter - параметры запроса, search - путь на страницу);
 * add - метод отвечающий за добавления кеша в storage (параметры: value - данные для хранения, obj - объект { method, pathname, param, search}, ttl - время жизни);
 * addByKey - метод отвечающий за добавления данных в storage со своим ключем
 * getByData - метод отвечающий за получения данных из storage;
 * getByKey - метод отвечающий за получения данных из storage по ключу;
 * deleteByData - метод отвечающий за удаления элемента storage;
 * deleteByKey - метод отвечающий за удаления элемента storage, который хранится со своим ключем;
 * deleteAll - метод удаляет все элементы cache из storage;
 * deleteAllByPrefix - метод удаляет cache по prefix
 *
 * **/

export const DAY = 86400; //86400 секунд в сутках

class Cache {
    ttl = DAY * 3; // Пока 3 дня
    prefix = 'cache';

    hasKey = (key) => {
        return _.isNull(localStorage.getItem(key));
    };

    hasKeyPrefix = (prefix) => {
        let keys = Object.keys(localStorage);
        if (!keys.length) return true;

        const reg = new RegExp(`^${ prefix }\-*`);
        for (let key of keys) {
            if (key.search(reg) !== -1) {
                return false;
            }
        }
        return true;
    };

    formationKey = (obj, prefix = this.prefix) => {
        return `${ prefix }${ simpleHash(JSON.stringify(obj)) }`;
    };

    add = (value, obj, ttl = this.ttl, prefix = this.prefix) => {
        if (_.isEmpty(obj) || !_.isInteger(ttl) || !_.isString(prefix)) {
            return false;
        }
        const keyHash = this.formationKey(obj, prefix);
        LocalStorage.put(keyHash, value, ttl);

        return keyHash;
    };

    addByKey = (value, key, ttl = this.ttl) => {
        if (!_.isInteger(ttl)) {
            return false;
        }
        LocalStorage.put(key, value, ttl);

        return key;
    };

    getTimeCreateCache = (obj, prefix = this.prefix) => {
        const keyHash = this.formationKey(obj, prefix);
        const cache = JSON.parse(localStorage.getItem(keyHash));
        if (!cache) {
            return null;
        }
        return cache.t;
    };

    getByData = (obj, prefix = this.prefix) => {
        if (_.isEmpty(obj) || !_.isString(prefix)) {
            return false;
        }
        const keyHash = this.formationKey(obj, prefix);
        return LocalStorage.getSync(keyHash);
    };

    getByKey = (key) => {
        return LocalStorage.getSync(key);
    };

    deleteByData = (obj, prefix = this.prefix) => {
        if (_.isEmpty(obj) || !_.isString(prefix)) {
            return false;
        }
        const keyHash = this.formationKey(obj, prefix);
        LocalStorage.removeKey(keyHash);

        return this.hasKey(keyHash);
    };

    deleteByKey = (key) => {
        LocalStorage.removeKey(key);

        return this.hasKey(key);
    };

    deleteAll = () => {
        localStorage.clear();
    };

    deleteAllByPrefix = (prefix = this.prefix) => {
        if (!_.isString(prefix)) {
            return false;
        }

        let keys = Object.keys(localStorage);
        if (!keys.length) return false;

        const reg = new RegExp(`^${ prefix }\-*`);
        for (let key of keys) {
            if (key.search(reg) !== -1) {
                LocalStorage.removeKey(key);
            }
        }

        return this.hasKeyPrefix(prefix);
    };

    isCacheStale = (obj) => {
        const t = this.getTimeCreateCache(obj);
        return (getNow() - t) > this.ttl;
    }
}


export default new Cache();
