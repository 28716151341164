import styles from 'layouts/main/sidebar/styles.module.scss';
import * as React from 'react';
import _ from 'lodash';

export default function MenuSkeleton() {
    const items = _.times(16, (num) => {
        return (
            <div key={ `SkeletonListItem-${num}` } className={ styles.SkeletonListItem }>
                <div className={ styles.SkeletonListHolder }/>
                <div
                    className={ styles.SkeletonListHolder }
                    style={ {
                        width: `${Math.floor(Math.random() * (203 - 80 + 1)) + 80}px`,
                    } }
                />
            </div>
        );
    });

    return (
        <div className={ styles.SkeletonList }>
            { items }
        </div>
    );
}