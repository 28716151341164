import { observable, makeObservable } from 'mobx';
import { UiActionStoreType } from 'types/uiActions/index';

class UiActionStore implements UiActionStoreType {
    private _waitServerResponse = false;

    constructor() {
        makeObservable(this, {
            _waitServerResponse: observable,
        });
    }

    getWaitServerResponse(): boolean {
        return this._waitServerResponse;
    }

    setWaitServerResponse(value: boolean) {
        this._waitServerResponse = value;
    }
}

export default new UiActionStore();
