import * as React from 'react';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';

import IconExpanded from 'assets/img/icons/chevron-down.svg';
import IconCollapsed from 'assets/img/icons/chevron-right.svg';
import { Fragment } from 'react';
import { sideMenuState } from 'globalState/widgets/sideMenuState';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { menuType } from 'helpers/widget';

/**
 * Виджет меню
 * Template:
 * <SideMenu
 *      parentsDepth="{options.parents_depth}"
 *      childrenDepth="{options.children_depth}"
 *      includeCategories="{options.include_categories}"
 *      includeItems="{options.include_items}">
 * </SideMenu>
 */
class SideMenu extends React.Component {
    timeout;

    constructor(props) {
        super(props);
        this.timeout = setTimeout(() => {
            sideMenuState.fetchWidgetMenu(this.props, window.location).catch(console.error);
        }, 300);
    }

    componentDidMount() {
        sideMenuState.onNavigate();
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
        sideMenuState.clearState();
    }

    /**
     * Функция отрисовки непосредственно самого пункта меню
     * @param element - пункт меню
     */
    renderMenuElement(element) {
        const currentStyle = sideMenuState.isCurrent(element) ? styles.current : '';
        const isExpanded = sideMenuState.isExpanded(element);
        return <div
            className={ `${ styles.item } ${ currentStyle } ${ isExpanded ? styles.expanded : ''}` }
            data-test={ ATTRIBUTES.sideMenuItem }
        >
            {
                (element.type === menuType.NODE && element.children.length > 0) || element.type === menuType.CATEGORY ?
                <span
                    onClick={ sideMenuState.toogleElement(element, this.props, window.location) }
                    className={ styles.categoryIcon }
                    data-test={ ATTRIBUTES.sideMenuItemIconExpand }
                    dangerouslySetInnerHTML={ { __html: isExpanded ? IconExpanded : IconCollapsed } }
                /> :
                <span className={ styles.bullet } />
            }
            <span
                className={ styles.link }
                onClick={ () => {
                    sideMenuState.beforeElementChange(element)();
                    if (element.children.length > 0) {
                        sideMenuState.toogleElement(element, this.props, window.location)();
                    }
                } }
                data-test={ ATTRIBUTES.sideMenuItemLink }
            >
                <span className={ element.children.length ? styles.category : '' }>
                    { element.title }
                </span>
                {
                    element.children.length > 0 ? <span className={ styles.count }>{ element.children.length }</span> : null
                }
            </span>
        </div>;
    }

    /**
     * Функция отрисовки пунктов меню вместе с подпунктами
     * @param elements - массив пунктов меню
     */
    renderMenuElements = (elements) => {
        if (!elements) return;

        return elements.map(element => {
            return (
                <Fragment key={ element.id }>
                    {
                        this.renderMenuElement(element)
                    }
                    {
                        sideMenuState.isExpanded(element) &&
                        <div
                            className={ styles.submenu }
                            data-test={ ATTRIBUTES.sideMenuSubItem }

                        >
                            { this.renderMenuElements(element.children) }
                        </div>
                    }
                </Fragment>
            );
        });
    };

    render() {
        const { className } = this.props;
        return (
            <div className={ `${ styles.sidebarMenu } ${ className || '' }` } data-test={ this.props['data-test'] ? this.props['data-test'] : `sidemenu-${ ATTRIBUTES.widget }` }>
                { this.renderMenuElements(sideMenuState.data.length ? sideMenuState.data[0].children : []) }
            </div>
        );
    }
}

export default observer(SideMenu);