import ListBaseStorage from 'components/portalDesigner/storage/listBaseStorage';

/**
 * state со списком всех шаблонов страниц портала
 */
class PortalPageTemplateStorage extends ListBaseStorage {
    table_name = 'page';
    columns = [
        'path_name',
        'sys_id',
        'title',
    ].join(',');

    constructor() {
        super();
    }

    filterByTitle(title) {
        return this.filter('title', title);
    }

    getBySysId(sysId) {
        return this.getBy('sys_id', sysId);
    }
}

export default new PortalPageTemplateStorage;
