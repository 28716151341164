import * as React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import FieldWrapper from 'components/dynamicForms/view/fieldWrapper';
import StringInput from 'components/dynamicForms/view/field/stringInput';
import { GithubPicker } from 'react-color';
import { observable, makeObservable } from 'mobx';
import styles from 'components/dynamicForms/view/field/color/styles.module.scss';
import ColorModel from 'components/dynamicForms/model/field/ColorModel';
import Dropdown from 'components/dropdown';
import { getTestNameField } from 'helpers/data';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { isChanged } from 'helpers/form';
import Button from 'components/button';
import IconPicker from 'assets/img/icons/picker.svg';

/**
 * Описание: компонент Color
 * Параметры:
 * onChange: {required, type: function} - метод для изменения
 * value: {type: string}
 */
class Color extends React.Component {
    model;
    isShow = false;
    refButton = React.createRef();
    refDropdown = React.createRef();

    constructor(props) {
        super(props);

        makeObservable(this, {
            model: observable,
            isShow: observable,
        });

        if (props.model) {
            this.model = props.model;
        } else {
            this.model = new ColorModel(props);
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.onDocumentClick);
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props, prevProps)) {
            if (this.props.model) {
                this.model = this.props.model;
            } else {
                this.model.mergeData(this.props);
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onDocumentClick);
    }

    // изменение из Picker
    handleChangeComplete = ({ hex }) => {
        this.model.value = hex;
        this.model.changed = isChanged(this.model.defaultValue, this.model.value);
        this.isShow = false;
    };

    // изменяем флаг для показа пикера
    onHandle = () => {
        if (this.model.readonly) return;
        this.isShow = !this.isShow;
    };

    onChange = ({ value }) => {
        this.model.value = value || '';
        this.model.changed = isChanged(this.model.defaultValue, this.model.value);
        if (this.props.onChange) {
            this.props.onChange({
                component: this,
                value,
            });
        }
    };

    onDocumentClick = ({ target }) => {
        const { current: btnEl } = this.refButton;
        const { current: dropdownEl } = this.refDropdown;
        if (!btnEl || !dropdownEl) return;
        if (!btnEl.contains(target) && !dropdownEl.contains(target)) {
            this.isShow = false;
        }
    }

    render() {
        return (
            <FieldWrapper model={ this.model }>
                <div
                    className={ styles.color }
                    data-test={ `${ getTestNameField(this.model) }-${ ATTRIBUTES.fieldColor }` }
                    data-test-visible={ this.model.isVisible }
                    data-test-mandatory={ this.model.isMandatory }
                    data-test-readonly={ this.model.readonly }
                    data-test-field-type={ this.model.sys_column_name ? this.model.column_type : undefined }
                    data-test-field-name={ this.model.sys_column_name }
                >
                    <StringInput
                        ref={ this.model.ref }
                        className={ styles.colorInput }
                        isMandatory={ this.model.isMandatory }
                        readOnly={ this.model.readonly }
                        value={ this.model.value }
                        onChange={ this.onChange }
                        autofill="off"
                        autoComplete="off"
                        showCleanBtn
                    />
                    { !(this.model.readonly && !this.model.value) && <Button
                        buttonType={ 'icon-border' }
                        onClick={ this.onHandle }
                        className={ styles.colorButton }
                        disabled={ this.model.readonly }
                        ref={ this.refButton }
                        svg={ this.model.value ? null : IconPicker }
                    >
                        { this.model.value && <div style={ { backgroundColor: this.model.value } } className={ styles.colorFrame }/>}
                    </Button> }
                </div>
                {
                    this.isShow &&
                    <Dropdown refParent={ this.refButton } ref={ this.refDropdown } floatRight>
                        <GithubPicker
                            width={ '210px' }
                            className={ styles.colorPicker }
                            triangle={ 'hide' }
                            onChangeComplete={ this.handleChangeComplete }
                        />
                    </Dropdown>
                }
            </FieldWrapper>
        );
    }
}

export default observer(Color);
