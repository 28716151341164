import * as React from 'react';
import { observable, reaction, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import _ from 'lodash';

import Report from 'components/report';

import FilterComponent from 'components/filter';

import styles from '../styles.module.scss';
import { simpleHashFromObject } from 'helpers/misc';
import reportState, { IS_LOAD_DATA, IS_OPEN_FILTER, IS_UPDATE_CHART, IS_UPDATE_MENU } from 'globalState/report';
import { renderReportError, renderReportLoader, renderUpperRightBar } from 'helpers/report/reportView';

/**
 * Описание: компонент для просмотра отчёта
 * содержит condition builder и сам отчёт
 * @props:
 chart, // данные чарта
 type, // тип чарта
 name,  // имя чарта
 field, // поле по которому строится чарт
 tableName , // display_value исользуемой таблицы
 */
class ReportView extends React.Component {
    burgerMenuItems = [];
    isUpdateChart = false;
    isRunCondition = false;

    constructor(props) {
        super(props);

        makeObservable(this, {
            burgerMenuItems: observable,
            isUpdateChart: observable,
            isRunCondition: observable,
        });

        const { chart, type, name, field, formData } = this.props;
        reportState.reportData = {
            chart,
            formData,
            type,
            name,
            field,
            tableName: this.props.tableName,
        };

        reaction(() => reportState.stateFlags & IS_UPDATE_CHART,
            () => {
                if (!this.isUpdateChart) {
                    reportState.updateCurrentStyles();
                }
            });
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.chart, this.props.chart)
            || (!_.isEqual(prevProps.type, this.props.type) && !prevProps.type)
            || !_.isEqual(prevProps.name, this.props.name)
        ) {
            const { chart, type, name, field, formData } = this.props;
            reportState.reportData = {
                chart,
                formData,
                type,
                name,
                field,
                tableName: this.props.tableName,
                conditions: reportState.conditionState.getConditionString(),
            };
        }
        if (!_.isEqual(this.isUpdateChart, this.props.isUpdateChart)) {
            this.isUpdateChart = this.props.isUpdateChart;
        }
        if (!this.burgerMenuItems.length && reportState.menuItems.length && reportState.indexReportError) {
            this.onMenuChanged(reportState.menuItems);
            reportState.stateFlags = reportState.stateFlags & ~IS_UPDATE_MENU;
        }
    }

    onMenuChanged = (menuItems) => {
        this.burgerMenuItems = menuItems;
    };

    updateChart = async () => {
        reportState.updateConditionInUrl();
        const { getChart } = this.props;
        this.isUpdateChart = true;
        try {
            if (getChart) {
                await getChart();
            }
            reportState.updateCurrentStyles();
        }
        catch (e) {
            throw e;
        }
        finally {
            this.isUpdateChart = false;
        }
    };

    renderReportComponent(getChart) {
        if (reportState.indexReportError) {
            return renderReportError();
        }
        return (
            <Report
                key={ simpleHashFromObject(reportState.chart) }
                location={ this.props.location }
                isLoadedChart={ !this.isUpdateChart }
                onMenuChanged={ this.onMenuChanged }
                getChart={ getChart }
                additionalGroup={ reportState.additionalGroup }
                additionalGroupValue={ reportState.additionalGroupValue }
                isUpdateMenu={ !!(reportState.stateFlags & IS_UPDATE_MENU) }
            />
        );
    }

    render() {
        const { getChart, save, location } = this.props;

        if (!reportState.indexReportError) {
            if (!reportState.table || !!(reportState.stateFlags & IS_LOAD_DATA)) {
                return renderReportLoader(location);
            }
        }


        return <div className={ styles.ReportView }>
            { renderUpperRightBar(this.burgerMenuItems, save, this.updateChart) }

            <div className={ styles.Report }>
                { reportState.table && <FilterComponent
                    key={ `chart-filter-${ reportState.table.database_value }` }
                    tableName={ reportState.table.display_value }
                    tableId={ reportState.table.database_value }
                    conditionState={ reportState.conditionState }
                    isRunDisabled={ true }
                    isSaveDisabled={ true }
                    isSortingDisabled={ true }
                    isGroupingDisabled={ true }
                    location={ location }
                    onChangeCondition={ reportState.updateConditionInUrl }
                    isOpen={ !!(reportState.stateFlags & IS_OPEN_FILTER) }
                    toggleIsOpen={ reportState.toggleIsOpen }
                /> }
                {
                    this.renderReportComponent(getChart)
                }
            </div>
        </div>;
    }
}

export default observer(ReportView);
