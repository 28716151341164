import { action, observable, makeObservable } from 'mobx';

class AttachmentsWidgetState {
    _attachments = {};

    constructor() {
        makeObservable(this, {
            _attachments: observable,
            setAttachment: action,
            reset: action,
        });
    }

    setAttachment = (id, attachment = {}) => {
        if (id){
            const attachmentTemplate = {
                hasWidget: false,
                essence: null,
                recordId: null,
                dndAttachmentsProps: [],
                widgetComponent: null,
            };
            if (!this._attachments){
                this._attachments = {};
            }
            this._attachments[id] = { ...attachmentTemplate, ...this._attachments[id], ...attachment };
        }
    };

    getAttachment = (id) => {
        return this._attachments && this._attachments[id];
    };

    reset = (id) => {
        if (id){
            if (!this._attachments){
                this._attachments = {};
            }
            this._attachments[id] = {
                hasWidget: false,
                essence: null,
                recordId: null,
                dndAttachmentsProps: [],
                widgetComponent: null,
            };
        }
    }
}

const attachmentsWidgetState = new AttachmentsWidgetState();

export default attachmentsWidgetState;


