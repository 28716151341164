import * as React from 'react';

import styles from './styles.module.scss';


function Button({ innerRef, buttonType, className, isLoading, ...props }) {
    let btnClass = [ styles.btn ];

    switch (buttonType) {
        case 'default':
            btnClass.push(styles.btnDefault);
            break;
        default:
            btnClass.push(styles.btnPrimary);
    }
    if (className) {
        btnClass.push(className);
    }
    if (isLoading) {
        btnClass.push(styles.loading);
    }
    btnClass = btnClass.join(' ');

    return (
        <button
            className={ btnClass }
            { ...props }
            ref={ innerRef }
        >
            { props.children }
        </button>
    );
}


export default React.forwardRef((props, ref) => <Button innerRef={ ref } { ...props } />);
