import React, { useEffect, useRef, useState } from 'react';
import type {LexicalCommand, LexicalEditor} from 'lexical';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {mergeRegister} from '@lexical/utils';
import {
    $getSelection,
    $isRangeSelection,
    $isRootNode,
    COMMAND_PRIORITY_EDITOR,
    COMMAND_PRIORITY_HIGH,
    COMMAND_PRIORITY_LOW,
    COMMAND_PRIORITY_CRITICAL,
    createCommand,
    DRAGOVER_COMMAND,
    DRAGSTART_COMMAND,
    DROP_COMMAND,
    PASTE_COMMAND,
} from 'lexical';
import DragAndDrop, {DragAndDrop as DragAndDropComp} from 'components/dragAndDrop';
import {
    $createImageNode,
    ImageNode,
    ImagePayload,
} from '../../nodes/ImageNode';
import {onDragover, onDragStart, onDrop, onUploadImage} from "./helpers";

export type InsertImagePayload = Readonly<ImagePayload>;

export const INSERT_IMAGE_COMMAND: LexicalCommand<InsertImagePayload> =
    createCommand();
export const TOGGLE_MODAL_IMAGE_COMMAND = createCommand();

export const ImagesPlugin = ({model}): JSX.Element | null => {
    const [editor] = useLexicalComposerContext();
    const [isShowImageModal, setShowImageModal] = useState(false);
    let dndRef = useRef<DragAndDropComp | null>(null);

    useEffect(() => {
        if (!editor.hasNodes([ImageNode])) {
            throw new Error('ImagesPlugin: ImageNode not registered on editor');
        }

        return mergeRegister(
            editor.registerCommand<InsertImagePayload>(
                INSERT_IMAGE_COMMAND,
                (payload) => {
                    const selection = $getSelection();
                    if ($isRangeSelection(selection)) {
                        if ($isRootNode(selection.anchor.getNode())) {
                            selection.insertParagraph();
                        }
                        const rootElement = editor.getRootElement();
                        const offset = 20;
                        const width = (rootElement?.offsetWidth as number) - offset;

                        const imageNode = $createImageNode({...payload, width });
                        selection.insertNodes([imageNode]);
                    }
                    return true;
                },
                COMMAND_PRIORITY_EDITOR,
            ),
            editor.registerCommand(
                TOGGLE_MODAL_IMAGE_COMMAND,
                (isOpened: boolean) => {
                    setShowImageModal(isOpened);
                    if (!isOpened){
                        editor.focus();
                    } else {
                        editor.blur();
                    }
                    return true;
                },
                COMMAND_PRIORITY_CRITICAL,
            ),
            editor.registerCommand<DragEvent>(
                DRAGSTART_COMMAND,
                (event) => {
                    return onDragStart(event);
                },
                COMMAND_PRIORITY_HIGH,
            ),
            editor.registerCommand<DragEvent>(
                DRAGOVER_COMMAND,
                (event) => {
                    return onDragover(event);
                },
                COMMAND_PRIORITY_LOW,
            ),
            editor.registerCommand<DragEvent>(
                DROP_COMMAND,
                (event) => {
                    return onDrop(event, editor);
                },
                COMMAND_PRIORITY_HIGH,
            ),
            editor.registerCommand<any>(
                PASTE_COMMAND,
                (event) => {
                    return onPasteImage(event, editor);
                },
                COMMAND_PRIORITY_HIGH,
            ),
        );
    }, [editor]);

    const setDndRef = (dndComp: DragAndDropComp) => {
        dndRef.current = dndComp;
    };

    const onPasteImage = (event, editor: LexicalEditor): boolean => {
        const payload = event?.clipboardData?.files || [];
        if(!payload.length) return false;

        const { sysTableName, sysId} = model;
        onUploadImage(editor, payload, sysTableName, sysId).then(() => {
            return true;
        }).catch(() => {
            return false;
        });
        return true;
    };

    const onClick = async ({ originalFileData }) => {
        const payload = [originalFileData];
        if(!payload.length) return false;
        editor.focus();

        const { sysTableName, sysId} = model;
        const response = await onUploadImage(editor, payload, sysTableName, sysId);

        dndRef.current?.handleClose();
        return response;
    };

    return (
        <DragAndDrop
            onClose={ () => {editor.dispatchCommand(TOGGLE_MODAL_IMAGE_COMMAND, false)} }
            getRef={setDndRef}
            show={ isShowImageModal }
            downloadUrl={ 'image' }
            attachUrl={ '/import/json' }
            essence={ 'field_type_example' }
            recordId=''
            uploadFileAction={ onClick }
            isClearAttachWhenClose
            isWaitForRequest
            isCloseWhenAdd
        />
    );
};
