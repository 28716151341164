import * as React from 'react';
import Header from './header';
import Sidebar from './sidebar';
import InfoMessages from 'components/infoMessages';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import Debugger from 'components/debugger';
import ListBox from './listbox';
import ErrorBoundary from 'components/errorBoundary';
import brandingState from 'globalState/branding';
import sidebarState from 'globalState/sidebarState';

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.getBrandingData().catch(console.error);
    }

    getBrandingData = async () => {
        await brandingState.getBrandingData();
        brandingState.setPlatformFavicon();
    }

    render() {
        const { children, ...restParams } = this.props;

        return (

            <span>
                <div className={ `${ styles.Wrap } ${ sidebarState.isPinned && sidebarState.isOpened ? styles.pinnedAside : '' }` }>
                    <ErrorBoundary>
                        <Header />
                    </ErrorBoundary>
                    <ErrorBoundary>
                        <Sidebar { ...restParams } />
                    </ErrorBoundary>
                    <div className={ styles.Main }>
                        <ErrorBoundary>
                            { React.cloneElement(children, restParams) }
                        </ErrorBoundary>
                    </div>
                    <ErrorBoundary>
                        <ListBox />
                    </ErrorBoundary>
                    <ErrorBoundary>
                        <InfoMessages />
                    </ErrorBoundary>
                    <ErrorBoundary>
                        <Debugger />
                    </ErrorBoundary>
                </div>
            </span>
        );
    }
}

export default observer(Layout);
