import * as React from 'react';
import { observer } from 'mobx-react';

import { observable, makeObservable } from 'mobx';


class ColoredText extends React.Component {
    color = '';

    constructor(props) {
        super(props);

        makeObservable(this, {
            color: observable,
        });

        if (window.PORTAL_STATE === undefined) {
            throw new Error('window.PORTAL_STATE must be defined for ColorInput widget');
        }

        PORTAL_STATE.updateColor = () => {
            this.color = PORTAL_STATE.color;
        };

        setTimeout(() => {
            this.color = PORTAL_STATE.color;
        }, 1);
    }

    render() {
        return (
            <div>
                <h4 style={{ color: this.color }}>Colored text</h4>
            </div>
        );
    }
}

export default observer(ColoredText);
