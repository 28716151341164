import apiRequest from 'lib/apiRequest';

//Получение списка полей
export const fetchOptions = async (table_id, condition, getFromCache = true) => {
    const params = Object.assign({},
        { table_id },
        condition && { condition },
    );
    const response = await new apiRequest('GET /field/field-name-options')
        .qs(params)
        .caching(true, getFromCache)
        .send();
    return {
        response,
        data: response.getData(),
    };
};

//Получение условия по reference qualifier
export const fetchCondition = async (table, column, sysId, getFromCache = true) => {
    const response = await new apiRequest(`POST /reference-qualifier/dynamic/${ table }/${ column }${ sysId ? ('/' + sysId) : '' }`)
        .caching(true, getFromCache)
        .send();
    return {
        response,
        data: response.getData(),
    };
};
