import { observable, makeObservable } from 'mobx';
import * as React from 'react';

import mapper from 'components/dynamicForms/model/field/mapper';
import { underscoreToCamelCase } from 'helpers/misc';

import FormSplitModel from 'components/dynamicForms/model/field/FormSplitModel';
import WidgetModel from 'components/dynamicForms/model/field/WidgetModel';

import _ from 'lodash';
import langStore from 'globalState/lang';
import { runOnTypeScripts, TYPE_ON_LOAD, TYPE_ON_SUBMIT } from 'helpers/scriptClientHelper';
import InfoMessagesState from 'globalState/infoMessages';
import newRecordAttachments from 'globalState/newRecordAttachments';
import apiRequest from 'lib/apiRequest';
import componentsUpdaterState from 'globalState/componentsUpdater';
import { helperRedirect } from 'helpers/history';
import FormsState from 'globalState/forms';
import ActivitiesModel from 'components/dynamicForms/model/field/ActivitiesModel';
import EventBusState from 'globalState/eventBus';
import { eventType } from 'constants/eventBusTypes';
import sidebarState from 'globalState/sidebarState';
import activityFeeds from 'globalState/activityFeeds';
import { evalScript } from 'helpers/widget';
import { LINK_NOT_FOUND } from 'constants/strings';
import { generateRandomString } from 'helpers/data';
import DynamicFormModel from './DynamicFormModel';
import { BaseFormFieldType } from 'types/components/dynamicForms/baseFormField';
import { FormSectionType } from 'types/components/dynamicForms/formSection';
import { FormSplitType } from 'types/components/dynamicForms/formSplit';
import { fetchRemAttr } from "actions/record";

const TABLE_NAME_SYS_DB_TABLE = 'sys_db_table';
const FIELD_TRANSLATED_NAME_MARKER = '{column_translated_title}';
const FIELD_SYS_NAME_MARKER = '{column_name}';

/**
 * класс модели секции формы
 */
export default class FormSectionModel implements FormSectionType {
    ref = React.createRef();
    contextMenu;
    isWarning = false;
    isMandatory = false;
    elements = [];
    index;
    name;
    formName;
    serviceName;
    parentFormModel;
    clientScripts;
    sysId = '';
    forReference;
    tableName = '';
    formId = '';
    isServicePortal;
    remId;
    remAttached;
    widgetId;
    id;
    parentFormId;
    isActivityFeedWidget;

    /**
     *
     * @param data {{}}
     * @param parentFormModel {DynamicFormModel}
     */
    constructor(data, parentFormModel: DynamicFormModel | null = null) {
        makeObservable(this, {
            isWarning: observable,
            isMandatory: observable,
            elements: observable,
            sysId: observable,
            tableName: observable,
            formId: observable,
        });

        this.contextMenu = null;
        this.isWarning = false;
        this.isMandatory = false;
        this.elements = [];
        this.index = -1;
        this.name = '';
        this.formName = '';
        this.serviceName = '';
        this.parentFormModel = parentFormModel;
        this.clientScripts = [];
        this.sysId = '';
        this.tableName = '';
        this.formId = '';
        this.isServicePortal = false;
        this.remId = undefined;
        this.remAttached = undefined;
        this.widgetId = null;
        this.id = '';
        this.parentFormId = '';
        this.isActivityFeedWidget = false;
        this.setData(data);
    }

    getFormId = () => {
        const parentTableName = this.parentFormModel && this.parentFormModel.formId;
        return this.formId || parentTableName;
    };

    getTableName() {
        const parentTableName = this.parentFormModel && this.parentFormModel.tableName;
        return this.tableName || parentTableName;
    }

    getFormName() {
        const parentFormName = this.parentFormModel && this.parentFormModel.formName;
        return this.formName || parentFormName;
    }

    getWidgetId() {
        const parentWidgetId = this.parentFormModel && this.parentFormModel.widgetId;
        return this.widgetId || parentWidgetId;
    }

    getIsActivityFeed() {
        const parentIsActivityFeed = this.parentFormModel && this.parentFormModel.isActivityFeedWidget;
        return this.isActivityFeedWidget || parentIsActivityFeed;
    }

    getSysId() {
        let result = '';
        if (this.sysId) {
            result = this.sysId;
        }
        else {
            if (this.parentFormModel) {
                result = this.parentFormModel?.sysId;
            }
        }
        return result;
    }

    setFieldMessage = (field) => {
        const { reference_titles } = langStore.getTranslate();
        if (field.value?.reference_state === LINK_NOT_FOUND) {
            field.notice = {
                type: 'warning',
                message: reference_titles?.title_record_not_found,
            };
        }
        if (_.isArray(field.value)) {
            const notFoundValues = field.value.filter(value => value?.reference_state === LINK_NOT_FOUND);
            if (notFoundValues.length > 0){
                if (field.value.length === 1){
                    field.notice = {
                        type: 'warning',
                        message: reference_titles?.title_record_not_found,
                    };
                } else {
                    field.notice = {
                        type: 'warning',
                        message: reference_titles?.title_one_record_not_found,
                    };
                }
            }
        }
    };

    /**
     * установка данных модели
     *
     * @param data
     */
    setData(data) {
        this.mergeData(data, ['elements']);
        if (!this.id){
            this.id = generateRandomString();
        }
        const elements = data.elements || data.fields;
        this.elements = elements.map((element) => {
            const field = { allElements: elements, ...element };
            if (!field.read_only) {
                this.setFieldMessage(field);
            }
            return  this.getModelByType(this.fieldModelTransform(field));
        });
        this.forReference = data.forReference;
        this.computeIsMandatory();
        this.computeIsWarning();
    }

    /**
     * трансформации структуры поля пришедшей с бэка
     *
     * @param data
     */
    fieldModelTransform = (field) => {
        field.placeholder = field?.field_info?.placeholder;
        return { ...field };
    };

    /**
     * мерджит данные с сервера с данными модели
     *
     * @param data
     * @param exclude
     */
    mergeData(data, exclude: string[] = []) {
        _.each(data, (value, key) => {
            let property = underscoreToCamelCase(key);
            if (!exclude.includes(property) && this.hasOwnProperty(property) && !_.isEqual(this[property], value)) {
                this[property] = value;
            }
        });
    }

    /**
     * Возвращает класс модели соответствующий переданному типу поля
     *
     * @param field {*}
     * @return {BaseFormFieldModel|{}}
     */
    getModelByType(field) {
        switch (true) {
            case !!(field.column_type && mapper[field.column_type] !== undefined):
                return new mapper[field.column_type](field, this);

            case !!field.split:
                return new FormSplitModel(field);

            case !!field.widget_instance_id:
                return new WidgetModel(field);

            case !!field.formatter:
                return new ActivitiesModel(field);
        }
    }

    /**
     * возвращает массив элментов структурированных для работы со split
     * (разделители в форме)
     *
     * @returns {[BaseFormFieldModel | FormSplitType]}
     */
    getFormattedElements() {
        const parsed = this.parseSplits(this.elements);
        return parsed.result;
    }

    parseSplits = (elements: (BaseFormFieldType | FormSplitType)[], isSubLevel = false) => {
        let currentRowIndex = -1; // индекс текущего ряда
        let target: any[] = []; // текущий массив, в который набираем элементы (строки или столбцы)
        let splitMode = false;
        let skipTillIndex = -1; // Индекс элемента, до которого пропускаем обработку. -1 - не пропускаем, стандартное выполнение
        let lastIndex = -1; //Индекс последнего обработанного элемента на текущем уровне вложенности
        const parsed = elements.reduce((result: any, model: BaseFormFieldType | FormSplitType, index) => {
            if (skipTillIndex < 0 || index > skipTillIndex) { // Если не включен пропуск элементов
                if (model.isSplit()) {
                    switch ((model as FormSplitModel).split) {
                        case 'begin_split':
                            if (splitMode) {
                                // Новая вложенная секция
                                const subElements = elements.slice(index);
                                const subResult = this.parseSplits(subElements, true);
                                if (subResult) {
                                    target.push(subResult.result[0]);
                                    skipTillIndex = index + subResult.index; //Обработка выполнена на вложенном уровне до skipTillIndex элемента, поэтому далее пропускаем элементы
                                }
                                break;
                            }
                            else {
                                // Новая секция
                                currentRowIndex++;
                                result[currentRowIndex] = { title: (model as FormSplitModel).title, rows: [] }; // будем набирать столбцы
                                splitMode = true;
                            }
                        case 'split':
                            if (splitMode) {
                                target = []; // создаем массив строк под новый столбец
                                result[currentRowIndex].rows.push(target);
                            }
                            else {
                                // Нашли split без предыдущего begin_split
                                // Добавляем begin_split в начало списка
                                // Конвертируем набранные строки в первый столбец первой строки
                                currentRowIndex = 0; // сбрасываем счётчик рядов
                                result = []; // сбрасываем результат
                                result[currentRowIndex] = { title: '', rows: [] };
                                result[currentRowIndex].rows.push(target); // создаём новый столбец c ранее набранными полями
                                splitMode = true;
                                target = []; // создаем массив для второго столбца
                                result[currentRowIndex].rows.push(target); // создаем второй столбец
                            }
                            break;
                        case 'end_split':
                            if (isSubLevel) { // работаем на вложенном уровне
                                // заканчиваем работу на этом уровне
                                lastIndex = index;
                                skipTillIndex = elements.length-1; // далее на этом уровне не будем добавлять элементы
                                break;
                            }
                            target = result; // снова набираем в строки
                            splitMode = false;
                            break;
                    }
                }
                else {
                    if (!splitMode) {
                        currentRowIndex++;
                    }
                    target.push(model);
                }
            }
            return result;
        }, target);
        return {
            result: parsed,
            index: lastIndex,
        };
    };


    /**
     *
     * @param data {[]|string}
     */
    clearFields(data) {
        if (Array.isArray(data)) {
            this.elements.forEach((elem: BaseFormFieldType) => {
                if (data.includes(elem.sysColumnName)) {
                    elem.clear();
                }
            });
        }
    }

    /**
     * Проверяет форму на валидность
     * @returns {Boolean}
     */
    checkFormIsInvalid = () => {
        return _.some(this.elements, [
            'isValid',
            false,
        ]);
    };

    /**
     * @param isAll
     * @return {{data: *, error: string[]}}
     */
    serialize(isAll = false) {
        const { validation_field_mandatory } = langStore.getTranslate();
        const sysId = this.sysId;

        const activityState = activityFeeds.getItem(this.getTableName(), sysId);
        let typeTabsData = {};
        if (activityState) {
            const typeTabs = activityState.getTypeTabs();
            typeTabsData = _.reduce(typeTabs, (result, tab) => {
                if (tab.comment && tab.column_name) {
                    result[tab.column_name] = tab.comment;
                }
                return result;
            }, {});
        }

        let allFields = this.elements.filter((el: BaseFormFieldType) => !el.skipInRequest && !el.isSplit());
        let fieldsForSave = [...allFields];

        if (sysId && !isAll) {
            fieldsForSave = allFields.filter((field: BaseFormFieldType) => !field.isWarning && field.changed);
        }

        const data = fieldsForSave.reduce((result, field: BaseFormFieldType) => {
            if (field.getValueForSave) {
                result[field.sysColumnName] = field.getValueForSave();
            }
            return result;
        }, {});

        const error = allFields.filter((field: BaseFormFieldType) => field.isWarning && !field.hidden && !field.notShow && !typeTabsData[field.sysColumnName])
                            .map((field: BaseFormFieldType) => validation_field_mandatory && validation_field_mandatory
                                .replace(FIELD_TRANSLATED_NAME_MARKER, field.name)
                                .replace(FIELD_SYS_NAME_MARKER, field.sysColumnName)
                            );
        return {
            data: { ...data, ...typeTabsData },
            error,
        };
    }

    uiGetForm() {
        return this.parentFormModel ? this.parentFormModel : this;
    }

    uiGetValue(sys_column_name) {
        const field = FormsState.getField(sys_column_name);
        if (!field) return;
        return field.getValueForSave();
    }

    isListenSave = () => {
        if (this.parentFormModel?.isSubForm) {
            return false;
        }
        const subscriptions = EventBusState.getSubscriptions();
        return subscriptions.hasOwnProperty(eventType.AFTER_SAVE_EVENT);
    };

    setEventAfterSave = (isOk, view, tableName, recordId, errors, displayValue = '') => {
        const payload = isOk ? {
            view,
            tableName,
            recordId,
            displayValue,
        } : {
            errors,
        };
        EventBusState.emit(eventType.AFTER_SAVE_EVENT, {
            payload,
            result: isOk ? 'OK' : 'ERROR',
        });
    };

    isListenSaveAndUiActions = () => {
        if (this.parentFormModel?.isSubForm) {
            return false;
        }
        const subscriptions = EventBusState.getSubscriptions();
        return subscriptions.hasOwnProperty(eventType.AFTER_SAVE_AND_GET_UI_ACTIONS_EVENT);
    };

    setEventAfterSaveAndUiActions = (isOk, view, tableName, recordId, errors, displayValue = '') => {
        const payload = isOk ? {
            view,
            tableName,
            recordId,
            displayValue,
        } : {
            errors,
        };
        EventBusState.emit(eventType.AFTER_SAVE_AND_GET_UI_ACTIONS_EVENT, {
            payload,
            result: isOk ? 'OK' : 'ERROR',
        });
    };

    updateWidgetsAfterSave = () => {
        const updateElements = (elements) => {
            _.forEach(elements, element => {
                if (element?.data?.widget_instance_id) {
                    const widgetInstanceId = this.parentFormModel?.isSubForm
                        ? `modal${ element?.data?.widget_instance_id }`
                        : element?.data?.widget_instance_id;
                    window.s_widget.serverUpdate(widgetInstanceId);
                    evalScript(element?.data?.client_script, widgetInstanceId);
                }
            });
        };
        if (this.parentFormModel) {
            _.forEach(this.parentFormModel?.sections, section => {
                updateElements(section.elements);
            });
            return;
        }
        updateElements(this.elements);
    };

    /**
     * @return {Promise<unknown>}
     */
    async save(view = 'Default', isReturnFull = false) {
        const { form_notification_data = {}} = langStore.getTranslate();
        const sysId = this.getSysId();
        const tableName = this.getTableName();
        let rem;
        const parentFormModelId = this.parentFormModel?.id || this.parentFormId || this.id;
        if (this.remId === undefined){
            rem = FormsState.getRemSection(parentFormModelId, this.getTableName(), this.getSysId()); // Поиск дочерней rem формы (симплтэг rem)
        }
        if (this.clientScripts) {
            const result = await runOnTypeScripts(this.clientScripts, TYPE_ON_SUBMIT, null, null, parentFormModelId, this.parentFormModel?.isSubForm);
            if (!result) {
                return Promise.reject('Validation failed');
            }
        }
        if (rem && rem.clientScripts) {
            const result = await runOnTypeScripts(rem.clientScripts, TYPE_ON_SUBMIT, null, null, parentFormModelId, this.parentFormModel?.isSubForm);
            if (!result) {
                return Promise.reject('Validation failed');
            }
        }

        const isInvalidForm = this.parentFormModel ? this.parentFormModel.checkFormIsInvalid() : this.checkFormIsInvalid();

        let serialized;
        if (this.remId !== undefined) { //Если это самостоятельная REM форма (симплтэг remform), то у неё только атрибуты
            serialized = {
                data: {},
                error: [],
            };
        }
        else {
            serialized = this.parentFormModel ? this.parentFormModel.serialize() : this.serialize();
        }
        let { data, error } = serialized;

        if (error.length) {
            InfoMessagesState.pushError(error);
            return Promise.reject(error);
        }

        let remData = [],
            remNotChanged = true,
            serializedREM;
        const isNewRem = rem?.remAttached === false; // Данная секция - это REM секция и он не сохранена
        if (rem) {
            serializedREM = rem.serialize(isNewRem); // Если сначала создана запись, а потом указана модель - нужно отправить на бэк все атрибуты
        }
        if (this.remId !== undefined){
            serializedREM = this.serialize();
        }
        if (serializedREM){
            if (serializedREM.error.length) {
                InfoMessagesState.pushError(serializedREM.error);
                return Promise.reject(serializedREM.error);
            }
            remData = serializedREM.data;
            remNotChanged = _.every(remData, [
                'changed',
                false,
            ]);
        }

        const notChanged = _.every(data, [
            'changed',
            false,
        ]);
        if (Object.keys(data).length === 0 && Object.keys(remData).length === 0 || notChanged && remNotChanged || isInvalidForm) {
            if (notChanged && remNotChanged) {
                InfoMessagesState.pushInfo(form_notification_data.not_changed);
            }
            return Promise.reject(error.join('\n'));
        }

        let requestURL;

        let params = {
            form_id: '',
            form_view: '',
            open_first_rel_list: 0,
            is_service_portal: 0,
        };

        if (this.parentFormModel && this.parentFormModel.formId) {
            params.form_id = this.parentFormModel.formId;
        }
        if (this.parentFormModel && this.parentFormModel.formView) {
            params.form_view = this.parentFormModel.formView;
        }

        if (sysId) {
            requestURL = `POST /record/${ tableName }/${ sysId }`;
            data = {
                record: data,
                rem_attributes: remData ? remData : null,
                rem_id: this.remId || rem?.remId,
            };
        }
        else {
            requestURL = `PUT /record/${ tableName }`;
            // Add attachments for new record
            data = {
                record: data,
                attachments: newRecordAttachments.getData(),
                rem_attributes: remData ? remData : null,
                rem_id: this.remId || rem?.remId,
            };
        }
        if (this.isServicePortal || this.parentFormModel && this.parentFormModel.isServicePortal) {
            params.open_first_rel_list = 0;
            params.is_service_portal = 1;
        }
        else {
            params.is_service_portal = 0;
        }
        if (this.parentFormModel) {
            this.parentFormModel.disableUiActionOnSaveForm(true);
        }
        const response = await new apiRequest(requestURL).qs(params).sendJSON(data).catch().finally(()=>{
            if (this.parentFormModel) {
                this.parentFormModel.disableUiActionOnSaveForm(false);
            }
        });
        if (sysId) componentsUpdaterState.activitiesUpdater++; // Make activites components react and update

        const responseData = response.getData();
        const { record_id, display_value, reference_state } = responseData;
        if (this.parentFormModel && this.parentFormModel.setPageName) {
            this.parentFormModel.setPageName(responseData.page_name || '', responseData.is_page_name_hidden);
        }
        const silentMode = this.parentFormModel && this.parentFormModel.silentMode;
        const isOk = response.status === 'OK';

        if (isOk) {
            FormsState.clearChangedFields(parentFormModelId);
            if (['sys_menu_item', 'sys_menu_category', 'sys_db_table'].includes(tableName)) {
                sidebarState.fetchMenuList();
            }
            if(['sys_menu_favorite'].includes(tableName)) {
                sidebarState.fetchFavoritesList();
            }
            const activityState = activityFeeds.getItem(this.getTableName(), this.sysId);
            if (responseData.sections !== undefined && activityState) {
                activityState.fetchResponseData();
                activityState.setActivityTypesFromFields(responseData.sections);
            }
            this.elements.forEach((element: BaseFormFieldType) => element.changed = false);
            if (this.isListenSave()) {
                this.setEventAfterSave(isOk, view, tableName, record_id, response.messages, display_value);
                if (isReturnFull) {
                    return Promise.resolve({
                        recordId: record_id,
                        displayValue: display_value,
                        referenceState: reference_state,
                    });
                }
                return Promise.resolve(record_id);
            }

            // If we create new record (or new rem in a record), redirect then
            if ((!sysId || isNewRem) && !silentMode && !this.isListenSave()) {
                if (this.isServicePortal || (this.parentFormModel && this.parentFormModel.isServicePortal)) {
                    // ничего не делаем, если сервис портал
                }
                else if (tableName === TABLE_NAME_SYS_DB_TABLE && data.name) {
                    helperRedirect(`/record/${ data.name }`);
                }
                else {
                    helperRedirect(`/record/${ tableName }/${ record_id }`);
                }
            }

            this.elements.forEach((field: BaseFormFieldType) => field.cellEditMode = false);
            this.clearFields([
                'encrypted_password',
                'password',
                'additional_comments',
                'work_notes',
            ]);

            //Обновляем поля формы в соответствии с сохраненными данными
            if (this.parentFormModel){
                const newParentFormModel = { sysId: responseData.record_id, sections: responseData.sections };
                this.parentFormModel.setData(newParentFormModel);
            } else {
                if (responseData.sections) {
                    const newFormSectionModel = { sysId: responseData.record_id, ...responseData.sections[0] };
                    this.setData(newFormSectionModel);
                }
            }
            if (rem) {
                const sysId = responseData.record_id;
                const { data: responseRemData } = await fetchRemAttr(tableName, sysId, '', false);
                const newFormSectionModel = { sysId, ...responseRemData.sections[0] };
                rem.setData(newFormSectionModel);
            }

            /**
             * @todo Перенести на события
             */
            if (sysId && this.parentFormModel && this.parentFormModel.updateUiActionsAfterSaveForm) {
                this.parentFormModel.updateUiActionsAfterSaveForm();
            }

            if (sysId && this.parentFormModel && this.parentFormModel.updateRelatedListAfterSaveForm) {
                if (responseData.related_lists && responseData.related_lists.active_element_id) {
                    this.parentFormModel.updateRelatedListAfterSaveForm(responseData.related_lists.active_element_id);
                }
            }
            if (this.clientScripts) {
                await runOnTypeScripts(this.clientScripts, TYPE_ON_LOAD, null, null, parentFormModelId, this.parentFormModel?.isSubForm);
            }
            if (sysId && rem && rem.clientScripts) {
                await runOnTypeScripts(rem.clientScripts, TYPE_ON_LOAD, null, null, parentFormModelId, this.parentFormModel?.isSubForm);
            }
            if (this.isListenSaveAndUiActions()) {
                this.setEventAfterSaveAndUiActions(isOk, view, tableName, record_id, response.messages, display_value);
            }
            this.updateWidgetsAfterSave();
        }

        if (isReturnFull) {
            return Promise.resolve({
                recordId: record_id,
                displayValue: display_value,
                referenceState: reference_state,
            });
        }
        return Promise.resolve(record_id);
    }

    /**
     * возвращает модель поля секции по его системному имени
     *
     * @param sysColumnName
     * @return {BaseFormFieldModel | FormSplitType | null}
     */
    getFieldByName(sysColumnName) {
        const fieldModel: BaseFormFieldType | FormSplitType | undefined = this.elements.find((model: BaseFormFieldType) => model.sysColumnName === sysColumnName);
        return fieldModel || null;
    }

    getSectionFields() {
        return _.filter(this.elements, (field: BaseFormFieldType) => !field.skipInRequest && !field.isSplit());
    }

    uiGetSectionNames() {
        return this.name;
    }

    uiHideFieldMsg() {
        this.elements.forEach((field: BaseFormFieldType) => {
            if (field.notice) {
                field.notice = null;
            }
        });
    }

    computeIsWarning(){
        if (!_.isEmpty(this.elements)){
            this.isWarning = this.elements.some((element: BaseFormFieldType) => element.isWarning);
            if (this.parentFormModel) {
                this.parentFormModel.isWarning = this.isWarning;
            }
        }
    }

    computeIsMandatory(){
        if (!_.isEmpty(this.elements)) {
            this.isMandatory = this.elements.some((element: BaseFormFieldType) => element.isMandatory);
            if (this.parentFormModel) {
                this.parentFormModel.isMandatory = this.isMandatory;
            }
        }
    }

    setGlobalForms = () => {
        const dynamicFormId = this.parentFormModel?.id || this.parentFormId || this.id;
        if (!this.parentFormId) {
            this.parentFormId = dynamicFormId;
        }
        if (FormsState.getSection(dynamicFormId, this.id)) {
            FormsState.deleteSection(dynamicFormId, this.id);
        }
        FormsState.addSection(dynamicFormId, this);
    };
}



