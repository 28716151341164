import ListBaseStorage from 'components/portalDesigner/storage/listBaseStorage';

/**
 * state со списком всех порталов
 */
class PortalStorage extends ListBaseStorage {
    table_name = 'portal';
    columns = [
        'path_name',
        'title',
        'sys_id',
        'logo',
        'favicon',
        'scss_variables',
        'scss_styles',
    ].join(',');

    constructor() {
        super();
    }
}

export default new PortalStorage;
