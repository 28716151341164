import BaseFormFieldModel from './BaseFormFieldModel';

/**
 * типы полей численного типа
 *
 * @type {[string]}
 */
const COLUMN_TYPES_NUMBER = [
    'integer',
    'smallinteger',
    'biginteger',
    'float',
    'decimal',
    'percent_complete',
];

export default class StringInputModel extends BaseFormFieldModel {

    /**
     *
     * autoComplete
     *
     * @type {string}
     */
    autoComplete;

    /**
     *
     * autofill
     *
     * @type {string}
     */
    autofill;

    /**
     *
     * placeholder
     *
     * @type {string}
     */
    placeholder;

    /**
     *
     * column_name
     *
     * @type {string}
     */
    columnName;

    /**
     *
     * showCleanBtn
     *
     * @type {boolean}
     */
    showCleanBtn;

    // columnType = 'string';

    constructor(data, parentFormSectionModel) {
        super(data, parentFormSectionModel);
        if (this.columnType === 'password') {
            this.autoComplete = 'new-password';
        }
        this.mergeData(data);
    }

    getType() {
        if (this.columnType === 'password' || this.columnType === 'encrypted_password') {
            return 'password';
        }
        return 'text';
    }

    isNumberType() {
        return COLUMN_TYPES_NUMBER.includes(this.columnType);
    }
}
