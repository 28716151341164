import * as React from 'react';
import Container from 'components/container';
import { observable, makeObservable, action } from 'mobx';
import { observer } from 'mobx-react';
import apiRequest from 'lib/apiRequest';
import ActionsPanel from 'components/actionsPanel';
import UiButtons from 'components/uiButtons';
import Filter from 'components/filter';
import { getUrlParams, isEmpty, paramsToString } from 'helpers/data';
import conditionState from 'globalState/conditions/condition';
import { helperRedirectReplace } from 'helpers/history';
import SmartTitle from 'components/smartTitle';
import { fetchDotWalkList } from 'actions/conditions';
import Skeleton from './skeleton';
import UiButtonsContainer from 'components/uiButtonsContainer';
import { EnhancedComponent } from 'components/EnhancedComponent';
import * as _ from 'lodash';
import styles from './styles.module.scss';
import { globalEventEmitter } from 'lib/EventEmitter';
import DragAndDrop from 'components/dragAndDrop';
import ErrorWrapperHoc from 'helpers/hoc/errorWrapperHOC';
import { isMedia } from 'helpers/html';
import UiBurger from 'components/uiBurger';
import { helperRedirect } from 'helpers/history';
import { GroupedTable } from 'components/groupedTable';
import { fetchListFilter, fetchListGroupsFilter } from 'actions/list';
import GroupDataState from 'globalState/table/groupData';
import Button from 'components/button';
import { ATTRIBUTES } from 'constants/attributesForTests';
import subFormState from 'globalState/subForm';
import SubFormModal from 'components/subForm/subFormModal';
import langStore from 'globalState/lang';
import ChosenOptionsState from 'globalState/chosenOptions';
import { checkAndRedirectPage } from 'helpers/tableHelpers';


class ListPage extends EnhancedComponent {
    data = {};
    uiActions = {};
    showAttachPopup = false;
    lastRouteParams = '';
    conditionData;
    dndDropFuncLink = null;
    filterName = '';
    isMediaSm = false;
    isLoadingList = true;
    chosenOptionsState = null;

    //TODO: когда будет реализация валидации стоит это свойство вынести
    maxLengthFind = 255; //ограничение ввода символово в поля для поиска

    constructor(props) {
        super(props);

        makeObservable(this, {
            data: observable,
            uiActions: observable,
            showAttachPopup: observable,
            conditionData: observable,
            isMediaSm: observable,
            isLoadingList: observable,
            fetchData: action,
            fetchUiActions: action,
            updateUiActions: action,
            handleCloseAttachPopup: action,
            handleShowAttachPopup: action,
            checkMedia: action,
        });

        conditionState.clearData();
        this.setFixedCondition();
        window.listGlobal[this.getTableName(props.match, props.location)] = this;
        this.chosenOptionsState = new ChosenOptionsState();

        this.useEffect(this.onMount);
        this.useEffect(this.onUpdate, true);
        this.useEffect(this.onUnmount);
        this.useEffect(this.useImpersonateHandler);
    }

    onMount = (mount) => {
        if (!mount) return;
        this.doFetch();
        //two monitors dictionary window oversize fix
        if (this.props.isWindow) {
            const width = window.outerWidth > window.screen.availWidth ? window.screen.availWidth * .7 : window.outerWidth;
            const height = window.outerHeight > window.screen.availHeight ? window.screen.availHeight * .7 : window.outerHeight;
            window.resizeTo(width, height);
            window.moveTo(window.screenX, window.screenY);
        }

        window.addEventListener('resize', this.onWindowResize);
        this.onWindowResize();
    };

    onUpdate = (mount, update, prevProps) => {
        if (!update) return;

        this.checkMedia();

        const { match, location } = this.props;
        const { search } = location;
        const prevSearch = prevProps.location.search;
        const prevMatch = prevProps.match;

        const matchChanged = match && match.params && prevMatch.params && match.params.table_name !== prevMatch.params.table_name;
        if (search !== prevSearch) {
            this.doFetch(true);

            const matchNotChanged = match && match.params && prevMatch.params && match.params.table_name === prevMatch.params.table_name;
            if (getUrlParams(search).condition && getUrlParams(search).condition !== getUrlParams(prevSearch).condition && matchNotChanged) {
                conditionState.parseConditionString(getUrlParams(search).condition, this.getTableName(match, location), this.data.sys_id);
            }
        }
        if ((!search && prevSearch) || (search && !getUrlParams(search).condition && getUrlParams(prevSearch).condition)) {
            conditionState.clearData();
        }
        if (matchChanged) {
            this.doFetch();
        }
    };

    onUnmount = () => {
        return () => {
            conditionState.clearData();
            window.removeEventListener('resize', this.onWindowResize);
        };
    };

    // Update page data when we do impersonate or deImpersonate
    useImpersonateHandler = () => {
        const handleImpersonate = () => {
            this.doFetch();
        };

        return globalEventEmitter.subscribe([
            'impersonate',
            'deImpersonate',
        ], handleImpersonate);
    };

    setFixedCondition = () => {
        const { match, location } = this.props;
        const { search } = location;
        if (search) {
            const params = getUrlParams(search);
            if (params.is_fixed && params.condition) {
                conditionState.setFixedCondition(params.condition);
                const tableName = this.getTableName(match, location);
                conditionState.parseFixedCondition(params.condition, tableName);
            }
        }
    };

    handleChangeCondition = () => {
        conditionState.refreshBreadcrumbs();
        const parsedSearchParams = getUrlParams(this.props.location.search);
        const isFixed = parsedSearchParams.is_fixed ? parsedSearchParams.is_fixed : undefined;
        if (parsedSearchParams && parsedSearchParams.page) {
            delete parsedSearchParams.page;
        }
        const params = {
            ...parsedSearchParams,
            condition: conditionState.getConditionString(),
            is_fixed: isFixed,
        };
        helperRedirectReplace({
            search: paramsToString(params),
        });
    };

    getTableName = (match, location) => {
        if (!match.params.table_name) {
            return '';
        }
        return (match.params.table_name === 'index') ?
            new URLSearchParams(location.search).get('essence') :
            match.params.table_name;
    };

    doFetch = (isUpdate = false, perPage) => {
        this.fetchUiActions().catch(this.errorFetchData);
        this.fetchData(isUpdate, perPage).catch(this.errorFetchData);
    };

    fetchData = async (isUpdate = false, perPage) => {
        this.isLoadingList = true;
        const { match, location } = this.props;
        const parsedSearchParams = getUrlParams(location.search);

        const tableName = this.getTableName(match, location);
        window.listGlobal[tableName] = this;
        await this.chosenOptionsState.fetchChosenListOptions('table', tableName);
        if (perPage) {
            parsedSearchParams.per_page = perPage;
        } else if (parsedSearchParams.per_page) {
            this.chosenOptionsState.setChosenOption('');
        } else if (this.chosenOptionsState.getChosenOption()) {
            parsedSearchParams.per_page = this.chosenOptionsState.getChosenOption();
        }
        if (this.data && this.data.sort) {
            parsedSearchParams.sort = true;
            parsedSearchParams.type = this.sort.type;
            parsedSearchParams.field = this.sort.field;
        }
        try {
            let data = {},
                isOkStatus = false;
            try {
                ({
                    data,
                    isOkStatus,
                } = await fetchListFilter(tableName, parsedSearchParams, true));
            }
            catch (error) {
                if (error?.response?.status !== 404) {
                    conditionState.clearData();
                    helperRedirect(`/list/${ tableName }`);
                }
            }
            if (isOkStatus) {
                this.data = data;
                if (!isUpdate) {
                    conditionState.parseConditionString(parsedSearchParams.condition, tableName, this.data.sys_id);
                    conditionState.setSortingColumns(this.data.columns || []);
                    this.fetchDotWalk(this.data.sys_id, this.data.columns);
                }
                this.fetchListGroupsFilter(tableName, parsedSearchParams);
                window.s_list.query = this.data.full_condition;
                conditionState.sortingBreadcrumb();

                const hasParamGroupBy = location && location.search.includes('GROUPBY');
                const isRedirecToLastPageCondition = Object.keys(parsedSearchParams).length && !hasParamGroupBy && this.data.items.length === 0;
                
                if (isRedirecToLastPageCondition) {
                    delete parsedSearchParams.per_page;
                    const lastPage = Math.ceil(this.data.pagination.total / this.data.pagination.per_page);

                    if (lastPage < parseInt(parsedSearchParams.page)) {
                        helperRedirectReplace({
                            search: paramsToString({ ...parsedSearchParams, page: lastPage }),
                        });
                    }
                }
            }
        }
        catch (e) {
            throw e;
        }
        this.isLoadingList = false;
    };

    fetchListGroupsFilter = async (tableName, params) => {
        if (params.condition && params.condition.includes('GROUPBY')) {
            const response = await fetchListGroupsFilter(tableName, params);
            if (response.isOkStatus) {
                GroupDataState.setData(response.data);
                const {per_page, groups_count} = response.data;
                checkAndRedirectPage({
                    perPage: per_page,
                    total: groups_count,
                });
            }
        }
    };

    fetchDotWalk = async (tableId, columns) => {
        if (!tableId) {
            return;
        }
        const params = {
            referenced_table_id: tableId,
        };
        const response = await fetchDotWalkList(params);
        const data = response.isOkStatus && response.data ? response.data : {};
        conditionState.setDotWalkList(data.items || data);
        conditionState.findAndSetAddColumnsDotWalkList(columns);
    };

    onClickButton = (item) => {
        this.filterName = item.name;
    };

    fetchUiActions = async () => {
        const { match, location, isWindow } = this.props;

        if (!match.params.table_name || isWindow) {
            return;
        }

        const filters = this.getFilterOptions(this.uiActions.list_burger_menu);
        const selectedFilter = filters.filter(filter => filter.name === this.filterName);

        const parsedSearchParams = getUrlParams(location.search);

        const body = {};

        if (selectedFilter.length) {
            body.current_filter_name = selectedFilter[0].default_name;
        }

        try {
            const url = '/ui-action/list-actions/';
            //TODO: когда будет реализация по фильтрам от бэка переделать на POST
            const response = await new apiRequest('GET ' + url + this.getTableName(match, location)).qs(parsedSearchParams).caching().sendJSON(body);
            const data = response.getData();
            this.uiActions = isEmpty(data) ? this.uiActions : data;
        }
        catch (e) {
            throw e;
        }
    };

    updateUiActions = (uiActions) => {
        if (!uiActions) {
            return;
        }
        this.uiActions.list_burger_menu = this.uiActions.list_burger_menu.map(item => {
            if (item.special === 'list_filters') {
                item.sub_menu = uiActions;
            }
            return item;
        });
    };

    errorFetchData = (e) => {
        console.error(e.message);
    };

    handleCloseAttachPopup = () => {
        this.showAttachPopup = false;
    };

    // Используется для экшенов при вызове окна из скриптов
    handleShowAttachPopup = () => {
        this.showAttachPopup = true;
    };

    getFilterOptions(burgerMenu) {
        if (burgerMenu === undefined) return [];
        for (let i = 0; i < burgerMenu.length; i++) {
            if (burgerMenu[i].special === 'list_filters') {
                return burgerMenu[i].sub_menu;
            }
        }

        return [];
    }

    checkMedia = () => {
        this.isMediaSm = isMedia('sm');
    };

    onWindowResize = () => {
        this.checkMedia();
    };

    handleAddClick = () => {
        window.isShowModal = true;
        const { match, location } = this.props;
        const params = new URLSearchParams(location.search);
        subFormState.setTitle(params.get('label'));
        subFormState.setColumnName(params.get('column_name'));
        subFormState.setColumnId(params.get('column_id'));
        subFormState.setTableName(match.params.table_name);
        subFormState.setIsShow(!subFormState.getIsShow());
    };

    renderNewButton = () => {
        const { isWindow, location } = this.props;
        const params = getUrlParams(location.search);
        const { list_titles } = langStore.getTranslate();
        let button = (
            <Button
                onClick={ this.handleAddClick }
                data-test={ ATTRIBUTES.listNewButton }
            >
                { list_titles?.new }
            </Button>
        );
        if (!isWindow || (isWindow && !params.column_name)) {
            button = null;
        }
        let header = null;
        if (isWindow) {
            header = (
                <div className={ styles.AddHeader }>
                    { this.data.page_name || this.data.essence }
                </div>
            );
        }
        if (!button && !header) {
            return null;
        }
        return (
            <div className={ styles.AddBlock }>
                { header }
                { button }
            </div>
        );
    };

    render() {
        if ((_.isEmpty(this.data) && _.isEmpty(this.uiActions))) {
            return <Skeleton isMobile={ this.isMediaSm } isWindow={ this.props.isWindow } />;
        }

        const filterOptions = this.getFilterOptions(this.uiActions.list_burger_menu);

        return (
            <Container className={ this.props.isWindow ? styles.ContainerWindow : '' }>
                <SmartTitle
                    hidden
                    isWithoutPageTitle={ this.props.isWindow }
                >{ this.data.page_name || this.data.essence }</SmartTitle>

                { this.renderNewButton() }
                { !this.props.isWindow && (
                    <ActionsPanel pageName={ this.data.page_name || this.data.essence }
                                  center={
                                      !this.isMediaSm && <UiButtonsContainer
                                          data={ this.uiActions.list_header_right_button }
                                          tableName={ this.data.essence }
                                          actionsContextMenu={ this.uiActions.button_context_menu }
                                      />
                                  }
                                  onClickButton={ this.onClickButton }
                                  contextMenu={ this.uiActions.list_burger_menu }
                                  headerButton={
                                      !this.isMediaSm && <UiButtons
                                          data={ this.uiActions.list_banner_button }
                                          tableName={ this.data.essence }
                                          actionsContextMenu={ this.uiActions.button_context_menu }
                                      />
                                  }
                                  tableName={ this.data.essence }
                                  right={
                                      this.isMediaSm && <UiBurger
                                          data={ this.uiActions.list_banner_button ? [
                                              ...this.uiActions.list_banner_button,
                                              ...this.uiActions.list_header_right_button,
                                          ] : [] }
                                          tableName={ this.data.essence }
                                          isMobile
                                          menuTitle={ this.data.page_name || this.data.essence }
                                      />
                                  }
                    />
                ) }
                <div className={ styles.FilterWrap }>
                    <Filter
                        tableName={ this.props.match.params.table_name }
                        tableId={ this.data.sys_id }
                        conditionState={ conditionState }
                        location={ this.props.location }
                        options={ filterOptions }
                        updateUiActions={ this.updateUiActions }
                        maxLength={ this.maxLengthFind }
                        isMobile={ this.isMediaSm }
                        isWindow={ this.props.isWindow }
                    />
                </div>

                { !this.isLoadingList ? <GroupedTable
                    parent={ this }
                    key={ this.data.essence }
                    essence={ this.data.essence }
                    view={ new URLSearchParams(this.props.location.search).get('view') }
                    columns={ this.data.columns || [] }
                    items={ this.data.items || [] }
                    isWindow={ this.props.isWindow }
                    usedByList={ new URLSearchParams(this.props.location.search).get('used_by_list') === 'true' }
                    contextMenuHeader={ this.uiActions.list_table_header_context_menu }
                    contextMenuBody={ this.uiActions.list_context_menu }
                    pagination={ this.data.pagination }
                    location={ this.props.location }
                    conditionState={ conditionState }
                    onChangeCondition={ this.handleChangeCondition }
                    selectUiAction={ this.uiActions.list_choice }
                    doFetch={ this.doFetch }
                    tableId={ this.data.sys_id }
                    clientScripts={ this.data.client_scripts }
                    maxLength={ this.maxLengthFind }
                    isServicePortal={ false }
                    isMobile={ this.isMediaSm }
                    chosenOptionsState={ this.chosenOptionsState }
                /> : <Skeleton isMobile={ this.isMediaSm } isList />
                }
                <UiButtons data={ this.uiActions.list_bottom_button }
                           tableName={ this.data.essence }
                           actionsContextMenu={ this.uiActions.button_context_menu } />
                { this.uiActions.list_link && this.uiActions.list_link.length > 0 &&
                <UiButtons data={ this.uiActions.list_link }
                           tableName={ this.data.essence }
                           actionsContextMenu={ this.uiActions.button_context_menu }
                           isRelatedLinks={ true } />
                }
                <DragAndDrop
                    onClose={ this.handleCloseAttachPopup }
                    show={ this.showAttachPopup }
                    attachUrl={ '/import/json' }
                    essence={ this.props.match.params.table_name }
                    onDropFunc={ (func) => {
                        this.dndDropFuncLink = func;
                    } }
                    isWaitForRequest
                    isClearAttachWhenClose
                />
                <SubFormModal
                    isList={ !this.props.isWindow }
                />
            </Container>
        );
    }
}


export default ErrorWrapperHoc(observer(ListPage));
