import BaseFormFieldModel from './BaseFormFieldModel';
import _ from 'lodash';

export default class HtmlInputModel extends BaseFormFieldModel {

    columnType = 'html';

    constructor(data, parentFormSectionModel) {
        super(data, parentFormSectionModel);
        super.mergeData(data);
    }

    uiSetValue(value) {
        if (value === '') {
            return this.uiClearValue();
        }
        if (_.isEqual(this.defaultValue, value)) {
            this.changed = false;
        } else if (!_.isEqual(this.value, value)) {
            this.changed = true;
        }
        setTimeout(() => {
            // Баг в плагине https://github.com/ckeditor/ckeditor4-react/issues/114 когда пофиксят можно убрать
            // не отображается измененное значение, хотя в плагин данные поступают
            this.value = value;
        }, 300);
    }
}
