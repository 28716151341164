import _ from 'lodash';
import { smartRedirect } from 'helpers/history';
import { getDateValueByString, getUserDateTimeFormat, getUserTimezone } from 'helpers/getUserTimeZone';

export function handleKeyDown(e, isServicePortal, searchParam) {
    if (e.keyCode === 13) {
        e.preventDefault();
        onSearch(isServicePortal, searchParam);
    }
}

export function onSearch(isServicePortal, param) {
    const searchParam = param.trim();
    if (!searchParam) return;

    const resultParam = encodeURIComponent(encodeURIComponent(searchParam)); // react-router автоматом декадирует, если строка в match params, поэтому 2 раза, чтобы потом не вылезло ошибки
    if (isServicePortal) {
        smartRedirect('/sp/search/' + resultParam);
    } else {
        smartRedirect('/search/' + resultParam);
    }
}

export function simpleDecodeUri(searchParam: string) {
    const matchesFromSearchParam = searchParam.match(/%[\dA-F]{2}/g);
    let result = searchParam;
    if (matchesFromSearchParam) {
        try {
            result = decodeURIComponent(result);
        } catch (err) {
            _.forEach(_.uniq(matchesFromSearchParam), item => {
                result = result.replace(new RegExp(item, 'g'), `${ decodeURIComponent(item) }`);
            });
        }
    }
    return result;
}

export function getParseText(text: string, replacer: (match: string, text: string) => string) {
    return text.replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/&lt;srch_plh&gt;([^<]*?)&lt;\/srch_plh&gt;/g, replacer);
}

export function getFormatValue(column_type: string, value: string) {
    if (column_type === "datetime") {
        return getDateValueByString(
            value,
            getUserTimezone(),
            getUserDateTimeFormat()
        );
    }
    return value;
}
