import * as React from 'react';
import Container from 'components/container';
import Chart from 'components/chart';
import Select from 'components/chart/select';
import Button from 'components/button';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import apiRequest from 'lib/apiRequest';
import { helperRedirect } from 'helpers/history';
import Heading from 'components/heading';
import styles from './styles.module.scss';
import ActionsPanel from 'components/actionsPanel';
import ErrorWrapperHoc from 'helpers/hoc/errorWrapperHOC';
import langStore from 'globalState/lang';


class ChartPage extends React.Component {
    data;
    errMessage = '';
    lastRouteParams = '';

    constructor(props) {
        super(props);

        makeObservable(this, {
            data: observable,
            errMessage: observable,
        });

        this.fetchData().catch(this.fetchError);
    }

    fetchError = (e) => {
        this.errMessage = e.message;
    };

    fetchData = async () => {
        const routeParams = window.location.pathname + window.location.search;
        if (routeParams.localeCompare(this.lastRouteParams) === 0) {
            return false;
        }
        this.lastRouteParams = routeParams;

        const response = await new apiRequest('GET ' + routeParams).send();
        this.data = response.getData();
    };

    runFilters = () => {
        let errors = 0;
        const { filters } = this.data;

        //validate selects if empty
        for (const prop in filters) {
            if (filters.hasOwnProperty(prop)) {
                const item = filters[prop];
                if (item.value === '') {
                    item.isError = true;
                    errors++;
                }
            }
        }

        // if everything is ok
        if (errors === 0) {
            const redirect = '/chart/' + filters['table_name'].value + '/' + filters['column_name'].value + '/' + filters['chart_type'].value + window.location.search;
            helperRedirect(redirect);
            this.fetchData().catch(this.fetchError);
        }
    };

    onSelectChange = async (value, type) => {
        // update select current value
        this.data.filters[type].value = value;
        this.data.filters[type].isError = false;

        // if table_name select changed
        if (type === 'table_name') {
            // reset column_name select value
            this.data.filters['column_name'].value = '';

            // get column_name select options
            if (value) {
                const response = await new apiRequest('GET /chart/get-columns?essence='+value).send();
                this.data.filters['column_name'].options = response.getData().column_name.options;
            }
        }
    };

    renderFilters() {
        const { filters } = this.data;
        const items = [];

        for (const prop in filters) {
            if (filters.hasOwnProperty(prop)) {
                const item = filters[prop];
                items.push(
                    <div key={ prop } className={ styles.field }>
                        <label>{ item.label }</label>
                        <Select options={ item.options } value={ item.value } isError={ item.isError } onChange={ (value) => this.onSelectChange(value, prop) } />
                    </div>,
                );
            }
        }

        return items;
    }

    render() {
        if (this.errMessage) return <Container>{ this.errMessage }</Container>;
        if (!this.data) return null;
        const { report } = langStore.getTranslate();

        return (
            <Container>
                <div className={ styles.content }>
                    <ActionsPanel pageName={ '' } center={ null } right={ null } hideBurgerMenu />
                    <div className={ styles.filters }>
                        <Heading size={'h3'}>
                            { report.group_by }
                        </Heading>
                        { this.renderFilters() }
                        <Button onClick={ this.runFilters } buttonType='primary'>update</Button>
                    </div>
                    <div className={ styles.charts }>
                        <Heading size={ 'h3' }>
                            { report.chart }
                        </Heading>
                        <Chart data={ this.data } />
                    </div>
                </div>
            </Container>
        );
    }
}


export default ErrorWrapperHoc(observer(ChartPage));
