import { action, observable, makeObservable, runInAction } from 'mobx';
import * as React from 'react';
import * as _ from 'lodash';
import parse from 'html-react-parser';
import ServicePortalWidgetsPage from 'pages/servicePortalWidgets';
import modalsDataState from 'globalState/modals';

class ModalDataState {
    private _id: number | undefined;
    private _isShow = false;
    private _width = 0;
    private _title = '';
    private _element: JSX.Element | undefined;
    private _isLoading = false;

    constructor(id?: number) {
        makeObservable(this, {
            _id: observable,
            _isShow: observable,
            _width: observable,
            _title: observable,
            _isLoading: observable,
            setId: action,
            setShow: action,
            setWidth: action,
            setTitle: action,
            renderTemplate: action,
            renderPageTemplate: action,
        });

        if (id) {
            runInAction(() => {
                this._id = id;
            });
        }
    }

    setId = (id: number) => {
        this._id = id;
    };

    getId = (): number | undefined => {
        return this._id;
    };

    setShow = (isShow = true) => {
        this._isShow = isShow;
    };

    getShow = (): boolean => {
        return this._isShow;
    };

    setWidth = (width: number) => {
        this._width = width;
    };

    getWidth = (): number => {
        return this._width;
    };

    setTitle = (title: string) => {
        this._title = title;
    };

    getTitle = (): string => {
        return this._title;
    };

    getIsLoading(): boolean {
        return this._isLoading;
    }

    setIsLoading(value: boolean) {
        this._isLoading = value;
    }

    getElement = (): JSX.Element | undefined => {
        return this._element;
    };

    renderTemplate = (template: string, styles: string) => {
        _.forEach(modalsDataState.getModals(), modal => {
            if (modal.getId() !== this._id) {
                modal.setShow(false);
            }
        });
        const resultTemplate = styles ? `<style>${ styles }</style>${ template }` : template;
        this.setShow();
        this._element = parse(resultTemplate);
    };

    renderPageTemplate = (pageTemplatePathName: string, portalSuffix: string) => {
        _.forEach(modalsDataState.getModals(), modal => {
            if (modal.getId() !== this._id) {
                modal.setShow(false);
            }
        });
        this.setShow();
        this._element = (
            <ServicePortalWidgetsPage
                portalSuffix={ portalSuffix }
                pageTemplatePathName={ pageTemplatePathName }
                isModal
            />
        );
    };
}

export const modalDataState = ModalDataState;
export default new ModalDataState();
