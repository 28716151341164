import * as React from 'react';

import { observer } from 'mobx-react';

import styles from './styles.module.scss';
import UiButtons from 'components/uiButtons';
import REMFormWidgetState from './state';
import { withRouter } from 'react-router-dom';
import { ATTRIBUTES } from 'constants/attributesForTests';
import IconChevronRight from 'assets/img/icons/chevron-right.svg';
import IconChevronDown from 'assets/img/icons/chevron-down.svg';
import { beforeUnload } from 'helpers/form';
import FormsState from 'globalState/forms';
import widgetsDataState from 'globalState/widgets';
import DynamicForm from 'components/dynamicForms';

/**
 * Виджет для форм составленных из REM-атрибутов на портале
 * @props tableName системное название таблицы
 * @props recordId id записи
 * @props modelId РЕМ модель
 * @props isUserScripts Запускать клиентские скрипты
 * @props titleHide Скрыть заголовок
 * @props className Класс контейнера формы
 * @props location
 * @props isPortal Режим портала
 * @props saveButtonCaption Надпись на кнопке Save
 * @props readOnly Режим - только чтение
 * @props hideSaveButton - Скрыть кнопку сохранения
 * @props formName - идентификатор формы
 */
class REMFormWidget extends React.Component {
    /**
     * @type REMFormWidgetState
     */
    state;

    constructor(props) {
        super(props);

        this.state = new REMFormWidgetState(props);
    }

    componentDidMount() {
        window.addEventListener('beforeunload', beforeUnload);
    }

    componentDidUpdate(prevProps) {
        const { tableName, recordId, modelId, isUserScripts, widgetId, formName, isPortal, titleHide } = this.props;
        if (
            prevProps.tableName !== tableName
            || prevProps.recordId !== recordId
            || prevProps.titleHide !== titleHide
            || prevProps.modelId !== modelId
            || prevProps.isUserScripts !== isUserScripts
            || prevProps.widgetId !== widgetId
            || prevProps.formName !== formName
            || prevProps.isPortal !== isPortal
        ) {
            this.state.updateData(this.props).catch(console.error);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.state.timeout);
        widgetsDataState.removeForm(this.state.dynamicFormId);
        window.removeEventListener('beforeunload', beforeUnload);
    }

    handleHideClick = () => {
        this.state.switchHiddenState();
    };

    renderHeader = () => {
        const { titleHide, isPortal } = this.props;
        if (titleHide || isPortal) {
            return null;
        }
        const arrowRight = <div className={ styles.HideButton }
                                onClick={ this.handleHideClick }
                                dangerouslySetInnerHTML={ { __html: IconChevronRight } }
        />;
        const arrowDown = <div className={ styles.HideButton }
                             onClick={ this.handleHideClick }
                             dangerouslySetInnerHTML={ { __html: IconChevronDown } }
        />;
        const closedStyle = !this.state.hidden ? '' : styles.HeadingAgentClosed;

        return (
            <div className={ `${ styles.HeadingAgent } ${ closedStyle }` }>
                { this.state.hidden ? arrowRight : arrowDown }
                <span className={ styles.HeadingText }>{ this.state.getTitle() }</span>
            </div>
        );
    };

    renderForm = () => {
        const { isPortal, saveButtonCaption, readOnly, widgetId, formName, hideSaveButton } = this.props;
        const { tableName, recordId, remData, modelId, isUserScripts, hidden } = this.state;
        if (!remData || hidden) {
            return null;
        }
        const { rem_title: name, sections, client_scripts: clientScripts } = remData;
        const fields = sections[0].elements;
        if (!fields) {
            return null;
        }
        let stateFields = FormsState.getFields(this.state.id);
        stateFields = _.filter(stateFields, field => field.parentFormSectionModel.remId === modelId);
        const disabled = !!readOnly || !!_.find(stateFields, field => field.isMandatory && !field.value);
        const saveScript = (formName || widgetId ? `s_widgets.getFormById('${ this.state.dynamicFormId }').save()` : 's_form.save()') + '.then(__resolveServerResponse).catch(__rejectServerResponse)';
        const actionData = isPortal ? [{
            client: true,
            style: 'primary',
            script: saveScript,
            name: saveButtonCaption,
            disabled: disabled,
            page_key: 'form_button__save',
            wait_server_response: 'true',
        }] : [];
        const formContainerClass = isPortal ? '' : styles.FormContainerAgent;

        return (
            <div className={ formContainerClass }>
                <DynamicForm
                    isServicePortal={ isPortal }
                    sections={ sections }
                    fields={ fields }
                    name={ name }
                    sysId={ recordId || null }
                    tableName={ tableName }
                    clientScripts={ isUserScripts && clientScripts }
                    key={ `${ tableName }-${ recordId }` }
                    widgetId={ widgetId }
                    formName={ formName }
                    id={ this.state.dynamicFormId }
                    remId={ modelId }
                />
                { this.props.children }

                { actionData.length !== 0 && !hideSaveButton && <div className={ styles.SaveButtonWrapper }>
                    <UiButtons
                        recordId={ recordId || null}
                        tableName={ tableName }
                        data={ actionData }
                        isServicePortal={ isPortal }
                    />
                    </div>
                }
            </div>
        );
    };

    render() {
        if (!this.state) {
            return null;
        }

        const { remData } = this.state;
        const { className, isPortal } = this.props;

        if (!remData) {
            return null;
        }

        const customClass = className || '';
        const wrapperClass = isPortal ? customClass : `${ styles.WrapperAgent } ${ customClass }`;
        return (
            <div className={ wrapperClass } data-test={ this.props['data-test'] ? this.props['data-test'] : `form-${ ATTRIBUTES.widget }` }>
                { this.renderHeader() }
                { this.renderForm() }
            </div>
        );
    }
}

export default withRouter(observer(REMFormWidget));
