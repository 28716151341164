import * as React from 'react';
import { runScript } from 'helpers/scriptClientHelper';
import { observer } from 'mobx-react';
import { getAttr, getType, injectData } from 'helpers/widget';
import ChildrenComponent from 'components/widget/childrenComponent';
import { ATTRIBUTES } from 'constants/attributesForTests';

const DIRECTIVE_IF = 'simple-if';
const DIRECTIVE_REPEAT = 'simple-repeat';
const DIRECTIVE_SHOW = 'simple-show';
const DIRECTIVE_STYLE = 'simple-style';
const CHILDREN_CLASS_NAME = 'ChildrenComponent';
const PARSED_CLASS_NAME = 'ParsedComponent';

class ParsedComponent extends React.Component {
    componentDidCatch(error) {
        console.warn(error);
    }

    getStyle = (style) => {
        if (style) {
            try {
                return eval(style);
            }
            catch (e) {
                console.error(e);
                return {};
            }
        }
        return {};
    };

    handleChangeField = (key) => (e) => {
        const { widgetDataState } = this.props;
        const onchange = this.props['event-change'];
        widgetDataState.change(key)(e);
        if (onchange) {
            runScript(onchange);
        }
    };

    handleClickField = () => (event) => {
        const onclick = this.props['event-click'];
        if (onclick) {
            event.preventDefault();
            runScript(onclick);
        }
    };

    handleMouseOverField = () => () => {
        const onMouseOver = this.props['event-mouseover'];
        if (onMouseOver) {
            runScript(onMouseOver);
        }
    };

    handleMouseOutField = () => () => {
        const onMouseOut = this.props['event-mouseout'];
        if (onMouseOut) {
            runScript(onMouseOut);
        }
    };

    handleContextMenuField = () => (event) => {
        const onContext = this.props['event-context'];
        if (onContext) {
            event.preventDefault();
            runScript(onContext);
        }
    };

    getEvents = () => {
        const { model } = this.props;
        const events = {
            onClick: this.handleClickField(),
            onContextMenu: this.handleContextMenuField(),
            onMouseOver: this.handleMouseOverField(),
            onMouseOut: this.handleMouseOutField(),
        };
        if (!model) {
            return events;
        }
        const fieldIds = model.split('.');
        events.onChange = this.handleChangeField(fieldIds[1]);
        return events;
    };

    checkIfDirective = (attrs) => {
        let result;
        if (attrs && attrs.hasOwnProperty(DIRECTIVE_IF)) {
            const f = new Function('"use strict";return (' + (attrs[DIRECTIVE_IF] || false) + ')');
            try {
                result = f();
            }
            catch (e) {
                console.error(e);
                result = false;
            }
        }
        else {
            result = true;
        }
        return result;
    };

    updateChildChildren = (propName, propValue, children) => {
        if (propName && typeof children === 'string') {
            // заменяем {propName} на имя этого свойства - где propName это ключ заданный пользователем
            const replaceRegex = new RegExp(`{\\s?${propName}\\s?}`, 'g');
            const searchProp = children.search(replaceRegex);
            if (searchProp !== -1) {
                return children.replace(replaceRegex, propValue);
            }
        }
        return children;
    };

    getRepeatProps = (propName, propValue, props) => {
        const updProps = { ...props };
        for (const key in updProps) {
            if (updProps.hasOwnProperty(key)) {
                updProps[key] = this.updateChildChildren(propName, propValue, updProps[key]);
            }
        }
        return updProps;
    };

    getRepeatData = (items, propName, element) => {
        const { children, widgetDataState } = this.props;
        let clonedElements = [];
        if (items) {
            items.forEach((item) => {
                const props = {
                    ...element.props,
                    key: item[0], // ключ элемента массива (имя свойства объекта)
                    [propName]: item[1], // значение элемента массива (значение свойства объекта)
                };
                let newChildren = [];
                if (children && children.length) {
                    children.forEach((child, index) => {
                        // свойства для повторов
                        const childProps = {
                            ...this.getRepeatProps(child.props),
                            data: {
                                ...widgetDataState.getData(),
                                [propName]: item[1],
                            },
                            children: typeof child.props.children === 'string'
                                ? this.updateChildChildren(propName, item[0], child.props.children)
                                : child.props.children,
                        };
                        if (child.type.name === CHILDREN_CLASS_NAME) {
                            newChildren.push(<ChildrenComponent key={ `child${ index }` } { ...childProps } />);
                        } else if (child.type.name === PARSED_CLASS_NAME) {
                            const Type = getType(child.props.type);
                            newChildren.push(<Type key={ `child${ index }` } { ...childProps } />);
                        }
                    });
                }
                clonedElements.push(<element.type { ...props } >{ newChildren }</element.type>);
            });
        }
        return clonedElements;
    };

    applyRepeatDirective = (attrs, element) => {
        let result;
        let expr = attrs && attrs.hasOwnProperty(DIRECTIVE_REPEAT) ? attrs[DIRECTIVE_REPEAT] : null;
        if (expr) {
            try {
                expr = injectData(this.props.widgetDataState.getData(), expr);
                const re = /^(\S+)\s+in\s+(.+)$/; // находим имя prop и массив (или объект) в конструкции for in
                const match = expr.match(re);
                if (!match) {
                    result = null;
                }
                else {
                    const propName = match[1]; // имя prop, которое будет передаваться в клонируемый компонент
                    const objName = match[2]; // массив (или объект со свойствами) для итераций.
                    const f = new Function('"use strict";return (' + objName + ')');
                    const object = f(); // получаем объект
                    const items = object && Object.entries(object);
                    result = <>{ this.getRepeatData(items, propName, element) }</>;
                }
            }
            catch (e) {
                console.error(e);
                result = null;
            }
        }
        else {
            result = element; // директива не установлена для элемента
        }
        return result;
    };

    applyShowDirective = (attrs) => {
        let result;
        if (attrs && attrs.hasOwnProperty(DIRECTIVE_SHOW)) {
            const f = new Function('"use strict";return (' + (attrs[DIRECTIVE_SHOW] || false) + ')');
            try {
                const isShow = f();
                if (!isShow) {
                    result = { 'display': 'none' };
                }
            }
            catch (e) {
                console.error(e);
            }
        }
        return result;
    };

    applyStyleDirective = (attrs) => {
        let res;
        if (attrs && attrs.hasOwnProperty(DIRECTIVE_STYLE)) {
            const f = new Function('"use strict";return (' + attrs[DIRECTIVE_STYLE] + ')');
            try {
                res = f();
            }
            catch (e) {
                console.error(e);
            }
        }
        return res;
    };

    removeRedundantAttr(typeProps, type) {
        const _typeProps = {...typeProps};
        delete _typeProps[DIRECTIVE_IF];
        delete _typeProps[DIRECTIVE_SHOW];
        delete _typeProps['timestampKey'];
        delete _typeProps['fill-rule'];
        delete _typeProps['clip-rule'];
        if (typeof type === 'string') {
            delete _typeProps['widgetId'];
            delete _typeProps['tableName'];
            delete _typeProps['recordId'];
        }
        return _typeProps;

    }


    render() {
        const { style, children, type } = this.props;
        const Type = getType(type);
        const attrs = getAttr(this.props);
        const customStyle = {
            ...this.getStyle(style),
            ...this.applyStyleDirective(attrs),
            ...this.applyShowDirective(attrs),
        };
        let typeProps = {
            ...attrs,
            ...this.getEvents(),
        };

        let output = Type && !!this.checkIfDirective(attrs) && <Type { ...this.removeRedundantAttr(typeProps, Type) } style={ customStyle } data-test={ `${ type }-${ ATTRIBUTES.widget }` }>
            { children }
        </Type>;
        output = output && this.applyRepeatDirective(attrs, output);
        return output;
    }
}

export default observer(ParsedComponent);
