import sidebarState from 'globalState/sidebarState';
import styles from 'layouts/main/sidebar/styles.module.scss';
import Button from 'components/button';
import IconCircleLeft from 'assets/img/icons/circle-left.svg';
import * as React from 'react';
import { observer } from 'mobx-react';
import Label from 'layouts/main/sidebar/label';
import { DynamicHeaderPropsType } from 'types/globalState/menu';
import { ATTRIBUTES } from 'constants/attributesForTests';

function DynamicHeader({ handleClickDynamicBack }: DynamicHeaderPropsType) {
    if (!sidebarState.getIsOpenDynamic()) {
        return null;
    }
    const title = !sidebarState.getDynamicTitle() ? (
        <div
            className={ styles.SkeletonTitleHolder }
        />
    ) : (
        <div className={ styles.DynamicHeaderTitle } data-test={ ATTRIBUTES.sidebarDynamicHeaderTitle } >
            <Label>{ sidebarState.getDynamicTitle() }</Label>
        </div>
    );
    return (
        <div
            className={ styles.DynamicHeader }
            data-test={ ATTRIBUTES.sidebarDynamicHeader }
        >
            <Button
                buttonType='icon-mini'
                onClick={ handleClickDynamicBack }
                svg={ IconCircleLeft }
                className={ styles.DynamicHeaderRow }
                data-test={ ATTRIBUTES.sidebarDynamicIconBack }
            />
            { title }
        </div>
    );
}

export default observer(DynamicHeader);