/**
 * Описание: Выход объекта активность
 * Для вывода выхода активности на диаграмме
 * Параметры:
 * sys_id - идентификатор выхода
 * x - положение относительно начала блока активности для выходов
 * y - положение относительно начала блока активности для выходов
 * width - ширина выхода
 * height - высота выхода
 * name - название выхода
 * activeExit - текущий активный выход
 * setActiveExit - установить выход текущим
 * cornerRadius - скругление углов последнего выхода в активити (пока не используется)
 * selectObject - метод выбора объекта (установки его текущим)
 * onContextMenu - обработчик события выхова контекстного меню
 * selected - выбран
 * togglePropertiesPanel - метод отображения панели свойств
 * readOnly - запрет редатирования
 * linked - к выходу присоединена связь
 * isNew - выход ещё не сохранён в БД
 * setExitSortingMode - перейти в режим сортировки выходов
 * yRange - диапазон абсолютных значений по оси Y области, в которой может располагаться выход при его перетаскивании
 * moveExit - метод для пересчёта положении других выходов активности при перетаскивании данного выхода
 * saveExitPosition -  метод для сохранения положения выходов (вызывается при окончании перетаскивания выхода)
 * state - статус выхода для режима viewer (true - выполнен переход, false - не выполнен)
 * isPanMode - режим панорамирования
 * editCompleted - завершена ли активность выхода
 * */

import * as React from 'react';
import { Rect, Text, Group, Line, Circle } from 'react-konva';


const completedColor = '#05c270';
const selectedColor = '#E8F0FE';
const regularColor = '#ffffff';
const inactiveColor = '#DFE1E5';
const inactiveTextColor = '#A6A7B5';
const lineColor = '#dfe1e5';
const textColor = '#17181d';
const activeColor = '#0f8af9';
const regularExitColor = '#6E728F';

export default class Exit extends React.Component {

    constructor(props) {
        super(props);
    }

    handleClick = (event) => {
        if (event.evt.button === 2) {
            this.props.selectObject('wf_activity_exit', this.props.sys_id, true);
        }// context menu selects object
        else {
            this.props.selectObject('wf_activity_exit', this.props.sys_id);
        } // toggle selection
        event.cancelBubble = true;
    };

    handleDblClick = (event) => {
        this.props.selectObject('wf_activity_exit', this.props.sys_id, true); // select & show properties
        this.props.togglePropertiesPanel(true);
        event.cancelBubble = true;
    };

    isSelected = () => {
        return this.props.selected.type === 'wf_activity_exit' && this.props.selected.sys_id === this.props.sys_id;
    };

    handleMouseOver = () => {
        if (!this.props.readOnly) {
            this.props.setActiveExit(this.props.sys_id);
        }
    };

    handleMouseOut = () => {
        if (!this.props.readOnly) {
            this.props.setActiveExit(null);
        }
    };

    componentDidCatch(error, info) {
        console.error(error, info);
    }

    handleDragStart = (e) => {
        e.cancelBubble = true;
        this.props.setExitSortingMode(true);
    };

    handleDragMove = (e) => {
        e.cancelBubble = true;
        e.target.x(this.props.x);
        if (e.target.y() < this.props.yRange.start) {
            e.target.y(this.props.yRange.start);
        }
        if (e.target.y() > this.props.yRange.end) {
            e.target.y(this.props.yRange.end);
        }
        this.props.moveExit(e.target.y() + this.props.height / 2, this.props.sys_id);
        e.cancelBubble = true;
    };

    handleDragEnd = (e) => {
        e.cancelBubble = true;
        this.props.setExitSortingMode(false);
        this.props.saveExitPosition();
        e.cancelBubble = true;
    };

    render() {
        return (
            <Group
                draggable={ !this.props.readOnly && this.props.activeExit !== this.props.sys_id && !this.props.isPanMode } // если выход не выбран для создания новой связи
                x={ this.props.x }
                y={ this.props.y }
                width={ this.props.width }
                height={ this.props.height }
                onDragStart={ this.handleDragStart }
                onDragMove={ this.handleDragMove }
                onDragEnd={ this.handleDragEnd }
                onDblClick={ this.handleDblClick }
                onClick={ this.handleClick }
                onContextMenu={ (e) => {
                    this.props.onContextMenu(e);
                } }
                _useStrictMode
            >
                <Rect
                    x={ 1 }
                    y={ 1 }
                    width={ this.props.width }
                    height={ this.props.height }
                    fill={ !this.props.editCompleted ? inactiveColor : (this.isSelected() ? selectedColor : regularColor) }
                    strokeWidth={ 1 }
                    cornerRadius={ this.props.cornerRadius }
                />
                <Line
                    stroke={ lineColor }
                    fill={ lineColor }
                    points={ [
                        1,
                        1,
                        this.props.width + 1,
                        1,
                    ] }
                    strokeWidth={ 1 }
                    listening={ false }
                />
                <Text
                    x={ 13 }
                    y={ 9 }
                    width={ this.props.width - 24 }
                    height={ this.props.height }
                    text={ this.props.name }
                    fontSize={ 12 }
                    fill={ !this.props.editCompleted ? inactiveTextColor : textColor }
                    fontFamily='Open Sans'
                    ellipsis={ true }
                    wrap={ 'none' }
                    listening={ false }
                />
                <Circle
                    x={ this.props.width + 1 }
                    y={ (this.props.height) / 2 }
                    radius={ 4 }
                    fill={ (this.props.activeExit === this.props.sys_id) ? activeColor : regularColor }
                    stroke={ this.props.linked ? (this.props.state ? completedColor : regularExitColor) : activeColor }
                    strokeWidth={ 2 }
                    onMouseOver={ this.handleMouseOver }
                    onMouseOut={ this.handleMouseOut }
                />
            </Group>
        );
    }
}
