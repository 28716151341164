import styles from 'layouts/main/sidebar/menu/styles.module.scss';
import { ATTRIBUTES } from 'constants/attributesForTests';
import MenuLink from 'layouts/main/sidebar/menu/menuLink';
import { Link } from 'react-router-dom';
import IconEdit from 'assets/img/icons/edit-2.svg';
import sidebarState from 'globalState/sidebarState';
import IconStarFill from 'assets/img/icons/star-fill.svg';
import IconStar from 'assets/img/icons/star.svg';
import * as React from 'react';
import { SubMenuItemPropsType } from 'types/globalState/menu';
import { observer } from 'mobx-react';

function SubMenuItem({
                         item,
                         subItem,
                         subIndex,
                         level,
                         renderMenuItems,
                         handleClickEdit,
                         toggleFavorites,
                         handleDynamicLinkClick,
                     }: SubMenuItemPropsType): JSX.Element | null {
    if (item.getDynamic() || !subItem.getIsShow()) {
        return null;
    }
    if ((subItem.getItems().length && !sidebarState.getIsOpenDynamic()) || subItem.getIsCategory()) {
        return renderMenuItems(subItem, subIndex, [], level + 1);
    }

    let favoritesClass: string[] | string = [ styles.linkBtn ];
    if (subItem.getIsFavorite()) {
        favoritesClass.push(styles.active);
    }
    favoritesClass = favoritesClass.join(' ');
    let itemClass: string[] | string = [ styles.item ];
    if (subItem.getIsActive()) {
        itemClass.push(styles.active);
    }
    itemClass = itemClass.join(' ');
    return (
        <div className={ itemClass }
             key={ `subItem${ subItem.getSysId() }${ subIndex }` }
             data-test={ ATTRIBUTES.sidebarMenuSubItem }
        >
            <div className={ styles.linkWrap } data-test={ ATTRIBUTES.sidebarMenuSubItemLinks }>
                <MenuLink
                    item={ subItem }
                    handleDynamicLinkClick={ handleDynamicLinkClick }
                />
                {
                    item.getIsEditAccess() && (
                        <Link
                            to={ subItem.getEditUrl() }
                            onClick={ handleClickEdit }
                            className={ styles.linkBtn }
                            dangerouslySetInnerHTML={ { __html: IconEdit } }
                            data-test={ ATTRIBUTES.sidebarSubLinkEdit }
                        />
                    )
                }
                { !subItem.getDynamic() && !sidebarState.getIsOpenDynamic() &&
                    <span
                        className={ favoritesClass }
                        onClick={ toggleFavorites(subItem) }
                        dangerouslySetInnerHTML={ { __html: subItem.getIsFavorite() ? IconStarFill : IconStar } }
                        data-test={ ATTRIBUTES.sidebarSubLinkFavorites }
                    />
                }
            </div>
        </div>
    );
}

export default observer(SubMenuItem);