import * as React from "react";
import moment from "moment";
import {
    getUserTimezone,
    getUserDateTimeFormat,
} from 'helpers/getUserTimeZone';
import type {FileType, CardData} from "types/components/exportManager";
import {FILE_STATUS} from "types/components/exportManager";

export const mapDataType = (type: FileType) => {
    switch (type) {
        case 'json':
            return 'JSON';
        case 'excel':
            return 'XLSX';
        default:
            return '';
    }
};

export const isFileLoaded = (state: FILE_STATUS): boolean => [FILE_STATUS.LOADED, FILE_STATUS.DOWNLOADED].includes(state);

export const getSysIds = (cards: CardData[]) => cards.map(card => card.sysId);

export const getFormatSize = (byteSize?: string) => {
    if (!byteSize) return '';
    let bytes = parseInt(byteSize);

    let fileSize: string;
    let kb = bytes / 1024;
    let mb = bytes / 1048576;
    let gb = bytes / 1073741824;
    let tb = bytes / 1099511627776;

    if (kb < 1024) {
        fileSize = `${kb.toFixed(2)} KB`;
    } else if (mb < 1024) {
        fileSize = `${mb.toFixed(2)} MB`;
    } else if (gb < 1024) {
        fileSize = `${gb.toFixed(2)} GB`;
    } else {
        fileSize = `${tb.toFixed(2)} TB`;
    }

    return (
        <>
            <span>&#x2022;</span> {fileSize}
        </>
    );
};

export const getFileDateTime = (date: string) => {
    return moment.tz(date, getUserTimezone()).format(getUserDateTimeFormat());
};