import * as React from 'react';
import { observer } from 'mobx-react';
import {action, makeObservable, observable} from 'mobx';
import {Link} from 'react-router-dom';
import styles from './styles.module.scss';
import Popup from 'components/popup/index';
import PreviewForm from './previewForm/index';
import langStore from 'globalState/lang/index';
import { fetchPreviewRecord } from 'actions/recordPopup';
import {MAX_LENGTH_NAME} from "./config";
import UsersIcon from 'assets/img/icons/user-many.svg';
import {ATTRIBUTES} from "constants/attributesForTests";
import {Delegate, RecordPopupParams, RecordPopupProps, Section} from 'types/components/recordPopup/recordPopup';

class RecordPopup extends React.Component<RecordPopupProps> {
    sections: Section[] = [];
    pageName = '';
    urlTableName = '';
    delegations: Delegate[] = [];
    delegationTitle = '';

    constructor(props) {
        super(props);
        makeObservable(this, {
            sections: observable,
            pageName: observable,
            urlTableName: observable,
            delegations: observable,
            delegationTitle: observable,
            getData: action,
        });
    }

    componentDidMount() {
        const { isShow } = this.props;
        if (isShow) {
            this.getData().catch(console.error);
        }
    }

    componentDidUpdate(prevProps) {
        const { isShow } = this.props;
        if (isShow && !prevProps.isShow) {
            this.getData().catch(console.error);
        }
    }

    getData = async () => {
        const { essence, sys_id, refColumnId } = this.props;
        const params: RecordPopupParams = {};
        if (refColumnId) {
            params.reference_column_id = refColumnId;
        }

        const { isOkStatus, data } = await fetchPreviewRecord(essence, sys_id, params);

        if (isOkStatus) {
            const response_url_table_name = (data.url_table_name !== '') ? data.url_table_name : '';
            this.urlTableName = response_url_table_name || essence;
            this.sections = data.sections;
            this.pageName = data.page_name;
            this.delegations = data.delegates_info?.delegates || [];
            this.delegationTitle = data.delegates_info?.title || '';
        }
    };

    getShortValue = (string: string) => {
        if (string.length > MAX_LENGTH_NAME) {
            return (
                <span title={string}>
                    {`${string.slice(0, MAX_LENGTH_NAME)}...`}
                </span>
            );
        }
        return string;
    };

    renderDelegation = () => {
        if (!this.delegations.length) {
            return null;
        }

        return (
            <div
                className={styles.DelegationContainer}
                data-test={ ATTRIBUTES.popupDelegationContainer }
            >
                <div
                    className={styles.DelegationTitleContainer}
                    data-test={ ATTRIBUTES.popupDelegationTitleContainer }
                >
                    <div
                        className={styles.DelegationIcon}
                        dangerouslySetInnerHTML={{__html: UsersIcon}}
                        data-test={ ATTRIBUTES.popupDelegationIcon }
                    />
                    <span className={styles.DelegationTitle}>{this.delegationTitle}</span>
                </div>
                <div
                    className={styles.DelegationUsers}
                    data-test={ ATTRIBUTES.popupDelegationUsers }
                >
                    {this.delegations.map(({display_name, record_table_name, record_id}) => (
                        <Link
                            key={record_id}
                            className={styles.DelegationUser}
                            target="_blank"
                            to={`/record/${ record_table_name }/${ record_id }`}
                            data-test={ ATTRIBUTES.popupDelegationUserLink }
                        >
                            {this.getShortValue(display_name)}
                        </Link>
                    ))}
                </div>
            </div>
        );
    }

    render() {
        const { open_record } = langStore.getTranslate();
        const { isServicePortal, isWindow, isShow, sys_id} = this.props;

        if (!isShow || this.sections.length === 0) {
            return null;
        }

        const prefix = isServicePortal ? '/sp' : '';

        const popupButton = isWindow ? null : {
            title: open_record ? open_record : '',
            link: `${prefix}/record/${ this.urlTableName }/${ sys_id }`,
        };

        return (
            <Popup { ...this.props } isShow={ isShow } title={ this.pageName } button={ popupButton } >
                {this.renderDelegation()}
                <PreviewForm fields={ this.sections[0].elements } />
            </Popup>
        );
    }
}

export default observer(RecordPopup);
