import { observable, makeObservable } from 'mobx';
import { IListChosenOptionsState } from 'types/components/list';
import { IListOption } from 'types/components/numberOfElements';
import { getPreference } from 'actions/preferences';
import listboxState from 'globalState/listboxState';

class ChosenOptionsState implements IListChosenOptionsState {
    private _listChosenOptions: IListOption[] = [];
    private _chosenOption = '';
    private _isNotFetch = false;
    private _hintText = '';

    constructor() {
        makeObservable(this, {
            _listChosenOptions: observable,
            _chosenOption: observable,
            _isNotFetch: observable,
            _hintText: observable,
        });
    }

    setListChosenOptions(listChosenOptions) {
        this._listChosenOptions = listChosenOptions;
    }

    getListChosenOptions() {
        return this._listChosenOptions;
    }

    setChosenOption(chosenOption) {
        this._chosenOption = chosenOption;
    }

    getChosenOption() {
        return this._chosenOption;
    }

    getHintText(): string {
        return this._hintText;
    }

    fetchChosenOption = async (type, name, chosenOption) => {
        const defaultPreference = 'list_items.per_page'; // шестеренка
        const tablePreference = `${ type }.${ name }.per_page`;  // блокнот: type => 'table' | 'related'
        const response = await getPreference([defaultPreference, tablePreference]);
        if (response.isOkStatus) {
            let perPage = '';
            if (response.data[tablePreference]) {
                perPage = response.data[tablePreference];
            } else if (response.data[defaultPreference]) {
                perPage = response.data[defaultPreference];
            }
            const intPerPage = parseInt(perPage, 10);
            if (!isNaN(intPerPage) && intPerPage > 1000) {
                perPage = '1000';
            }
            this.setChosenOption(perPage || chosenOption);
        }
    }

    fetchChosenListOptions = async (type, name) => {
        if (this._isNotFetch) {
            return;
        }
        let field = listboxState.getField('list_items.per_page'); // шестеренка
        let chosenOption;
        if (!field) {
            await listboxState.setData();
            field = listboxState.getField('list_items.per_page');
        }
        this.setListChosenOptions(field?.options || []);
        if (field?.database_value) {
            chosenOption = field.database_value;
        }
        this._hintText = field?.name || '';
        await this.fetchChosenOption(type, name, chosenOption);
    };

    getIsNotFetch(): boolean {
        return this._isNotFetch;
    }

    setIsNotFetch(value: boolean) {
        this._isNotFetch = value;
    }
}

export default ChosenOptionsState;
