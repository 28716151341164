import * as React from 'react';
import { observer } from 'mobx-react';

import styles from './styles.module.scss';

/**
 * Название: Переключатель
 * Параметры:
 * checked: {boolean} - состояние вкл./выкл
 * disabled: {boolean} - возможность редактировать
 * onChange: {function} - метод вызываемый при смене состояния
 * text: {string} - текст выводимый справа от переключателя
 */

export class Toggle extends React.Component {
    onChange = (e) => {
        e.preventDefault();
        const { onChange, disabled } = this.props;
        if (onChange && !disabled) {
            onChange(!this.props.checked);
        }
    };

    render() {
        const { checked, disabled, text, boldText } = this.props;
        let stateClass = [styles.Toggle];
        if (checked) {
            stateClass.push(styles.checked);
        }
        if (disabled) {
            stateClass.push(styles.disabled);
        }
        stateClass = stateClass.join(' ');

        return (
            <div className={ styles.ToggleWrap }>
                <button type='button' className={ stateClass } onClick={ this.onChange }>
                    <div className={ styles.ToggleIcons }>
                        <div className={ styles.ToggleIconOn } />
                        <div className={ styles.ToggleIconOff } />
                    </div>
                    <div className={ styles.ToggleButton } />
                </button>
                { text && (
                    <div className={ `${styles.ToggleText} ${boldText ? styles.BoldText : ''}` } onClick={ this.onChange }>
                        { text }
                    </div>
                ) }
            </div>
        );
    }
}

export default observer(Toggle);
