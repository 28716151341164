import * as React from 'react';
import { ATTRIBUTES } from 'constants/attributesForTests';

import styles from './styles.module.scss';

import GlobalPortal from 'components/globalPortal';

let MOUSE_X = null;
let MOUSE_Y = null;

document.addEventListener('mousemove', (e) => {
    MOUSE_X = e.pageX;
    MOUSE_Y = e.pageY - 30;
});

export default class quickMessage extends React.Component {
    constructor(props) {
        super(props);
    }


    getPosition = () => {
        if (this.props.style) {
            return this.props.style;
        }

        return {
            left: `${ MOUSE_X }px`,
            top: `${ MOUSE_Y }px`,
        };
    };


    render() {
        return (
            <GlobalPortal>
                <div { ...this.props } className={ styles.quickMessage } style={ this.getPosition() } data-test={ ATTRIBUTES.quickMessage }>
                    { this.props.children }
                </div>
            </GlobalPortal>
        );
    }
}
