import _ from "lodash";
import {formatNumberValue, getFieldValue} from 'helpers/report/report';
import { prepareCutValue } from 'helpers/report/reportComponent';

const colorChartPalette = ['#FDAC41', '#54E8F0', '#5B8DEE', '#9B57DF', '#F56F6F',
    '#DEA5E8', '#00CFDD', '#FDE878', '#52ECAB', '#C5F75C'];

/**
 * Функция, которая изначально вызывается для всех стилей, которая извлекает стили из рекорта, распределяет их по типам.
 * @param styles - массив всех стилей
 * @param type - тип репорта
 * @param chartData - данные отчета
 * @param isMasterReport - флаг, определяющий МО это?
 * @param sliceType - тип слайса (для bar)
 * @param currentReportState - текущий стейт репорта
 * @returns {*|{plotOptions: {series: {dataLabels: {enabled: boolean}}}, legend: {layout: string, verticalAlign: *, borderWidth: *, width: number, align: *, enabled: boolean}, drilldown_list_layout_id: *, title: {verticalAlign: *, useHTML: boolean, showType: string, style: {color: string, fontSize: string, fontWeight: *}, text: (*|null), align: string}, decimalPrecision: *, colors: *[]}}
 */
export function exportStyles(styles, type, chartData, currentReportState, isMasterReport = false, sliceType = null) {
    let data;
    const colorsData = currentReportState.additionalData;
    if (!isMasterReport) {
        chartData.formData = conversionToCommonData(chartData.formData);
        data = conversionToCommonData(styles);
    } else {
        data = styles;
    }

    // общие свойства
    let options = getMainOptions(data, colorsData);
    // сортируем данные по различным options в highchart
    // индивидуальные
    const individualOptions = sortDataTypeChart(type, data, colorsData);
    if (type === 'bar' || type === 'trends') {
        if (data.data_labels_in_the_middle || type === 'trends' || (sliceType === 'Stacked' && data.slice_by)) {
            options.plotOptions = {
                column: {
                    borderRadiusTopLeft: 4,
                    borderRadiusTopRight: 4,
                    align: 'middle',
                    stacking: 'normal',
                    dataLabels: {
                        enabled: !!data.display_data_labels,
                        color: 'white',
                        style: {
                            textOutline: false,
                            fontWeight: 'normal',
                        },
                        crop: true,
                        overflow: 'justify',
                    },
                    showInLegend: false,
                },
            };
        } else {
            options.plotOptions = {
                column: {
                    borderRadiusTopLeft: 4,
                    borderRadiusTopRight: 4,
                    align: null,
                    stacking: null,
                    dataLabels: {
                        crop: false,
                        overflow: 'none',
                        enabled: !!data.display_data_labels,
                        style: {
                            textOutline: false,
                            fontWeight: 'normal',
                        },
                        color: '#6e728f',
                    },
                    showInLegend: false,
                },
            };
        }

        const {group_by} = chartData.formData;
         if (sliceType === 'Stacked' || (type === 'trends' && group_by)) {
             options.plotOptions.column.stacking = 'normal';
         }
    } else if (type === 'line') {
        options.title.style.align = data.title_horizontal_alignment ? data.title_horizontal_alignment.toLowerCase() : 'center';
    } else if (type === 'gauge') {
        let gaugeData = data;
        const value = chartData.chart.report_data[0].value;
        const {min_value, max_value} = chartData.formData;
        if (!Object.keys(data).length) {
            gaugeData = chartData.formData;
        }
        let min = !min_value ? 0 : +min_value;
        // требование бизнеса, если max не указан явно, считаем что текущее значение составляет 70% от максимального
        let max = !max_value ? _.round(value / .7, 2) : +max_value;
        const stops = getGaugeStops(gaugeData, colorsData);

        const color = stops.reduce((result, [currentValue, color]) => {
            return currentValue <= value ? color : result;
        }, stops[0][1]);
        options.chart = {
            type: 'solidgauge',
        };
        const tickInterval = (max - min) / 4;
        let tickPositions = [min];
        for (let i = 1; i < 4; i++) {
            tickPositions.push(tickPositions[i-1] + tickInterval);
        }
        tickPositions.push(max);

        // когда одинаковые max и min шкала, либо не отрисовывается, либо HighCharts неверно расчитывает цвет, хак делает их разными и отрисовывает по логике DEF0007301
        min = min === max ? min - 0.0001 : min;

        options.yAxis = {
            min,
            max,
            minColor: color,
            maxColor: color,
            startOnTick: false,
            lineWidth: 0,
            tickPositions: tickPositions,
            minorTickInterval: null,
            title: {
                text: prepareCutValue(formatNumberValue(value, data.decimal_precision)),
                style: {
                    color,
                },
            },
            labels: {
                distance: 24,
                formatter: function () {
                    return this.value;
                },
                style: {
                    color: '#2e3238',
                    fontSize: '14px',
                    fontFamily: 'Open Sans',
                },
            },
        };
    }

    options = _.merge(options, individualOptions);

    return options;
}

/**
 * Получение отрезков gauge (минимальный, средний, высокий)
 * @param data
 * @param colorsData
 * @returns {((number|string)[]|(number|string)[]|(number|string)[])[]}
 */
function getGaugeStops(data, colorsData = null) {
    const {lower_limit, upper_limit} = data;
    const lowerLimit = lower_limit || 0;
    const upperLimit = upper_limit || 0;
    let minColor = '#41923a', middleColor = '#e4c004', highColor = '#d00000';
    if (colorsData && colorsData.color_data && !_.isEqual(colorsData.color_data, [])) {
        if (data.chart_color === 'Use One Color') {
            if (data.color && colorsData.color_data[data.color]) {
                minColor = middleColor = highColor = colorsData.color_data[data.color];
            }
        } else {
            if (data.high_score_color &&  colorsData.color_data[data.high_score_color]) {
                highColor = colorsData.color_data[data.high_score_color];
            }
            if (data.middle_score_color && colorsData.color_data[data.middle_score_color]) {
                middleColor = colorsData.color_data[data.middle_score_color];
            }
            if (data.low_score_color && colorsData.color_data[data.low_score_color]) {
                minColor = colorsData.color_data[data.low_score_color];
            }
        }
    }
    return [
        [0.000, minColor],

        [lowerLimit - 0.0001, minColor],
        [lowerLimit, middleColor],

        [upperLimit - 0.0001, middleColor],
        [upperLimit, highColor],

        // [1.000, highColor],
    ];
}

/**
 * Сортировка по типу для получение стилей, связанных с этим типом
 * @param type
 * @param data
 * @param colorsData
 * @returns {{plotOptions: {series: {dataLabels: {formatter: (function(): *), enabled: boolean}}}, yAxis: {gridLineWidth: boolean, opposite: boolean, title: {useHTML: boolean, style: {fontSize: string, fontWeight: string}, text: (*|null)}, gridLineDashStyle: string, labels: {style: {fontSize: (string|null)}}}, xAxis: {gridLineWidth: boolean, opposite: boolean, title: {useHTML: boolean, style: {fontSize: string, fontWeight: string}, text: (*|null)}, gridLineDashStyle: string, labels: {style: {fontSize: (string|null)}}}}|{series: {dataLabels: {formatter: (function(): string)}}[], colorAxis: {minColor: string, maxColor: string}}|{}|{color: string, decimalPrecision: *}|{yAxis: {gridLineWidth: boolean, min: number, max: number, opposite: boolean, title: {useHTML: boolean, style: {fontSize: string, fontWeight: string}, text: (*|null)}, gridLineDashStyle: string, labels: {style: {fontSize: (string|null)}}}, xAxis: {gridLineWidth: boolean, opposite: boolean, title: {margin: (number|*), useHTML: boolean, style: {fontSize: string, fontWeight: string}, text: *}, gridLineDashStyle: string, labels: {formatter: (function(): string), style: {fontSize: string}}}}|{chart: {width: *}}|{plotOptions: {pie: {dataLabels: {formatter: (function(): string), enabled: boolean}, borderWidth: number}}, legend: {itemMarginTop: number}}}
 */
export function sortDataTypeChart(type, data, colorsData = null) {
    switch (type) {
        case 'bar':
        case 'trends':
            return getBarChartOptions(data);
        case 'pie':
            return getPieChartOptions(data);
        case 'line':
            return getLineChartOptions(data);
        case 'digits':
            return getDigitOptions(data, colorsData);
        case 'heatmap':
            return getHeatmapOptions(data, colorsData);
    }
    return {};
}

/**
 * Получение выравнивания, т.к. оно в формате Одной строки.  Center top => ['Center', 'top']
 * @param data
 * @returns {{horizontalAlignment: (*|string), verticalAlignment: (*|string)}}
 */
function getAlignment(data) {
    let horizontalAlignment, verticalAlignment;
    if (data.legend_alignment) {
        const arrAlignment = data.legend_alignment.split(' ');
        horizontalAlignment = arrAlignment[0] && arrAlignment[0].toLowerCase();
        verticalAlignment = arrAlignment[1] && arrAlignment[1].toLowerCase();
    }
    return { horizontalAlignment, verticalAlignment };
}

/**
 * Общая сортировка стилей
 * @param data - все данные о чарте
 * @param additionalData - массив с цветами, source_table, palette_data
 * @returns {{plotOptions: {series: {dataLabels: {enabled: boolean}}}, legend: {layout: string, verticalAlign: *, borderWidth: *, width: number, align: *, enabled: boolean}, drilldown_list_layout_id: *, title: {verticalAlign: *, useHTML: boolean, showType: string, style: {color: string, fontSize: string, fontWeight: *}, text: (*|null), align: string}, decimalPrecision: *, colors: *[]}}
 */
export function getMainOptions(data, additionalData = null) {
    let align = 'left', verticalAlign = undefined;
    if (data.title_alignment) {
        const titleAlignment = data.title_alignment.split(" ");
        align = titleAlignment ? titleAlignment[0].toLowerCase() : 'center';
        verticalAlign = titleAlignment && titleAlignment[1] ? titleAlignment[1].toLowerCase() : 'top';
    }
    let colors, colorTitle = "#17181d";
    if (data.report_title_color && typeof additionalData === 'object' && additionalData.color_data && additionalData.color_data[data.report_title_color]) {
        colorTitle = additionalData.color_data[data.report_title_color];
    }
    if (additionalData && typeof additionalData === 'object' && data.chart_color_type && data.chart_color_type.toLowerCase() !== "default") {
        if (data.chart_color_type.toLowerCase() === "use one color" && additionalData.color_data && additionalData.color_data[data.color_id]) {
            colors = [additionalData.color_data[data.color_id]];
        } else if (additionalData.palette_data && additionalData.palette_data[data.palette_id]) {
            colors = additionalData.palette_data[data.palette_id];
        } else if (data.chart_color_type.toLowerCase() === "use one color") {
            colors = ['#FDAC41'];
        } else {
            colors = colorChartPalette;
        }
    } else {
        if(additionalData && typeof additionalData === 'object' && additionalData.palette_data){
            colors = additionalData.palette_data[Object.keys(additionalData.palette_data)[0]];
        }
        else {
            colors = colorChartPalette;
        }
    }
    const { horizontalAlignment, verticalAlignment } = getAlignment(data);
    return {
        chart: {
            animation: false,
        },
        colors: colors,
        title: {
            showType: data.report_title_show_type ? data.report_title_show_type.toLowerCase(): 'never',
            text: data.report_title ? data.report_title : null,
            useHTML: true,
            style: {
                fontSize: data.report_title_size ? data.report_title_size : 15,
                fontWeight: data.title_bold ? 'bold' : null,
                color: colorTitle,
            },
            align: align,
            verticalAlign: verticalAlign,
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: !!data.display_data_labels,
                },
            },
        },
        decimalPrecision: data.decimal_precision,
        drilldown_list_layout_id: data.drilldown_list_layout_id,
        legend : {
            enabled: !!data.show_legend,
            borderWidth: data.show_legend_border,
            itemStyle: {
                fontFamily: '\'OpenSans\', sans-serif',
            },
            // width: 146,
            padding: 15,
            align: horizontalAlignment ? horizontalAlignment : 'center',
            verticalAlign: verticalAlignment ? verticalAlignment : 'bottom',
            layout: horizontalAlignment && horizontalAlignment !== 'center' ? 'vertical' : 'horizontal',
        },
    };
}

/**
 * Сортировка для heatmap
 * @param data
 * @param colorsData - массив с цветами (название: hex)
 * @returns {{series: [{dataLabels: {formatter: function(): string}}], colorAxis: {minColor: string, maxColor: string}}}
 */
function getHeatmapOptions(data, colorsData = null) {
    let maxColor = "#00d1ff", minColor = "#ffffff";
    if (colorsData && colorsData.color_data && !_.isEqual(colorsData.color_data, [])) {
        if (data.high_score_color &&  colorsData.color_data[data.high_score_color]) {
            maxColor = colorsData.color_data[data.high_score_color];
        }
        if (data.low_score_color && colorsData.color_data[data.low_score_color]) {
            minColor = colorsData.color_data[data.low_score_color];
        }
    }
    return {
        colorAxis: {
            minColor: minColor,
            maxColor: maxColor,
        },
    };
}

/**
 * Установка шрифта (не больше 50px)
 * @param textSize - размер шрифта
 * @returns {string}
 */
export function setTextSize(textSize) {
    if (textSize) {
        if (textSize > 50) {
            return '50px';
        } else {
            return textSize + 'px';
        }
    }
    return '12px';
}

/**
 * Сортировка стилей для bar chart
 * @param data
 * @returns {{yAxis: {gridLineWidth: boolean, min: (number), max: (number), opposite: boolean, title: {useHTML: boolean, style: {fontSize: string, fontWeight: string}, text: (*|null)}, gridLineDashStyle: string, labels: {style: {fontSize: (string|null)}}}, xAxis: {gridLineWidth: boolean, opposite: boolean, title: {margin: (number|*), useHTML: boolean, style: {fontSize: string, fontWeight: string}, text: *}, gridLineDashStyle: string, labels: {formatter: (function(): string), style: {fontSize: string}}}}}
 */
function getBarChartOptions(data) {
    return {
        yAxis: {
            gridLineWidth: !!data.y_display_grid,
            gridLineDashStyle: data.y_grid_dotted ? 'dot' : 'solid',
            gridLineColor: '#D5D8DD',
            min: data.y_from && data.y_from.length ? Number(data.y_from) : undefined,
            max: data.y_to && data.y_to.length ? Number(data.y_to) : undefined,
            title: {
                // enabled: !!(data.y_title && data.y_title.length),
                text: data.y_title && data.y_title.length ? data.y_title : null,
                style: {
                    fontSize: setTextSize(data.y_title_size),
                    fontWeight: data.y_title_bold ? 'bold' : 'normal',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                },
                useHTML: true,
            },
            labels: {
                style: {
                    fontSize: data.y_label_size && data.y_label_size.length ? setTextSize(data.y_label_size) : null,
                    overflow: 'hidden',
                    maxWidth: '400px',
                },
            },
            endOnTick: false,
            opposite: !!data.y_display_opposite,
        },
        xAxis: {
            gridLineWidth: !!data.x_display_grid,
            gridLineDashStyle: data.x_grid_dotted ? 'dot' : 'solid',
            gridLineColor: '#D5D8DD',
            title: {
                // enabled: !!(data.x_title && data.x_title.length),
                text: data.x_title && data.x_title.length ? data.x_title : "",
                margin: data.x_display_opposite ? 20 : undefined,
                style: {
                    fontSize: setTextSize(data.x_title_size),
                    fontWeight: data.x_title_bold ? 'bold' : 'normal',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                },
                useHTML: true,
            },
            labels: {
                formatter: function() {
                    let label = '(not set)';
                    if (typeof this.value === 'number') {
                        this.value = this.value.toString();
                    }
                    if (this.value && typeof this.value === 'string' || this.value === "") {
                        label = this.value && this.value.length < 500 || this.value === "" ? this.value : this.value.slice(0, 500) + '...';
                    }
                    return label;
                },
                style: {
                    fontSize: data.x_label_size ? setTextSize(data.x_label_size): '12px',
                    overflow: 'hidden',
                    maxWidth: '400px',
                },
            },
            opposite: !!data.x_display_opposite,
        },
    };
}

/**
 * Сортировка стилей для digits
 * @param data
 * @param colorsData
 * @returns {{color: string, decimalPrecision: *}}
 */
function getDigitOptions(data, colorsData = null) {
    let color = "#e31450";
    if (colorsData && colorsData.color_data && data.color_id && colorsData.color_data[data.color_id]) {
        color = colorsData.color_data[data.color_id];
    }
    return {
        decimalPrecision: data.decimal_precision,
        color: color,
    };
}

/**
 * Сортировка стилей для line chart
 * @param data
 * @returns {{plotOptions: {series: {dataLabels: {formatter: (function(): *), enabled: boolean}}}, yAxis: {gridLineWidth: boolean, opposite: boolean, title: {useHTML: boolean, style: {fontSize: string, fontWeight: string}, text: (*|null)}, gridLineDashStyle: string, labels: {style: {fontSize: (string|null)}}}, xAxis: {gridLineWidth: boolean, opposite: boolean, title: {useHTML: boolean, style: {fontSize: string, fontWeight: string}, text: (*|null)}, gridLineDashStyle: string, labels: {style: {fontSize: (string|null)}}}}}
 */
function getLineChartOptions(data) {
    return {
        xAxis: {
            gridLineWidth: !!data.x_display_grid,
            gridLineDashStyle: data.x_grid_dotted ? 'dot' : 'solid',
            gridLineColor: '#D5D8DD',
            title: {
                text: data.x_title && data.x_title.length ? data.x_title : null,
                style: {
                    fontSize: setTextSize(data.x_title_size),
                    fontWeight: data.x_title_bold ? 'bold' : 'normal',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                },
                useHTML: true,
            },
            labels: {
                style: {
                    fontSize: data.x_label_size ? setTextSize(data.x_label_size) : null,
                    overflow: 'hidden',
                    maxWidth: '400px',
                },
            },
            opposite: !!data.x_display_opposite,
        },
        yAxis: {
            gridLineWidth: !!data.y_display_grid,
            gridLineDashStyle: data.y_grid_dotted ? 'dot' : 'solid',
            gridLineColor: '#D5D8DD',
            min: data.y_from && data.y_from.length ? data.y_from : null,
            max: data.y_to && data.y_to.length ? data.y_to : null,
            title: {
                text: data.y_title && data.y_title.length ? data.y_title : null,
                style: {
                    fontSize: setTextSize(data.y_title_size),
                    fontWeight: data.y_title_bold ? 'bold' : 'normal',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                },
                useHTML: true,
            },
            labels: {
                style: {
                    fontSize: data.y_label_size && data.y_label_size.length ? setTextSize(data.y_label_size) : null,
                    overflow: 'hidden',
                    maxWidth: '400px',
                },
            },
            endOnTick: false,
            opposite: !!data.y_display_opposite,
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: !!data.display_data_labels,
                },
            },
        },
    };
}

/**
 * Сортировка стилей для pie chart
 * @param data
 * @returns {{plotOptions: {pie: {dataLabels: {enabled: boolean}}}, legend: {itemMarginTop: number}}}
 */
function getPieChartOptions(data) {
    const { horizontalAlignment } = getAlignment(data);
    return {
        legend: {
            itemMarginTop: horizontalAlignment && horizontalAlignment !== 'center' ? 10 : 14,
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: !!data.display_data_labels,
                },
            },
        },
    };
}

//  функция извлекающие все стили из рекорда и объеденяющая их в отдельный объект
export function extractStylesFromRecord(record) {
    if (!record) return [];
    const styles = [];
    record.forEach((section) => {
        /**
         * @example
         * Style_Axis_X >>> ["style_axis_x", "axis_x", index: 0, input: "style_axis_x", groups: undefined]
         */
        const match = section.service_name.toLocaleLowerCase().match(/style_(\w*)/);
        if (match && match[1]) {
            styles.push(section);
        }
    });
    return styles;
}

/**
 * Извлечение из секций всех элементов в тип название поля:значение поля
 * @param record - рекорд, из которого извлекаем
 * @returns {[]}
 */
export function extractFormDataFromRecord(record) {
    if (!record) return [];
    const data = [];
    record.forEach((section) => {
        section.elements.forEach(element => data[element.sys_column_name] = getFieldValue(element, true) );
    });
    return data;
}

// функция фильтрующая данные из формы к одному объекту по типу ключ: значение
export function conversionToCommonData(data) {
      const objData = {};
       _.forEach(data, (form) => {
            form.elements.forEach((field) => {
                if (field.column_type === 'choice' && field.value) {
                    if (typeof field.value.database_value === 'string') {
                        objData[field.sys_column_name] = field.value.database_value.toLowerCase();
                    } else {
                        objData[field.sys_column_name] = field.value.database_value;
                    }
                }
                else if (field.column_type === 'reference' && field.sys_column_name === 'drilldown_list_layout_id' && field.value) {
                    objData[field.sys_column_name] = field.value.display_value;
                }
                else if (field.column_type === 'reference' && field.value) {
                    objData[field.sys_column_name] = field.value.database_value;
                }
                else {
                    objData[field.sys_column_name] = field.value;
                }
            });
            return objData;
      });
       return objData;
}

/**
 * Получение типа по его названию
 * @param name - название типа
 * @param types - массив всех типов
 * @returns {*}
 */
export function getTypeByName(name, types) {
    if (!name) return undefined;
    return types.sys_report_type.find((type) => type.type_service_name.toLowerCase() === name.toLowerCase());
}

/**
 * Получение типа по его айди
 * @param id - айди типа
 * @param types - все возможные типы репортов
 * @returns {*}
 */
export function getTypeById(id, types) {
    return types.sys_report_type.find((type) => type.type_id === id);
}

/**
 * Получение типа отчета по названию или айди типа
 * @param name - название типа
 * @param id - айди типа
 * @param types - все возможные типы репортов
 * @returns {null|*}
 */
export function getReportType({name, id}, types) {
    if (name) {
        return getTypeByName(name, types);
    } else if (id) {
        return getTypeById(id, types);
    } else {
        return null;
    }
}
