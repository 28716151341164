import * as React from 'react';
import { observer } from 'mobx-react';
import InfoMessagesState from 'globalState/infoMessages';
import Message from './message';
import styles from './styles.module.scss';
import Button from 'components/button';
import langStore from 'globalState/lang';
import { ATTRIBUTES } from 'constants/attributesForTests';


class InfoMessages extends React.Component {
    constructor(props) {
        super(props);
    }

    renderMessage = (msg) => {
        return (
            <Message
                key={ msg.id }
                type={ msg.type }
                message={ msg.message }
                id={ msg.id }
                duration={ msg.duration }
                data-test={ ATTRIBUTES.infoMessageItem }
            />
        );
    };

    render() {
        const { user_menu_titles } = langStore.getTranslate();

        const maxLength = 3;
        const result = [];
        const { messages } = InfoMessagesState;

        if (messages.length > maxLength) {
            InfoMessagesState.removeFirst();
        }

        for (const msg of messages) {
            result.push(this.renderMessage(msg));
        }

        return (
            <div
                className={ styles.container }
                data-test={ ATTRIBUTES.infoMessages }
            >
                { messages.length >= maxLength &&
                <Button
                    onClick={ () => {
                        InfoMessagesState.removeAll();
                    } }
                    className={ styles.hideAll }>
                    { user_menu_titles && user_menu_titles.hide_all }
                </Button> }
                { result }
            </div>
        );
    }
}

export default observer(InfoMessages);
