import { fetchRemAttr } from 'actions/record';
import { runOnTypeScripts, TYPE_ON_LOAD } from 'helpers/scriptClientHelper';
import { observable, makeObservable } from 'mobx';
import SimpleForm from 'ui-actions-scripts/simple-form';
import widgetsDataState from 'globalState/widgets';
import { generateRandomString } from 'helpers/data';

export default class REMFormWidgetState {
    remData;
    tableName;
    recordId;
    modelId;
    titleHide = false;
    isUserScripts = true;
    hidden = false;
    timeout = null;
    readOnly;
    parentFormModel;
    dynamicFormId = '';
    isPortal = true;

    constructor(data) {
        makeObservable(this, {
            remData: observable,
            modelId: observable,
            hidden: observable,
        });

        this.updateData(data).catch(console.error);
    }

    switchHiddenState(state) {
        if (state !== undefined) {
            this.hidden = state;
        }
        else {
            this.hidden = !this.hidden;
        }
    }

    async updateData({
                         tableName,
                         recordId,
                         modelId,
                         isUserScripts = true,
                         titleHide,
                         readOnly,
                         widgetId,
                         formName,
                     }) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(async () => {
            window.isNewRecord = !!recordId;
            this.tableName = tableName;
            this.recordId = recordId || null;
            this.modelId = modelId || null;
            this.isUserScripts = isUserScripts;
            this.titleHide = titleHide;
            this.readOnly = readOnly;
            let simpleForm;
            if (widgetId) {
                this.dynamicFormId = generateRandomString();
                simpleForm = new SimpleForm(this.tableName, this.recordId, null, formName || widgetId, this.dynamicFormId);
                widgetsDataState.addForm(this.dynamicFormId, simpleForm);
            }
            if (!window.s_form && simpleForm) {
                window.s_form = simpleForm;
            }
            if (this.tableName && (this.recordId || this.modelId)) {
                const data = await this.fetch(this.tableName, this.recordId, this.modelId);
                if (data && !_.isEmpty(data)) { // Backend возвращает [] в случае отсутствия данных
                    if (readOnly) {
                        data.sections[0].elements.forEach(elem => { elem.readonly = true });
                    }
                    this.remData = data;
                    this.modelId = data.rem_id; // при получении существующей записи устанавливаем modelId
                }
            }
            if (this.isUserScripts) {
                this.runOnLoadScript();
            }
        }, 100);
    }

    async fetch(tableName, recordId, modelId) {
        const record = await fetchRemAttr(tableName, recordId, modelId, this.isPortal);
        return record.data;
    }

    runOnLoadScript() {
        if (!this.remData) {
            return null;
        }
        const clientScripts = this.remData ? this.remData.client_scripts : [];
        if (clientScripts.length) {
            runOnTypeScripts(clientScripts, TYPE_ON_LOAD, null, null, this.dynamicFormId);
        }
    }

    getTitle() {
        if (!this.remData) {
            return null;
        }
        return this.remData.rem_title;
    }
}
