import type {
    EditorConfig,
    GridSelection,
    LexicalNode,
    NodeKey,
    NodeSelection,
    RangeSelection,
    SerializedLexicalNode,
    Spread,
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';
import { mergeRegister } from '@lexical/utils';
import {
    $getNodeByKey,
    $getSelection,
    $isNodeSelection,
    CLICK_COMMAND,
    COMMAND_PRIORITY_LOW,
    DecoratorNode,
    KEY_BACKSPACE_COMMAND,
    KEY_DELETE_COMMAND,
} from 'lexical';
import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react';

import ImageResizer from '../plugins/ImagePlugin/ImageResizer';
import styles from '../plugins/ImagePlugin/styles.module.scss';

export interface ImagePayload {
    altText: string;
    height?: number;
    key?: NodeKey;
    maxWidth?: number;
    src: string;
    width?: number;
}

const imageCache = new Set();

function useSuspenseImage(src: string) {
    if (!imageCache.has(src)) {
        throw new Promise(resolve => {
            const img = new Image();
            img.src = src;
            img.onload = () => {
                imageCache.add(src);
                resolve(null);
            };
        });
    }
}

function LazyImage({
                       altText,
                       className,
                       height,
                       imageRef,
                       maxWidth,
                       src,
                       width,
                   }: {
    altText: string;
    className: string | null;
    height: 'inherit' | number;
    imageRef: { current: null | HTMLImageElement };
    maxWidth: number;
    src: string;
    width: 'inherit' | number;
}): JSX.Element {
    useSuspenseImage(src);

    return (
        <img
            ref={imageRef}
            alt={altText}
            className={className || undefined}
            draggable="false"
            src={src}
            style={{
                height,
                maxWidth,
                width,
            }}
        />
    );
}

function ImageComponent({
                            altText,
                            height,
                            maxWidth,
                            nodeKey,
                            resizable,
                            src,
                            width,
                        }: {
    altText: string;
    height: 'inherit' | number;
    maxWidth: number;
    nodeKey: NodeKey;
    resizable: boolean;
    src: string;
    width: 'inherit' | number;
}): JSX.Element {
    const ref = useRef(null);
    const [isSelected, setSelected, clearSelection] = useLexicalNodeSelection(nodeKey);
    const [isResizing, setIsResizing] = useState<boolean>(false);
    const [editor] = useLexicalComposerContext();
    const [selection, setSelection] = useState<
        RangeSelection | NodeSelection | GridSelection | null
    >(null);

    const onDelete = useCallback(
        (event: KeyboardEvent) => {
            if (isSelected && $isNodeSelection($getSelection())) {
                event.preventDefault();
                const node = $getNodeByKey(nodeKey);
                if ($isImageNode(node)) {
                    node.remove();
                }
                setSelected(false);
            }

            return false;
        },
        [isSelected, nodeKey, setSelected]
    );

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                setSelection(editorState.read(() => $getSelection()));
            }),
            editor.registerCommand<MouseEvent>(
                CLICK_COMMAND,
                payload => {
                    const event = payload;

                    if (isResizing) {
                        return true;
                    }
                    if (event.target === ref.current) {
                        if (!event.shiftKey) {
                            clearSelection();
                        }
                        setSelected(!isSelected);

                        return true;
                    }

                    return false;
                },
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(KEY_DELETE_COMMAND, onDelete, COMMAND_PRIORITY_LOW),
            editor.registerCommand(KEY_BACKSPACE_COMMAND, onDelete, COMMAND_PRIORITY_LOW)
        );
    }, [clearSelection, editor, isResizing, isSelected, nodeKey, onDelete, setSelected]);

    const onResizeEnd = (nextWidth: 'inherit' | number, nextHeight: 'inherit' | number) => {
        // Delay hiding the resize bars for click case
        setTimeout(() => {
            setIsResizing(false);
        }, 200);

        editor.update(() => {
            const node = $getNodeByKey(nodeKey);
            if ($isImageNode(node)) {
                node.setWidthAndHeight(nextWidth, nextHeight);
            }
        });
    };

    const onResizeStart = () => {
        setIsResizing(true);
    };

    const draggable = isSelected && $isNodeSelection(selection);
    const isFocused = $isNodeSelection(selection) && (isSelected || isResizing);

    return (
        <Suspense fallback={null}>
            <>
                <div draggable={draggable}>
                    <LazyImage
                        altText={altText}
                        className={isFocused ? styles.FocusedImage : null}
                        height={height}
                        imageRef={ref}
                        maxWidth={maxWidth}
                        src={src}
                        width={width}
                    />
                </div>
                {resizable && isFocused && (
                    <ImageResizer
                        editor={editor}
                        imageRef={ref}
                        maxWidth={maxWidth}
                        onResizeEnd={onResizeEnd}
                        onResizeStart={onResizeStart}
                    />
                )}
            </>
        </Suspense>
    );
}

export type SerializedImageNode = Spread<
    {
        altText: string;
        height?: number;
        maxWidth: number;
        src: string;
        type: 'image';
        version: 1;
        width?: number;
    },
    SerializedLexicalNode
>;

export class ImageNode extends DecoratorNode<JSX.Element> {
    __src: string;

    __altText: string;

    __width: 'inherit' | number;

    __height: 'inherit' | number;

    __maxWidth: number;

    constructor(
        src: string,
        altText: string,
        maxWidth: number,
        width?: 'inherit' | number,
        height?: 'inherit' | number,
        key?: NodeKey
    ) {
        super(key);
        this.__src = src;
        this.__altText = altText;
        this.__maxWidth = maxWidth;
        this.__width = width || 'inherit';
        this.__height = height || 'inherit';
    }

    static getType(): string {
        return 'image';
    }

    static clone(node: ImageNode): ImageNode {
        return new ImageNode(
            node.__src,
            node.__altText,
            node.__maxWidth,
            node.__width,
            node.__height,
            node.__key
        );
    }

    static importJSON(serializedNode: SerializedImageNode): ImageNode {
        const { altText, height, maxWidth, src, width } = serializedNode;

        return $createImageNode({
            altText,
            height,
            maxWidth,
            src,
            width,
        });
    }

    exportJSON(): SerializedImageNode {
        return {
            altText: this.getAltText(),
            height: this.__height === 'inherit' ? 0 : this.__height,
            maxWidth: this.__maxWidth,
            src: this.getSrc(),
            type: 'image',
            version: 1,
            width: this.__width === 'inherit' ? 0 : this.__width,
        };
    }

    setWidthAndHeight(width: 'inherit' | number, height: 'inherit' | number): void {
        const writable = this.getWritable();
        writable.__width = width;
        writable.__height = height;
    }

    // View

    createDOM(config: EditorConfig): HTMLElement {
        const span = document.createElement('span');
        const { theme } = config;
        const className = theme.image;
        if (className !== undefined) {
            span.className = className;
        }

        return span;
    }

    updateDOM(): false {
        return false;
    }

    getSrc(): string {
        return this.__src;
    }

    getAltText(): string {
        return this.__altText;
    }

    decorate(): JSX.Element {
        return (
            <ImageComponent
                altText={this.__altText}
                height={this.__height}
                maxWidth={this.__maxWidth}
                nodeKey={this.getKey()}
                resizable
                src={this.__src}
                width={this.__width}
            />
        );
    }
}

export function $createImageNode({
                                                   altText,
                                                   height,
                                                   key,
                                                   maxWidth = 440,
                                                   src,
                                                   width,
                                               }: ImagePayload): ImageNode {
    return new ImageNode(src, altText, maxWidth, width, height, key);
}

export function $isImageNode(node: LexicalNode | null | undefined): node is ImageNode {
    return node instanceof ImageNode;
}
