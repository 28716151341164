import React from 'react';
import { action, observable, makeObservable, runInAction } from 'mobx';
import { evalScript, replaceWidgetCustom, replaceWidgetProp } from 'helpers/widget';
import parse, { domToReact } from 'html-react-parser';
import * as _ from 'lodash';
import { WidgetDataElementType, WidgetDataType, WidgetElementType } from 'types/components/widgets/index';
import ElementWidget from 'globalState/widgets/widgetData/element/index';

export class WidgetData {
    private _data: WidgetDataType = {};
    private _id = '';
    private _term = {};
    private _elements: WidgetElementType[] = [];
    private _options: WidgetDataType = {};
    private _clientScript = '';
    private _tableNameForm = '';
    private _recordIdForm = '';
    private _countForm = 0;

    constructor(id = '', clientScript = '') {
        makeObservable(this, {
            _data: observable,
            _id: observable,
            _term: observable,
            _elements: observable,
            _options: observable,
            _clientScript: observable,
            _tableNameForm: observable,
            _recordIdForm: observable,
            _countForm: observable,
            setOptions: action,
            setFieldValue: action,
            setFieldValues: action,
            setOptionValue: action,
            setTableNameForm: action,
            setRecordIdForm: action,
            setClientScript: action,
            removeElementByIndex: action,
            add: action,
            remove: action,
            change: action,
            addTemplate: action,
            removeTemplate: action,
            parse: action,
        });

        runInAction(() => {
            this._id = id;
            this._clientScript = clientScript;
        });
    }

    getId = (): string => {
        return this._id;
    };

    getData = (): WidgetDataType => {
        return this._data;
    };

    getOptions = (): WidgetDataType => {
        return this._options;
    };

    setOptions = (options: WidgetDataType) => {
        this._options = options;
    };

    getFieldValue = (fieldKey: string): any => {
        return this._data[fieldKey];
    };

    setFieldValue = (fieldKey: string, value: any) => {
        this._data[fieldKey] = value;
        this.updateElements();
    };

    setFieldValues = (source: {[key: string]: any}) => {
        Object.assign(this._data, source);
        this.updateElements();
    };


    getOptionValue = (fieldKey: string): any => {
        return this._options[fieldKey];
    };

    setOptionValue = (fieldKey: string, value: any) => {
        return this._options[fieldKey] = value;
    };

    getClientScript(): string {
        return this._clientScript;
    }

    getTableNameForm(): string {
        return this._tableNameForm;
    }

    setTableNameForm(value: string) {
        this._tableNameForm = value;
    }

    getRecordIdForm(): string {
        return this._recordIdForm;
    }

    setRecordIdForm(value: string) {
        this._recordIdForm = value;
    }

    setClientScript(value: string) {
        this._clientScript = value;
    }

    hasOneForm(): boolean {
        return this._countForm === 1;
    }

    hasNotForm(): boolean {
        return this._countForm === 0;
    }

    removeElementByIndex = (index) => {
        const elements: WidgetElementType[] = this._elements;
        return elements.filter((el, i) => i !== index);
    };

    getTerm = (fieldKey: string): string => {
        return this._term[fieldKey];
    };

    add = (fieldKey) => {
        this._data[fieldKey] = '';
    };

    remove = (fieldKey) => {
        delete this._data[fieldKey];
    };

    change = (fieldKey) => (value) => {
        if (value.target) {
            this._data[fieldKey] = value.target.value;
            return;
        }
        if (value && value.component) {
            if (typeof value.value !== 'undefined') {
                this._data[fieldKey] = value.value;
            }
            if (typeof value.term !== 'undefined') {
                this._term[fieldKey] = value.term;
            }
            return;
        }
        if (value && !_.isNull(value.value)) {
            this._data[fieldKey] = value.value;
            return;
        }
        this._data[fieldKey] = value;
    };

    addTemplate = (tagId, template, script, type): void => {
        if (_.isEmpty(this._elements)) {
            return;
        }
        const oldElements: WidgetElementType[] = [ ...this._elements ];

        this._elements = this.updateChildrenElements(oldElements, template, tagId, type);

        evalScript(script, this._id);
    };

    removeTemplate = (tagId): void => {
        if (_.isEmpty(this._elements)) {
            return;
        }
        this._elements = this.removeChildrenElements(this._elements, tagId);
    };

    addElements = (element: WidgetElementType, template: string, type: string): WidgetElementType[] => {
        if (type === 'before') {
            return [
                ...this.newChildren(template),
                element,
            ];
        }
        if (type === 'after') {
            return [
                element,
                ...this.newChildren(template),
            ];
        }
        if (!_.isEmpty(element.getChildren())) {
            const oldChildren = [ ...element.getChildren() ];
            const newChildren = this.newChildren(template);
            const newElements = _.filter(oldChildren, child => {
                return !_.find(newChildren, newChild => _.isEqual(child.getType(), newChild.getType())
                    && _.isEqual(child.getChildren(), newChild.getChildren()) && _.isEqual(child.getData(), newChild.getData()));
            });
            element.setChildren([
                ...newElements,
                ...newChildren,
            ]);
            return [ element ];
        } else {
            element.setChildren([
                ...this.newChildren(template),
            ]);
            return [ element ];
        }
    };

    updateChildrenElements = (innElements, template, tagId, type): WidgetElementType[] => {
        let elements: WidgetElementType[] = [];
        _.forEach(innElements, element => {
            if (element.getProps()) {
                if (element.getProps().id === tagId) {
                    elements = [
                        ...elements,
                        ...this.addElements(element, template, type),
                    ];
                } else if (element.getChildren().length > 0) {
                    element.setChildren(this.updateChildrenElements(element.getChildren(), template, tagId, type));
                    elements = [
                        ...elements,
                        element,
                    ];
                } else {
                    elements = [
                        ...elements,
                        element,
                    ];
                }
            } else {
                elements = [
                    ...elements,
                    element,
                ];
            }
        });
        return elements;
    };

    removeChildrenElements = (innElements, tagId) => {
        let elements: WidgetElementType[] = [];
        _.forEach(innElements, element => {
            if (element.getProps()) {
                if (element.getProps().id === tagId) {
                    element.setChildren([]);
                    elements = [
                        ...elements,
                        element,
                    ];
                } else if (element.getChildren().length > 0) {
                    element.setChildren(this.removeChildrenElements(element.getChildren(), tagId));
                    elements = [
                        ...elements,
                        element,
                    ];
                } else {
                    elements = [
                        ...elements,
                        element,
                    ];
                }
            } else {
                elements = [
                    ...elements,
                    element,
                ];
            }
        });
        return elements;
    };

    newChildren = (template): WidgetElementType[] => {
        const result: WidgetDataElementType[] = this.parseTemplate(template);
        const children: WidgetElementType[] = [];
        if (_.isArray(result)) {
            _.forEach(result, row => {
                if (typeof row === 'object') {
                    children.push(new ElementWidget(row, this.getFieldValue));
                }
            });
        } else {
            if (typeof result === 'object') {
                children.push(new ElementWidget(result, this.getFieldValue));
            }
        }
        return children;
    };

    getElements = () => {
        return this._elements;
    };

    parse = (template) => {
        this._countForm = 0;
        const result: WidgetDataElementType[] = this.parseTemplate(template);
        this._elements = _.map(result, element => new ElementWidget(element, this.getFieldValue));
    };

    parseTemplate = (template) => {
        const trimArray = (elements) => {
            let result: any[] = [];
            _.forEach(elements, element => {
                if (typeof element === 'string') {
                    if (_.trim(element,' \n\t')) {
                        result.push(element);
                    }
                } else {
                    let resultElement = _.cloneDeep(element);
                    if (_.isArray(resultElement.props?.children) && resultElement.props?.children?.length > 0) {
                        resultElement.props.children = trimArray(resultElement.props.children);
                    } else if (_.isObject(resultElement.props?.children)) {
                        resultElement.props.children = [ resultElement.props?.children ];
                    }
                    result.push(resultElement);
                }
            });
            return result;
        };
        const replacedTemplate = replaceWidgetCustom(template, this._id);
        const parserOptions = {
            replace: (domNode) => {
                if (!domNode.name) {
                    return null;
                }
                const type = domNode.type === 'tag' ? domNode.name : domNode.type;
                return React.createElement(
                    type,
                    this.getProps(domNode),
                    domToReact(domNode.children, parserOptions)
                );
            },
        };
        const result = parse(replacedTemplate, parserOptions);
        const elements = _.isArray(result) ? result : [ result ];
        return trimArray(elements);
    };

    getProps = (domNode) => {
        const props = { ...domNode.attribs };
        if (props) {
            for (const key in props) {
                if (props.hasOwnProperty(key)) {
                    props[key] = replaceWidgetProp(props[key]);
                }
            }
            if (props.style !== undefined) {
                props.style = `;(() => { return { ${ props.style } } })();`;
            }
            if (props.isshow !== undefined) {
                props.isShow = props.isshow;
                delete props.isshow;
            }
            if (props.doclose !== undefined) {
                props.doClose = props.doclose;
                delete props.doclose;
            }
            if (props.ismandatory !== undefined) {
                props.isMandatory = props.ismandatory;
                delete props.ismandatory;
            }
            if (props.isvisible !== undefined) {
                props.isVisible = props.isvisible;
                delete props.isvisible;
            }
            if (props.readonly !== undefined) {
                props.readOnly = props.readonly;
                delete props.readonly;
            }
            if (props.isopened) {
                props.isOpened = props.isopened;
                delete props.isopened;
            }
            if (props.value && typeof props.value === 'string' && props.value.search(/{?\[/) !== -1) {
                props.value = JSON.parse(props.value);
            }
            if (props.values && props.values.search(/{?\[/) !== -1) {
                props.values = JSON.parse(props.values);
            }
            if (props.canexcludeall !== undefined) {
                props.canExcludeAll = props.canexcludeall;
                delete props.canexcludeall;
            }
            if (props.isreadonly !== undefined) {
                props.isReadOnly = props.isreadonly;
                delete props.isreadonly;
            }
            if (props.value !== undefined && props.model) {
                const model = props.model.split('.');
                let value = props.value;
                if (value === 'false' || value === 'true') {
                    value = value !== 'false';
                }
                this.setFieldValue(model[1], value);
            }
        }
        if (![ 'file', 'text' ].includes(props.type)) {
            props.type = domNode.name;
        }
        props.children = [ ...this.getChildrenComponent(domNode.children) ];
        return props;
    };

    getChildrenComponent = (children) => {
        if (!children || children.length === 0) {
            return [];
        }

        const oneFormAction = (props) => {
            if (props.type === 'form' && props.view !== 'modal') {
                this._countForm++;
                if (this._countForm === 1) {
                    this.setTableNameForm(props.tablename);
                    this.setRecordIdForm(props.sysid);
                }
            }
        };

        const elements = children.map((child, index) => {
            if (!child) {
                return null;
            }
            if (typeof child === 'string') {
                const resChild = _.trim(child, ' \n\t');
                return resChild ? resChild : null;
            }
            if (child.name) {
                const props = this.getProps(child);
                oneFormAction(props);
                return props;
            }
            if (!child.data) {
                return null;
            }
            const data = _.trim(child.data, ' \n\t');
            if (!data) {
                return null;
            }
            return {
                key: `child${ index }`,
                data: data,
                type: 'text',
            };
        });
        return elements.filter(el => el !== null);
    };

    updateElements = () => {
        const update = (elements) => {
            _.forEach(elements, element => {
                const newProps = _.reduce(element.getProps(), function (result, value, key) {
                    result[key] = value;
                    return result;
                }, {});
                element.setProps(newProps);
                const elementData = element.getData();
                if (!_.isEmpty(elementData)) {
                    element.setData(elementData);
                }
                if (!_.isEmpty(element.getChildren())) {
                    update(element.getChildren());
                }
            });
        };
        update(this._elements);
    }

    clear = () => {
        this._data = {};
        this._id = '';
        this._term = {};
        this._elements = [];
        this._options = {};
        this._clientScript = '';
        this._tableNameForm = '';
        this._recordIdForm = '';
        this._countForm = 0;
    };
}

export default new WidgetData();
