import widgetsData from 'globalState/widgets';

const getWidget = (id) => {
    const widget = widgetsData.getWidget(id);
    if (!widget) {
        return null;
    }
    return widget;
};

export default class SimpleWidgets {
    showData = (widgetId) => {
        const widget = getWidget(widgetId);
        if (!widget) {
            return;
        }
        // eslint-disable-next-line no-console
        console.log(widget.getData());
    };

    setFieldValue = (widgetId, key, value) => {
        const widget = getWidget(widgetId);
        if (!widget) {
            return;
        }
        widget.setFieldValue(key, value);
    };

    getFieldValue = (widgetId, key) => {
        const widget = getWidget(widgetId);
        if (!widget) {
            return null;
        }
        return widget.getFieldValue(key);
    };

    getWidgets = () => {
        return widgetsData.getWidgetsId();
    };

    // получение формы в виджете
    getForm = (formName) => {
        return widgetsData.getFormByName(formName);
    };

    getFormById = (id) => {
        return widgetsData.getFormById(id);
    };

    getForms = () => {
        const forms = widgetsData.getForms();
        return forms;
    };
}
