import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, makeObservable} from 'mobx';
import styles from './styles.module.scss';
import Dropdown from 'components/dropdown';
import StringInput from 'components/dynamicForms/view/field/stringInput';
import Button from 'components/button';
import langStore from 'globalState/lang';
import { helperRedirect } from 'helpers/history';
import { isEqual } from 'helpers/data';
import IconArrow from 'assets/img/select-arrow.svg';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { ChoiceProps, OptionItem } from 'types/components/filter/choice';

/***
 * Описание: сохранение/выбор фильтра
 * Праметры:
 * value: {required: false, type: string} - введённое значение
 * readOnly: {required: false, type: boolean} - readOnly
 * options: {required: true, type: array} - массив доступных значений
 * onChange: {required: true, type: function} - метод вызываемый при изменении значения инпута
 * onSave: {required: true, type: function} - метод вызываемый при сохранении фильтра
 * isMobile - мобильная версия
 */

class ChoiceSaveComponent extends React.Component<ChoiceProps>  {
    isOpened = false;
    hoverId = '';
    refDropdown: React.RefObject<HTMLDivElement> = React.createRef();
    refInput: React.RefObject<HTMLDivElement> = React.createRef();
    refButton: React.RefObject<HTMLButtonElement> = React.createRef();

    constructor(props) {
        super(props);

        makeObservable(this, {
            isOpened: observable,
            hoverId: observable,
        });
    }

    componentDidMount() {
        window.addEventListener('scroll', () => this.isOpened = false);
        document.addEventListener('click', this.onDocumentClick);

        if (this.props.value === '') {
            const selected = this.props.options.filter((option) => option.is_selected)[0];
            if (typeof selected !== 'undefined') {
                this.setValue(selected.name);
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.options, prevProps.options)) {
            const selected = this.props.options.filter((option) => option.is_selected)[0];

            if (typeof selected !== 'undefined') {
                this.setValue(selected.name);
            }
            else {
                this.setValue('');
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', () => this.isOpened = false);
        document.removeEventListener('click', this.onDocumentClick);
    }

    onItemHover = (hoverId: string) => {
        this.hoverId = hoverId;
    };

    onItemClick = (option: OptionItem) => {
        helperRedirect(option.url);
        this.isOpened = false;
        this.setValue(option.name);
    };

    onDocumentClick = (e) => {
        const dropdownEl = this.refDropdown ? this.refDropdown.current : null;
        const buttonEl = this.refButton.current;
        if (!dropdownEl || !buttonEl) return false;

        if (!dropdownEl.contains(e.target) && !buttonEl.contains(e.target)) {
            this.isOpened = false;
        }
    };

    setValue(name: string) {
        const { filter_titles = {} } = langStore.getTranslate();
        this.props.onChange(name !== filter_titles.none_filter_name ? name : '');
    }

    onChange = (data) => {
        this.props.onChange(data.value);
    };

    renderMenu() {
        const items = this.props.options.map((option, index) => {
            const hoverId = index + '.' + option.name;
            return (
                <div
                    className={ `${ styles.menuItem } ${ hoverId === this.hoverId ? styles.hover : '' }` }
                    key={ hoverId }
                    onClick={ () => this.onItemClick(option) }
                    onMouseEnter={ () => this.onItemHover(hoverId) }
                >
                    { option.name }
                </div>
            );
        });

        return <div className={styles.menu}>{items}</div>;
    }

    render() {
        const { filter_titles } = langStore.getTranslate();
        const { isMobile, isServicePortal, value, readOnly, onSave} = this.props;

        return (
            <>
                <div
                    className={ `${styles.Choice} ${isMobile ? styles.MobileChoice : ''}` }
                    ref={ this.refInput }
                    data-test={ ATTRIBUTES.conditionSavedFilters }
                >
                    <div className={ styles.container }>
                        <StringInput
                            isServicePortal={ isServicePortal }
                            className={ styles.input }
                            onChange={ this.onChange }
                            value={ value }
                            readOnly={ readOnly }
                            placeholder={ filter_titles?.enter_name }
                        />
                        <Button
                            className={ styles.button }
                            buttonType={ 'icon-border' }
                            onClick={ () => {
                                this.isOpened = !this.isOpened;
                            } }
                            ref={ this.refButton }
                        >
                            <div className={ styles.icon } dangerouslySetInnerHTML={ { __html: IconArrow } } />
                        </Button>
                    </div>

                    { this.isOpened && !readOnly &&
                    <Dropdown refParent={ this.refInput } ref={ this.refDropdown }>
                        { this.renderMenu() }
                    </Dropdown>
                    }
                </div>

                <Button
                    onClick={ onSave }
                    disabled={ !value || readOnly }
                    className={ isMobile ? styles.MobileSave : '' }
                    data-test={ ATTRIBUTES.conditionSaveNewFilterButton }
                >
                    { filter_titles?.button_save }
                </Button>
            </>
        );
    }
}

export default observer(ChoiceSaveComponent);
