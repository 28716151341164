//Класс клиентского API для доступа к хранлилщу данных бизнес решения
import BusinessSolutionStorage from 'globalState/bsStorage';


export default class SimpleStorage {

    setItem = (key, value) => {
        BusinessSolutionStorage.setItem(key, value);
    };

    getItem = (key) => {
        return BusinessSolutionStorage.getItem(key);
    };

    getKeys = () => {
        return BusinessSolutionStorage.getKeys();
    };

    getEntries = () => {
        return BusinessSolutionStorage.getEntries();
    };

    hasItem = (key) => {
        return BusinessSolutionStorage.hasItem(key);
    };

    getSize = () => {
        return BusinessSolutionStorage.getSize();
    };

    reset = () => {
        BusinessSolutionStorage.reset();
    };
}