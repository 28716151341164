import { observable, makeObservable } from 'mobx';
import { modalDataState } from 'globalState/modal/index';
import * as _ from 'lodash';

export class Modals {
    modals: any[] = [];

    constructor() {
        makeObservable(this, {
            modals: observable,
        });
    }

    addModal(id) {
        const autoId = this.modals.length ? this.modals.length + 1 : 1;
        let finalId = id ? id : autoId;
        const modal = new modalDataState(finalId);
        this.modals.push(modal);
        return modal;
    }

    getModal(id) {
        return _.find(this.modals, modal => modal.getId() === id);
    }

    removeModal(id) {
        this.modals = _.filter(this.modals, modal => modal.getId() !== id);
    }

    getModals() {
        return this.modals;
    }

    getModalIds() {
        return _.map(this.modals, modal => modal.getId());
    }
}

export default new Modals();
