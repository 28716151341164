import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import { isEqual } from 'helpers/data';
import ContextMenu from 'components/contextMenu';
import { IS_UPDATE_CHART } from 'globalState/report';
import styles from './styles.module.scss';

import InfoMessagesState from 'globalState/infoMessages';
import SelectUiAction from 'components/uiButtons/selectUiAction';
import actionsPanelState from 'globalState/actionsPanelState';
import globalTableState from 'globalState/table';
import langStore from 'globalState/lang';

import Thead from 'components/table/thead';
import Tbody from 'components/table/tbody';
import Pagination from 'components/groupedTable/pagination';
import IconEyeOff from 'assets/img/icons/eye-off.svg';
import { getFieldType } from 'helpers/condition';
import Button from 'components/button';
import GlobalPortal from 'components/globalPortal';
import TablesState from 'globalState/tables';
import { ATTRIBUTES } from 'constants/attributesForTests';


/**
 * компонент таблицы
 * используется на листах и для отчёта типа List
 *
 * @props essence
 * @props columns
 * @props items
 * @props parent
 * @props pagination
 * @props conditionState
 * @props onChangeCondition
 * @props contextMenuBody
 * @props contextMenuHeader
 * @props isServicePortal
 * @props recordId
 * @props recordTableName
 * @props relatedColumnName - название колонки, которая ссылается на рекорд. Заполняется в relatedList
 * @props doFetch
 * @props selectUiAction
 * @props isBlankMode режим без поиска, настроек, чекбоксов и т.д.
 * @props formId id формы record`а для передачи в таблицу релейт листов.
 * @props isWindow режим словаря
 * @props usedByList режим словаря, открытого через поле list *
 * @props isMobile отображение для мобилки
 */
export const Table = observer(class Table extends React.Component {
    editRow = null;
    editCol = null;
    items;
    isShowContextMenu = false;
    isShowHeadListMenu = false;
    menuCoordinates = {
        x: 0,
        y: 0,
    };
    _checked = new Set;
    SelectUiActionCheck = false;
    headRowRef = React.createRef();
    fixedHeadRowRef = React.createRef();
    fixedHeadRef = React.createRef();
    fixedContainerRef = React.createRef();
    tableWrapRef = React.createRef();
    tableRef = React.createRef();
    fixedScrollRef = React.createRef();
    isFixedHeadVisible = false;
    isSearchRowVisible = false;
    isHorizontalScrollVisible = false;
    timeout = null;
    selectedByList = [];

    constructor(props) {
        super(props);

        makeObservable(this, {
            editRow: observable,
            editCol: observable,
            items: observable,
            isShowContextMenu: observable,
            isShowHeadListMenu: observable,
            menuCoordinates: observable,
            _checked: observable,
            SelectUiActionCheck: observable,
            isFixedHeadVisible: observable,
            isSearchRowVisible: observable,
            isHorizontalScrollVisible: observable,
        });

        this.setInnerAttributes();
        //window.tableGlobal[this.props.essence] = this;
        TablesState.setTable(this.props.essence, this);
        if (props.reportState) {
            this.reportState = props.reportState;
        }
    }

    componentDidUpdate(prevProps) {
        this.setInnerAttributes(prevProps);

        const tableState = this.getStateTable();
        if (tableState.getRows() && tableState.getRows().length) {
            this.SelectUiActionCheck = this.isAllChecked();
        }

        // обновление для репортов
        if (this.props.isUpdateChart && this.reportState) {
            this.reportState.stateFlags = this.reportState.stateFlags & ~IS_UPDATE_CHART;
        }

        this.initFixedHeaderAndHorizontalScroll();
    }

    componentDidMount() {
        this.initFixedHeaderAndHorizontalScroll();
    }

    componentWillUnmount() {
        const { parent } = this.props;

        //window.tableGlobal[this.props.essence] && delete window.tableGlobal[this.props.essence];
        TablesState.deleteTable(this.props.essence);

        this.SelectUiActionCheck = false;
        this._checked.clear();

        const parent_data = parent ?
            (parent.data ? parent.data.essence : parent.props.data.essence)
            : null;

        const tableState = this.getStateTable();

        if (parent_data === this.props.essence) {
            tableState.clear();
        }

        this.destroyFixedHeaderAndHorizontalScroll();
    }

    initFixedHeaderAndHorizontalScroll = () => {
        if (actionsPanelState.panelEl) {
            this.updateFixedHeadDimensions();
            this.checkFixedHead();
        }

        this.updateHorizontalScrollDimensions();
        this.checkHorizontalScroll();
        if (this.tableWrapRef && this.tableWrapRef.current) {
            this.tableWrapRef.current.addEventListener('scroll', this.onTableWrapScroll);
        }
        if (this.fixedScrollRef && this.fixedScrollRef.current) {
            this.fixedScrollRef.current.addEventListener('scroll', this.onHorizontalScroll);
        }
        window.addEventListener('scroll', this.onWindowScroll);
        window.addEventListener('resize', this.onWindowResize);

    };

    destroyFixedHeaderAndHorizontalScroll = () => {
        if (this.tableWrapRef && this.tableWrapRef.current) {
            this.tableWrapRef.current.removeEventListener('scroll', this.onTableWrapScroll);
        }
        if (this.fixedScrollRef && this.fixedScrollRef.current) {
            this.fixedScrollRef.current.removeEventListener('scroll', this.onHorizontalScroll);
        }
        window.removeEventListener('scroll', this.onWindowScroll);
        window.removeEventListener('resize', this.onWindowResize);
    };

    onWindowScroll = () => {
        if (actionsPanelState.panelEl) {
            this.checkFixedHead();
        }
        this.checkHorizontalScroll();
    };

    getStateTable = () => {
        return this.props.tableState || globalTableState;
    };

    onWindowResize = () => {
        if (actionsPanelState.panelEl) {
            this.updateFixedHeadDimensions();
        }
        this.updateHorizontalScrollDimensions();
    };

    onTableWrapScroll = () => {
        if (actionsPanelState.panelEl) {
            this.setFixedHeadContLeft();
        }
        // используем timeout для корректной работы скролла с помощью Shift + mouseWheel
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.setHorizontalScrollLeft();
        }, 200);

        // тригерим скролл для закрытия дропдаунов селектов в шапке таблицы
        window.dispatchEvent(new Event('scroll'));
    };

    onHorizontalScroll = () => {
        if (actionsPanelState.panelEl) {
            this.setFixedHeadContLeft();
        }
        this.setTableWrapScrollLeft();
    };

    toggleSearchRowVisibility = () => {
        this.isSearchRowVisible = !this.isSearchRowVisible;
    };

    setInnerAttributes = (prevProps = null) => {
        // ссылка, чтобы uiActions видели этот компонент
        TablesState.setTable(this.props.essence, this);
        if (
            prevProps &&
            isEqual(
                { essence: prevProps.essence, ...prevProps.items },
                { essence: this.props.essence, ...this.props.items },
            ) &&
            isEqual(this.props.pagination && prevProps.pagination)
        ) {
            return false;
        }

        const tableState = this.getStateTable();

        if (!prevProps) {
            tableState.setRows(this.props.items);
        }
        else if (!isEqual(this.props.items, prevProps.items)) {
            tableState.setRows(this.props.items);
            this.updateCheckedRows();
        }
        tableState.setName(this.props.essence);
        tableState.setPagination(this.props.pagination);
        tableState.setTableId(this.props.tableId);
        tableState.setRecordColumnName(this.props.recordColumnName);
    };

    updateCheckedRows = () => {
        const oldCheckedRows = this.getCheckedRow();
        this._checked.clear();
        if (oldCheckedRows.length) {
            _.forEach(oldCheckedRows, (sysId) => {
                if (_.find(this.getStateTable().getRows(), item => item.sys_id === sysId)) {
                    this._checked.add(sysId);
                }
            });
        }
    };

    // Метод срабатывает каждый раз по вводу текста в фильтр
    handleValueChange = (id, fieldValue, operatorValue, options) => ({ value, submit, isClean }) => {
        const { conditionState, onChangeCondition } = this.props;
        const filterFields = conditionState ? conditionState.getFilterFields() : [];

        const newId = id || `filter${ conditionState.getKeyFilterIndex() + 1 }`;
        let filteringRow = filterFields.find(row => row.getId() === newId);
        if (!filteringRow) {
            filteringRow = this.props.conditionState.addFiltering('AND', 1);
        }
        let innValue = value;
        if (value !== null && value !== undefined) {
            if (value.target) {
                innValue = conditionState.isSorting(newId) || value.target
                    ? value.target.value
                    : options.find(option => option.database_value === value.target.value);
            }
            if (value === true || value === false) {
                innValue = value ? 1 : 0;
            }
        }

        const isNotChangeFilterFields = filterFields.find(
            item => {
                return item.getId() === id &&
                    item.getOperator().database_value === operatorValue &&
                    item.getField().dot_walking_attribute === fieldValue &&
                    item.getValue() === innValue;
            },
        );

        if (!isNotChangeFilterFields) { // Если значение изменилось в фильтре
            const dotWalkItem = this.findDotWalkItem(fieldValue);
            const element = dotWalkItem && dotWalkItem.condition_type === 'boolean' ? 'select' : 'text';
            if (!innValue) {
                conditionState.removeFilteringRow(newId);
            }
            else {
                const findObj = langStore.getConstant(getFieldType(dotWalkItem || {}));
                const findOperator = findObj ? findObj.options.find(option => option.database_value === operatorValue) : '';
                filteringRow.setField(dotWalkItem || fieldValue);
                filteringRow.setOperator(findOperator || {
                    database_value: operatorValue,
                    show_element: element,
                });
                filteringRow.setValue(innValue);
            }

            // изменяем когда выбор в поле select
            if (element === 'select' && onChangeCondition) {
                onChangeCondition();
            }
        }
        // изменяем когда нажат enter или clean
        if ((submit || (isClean && !innValue)) && onChangeCondition) {
            onChangeCondition();
        }
    };

    findDotWalkItem = (fieldValue) => {
        const { conditionState } = this.props;
        const dotWalkList = conditionState.getDotWalkList();
        const addDotWalkLists = conditionState.getColumnsDotWalkList();
        let dotWalkItem = dotWalkList.find(dotWalk => dotWalk.dot_walking_attribute === fieldValue);
        if (!dotWalkItem) {
            for (let index = 0; index < addDotWalkLists.length; index++) {
                dotWalkItem = addDotWalkLists[index].find(dotWalk => dotWalk.dot_walking_attribute === fieldValue);
                if (dotWalkItem) {
                    break;
                }
            }
        }
        return dotWalkItem;
    };

    handleSortingChange = (fieldValue, directionValue) => {
        const { conditionState, onChangeCondition } = this.props;
        conditionState.clearSorting();
        const sortingRow = conditionState.addSorting();
        const dotWalkItem = this.findDotWalkItem(fieldValue);
        sortingRow.setField(dotWalkItem || fieldValue);
        sortingRow.setDirection({ database_value: directionValue });
        if (onChangeCondition) {
            onChangeCondition();
        }
    };

    // функция для удаления через внешние uiActions
    //todo После рефакторинга глобальных переменных вынести doFetch в клиентские крипты
    deleteCheckedRow = (rowId) => {
        this._checked.clear();
        const tableState = this.getStateTable();
        tableState.setRows(tableState.getRows().filter((item) => item.sys_id !== rowId));
        const pagination = tableState.getPagination();
        tableState.changePaginationTotal(this.isDisablePageCalculation ? pagination.total : pagination.total - 1);
        tableState.changePaginationPerPage(pagination.per_page - 1);
        if (this.props.doFetch) {
            this.props.doFetch(false);
        }
    };

    checkAllRow = ({ value }) => {
        const tableState = this.getStateTable();
        tableState.getRows().forEach((item) => {
            if (value) {
                this._checked.add(item.sys_id);

                if (this.props.usedByList) {
                    const label = { value: item.__display_value };
                    const display_value = label.value && typeof label.value === 'object' && 'display_value' in label.value ?
                        label.value.display_value || item.sys_id : label.value;

                    this.selectedByList.push({
                        display_value,
                        database_value: item.sys_id,
                    });
                }
            }
            else {
                this._checked.delete(item.sys_id);

                if (this.props.usedByList) {
                    this.selectedByList = this.selectedByList.filter(item => item.database_value !== item.sys_id);
                }
            }
        });
        this.SelectUiActionCheck = value;
    };

    isAllChecked() {
        const tableState = this.getStateTable();
        if (tableState.getRows().length === 0 || this._checked.size === 0) {
            return false;
        }
        const hasUnchecked = tableState.getRows().some(
            item => !this._checked.has(item.sys_id),
        );
        return !hasUnchecked;
    }

    onCheckRow = (id, value, display_value) => {
        if (value) {
            this._checked.add(id);
            this.SelectUiActionCheck = this.isAllChecked();

            if (this.props.usedByList) {
                this.selectedByList.push({
                    display_value,
                    database_value: id,
                });
            }
        }
        else {
            this._checked.delete(id);
            this.SelectUiActionCheck = false;

            if (this.props.usedByList) {
                this.selectedByList = this.selectedByList.filter(item => item.database_value !== id);
            }
        }
    };

    //функция для добавления оповещений через uiActions
    addInfoMessages(message) {
        InfoMessagesState.pushInfo(message);
    }

    getCheckedRow = () => {
        return Array.from(this._checked);
    };

    toString() {
        return this.props.essence;
    }

    handleToggleContextMenu = (isHeaderMenu = false) => () => {
        if (isHeaderMenu) {
            this.isShowHeadListMenu = !this.isShowHeadListMenu;
        }
        else {
            this.isShowContextMenu = !this.isShowContextMenu;
        }
    };

    get isDisablePageCalculation() {
        const tableState = this.getStateTable();
        const pagination = tableState.getPagination();
        return !pagination.enabled_pages_calculation && pagination.total < 0;
    }

    renderContextMenu = () => {
        if (!this.isShowContextMenu || !this.props.contextMenuBody || this.props.isWindow) {
            return null;
        }

        return (
            <ContextMenu
                items={ this.props.contextMenuBody }
                x={ this.menuCoordinates.x }
                y={ this.menuCoordinates.y }
                isShowContextMenu={ this.isShowContextMenu }
                onToggleContextMenu={ this.handleToggleContextMenu() }
            />
        );
    };

    renderHeadListMenu = () => {
        if (!this.isShowHeadListMenu || !this.props.contextMenuHeader || this.props.isWindow) {
            return null;
        }

        return <ContextMenu
            items={ this.props.contextMenuHeader }
            x={ this.menuCoordinates.x }
            y={ this.menuCoordinates.y }
            isShowContextMenu={ this.isShowHeadListMenu }
            onToggleContextMenu={ this.handleToggleContextMenu(true) }
        />;
    };

    updateFixedHeadDimensions = () => {
        const { current: fixedHead } = this.fixedHeadRef;
        const { current: tableWrap } = this.tableWrapRef;
        if (!tableWrap || !fixedHead) return;

        fixedHead.style.left = `${ tableWrap.getBoundingClientRect().left }px`;
        fixedHead.style.width = `${ tableWrap.offsetWidth }px`;

        this.setFixedHeadContLeft();
        this.setFixedHeadColSizes();
        this.setFixedHeadTop();
    };

    checkFixedHead = () => {
        if (!this.fixedHeadRef || !this.tableWrapRef || !this.fixedHeadRef.current || !this.tableWrapRef.current) {
            return;
        }
        const tableHeadHeight = this.fixedHeadRef.current.getBoundingClientRect().height;
        const tableWrapRect = this.tableWrapRef.current.getBoundingClientRect();
        const panelRect = actionsPanelState.panelEl.getBoundingClientRect();
        const scrollTop = window.pageYOffset;

        this.isFixedHeadVisible = scrollTop + panelRect.top + panelRect.height > tableWrapRect.top + scrollTop && scrollTop + panelRect.top + panelRect.height + tableHeadHeight < scrollTop + tableWrapRect.top + tableWrapRect.height;
    };

    setFixedHeadTop = () => {
        const panelRect = actionsPanelState.panelEl.getBoundingClientRect();
        let fixedHead;
        if (this.fixedHeadRef && this.fixedHeadRef.current) {
            fixedHead = this.fixedHeadRef.current;
        }
        if (!fixedHead) {
            return;
        }
        fixedHead.style.top = panelRect.top + panelRect.height + 'px';
    };

    setFixedHeadColSizes = () => {
        const fixedHeader = this.fixedHeadRowRef ? this.fixedHeadRowRef.current : null;
        const header = this.headRowRef ? this.headRowRef.current : null;
        if (!header || !fixedHeader) {
            return;
        }
        for (let i = 0; i < header.children.length; i++) {
            if (!fixedHeader.children[i].children[0] || !header.children[i].children[0]) return;
            fixedHeader.children[i].children[0].style.width = header.children[i].children[0].getBoundingClientRect().width + 'px';
        }
    };

    setFixedHeadContLeft = () => {
        const wrap = this.tableWrapRef ? this.tableWrapRef.current : null;
        const container = this.fixedContainerRef ? this.fixedContainerRef.current : null;
        if (!wrap || !container) {
            return;
        }
        container.style.left = -wrap.scrollLeft + 'px';
    };

    checkHorizontalScroll = () => {
        if (!this.fixedHeadRef || !this.tableWrapRef || !this.fixedHeadRef.current || !this.tableWrapRef.current) {
            return;
        }
        const tableWrapRect = this.tableWrapRef.current.getBoundingClientRect();
        const tableHeadHeight = this.fixedHeadRef.current.getBoundingClientRect().height;
        const scrollTop = window.pageYOffset;
        const windowHeight = window.document.documentElement.clientHeight;
        this.isHorizontalScrollVisible = tableWrapRect.top + scrollTop + tableWrapRect.height > scrollTop + windowHeight && tableWrapRect.top + scrollTop + tableHeadHeight < scrollTop + windowHeight;
    };

    setHorizontalScrollLeft = () => {
        if (!this.tableWrapRef || !this.fixedScrollRef || !this.tableWrapRef.current || !this.fixedScrollRef.current) {
            return;
        }
        this.fixedScrollRef.current.scrollLeft = this.tableWrapRef.current.scrollLeft;
    };

    setTableWrapScrollLeft = () => {
        if (!this.fixedScrollRef || !this.tableWrapRef || !this.fixedScrollRef.current || !this.tableWrapRef.current) {
            return;
        }
        this.tableWrapRef.current.scrollLeft = this.fixedScrollRef.current.scrollLeft;
    };

    updateHorizontalScrollDimensions = () => {
        const { current: fixedScroll } = this.fixedScrollRef;
        const { current: tableWrap } = this.tableWrapRef;
        const { current: table } = this.tableRef;
        if (!tableWrap || !fixedScroll || !table) return;

        fixedScroll.style.left = `${ tableWrap.getBoundingClientRect().left }px`;
        fixedScroll.style.width = `${ tableWrap.offsetWidth }px`;
        fixedScroll.children[0].style.width = `${ table.offsetWidth }px`;
    };

    selectDictionary = () => {
        window.opener.postMessage(this.selectedByList, '*');
    };

    checkPagination = () => {
        const tableState = this.getStateTable();
        const pagination = tableState.getPagination();
        if (!pagination.total || !pagination.per_page || this.isDisablePageCalculation) return false;
        if (pagination.total <= pagination.per_page) return true;
        return false;
    };

    renderBody() {
        const { conditionState, formId, relatedListId } = this.props;
        const { list_titles } = langStore.getTranslate();

        const filterFields = conditionState ? conditionState.getFilterFields() : [];

        const styleTableWrap = [styles.tableWrap];
        if (!this.props.isServicePortal) {
            styleTableWrap.push(styles.mainTable);
        }
        if (this.props.classes && this.props.classes.TableWrapper) {
            styleTableWrap.push(this.props.classes.TableWrapper);
        }

        const fixedHorizontalScroll = [styles.fixedHorizontalScroll];
        if (this.props.classes && this.props.classes.fixedHorizontalScroll) {
            fixedHorizontalScroll.push(this.props.classes.fixedHorizontalScroll);
        }

        let message;
        const tableState = this.getStateTable();
        const pagination = tableState.getPagination();
        const itemsLength = tableState.getRowsLength();
        if (pagination.hidden_entries) {
            message = <div className={ styles.tableMessage }>
                <div className={ styles.icon } dangerouslySetInnerHTML={ { __html: IconEyeOff } } />
                { list_titles && list_titles.hidden_entries }
            </div>;
        }
        else if (itemsLength === 0) {
            message = <div className={ styles.tableMessage }>{ list_titles && list_titles.no_items }</div>;
        }


        const headerClass = this.props.classes && this.props.classes.Header ? this.props.classes.Header : '';
        return (
            <React.Fragment>
                <GlobalPortal>
                    <div ref={ this.fixedHeadRef }
                         className={ `${ styles.fixedHead } ${ this.isFixedHeadVisible && !this.props.isMobile ? styles.visible : '' } ${ this.props.classes && this.props.classes.fixedHead }` }>
                        <div className={ styles.container } ref={ this.fixedContainerRef }>
                            <table className={ styles.Table }>
                                <Thead
                                    reference={ this.fixedHeadRowRef }
                                    table={ this }
                                    filterFields={ filterFields }
                                    sortingFields={ conditionState ? conditionState.getSortingFields() : [] }
                                    onSearchClick={ this.toggleSearchRowVisibility }
                                    isSearchRowVisible={ this.isSearchRowVisible }
                                    isBlankMode={ this.props.isBlankMode }
                                    className={ this.props.classes && this.props.classes.Header }
                                    isFixedHead
                                />
                            </table>
                        </div>
                    </div>
                </GlobalPortal>
                <div className={ styleTableWrap.join(' ') } ref={ this.tableWrapRef }>
                    <table
                        className={ `${ styles.Table }` }
                        ref={ this.tableRef }
                        data-test={ ATTRIBUTES.table }
                    >
                        <Thead
                            reference={ this.headRowRef }
                            table={ this }
                            filterFields={ filterFields }
                            sortingFields={ conditionState ? conditionState.getSortingFields() : [] }
                            onSearchClick={ this.toggleSearchRowVisibility }
                            isSearchRowVisible={ this.isSearchRowVisible }
                            recordId={ this.props.recordId }
                            recordTableName={ this.props.recordTableName }
                            isBlankMode={ this.props.isBlankMode }
                            isWindow={ this.props.isWindow }
                            usedByList={ this.props.usedByList }
                            className={ headerClass }
                            formId={ formId }
                            maxLength={ this.props.maxLength }
                            maxValues={ this.props.maxValues }
                            isMobile={ this.props.isMobile }
                            data-test={ ATTRIBUTES.tableHeader }
                        />
                        <Tbody
                            key={ this.items }
                            items={ tableState.getRows() }
                            table={ this }
                            filterFields={ filterFields }
                            conditionState={ conditionState }
                            isServicePortal={ this.props.isServicePortal }
                            isRelatedList={ this.props.isRelatedList }
                            onCheckRow={ this.onCheckRow.bind(this) }
                            isBlankMode={ this.props.isBlankMode }
                            isWindow={ this.props.isWindow }
                            usedByList={ this.props.usedByList }
                            classes={ this.props.classes }
                            clientScripts={ this.props.clientScripts }
                            isMobile={ this.props.isMobile }
                            formId={ formId }
                            relatedListId={ relatedListId }
                        />
                    </table>
                    { this.renderHeadListMenu() }
                    { this.renderContextMenu() }
                    { message }
                </div>
                <div
                    className={ `${ fixedHorizontalScroll.join(' ') } ${ this.isHorizontalScrollVisible && !this.props.isMobile ? styles.visible : '' }` }
                    ref={ this.fixedScrollRef }>
                    <div />
                </div>
                { (this.props.usedByList && this.getCheckedRow().length > 0) && <Button buttonType={ 'primary' } className={ styles.SelectButton } onClick={ this.selectDictionary }>{ list_titles && list_titles.select_items }</Button> }
            </React.Fragment>
        );
    }

    render() {
        const { list_titles = {} }  = langStore.getTranslate();
        const tableState = this.getStateTable();
        if (!tableState.getRows()) {
            return null;
        }
        const pagination = tableState.getPagination();
        const isBlankMode =
            this.props.isBlankMode === undefined
                ? false
                : !this.props.isServicePortal && this.props.isBlankMode;
        const checkPaginationBoolean = this.checkPagination();

        let showSelectUiAction = false;
        if (this.props.selectUiAction && pagination.total > 1) {
            if (this.props.isWindow) {
                showSelectUiAction = !!this.props.usedByList;
            }
            else {
                showSelectUiAction = true;
            }
        }

        let totalItems = '';
        if (this.isDisablePageCalculation) {
            totalItems = `${Math.abs(pagination.total)}+ ${list_titles.items}`;
        } else if (!tableState.getRowsLength() && !pagination.enabled_pages_calculation) {
            totalItems = '';
        } else {
            totalItems = `${pagination.total} ${list_titles.items}`;
        }

        return (
            <React.Fragment>
                {
                    isBlankMode ? null : <div className={ styles.tableTop }>
                        <div
                            className={ `${ styles.PaginationTotalText }` }
                            data-test={ ATTRIBUTES.tablePagintaionTotalItems }
                        >
                            { totalItems }
                        </div>
                        {
                            checkPaginationBoolean || this.props.isMobile ? null :
                                <Pagination
                                    pagination={ pagination }
                                    onChangePage={ this.props.onChangePage }
                                />
                        }
                    </div>
                }

                { this.renderBody() }

                {
                    isBlankMode ? null : <div className={ styles.tableEnd }>
                        { showSelectUiAction && !this.props.isMobile &&
                        <SelectUiAction
                            value={ this.SelectUiActionCheck }
                            essence={ this.props.essence }
                            items={ this.props.selectUiAction }
                            onChangeCheckbox={ this.checkAllRow }
                        />
                        }
                        {
                            !checkPaginationBoolean &&
                            <Pagination
                                isMobile={ this.props.isMobile }
                                pagination={ pagination }
                                onChangePage={ this.props.onChangePage }
                            />
                        }
                    </div>
                }
            </React.Fragment>
        );
    }

    get globalTableState() { // Для проверки состояния в тестах
        return globalTableState;
    }
});

