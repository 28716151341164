import * as React from 'react';
import { observer } from 'mobx-react';
import UiButton from 'components/uiButtons/uiButton';
import styles from './styles.module.scss';
import { ATTRIBUTES } from 'constants/attributesForTests';
import UiActionState from 'globalState/uiAction';

/**
 * компонент группы кнопок
 * используется на листах и записях для обображения элементов упраления
 *
 * @props data
 * @props recordId
 * @props tableName
 * @props actionsContextMenu
 */
class UiButtons extends React.Component {
    constructor(props) {
        super(props);
    }

    renderButtons = (items) => {
        if (!items || items.length === 0) {
            return null;
        }
        return (
            <>
                { items.map((item, index) => {
                    return (
                        <div className={ this.props.isRelatedLinks ? styles.link : styles.uiButton }
                             key={ `${ index }_${ item.sys_id }` }>
                            <UiButton
                                item={ item }
                                isRelatedLinks={ this.props.isRelatedLinks }
                                tableName={ this.props.tableName }
                                recordId={ this.props.recordId }
                                actionsContextMenu={ this.props.actionsContextMenu }
                                disableUiActions={ this.props.disableUiActions }
                                data-test={ `${ item.page_key }-${ ATTRIBUTES.uiButton }` }
                                isDisabled={ UiActionState.getWaitServerResponse() }
                            />
                        </div>);
                })
                }
            </>
        );
    };

    render() {

        return (
            <>
                { this.renderButtons(this.props.data) }
            </>
        );
    }
}

export default observer(UiButtons);
