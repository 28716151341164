import * as React from 'react';
import { ItemIconPropsType } from 'types/globalState/menu';
import { observer } from 'mobx-react';
import IconTextFormatList from 'assets/img/icons/text-format-list.svg';
import styles from 'layouts/main/sidebar/menu/styles.module.scss';
import { ATTRIBUTES } from 'constants/attributesForTests';

function ItemIcon({ item, className }: ItemIconPropsType) {
    if (!item.getIcon()) {
        if (item.getIsCategory()) {
            return (
                <div className={ className } data-test={ ATTRIBUTES.sidebarDefaultCategoryIcon }>
                    <span dangerouslySetInnerHTML={ { __html: IconTextFormatList } } className={ styles.icon } />
                </div>
            );
        }
        return null;
    }
    return (
        <div
            className={ className }
            data-test={ ATTRIBUTES.sidebarMenuItemIcon }
        >
            <img src={ item.getIcon() } />
        </div>
    );
}

export default observer(ItemIcon);