import {fetchI18nMessages} from 'actions/i18n';
import {SimpleI18nType, I18nMessagesParams} from 'types/clientAPI/simpleI18n';

export default class SimpleI18n implements SimpleI18nType {
    getMessage = async (messages: string, ...args): Promise<string> => {
        let config, callback;
        if (typeof args[0] === "function") {
            callback = args[0];
        } else {
            config = args[0];
            callback = args[1];
        }

        const params = {
            messages,
            config,
        } as I18nMessagesParams;

        const {isOkStatus, data} = await fetchI18nMessages(params);
        if (isOkStatus) {
            callback && callback(data.messages[0]);
            return data.messages[0];
        } else {
            callback && callback(messages);
            return messages;
        }
    };

    getMessages = async (messages: string[], ...args): Promise<string[]> => {
        let config, callback;
        if (typeof args[0] === "function") {
            callback = args[0];
        } else {
            config = args[0];
            callback = args[1];
        }

        const params = {
            messages,
            config,
        } as I18nMessagesParams;

        const {isOkStatus, data} = await fetchI18nMessages(params);
        if (isOkStatus) {
            callback && callback(data.messages);
            return data.messages;
        } else {
            callback && callback(messages);
            return messages;
        }
    };
}

