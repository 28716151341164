import * as React from 'react';
import styles from './styles.module.scss';
import {BadgeProps, StyleObj} from "types/components/badge";

function Badge({width, height, background, color, children, className, innerRef, ...props}: BadgeProps) {
    const styleObj: StyleObj = {};

    if (width) {
        styleObj.minWidth = `${width}px`;
    }
    if (height) {
        styleObj.height = `${height}px`;
        styleObj.lineHeight = `${height}px`;
    }
    if (background) {
        styleObj.background = `${background}`;
    }
    if (color) {
        styleObj.color = `${color}`;
    }

    return (
        <div
            { ...props }
            className={ `${styles.Badge} ${className}` }
            style={ styleObj }
            ref={ innerRef }
        >
            { children }
        </div>
    );
}

export default React.forwardRef<HTMLDivElement, BadgeProps>((props: BadgeProps, ref?: React.Ref<HTMLDivElement> | null) => <Badge innerRef={ref} {...props} />);
