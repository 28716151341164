import {$isAtNodeEnd} from '@lexical/selection';
import {ElementNode, RangeSelection, TextNode} from "lexical";

export function getSelectedNode(selection: RangeSelection): TextNode | ElementNode {
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    if (anchorNode === focusNode) {
        return anchorNode;
    }
    const isBackward = selection.isBackward();
    if (isBackward) {
        return $isAtNodeEnd(focus) ? anchorNode : focusNode;
    } else {
        return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
    }
}

export function setPopupPosition(
    editor: HTMLElement,
    rect: ClientRect | null,
    rootElement: HTMLElement,
): void {
    if (rect === null) {
        editor.style.opacity = '0';
        editor.style.top = '-1000px';
        editor.style.left = '-1000px';
    }
    else {
        const windowHeight = document.documentElement.clientHeight;
        const editorRect = editor.getBoundingClientRect();
        const offset = 10;

        editor.style.opacity = '1';

        let top = rect.top + rect.height + window.pageYOffset + offset;
        if (top - window.scrollY < 0) { // если попап вылезает за верх окна
            top = window.scrollY + offset / 2;
        }
        else if (top + editorRect.height - window.scrollY > windowHeight) { // если попап вылезает за низ окна
            top = top - (top + editorRect.height - window.scrollY - windowHeight) - offset * 2;
        }
        // проставляем top для попапа
        editor.style.top = `${ top }px`;

        const left = rect.left - editor.offsetWidth / 2 + rect.width / 2;
        const rootElementRect = rootElement.getBoundingClientRect();
        if (rootElementRect.left > left) {
            editor.style.left = `${ rect.left + window.pageXOffset }px`;
        }
        else if (left + editor.offsetWidth > rootElementRect.right) {
            editor.style.left = `${
                rect.right + window.pageXOffset - editor.offsetWidth
            }px`;
        } else {
            editor.style.left = `${ rect.left + window.pageXOffset }px`;
        }
    }
}