import BasePortalDesignerCollection from './base';
import ColumnPortalDesignerModel from 'components/portalDesigner/model/column';

/**
 * Коллекция ячеек
 * @extends BasePortalDesignerCollection
 */
export default class ColumnsPortalDesignerCollection extends BasePortalDesignerCollection {

    /**
     *
     * @param columns [ColumnPortalDesignerModel]
     * @param parent {RowPortalDesignerModel}
     */
    constructor(columns, parent) {
        super(columns, ColumnPortalDesignerModel, parent);
    }
}
