/**
 * Описание: Для управления масштабом страницы
 *  zoom - текущий zoom
 *  increaseZoom - метод увеличения zoom
 *  decreaseZoom - метод уменьшения zoom
 *  isToolsOpened - открыта ли панель инструментов
 *  adjustZoom - метод отрисовки диаграммы на весь экран
 *  resetZoom - метод сброса zoom до 100%
 * */
import * as React from 'react';

import styles from './styles.module.scss';
import IconFitToScreen from 'assets/img/icons/size-minimize.svg';
import IconMinus from 'assets/img/icons/minus.svg';
import IconPlus from 'assets/img/icons/plus.svg';
import langStore from 'globalState/lang';


export default class ZoomPanel extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidCatch(error, info) {
        console.error(error, info);
    }

    render() {
        const { workflow_titles } = langStore.getTranslate();
        return (
            <div className={ `${ styles.ZoomPanelsContainer } ${ this.props.isToolsOpened ? styles.ZoomWithTools : '' }` }>
                <div className={ styles.ZoomPanel }>
                    <div className={ styles.Icon } dangerouslySetInnerHTML={ { __html: IconMinus } } onClick={ this.props.decreaseZoom } />
                    <div className={ `${ styles.ZoomPanelText } ${ styles.ZoomPanelRatio }` } onClick={ this.props.resetZoom }>{ `${ this.props.zoom * 100 }%` }</div>
                    <div className={ styles.Icon } dangerouslySetInnerHTML={ { __html: IconPlus } } onClick={ this.props.increaseZoom } />
                </div>
                <div className={ styles.ZoomPanel } onClick={ this.props.adjustZoom }>
                    <div className={ styles.Icon } dangerouslySetInnerHTML={ { __html: IconFitToScreen } } />
                    <div className={ styles.ZoomPanelText } >{ workflow_titles && workflow_titles.zoom_all }</div>
                </div>
            </div>
        );
    }
}