import BaseFormFieldModel from './BaseFormFieldModel';
import { isEmptyPhoto } from 'helpers/isEmpty';
import { isChanged } from 'helpers/form';

export default class ImageModel extends BaseFormFieldModel {

    /**
     * Форма?
     * form
     *
     * @type {*}
     */
    form;

    /**
     * Сообщение
     * message
     *
     * @type {*}
     */
    message;

    columnType = 'image';

    constructor(data, parentFormSectionModel) {
        super(data, parentFormSectionModel);
        super.mergeData(data);
    }

    uiGetValue() {
        return this.value && this.value.sys_id;
    }

    uiSetValue(value) {
        if (value === '') {
            return this.uiClearValue();
        }

        this.value.sys_id = value;
        this.changed = isChanged(this.defaultValue, this.value);
    }

    checkEmptyValue() {
        return isEmptyPhoto(this.value);
    }

    getValueForSave(){
        return this.value && this.value.sys_id;
    }
}
