import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import { Link } from 'react-router-dom';
import GlobalPortal from 'components/globalPortal';
import debuggerState from 'globalState/debugger';
import { withRouter } from 'react-router';
import styles from './styles.module.scss';
import IconUp from 'assets/img/icons/chevron-up.svg';
import IconX from 'assets/img/icons/close-x.svg';


class Debugger extends React.Component {

    isHide = true;
    activeTab = 0;

    constructor(props) {
        super(props);

        makeObservable(this, {
            isHide: observable,
            activeTab: observable,
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    componentDidCatch(error, info) {
        console.error(error, info);
    }

    onRouteChanged() {
        debuggerState.data = [];
        this.activeTab = 0;
    }

    toggle = () => {
        this.isHide = !this.isHide;
    };


    renderHeader() {
        return (
            <thead>
            <tr>
                <th width="200">
                    Timing type
                </th>
                <th width="40">
                    Total time
                </th>
                <th width="40">
                    Start time
                </th>
                <th width="40">
                    Stop time
                </th>
                <th width="40">
                    Memory
                </th>
                <th width="45">
                    DB time
                </th>
                <th width="40">
                    DB requests
                </th>
                <th width="40">
                    Count
                </th>
                <th>
                    Waterfall
                </th>
            </tr>
            </thead>
        );
    }

    renderRow(data, index) {
        return (
            <tr className={ styles['DebuggerRowNesting' + data.timing_type.nesting] } key={ index }>
                <td
                    style={ {
                        textIndent: `${ data.timing_type.nesting * 5 }px`,
                    } }>
                    { data.timing_type.name }
                </td>
                <td>
                    { data.total_time }
                </td>
                <td>
                    { data.start_time }
                </td>
                <td>
                    { data.stop_time }
                </td>
                <td>
                    { data.memory }
                </td>
                <td>
                    { data.db_time }
                </td>
                <td>
                    { data.db_requests }
                </td>
                <td>
                    { data.count }
                </td>
                <td>
                    <div className={ styles.DebuggerRowProgress }
                         style={ {
                             left: `${ data.waterfall.start_percent }%`,
                             width: `${ data.waterfall.width }%`,
                         } } />
                </td>
            </tr>
        );
    }


    tabs({ timing_breakdown, total_data }) {
        return (
            <>
                <div className={ styles.DebuggerHeader } onClick={ this.toggle }>
                    Time: { total_data.time }, DB: { total_data.db.requests } ({ total_data.db.time }), Memory: { total_data.memory }
                    {
                        this.isHide ? null : <div className={ styles.DebuggerURL }>
                            <Link to={ total_data.url }>{ total_data.url }</Link>
                        </div>
                    }
                    <span className={ styles.DebuggerLink } dangerouslySetInnerHTML={ { __html: this.isHide ? IconUp : IconX } } />
                </div>
                <div className={ styles.DebuggerBody }>
                    <table className={ styles.DebuggerTable }>
                        { this.renderHeader() }
                        <tbody>
                        {
                            timing_breakdown.map((data, index) => {
                                return this.renderRow(data, index);
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </>
        );
    }

    render() {
        if (!debuggerState.isActive()) {
            return null;
        }

        const classNames = [ styles.Debugger ];
        if (this.isHide) {
            classNames.push(styles.DebuggerCollapsed);
        }

        const titles = [];
        const tabs = [];
        debuggerState.data.forEach((data, index) => {
            const titleClass = [ styles.DebuggerTabsTitleItem ];
            const tabClass = [ styles.DebuggerTabsItem ];
            if (this.activeTab === index) {
                titleClass.push(styles.DebuggerTabsTitleItemActive);
                tabClass.push(styles.DebuggerTabsItemActive);
            }

            titles.push(<div className={ titleClass.join(' ') } onClick={ () => this.activeTab = index }>
                { data.total_data.url.replace(/\?.*/gi, '') }
            </div>);

            tabs.push(<div className={ tabClass.join(' ') } key={ index }>
                { this.tabs(data) }
            </div>);
        });

        return (
            <GlobalPortal>
                <div className={ classNames.join(' ') }>
                    {
                        this.isHide ? null : <div className={ styles.DebuggerTabsTitle }>
                            { titles }
                        </div>
                    }
                    <div className={ styles.DebuggerTabs }>
                        { tabs }
                    </div>
                </div>
            </GlobalPortal>
        );
    }
}

export default withRouter(observer(Debugger));
