import * as React from 'react';
import { observer } from 'mobx-react';

import langStore from 'globalState/lang';


class Crumb extends React.Component {
    isSameOptions = () => {
        const { item } = this.props;
        return item.getOperator() && [
            'SAMEAS',
            'NSAMEAS',
            'GT_FIELD',
            'LT_FIELD',
            'GT_OR_EQUALS_FIELD',
            'LT_OR_EQUALS_FIELD',
        ].includes(item.getOperator().database_value);
    };

    isSorting = (item) => {
        return item.getId() && item.getId().includes('sorting');
    };

    isGrouping = (item) => {
        return item.getId() && item.getId().includes('grouping');
    };

    getArrayValue = (value, result) => {
        let resultValue = result;
        value.forEach(v => {
            if (Array.isArray(v)) {
                resultValue = this.getArrayValue(v, resultValue);
            }
            else {
                resultValue += !resultValue ? v.value || v.display_value || v : `, ${ v.value || v.display_value || v }`;
            }
        });
        return resultValue;
    };

    getValue = (value) => {
        const { data, item } = this.props;
        const { bool } = langStore.getTranslate();
        if (!value) {
            return '';
        }
        if (item.getOperator() && item.getOperator().database_value && this.isSameOptions()) {
            if (value && value.display_value) {
                return value.display_value;
            }
            const filteredData = data.find(field => field.dot_walking_attribute === value);
            return filteredData ? filteredData.column_title : value;
        }
        let resultValue = '';
        if (Array.isArray(value)) {
            resultValue = this.getArrayValue(value, resultValue);
        }
        else if (typeof value.display_value !== 'undefined') {
            resultValue = value.display_value;
        }
        else if (item.getField().column_type === 'boolean') {
            resultValue = (value === '1') ? (bool && bool.yes) : (bool && bool.no);
        }
        else {
            resultValue = value;
        }
        return resultValue;
    };

    render() {
        const { item, delimeter } = this.props;
        const { filter_titles } = langStore.getTranslate();
        if (!item) {
            return null;
        }
        let value;
        if (this.isSorting(item)) {
            value = (item.getDisplayValue().is_dwa ?  '... ' : '')
                + (item.getDisplayValue().display_column_name ? item.getDisplayValue().display_column_name : item.getField().column_title)
                + delimeter + item.getDirection();
        }
        if (this.isGrouping(item)) {
            value = (item.getDisplayValue().is_dwa ? '... ' : '')
                + filter_titles.group + delimeter +(item.getDisplayValue().display_column_name ? item.getDisplayValue().display_column_name : item.getField().column_title);
        }
        else {
            value = (item.getField().column_title || item.getField().dot_walking_attribute)
                + delimeter + (item.getOperator().display_value || item.getOperator()) +
                delimeter + (this.getValue(item.getValue()));
        }

        return <span title={ value }>{ value }</span>;
    }
}

export default observer(Crumb);