import React from 'react';
import Dropdown from 'components/dropdown';
import IconUnion from 'assets/img/icons/union.svg';
import IconCheck from 'assets/img/icons/check.svg';
import styles from './styles.module.scss';
import Button from 'components/button';
import {makeObservable, observable} from 'mobx';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { INumberOfElementsProps } from 'types/components/numberOfElements';
import { savePreference } from 'actions/preferences';
import { ATTRIBUTES } from 'constants/attributesForTests';
import globalTableState from 'globalState/table';
import { checkAndRedirectPage } from 'helpers/tableHelpers';

class NumberOfElements extends React.Component<INumberOfElementsProps> {
    isShow = false;
    current = '';
    refParent = React.createRef<HTMLDivElement>();
    refDropdown = React.createRef<HTMLDivElement>();
    refMenu = React.createRef<HTMLDivElement>();

    constructor(props) {
        super(props);

        makeObservable(this, {
            isShow: observable,
            current: observable,
        });
    }

    componentDidMount() {
        document.addEventListener('click', this.onDocumentClick);
        window.addEventListener('scroll', this.onWindowScroll);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onDocumentClick);
        window.removeEventListener('scroll', this.onWindowScroll);
    }

    onDocumentClick = (e) => {
        const dropdownEl = this.refDropdown ? this.refDropdown.current : null;
        const parentEl = this.refParent.current;
        if (!dropdownEl || !parentEl) return false;

        if (!dropdownEl.contains(e.target) && !parentEl.contains(e.target)) {
            this.isShow = false;
        }
    };

    onWindowScroll = () => {
        this.isShow = false;
    };

    handleClick = () => {
        this.isShow = !this.isShow;
    }

    handleClickItem = (item) => async () => {
        const { type, name, doFetch, chosenOptionsState } = this.props;
        this.isShow = false;
        this.current = '';
        chosenOptionsState?.setChosenOption(item.database_value);
        chosenOptionsState?.setIsNotFetch(true);

        const groupsPagination = globalTableState.getCurrentPagination();

        checkAndRedirectPage({
            perPage: item.database_value,
            total: groupsPagination?.pageTotal,
        });

        doFetch(false, item.database_value);
        await savePreference(`${ type }.${ name }.per_page`, item.database_value);
        chosenOptionsState?.setIsNotFetch(false);
    }

    handleMouseEnter = (item) => () => {
        this.current = item.database_value;
    }

    handleMouseLeave = () => {
        this.current = '';
    }

    renderList = () => {
        const { chosenOptionsState } = this.props;
        if (!this.isShow || !chosenOptionsState) {
            return null;
        }
        const perPage = chosenOptionsState.getChosenOption();
        const items = _.map(chosenOptionsState?.getListChosenOptions(), (item) => {
            return (
                <div
                    className={ `${ styles.MenuItem } ${ this.current === item.database_value ? styles.Focus : '' }` }
                    key={ item.database_value }
                    onClick={ this.handleClickItem(item) }
                    onMouseEnter={ this.handleMouseEnter(item) }
                    onMouseLeave={ this.handleMouseLeave }
                    data-test={ ATTRIBUTES.customSelectDropdownItem }
                >
                    <div>
                        { item.display_value }
                    </div>
                    { perPage === item.database_value ?
                        (
                            <div dangerouslySetInnerHTML={ { __html: IconCheck } } className={ styles.MenuItemIcon } />
                        )
                        : null
                    }

                </div>
            );
        });
        return (
            <Dropdown refParent={ this.refParent } ref={ this.refDropdown } disableMinWidth>
                <div
                    className={ styles.Menu }
                    ref={ this.refMenu }
                    data-test={ ATTRIBUTES.numberOfElementsMenu }
                >
                    { items }
                </div>
            </Dropdown>
        );
    }

    render() {
        const { totalString, chosenOptionsState, isHideButtonList } = this.props;
        const isHideButton = isHideButtonList || !chosenOptionsState || _.isEmpty(chosenOptionsState?.getListChosenOptions());
        const hint = `${ chosenOptionsState?.getHintText() }${ chosenOptionsState?.getChosenOption() ? `: ${chosenOptionsState?.getChosenOption()}` : '' }`;

        const button = !isHideButton ? (
            <Button
                buttonType='icon'
                onClick={ this.handleClick }
                key="button"
                svg={ IconUnion }
                hint={ hint }
            />
        ) : null;

        return (
            <div className={ styles.Wrap }>
                <div
                    className={ styles.ElementsBlock }
                    ref={ this.refParent }
                    data-test={ ATTRIBUTES.numberOfElements }
                >
                    { button }
                    <div className={ styles.ElementsText } data-test={ ATTRIBUTES.tablePaginationTotalItems }>
                        { totalString }
                    </div>
                </div>
                { this.renderList() }
            </div>
        );
    }
}

export default withRouter<INumberOfElementsProps, any>(observer(NumberOfElements));
