import * as React from 'react';
import { observer } from 'mobx-react';

import styles from 'components/portalDesigner/styles.module.scss';
import IconWidget from 'assets/img/icons/more-horizontal-kebab.svg';

import SysWidgetStorage from 'components/portalDesigner/storage/sysWidget';
import DragAndDrop from 'components/portalDesigner/components/dragAndDrop';
import PageData from 'components/portalDesigner/state/pageData';

class Widgets extends React.Component {

    constructor(props) {
        super(props);
    }

    onClick = () => {
        // @todo добавить в рамках задачи по добавлению виджетов BIT0037320
    };


    render() {
        const widgets = SysWidgetStorage.filterByName(this.props.filterValue);

        return (
            <div className={ styles.List }>
                <div className={ styles.ListMenuTitle }>
                    Widgets
                </div>
                <ul className={ styles.ListMenu }>
                    {
                        widgets.map((widget, index) => {
                            return <DragAndDrop key={ index } sysId={ widget.sys_id  }  type={ PageData.TYPE_WIDGET_INSTANCE } isNew>
                                <li key={ widget.sys_id } onClick={ this.onClick(widget) }>
                                    <span className={ styles.Icon } dangerouslySetInnerHTML={ { __html: IconWidget } } />
                                    { widget.name }
                                </li>
                            </DragAndDrop>;
                        })
                    }
                </ul>
            </div>
        );
    }
}

export default observer(Widgets);
