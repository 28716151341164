import widgetsData from 'globalState/widgets';
import apiRequest from 'lib/apiRequest';
import { parseToJson } from 'helpers/data';

const getWidget = (id) => {
    const widget = widgetsData.getWidget(id);
    if (!widget) {
        return null;
    }
    return widget;
};

export default class SimpleWidget {
    // инициализация
    constructor(widgetId, isUpdate, params) {
        if (isUpdate) {
            this.serverUpdate(widgetId, params);
        }
    }

    // получение id виджета
    getId = (widgetId) => {
        return widgetId;
    };

    // вывод data в console
    showData = (widgetId) => {
        const widget = getWidget(widgetId);
        if (!widget) {
            return;
        }
        // eslint-disable-next-line no-console
        console.log(widget.getData());
    };

    // уствновка значения s_widget.setFieldValue(key, value)
    setFieldValue = (widgetId, key, value) => {
        const widget = getWidget(widgetId);
        if (!widget) {
            return;
        }
        widget.setFieldValue(key, value);
    };

    // получение значения s_widget.getFieldValue(key)
    getFieldValue = (widgetId, key) => {
        const widget = getWidget(widgetId);
        if (!widget) {
            return null;
        }
        return widget.getFieldValue(key);
    };

    // уствновка значения s_widget.setOptionValue(key, value)
    setOptionValue = (widgetId, key, value) => {
        const widget = getWidget(widgetId);
        if (!widget) {
            return;
        }
        widget.setOptionValue(key, value);
    };

    // получение значения s_widget.getOptionValue(key)
    getOptionValue = (widgetId, key) => {
        const widget = getWidget(widgetId);
        if (!widget) {
            return;
        }
        return widget.getOptionValue(key);
    };

    // обмен информацией с сервером s_widget.serverUpdate()
    serverUpdate = async (widgetId, params) => {
        const widget = getWidget(widgetId);
        if (!widget) {
            return;
        }
        const sendObj = params ? { ...widget.getData(), ...params } : widget.getData();
        const response = await new apiRequest('POST /widget/run-server-script/' + widgetId.replace(/modal/, '')).sendJSON(sendObj);
        const data = response && response.getData ? response.getData() : undefined;
        if (data && data.data) {
            widget.setFieldValues(data.data);
        }
        return response;
    };

    // получение иерархии элементов в react virtual dom
    getElements = (widgetId) => {
        const widget = getWidget(widgetId);
        if (!widget) {
            return;
        }
        const elements = widget.getElements();
        return elements ? parseToJson(elements) : null;
    };

    // добавление внутреннего template в текущий template
    addTemplate = (widgetId, id, template, script, type) => {
        const widget = getWidget(widgetId);
        if (!widget) {
            return;
        }
        widget.addTemplate(id, template, script, type);
    };

    // удаление template по id
    removeTemplate = (widgetId, id) => {
        const widget = getWidget(widgetId);
        if (!widget) {
            return;
        }
        widget.removeTemplate(id);
    };
}
