import { observable, makeObservable } from 'mobx';
import { getUrlParams } from 'helpers/data';
import { fetchWidgetMenuList } from 'actions/widgets';
import EventBusState from 'globalState/eventBus';
import { helperRedirect } from 'helpers/history';
import _ from 'lodash';
import { eventType } from 'constants/eventBusTypes';
import { hasElementsSamePage, menuType } from 'helpers/widget';

class SideMenuState {
    data = [];
    expandedElements = [];
    currentElement = {};
    currentUrl = '';

    constructor() {
        makeObservable(this, {
            data: observable,
            expandedElements: observable,
            currentElement: observable,
            currentUrl: observable,
        });
    }

    onNavigate() {
        // Подписываемся на событие
        const result = EventBusState.on(eventType.NAVIGATE, this.onElementChange);
        this.unSubscribe = result.unsubscribe;
    }

    setData(data) {
        this.data = data;
    }

    getData() {
        return this.data;
    }

    getCurrentUrl() {
        return this.currentUrl;
    }

    /**
     * Метод срабатываает при получнии события о смене элемента
     * @param data - данные события
     */
    onElementChange = (data) => {
        this.countCurrentUrl(this.urlSuffix, this.pathName, data.category_id, data.item_id);
        this.expandCurrentElement(this.data);
    };

    /**
     * Метод срабатывает при смене текущего элемента
     * @param element - новый элемент
     */
    beforeElementChange(element) {
        return () => {
            // Генерируем событие, если произошёл переход между элементами, у которых гарантированно один page_template
            if (hasElementsSamePage(this.currentElement, element)) {
                const isRedirect = helperRedirect(element.url, null, { preventReload: true }); // Предотвращаем перезагрузку страницы
                if(!isRedirect) return;
                this.currentUrl = element.url;
                this.currentElement = element;
                EventBusState.emit(eventType.NAVIGATE,
                    element.type === menuType.NODE ?
                        { category_id: null } :
                            element.type === menuType.CATEGORY ?
                            { category_id: element.id } :
                            { item_id: element.id },
                );
            }
            else {
                helperRedirect(element.url);        // Переход с полной перерисовкой страницы
            }
        };
    }

    /**
     * Метод подготавливает параметры и получает данные
     * @param params - пользовательские параметры
     * @param location - текущий location
     */
    async fetchWidgetMenu(params, location, element = null) {
        const arrPath = _.trim(location.pathname, '/').split('/');
        this.urlSuffix = arrPath[0];
        this.pathName = arrPath[1];
        const searchParams= getUrlParams(location.search);

        const fetchParams = _.omitBy({
            url_suffix: this.urlSuffix,
            path_name: this.pathName,
            children_depth: params.childrenDepth,
            parents_depth: params.parentsDepth,
            include_categories: params.includeCategories,
            include_items: params.includeItems,
            parent_category_id: params.parentCategoryId,
            short_data: searchParams.category || searchParams.item ? '0' : '1',
        }, _.isEmpty);
        this.countCurrentUrl(this.urlSuffix, this.pathName, searchParams.category, searchParams.item);
        const data = await fetchWidgetMenuList(fetchParams);
        if (data) {
            if (!params.parentCategoryId) {
                this.data = this.prepareData(data, menuType.NODE);
                this.expandCurrentElement(this.data);
            } else {
                const children = [];
                children.push(...this.prepareData(data[0].sub_menu, menuType.NODE));
                children.push(...this.prepareData(data[0].categories, menuType.CATEGORY, element.nodeId));
                children.push(...this.prepareData(data[0].items, menuType.ITEM, element.nodeId));
                element.children = children;
            }
        }
    }

    /**
     * Метод получения текущих элементов
     * @param data - исходные данные
     * @returns {*[]}
     */
    getCurrentItems(data) {
        let items = [];
        _.some(data, element => {
            if (this.currentUrl === element.url) {
                items = element.children;
                return true;
            } else if (this.getCurrentItems(element.children).length > 0) {
                items = this.getCurrentItems(element.children);
                return true;
            }
        });
        return items;
    }

    /**
     * Метод преобразует данные для последующей отрисовки
     * @param data - исходные данные
     * @param type - тип текущего элемента
     * @param nodeId - portal_node, дочерним элементом которого является текущий элемент
     * @returns {[]} - массив пунктов меню
     */
    prepareData(data, type, nodeId = null) {
        const newData = [];
        _.forEach(data, element => {
            nodeId = type === menuType.NODE ? element.map_id : nodeId;
            const children = [];
            children.push(...this.prepareData(element.sub_menu, menuType.NODE));
            children.push(...this.prepareData(element.categories, menuType.CATEGORY, nodeId));
            children.push(...this.prepareData(element.items, menuType.ITEM, nodeId));
            newData.push({
                url: element.url,
                type: type,
                id: type === menuType.NODE ? element.map_id : element.sys_id,
                title: type === menuType.NODE ? element.name : element.title_or_id,
                children,
                nodeId,
            });
        });
        return newData;
    }

    /**
     * Метод устанавливает url для поиска текущего элемента
     * @param urlSuffix - urlSuffix
     * @param pathName - pathName
     * @param categoryId - id категории
     * @param itemId - id айтема
     */
    countCurrentUrl(urlSuffix, pathName, categoryId, itemId) {
        let url = `/${ urlSuffix }/${ pathName }`;
        if (itemId) {
            url += '?item=' + itemId;
        }
        else if (categoryId) {
            url += '?category=' + categoryId;
        }
        this.currentUrl = url;
    }

    /**
     * Метод рекурсивно перебирает все пункты меню, находит с текущим url
     * и добавлят все родительские пункты к списку развёрнутых
     * @param data - списов пунктов меню
     * @returns {boolean} - есть ли внутри искомый пункт
     */
    expandCurrentElement(data) {
        return _.some(data, element => {
            if (this.currentUrl === element.url) {
                this.currentElement = _.pick(element, [
                    'type',
                    'nodeId',
                    'id',
                ]);
                return true;
            }
            else if (this.expandCurrentElement(element.children)) {
                const expandedElement = _.pick(element, [
                    'type',
                    'nodeId',
                    'id',
                ]);
                if (!_.includes(this.expandedElements, expandedElement)) {
                    this.expandedElements.push(expandedElement);
                }
                return true;
            }
            return false;
        });
    }

    /**
     * Метод сворачивает элемент, если он развёрнут, и наоборот
     * @param element -
     * @returns { Function }
     */
    toogleElement = (element, params, location) => () => {
        if (this.isExpanded(element)) {
            _.remove(this.expandedElements, e => this.equals(element, e));
        } else {
            this.fetchWidgetMenu({
                ...params,
                parentCategoryId: element.id,
            }, location, element);
            this.expandedElements.push(_.pick(element, [
                'type',
                'nodeId',
                'id',
            ]));
        }
    };

    isCurrent(element) {
        return this.equals(element, this.currentElement);
    }

    isExpanded(element) {
        return _.some(this.expandedElements, e => this.equals(element, e));
    }

    equals(element1, element2) {
        return element1.type === element2.type &&
            element1.nodeId === element2.nodeId &&
            element1.id === element2.id;
    }

    /**
     * Метод отписывается от события и очищает данные
     */
    clearState() {
        this.unSubscribe();
        this.data = [];
        this.expandedElements = [];
        this.currentElement = {};
    }
}

export const sideMenuState = new SideMenuState();

export default SideMenuState;
