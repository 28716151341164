import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import userState from 'globalState/user';
import { helperRedirect, smartRedirect } from 'helpers/history';
import langStore from 'globalState/lang';
import InfoMessagesState from 'globalState/infoMessages';
import styles from 'components/login/styles.module.scss';
import SmartTitle from 'components/smartTitle';
import Select from 'components/login/select';
import Help from 'components/login/help';
import LoginInput from 'components/login/input';
import LoginButton from 'components/login/button';
import FormServerErrors from 'components/formItems/serverErrors';
import ResetPassword from 'components/portalWidgetsComponents/AuthFull/ResetPassword';
import FormValidator from 'helpers/formValidator';
import SignUp from 'components/portalWidgetsComponents/AuthFull/SignUp';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { fetchSsoInfo, fetchAuthTranslateData } from 'actions/auth';

/**
 * Props:
 * redirectTo - Куда перекинуть в случае успешного логина
 * isRegistration: {type: boolean, default: false} - Регистрация
 * isSwitchLang: {type: boolean, default: true} - Переключение языков
 * logo: {type: string, default: ''} - Логотип
 * isRememberMe: {type: boolean, default: false} - Запомнить меня
 * isResetPassword: {type: boolean, default: false} - Восстановление пароля (булево, ложь)
 * useSourceUrl: {type: boolean, default: false} - использовать redirect из localStorage (булево, ложь)
 * usernameValidation: {type: string} - Настраиваемая валидация данных в строке с именем пользователя
 * passwordValidation: {type: string} - Настраиваемая валидация данных в строке с паролем
 */
class AuthFull extends React.Component {
    formFields = {
        username: FormValidator.createFormFieldObj(),
        password: FormValidator.createFormFieldObj(),
    };
    serverError = '';
    isRegistration = false;
    isResetPassword = false;
    isSwitchLang = true;
    isRememberMe = false;
    langData = {};
    isFetching = false;
    redirectTo = '/';
    rememberMe = 0;
    showResetPassword = false;
    showRegistration = false;

    constructor(props) {
        super(props);

        makeObservable(this, {
            formFields: observable,
            serverError: observable,
            isRegistration: observable,
            isResetPassword: observable,
            isSwitchLang: observable,
            isRememberMe: observable,
            langData: observable,
            isFetching: observable,
            redirectTo: observable,
            rememberMe: observable,
            showResetPassword: observable,
            showRegistration: observable,
        });

        this.redirectTo = props.redirectTo !== undefined ? props.redirectTo : this.redirectTo;
        this.isRegistration = props.isRegistration !== undefined ? props.isRegistration : this.isRegistration;
        this.isResetPassword = props.isResetPassword !== undefined ? props.isResetPassword : this.isResetPassword;
        this.isRememberMe = props.isRememberMe !== undefined ? props.isRememberMe : this.isRememberMe;
        this.isSwitchLang = props.isSwitchLang !== undefined ? props.isSwitchLang : this.isSwitchLang;
        this.redirectAuthorized();
        if (!userState || !userState.authorized) {
            this.fetchSsoInfo().catch(console.error);
            this.fetchTranslate().catch(console.error);
        }
    }

    componentDidUpdate(prevProps) {
        const { redirectTo, isRegistration, isResetPassword, isRememberMe, isSwitchLang } = this.props;
        if (redirectTo !== prevProps.redirectTo) {
            this.redirectTo = redirectTo;
        }
        if (isRegistration !== prevProps.isRegistration) {
            this.isRegistration = isRegistration;
        }
        if (isResetPassword !== prevProps.isResetPassword) {
            this.isResetPassword = isResetPassword;
        }
        if (isRememberMe !== prevProps.isRememberMe) {
            this.isRememberMe = isRememberMe;
        }
        if (isSwitchLang !== prevProps.isSwitchLang) {
            this.isSwitchLang = isSwitchLang;
        }
    }

    // Метод проверяет наличие флага, и редиректит либо на SSO урл либо на WelcomePage
    fetchSsoInfo = async () => {
        const { data: {enabled, ssoLoginUrl}, isOkStatus } = await fetchSsoInfo();
        // Флаг для определения возвращались ли мы на текущую страницу, убрать цикличный редирект

        if (isOkStatus) {
            userState.ssoEnabled = enabled;
            if (enabled && ssoLoginUrl) {
                helperRedirect(ssoLoginUrl);
            }
        }
    };

    fetchTranslate = async (choseLange) => {
        this.serverError = '';
        let lang = '';

        if (choseLange) {
            lang = `/${ choseLange }`;
        }
        else {
            const _lang = window.localStorage.getItem('lang');
            lang = _lang ? `/${ _lang }` : '';
        }

        const { isOkStatus, data: langData } = await fetchAuthTranslateData(lang);
        if (isOkStatus) {
            const langValue = langData.language;
            const langSpecial = langData.special;
            this.langData = {
                langValue,
                langSpecial,
            };
            window.localStorage.setItem('lang', langValue);
        } else {
            InfoMessagesState.pushError(`Failed to load translation data from server`);
        }
    };

    redirectAuthorized = () => {
        if (userState && userState.authorized) {
            helperRedirect(this.redirectTo);
        }
    };

    handleValueChange = (e) => {
        const { name, value } = e.target;
        this.formFields[name].value = value;
        this.formFields[name].errorMessage = '';
    };

    handleChangeRememberMe = ({ value }) => {
        this.rememberMe = value;
    };

    onFormKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.submit().catch(console.error);
        }
    };

    submit = async () => {
        if (this.isFetching) {
            return;
        }
        this.isFetching = true;

        const fv = new FormValidator(this.formFields);
        /*const promises = [];

         // Validate username
         promises.push(
         fv.validateField(this.formFields.username, () => {
         //if (val.length < 3) return `Login must contain not less than 3 symbols`;
         //if (val === '111') return `This login not allowed`;
         }),
         );

         // Validate password
         promises.push(
         fv.validateField(this.formFields.password, () => {
         //if (val.length < 3) return `Password must contain not less than 3 symbols`;
         }),
         );

         // Await for validations
         await Promise.all(promises);*/

        const isValid = fv.isFieldsValid();

        if (!isValid) {
            this.isFetching = false;
            return;
        }

        try {
            const data = fv.getFieldsData();
            data.language = this.langData.langValue;
            const result = await userState.login(data);
            if (result) {
                InfoMessagesState.removeAll();
                this.redirect();
            }
        }
        catch (error) {
            const errorsParsed = fv.applyServerValidationErrors(error.response.data);
            if (!errorsParsed) {
                this.serverError = fv.serverErrorMessage || error.message;
            }
        }
        finally {
            this.isFetching = false;
        }
    };

    redirect = () => {
        if (this.redirectTo === '/') {
            smartRedirect(this.redirectTo);
        } else {
            const redirectTo = window.localStorage.getItem('redirect');
            if (this.props.useSourceUrl && redirectTo) {
                window.localStorage.removeItem('redirect');
                helperRedirect(redirectTo);
            } else {
                helperRedirect(this.redirectTo);
            }
        }
    };

    handleToggleResetPassword = () => {
        this.showResetPassword = !this.showResetPassword;
    };

    handleToggleRegistration = () => {
        this.showRegistration = !this.showRegistration;
    };

    renderResetPassword = () => {
        if (!this.isResetPassword) {
            return null;
        }
        const { login_page } = langStore.getTranslate();
        return (
            <div>
                <span
                    className={ styles.Link }
                    onClick={ this.handleToggleResetPassword }
                    data-test={ ATTRIBUTES.linkResetPassword }
                >
                    { login_page && login_page.reset_link }
                </span>
            </div>
        );
    };

    renderRegistration = () => {
        if (!this.isRegistration) {
            return null;
        }
        const { login_page } = langStore.getTranslate();
        return (
            <div>
                <span
                    className={ styles.Link }
                    onClick={ this.handleToggleRegistration }
                    data-test={ ATTRIBUTES.linkSignUp }
                >
                    { login_page && login_page.sign_up_link }
                </span>
            </div>
        );
    };

    renderLogo = () => {
        const { logo } = this.props;
        if (!logo) {
            return null;
        }
        return (
            <div className={ styles.Logo }>
                <img src={ logo } />
            </div>
        );
    };

    renderSwitchLang = () => {
        if (!this.isSwitchLang) {
            return null;
        }
        return (
            <Select
                onChange={ this.fetchTranslate }
                databaseValue={ this.langData.langValue }
                options={ this.langData.langSpecial }
            />
        );
    };

    render() {
        const { login_page, help, placeholders } = langStore.getTranslate();
        const { logo, className } = this.props;
        if (this.showRegistration) {
            return (
                <SignUp
                    onToggleRegistration={ this.handleToggleRegistration }
                    onFetchTranslate={ this.fetchTranslate }
                    langData={ this.langData }
                    isSwitchLang={ this.isSwitchLang }
                    logo={ logo }
                    onRedirect={ this.redirect }
                />
            );
        }
        if (this.showResetPassword) {
            return (
                <ResetPassword
                    onTogglePassword={ this.handleToggleResetPassword }
                    onFetchTranslate={ this.fetchTranslate }
                    langData={ this.langData }
                    logo={ logo }
                    isSwitchLang={ this.isSwitchLang }
                />
            );
        }

        return (
            <div
                className={ `${ styles.Form } ${ className || '' }` }
                onKeyPress={ this.onFormKeyPress }
                data-test={ this.props['data-test'] ? this.props['data-test'] : `authfull-${ ATTRIBUTES.widget }` }
            >

                <SmartTitle hidden>{ login_page ? login_page.title : '' }</SmartTitle>
                { this.renderSwitchLang() }
                <Help
                    title={ help && help.title }
                    children={ help && help.content }
                />
                { this.renderLogo() }
                <div className={ styles.Field }>
                    <LoginInput
                        placeholder={ placeholders && placeholders.username }
                        name="username"
                        onChange={ this.handleValueChange }
                        msg={ this.formFields.username.errorMessage }
                        autoFocus
                        isDisabled={ this.isFetching }
                        data-test={ `username-${ ATTRIBUTES.loginField }` }
                    />
                </div>
                <div className={ styles.Field }>
                    <LoginInput
                        placeholder={ placeholders && placeholders.password }
                        type="password"
                        name="password"
                        onChange={ this.handleValueChange }
                        isDisabled={ this.isFetching }
                        msg={ this.formFields.password.errorMessage }
                        data-test={ `password-${ ATTRIBUTES.loginField }` }
                    />
                </div>
                <div className={ styles.Field }>
                    <LoginButton
                        isLoading={ this.isFetching }
                        onClick={ this.submit }
                        buttonType="primary"
                        data-test={ ATTRIBUTES.loginButton }
                        disabled={ !this.formFields.username.value || !this.formFields.password.value }
                    >
                        { login_page && login_page.submit_button }
                    </LoginButton>
                </div>
                <FormServerErrors msg={ this.serverError } />
                <div className={ `${ styles.LinksField } ${ this.isResetPassword !== this.isRegistration ? styles.alignCenter : '' }` }>
                    { this.renderResetPassword() }
                    { this.renderRegistration() }
                </div>
            </div>
        );
    }
}

export default observer(AuthFull);
