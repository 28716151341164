import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import styles from './styles.module.scss';
import Dropdown from 'components/dropdown';
import ChevronRight from 'assets/img/icons/chevron-right.svg';
import { runScript } from 'helpers/scriptClientHelper';
import DecorationElement from 'components/portalWidgetsComponents/DropdownWidget/DecorationElement';
import { ATTRIBUTES } from 'constants/attributesForTests';

/**
 * Виджет выпадающего списка (обертка)
 * Props:
 * @param [array] elements - элементы
 * @param {ref} refParent - ссылка на родителя
 * @param {object} [styleMenu] - объект со стилями для меню
 * @param {boolean} isShow
 * @method onClose
 * @method onMouseOver
 * @method onMouseOut
 */
class CategoryDropdown extends React.Component {
    isShow = false;
    isOpenCategory = -1;
    refDropdown = React.createRef();
    refElements = [];
    refSubDropdowns = [];
    isMouseOver = false;
    isSubMouseOver = false;
    timeout = null;

    constructor(props) {
        super(props);

        makeObservable(this, {
            isShow: observable,
            isOpenCategory: observable,
            isMouseOver: observable,
            isSubMouseOver: observable,
            timeout: observable,
        });

        this.isShow = props.isShow || this.isShow;
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        this.props.addSubDropdown(this.refDropdown);
    }

    componentDidUpdate(prevProps) {
        const { isShow } = this.props;
        if (isShow !== prevProps.isShow) {
            this.isShow = isShow;
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        clearTimeout(this.timeout);
    }

    handleClickOutside = (e) => {
        const { refParent, onClose } = this.props;
        const { current: el } = refParent;
        const { current: parentEl } = this.refDropdown;

        if (!el || !parentEl) {
            return;
        }

        let isContain = false;
        _.forEach(this.refSubDropdowns, element => {
            if (element && element.current && element.current.contains(e.target)) {
                isContain = true;
            }
        });
        if (!el.contains(e.target) && !parentEl.contains(e.target) && !isContain) {
            this.isShow = false;
            this.isOpenCategory = -1;
            this.refSubDropdowns = [];
            if (onClose) {
                onClose();
            }
        }
    };

    addSubDropdown = (refDropdown) => {
        this.refSubDropdowns.push(refDropdown);
        this.props.addSubDropdown(refDropdown);
    };

    handleChevronMouseOver = (element, index) => () => {
        const { onMouseOver } = this.props;
        if (onMouseOver) {
            onMouseOver();
        }
        if (element.event_to_display && _.includes(element.event_to_display, 'mouseover')) {
            clearTimeout(this.timeout);
            this.isOpenCategory = index;
            this.isMouseOver = true;
        }
        const conditionRunScript = element.event_to_display
            && _.includes(element.event_to_display, 'mouseover')
            && element.events && element.events.mouseover;
            
        if (conditionRunScript) {
            runScript(element.events.mouseover);
        }
    };

    handleChevronMouseOut = (element) => () => {
        if (element.event_to_display && _.includes(element.event_to_display, 'mouseover')) {
            this.isMouseOver = false;
            this.handleHideSubMenu();
        }
    };

    handleCloseCategory = () => {
        this.isOpenCategory = -1;
    };

    handleSubDropdownMouseOver = () => {
        clearTimeout(this.timeout);
        this.isSubMouseOver = true;
    };

    handleSubDropdownMouseOut = () => {
        this.isSubMouseOver = false;
        this.handleHideSubMenu();
    };

    handleHideSubMenu = () => {
        this.timeout = setTimeout(this.handleHideCategory, 16);
    };

    handleHideCategory = () => {
        const {onMouseOut} = this.props;
        if (!this.isMouseOver && !this.isSubMouseOver) {
            this.isOpenCategory = -1;
            if (onMouseOut) {
                onMouseOut();
            }
        }
    };

    handleClickElement = (element, index) => (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (element.event_to_display && _.includes(element.event_to_display, 'click')) {
            this.isOpenCategory = index;
        }
        const conditionRunScript = element.event_to_display
            && _.includes(element.event_to_display, 'click')
            && element.events && element.events.click;

        if (conditionRunScript) {
            runScript(element.events.click);
        }
    };

    handleButtonContextMenuElement = (element, index) => (event) => {
        if (element.event_to_display && _.includes(element.event_to_display, 'context')) {
            event.preventDefault();
            this.isOpenCategory = index;
        }
        if (!element.events || !element.events.context) {
            return;
        }
        event.preventDefault();
        runScript(element.events.context);
    };

    handleMouseLeave = (e) => {
        
        const { refParent } = this.props;
        const { current: el } = refParent;
        const { current: parentEl } = this.refDropdown;

        if (!el || !parentEl) {
            return;
        }

        const isMouseLeave = this.refSubDropdowns.length === 0 && parentEl.contains(e.target);
            
        if ( isMouseLeave ) {
            this.refSubDropdowns = [];
            this.handleHideCategory();
        }
    }

    getMenuSize = () => {
        const { sizeMenu } = this.props;
        if (!sizeMenu) {
            return styles.widthDefault;
        }
        switch (sizeMenu) {
            case 'low':
                return styles.widthSmall;
            case 'middle':
                return styles.widthMiddle;
            default:
                return styles.widthDefault;
        }
    };

    renderCategory = (elements, index) => {
        if (!elements || !elements.length || index !== this.isOpenCategory) {
            return null;
        }
        const sizeMenu = this.props.sizeMenu;
        return (
            <CategoryDropdown
                elements={ elements }
                refParent={ this.refElements[index] }
                addSubDropdown={ this.addSubDropdown }
                onClose={ this.handleCloseCategory }
                onMouseOver={ this.handleSubDropdownMouseOver }
                onMouseOut={ this.handleSubDropdownMouseOut }
                isShow
                sizeMenu={ sizeMenu }
            />
        );
    };

    renderElements = (elements) => {
        return _.map(elements, (element, index) => {
            this.refElements[index] = React.createRef();
            const hasNestedMenu = !!(element.child_elements && element.child_elements.length);
            const chevronRight = hasNestedMenu ? (
                <span
                    className={ styles.Arrow }
                    dangerouslySetInnerHTML={ { __html: ChevronRight } }
                />
            ) : null;
            const isActive = index === this.isOpenCategory;
            return (
                <React.Fragment key={ `element${ element.title }${ index }` }>
                    <div
                        className={ `${ styles.Element } ${ isActive ? styles.Active : '' }` }
                        onClick={ this.handleClickElement(element, index) }
                        ref={ this.refElements[index] }
                        onMouseOver={ this.handleChevronMouseOver(element, index) }
                        onMouseOut={ this.handleChevronMouseOut(element) }
                        onContextMenu={ this.handleButtonContextMenuElement(element, index) }
                    >
                        <div 
                            className={ styles.ElementDescription }
                            data-test={ ATTRIBUTES.сategoryDropdownWidgetElementDescription }
                        >
                            <div
                                className={ styles.ElementTitle }
                                data-test={ ATTRIBUTES.сategoryDropdownWidgetElementTitle }
                            >
                                { element.title }
                            </div>
                            <div 
                                className={ `${styles.ElementBlockDecoration}`}
                                data-test={ ATTRIBUTES.сategoryDropdownWidgetElementBlockDecoration }
                            >
                                { this.renderDecoration(element) }
                            </div>
                            { chevronRight }
                        </div>
                    </div>
                    { this.renderCategory(element.child_elements, index) }
                </React.Fragment>
            );
        });
    };

    renderDecoration = (element) => {
        if (!element.decoration) {
            return null;
        }
        return <DecorationElement decoration={ element.decoration } />;
    };

    render() {
        const { elements, refParent } = this.props;
        if (!this.isShow) {
            return null;
        }
        const sizeMenuClass = this.getMenuSize();
        let cn = [styles.Menu];
        if (this.isShow) {
            cn.push(styles.Active, sizeMenuClass);
        }
        return (
            <Dropdown
                refParent={ refParent }
                ref={ this.refDropdown }
                defaultDirection='right-middle'
                offsetTop={ 0 }
                offsetLeft={ 0 }
                >
                <div className={ cn.join(' ') }
                    onMouseLeave={ this.handleMouseLeave }
                >
                    { this.renderElements(elements) }
                </div>
            </Dropdown>
        );
    }
}

export default observer(CategoryDropdown);
