import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import moment from 'moment-timezone';
import _ from 'lodash';

import MaskedInput from 'components/dynamicForms/view/field/maskedInput';
import OptionsInput from 'components/dynamicForms/view/field/optionsInput';
import FieldWrapper from 'components/dynamicForms/view/fieldWrapper';
import Button from 'components/button';
import Dropdown from 'components/dropdown';
import { DateTimePicker } from 'components/dateTimePicker';

import IconCalendar from 'assets/img/icons/calendar.svg';
import DateTimeInputModel from 'components/dynamicForms/model/field/DateTimeInputModel';
import MaskedInputModel from 'components/dynamicForms/model/field/MaskedInputModel';
import OptionsInputModel from 'components/dynamicForms/model/field/OptionsInputModel';

import styles from './styles.module.scss';

import { ATTRIBUTES } from 'constants/attributesForTests';
import { getTestNameField } from 'helpers/data';
import { getUserTimezone, convertToServerDateTimeFormat } from "helpers/getUserTimeZone";
import { isChanged } from 'helpers/form';
import langStore from 'globalState/lang';
import { isMedia } from 'helpers/html';

/**
 * Описание: компонент dateTimeInput
 * Параметры:
 * pickertype: {required, type: string} - тип
 * format: {type: string} - формат
 * onChange: {type: function} - метод изменения значения в родителе
 * placeholder: {type: string} - placeholder
 * value: {type: string}
 * beginDate: {type: string} - ограничение начальной даты
 * endDate: {type: string} - ограничение конечной даты
 */
class DateInput extends React.Component {
    model;
    isShowDateTimePicker = false;
    areOptionsShown = false;
    refInput = React.createRef();
    refDropdown = React.createRef();
    /**
     * @type MaskedInputModel | OptionsInputModel
     */
    inputModel;

    constructor(props) {
        super(props);

        makeObservable(this, {
            model: observable,
            isShowDateTimePicker: observable,
            areOptionsShown: observable,
        });

        if (props.model) {
            this.model = props.model;
        } else {
            this.model = new DateTimeInputModel(props);
        }

        this.setChildModel();
    }

    componentDidMount() {
        document.addEventListener('click', this.onDocumentClick);
        window.addEventListener('scroll', this.onWindowScroll);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onDocumentClick);
        window.removeEventListener('scroll', this.onWindowScroll);
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props, prevProps)) {
            if (this.props.model) {
                this.model = this.props.model;
            } else {
                this.model.mergeData(this.props);
            }
        }
        if (this.model.isCondition) {
            const value = this.model.convertValueToOption(this.model.value);
            this.inputModel.mergeData({
                className: styles.input,
                readonly: this.model.readonly,
                is_mandatory: this.model.isMandatory,
                value: value,
                placeholder: this.model.placeholder,
                valueOpts: this.model.valueOpts,
                areOptionsShown: this.areOptionsShown,
            });
        } else {
            this.inputModel.mergeData({
                readonly: this.model.readonly,
                value: this.model.getDisplayValue(),
                placeholder: this.model.placeholder,
                validate: {
                    format: this.model.getDisplayFormat(),
                },
                cellEditMode: this.model.cellEditMode,
            });
        }

    }

    onDocumentClick = (e) => {
        const dropdownEl = this.refDropdown ? this.refDropdown.current : null;
        const inputEl = this.refInput.current;
        if (!dropdownEl || !inputEl) return false;

        if (!dropdownEl.contains(e.target) && !inputEl.contains(e.target)) {
            this.onModalClose();
        }
    };

    setChildModel = () => {
        if (this.model.isCondition) {
            const value = this.model.convertValueToOption(this.model.value);
            this.inputModel = new OptionsInputModel({
                className: styles.input,
                readonly: this.model.readonly,
                is_mandatory: this.model.isMandatory,
                value: value,
                placeholder: this.model.placeholder,
                valueOpts: this.model.valueOpts,
            });
        } else {
            this.inputModel = new MaskedInputModel({
                readonly: this.model.readonly,
                is_mandatory: this.model.isMandatory,
                value: this.model.getDisplayValue(),
                placeholder: this.model.placeholder,
                validate: {
                    format: this.model.getDisplayFormat(),
                    separator: /[-:.\s/]/,
                },
                column_type: this.model.column_type,
                cellEditMode: this.model.cellEditMode,
                maskClassName: this.model.maskClassName,
            });
        }
    };

    onModalClose = () => {
        this.isShowDateTimePicker = false;
        this.runForceClientScripts();
    };

    onWindowScroll = () => {

        if (!this.isShowDateTimePicker || isMedia('sm')) return;
        this.onModalClose();
    };

    showDateTimePicker = () => {
        this.isShowDateTimePicker = !this.isShowDateTimePicker;
        this.areOptionsShown = false;
    };

    /**
     *
     * @param inputValue {StringInputModel | string | null} - отображаемое значение
     */
    onInputChange = (inputValue) => {
        let value = inputValue;
        if (inputValue instanceof OptionsInputModel) {
            if (inputValue.value && _.isObject(inputValue.value) && inputValue.value.is_option){
                this.model.value = inputValue.value;
            } else {
                value = inputValue.value.display_value;
                const date = moment(value, this.model.getDisplayFormat(), true);
                if (date.isValid()) {
                    const systemFormatValue = date.format(this.model.getFormat());
                    let database_value;
                    if (this.model.pickertype === 'datetime'){
                        database_value = convertToServerDateTimeFormat(date);
                    } else {
                        database_value = systemFormatValue;
                    }
                    this.model.value = {
                        database_value: database_value,
                        display_value: systemFormatValue,
                        is_option: false,
                    };
                } else {
                    this.model.value = {
                        database_value: value,
                        display_value: value,
                        is_option: false,
                    };
                }
            }
        } else {
            if (!value) {
                this.model.value = value;
            } else {
                const date = moment(value, this.model.getDisplayFormat(), true);
                if (date.isValid()) {
                    const systemFormatValue = date.format(this.model.getFormat());
                    this.model.value = this.model.getFormatValue(systemFormatValue, false, getUserTimezone());
                }
            }
        }

        this.model.changed = isChanged(this.model.defaultValue, this.model.value);
        if (this.props.onChange) {
            this.props.onChange(this.model);
        }
    };

    /**
     * @param value {moment} - отображаемое значение
     */
    update = (value) => {
        const _value = value.format(this.model.getFormat());
        const formattedValue = this.model.getFormatValue(_value, false, getUserTimezone());
        if (this.model.isCondition){
            this.model.value = {
                database_value: formattedValue,
                display_value: this.model.convertToDisplayValue(formattedValue),
                is_option: false,
            };
            this.inputModel.value = this.model.value;
        } else {
            this.model.value = formattedValue;
            this.model.checkValidFormatValue(this.model.getDisplayValue());
        }

        this.model.changed = isChanged(this.model.defaultValue, this.model.value);
        if (this.props.onChange) {
            this.props.onChange(this.model);
        }
    };

    onPaste = (evt) => {
        let clipboardData,
            pastedData;
        evt.stopPropagation();
        evt.preventDefault();


        clipboardData = evt.clipboardData || window.clipboardData;
        pastedData = clipboardData.getData('text/plain');

        const date = moment(pastedData, this.model.getDisplayFormat(), true);
        const isValid = date.isValid();
        if (isValid || this.model.isCondition && pastedData.includes('javascript:')) {
            if (isValid){
                const systemFormatValue = date.format(this.model.getFormat());
                const value = this.model.getFormatValue(systemFormatValue, false, getUserTimezone());
                if (this.model.isCondition) {
                    this.model.value = this.model.convertValueToOption(value);
                } else {
                    this.model.value = value;
                }
            } else {
                this.model.value = {
                    display_value: pastedData,
                    database_value: pastedData,
                    is_option: false,
                };
            }

            this.model.changed = isChanged(this.model.defaultValue, this.model.value);
            if (this.props.onChange) {
                this.props.onChange(this.model);
            }
        }
    };

    runForceClientScripts = () => {
        if (!this.model.allowRunScripts && this.model.changed) {
            this.model.runClientScripts(this.model.value);
        }
    }

    handleOptionsStateChange = (state) => {
        this.areOptionsShown = state;
        if (state){
            this.isShowDateTimePicker = false;
        }
    };

    handleEnterKeyDown = () => {
        if (this.model.save && this.model.cellEditMode && !this.model.readonly) {
            this.model.save();
        }
    };

    renderChildInput = () => {
        if (this.model.isCondition) {
            return (
                <OptionsInput
                    model={ this.inputModel }
                    onChange={ this.onInputChange }
                    onPaste={ this.onPaste }
                    onOptionsToggle={ this.handleOptionsStateChange }
                    value={ this.model.value } // let mobx see changes
                    areOptionsShown={ this.areOptionsShown }
                />
            );
        } else {
            return (
                <MaskedInput
                    model={ this.inputModel }
                    onPaste={ this.onPaste }
                    onBlur={ this.runForceClientScripts }
                    getNewMaskValue={ this.model.getNewMaskValue }
                    checkValidFormatValue={ this.model.checkValidFormatValue }
                    checkValidEnteredValue={ this.model.checkValidEnteredValue }
                    showWrongDataMsg={ this.model.showWrongDataMsg }
                    hideAllValidateMsg={ this.model.hideAllValidateMsg }
                    onChange={ this.onInputChange }
                    save={ this.handleEnterKeyDown }
                />
            );
        }
    };

    render() {
        const { system_buttons_hints } = langStore.getTranslate();
        const { className, hidePickerButton } = this.model;
        const initialValue = this.model.getInitialValue();
        const displayValue = this.model.getDisplayValue();
        let showPickerButton = !hidePickerButton;
        if(this.model.readonly){
            if(this.model.columnType === 'time' || !displayValue){
                showPickerButton = false;
            }
        }
        let classes = [
            styles.DateTimePickerInput,
        ];
        if (className) {
            classes.push(className);
        }


        const fieldName = getTestNameField(this.model);
        const dataTest = (fieldName !== 'unknown') ? `${ fieldName }-${ ATTRIBUTES.fieldDateInput }` : `${ ATTRIBUTES.fieldDateInput }`;
        const pickerType = this.model.pickertype || this.model.columnType;
        return (
            <FieldWrapper model={ this.model }>
                <div
                    className={ classes.join(' ') }
                    ref={ this.refInput }
                    data-test={ this.props['data-test'] ? this.props['data-test'] : dataTest }
                    data-test-visible={ this.model.isVisible }
                    data-test-mandatory={ this.model.isMandatory }
                    data-test-readonly={ this.model.readonly }
                    data-test-field-type={ this.model.sys_column_name ? this.model.column_type : undefined }
                    data-test-field-name={ this.model.sys_column_name }
                >
                    <div className={ styles.Group }>
                        { this.renderChildInput() }
                        { showPickerButton &&
                        <Button
                            buttonType='icon-border'
                            svg={ IconCalendar }
                            className={ styles.button }
                            onClick={ this.showDateTimePicker }
                            data-test={ ATTRIBUTES.fieldDateTimeButton }
                            hint={ system_buttons_hints?.open_the_calendar }
                        />
                        }
                    </div>

                    {
                        this.isShowDateTimePicker && this.model.isVisible &&
                        <Dropdown refParent={ this.refInput } ref={ this.refDropdown } disableMinWidth>
                            <DateTimePicker
                                readonly={ this.model.readonly }
                                initialValue={ initialValue }
                                pickertype={ pickerType }
                                update={ this.update }
                                beginDate={ this.model.beginDate }
                                endDate={ this.model.endDate }
                                onClose={ this.onModalClose }
                            />
                        </Dropdown>
                    }
                </div>
            </FieldWrapper>
        );
    }
}

export default observer(DateInput);
