import * as React from 'react';
import { observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';
import _ from 'lodash';
import styles from './styles.module.scss';
import Breadcrumb from 'components/filter/breadcrumb';
import Button from 'components/button';
import ConditionFilters from 'components/conditionFilters';
import Choice from './choice';
import MobileModal from 'components/modalWrapper';
import Dropdown from 'components/dropdown';
import langStore from 'globalState/lang';
import { helperRedirect } from 'helpers/history';
import { getUrlParams, paramsToString } from 'helpers/data';
import { isMedia } from 'helpers/html';
import { fetchFilterSave } from 'actions/filter';
import IconClose from 'assets/img/icons/close-x.svg';
import IconMore from 'assets/img/icons/more-horizontal-kebab.svg';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { FilterProps, FilterSaveBody } from 'types/components/filter/filter';
import {KeyboardKeys} from "types/components/dynamicForms/maskInput";
import { getConditionDictionary } from 'helpers/condition';

/**
 * Описание: компонент Filter
 * Параметры:
 * isOpen - открыт ли condition builder
 * tableId - идентификатор фильтруемой таблицы
 * onChangeCondition - обработчик изменения условия
 * location - строка браузера
 * conditionState - хранилище
 * tableName - имя фильтруемой таблицы
 * readOnly - поля доступны только для чтения
 * notEditCondition - не редактируемый condition (для связанных списков. для запрета удаления из breadcrumb)
 * notEditTable - не используется
 * options - список сохраненных фильтров
 * isRunDisabled - скрыта ли кнопка Run
 * isSaveDisabled - скрыто ли меню сохраненных фильтров
 * isSortingDisabled - убрана ли возможность сортировки
 * isGroupingDisabled - убрана ли возможность группировки
 * isMobile - мобильное отображение
 * isWindow - открыто в словаре
 */

class FilterComponent extends React.Component<FilterProps> {
    data = [];
    isOpen = false;
    textSave = '';
    activeConditionFilter = null;
    isSmallControls = false;
    isShowDropdown = false;
    refFilter: React.RefObject<HTMLDivElement> = React.createRef();
    refControls: React.RefObject<HTMLDivElement> = React.createRef();
    refMoreButton: React.RefObject<HTMLButtonElement> = React.createRef();
    refDropdown: React.RefObject<HTMLDivElement> = React.createRef();

    constructor(props) {
        super(props);

        makeObservable(this, {
            isOpen: observable,
            textSave: observable,
            data: observable,
            activeConditionFilter: observable,
            isSmallControls: observable,
            isShowDropdown: observable,
            setActiveConditionFilter: action,
        });

        getConditionDictionary();
    }

    componentDidMount() {
        this.onWindowResize();

        window.addEventListener('resize', this.onWindowResize);
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentDidUpdate(prevProps) {
        const { isOpen, toggleIsOpen } = this.props;
        if (prevProps.isOpen !== isOpen && !toggleIsOpen) {
            this.toggleOpen();
        }

        if (toggleIsOpen && prevProps.isOpen !== isOpen) {
            this.isOpen = this.props.isOpen as boolean;
        }

        this.onWindowResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    onWindowResize = () => {
        if (this.refControls.current) {
            this.isSmallControls = isMedia('md') && !isMedia('sm');
        }
    };

    handleKeyDown = (evt) => {
        const filterEL = this.refFilter ? this.refFilter.current : null;
        if (!filterEL) return;

        if (filterEL.contains(evt.target)) {
            if (evt.key === KeyboardKeys.Enter && (evt.ctrlKey || evt.metaKey)) {
                this.handleRun();
            }
        }
    };

    handleClickOutside = (evt) => {
        if (!this.refDropdown || !this.refDropdown.current) {
            return false;
        }

        if (!this.refDropdown.current.contains(evt.target)) {
            this.isShowDropdown = false;
        }
    };

    handleToggleDropdown = () => {
        this.isShowDropdown = !this.isShowDropdown;
    };

    handleClickAll = () => {
        const { onChangeCondition } = this.props;
        if (onChangeCondition) {
            onChangeCondition();
            return;
        }
        const parsedSearchParams = getUrlParams(this.props.location.search);
        const params = {
            ...parsedSearchParams,
            condition: '',
        };
        helperRedirect({
            search: paramsToString(params),
        });
    };

    handleRun = () => {
        const { onChangeCondition, conditionState, tableName, location } = this.props;
        if (onChangeCondition) {
            onChangeCondition();
            return;
        }
        conditionState.showLoader = true;
        const parsedSearchParams = getUrlParams(location.search);
        const isFixed = parsedSearchParams.is_fixed ? parsedSearchParams.is_fixed : undefined;
        if (parsedSearchParams && parsedSearchParams.page) {
            delete parsedSearchParams.page;
        }
        const condition = conditionState.getConditionString();
        if (parsedSearchParams?.condition === condition || !condition && !parsedSearchParams?.condition){
            window.s_list.refresh(tableName); // DEF0012358
            conditionState.showLoader = false;
        } else {
            const params = {
                ...parsedSearchParams,
                condition: condition,
                is_fixed: isFixed,
            };
            helperRedirect({
                search: paramsToString(params),
            }, () => {
                conditionState.showLoader = false;
            });
        }
    };

    handleChangeSave = (value) => {
        this.textSave = value;
    };

    handleSave = async () => {
        const { options, tableName, conditionState, updateUiActions } = this.props;
        const trimmedText = this.textSave.replace(/^\s+|\s+$/g, '');
        if (!trimmedText) {
            return;
        }

        const selectedFilter = options.filter(filter => filter.is_selected);
        const body: FilterSaveBody = {
            essence: tableName,
            title: trimmedText,
            condition: conditionState.getConditionString(),
        };

        if (selectedFilter.length) {
            body.current_filter_name = selectedFilter[0].default_name;
        }

        const { data, isOkStatus } = await fetchFilterSave(body);
        if (isOkStatus) {
            updateUiActions(data.saved_filters);
        }
    };

    handleAddFiltering = (type: string, block?: number, index?: number) => () => {
        this.props.conditionState.addFiltering(type, block, index);
    };

    handleAddSorting = () => {
        this.props.conditionState.addSorting();
        this.isShowDropdown = false;
    };

    handleAddGrouping = () => {
        this.props.conditionState.addGrouping();
        this.isShowDropdown = false;
    };

    handleClearAll = () => {
        const { onChangeCondition } = this.props;
        this.props.conditionState.clearData();
        this.props.conditionState.addFiltering('AND');
        if (onChangeCondition) {
            onChangeCondition();
            return;
        }
    };

    handleRemoveBreadcrumb = (id) => {
        const { conditionState } = this.props;
        conditionState.removeFilteringRow(id);
        conditionState.removeSortingRow(id);
        conditionState.removeGroupingRow(id);
        conditionState.removeConditionBreadcrumbField(id);
        this.handleRun();
    };

    toggleOpen = () => {
        const { isOpen, toggleIsOpen } = this.props;
        if (toggleIsOpen) {
            toggleIsOpen();
            return;
        }
        this.isOpen = typeof isOpen === 'undefined' ? !this.isOpen : isOpen;
    };

    setActiveConditionFilter = (id) => {
        this.activeConditionFilter = id;
    };

    renderFiltering = () => {
        const { conditionState, readOnly, tableId, maxLength, isMobile, isWindow } = this.props;
        const { filter_titles } = langStore.getTranslate();

        let prevBlock = conditionState.getMinBlockNumber();
        let filterFields = conditionState.getFilterFields();

        if (filterFields.length === 0) {
            conditionState.addFiltering('AND');
            filterFields = conditionState.getFilterFields();
        }

        const fields = filterFields.map((row, index) => {
            const orHead = row.getBlock() > prevBlock ? (
                <div className={ styles.FilterLabel }>
                    { filter_titles ? `${ filter_titles.label_or } ${ filter_titles.conditions_must_be_met?.toLowerCase() }` : '' }
                </div>
            ) : null;

            prevBlock = row.getBlock();
            const isActive = this.activeConditionFilter === row.getId();
            return (
                <div className={ `${ orHead ? styles.FilterOrCondition : '' }` } key={ row.getId() }>
                    { orHead }
                    <ConditionFilters
                        tableId={ tableId }
                        row={ row }
                        className={ row.getType() === 'OR' ? styles.BorderBottom : '' }
                        onAddFiltering={ this.handleAddFiltering }
                        onRemoveFilteringRow={ conditionState.removeFilteringRow }
                        index={ index }
                        data={ conditionState.getDotWalkList() }
                        isActive={ isActive }
                        setActive={ this.setActiveConditionFilter }
                        readOnly={ readOnly || row.getDisabled() || conditionState.showLoader }
                        conditionCount={ filterFields.length }
                        isHideOrButton={ conditionState.getIsHideOr() }
                        maxLength={ maxLength }
                        isMobile={ isMobile }
                        isWindow={ isWindow }
                    />
                </div>
            );
        });

        return (
            <div
                className={ styles.FilterConditions }
                data-test={ ATTRIBUTES.conditionFilteringRows }
            >
                { fields }
            </div>
        );
    };

    renderSorting = () => {
        const { conditionState, tableId, readOnly, isMobile } = this.props;

        const { filter_titles } = langStore.getTranslate();

        if (!conditionState.isShowSorting()) {
            return null;
        }
        const fields = conditionState.getSortingFields().map((row) => {
            return (
                <ConditionFilters
                    row={ row }
                    key={ row.getId() }
                    tableId={ tableId }
                    onRemoveSortingRow={ conditionState.removeSortingRow }
                    data={ conditionState.getDotWalkList() && conditionState.getDotWalkList().filter(row => row.condition_type !== 'keywords' && !row.hasOwnProperty('reAttributes')) }
                    isActive={ this.activeConditionFilter === row.getId() }
                    setActive={ this.setActiveConditionFilter }
                    readOnly={ readOnly || conditionState.showLoader }
                    isMobile={ isMobile }
                />
            );
        });

        if (!fields.length) {
            return null;
        }

        return (
            <div
                className={ styles.FilterSorts }
                data-test={ ATTRIBUTES.conditionSortingRows }
            >
                <div className={ styles.FilterLabel }>
                    { filter_titles && filter_titles.order }
                </div>
                { fields }
            </div>
        );
    };

    renderGrouping = () => {
        const { conditionState, tableId, readOnly, isMobile } = this.props;

        const { filter_titles } = langStore.getTranslate();

        if (!conditionState.isShowGrouping()) {
            return null;
        }
        const fields = conditionState.getGroupingFields().map((row) => {
            return (
                <ConditionFilters
                    row={ row }
                    key={ row.getId() }
                    tableId={ tableId }
                    onRemoveGroupingRow={ conditionState.removeGroupingRow }
                    data={ conditionState.getDotWalkList() && conditionState.getDotWalkList().filter(row => row.condition_type !== 'keywords' && !row.hasOwnProperty('reAttributes')) }
                    isActive={ this.activeConditionFilter === row.getId() }
                    setActive={ this.setActiveConditionFilter }
                    readOnly={ readOnly || conditionState.showLoader }
                    isMobile={ isMobile }
                />
            );
        });

        if (!fields.length) {
            return null;
        }

        return (
            <div
                className={ styles.FilterSorts }
                data-test={ ATTRIBUTES.conditionGroupingRows }
            >
                <div className={ styles.FilterGroupRow }>
                    <div className={ isMobile ? styles.FilterLabel : styles.FilterGroupLabel }>
                        { filter_titles && filter_titles.group }
                    </div>
                    { !isMobile &&
                    <>
                        <div className={ styles.FilterGroupLabel }>
                            { filter_titles && filter_titles.group_sort }
                        </div>
                        <div className={ styles.FilterGroupSpace } />
                    </> }
                </div>
                { fields }
            </div>
        );
    };

    renderAdditionalButtons = () => {
        const { filter_titles } = langStore.getTranslate();
        const { isSortingDisabled, isGroupingDisabled, readOnly, conditionState } = this.props;
        if (this.isSmallControls) {
            return (
                <Button
                    buttonType="icon"
                    svg={ IconMore }
                    className={ styles.MoreControls }
                    onClick={ this.handleToggleDropdown }
                    ref={ this.refMoreButton }
                    disabled={ readOnly }
                />
            );
        }
        const sortButton = !isSortingDisabled && (
            <Button
                className={ styles.Button }
                onClick={ this.handleAddSorting }
                disabled={ readOnly }
                data-test={ ATTRIBUTES.conditionButtonSort }
            >
                { filter_titles && filter_titles.button_sort }
            </Button>
        );
        const groupButton = !isGroupingDisabled && (
            <Button
                className={ styles.Button }
                onClick={ this.handleAddGrouping }
                disabled={ readOnly || !_.isEmpty(conditionState.getGroupingFields()) }
                data-test={ ATTRIBUTES.conditionButtonGroup }
            >
                { filter_titles && filter_titles.button_group }
            </Button>
        );
        return (
            <>
                { sortButton }
                { groupButton }
            </>
        );
    };

    render() {
        const {
            conditionState, readOnly, isMobile, location, tableId, tableName, notEditCondition,
            isSortingDisabled, isGroupingDisabled, isSaveDisabled, isRunDisabled,
            options,
        } = this.props;
        const { filter_titles } = langStore.getTranslate();
        const breadcrumbTpl = (
            <Breadcrumb
                filterFields={ conditionState ? conditionState.getBreadcrumbFilterFields() : [] }
                sortingFields={ conditionState ? conditionState.getBreadcrumbSortingFields() : [] }
                groupingFields={ conditionState ? conditionState.getBreadcrumbGroupingFields() : [] }
                conditionState={ conditionState }
                data={ conditionState.getDotWalkList() }
                location={ location }
                tableId={ tableId }
                tableName={ tableName }
                notEditCondition={ notEditCondition }
                readOnly={ readOnly }
                onClickHandler={ this.toggleOpen }
                onRemoveCondition={ this.handleRemoveBreadcrumb }
                onClickAll={ this.handleClickAll }
            />
        );

        const oRButton = !conditionState.getIsHideOr() ? (
            <Button
                className={ styles.Button }
                onClick={ this.handleAddFiltering('OR') }
                disabled={ readOnly }
                data-test={ ATTRIBUTES.conditionButtonOr }
            >
                { filter_titles?.button_or }
            </Button>
        ) : null;
        let filterTpl: React.ReactNode = (
            <div
                className={ `${ styles.Filter } ${ isMobile ? styles.MobileFilter : '' }` }
                ref={ this.refFilter }
            >
                <div className={ styles.FilterControls } key="controls">
                    <div
                        className={ styles.FilterControlsContainer }
                        ref={ this.refControls }
                    >
                        <div className={ styles.Column }>
                            <div className={ styles.FilterMainLabel }>
                                { filter_titles?.filter_main_label }
                            </div>

                            <Button
                                className={ styles.Button }
                                onClick={ this.handleAddFiltering('AND') }
                                disabled={ readOnly }
                                data-test={ ATTRIBUTES.conditionButtonAnd }
                            >
                                { filter_titles?.button_and }
                            </Button>
                            { oRButton }
                            { this.renderAdditionalButtons() }
                        </div>

                        <div className={ styles.Column }>
                            {
                                !isSaveDisabled &&
                                <Choice
                                    onChange={ this.handleChangeSave }
                                    value={ this.textSave }
                                    readOnly={ readOnly }
                                    onSave={ this.handleSave }
                                    options={ options || [] }
                                    isMobile={ isMobile }
                                />
                            }
                        </div>

                        <Button
                            className={ styles.ClearAll }
                            onClick={ this.handleClearAll }
                            disabled={ readOnly }
                            data-test={ ATTRIBUTES.conditionButtonClearAll }
                        >
                            { filter_titles?.clear_all }
                        </Button>
                    </div>
                </div>
                { this.renderFiltering() }
                {
                    !isSortingDisabled &&
                    this.renderSorting()
                }
                {
                    !isGroupingDisabled &&
                    this.renderGrouping()
                }
                <div className={ styles.Buttons }>
                    {
                        !isRunDisabled && (
                            <>
                                <Button
                                    className={ styles.Button }
                                    buttonType={ 'primary' }
                                    onClick={ this.handleRun }
                                    disabled={ readOnly }
                                    data-test={ ATTRIBUTES.conditionButtonRun }
                                    data-loader={ conditionState.showLoader }
                                    hint={(
                                        <>
                                            <span>Ctrl</span>
                                            <span>Enter</span>
                                        </>
                                    )}
                                >
                                    { filter_titles?.button_run }
                                </Button>
                            </>
                        )
                    }
                    <Button
                        className={ styles.Button }
                        onClick={ this.toggleOpen }
                        data-test={ ATTRIBUTES.conditionButtonClose }
                    >
                        { filter_titles?.button_close }
                    </Button>
                </div>
                {
                    this.isShowDropdown &&
                    <Dropdown refParent={ this.refMoreButton } ref={ this.refDropdown } minWidth={ 168 }>
                        <div className={ styles.DropdownItems }>
                            <div className={ styles.DropdownItem } onClick={ this.handleAddSorting }>
                                { filter_titles?.button_sort }
                            </div>
                            { _.isEmpty(conditionState.getGroupingFields()) &&
                            <div className={ styles.DropdownItem } onClick={ this.handleAddGrouping }>
                                { filter_titles?.button_group }
                            </div>
                            }
                        </div>
                    </Dropdown>
                }
            </div>
        );

        if (isMobile) {
            filterTpl = this.isOpen ? (
                <MobileModal>
                    <div className={ styles.Mobile }>
                        <div className={ styles.MobileTop }>
                            { breadcrumbTpl }
                            <Button
                                buttonType={ 'icon' }
                                svg={ IconClose }
                                onClick={ this.toggleOpen }
                            />
                        </div>
                        { filterTpl }
                    </div>
                </MobileModal>
            ) : null;
        }
        else {
            filterTpl = (
                <div className={`${styles.FilterWrap} ${this.isOpen ? styles.active : ''}`}>
                    <div className={ styles.FilterWrapContent } >
                        { filterTpl }
                    </div>
                </div>
            );
        }

        return (
            <div
                data-test={ ATTRIBUTES.conditionFilter }
            >
                { breadcrumbTpl }

                { filterTpl }
            </div>
        );
    }
}

export default observer(FilterComponent);
