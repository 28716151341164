import { action, observable, makeObservable } from 'mobx';
import { SearchStateType } from 'types/globalState/search';

class SearchState implements SearchStateType {
    private _searchParam = '';
    private _term = '';
    private _searchOnPage = 0;

    constructor() {
        makeObservable(this, {
            _searchParam: observable,
            _term: observable,
            _searchOnPage: observable,
            setSearchParam: action,
            setSearchTerm: action,
            setSearchOnPage: action,
        });
    }

    getSearchParam(): string {
        return this._searchParam;
    }

    setSearchParam(value: string) {
        this._searchParam = value;
        this._term = value;
    }

    getSearchTerm(): string {
        return this._term;
    }

    setSearchTerm(value: string) {
        this._term = value;
    }

    getSearchOnPage(): number {
        return this._searchOnPage;
    }

    setSearchOnPage(value: number) {
        this._searchOnPage += value;
    }
}

export default new SearchState();
