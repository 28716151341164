import * as React from 'react';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import Button from 'components/button';
import { throttle } from 'helpers/misc';
import ChevronUp from 'assets/img/icons/chevron-up.svg';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { DEFAULT_MIN_PERCENT_PAGE } from './config';

export interface QuickButtonReturnProps {
    className?: string;
}

class QuickButtonReturn extends React.Component<QuickButtonReturnProps> {
    isShowQuickBtn = false;
    previousScroll = 0;
    previousDocumentHeight: number;
    isChangeDocHeight = false;

    constructor(props) {
        super(props);

        makeObservable(this, {
            isShowQuickBtn: observable,
        });
    }

    componentDidMount() {
        window.addEventListener('scroll', this.throttleWindowScroll());
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.throttleWindowScroll());
    }

    throttleWindowScroll = () => {
        return throttle(this.onWindowScroll, 150);
    }


    onWindowScroll = () => {
        let currentScroll = this.getPercentOfPage();
        // Минимальный процент страницы когда должна пропадать кнопка (чтобы в самом вверху не была)
        const isMinPresentPage = currentScroll > DEFAULT_MIN_PERCENT_PAGE;

        this.isShowQuickBtn = this.isChangeDocHeight ? false : (currentScroll < this.previousScroll) && isMinPresentPage;
        this.previousScroll = currentScroll;
    }

    getPercentOfPage = () => {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        let windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        let documentHeight = Math.max(
            document.body.scrollHeight, document.body.offsetHeight, document.body.clientHeight,
            document.documentElement.scrollHeight, document.documentElement.offsetHeight, document.documentElement.clientHeight
        );

        this.isChangeDocHeight = documentHeight !== this.previousDocumentHeight;
        this.previousDocumentHeight = documentHeight;

        return ((scrollTop / (documentHeight - windowHeight)) * 100);
    }

    handleClickQuickReturn = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    render() {
        const { className } = this.props;
        return (
            <Button
                onClick={ this.handleClickQuickReturn }
                buttonType={ 'icon-primary' }
                svg={ ChevronUp }
                className={ `${ className } ${ styles.BtnQuickReturn } ${ this.isShowQuickBtn ? styles.Active : styles.Leave }` }
                data-test={ ATTRIBUTES.quickReturnButton }
            />
        );
    }
}

export default observer(QuickButtonReturn);
