import apiRequest from 'lib/apiRequest';
import * as _ from 'lodash';
import { handleResponse } from 'helpers/action';
import { ResponsePreferencesSave, ResponseGetPreferences, ResponseGetPublicProperties, ResponseGetSettings } from 'types/actions/preferences';

/**
 * Сохраение
 * @param name - имя
 * @param value - значение
 * */
export const savePreference = async (name: string, value: any): Promise<ResponsePreferencesSave> => {
    const data = {
        name,
        value: value.toString(),
    };
    const response = await new apiRequest(`POST /preferences/save`).sendJSON(data);
    return handleResponse(response);
};

/**
 * Получение значения
 * @param preferencesNames - имя или имена
 * */
export const getPreference = async (preferencesNames: string[] | string): Promise<ResponseGetPreferences> => {
    const urlParams = new URLSearchParams('');
    if (Array.isArray(preferencesNames)) {
        _.forEach(preferencesNames, preference => {
            urlParams.append('preferencesNames[]', preference);
        });
    } else {
        urlParams.append('preferencesNames', preferencesNames);
    }
    const response = await new apiRequest(`GET /preferences/get-preferences?${ urlParams.toString() }`).send();
    return handleResponse(response);
};

export const fetchPublicProperties = async (): Promise<ResponseGetPublicProperties> => {
    const response = await new apiRequest(`GET /sys-property/get-public-properties`)
        .caching()
        .send();
    return handleResponse(response);
};

export const fetchPreferencesSettings = async (): Promise<ResponseGetSettings> => {
    const response = await new apiRequest(`GET /setting/get`)
        .caching()
        .send();
    return handleResponse(response);
};

