import type { Condition } from 'globalState/conditions/condition';
import type { FilteringRow } from 'globalState/conditions/condition/filteringRow';
import type { SortingRow } from 'globalState/conditions/condition/sortingRow';
import type { GroupingRow } from 'globalState/conditions/condition/groupingRow';
import * as H from 'history';

export interface BreadcrumbsProps {
    filterFields: FilteringRow[];
    sortingFields: SortingRow[];
    groupingFields: GroupingRow[];
    data: Fields;
    conditionState: Condition;
    location: H.Location;
    tableName: string;
    tableId: string;
    readOnly?: boolean;
    notEditCondition?: string;
    onRemoveCondition: (id: string) => void;
    onClickAll: () => void;
    onClickHandler: () => void;
}

export interface BreadcrumbsLinkProps {
    item: FilteringRow | SortingRow | GroupingRow;
    data: Fields;
    delimeter: string;
}

export type RowType = SortingRow | GroupingRow | FilteringRow

export interface DraggedItem {
    getId?: () => string;
    id?: string;
}

export type Fields = (Field | ModelField)[]

export interface Field {
    column_id: string;
    column_title: string;
    column_type: string;
    condition_type: string;
    dot_walking_attribute: string;
    referenced_table_id: string;
    referenced_table_name: string;
    validate: any;
}

export enum FilterType {
    ALL,
    FIELD,
    MODEL,
    ATTRIBUTE
}

export interface ModelField {
    title: string;
    sysId: string;
    reAttributes: Field[];
    isActive?: boolean;
}