import BaseFormFieldModel from './BaseFormFieldModel';

const MAX_LENGTH = 50;

export default class URLModel extends BaseFormFieldModel {
    columnType = 'url';
    constructor(data, parentFormSectionModel) {
        super(data, parentFormSectionModel);
        super.mergeData(data);
    }

    getShortValue() {
        return this.value.length > MAX_LENGTH ? `${this.value.slice(0, MAX_LENGTH)}...` : this.value;
    }
}
