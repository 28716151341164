import BaseFormFieldModel from './BaseFormFieldModel';
import _ from 'lodash';
import {observable, makeObservable} from "mobx";
import { isEmptyArray } from 'helpers/isEmpty';
import { isChanged } from 'helpers/form';
import { getDisplayValueTranslate } from 'helpers/translations';

export default class ListModel extends BaseFormFieldModel {
    /**
     * значение ввода
     * @type {string}
     */
    term = '';

    /**
     * special
     *
     * @type {*}
     */
    special = {};

    /**
     * forReference
     *
     * @type {*}
     */
    forReference;

    /**
     * Тип колонки
     *
     * @type {string}
     */
    columnType = 'list';


    /**
     * Условие для поля
     *
     * @type {*}
     */
    condition;

    /**
     * Флаг нужен для передачи в url
     *
     * @type {boolean}
     */
    isFixed;

    isUpdatedFromDropdown = false;

    /**
     * Флаг когда поле в фокусе
     *
     * @type {boolean}
     */
    isFocused = false;

    /**
     * Флаг когда на поле навелись
     *
     * @type {boolean}
     */
    isHovered = false;

    /**
     * Флаг если поле открыто в диалоговом окне
     *
     * @type {boolean}
     */
    isWindow

    /**
     * Id поля
     *
     * @type {string}
     */
    sysColumnId

    /**
     * Id поля
     *
     * @type {string}
     */
    tableId


    constructor(data, parentFormSectionModel) {
        super(data, parentFormSectionModel);

        makeObservable(this, {
            term: observable,
            isFocused: observable,
            isHovered: observable,
        });

        this.mergeData(data);
    }

    mergeData(data){
        super.mergeData(data);
        this.setWidgetParams();
    }

    setWidgetParams() {
        // необходимо для виджета List
        if (this.condition) {
            this.special.reference_qualifier = {
                ...this.special.reference_qualifier,
                condition: this.condition,
            };
        }
        if (this.isFixed) {
            this.special.reference_qualifier = {
                ...this.special.reference_qualifier,
                is_fixed: this.isFixed,
            };
        }
    }

    /**
     * Преобразует value к нужному виду
     */
    setValue(value) {
        return !_.isNil(value) ? value : [];
    }

    uiGetValue() {
        return this.value && Array.isArray(this.value) && this.value.map((value) => value.database_value ).join(',');
    }

    uiGetDisplayValue() {
        return this.value && Array.isArray(this.value) && this.value.map((value) => value.display_value ).join(',');
    }

    async uiSetValue(value) {
        if (value.length === 0) {
            return this.uiClearValue();
        }

        let parseDatabaseValue = value;
        if (Array.isArray(value)) {
            parseDatabaseValue = value.join(',');
        }

        const params = {
            table_name: this.sysTableName,
            column_id: this.columnId,
            value: parseDatabaseValue,
        };

        this.value = await getDisplayValueTranslate(params);
        this.changed = isChanged(this.defaultValue, this.value);
    }

    checkEmptyValue() {
        return isEmptyArray(this.value);
    }

    getValueForSave(){
        return this.value && Array.isArray(this.value) && this.value.map((item) => {
            return item.database_value;
        }).join(',');
    }
}
