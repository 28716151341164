import * as React from 'react';

import { observable, reaction, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import componentsUpdaterState from 'globalState/componentsUpdater';
import langStore from 'globalState/lang';
import IconChevron from 'assets/img/icons/chevron-down.svg';
import Button from 'components/button';
import styles from './styles.module.scss';
import { fetchActivities } from 'actions/feedActivity';
import { withRouter } from 'react-router-dom';
import { getUrlParams } from 'helpers/data';
import { ATTRIBUTES } from 'constants/attributesForTests';

const TYPE_FIELD_CHANGE = 'field_changes';
const TYPE_WORKAROUND = 'workaround';
const TYPE_ADDITIONAL_COMMENTS = 'additional_comments';
const TYPE_WORK_NOTES = 'work_notes';
const TYPE_DEFAULT = 'default';

class Activities extends React.Component {
    data;
    errorMessage = '';
    isActiveList = false;
    lastRouteParams = '';
    disposers = [];

    constructor(props) {
        super(props);

        makeObservable(this, {
            data: observable,
            errorMessage: observable,
            isActiveList: observable,
        });

        this.fetchData().catch(this.errorFetchData);

        this.disposers.push(
            reaction(
                () => componentsUpdaterState.activitiesUpdater,
                () => {
                    this.getActivities().catch(console.error);
                },
            ),
        );
    }

    componentWillUnmount() {
        this.disposers.forEach(d => d());
    }

    componentDidUpdate(prevProps) {
        const { essence, recordId, location } = this.props;
        if (essence !== prevProps.essence
            || recordId !== prevProps.recordId
            || location !== prevProps.location){
            this.fetchData().catch(this.errorFetchData);
        }
    }

    fetchData = async () => {
        if (!this.props.essence || !this.props.recordId) return;

        const routeParamsString = JSON.stringify({
            ...this.props,
            ...this.props.location.search,
        });
        if (this.lastRouteParams === routeParamsString) return false;
        this.lastRouteParams = routeParamsString;

        this.getActivities().catch(console.error);
    };

    errorFetchData = (message) => {
        console.error(message);
    };

    getActivities = async () => {
        const tableName = this.props.essence;
        const sysId = this.props.recordId;
        const params = {};

        const parsedSearchParams = getUrlParams(this.props.location.search);
        if (parsedSearchParams.form_view) {
            params.form_view = parsedSearchParams.form_view;
        }

        this.data = await fetchActivities(tableName, sysId, params);
    };

    getStyleFromType(field) {
        switch (field.type) {
            case TYPE_WORK_NOTES:
                return styles.typeWorkNotes;
            case TYPE_ADDITIONAL_COMMENTS:
                return styles.typeAdditional;
            case TYPE_FIELD_CHANGE:
            case TYPE_WORKAROUND:
            case TYPE_DEFAULT:
                return styles.typeDefault;
        }
        return null;
    }

    renderFieldChange = (items) => {
        if (!items) return;

        return (
            <div className={ styles.ActivitiesTable } data-test={ ATTRIBUTES.activitiesItemTable }>
                <>
                    { items.map(item => {
                        if (item.display_old_value === null) {
                            return (
                                <div
                                    className={ styles.ActivitiesTableRow }
                                    key={ item.sys_id }
                                    data-test={ ATTRIBUTES.activitiesItemTableRow }
                                >
                                    <div
                                        className={ styles.ActivitiesTableCol }
                                        data-test={ ATTRIBUTES.activitiesItemTableColumn }
                                    >
                                        { item.field_name }
                                    </div>
                                    <div
                                        className={ styles.ActivitiesTableCol }
                                        data-test={ ATTRIBUTES.activitiesItemTableColumn }
                                    >
                                        { item.display_new_value }
                                    </div>
                                </div>
                            );
                        }
                        return (
                            <div
                                className={ styles.ActivitiesTableRow }
                                key={ item.sys_id }
                                data-test={ ATTRIBUTES.activitiesItemTableRow }
                            >
                                <div
                                    className={ styles.ActivitiesTableCol }
                                    data-test={ ATTRIBUTES.activitiesItemTableColumn }
                                >
                                    { item.field_name }
                                </div>
                                <div
                                    className={ `${ styles.BreakWord } ${ styles.ActivitiesTableCol }` }
                                    data-test={ ATTRIBUTES.activitiesItemTableColumn }
                                >
                                    { item.display_new_value } <i>{ item.verb }</i> { item.display_old_value }
                                </div>
                            </div>
                        );
                    })
                    }
                </>
            </div>
        );
    };

    renderFieldBody = (field) => {

        if (field.type === TYPE_FIELD_CHANGE) {
            return this.renderFieldChange(field.special);
        }

        return (
            <div className={ styles.BreakWord } data-test={ ATTRIBUTES.activitiesItemBreakWord }>
                { field.special.value }
            </div>
        );
    };

    renderFieldsItems = (fields) => {
        if (!this.data.elements.length) return;

        return fields.map(field => {
            return (
                <div
                    key={ field.index }
                    className={ styles.ActivitiesItem }
                    data-test={ ATTRIBUTES.activitiesItem }
                >
                    <div className={ `${ styles.ActivitiesBar } ${ this.getStyleFromType(field) }` } />
                    <div className={ styles.ActivitiesHead }>
                        <div className={ styles.ActivitiesHeadPart }>
                            <div className={ styles.ActivitiesUser }>
                                <div className={ styles.ActivitiesUserAva }>
                                    <div className={ styles.ActivitiesUserPic } />
                                    { /*<div className={ styles.ActivitiesUserStatus } />*/ }
                                </div>
                                <div className={ styles.ActivitiesUserName } data-test={ ATTRIBUTES.activitiesItemUserName }>
                                    { field.user.title }
                                </div>
                            </div>
                        </div>
                        <div className={ styles.ActivitiesHeadPart }>
                            <div className={ styles.ActivitiesInfo } data-test={ ATTRIBUTES.activitiesItemInfo }>
                                <span> { field.type_title }</span>
                                <span>&nbsp;•&nbsp;</span>
                                <span> { field.sys_created_at } </span>
                            </div>
                        </div>
                    </div>
                    <div>
                        { this.renderFieldBody(field) }
                    </div>
                </div>
            );
        });
    };

    render() {
        const { activities_title } = langStore.getTranslate();
        if (this.errorMessage) {
            return (
                <div>{ this.errorMessage }</div>
            );
        }
        if (!this.data) return null;

        return (
            <div className={ styles.Activities } data-test={ ATTRIBUTES.activities }>
                <div className={ styles.ActivitiesLabelWrap }>
                    <div className={ styles.ActivitiesLabel } data-test={ ATTRIBUTES.activitiesLabel }>
                        { activities_title ? activities_title : '' }: { this.data.elements.length }
                    </div>
                    { this.data.elements.length > 0 && (
                        <Button
                            buttonType={ 'icon' }
                            svg={ IconChevron }
                            className={ `${ styles.ActivitiesToggle } ${ this.isActiveList ? styles.active : '' }` }
                            onClick={ () => {
                                this.isActiveList = !this.isActiveList;
                            } }
                            data-test={ ATTRIBUTES.activitiesToggleButton }
                        />
                    ) }
                </div>

                <div className={ `${ styles.ActivitiesList } ${ this.isActiveList ? styles.active : '' }` }>
                    { this.renderFieldsItems(this.data.elements) }
                </div>
            </div>
        );
    }
}

export default withRouter(observer(Activities));
