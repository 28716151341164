import React from 'react';
import styles from './styles.module.scss';
import IconUserDefault from 'assets/img/user-ava.svg';
import {ATTRIBUTES} from "constants/attributesForTests";
import type { IDropdownUser } from 'types/components/otherUser';

class DropdownUser extends React.Component<IDropdownUser> {
    handleClickUser = () => {
        const { user } = this.props;
        if (user.is_anonymous) {
            return;
        }
        window.open(`/record/user/${ user.id }`, '_blank');
    }

    renderUserTitle = () => {
        const { user } = this.props;
        const userProf = user.extra_field ? (
            <div
                className={ styles.ElementUserProf }
                data-test={ATTRIBUTES.otherUserDropdownProf}
            >
                { user.extra_field }
            </div>
        ) : null;
        return (
            <>
                <div
                    className={ styles.ElementUserTitle }
                    data-test={ATTRIBUTES.otherUserDropdownTitle}
                >
                    { user.name }
                </div>
                { userProf }
            </>
        );
    }

    render() {
        const { user, innerRef, current } = this.props;
        const avatar = user?.avatar ? (
            <div className={ styles.Ava } style={ { backgroundImage: `url("${ user.avatar }")` } } />
        ) : (
            <div className={ styles.Ava } dangerouslySetInnerHTML={ { __html: IconUserDefault } } />
        );
        const clientWidth = document.documentElement.clientWidth;
        const styleElement = { width: clientWidth <= 575 ? clientWidth * 0.96 : 'auto' };
        const styleElementBlock = { width: clientWidth <= 575 ? clientWidth * 0.98 - 66 : '96%' };

        return (
            <div
                className={ `${ styles.Element } ${ current === user.id ? styles.Active : '' } ${ user.is_anonymous ? styles.NonClick : '' }` }
                onClick={ this.handleClickUser }
                ref={ innerRef }
                style={ styleElement }
            >
                <span
                    className={ styles.ElementAvatar }
                    data-test={ATTRIBUTES.otherUserDropdownAvatar}
                >
                    { avatar }
                </span>
                <div
                    className={ styles.ElementBlock }
                    style={ styleElementBlock }
                >
                    { this.renderUserTitle() }
                </div>
            </div>
        );
    }
}

export default React.forwardRef((props: IDropdownUser, ref?: React.Ref<HTMLDivElement> | null) => <DropdownUser innerRef={ ref } { ...props } />);
