import * as React from 'react';
import { observer } from 'mobx-react';
import { FormSection } from 'components/dynamicForms/view/form';
import REMWidgetState from './state';
import { withRouter } from 'react-router-dom';
import { beforeUnload } from 'helpers/form';
import { globalEventEmitter } from 'lib/EventEmitter';

/**
 * Виджет для расширения REM-атрибутами стандартной формы
 * @props tableName системное название таблицы
 * @props recordId id записи
 * @props modelId РЕМ модель
 * @props isUserScripts Запускать клиентские скрипты
 * @props location
 * @props isPortal Режим портала
 * @props readOnly
 * @props formName - идентификатор формы
 */
class REMWidget extends React.Component {
    /**
     * @type REMWidgetState
     */
    state;

    constructor(props) {
        super(props);
        this.state = new REMWidgetState(props);
        globalEventEmitter.subscribe(['impersonate', 'deImpersonate'], this.handleImpersonate);
    }

    componentDidUpdate(prevProps) {
        const { tableName, recordId, modelId, isUserScripts, widgetId, formName } = this.props;
        if (this.state &&
            (prevProps.tableName !== tableName
                || prevProps.recordId !== recordId
                || prevProps.modelId !== modelId
                || prevProps.isUserScripts !== isUserScripts
                || prevProps.widgetId !== widgetId
                || prevProps.formName !== formName)
        ) {
            this.state.updateData(this.props).catch(console.error);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', beforeUnload);
    }

    handleImpersonate = () => {
        this.state.updateData(this.props).catch(console.error);
    }

    renderAttributes = () => {
        const { location, isPortal, widgetId, formName } = this.props;
        const { tableName, recordId, modelId = null, remData, isUserScripts, remAttached } = this.state;
        if (!remData) {
            return null;
        }
        const clientScripts = remData ? remData.client_scripts : null;
        const fields = remData ? remData.sections[0].elements : null;
        if (!fields) {
            return null;
        }

        //Виджет, являясь элементом секции, получает parentFormSectionModel. Далее привязываем форму симплтэга remwidget к dynamic form
        const dynamicForm = this.state.parentFormSectionModel.parentFormModel;
        return (
            <FormSection
                isServicePortal={ isPortal }
                tableName={ tableName }
                sysId={ recordId || null }
                fields={ fields }
                location={ location }
                remId={ modelId }
                clientScripts={ isUserScripts && clientScripts }
                widgetId={ widgetId }
                formName={ formName }
                remAttached={ remAttached }
                serviceName={ modelId } //для идентификации внутри FormsState
                parentFormModel={ dynamicForm }
            />
        );
    };


    render() {
        if (this.state) {
            return this.renderAttributes();
        }
        else {
            return null;
        }
    }
}

export default withRouter(observer(REMWidget));
