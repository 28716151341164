import ListBaseStorage from 'components/portalDesigner/storage/listBaseStorage';
import apiRequest from 'lib/apiRequest';
/**
 * state со списком всех layout
 */
class LayoutsStorage extends ListBaseStorage {

    constructor() {
        super();
    }

    fetch = async () => {
        const response = await new apiRequest('GET /portal/all-layouts').send();
        const data = response.getData();
        this.set(data);
    };

    getByIndex(index) {
        return this.data[index];
    }
}

export default new LayoutsStorage;
