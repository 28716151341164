/**
 *
 * @type {'container'|'layout'|'widget_instance'}
 */
let draggedSysId = null;
let draggedType;
let dragged = false;
let isNew = false;

class DragAndDropState {
    set sysId(sysId) {
        draggedSysId = sysId;
    }

    get sysId() {
        return draggedSysId;
    }

    set type(type) {
        draggedType = type;
    }

    get type() {
        return draggedType;
    }

    set dragged(isDragged) {
        dragged = isDragged;
    }

    get dragged() {
        return dragged;
    }

    set isNew(isNewElement) {
        isNew = isNewElement;
    }

    get isNew() {
        return isNew;
    }

    serialize() {
        return {
            sysId: draggedSysId,
            type: draggedType,
            dragged: dragged,
        };
    }

    clean() {
        draggedSysId = null;
        draggedType = null;
        dragged = false;
        isNew = false;
    }
}

export default new DragAndDropState();
