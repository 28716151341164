import BasePortalDesignerCollection from './base';
import WidgetInstancePortalDesignerModel from 'components/portalDesigner/model/widgetInstance';

/**
 * Коллекция виджетов
 * @extends {BasePortalDesignerCollection}
 */
export default class WidgetInstancesPortalDesignerCollection extends BasePortalDesignerCollection {

    /**
     *
     * @param widgetInstances [WidgetInstancePortalDesignerModel]
     * @param parent {ColumnPortalDesignerModel}
     */
    constructor(widgetInstances, parent) {
        super(widgetInstances, WidgetInstancePortalDesignerModel, parent);
    }
}
