import sidebarState from 'globalState/sidebarState';

export default class SimpleMenu {

    updateFavoritesList = () => {
       sidebarState.fetchFavoritesList();
    };

    updateMenuList = () => {
        sidebarState.fetchMenuList();
    };
}
