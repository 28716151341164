import * as React from 'react';
import { observer } from 'mobx-react';
import styles from 'components/dateTimePicker/styles.module.scss';

/**
 * Описание: Строка дней недели
 * Параметры:
 * days - массив дней недели
 */
class DatePickerDaysRow extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (<div className={ styles.DaysHeader }>
            { this.props.days.map((day, index) => {
                return <div className={ styles.Col } key={ index }>{ day }</div>;
            }) }
        </div>);
    }
}

export default observer(DatePickerDaysRow);
