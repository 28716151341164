import { Centrifuge } from 'centrifuge';
import userState from 'globalState/user';

export class CentrifugeConnect {
    constructor() {
        const ws = window.location.protocol === 'http:' ? 'ws:' : 'wss:';
        const address = window.PUSH_NOTIFICATIONS_ADDRESS || 'localhost:8443';
        return new Centrifuge(`${ ws }//${ address }/connection/websocket`, {
            token: userState.accessToken,
        });
    }
}
