import ListBaseStorage from 'components/portalDesigner/storage/listBaseStorage';

/**
 * state со списком всех виджетов
 */
class SysWidgetStorage extends ListBaseStorage {
    table_name = 'sys_widget';
    columns = [
        'name',
        'sys_id',
    ].join(',');

    constructor() {
        super();
    }

    filterByName(name) {
        return this.filter('name', name);
    }
}

export default new SysWidgetStorage;
