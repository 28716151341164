import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import styles from './styles.module.scss';
import cellStyles from 'components/groupedTable/styles.module.scss';
import GlobalPortal from 'components/globalPortal';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { ContextMenuConditionProps } from 'types/components/table/contextMenuCondition/index';
import _ from 'lodash';
import IconArrowUp from 'assets/img/icons/arrow-up.svg';
import IconArrowDown from 'assets/img/icons/arrow-down.svg';
import IconFolder from 'assets/img/icons/folder.svg';
import IconFootprint from 'assets/img/icons/footprint.svg';
import IconCheck from 'assets/img/icons/check.svg';
import IconSizeMaximize from 'assets/img/icons/size-maximize-2.svg';
import IconSizeMinimize from 'assets/img/icons/size-minimize-2.svg';
import langStore from "globalState/lang/index";
import { executeScripts } from 'helpers/uiActionsHelper';
import TableState from 'globalState/table/index';
import currentCellState from 'globalState/table/currentCell/index';

/**
 * Описание: контекстное меню для листов
 * Параметры:
 * items: {required: true, type: array} - массив элементов меню
 * table: {required: true, type: link} - ссылка на родительскую таблицу
 * isHeaderMenu: {required: false, type: boolean} - тип контекстного меню
 * x: {required: true, type: number} - координаты по x, по которым будет показано меню
 * y: {required: true, type: number} - координаты по y, по которым будет показано меню
 */
class ContextMenuCondition extends React.Component<ContextMenuConditionProps>  {
    items = [];
    refNode: React.RefObject<HTMLDivElement> = React.createRef();
    refMenu: React.RefObject<HTMLDivElement> = React.createRef();

    constructor(props: ContextMenuConditionProps) {
        super(props);

        makeObservable(this, {
            items: observable,
        });
    }

    componentDidUpdate() {
        this.updateMenuPosition();
    }

    componentDidMount() {
        this.updateMenuPosition();
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('scroll', this.handleWindowScroll);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('scroll', this.handleWindowScroll);
    }

    handleWindowScroll = () => {
        if (!this.props.isShowContextMenu) {
            return;
        }

        const { onToggleContextMenu } = this.props;

        if (!this.refMenu || !this.refMenu.current) {
            return false;
        }

        if (onToggleContextMenu) {
            onToggleContextMenu();
        }
    };

    handleClickOutside = (e) => {
        if (!this.props.isShowContextMenu) {
            return;
        }

        const { onToggleContextMenu } = this.props;

        if (!this.refMenu || !this.refMenu.current || e.target?.closest('th')?.classList.contains(cellStyles.focus)) {
            return false;
        }

        if (!this.refMenu.current.contains(e.target) && onToggleContextMenu) {
            onToggleContextMenu();
        }
    };

    updateMenuPosition = () => {
        if (!this.refNode || !this.refMenu || !this.refNode.current || !this.refMenu.current) {
            return;
        }
        const { x, y } = this.props;

        const offset = 10;
        const popupRect = this.refMenu.current.getBoundingClientRect();
        const windowWidth = document.documentElement.clientWidth;
        const windowHeight = document.documentElement.clientHeight;

        let left = x;
        let top = y;

        if (left + popupRect.width > windowWidth) {
            left = left - (left + popupRect.width - windowWidth) - offset / 2;
            this.refMenu.current.style.left = `${ left }px`;
        } else {
            this.refMenu.current.style.left = `${ left }px`;
        }

        if (top + popupRect.height > windowHeight + document.documentElement.scrollTop) {
            top = top - (top + popupRect.height - windowHeight - document.documentElement.scrollTop) - offset / 2;
            this.refMenu.current.style.top = `${ top }px`;
        } else {
            this.refMenu.current.style.top = `${ top }px`;
        }
    };

    handleClick = (item) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { onToggleContextMenu } = this.props;
        if (item.onClick) {
            item.onClick();
        }

        if (onToggleContextMenu) {
            onToggleContextMenu();
        }
    };

    handleExecuteScripts = (item) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { onToggleContextMenu } = this.props;
        executeScripts(item);
        if (onToggleContextMenu) {
            onToggleContextMenu();
        }
    };

    handleUngroup = () => {
        const { onChangeGrouping } = this.props;
        TableState.setGroups([]);
        onChangeGrouping('', '');
    };

    getGroupItem = () => {
        const { filter_titles } = langStore.getTranslate();
        const { groupingFields, onChangeGrouping, isGroup } = this.props;
        const attribute = currentCellState.getAttribute();
        if ((!_.isEmpty(groupingFields) && groupingFields[0].getField().dot_walking_attribute === attribute) || isGroup) {
            return null;
        }
        return {
            name: `${ filter_titles ? (!_.isEmpty(groupingFields) ? filter_titles.regroup : filter_titles.group) : '' }: ${ currentCellState.getValue() }`,
            value: `GROUPBY${ attribute }`,
            checked: false,
            icon: IconFolder,
            onClick: () => onChangeGrouping((attribute as string), 'GROUPBY'),
        };
    }

    getShowGroups = () => {
        const { groupingFields, onChangeIsShowAll } = this.props;
        if (_.isEmpty(groupingFields)) {
            return [];
        }
        const { list_titles } = langStore.getTranslate();
        const isShowAllGroups = TableState.getGroups().length === _.filter(TableState.getGroups(), group => group.getIsShow()).length;
        const showGroups = [
            {
                name: `${ list_titles ? (isShowAllGroups ? list_titles.collapse_groups : list_titles.expand_groups) : '' }`,
                value: '',
                checked: false,
                icon: isShowAllGroups ? IconSizeMinimize : IconSizeMaximize,
                onClick: () => onChangeIsShowAll(!isShowAllGroups),
            },
        ];
        const isShowOneGroup = _.find(TableState.getGroups(), group => group.getIsShow());
        if (isShowOneGroup && !isShowAllGroups) {
            showGroups.push({
                name: `${ list_titles ? list_titles.collapse_groups : '' }`,
                value: '',
                checked: false,
                icon: IconSizeMinimize,
                onClick: () => onChangeIsShowAll(!isShowOneGroup),
            });
        }
        return showGroups;
    };

    isActiveSort = (order) => {
        const { sortingFields } = this.props;
        if (_.isEmpty(sortingFields)) {
            return false;
        }
        const { filter_titles } = langStore.getTranslate();
        const ascending = filter_titles ? filter_titles.a_to_z : 'ascending';
        const attribute = (window as any).currentCell && (window as any).currentCell.attribute;
        const checkedSortValues = _.map(sortingFields, sortRow => {
            return `${ sortRow.getDirection() === ascending ? 'ORDERBY' : 'ORDERBYDESC' }${ sortRow.getField().dot_walking_attribute }`;
        });
        return checkedSortValues.includes(`${ order }${ attribute }`);
    };

    getTopItems = () => {
        const { filter_titles } = langStore.getTranslate();
        const { groupingFields, onChangeSorting, isGroup } = this.props;
        const attribute = (window as any).currentCell && (window as any).currentCell.attribute;
        const groupItem = this.getGroupItem();
        const unGroupItem = !_.isEmpty(groupingFields) ? {
            name: filter_titles && filter_titles.ungroup,
            value: ``,
            checked: false,
            icon: IconFootprint,
            onClick: this.handleUngroup,
        } : null;
        const sortingItems = !isGroup ? [
            {
                name: `${ filter_titles ? filter_titles.group_sort + ' ' + filter_titles.a_to_z : '' }`,
                value: `ORDERBY${ attribute }`,
                checked: this.isActiveSort('ORDERBY'),
                icon: IconArrowUp,
                onClick: () => this.isActiveSort('ORDERBY') ? onChangeSorting('','') : onChangeSorting((attribute as string), (filter_titles && filter_titles.a_to_z) || 'ascending'),
            },
            {
                name: `${ filter_titles ? filter_titles.group_sort + ' ' + filter_titles.z_to_a : '' }`,
                value: `ORDERBYDESC${ attribute }`,
                checked: this.isActiveSort('ORDERBYDESC'),
                icon: IconArrowDown,
                onClick: () => this.isActiveSort('ORDERBYDESC') ? onChangeSorting('','') : onChangeSorting((attribute as string), (filter_titles && filter_titles.z_to_a) || 'descending'),
            },
        ] : [];
        const showGroups = this.getShowGroups();
        return [
            ...sortingItems,
            groupItem,
            unGroupItem,
            ...showGroups,
        ];
    };

    getGroupingItems = () => {
        const { filter_titles } = langStore.getTranslate();
        const { groupingFields, onChangeGrouping } = this.props;
        let items: any[] = [];
        if (!_.isEmpty(groupingFields)) {
            const groupAttribute = groupingFields[0].getField().dot_walking_attribute;
            const checkedGroupValue = `${ groupingFields[0].getDirection().database_value }${ groupAttribute }`;
            items = [
                {
                    name: filter_titles && filter_titles.group_by,
                    value: `GROUPBY${ groupAttribute }`,
                    checked: checkedGroupValue === `GROUPBY${ groupAttribute }`,
                    icon: null,
                    onClick: () => onChangeGrouping(groupAttribute, 'GROUPBY'),
                },
                {
                    name: filter_titles && filter_titles.group_by_desc,
                    value: `GROUPBYDESC${ groupAttribute }`,
                    checked: checkedGroupValue === `GROUPBYDESC${ groupAttribute }`,
                    icon: null,
                    onClick: () => onChangeGrouping(groupAttribute, 'GROUPBYDESC'),
                },
                {
                    name: filter_titles && filter_titles.group_by_count,
                    value: `GROUPBYCOUNT${ groupAttribute }`,
                    checked: checkedGroupValue === `GROUPBYCOUNT${ groupAttribute }`,
                    icon: null,
                    onClick: () => onChangeGrouping(groupAttribute, 'GROUPBYCOUNT'),
                },
                {
                    name: filter_titles && filter_titles.group_by_count_desc,
                    value: `GROUPBYCOUNTDESC${ groupAttribute }`,
                    checked: checkedGroupValue === `GROUPBYCOUNTDESC${ groupAttribute }`,
                    icon: null,
                    onClick: () => onChangeGrouping(groupAttribute, 'GROUPBYCOUNTDESC'),
                },
            ];
        }
        return items;
    };

    renderUiActionItems = () => {
        const { uiActionItems, isGroup, isWindow } = this.props;
        if (isGroup || isWindow) {
            return null;
        }
        const uiActionItemsHtml = _.map(uiActionItems, (item, index) => {
            return (
                <div
                    className={ styles.Item }
                    key={ JSON.stringify(item) + index }
                    onClick={ this.handleExecuteScripts(item) }
                    data-test={ `${ ATTRIBUTES.contextMenuItem }_${ index }` }
                >
                    <div>
                        { item.name }
                    </div>
                </div>
            );
        });
        return (
            <>
                <div className={ styles.Delimeter } />
                { uiActionItemsHtml }
            </>
        );
    };

    renderMenu = (items: any[], groupingItems: any[]) => {
        const { groupingFields } = this.props;
        const { list_titles } = langStore.getTranslate();
        const delimeter = !_.isEmpty(groupingFields) ? (
            <>
                <div className={ styles.Delimeter } />
                <div className={ styles.GroupHead }>
                    { list_titles && list_titles.groups_sorting }: { groupingFields[0].getDisplayValue().display_column_name }
                </div>
            </>
        ) : null;
        const topItems = _.map(items, (item, index) => {
            if (!item) {
                return null;
            }
            const iconHtml = item.icon ? (
                <div dangerouslySetInnerHTML={ { __html: item.icon } } className={ styles.ItemIcon } />
            ) : null;
            const iconCheck = item.checked ? (
                <div dangerouslySetInnerHTML={ { __html: IconCheck } } className={ styles.ItemCheck } />
            ) : null;
            return (
                <div
                    className={ styles.Item }
                    key={ JSON.stringify(item) + index }
                    onClick={ this.handleClick(item) }
                    data-test={ `${ ATTRIBUTES.contextMenuItem }_${ index }` }
                >
                    <div className={ styles.ItemText }>
                        { iconHtml }
                        <div>
                            { item.name }
                        </div>
                    </div>
                    { iconCheck }
                </div>
            );
        });
        const groupingItemsHtml = _.map(groupingItems, (item, index) => {
            const iconCheck = item.checked ? (
                <div dangerouslySetInnerHTML={ { __html: IconCheck } } className={ styles.ItemCheck } />
            ) : null;
            return (
                <div
                    className={ styles.Item }
                    key={ JSON.stringify(item) + index }
                    onClick={ this.handleClick(item) }
                    data-test={ `${ ATTRIBUTES.contextMenuItem }_${ index }` }
                >
                    <div>
                        { item.name }
                    </div>
                    { iconCheck }
                </div>
            );
        });
        return (
            <>
                { topItems }
                { delimeter }
                { groupingItemsHtml }
                { this.renderUiActionItems() }
            </>
        );
    };

    render() {
        if (!this.props.isShowContextMenu) {
            return null;
        }
        const items = this.getTopItems();
        const groupingItems = this.getGroupingItems();

        return (
            <div ref={ this.refNode }>
                <GlobalPortal>
                    <div
                        className={ `${ styles.menu }` }
                        ref={ this.refMenu }
                        data-test={ ATTRIBUTES.contextMenu }
                    >
                        { this.renderMenu(items, groupingItems) }
                    </div>
                </GlobalPortal>
            </div>
        );
    }
}

export default observer(ContextMenuCondition);
