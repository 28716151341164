/**
 * Модель виджета
 */
export default class WidgetModel {

    /**
     * тип разделителя
     *
     * @type {*}
     */
    data;

    parentFormSectionModel;

    constructor(data) {
        this.data = data;
    }

    isSplit() {
        return false;
    }
}
