import * as React from 'react';
import { observer } from 'mobx-react';
import IconChevronRight from 'assets/img/icons/chevron-right.svg';
import IconChevronLeft from 'assets/img/icons/chevron-left.svg';
import styles from './styles.module.scss';
import { observable, makeObservable } from 'mobx';
import { ATTRIBUTES } from 'constants/attributesForTests';

/**
 * Описание: Слайдер для табов
 * Параметры:
 * onUpdate: {type: function} - возвращает флаг активен ли слайдер
 * activeTab - элемент выбраной вкладки
 * active - выбраная вкладка
 * arrowsClassName - класс для стилизации стрелок управления
 * noMargins - отображение без отступов слева и справа, когда слайдер неактивен
 */
class TabsSlider extends React.Component {
    isSliderOn = false;
    isTransitionOn = false;
    timeout = null;
    refList = React.createRef();
    refWrap = React.createRef();

    constructor(props) {
        super(props);

        makeObservable(this, {
            isSliderOn: observable,
            isTransitionOn: observable,
        });
    }

    componentDidMount() {
        window.addEventListener('resize', this.checkListSizes);
        this.checkListSizes();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkListSizes);
    }

    componentDidUpdate(prevProps) {
        if (this.props.active !== prevProps.active) {
            this.checkActiveTabVisibility();
        }

    }

    checkActiveTabVisibility = () => {
        if (!this.isSliderOn) return;

        const { activeTab } = this.props;
        const listEl = this.refList ? this.refList.current ? this.refList.current : null : null;
        const wrapEl = this.refWrap ? this.refWrap.current ? this.refWrap.current : null : null;
        const tabEl = activeTab ? activeTab.current ? activeTab.current : null : null;

        if (!tabEl || !wrapEl || !listEl) return;

        const tabRect = tabEl.getBoundingClientRect();
        const wrapRect = wrapEl.getBoundingClientRect();
        let listLeft = parseInt(listEl.style.left ? listEl.style.left : 0);
        const tabLeft = Math.round(tabRect.left);
        const tabRight = tabLeft + Math.round(tabRect.width);
        const wrapLeft = Math.round(wrapRect.left);
        const wrapRight = wrapLeft + Math.round(wrapRect.width);

        if (tabLeft < wrapLeft) {
            listLeft = listLeft + (wrapLeft - tabLeft);
        }
        else if (tabRight > wrapRight) {
            listLeft = listLeft - (tabRight - wrapRight);
        }

        listEl.style.left = `${ this.getProperLeft(listLeft) }px`;
    };

    checkListSizes = () => {
        const { current: list } = this.refList;
        const { current: wrap } = this.refWrap;
        if (!list || !wrap) return;

        this.isTransitionOn = false;
        this.isSliderOn = wrap.getBoundingClientRect().width < this.getListWidth(list);
        const left = this.isSliderOn ? parseInt(list.style.left ? list.style.left : 0) : 0;
        const properLeft = this.getProperLeft(left);
        list.style.left = properLeft + 'px';
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.isTransitionOn = true;
        }, 100);

        if (this.props.onUpdate) {
            this.props.onUpdate(this.isSliderOn);
        }
    };

    getListWidth = (list) => {
        const children = this.getListVisibleChildren(list);
        if(!children || children.length === 0) return list.getBoundingClientRect().width;
        const listLastItem = children[children.length-1];
        return listLastItem.getBoundingClientRect().left - list.getBoundingClientRect().left + listLastItem.getBoundingClientRect().width;
    };

    getListVisibleChildren = (list) => {
        if(!list || !list.children) return [];
        return [...list.children].filter(this.isVisible);
    };

    isVisible = (elem) => {
        return !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length );
    };

    getProperLeft = (left) => {
        const { current: list } = this.refList;
        const { current: wrap } = this.refWrap;
        if (!list || !wrap) return;

        const rightEdge = wrap.getBoundingClientRect().width - this.getListWidth(list);
        if (left > 0) {
            return 0;
        }
        else if (left < rightEdge && rightEdge < 0) {
            return Math.round(rightEdge);
        }
        else {
            return Math.round(left);
        }
    };

    moveList = (direction) => () => {
        const { current: list } = this.refList;
        const { current: wrap } = this.refWrap;
        if (!list || !wrap) return;

        const listItems = list.children;
        const wrapRect = wrap.getBoundingClientRect();
        let left = parseInt(list.style.left ? list.style.left : 0);
        const offset = 0;

        if (direction === 'left') {
            for (let i = 0, itemsLength = listItems.length; i < itemsLength; i++) {
                const item = listItems[i];
                const itemRect = item.getBoundingClientRect();
                if (Math.round(itemRect.left) + Math.round(itemRect.width) > Math.round(wrapRect.left) + Math.round(wrapRect.width)) {
                    left = left - Math.round(itemRect.left - wrapRect.left) + offset;
                    break;
                }
            }
        }
        else {
            for (let i = listItems.length - 1; i >= 0; i--) {
                const item = listItems[i];
                const itemRect = item.getBoundingClientRect();
                if (Math.round(itemRect.left) < Math.round(wrapRect.left)) {
                    left = left + Math.round(wrapRect.width - itemRect.width) + Math.round(wrapRect.left - itemRect.left) - offset;
                    break;
                }
            }
        }

        const properLeft = this.getProperLeft(left);
        list.style.left = properLeft + 'px';
    };


    render() {
        const {noMargins, arrowsClassName} = this.props;
        const arrowClass = arrowsClassName ? `${styles.Arrow} ${arrowsClassName}` : styles.Arrow;

        return (
            <div className={ styles.Base } data-test={ ATTRIBUTES.tabsSlider }>
                { this.isSliderOn && (
                    <div
                        onClick={ this.moveList('right') }
                        className={ arrowClass }
                        dangerouslySetInnerHTML={ { __html: IconChevronLeft } }
                        data-test={ ATTRIBUTES.tabsSliderLeft }
                    />
                ) }
                <div className={ `${ styles.Wrapper } ${ !this.isSliderOn && !noMargins ? styles.hasMargins : '' }` } ref={ this.refWrap }>
                    <div
                        className={ `${ styles.List } ${ this.isTransitionOn ? styles.TransitionLeft : '' }` }
                        ref={ this.refList }
                        data-test={ ATTRIBUTES.tabsSliderList }
                    >
                        { this.props.children }
                    </div>
                </div>
                { this.isSliderOn && (
                    <div
                        onClick={ this.moveList('left') }
                        className={ arrowClass }
                        dangerouslySetInnerHTML={ { __html: IconChevronRight } }
                        data-test={ ATTRIBUTES.tabsSliderRight }
                    />
                ) }
            </div>
        );
    }
}

export default observer(TabsSlider);
