/**
 * Описание: Компонент для вывода текста
 * Параметры:
 * text - текст для вывода
 * */
import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import IconChevronUp from 'assets/img/icons/chevron-down.svg';
import IconChevronDown from 'assets/img/icons/chevron-up.svg';
import styles from './styles.module.scss';

class LongText extends React.Component {
    isOpened = false;
    isLongText = false;

    refText = React.createRef();

    constructor(props) {
        super(props);

        makeObservable(this, {
            isOpened: observable,
            isLongText: observable,
        });
    }

    componentDidMount(){
        if (this.refText.current.offsetHeight > 56){
            this.isLongText = true;
        } else {
            this.isLongText = false;
        }
    }

    componentDidCatch(error, info) {
        console.error(error, info);
    }

    render() {
        return (
            <div>
                <div ref={ this.refText } className={ styles.Control }  style={ this.isLongText && !this.isOpened ? { height: '56px' } : {} }>
                    { this.props.text }
                </div>
                <div className={ styles.ButtonContainer }>
                    { this.isOpened && this.isLongText && <div className={ styles.Icon }
                                             dangerouslySetInnerHTML={ { __html: IconChevronDown } }
                                             onClick={ () => { this.isOpened = !this.isOpened } } /> }
                    { !this.isOpened && this.isLongText && <div className={ styles.Icon }
                                             dangerouslySetInnerHTML={ { __html: IconChevronUp } }
                                             onClick={ () => { this.isOpened = !this.isOpened } } /> }
                </div>
            </div>
        );
    }
}

export default observer(LongText);