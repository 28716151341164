import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import * as _ from 'lodash';
import styles from './styles.module.scss';
import {TextEditor} from "components/textEditor";
import FieldWrapper from 'components/dynamicForms/view/fieldWrapper';
import RichTextModel from 'components/dynamicForms/model/field/RichTextModel';
import { getTestNameField } from 'helpers/data';
import { isChanged } from "helpers/form";
import { ATTRIBUTES } from 'constants/attributesForTests';

class RichText extends React.Component {
    model;

    constructor(props) {
        super(props);

        makeObservable(this, {
            model: observable,
        });

        if (props.model) {
            this.model = props.model;
        } else {
            this.model = new RichTextModel(props);
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props, prevProps)) {
            if (this.props.model) {
                this.model = this.props.model;
            } else {
                this.model.mergeData(this.props);
            }
        }
    }

    onChange = (value) => {
        const { onChange } = this.props;

        this.model.value = value;
        this.model.changed = isChanged(this.model.defaultValue, this.model.value);

        if (onChange) {
            onChange(this.model);
        }
    };

    render() {
        const { readonly: readOnly, value, isVisible, isMandatory, sys_column_name, column_type, setRawEditor, isAdditional } = this.model;

        const editor = (
            <div
                className={ styles.HTMLInput }
                data-test={ `${ getTestNameField(this.model) }-${ ATTRIBUTES.fieldRichText }` }
                data-test-visible={ isVisible }
                data-test-mandatory={ isMandatory }
                data-test-readonly={ readOnly }
                data-test-field-type={ sys_column_name ? column_type : undefined }
                data-test-field-name={ sys_column_name }
                ref={ this.model.ref }
            >
                <TextEditor
                    model={this.model}
                    value={value}
                    readOnly={readOnly}
                    isAdditional={isAdditional}
                    onChange={this.onChange}
                    setRawEditor={setRawEditor}
                />
            </div>
        );

        if (isAdditional) {
            return editor;
        }

        return (
            <FieldWrapper model={ this.model }>
                {editor}
            </FieldWrapper>
        );
    }
}

export default observer(RichText);
