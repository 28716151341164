import { beforeUnload } from 'helpers/form';
import FormsState from 'globalState/forms';

export let history = null;
let previous = 0;
export let prevLocationLength = null; // length - длинна истории при первом заходе на форму


function subscribeOnHistoryChange() {
    if (!history) return;
    const _push = history.push;
    const _goBack = history.goBack;

    history.push = (path, state) => {
        previous += 1;
        _push(path, state);
    };

    history.goBack = (defaultPath = '/') => {
        if (previous > 0) {
            previous -= 1;
            _goBack();
        }
        else {
            history.push(defaultPath);
        }
    };
}

export function setHistory(h) {
    history = h;
    subscribeOnHistoryChange();
}

export function goBack(defaultPath = '/') {
    const unload = beforeUnload({});
    if (unload && !confirm(unload)) {
        return false;
    }
    FormsState.clear();
    history && history.goBack(defaultPath);
}

export function helperRedirect(to, method, state = undefined) {
    const unload = beforeUnload({});
    if (unload && !confirm(unload)) {
        return false;
    }
    FormsState.clear();
    // TODO DEF0010563
    if (`${ history.location.pathname }${ history.location.search }` === to) return false;
    if (to && typeof to === 'string') {
        if (to.length > 0 && to.startsWith('/')) {
            history && history.push(to, state);
        }
        else {
            window.location = to;
        }
    }
    else {
        history && history.push(to);
    }
    runMethod(method);
    return true;
}

export function helperRedirectReplace(to, method = null) {
    history && history.replace(to);
    runMethod(method);
}

export function smartRedirect(to, method) {
    const redirectTo = window.localStorage.getItem('redirect');
    if (redirectTo) {
        window.localStorage.removeItem('redirect');
        helperRedirect(redirectTo, method);
    }
    else {
        helperRedirect(to, method);
    }
}

export function setSearchQueryString(search) {
    history.push({
        search,
    });
}

export function setSearchQuery(params) {
    const search = new URLSearchParams(params);
    setSearchQueryString(search.toString());
}

function runMethod(method) {
    setTimeout(() => {
        if (method) {
            method();
        }
    }, 1000);
}

export function historyGo(n) {
    const unload = beforeUnload({});
    if (unload && !confirm(unload)) {
        return false;
    }
    FormsState.clear();
    history.go(n);
}

/*
* Установить длинну истории браузера
* Параметры:
* history.action - имя вызова экшена
* history.length - длинна истории барузера
* */
export const setLocationHistoryLength = ({ action, length} ) => {
    const PUSH_TYPE = 'PUSH';

    if (action === PUSH_TYPE) {
        setPrevLocationLength(length - 1);
    }
};

export const setPrevLocationLength = (length) => {
    prevLocationLength = length;
};
