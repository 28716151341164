import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';

import { configure } from "mobx";

configure({
    enforceActions: "never",
    computedRequiresReaction: true,
    reactionRequiresObservable: true,
    // observableRequiresReaction: true,
    disableErrorBoundaries: true,
});

export default function App() {
    return (
        <Router>
            <Routes />
        </Router>
    );
}
