import { registerCodeHighlighting } from "@lexical/code";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import * as React from "react";

export const CodeHighlightPlugin = () => {
    const [editor] = useLexicalComposerContext();
    React.useEffect(() => {
        return registerCodeHighlighting(editor);
    }, [editor]);
    return null;
};
