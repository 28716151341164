import * as React from 'react';
import {makeObservable, observable} from 'mobx';
import _ from 'lodash';
import FormSectionModel from 'components/dynamicForms/model/FormSectionModel';
import { underscoreToCamelCase } from 'helpers/misc';
import { generateRandomString } from 'helpers/data';
import { BaseFormFieldType } from 'types/components/dynamicForms/baseFormField';
import {DynamicFormType, DynamicFormProps } from 'types/components/dynamicForms/dynamicForm';
import { FormSectionType, FormSectionData } from 'types/components/dynamicForms/formSection';

/**
 * класс модели формы
 */
export default class DynamicFormModel implements DynamicFormType{
    ref = React.createRef();
    sections: FormSectionType[] = [];
    isFormLoaded = false;
    name;
    formName;
    sysId = '';
    tableName = '';
    tableId;
    clientScripts;
    formId;
    formView;
    readOnlyForm;
    contextMenu;
    isServicePortal;
    attachBtn;
    silentMode;
    widgetId;
    isActivityFeedWidget = false;
    id;
    isSubForm;
    isWarning;
    isMandatory;
    updateRelatedListAfterSaveForm = () => {};
    updateUiActionsAfterSaveForm = () => {};
    disableUiActionOnSaveForm = () => {};
    setPageName = () => {};

    constructor(data: DynamicFormProps) {
        makeObservable(this, {
            sections: observable,
            isFormLoaded: observable,
            sysId: observable,
            tableName: observable,
            isActivityFeedWidget: observable,
        });

        this.sections = [];
        this.isFormLoaded = false;
        this.name = '';
        this.formName = '';
        this.sysId = '';
        this.tableName = '';
        this.tableId = '';
        this.clientScripts = [];
        this.formId = '';
        this.formView = '';
        this.readOnlyForm = false;
        this.contextMenu = null;
        this.isServicePortal = false;
        this.attachBtn = null;
        this.silentMode = false;
        this.widgetId = '';
        this.isActivityFeedWidget = false;
        this.id = '';
        this.isSubForm = false;
        this.isWarning = false;
        this.isMandatory = false;
        this.setData(data);
    }

    uiGetForm() {
        return this.ref && this.ref.current;
    }

    setData(data: DynamicFormProps) {
        if (!data || !data.sections) {
            return;
        }
        this.mergeData(data, ['sections']);
        if (!this.id){
            this.id = generateRandomString();
        }
        this.sections = data.sections.map((section: FormSectionData) => {
            section.clientScripts = this.clientScripts;
            section.sysId = this.sysId;
            section.isServicePortal = this.isServicePortal;
            section.tableName = this.tableName; // явно указываем, т.к. с бэка для секции tableName не приходит
            /**
             * если модель секции уже есть, то обновляем её, а не создаем новую
             * @type {FormSectionModel}
             */
            const sectionModel: FormSectionType | undefined = this.sections.find((item: FormSectionType) => item.serviceName === section.service_name);
            if (sectionModel) {
                (sectionModel).setData(section);
                return sectionModel;
            }
            else {
                return new FormSectionModel(section, this);
            }
        });
    }

    mergeData(data: DynamicFormProps, exclude: string[] = []) {
        _.each(data, (value, key) => {
            let property = underscoreToCamelCase(key);
            if (!exclude.includes(property) && this.hasOwnProperty(property) && !_.isEqual(this[property], value)) {
                this[property] = value;
            }
        });
    }

    getUniqueValue() {
        return this.sysId;
    }

    checkFormIsInvalid() {
        return this.sections.some((section: FormSectionType) => section.checkFormIsInvalid());
    }

    serialize(isAll){
        let data = {};
        let error: any[] = [];
        this.sections.forEach((section: FormSectionType) => {
            const sectionFields = section.serialize(isAll);
            data = {...data, ...sectionFields.data};
            error = [...error, ...sectionFields.error];
        });
        return { data, error };
    }

    getFields() {
        const fields: BaseFormFieldType[] = [];
        _.forEach(this.sections, (section: FormSectionType) => {
            _.forEach(section.elements, (element: BaseFormFieldType) => {
                fields.push(element);
            });
        });
        return fields;
    }
}
