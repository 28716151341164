import { action, observable, makeObservable } from 'mobx';

export class SortingRow {
    _id = '';
    _field = '';
    _direction = 'ascending';
    _displayValue = {};
    _disabled = false;

    constructor(data) {
        makeObservable(this, {
            _id: observable,
            _field: observable,
            _direction: observable,
            _displayValue: observable,
            _disabled: observable,
            setId: action,
            setField: action,
            setDirection: action,
            setDisplayValue: action,
            setDisabled: action,
        });

        if (data) {
            this._id = data.id;
            this._field = data.field;
            this._direction = data.direction;
            this._displayValue = data.displayValue || {};
            this._disabled = data.disabled || false;
        }
    }

    getId() {
        return this._id;
    }

    setId(value) {
        this._id = value;
    }

    getField() {
        return this._field;
    }

    setField(value) {
        this._field = value;
    }

    getDirection() {
        return this._direction;
    }

    setDirection(value) {
        this._direction = value;
    }

    getDisplayValue() {
        return this._displayValue;
    }

    setDisplayValue(value) {
        this._displayValue = value;
    }

    getDisabled() {
        return this._disabled;
    }

    setDisabled(disabled) {
        this._disabled = disabled;
    }
}

export const SortingRowState = SortingRow;

export default new SortingRow();
