import * as React from 'react';
import { observer } from 'mobx-react';
import InfoMessagesState from 'globalState/infoMessages';
import IconX from 'assets/img/icons/close-x.svg';
import styles from './styles.module.scss';
import FormsState from 'globalState/forms';

export class FormMessages extends React.Component {
    refMessages = React.createRef();

    constructor(props) {
        super(props);

        // Назначаем ссылку на компонент чтобы ее можно было найти зная fieldName из любого места
        //window.dynamicFormsGlobals['FormMessages'] = this;
        FormsState.setMessages(this);
    }


    scrollToMessages() {
        window.scrollTo(0, this.refMessages.current.offsetTop);
    }

    removeMessage(index) {
        InfoMessagesState.remove(index);
    }

    uiAddMessage(type, message, duration) {
        InfoMessagesState.push({
            type,
            message,
            duration,
        });
    }

    uiClearMessages() {
        InfoMessagesState.removeAll();
    }

    renderMessages() {
        return InfoMessagesState.messages.map((item) => {
            const classNames = [ styles.notification ];
            if (item.type === 'error') {
                classNames.push(styles.red);
            }
            else {
                classNames.push(styles.blue);
            }

            return <div key={ JSON.stringify(item) } className={ classNames.join(' ') }>
                <div
                    onClick={ () => {
                        this.removeMessage(item.id);
                    } }
                    className={ styles.close }
                    dangerouslySetInnerHTML={ { __html: IconX } }
                />
                <div className={ styles.content } dangerouslySetInnerHTML={ { __html: item.message } } />
            </div>;
        });
    }

    render() {
        return null;

        // return (
        //     <div ref={ this.refMessages }>
        //         { this.renderMessages() }
        //     </div>
        // );
    }
}

export default observer(FormMessages);
