import * as _ from 'lodash';
import StringInput from 'components/dynamicForms/view/field/stringInput';
import Reference from 'components/dynamicForms/view/field/reference';
import AuthFull from 'components/portalWidgetsComponents/AuthFull';
import GetList from 'components/portalWidgetsComponents/GetList';
import DropdownWidget from 'components/portalWidgetsComponents/DropdownWidget';
import DropdownWidgetItems from 'components/portalWidgetsComponents/DropdownWidget/DropdownWidgetItems';
import AuthHeader from 'components/portalWidgetsComponents/AuthHeader';
import Card from 'components/portalWidgetsComponents/Card';
import Search from 'components/portalWidgetsComponents/Search';
import SearchResults from 'components/portalWidgetsComponents/SearchResults';
import ColorInput from 'components/portalWidgetsComponents/ColorInput';
import ColoredText from 'components/portalWidgetsComponents/ColoredText';
import Button from 'components/button';
import Badge from 'components/badge';
import TextArea from 'components/dynamicForms/view/field/textArea';
import Checkbox from 'components/dynamicForms/view/field/checkbox';
import Select from 'components/dynamicForms/view/field/select';
import Image from 'components/dynamicForms/view/field/image';
import SideMenu from 'components/portalWidgetsComponents/SideMenu';
import CodeMirrorComponent from 'components/dynamicForms/view/field/codeMirror';
import Conditions from 'components/dynamicForms/view/field/conditions';
import Template from 'components/dynamicForms/view/field/template';
import List from 'components/dynamicForms/view/field/list';
import KindChart from 'components/chart/kindChart';
import DateInput from 'components/dynamicForms/view/field/dateTimeInput';
import DaysOfWeek from 'components/dynamicForms/view/field/daysOfWeek';
import Duration from 'components/dynamicForms/view/field/duration';
import RichText from 'components/dynamicForms/view/field/richText';
import ModalWindow from 'components/modalWindow';
import HtmlInput from 'components/dynamicForms/view/field/htmlInput';
import Report from 'components/report';
import WidgetForm from 'components/portalWidgetsComponents/WidgetForm';
import BreadCrumbs from 'components/portalWidgetsComponents/BreadCrumbs';
import ActivityFeed from 'components/activityFeed';
import Attachments from 'components/portalWidgetsComponents/Attachments';
import MultiSelectComponent from 'components/multiselect';
import ListWidget from 'components/portalWidgetsComponents/ListWidget';
import CategoryWidget from 'components/portalWidgetsComponents/CategoryWidget';
import PreviewListWidget from 'components/portalWidgetsComponents/PreviewListWidget';
import CategoryFlatWidget from 'components/portalWidgetsComponents/CategoryFlatWidget';
import REMWidget from 'components/portalWidgetsComponents/REMWidget';
import REMFormWidget from 'components/portalWidgetsComponents/REMFormWidget';
import DurationInput from 'components/portalWidgetsComponents/DurationInput';
import StatesFlow from 'components/statesFlow';
import Toggle from "components/toggle";
import OtherUser from 'components/otherUser';
import userState from 'globalState/user';
import indicatePresences from 'globalState/indicatePresences';
import FormsState from 'globalState/forms';
import widgetsDataState from 'globalState/widgets';
import {runScript} from 'helpers/scriptClientHelper';
import {simpleDecodeUri} from 'helpers/search';
import {WIDGET_NAMES} from 'constants/widgets';
import { DirectiveClass, WidgetTypes } from 'types/helpers/widget';
import type {
    ActivityFeedParams,
    ActivityFeedProps,
    ActivityFeedWidgetProps,
    AttachmentProps,
    AttachmentWidgetProps,
    AttrForWidget,
    AuthFullProps,
    AuthFullWidgetProps,
    AuthHeaderProps,
    AuthHeaderWidgetProps,
    ButtonProps,
    ButtonWidgetProps,
    CardProps,
    CardWidgetProps,
    CategoryProps,
    CategoryWidgetProps,
    ChartProps,
    ChartWidgetProps,
    ClassDirectiveAttr,
    CodemirrorProps,
    CodemirrorWidgetProps,
    ConditionsProps,
    ConditionsWidgetProps,
    DateProps,
    DateTimeProps,
    DateTimeWidgetProps,
    DateWidgetProps,
    DurationInputProps,
    DurationInputWidgetProps,
    FieldProps,
    FieldWidgetProps,
    FormProps,
    FormWidgetProps,
    ListItemsProps,
    ListItemsWidgetProps,
    ListProps,
    ListWidgetProps,
    MatchParams,
    ModalProps,
    ModalWidgetProps,
    MultiselectProps,
    MultiselectWidgetProps,
    PopupProps,
    PopupWidgetProps,
    PreviewListProps,
    PreviewListWidgetProps,
    ReferenceProps,
    ReferenceWidgetProps,
    RemFormProps,
    RemFormWidgetProps,
    RemProps,
    RemWidgetProps,
    SearchProps,
    SearchResultsProps,
    SearchResultsWidgetProps,
    SearchWidgetProps,
    SelectProps,
    SelectWidgetProps,
    SideMenuElement,
    SideMenuProps,
    SideMenuWidgetProps,
    StatesFlowProps,
    StatesFlowWidgetProps,
    TempProps,
    ToggleProps,
    ToggleWidgetProps,
    IIndicatePresenceProps,
    IIndicatePresenceWidgetProps,
    WidgetProps,
} from 'types/helpers/widget';
import type {WidgetData} from 'globalState/widgets/widgetData';
import type {WidgetDataType} from 'types/components/widgets';
import type {UserData} from 'types/globalState/user';


export const replaceWidgetCustom = (text: string, widgetId: string): string => {
    const widgetReplace = text.replace(/(s_widget\.[^(]*)\(/g, `$1('${widgetId}',`);
    let replacedScript = widgetReplace.replace(/s_widget_custom/g, `s_widget_custom['${widgetId}']`);
    replacedScript = replacedScript.replace(/s_widget_custom(\[[^\]]*\])(\[[^\.]*)\./g, replacerInn);  // убираем лишние скобки с s_widget_custom из скриптов в аттрибутах
    const addCustom = '=> { window.s_widget_custom = window.s_widget_custom || {}; window.s_widget_custom';
    return replacedScript.replace(/=>[^{]*{[^w}]*window.s_widget_custom/g, addCustom);
};

// убираем лишние скобки с s_widget_custom из скриптов в аттрибутах
export const replaceWidgetProp = (prop: string): boolean | string => {
    if (prop === 'true') {
        return true;
    }
    if (prop === 'false') {
        return false;
    }
    return prop.replace(/s_widget_custom(\[[^\]]*\])([^\.]*)\./g, replacerInn);
};

const replacerInn = (match: string, p1, p2: string): string => {
    return match.replace(p2, '');
};

export const evalScript = (clientScript: string, widgetId: string) => {
    if (clientScript) {
        const f = new Function('"use strict";' + replaceWidgetCustom(clientScript, widgetId));
        try {
            f();
        } catch (e) {
            console.error(e);
        }
    }
};

export const getType = (type: string) => {
    switch (type) {
        case WidgetTypes.String:
            return StringInput;
        case WidgetTypes.Reference:
            return Reference;
        case WidgetTypes.AuthFull:
            return AuthFull;
        case WidgetTypes.Attachment:
            return Attachments;
        case WidgetTypes.GetList:
            return GetList;
        case WidgetTypes.DropDownMenu:
            return DropdownWidget;
        case WidgetTypes.DropDownMenuItems:
            return DropdownWidgetItems;
        case WidgetTypes.AuthHeader:
            return AuthHeader;
        case WidgetTypes.Card:
            return Card;
        case WidgetTypes.Search:
            return Search;
        case WidgetTypes.Results:
            return SearchResults;
        case WidgetTypes.Color:
            return ColorInput;
        case WidgetTypes.ColoredText:
            return ColoredText;
        case WidgetTypes.Button:
            return Button;
        case WidgetTypes.Textarea:
            return TextArea;
        case WidgetTypes.Checkbox:
            return Checkbox;
        case WidgetTypes.Select:
            return Select;
        case WidgetTypes.SideMenu:
            return SideMenu;
        case WidgetTypes.Codemirror:
            return CodeMirrorComponent;
        case WidgetTypes.Conditions:
            return Conditions;
        case WidgetTypes.Template:
            return Template;
        case WidgetTypes.List:
            return List;
        case WidgetTypes.Chart:
            return KindChart;
        case WidgetTypes.Date:
            return DateInput;
        case WidgetTypes.Datetime:
            return DateInput;
        case WidgetTypes.DaysOfWeek:
            return DaysOfWeek;
        case WidgetTypes.Duration:
            return Duration;
        case WidgetTypes.Modal:
            return ModalWindow;
        case WidgetTypes.HtmlEditor:
            return HtmlInput;
        case WidgetTypes.Report:
            return Report;
        case WidgetTypes.PreviewList:
            return PreviewListWidget;
        case WidgetTypes.Form:
            return WidgetForm;
        case WidgetTypes.Breadcrumbs:
            return BreadCrumbs;
        case WidgetTypes.ActivityFeed:
            return ActivityFeed;
        case WidgetTypes.Multiselect:
            return MultiSelectComponent;
        case WidgetTypes.File:
            return Image;
        case WidgetTypes.ListItems:
            return ListWidget;
        case WidgetTypes.Category:
            return CategoryWidget;
        case WidgetTypes.CategoryFlat:
            return CategoryFlatWidget;
        case WidgetTypes.Rem:
            return REMWidget;
        case WidgetTypes.RemForm:
            return REMFormWidget;
        case WidgetTypes.StatesFlow:
            return StatesFlow;
        case WidgetTypes.Badge:
            return Badge;
        case WidgetTypes.DurationInput:
            return DurationInput;
        case WidgetTypes.Toggle:
            return Toggle;
        case WidgetTypes.WysiwygEditor:
            return RichText;
        case WidgetTypes.IndicatePresence:
            return OtherUser;
        default:
            return type;
    }
};

const applyClassDirective = (oldProps: TempProps): ClassDirectiveAttr => {
    let attr = {} as ClassDirectiveAttr;
    let classes: string[] = [];
    if (oldProps?.hasOwnProperty(DirectiveClass.CLASS)) {
        classes.push(oldProps[DirectiveClass.CLASS] as string);
        delete oldProps[DirectiveClass.CLASS];
    }
    if (oldProps?.hasOwnProperty(DirectiveClass.CLASS_NAME)) {
        classes.push(oldProps[DirectiveClass.CLASS_NAME] as string);
        delete oldProps[DirectiveClass.CLASS_NAME];
    }
    if (oldProps?.hasOwnProperty(DirectiveClass.CLASS_NAME_CC)) {
        classes.push(oldProps[DirectiveClass.CLASS_NAME_CC] as string);
        delete oldProps[DirectiveClass.CLASS_NAME_CC];
    }
    if (oldProps?.hasOwnProperty(DirectiveClass.DIRECTIVE_CLASS)) {
        classes.push(oldProps[DirectiveClass.DIRECTIVE_CLASS] as string);
        delete oldProps[DirectiveClass.DIRECTIVE_CLASS];
    }
    attr.className = classes.join(' ');
    return attr;
};

export const getAttr = (oldProps: WidgetProps) => {
    const {
        type,
        model,
        widgetDataState,
        ...props
    } = oldProps;

    const tempProps = getDataToProps(widgetDataState, props);

    let attr = {} as AttrForWidget;
    attr = {...attr, ...applyClassDirective(tempProps)};
    let modelItem = '';
    if (model) {
        const fieldIds = model.split('.');
        attr.value = widgetDataState.getFieldValue(fieldIds[1]) === undefined ? props.value : widgetDataState.getFieldValue(fieldIds[1]);
        attr.term = widgetDataState.getTerm(fieldIds[1]);
        modelItem = fieldIds[1];
    }
    attr.widgetId = widgetDataState.getId();

    switch (type) {
        case WidgetTypes.Chart:
            attr = {...attr, ...getChartProps(tempProps)};
            break;

        case WidgetTypes.List:
            attr = {...attr, ...getListProps(tempProps)};
            break;

        case WidgetTypes.Reference:
            attr = {...attr, ...getReferenceProps(tempProps)};
            break;

        case WidgetTypes.Template:
        case WidgetTypes.Conditions:
            attr = {...attr, ...getConditionsProps(tempProps)};
            break;

        case WidgetTypes.Select:
            attr = {...getSelectProps(tempProps), ...attr};
            break;

        case WidgetTypes.Multiselect:
            attr = {...attr, ...getMultiselectProps(tempProps, attr)};
            break;

        case WidgetTypes.Codemirror:
            attr = {...attr, ...getCodemirrorProps(tempProps)};
            break;

        case WidgetTypes.Datetime:
            attr = {...attr, ...getDateTimeProps(tempProps)};
            break;

        case WidgetTypes.Date:
            attr = {...attr, ...getDateProps(tempProps)};
            break;

        case WidgetTypes.ActivityFeed:
            attr = {...attr, ...getActivityFeedProps(tempProps, widgetDataState)};
            break;

        case WidgetTypes.Modal:
            attr = {...attr, ...getModalProps(tempProps)};
            break;

        case WidgetTypes.DropDownMenu:
            attr.modelItem = modelItem;
            attr = {...attr, ...getPopupProps(tempProps)};
            break;

        case WidgetTypes.Search:
            attr = {...attr, ...getSearchWidgetProps(tempProps)};
            break;

        case WidgetTypes.Results:
            attr = {...attr, ...getSearchResultsWidgetProps(tempProps)};
            break;

        case WidgetTypes.AuthHeader:
            attr = {...attr, ...getAuthHeaderProps(tempProps)};
            break;

        case WidgetTypes.ListItems:
            attr = {...attr, ...getListItemsProps(tempProps, widgetDataState)};
            break;

        case WidgetTypes.Category:
        case WidgetTypes.CategoryFlat:
            attr = {...attr, ...getCategoryWidgetProps(tempProps)};
            break;

        case WidgetTypes.PreviewList:
            attr = {...attr, ...getPreviewListWidgetProps(tempProps)};
            break;

        case WidgetTypes.AuthFull:
            attr = {...attr, ...getAuthFullProps(tempProps)};
            break;

        case WidgetTypes.Card:
            attr = {...attr, ...getCardProps(tempProps)};
            break;

        case WidgetTypes.Form:
            attr = {...attr, ...getFormProps(tempProps)};
            break;

        case WidgetTypes.SideMenu:
            attr = {...attr, ...getSideMenuProps(tempProps)};
            break;

        case WidgetTypes.Rem:
            attr = {...attr, ...getRemProps(tempProps)};
            break;

        case WidgetTypes.RemForm:
            attr = {...attr, ...getRemFormProps(tempProps)};
            break;

        case WidgetTypes.Button:
            attr = {...attr, ...getButtonProps(tempProps)};
            break;

        case WidgetTypes.StatesFlow:
            attr = {...attr, ...getStatesFlowProps(tempProps)};
            break;

        case WidgetTypes.Badge:
            attr = {...attr};
            break;

        case WidgetTypes.Textarea:
        case WidgetTypes.String:
        case WidgetTypes.Checkbox:
        case WidgetTypes.DaysOfWeek:
        case WidgetTypes.Duration:
        case WidgetTypes.HtmlEditor:
        case WidgetTypes.WysiwygEditor:
            attr = {...attr, ...getFieldProps(tempProps)};
            break;

        case WidgetTypes.Attachment:
            attr = {...attr, ...getAttachmentProps(tempProps)};
            break;

        case WidgetTypes.IndicatePresence:
            attr = { ...attr, ...getIndicatePresenceProps(tempProps) };
            break;

        case WidgetTypes.DurationInput:
            attr = {...attr, ...getDurationInputProps(tempProps)};
            break;

        case WidgetTypes.Toggle:
            attr = {...attr, ...getToggleProps(tempProps)};
            break;

        default:
            delete tempProps['isServicePortal'];
            break;
    }

    delete tempProps.style;
    delete tempProps.type;
    delete tempProps['event-change'];
    delete tempProps['event-click'];
    delete tempProps['event-mouseover'];
    delete tempProps['event-context'];

    return {...tempProps, ...attr};
};

const getFieldProps = (tempProps: FieldWidgetProps): FieldProps => {
    let attr = {} as FieldProps;
    if (tempProps.name) {
        attr.sysColumnName = tempProps.name;
        delete tempProps.name;
    }
    if (tempProps.fieldinfo) {
        attr.fieldInfo = JSON.parse(tempProps.fieldinfo);
        delete tempProps.fieldinfo;
    }
    // у полей в модели поле readonly
    if (tempProps.readOnly !== undefined) {
        attr.readonly = tempProps.readOnly;
        delete tempProps.readOnly;
    }
    return attr;
};

const getButtonProps = (tempProps: ButtonWidgetProps): ButtonProps => {
    let attr = {} as ButtonProps;
    if (tempProps.buttontype) {
        attr.buttonType = tempProps.buttontype;
        delete tempProps.buttontype;
    }
    if (tempProps.buttonsize) {
        attr.buttonSize = tempProps.buttonsize;
        delete tempProps.buttonsize;
    }
    return attr;
};

const getStatesFlowProps = (tempProps: StatesFlowWidgetProps): StatesFlowProps => {
    let attr = {} as StatesFlowProps;
    if (tempProps.states) {
        attr.states = JSON.parse(tempProps.states);
        delete tempProps.states;
    }
    return attr;
};

const getSideMenuProps = (tempProps: SideMenuWidgetProps): SideMenuProps => {
    let attr = {} as SideMenuProps;
    if (tempProps.parentsdepth) {
        attr.parentsDepth = tempProps.parentsdepth;
        delete tempProps.parentsdepth;
    }
    if (tempProps.childrendepth) {
        attr.childrenDepth = tempProps.childrendepth;
        delete tempProps.childrendepth;
    }
    if (tempProps.includecategories) {
        attr.includeCategories = tempProps.includecategories;
        delete tempProps.includecategories;
    }
    if (tempProps.includeitems) {
        attr.includeItems = tempProps.includeitems;
        delete tempProps.includeitems;
    }
    return attr;
};

const getFormProps = (tempProps: FormWidgetProps): FormProps => {
    let attr = {} as FormProps;
    if (tempProps.hasOwnProperty('tablename')) {
        attr.tableName = tempProps.tablename;
        delete tempProps.tablename;
    }
    if (tempProps.hasOwnProperty('sysid')) {
        attr.sysId = tempProps.sysid;
        delete tempProps.sysid;
    }
    if (tempProps.hasOwnProperty('titlehide')) {
        attr.titleHide = tempProps.titlehide;
        delete tempProps.titlehide;
    }
    if (tempProps.hasOwnProperty('uiactions')) {
        attr.uiActions = typeof tempProps.uiactions === 'boolean' ? tempProps.uiactions : tempProps.uiactions === 'true';
        delete tempProps.uiactions;
    }
    if (tempProps.hasOwnProperty('userscripts')) {
        attr.userScripts = typeof tempProps.userscripts === 'boolean' ? tempProps.userscripts : tempProps.userscripts === 'true';
        delete tempProps.userscripts;
    }
    if (tempProps.hasOwnProperty('name')) {
        attr.formName = tempProps.name;
        delete tempProps.name;
    }
    return attr;
};

const getCardProps = (tempProps: CardWidgetProps): CardProps => {
    let attr = {} as CardProps;
    if (tempProps.shortdescription) {
        attr.shortDescription = tempProps.shortdescription;
        delete tempProps.shortdescription;
    }
    return attr;
};

const getAuthFullProps = (tempProps: AuthFullWidgetProps): AuthFullProps => {
    let attr = {} as AuthFullProps;
    if (tempProps.isregistration !== undefined) {
        attr.isRegistration = tempProps.isregistration;
        delete tempProps.isregistration;
    }
    if (tempProps.usesourceurl !== undefined) {
        attr.useSourceUrl = tempProps.usesourceurl;
        delete tempProps.usesourceurl;
    }
    if (tempProps.isswitchlang !== undefined) {
        attr.isSwitchLang = tempProps.isswitchlang;
        delete tempProps.isswitchlang;
    }
    if (tempProps.isrememberme !== undefined) {
        attr.isRememberMe = tempProps.isrememberme;
        delete tempProps.isrememberme;
    }
    if (tempProps.isresetpassword !== undefined) {
        attr.isResetPassword = tempProps.isresetpassword;
        delete tempProps.isresetpassword;
    }
    if (tempProps.redirectto !== undefined) {
        attr.redirectTo = tempProps.redirectto;
        delete tempProps.redirectto;
    }
    if (tempProps.usernamevalidation !== undefined) {
        attr.usernameValidation = tempProps.usernamevalidation;
        delete tempProps.usernamevalidation;
    }
    if (tempProps.passwordvalidation !== undefined) {
        attr.passwordValidation = tempProps.passwordvalidation;
        delete tempProps.passwordvalidation;
    }
    return attr;
};

const getChartProps = (tempProps: ChartWidgetProps): ChartProps => {
    let attr = {} as ChartProps;
    if (tempProps.tablename) {
        attr.tableName = tempProps.tablename;
        delete tempProps.tablename;
    }
    if (tempProps.charttype) {
        attr.chartType = tempProps.charttype;
        delete tempProps.charttype;
    }
    return attr;
};

const getListProps = (tempProps: ListWidgetProps): ListProps => {
    let attr = {
        special: {
            table_name: tempProps.tablename || '',
            table_id: tempProps.tableid || '',
            dependency_map_link: tempProps.dependencymaplink || '',
            reference_qualifier: {
                condition: tempProps.condition || '',
                is_fixed: tempProps.isfixed,
            },
            can_create: !!tempProps.cancreate,
            can_read: tempProps.canread !== undefined ? tempProps.canread : true,
        },
    };
    delete tempProps.tablename;
    delete tempProps.tableid;
    delete tempProps.dependencymaplink;
    delete tempProps.condition;
    delete tempProps.isfixed;
    delete tempProps.cancreate;
    delete tempProps.canread;
    return {...getFieldProps(tempProps), ...attr};
};

const getReferenceProps = (tempProps: ReferenceWidgetProps): ReferenceProps => {
    let attr = {
        special: {
            table_name: tempProps.tablename || '',
            table_id: tempProps.tableid || '',
            dependency_map_link: tempProps.dependencymaplink || '',
            reference_qualifier: {
                condition: tempProps.condition || '',
                is_fixed: tempProps.isfixed,
            },
            can_create: !!tempProps.cancreate,
            can_read: tempProps.canread !== undefined ? tempProps.canread : true,
        },
    };
    delete tempProps.tablename;
    delete tempProps.tableid;
    delete tempProps.dependencymaplink;
    delete tempProps.condition;
    delete tempProps.isfixed;
    delete tempProps.cancreate;
    delete tempProps.canread;
    return {...getFieldProps(tempProps), ...attr};
};

const getConditionsProps = (tempProps: ConditionsWidgetProps): ConditionsProps => {
    let attr = {} as ConditionsProps;
    if (tempProps.tablename) {
        attr.tableName = tempProps.tablename;
        delete tempProps.tablename;
    }
    if (tempProps.tableid) {
        attr.tableId = tempProps.tableid;
        delete tempProps.tableid;
    }
    return {...getFieldProps(tempProps), ...attr};
};

const getSelectProps = (tempProps: SelectWidgetProps): SelectProps => {
    let attr = {
        special: {
            values: tempProps.options ? JSON.parse(tempProps.options) : [],
        },
    } as SelectProps;
    if (tempProps.radiobuttonsmode) {
        attr.radioButtonsMode = tempProps.radiobuttonsmode;
    }
    delete tempProps.radiobuttonsmode;
    delete tempProps.options;

    return {...getFieldProps(tempProps), ...attr};
};

const getMultiselectProps = (tempProps: MultiselectWidgetProps, oldAttr): MultiselectProps => {
    const attr = {
        options: tempProps.options ? JSON.parse(tempProps.options) : [],
        values: tempProps.values,
    };

    attr.options = tempProps.options ? JSON.parse(tempProps.options) : [];
    attr.values = oldAttr.value !== undefined ? oldAttr.value : tempProps.values;
    delete tempProps.options;
    delete tempProps.values;
    delete oldAttr.value;
    return {...getFieldProps(tempProps), ...attr};
};

const getCodemirrorProps = (tempProps: CodemirrorWidgetProps): CodemirrorProps => {
    const attr = {
        options: tempProps.options ? JSON.parse(tempProps.options) : {},
    };
    delete tempProps.options;
    return {...getFieldProps(tempProps), ...attr};
};

const getDateTimeProps = (tempProps: DateTimeWidgetProps): DateTimeProps => {
    let attr = {
        beginDate: tempProps.begindate || '',
        endDate: tempProps.enddate || '',
    };
    delete tempProps.begindate;
    delete tempProps.enddate;
    return {...getFieldProps(tempProps), ...attr};
};

const getDateProps = (tempProps: DateWidgetProps): DateProps => {
    let attr = {
        beginDate: tempProps.begindate || '',
        endDate: tempProps.enddate || '',
    } as DateProps;
    delete tempProps.begindate;
    delete tempProps.enddate;
    if (tempProps.validate !== undefined) {
        attr.validate = JSON.parse(tempProps.validate);
        delete tempProps.validate;
    }
    attr.pickertype = 'date';
    return {...getFieldProps(tempProps), ...attr};
};

const getActivityFeedProps = (tempProps: ActivityFeedWidgetProps, widgetDataState: WidgetData): ActivityFeedProps => {
    let attr = {} as ActivityFeedProps;
    if (widgetsDataState.hasOnlyOneForm()) {
        const widgetForm = widgetsDataState.getFormOnlyOne();
        attr.tableName = widgetForm.getTableNameForm();
        attr.sysId = widgetForm.getRecordIdForm();
        const matchTableName = attr.tableName?.match(/{data\.([^}]*)}/);
        if (matchTableName && matchTableName[1]) {
            attr.tableName = widgetForm.getFieldValue(matchTableName[1]) ? attr.tableName?.replace(/{data\.([^}]*)}/, widgetDataState.getFieldValue(matchTableName[1])) : '';
        }
        const matchSysId = attr.sysId?.match(/{data\.([^}]*)}/);
        if (matchSysId && matchSysId[1]) {
            attr.sysId = widgetForm.getFieldValue(matchSysId[1]) ? attr.sysId?.replace(/{data\.([^}]*)}/, widgetDataState.getFieldValue(matchSysId[1])) : '';
        }
    }
    attr.hasNotForm = !widgetsDataState.hasOnlyOneForm();
    if (tempProps.tablename !== undefined) {
        attr.tableName = tempProps.tablename;
        delete tempProps.tablename;
    }
    if (tempProps.sysid !== undefined) {
        attr.sysId = tempProps.sysid;
        delete tempProps.sysid;
        attr.hasNotForm = true; // Когда указываем конкретные атрибуты на запись, понятно, что не хотим, чтобы ссылалось на какую-то иную форму
    }
    if (tempProps.config !== undefined) {
        attr.config = JSON.parse(tempProps.config);
        if (attr.config.journal !== undefined) {
            attr.journal = _.split(_.replace(attr.config.journal, /\s/g, ''), ',');
        }
        if (attr.config.columns !== undefined) {
            attr.columns = _.split(_.replace(attr.config.columns, /\s/g, ''), ',');
        }
        if (attr.config.title !== undefined) {
            attr.title = attr.config.title;
        }
        if (attr.config.classes) {
            attr.classes = attr.config.classes;
        }
        attr.isNotStylized = false;
        if (attr.config.isNotStylized !== undefined) {
            attr.isNotStylized = attr.config.isNotStylized === 'true' || attr.config.isNotStylized === '1';
        }
        delete tempProps.config;
    }
    const data = widgetDataState.getData();
    attr.activities = data.response && data.response.activities ? data.response.activities : [];
    attr.activityTypes = data.response && data.response.activity_types ? data.response.activity_types : [];
    attr.historyFields = data.response && data.response.history_fields ? data.response.history_fields : [];
    attr.widgetId = widgetDataState.getId();
    if (tempProps.isalwaysopened) {
        attr.isAlwaysOpened = tempProps.isalwaysopened;
        delete tempProps.isalwaysopened;
    }
    return attr;
};

const getPopupProps = (tempProps: PopupWidgetProps): PopupProps => {
    let attr = {} as PopupProps;
    if (tempProps.doClose) {
        const closeStr = tempProps.doClose;
        attr.doClose = () => {
            runScript(closeStr);
        };
        delete tempProps.doClose;
    }
    if (tempProps.classnameactive) {
        attr.classNameActive = tempProps.classnameactive;
        delete tempProps.classnameactive;
    }
    return attr;
};

const getModalProps = (tempProps: ModalWidgetProps): ModalProps => {
    let attr = {} as ModalProps;
    if (tempProps.doClose || tempProps.doclose) {
        const closeStr = tempProps.doClose || tempProps.doclose as string;
        attr.doClose = () => {
            runScript(closeStr);
        };
        delete tempProps.doClose;
        delete tempProps.doclose;
    }
    if (tempProps.isfullscreenmobile) {
        attr.isFullScreenMobile = tempProps.isfullscreenmobile;
        delete tempProps.isfullscreenmobile;
    }
    if (tempProps.isshow) {
        attr.isShow = tempProps.isshow;
        delete tempProps.isshow;
    }
    return attr;
};

const getAuthHeaderProps = (tempProps: AuthHeaderWidgetProps): AuthHeaderProps => {
    let attr = {} as AuthHeaderProps;
    if (tempProps.profileurl) {
        attr.profileUrl = tempProps.profileurl;
        delete tempProps.profileurl;
    }
    return attr;
};

const getListItemsProps = (tempProps: ListItemsWidgetProps, widgetDataState: WidgetData): ListItemsProps => {
    let attr = {} as ListItemsProps;
    if (tempProps.condition) {
        const match = tempProps.condition.match(/{data\.([^}]*)}/);
        if (match && match[1]) {
            attr.condition = widgetDataState.getFieldValue(match[1]) ? tempProps.condition.replace(/{data\.([^}]*)}/, widgetDataState.getFieldValue(match[1])) : '';
            delete tempProps.condition;
        }
    }
    if (tempProps.tablename) {
        attr.tableName = tempProps.tablename;
        delete tempProps.tablename;
    }
    if (tempProps.choiceconditionname) {
        attr.choiceConditionName = tempProps.choiceconditionname;
        delete tempProps.choiceconditionname;
    }
    if (tempProps.dateconditionname) {
        attr.dateConditionName = tempProps.dateconditionname;
        delete tempProps.dateconditionname;
    }
    if (tempProps.perpage) {
        attr.perPage = tempProps.perpage;
        delete tempProps.perpage;
    }
    if (tempProps.listview) {
        attr.listView = tempProps.listview;
        delete tempProps.listview;
    }
    if (tempProps.displaycolumnnumber) {
        attr.displayColumnNumber = tempProps.displaycolumnnumber;
        delete tempProps.displaycolumnnumber;
    }
    if (tempProps.itempage) {
        attr.itemPage = tempProps.itempage;
        delete tempProps.itempage;
    }
    if (tempProps.itemview) {
        attr.itemView = tempProps.itemview;
        delete tempProps.itemview;
    }
    if (tempProps.fixedcondition) {
        attr.fixedCondition = tempProps.fixedcondition;
        delete tempProps.fixedcondition;
    }
    return attr;
};

const getCategoryWidgetProps = (tempProps: CategoryWidgetProps): CategoryProps => {
    let attr = {} as CategoryProps;
    if (tempProps.nodeid) {
        attr.nodeId = tempProps.nodeid;
        delete tempProps.nodeid;
    }
    if (tempProps.categorydescription) {
        attr.categoryDescription = tempProps.categorydescription;
        delete tempProps.categorydescription;
    }
    if (tempProps.categoryicon) {
        attr.categoryIcon = tempProps.categoryicon;
        delete tempProps.categoryicon;
    }
    if (tempProps.categorysubject) {
        attr.categorySubject = tempProps.categorysubject;
        delete tempProps.categorysubject;
    }
    if (tempProps.itemdescription) {
        attr.itemDescription = tempProps.itemdescription;
        delete tempProps.itemdescription;
    }
    if (tempProps.itemicon) {
        attr.itemIcon = tempProps.itemicon;
        delete tempProps.itemicon;
    }
    if (tempProps.itemsubject) {
        attr.itemSubject = tempProps.itemsubject;
        delete tempProps.itemsubject;
    }
    if (tempProps.hidedescription) {
        attr.hideDescription = tempProps.hidedescription;
        delete tempProps.hidedescription;
    }
    return attr;
};

const getSearchWidgetProps = (tempProps: SearchWidgetProps): SearchProps => {
    let attr = {} as SearchProps;
    if (tempProps.resultpage) {
        attr.resultPage = tempProps.resultpage;
        delete tempProps.resultpage;
    }
    if (tempProps.tsgroupid) {
        attr.tsGroupId = tempProps.tsgroupid;
        delete tempProps.tsgroupid;
    }
    if (tempProps.showtitle) {
        attr.showTitle = tempProps.showtitle;
        delete tempProps.showtitle;
    }
    if (tempProps.searchurl) {
        attr.searchUrl = tempProps.searchurl;
        delete tempProps.searchurl;
    }
    if (tempProps.searchquery) {
        attr.searchQuery = simpleDecodeUri(tempProps.searchquery);
        delete tempProps.searchquery;
    }
    if (tempProps.itempage) {
        attr.itemPage = tempProps.itempage;
        delete tempProps.itempage;
    }
    if (tempProps.itemview) {
        attr.itemView = tempProps.itemview;
        delete tempProps.itemview;
    }
    return attr;
};

const getSearchResultsWidgetProps = (tempProps: SearchResultsWidgetProps): SearchResultsProps => {
    let attr = {} as SearchResultsProps;
    if (tempProps.searchquery) {
        attr.searchQuery = simpleDecodeUri(tempProps.searchquery);
        delete tempProps.searchquery;
    }
    if (tempProps.istreeshown) {
        attr.isTreeShown = tempProps.istreeshown;
        delete tempProps.istreeshown;
    }
    if (tempProps.tsgroupid) {
        attr.tsGroupId = tempProps.tsgroupid;
        delete tempProps.tsgroupid;
    }
    if (tempProps.itempage) {
        attr.itemPage = tempProps.itempage;
        delete tempProps.itempage;
    }
    if (tempProps.itemview) {
        attr.itemView = tempProps.itemview;
        delete tempProps.itemview;
    }
    return attr;
};

const getPreviewListWidgetProps = (tempProps: PreviewListWidgetProps): PreviewListProps => {
    let attr = {} as PreviewListProps;
    if (tempProps.tablename) {
        attr.tableName = tempProps.tablename;
        delete tempProps.tablename;
    }
    if (tempProps.elementcount) {
        attr.elementCount = tempProps.elementcount;
        delete tempProps.elementcount;
    }
    if (tempProps.subjectcolumn) {
        attr.subjectColumn = tempProps.subjectcolumn;
        delete tempProps.subjectcolumn;
    }
    if (tempProps.statecolumn) {
        attr.stateColumn = tempProps.statecolumn;
        delete tempProps.statecolumn;
    }
    if (tempProps.rowstyle) {
        attr.rowStyle = tempProps.rowstyle;
        delete tempProps.rowstyle;
    }
    if (tempProps.itempage) {
        attr.itemPage = tempProps.itempage;
        delete tempProps.itempage;
    }
    if (tempProps.itemview) {
        attr.itemView = tempProps.itemview;
        delete tempProps.itemview;
    }
    if (tempProps.alllinktitle) {
        attr.allLinkTitle = tempProps.alllinktitle;
        delete tempProps.alllinktitle;
    }
    if (tempProps.alllinkpage) {
        attr.allLinkPage = tempProps.alllinkpage;
        delete tempProps.alllinkpage;
    }
    if (tempProps.datecolumn) {
        attr.dateColumn = tempProps.datecolumn;
        delete tempProps.datecolumn;
    }
    return attr;
};

const getRemProps = (tempProps: RemWidgetProps): RemProps => {
    let attr = {} as RemProps;
    if (tempProps.hasOwnProperty('tablename')) {
        attr.tableName = tempProps.tablename;
        delete tempProps.tablename;
    }
    if (tempProps.hasOwnProperty('recordid')) {
        attr.recordId = tempProps.recordid;
        delete tempProps.recordid;
    }
    if (tempProps.hasOwnProperty('modelid')) {
        attr.modelId = tempProps.modelid;
        delete tempProps.modelid;
    }
    if (tempProps.hasOwnProperty('isuserscripts')) {
        attr.isUserScripts = tempProps.isuserscripts;
        delete tempProps.isuserscripts;
    }
    if (tempProps.hasOwnProperty('isportal')) {
        attr.isPortal = tempProps.isportal;
        delete tempProps.isportal;
    }
    if (tempProps.hasOwnProperty('name')) {
        attr.formName = tempProps.name;
        delete tempProps.name;
    }
    if (tempProps.hasOwnProperty('parentformsectionmodel')) {
        attr.parentFormSectionModel = tempProps.parentformsectionmodel;
        delete tempProps.parentformsectionmodel;
    }
    return attr;
};

const getAttachmentProps = (tempProps: AttachmentWidgetProps): AttachmentProps => {
    let attr = {} as AttachmentProps;
    if (tempProps.hasOwnProperty('tablename')) {
        attr.tableName = tempProps.tablename;
        delete tempProps.tablename;
    }
    if (tempProps.hasOwnProperty('recordid')) {
        attr.recordId = tempProps.recordid;
        delete tempProps.recordid;
    }
    if (tempProps.hasOwnProperty('parentformsectionmodel')) {
        attr.parentFormSectionModel = tempProps.parentformsectionmodel;
        delete tempProps.parentformsectionmodel;
    }
    return attr;
};

const getIndicatePresenceProps = (tempProps: IIndicatePresenceWidgetProps) => {
    let attr = {} as IIndicatePresenceProps;
    if (tempProps.hasOwnProperty('tablename')) {
        attr.tableName = tempProps.tablename;
        delete tempProps.tablename;
    }
    if (tempProps.hasOwnProperty('recordid')) {
        attr.sysId = tempProps.recordid;
        delete tempProps.recordid;
    }
    attr.userSysId = userState?.user ? (userState.user as UserData).sys_id : '';
    attr.indicatePresence = indicatePresences.getIndicatePresence(attr.tableName, attr.sysId);
    return attr;
};

const getRemFormProps = (tempProps: RemFormWidgetProps): RemFormProps => {
    let attr = {} as RemFormProps;
    if (tempProps.hasOwnProperty('tablename')) {
        attr.tableName = tempProps.tablename;
        delete tempProps.tablename;
    }
    if (tempProps.hasOwnProperty('recordid')) {
        attr.recordId = tempProps.recordid;
        delete tempProps.recordid;
    }
    if (tempProps.hasOwnProperty('modelid')) {
        attr.modelId = tempProps.modelid;
        delete tempProps.modelid;
    }
    if (tempProps.hasOwnProperty('titlehide')) {
        attr.titleHide = tempProps.titlehide;
        delete tempProps.titlehide;
    }
    if (tempProps.hasOwnProperty('isuserscripts')) {
        attr.isUserScripts = tempProps.isuserscripts;
        delete tempProps.isuserscripts;
    }
    if (tempProps.hasOwnProperty('isportal')) {
        attr.isPortal = tempProps.isportal;
        delete tempProps.isportal;
    }
    if (tempProps.hasOwnProperty('classname')) {
        attr.className = tempProps.classname;
        delete tempProps.classname;
    }
    if (tempProps.hasOwnProperty('savebuttoncaption')) {
        attr.saveButtonCaption = tempProps.savebuttoncaption;
        delete tempProps.savebuttoncaption;
    }
    if (tempProps.hasOwnProperty('readonly')) {
        attr.readOnly = tempProps.readonly;
        delete tempProps.readonly;
    }
    if (tempProps.hasOwnProperty('name')) {
        attr.formName = tempProps.name;
        delete tempProps.name;
    }
    if (tempProps.hasOwnProperty('hidesavebutton')) {
        attr.hideSaveButton = tempProps.hidesavebutton;
        delete tempProps.hidesavebutton;
    }
    if (tempProps.hasOwnProperty('parentformsectionmodel')) {
        attr.parentFormSectionModel = tempProps.parentformsectionmodel;
        delete tempProps.parentformsectionmodel;
    }
    return attr;
};

const getDurationInputProps = (tempProps: DurationInputWidgetProps): DurationInputProps => {
    let attr: DurationInputProps = {};
    if (tempProps.onchange) {
        const onChangeStr = tempProps.onchange;
        attr.onChange = (ms: number) => {
            runScript(onChangeStr, {ms});
        };
        delete tempProps.onchange;
    }
    if (tempProps.maxvalue) {
        attr.maxValue = JSON.parse(tempProps.maxvalue);
        delete tempProps.maxvalue;
    }
    if (tempProps.exclude) {
        attr.exclude = JSON.parse(tempProps.exclude);
        delete tempProps.exclude;
    }
    if (tempProps.alignright) {
        attr.alignRight = tempProps.alignright;
        delete tempProps.alignright;
    }
    return attr;
};

const getToggleProps = (tempProps: ToggleWidgetProps): ToggleProps => {
    let attr = {} as ToggleProps;
    if (tempProps.boldtext) {
        attr.boldText = tempProps.boldtext;
        delete tempProps.boldtext;
    }
    if (tempProps.disabled) {
        attr.disabled = tempProps.disabled;
        delete tempProps.disabled;
    }
    if (tempProps.checked) {
        attr.checked = tempProps.checked;
    }
    if (tempProps['event-change']) {
        const onChange = tempProps['event-change'];
        attr.onChange = (value) => {
            if (onChange) {
                runScript(onChange, {value});
            }
        };
    }
    return attr;
};

const getDataToProps = (widgetDataState: WidgetData, oldProps: TempProps): TempProps => {
    const props = oldProps;
    for (const key in props) {
        if (props.hasOwnProperty(key) && typeof props[key] === 'string') {
            const match = props[key].match(/{data\.([^}]*)}/);
            if (match && match[1]) {
                props[key] = injectData(widgetDataState.getData(), props[key]);
            }
            if (props[key] === 'false' || props[key] === 'true') {
                props[key] = props[key] !== 'false';
            }
            if (!_.isNil(props[key]) && typeof props[key] === 'string') {
                const matchOptions = props[key].match(/{?options\.([^}]*)}?/);
                if (matchOptions && matchOptions[1]) {
                    props[key] = widgetDataState.getOptionValue(matchOptions[1]);
                }
            }
        }
    }
    return props;
};

// задание параметров для первоначального запроса к беку
export const getInitializeParams = (template: string): ActivityFeedParams | undefined => {
    const match = template.match(/<activityfeed/i);
    if (match && match[0]) {
        return getActivityFeedParams(template);
    }
};

const getActivityFeedParams = (template: string): ActivityFeedParams => {
    const params = window.location.href.split('/');
    let table_name: string;
    let record_id = '';
    if (params && params[3] === 'record' && params[4]) {
        table_name = params[4];
        record_id = params[5] ? params[5].replace(/\?.*/, '') : record_id;
    } else {
        const matchTableName = template.match(/table_name="([^"]*)"/);
        const matchSysId = template.match(/sys_id="([^"]*)"/);
        table_name = matchTableName && matchTableName[0] ? matchTableName[1] : '';
        record_id = matchSysId && matchSysId[0] ? matchSysId[1] : '';
    }
    return {
        table_name,
        record_id,
    };
};

export enum menuType {
    NODE,
    CATEGORY,
    ITEM,
}

/**
 * Функция определяет тип элемента
 * @param categoryId - id категории
 * @param itemId - id айтема
 * @returns {number} - тип
 */
export const getMenuType = (categoryId?: string, itemId?: string): menuType => {
    if (categoryId) {
        return menuType.CATEGORY;
    }
    if (itemId) {
        return menuType.ITEM;
    }
    return menuType.NODE;
};


/**
 * Функция проверяет, один ли page_template у элумунтов
 * @param prevElement - предыдущий элемент
 * @param newElement - элемент, на который переходим
 * @returns {boolean}
 */
export const hasElementsSamePage = (prevElement: SideMenuElement, newElement: SideMenuElement): boolean => {
    return prevElement.nodeId === newElement.nodeId && [
        menuType.NODE,
        menuType.CATEGORY,
        menuType.ITEM,
    ].includes(prevElement.type) && [
        menuType.NODE,
        menuType.CATEGORY,
        menuType.ITEM,
    ].includes(newElement.type);
};

/**
 * Функция подставляет в строку значения из данных
 * @param data - храилище данных
 * @param text - строка, в которой будем заменять выражения {data.some_variable} на значения соответствующих переменных из хранилища
 * @returns string - строка с подставленными данными
 */
export const injectData = (data: WidgetDataType, text: string): string => {
    const re = new RegExp('{\\s?data\\.([\\w\\.]+)\\s?}', 'g');
    let result = text;
    let match;
    while ((match = re.exec(text)) !== null) {
        const expr = match[0];
        const variableName = match[1];
        const value = getValueFromWidgetStore(data, variableName);
        if (typeof value === 'object' || Array.isArray(value)) {
            result = result.replace(expr, JSON.stringify(value));
        } else {
            result = result.replace(expr, value);
        }
    }
    return result;
};

export const getValueFromWidgetStore = (data: WidgetDataType, variableName: string): string => {
    const f = new Function('data', '"use strict"; return (data.' + variableName + ')'); // для возможности dot-walking
    let value;
    try {
        value = f(data) || '';
    } catch (e) {
        value = 'Invalid variable or object property name!';
    }
    return value;
};

export const getRecordLink = (match: MatchParams, tableName: string, id: string, itemPage: string, itemView = 'Default'): string => {
    if (match && match.params && itemPage) {
        const portal = match.params.portalSuffix || match.params.pageTemplatePathName;
        if (portal) {
            return `/${portal}/${itemPage}?table_name=${tableName}&record_id=${id}&view=${itemView}`;
        }
    }
    return `/record/${tableName}/${id}`;
};

export const updateWidgets = () => {
    updateActivityFeedWidget();
};

const updateActivityFeedWidget = () => {
    const widgets = FormsState.getWidgets();
    const activityFeedWidget = _.find(widgets, widget => widget.name === WIDGET_NAMES.activityFeed) as any;
    if (activityFeedWidget?.fetchResponseData) {
        activityFeedWidget.fetchResponseData();
    }
};

export const initCustomWidget = (widgetInstanceId) => {
    if (!window.s_widget_custom) {
        window.s_widget_custom = {};
    }
    if (!window.s_widget_custom[widgetInstanceId]) {
        window.s_widget_custom[widgetInstanceId] = {};
    }
};
