import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import * as React from 'react';
import { fetchPortalListData } from 'actions/list';
import { observable, makeObservable } from 'mobx';
import PaginationWidget from 'components/portalWidgetsComponents/ListWidget/PaginationWidget';
import ListFilterWidget from 'components/portalWidgetsComponents/ListWidget/ListFilterWidget';
import * as _ from 'lodash';
import ListSorting from 'components/portalWidgetsComponents/ListWidget/ListSorting';
import { helperRedirect } from 'helpers/history';
import { Link, withRouter } from 'react-router-dom';
import { getUrlParams, paramsToString } from 'helpers/data';
import { conditionStateClass as ConditionState } from 'globalState/conditions/condition';
import langStore from 'globalState/lang';
import { getRecordLink } from 'helpers/widget';
import { calculateCondtionString } from 'helpers/condition';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { checkAndRedirectPage, getCellValue } from 'helpers/tableHelpers';
import CellDescription from './CellDescription';
import { LINK_NOT_FOUND, LINK_TITLE_UNAVAILABLE, LINK_UNAVAILABLE } from 'constants/strings';
import { getLinkDisplayValue } from 'helpers/form';
import IconEyeOff from 'assets/img/icons/eye-off.svg';
import NumberOfElements from 'components/numberOfElements';
import ChosenOptionsState from 'globalState/chosenOptions';

/**
 * ListWidget component
 * @param {string} listView - вид
 * @param {string} tableName - имя таблицы
 * @param {string} [condition] - условие фильтра
 * @param {string} [choiceConditionName]
 * @param {string} [dateConditionName]
 * @param {string} [perPage]
 * @param {string} [displayColumnNumber]
 * @param {string} itemPage
 * @param {string} itemView
 */
class ListWidget extends React.Component {
    dataList = {};
    page = 1;
    sorting = '';
    condition = '';
    fixedCondition = '';
    conditionState = {};
    interval = null;
    isFetching = false;
    isScrollbarVisible = false;
    refWrapper = React.createRef();
    refTable = React.createRef();
    refScrollbar = React.createRef();
    timeout = null;
    chosenOptionsState = null;

    constructor(props) {
        super(props);

        makeObservable(this, {
            dataList: observable,
            page: observable,
            sorting: observable,
            condition: observable,
            fixedCondition: observable,
            conditionState: observable,
            interval: observable,
            isFetching: observable,
            isScrollbarVisible: observable,
        });

        const params = getUrlParams(props.location.search);
        this.conditionState = new ConditionState();
        this.chosenOptionsState = new ChosenOptionsState();

        this.condition = calculateCondtionString(
            props.condition,
            '',
            props.location.search,
        );

        this.fixedCondition = props.fixedCondition;

        if (this.condition) {
            this.conditionState.parseConditionString(this.condition, props.tableName);
        }
        this.page = params.page ? parseInt(params.page, 10) : 1;
    }

    componentDidMount() {
        this.fetchDataList();
        window.addEventListener('scroll', this.onWindowScroll);
        window.addEventListener('resize', this.onWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onWindowScroll);
        window.removeEventListener('resize', this.onWindowResize);
    }

    componentDidUpdate(prevProps) {
        const { condition, tableName, perPage } = this.props;
        const isNotEqualCondition = prevProps.condition !== condition;

        if (isNotEqualCondition || prevProps.tableName !== tableName || prevProps.perPage !== perPage) {
            this.condition = calculateCondtionString(
                condition,
                '',
                this.props.location.search,
            );
            if (this.condition) {
                this.conditionState.parseConditionString(this.condition, tableName);
            }
            this.fetchDataList();
        }
    }

    handleChangeSorting = sort => {
        let sorting = '';
        _.forEach(sort, (value, key) => {
            sorting += `^ORDERBY${ value !== 'asc' ? 'DESC' : '' }${ key }`;
        });
        this.sorting = sorting;
        this.page = 1;
        this.handleRunSearch();
    };

    handleChangeCondition = condition => {
        this.condition = calculateCondtionString(this.props.condition, condition);
        this.handleRunSearch();
    };

    replaceCondition = () => {
        return _.replace(this.condition, /\^ORDERBY[^&\\^)]*/g, '');
    };

    handleRunSearch = () => {
        this.redirectSearch();

        this.conditionState.parseConditionString(
            `${ this.replaceCondition() }${ this.sorting }`,
            this.props.tableName,
        );
        this.fetchDataList();
    };

    handleChangePage = page => {
        this.page = page;
        this.redirectSearch();
        this.fetchDataList();
    };

    redirectSearch = () => {
        const parsedSearchParams = getUrlParams(this.props.location.search);
        const params = {
            ...parsedSearchParams,
            condition: `${ this.replaceCondition() }${ this.sorting }`,
            page: this.page,
        };
        helperRedirect({
            search: paramsToString(params),
        });
    };

    getParams = (listPerPage) => {
        const { listView, perPage } = this.props;
        const params = { view: listView };
        if (this.condition || this.sorting) {
            params.condition = this.sorting ? `${ this.replaceCondition() }${ this.sorting }` : this.condition;
        }
        if (perPage) {
            params.per_page = parseInt(perPage, 10);
        } else if (listPerPage) {
            params.per_page = listPerPage;
        } else {
            params.per_page = 20;
        }
        params.page = this.page || 1;
        params.fixedCondition = this.fixedCondition;
        return params;
    };

    getData = async (perPage) => {
        const { tableName } = this.props;
        this.isFetching = true;
        await this.chosenOptionsState.fetchChosenListOptions('table', tableName);
        let listPerPage = '';
        if (perPage) {
            listPerPage = perPage;
        } else if (this.chosenOptionsState.getChosenOption()) {
            listPerPage = this.chosenOptionsState.getChosenOption();
        }
        const response = await fetchPortalListData({ ...this.getParams(listPerPage), essence: tableName });
        if (response.isOkStatus) {
            this.dataList = response.data;
            const lastPage = checkAndRedirectPage({
                perPage: this.dataList.pagination?.per_page || 0,
                total: this.dataList.pagination?.total || 0,
                isReturnResult: true,
            });
            this.page = lastPage || this.dataList.pagination?.page;
            await this.updateLastPage(lastPage, listPerPage, tableName);
            this.isFetching = false;
            this.setScrollbarDimensions();
            this.setScrollbarVisibility();
        }
    };

    updateLastPage = async (lastPage, listPerPage, tableName) => {
        if (lastPage) {
            const response = await fetchPortalListData({ ...this.getParams(listPerPage), essence: tableName });
            this.dataList = response.data;
        }
    }

    fetchDataList = async (perPage) => {
        const { tableName } = this.props;
        if (!tableName) return;

        if (this.isFetching) {
            this.interval = setInterval(async () => {
                if (!this.isFetching) {
                    await this.getData(perPage);
                    clearInterval(this.interval);
                }
            }, 300);
        }
        else {
            await this.getData(perPage);
        }
    };

    getInlineValue = (item, itemIndex, sysId, column, rowTableName) => {
        const { list_titles } = langStore.getTranslate();

        const {
            displayColumnNumber,
            match,
            tableName,
            itemPage,
            itemView,
        } = this.props;

        let result = (
            <span className={ styles.NotSet }>
                ({ list_titles && list_titles.not_set })
            </span>
        );

        const unavailableDataText = this.getUnavailableDataText(item.value, column.type);
        if (unavailableDataText){
            if ([LINK_UNAVAILABLE, LINK_TITLE_UNAVAILABLE].includes(item.value?.reference_state)){
                result = <>
                    <span className={ styles.BadgeEye } data-test={ ATTRIBUTES.badgeEye } dangerouslySetInnerHTML={ { __html: IconEyeOff } } />
                    <span className={ styles.NotSet }>{ unavailableDataText }</span>
                </>;
            } else {
                result = <span className={ styles.NotSet }>{ unavailableDataText }</span>;
            }
        }

        const cellValue = getCellValue(item.value, column.type, column.validate);
        if (!_.isNull(cellValue)) {
            result = cellValue;
        }

        if (
            !_.isNil(displayColumnNumber) &&
            itemIndex === parseInt(displayColumnNumber, 10)
        ) {
            const link = getRecordLink(match, rowTableName || tableName, sysId, itemPage, itemView);
            return (
                <Link className={ styles.Link } to={ link }>
                    { result }
                </Link>
            );
        }

        if (item.color && !_.isNull(cellValue)) {
            result = <div className={`${styles.StateBadge} ${styles[item.color]}`}>{ result }</div>;
        }

        return result;
    };

    getUnavailableDataText = (value, type) => {
        const { reference_titles } = langStore.getTranslate();
        let result = '';
        if ([LINK_UNAVAILABLE, LINK_TITLE_UNAVAILABLE, LINK_NOT_FOUND].includes(value?.reference_state)){
            if (value?.reference_state === LINK_NOT_FOUND && type === 'field_name') {
                result = _.lowerCase(reference_titles?.placeholder_column_not_found);
            } else {
                result = getLinkDisplayValue(value);
            }
        }
        return result;
    };

    renderSorting = () => {
        const sortingFields = this.conditionState.getSortingFields();
        let sort = {};
        _.forEach(sortingFields, sortField => {
            sort[sortField._field.dot_walking_attribute] =
                sortField._direction === 'ascending' ? 'asc' : 'desc';
        });
        return (
            <ListSorting
                columns={ this.dataList.columns }
                sort={ sort }
                onChangeSorting={ this.handleChangeSorting }
            />
        );
    };

    renderRows = () => {
        const { list_titles } = langStore.getTranslate();

        const rows = _.map(this.dataList.items, (row, index) => {
            let itemIndex = 0;
            const sysId = row.sys_id;
            const tableName = row.__table_name;
            const items = _.map(row, (item, name) => {
                const findInColumn = _.find(
                    this.dataList.columns,
                    column => column.db_column_name === name,
                );
                if (!findInColumn) {
                    return null;
                }
                itemIndex++;

                let tooltipText = list_titles ? list_titles.not_set : '';
                const cellValue = getCellValue(item.value, findInColumn.type, findInColumn.validate);
                const unavailableDataText = this.getUnavailableDataText(item.value, findInColumn.type);
                if (unavailableDataText){
                    tooltipText = unavailableDataText;
                } else if (!_.isNull(cellValue)) {
                    tooltipText = cellValue;
                }

                return (
                    <td key={ `column${ name }` } className={ styles.ListItem }>
                        <CellDescription
                            tooltipText={ tooltipText }
                            gradientClassName={ styles.ListGradient }
                        >
                            { this.getInlineValue(item, itemIndex, sysId, findInColumn, tableName) }
                        </CellDescription>
                    </td>
                );
            });
            return (
                <tr key={ `row${ index }` } className={ styles.ListRow }>
                    { items }
                </tr>
            );
        });
        return <tbody>{ rows }</tbody>;
    };

    get isDisablePageCalculation() {
        if (_.isEmpty(this.dataList)) {
            return null;
        }
        const { pagination: { total, enabled_pages_calculation } = {} } = this.dataList;
        return (!enabled_pages_calculation && total < 0) ;
    }

    onElScroll = ({ target }) => {
        const { current: scrollbarEl } = this.refScrollbar;
        const { current: wrapperEl } = this.refWrapper;

        if(!scrollbarEl || !wrapperEl) return;

        if(scrollbarEl.contains(target)){
            wrapperEl.scrollLeft = scrollbarEl.scrollLeft;
        }
        else if(wrapperEl.contains(target)){
            //timeout is for proper shift+mousewheel action
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                scrollbarEl.scrollLeft = wrapperEl.scrollLeft;
            },200);
        }
    };

    onWindowScroll = () => {
        this.setScrollbarVisibility();
    };

    onWindowResize = () => {
        this.setScrollbarDimensions();
    };

    setScrollbarVisibility = () => {
        const { current: wrapperEl } = this.refWrapper;
        if(!wrapperEl) return;

        const wrappRect = wrapperEl.getBoundingClientRect();
        const windowScrollTop = window.pageYOffset;
        const windowHeight = window.document.documentElement.clientHeight;

        this.isScrollbarVisible = wrappRect.top + windowScrollTop + wrappRect.height > windowScrollTop + windowHeight && wrappRect.top + windowScrollTop < windowScrollTop + windowHeight;
    };

    setScrollbarDimensions = () => {
        const { current: scrollbarEl } = this.refScrollbar;
        const { current: wrapperEl } = this.refWrapper;
        const { current: tableEl } = this.refTable;
        if(!scrollbarEl || !wrapperEl || !tableEl) return;

        const innerDiv = scrollbarEl.children[0];
        scrollbarEl.style.left = `${wrapperEl.getBoundingClientRect().left}px`;
        scrollbarEl.style.width = `${wrapperEl.getBoundingClientRect().width}px`;
        innerDiv.style.width = `${tableEl.getBoundingClientRect().width}px`;
    };

    render() {
        const { choiceConditionName, dateConditionName, className, tableName, perPage } = this.props;
        if (_.isEmpty(this.dataList)) {
            return null;
        }
        const choiceColumn = choiceConditionName
            ? _.find(
                this.dataList.columns,
                column => column.db_column_name === choiceConditionName,
            )
            : null;
        const { list_titles } = langStore.getTranslate();
        const { pagination: { total, totalItems: totalCount, enabled_pages_calculation } = {}, items } = this.dataList;

        let totalString = '';
        let totalNumber = 0;
        if (this.isDisablePageCalculation) {
            totalString = `${list_titles.total_items}: ${Math.abs(totalCount ? totalCount : total)}+`;
            totalNumber = Math.abs(totalCount ? totalCount : total);
        } else if (!items.length && !enabled_pages_calculation && this.page > 1) {
            totalString = '';
        } else {
            totalString = `${list_titles.total_items}: ${ totalCount || total }`;
            totalNumber = totalCount || total;
        }

        const fetchData = (isUpdate, perPage) => {
            this.fetchDataList(perPage);
        };

        return (
            <div
                className={ className || '' }
                data-test={
                    this.props['data-test']
                        ? this.props['data-test']
                        : `list-${ ATTRIBUTES.widget }`
                }
            >
                <div className={ styles.AllItems }>
                    <NumberOfElements
                        total={ totalNumber }
                        totalString={ totalString }
                        type='table'
                        name={ tableName }
                        doFetch={ fetchData }
                        chosenOptionsState={ this.chosenOptionsState }
                        isHideButtonList={ !!perPage }
                    />
                </div>
                <ListFilterWidget
                    filterFields={ this.conditionState.getFilterFields() }
                    onChangeCondition={ this.handleChangeCondition }
                    choiceConditionName={ choiceConditionName }
                    dateConditionName={ dateConditionName }
                    tableId={ this.dataList.sys_id }
                    choiceColumnId={ choiceColumn ? choiceColumn.db_column_id : null }
                    choiceLabel={
                        choiceColumn && choiceColumn.label
                            ? choiceColumn.label.toLowerCase()
                            : ''
                    }
                />
                <div className={ styles.ListWrap }>
                    <div onScroll={ this.onElScroll }  className={ styles.ListContainer } ref={ this.refWrapper }>
                        <table className={ styles.List } ref={ this.refTable }>
                            { this.renderSorting() }
                            { this.renderRows() }
                        </table>
                    </div>
                </div>
                <div onScroll={ this.onElScroll } className={ `${styles.ListScrollbar} ${this.isScrollbarVisible ? styles.active : ''}` } ref={ this.refScrollbar }><div/></div>
                <PaginationWidget
                    page={ this.page }
                    total={ this.dataList.pagination?.total }
                    perPage={ this.dataList.pagination?.per_page }
                    isDisablePageCalculation = { this.isDisablePageCalculation }
                    onChange={ this.handleChangePage }
                />
            </div>
        );
    }
}

export default withRouter(observer(ListWidget));
