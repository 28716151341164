import * as React from 'react';
import { observer } from 'mobx-react';

import styles from 'components/portalDesigner/styles.module.scss';

/**
 * состояние дизайнера когда страница не выбрана
 */
class PortalPageDesignerEmptyPage extends React.Component {
    render() {
        return <div className={ styles.View }>Choose page</div>;
    }
}

export default observer(PortalPageDesignerEmptyPage);
