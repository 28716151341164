export const eventType = {
    NAVIGATE: 'navigate',
    AFTER_SAVE_EVENT: 'afterSaveEvent',
    AFTER_SAVE_AND_GET_UI_ACTIONS_EVENT: 'afterSaveAndGetUiActionsEvent',
    AFTER_LOAD_BREADCRUMBS: 'afterLoadBreadcrumbs',
    VALIDATION: 'validation',
    AFTER_VALIDATION: 'afterValidation',
    AFTER_LOAD_WIDGETS: 'afterLoadWidgets',
    AF_COMMENT_TAB_CHANGED: 'activityFeedCommentTabChanged',
};
