import { observable, makeObservable } from 'mobx';
import * as _ from 'lodash';
import { EMPTY_CARD } from 'constants/taskBoard';

class TaskBoardLanesState {
    lanes = [];
    cards = {};

    setLanes = (lanes) => {
        let result = [ ...lanes ];
        result.sort(this.compare);
        this.lanes = result;
    };

    getLanes = () => {
        return this.lanes;
    };

    changeTwoOrder = (arr, firstIndex, nextIndex) => {
        const tempOrder = arr[firstIndex].order;
        arr[firstIndex].order = arr[nextIndex].order;
        arr[nextIndex].order = tempOrder;
    };

    // изменение order лейнов, когда мы перетаскиваем лейн
    changeLaneOrder = (parentSysId, sysId) => {
        let result = [ ...this.lanes ];
        this.changeOrderItems(result, parentSysId, sysId);
        result.sort(this.compare);
        this.lanes = result;
    };

    changeOrderItems = (result, parentSysId, sysId) => {
        const parentIndex = _.findIndex(result, item => item.sys_id === parentSysId);
        const currentIndex = _.findIndex(result, item => item.sys_id === sysId);
        if (!result[currentIndex] || !result[parentIndex] || result[currentIndex].order === result[parentIndex].order) {
            return;
        }
        if (currentIndex < parentIndex) {
            for (let i = currentIndex + 1; i <= parentIndex; i++) {
                if (result[currentIndex].order < result[i].order) {
                    this.changeTwoOrder(result, currentIndex, i);
                }
            }
        } else if (parentIndex < currentIndex) {
            for (let i = currentIndex - 1; i >= parentIndex; i--) {
                if (result[currentIndex].order > result[i].order) {
                    this.changeTwoOrder(result, currentIndex, i);
                }
            }
        }
    };

    compare = (a, b) => {
        if (a.order > b.order) {
            return 1;
        }
        if (b.order > a.order) {
            return -1;
        }
    };

    updateLanes = (data) => {
        const result = _.map(this.lanes, lane => {
            lane.order = data[lane.sys_id] || lane.order;
            return lane;
        });
        result.sort(this.compare);
        this.lanes = result;
    };

    setCards = (laneId, cards) => {
        let result = [...cards];
        result.sort(this.compare);
        this.cards[laneId] = result;
        this.cards = { ...this.cards };
    };

    getCards = (laneId) => {
        return this.cards[laneId] || [];
    };

    updateCards = (laneId, data, otherLaneId, otherSysId) => {
        if (otherLaneId && !_.isEmpty(this.cards[otherLaneId])) {
            const otherCard = _.find(this.cards[otherLaneId], card => card.sys_id === otherSysId);
            if (otherCard) {
                if (_.isEmpty(this.cards[laneId])) {
                    this.cards[laneId] = [];
                }
                this.cards[laneId].push(otherCard);
                this.deleteCard(otherLaneId, otherSysId);
            }
        }
        const result = _.map(this.cards[laneId], card => {
            card.order = data[card.sys_id] || card.order;
            card.lane_id = laneId;
            return card;
        });
        result.sort(this.compare);
        this.cards[laneId] = result;
        this.cards = { ...this.cards };
        this.deleteCard(laneId, EMPTY_CARD);
    };

    addCard = (laneId, card) => {
        let result = [ ...this.getCards(laneId) ];
        result.push(card);
        result.sort(this.compare);
        this.cards[laneId] = [ ...result ];
    };

    updateCard = (laneId, updatedCard) => {
        if (_.isEmpty(this.cards[laneId])) {
            this.cards[laneId] = [];
        }
        this.cards[laneId] = _.map(this.cards[laneId], card => {
            if (card.sys_id === updatedCard.sys_id) {
                return updatedCard;
            }
            return card;
        });
    };

    deleteCard = (laneId, cardId) => {
        const result = _.filter(this.cards[laneId], card => card.sys_id !== cardId);
        result.sort(this.compare);
        this.cards[laneId] = [ ...result ];
    };

    addEmptyCard = (laneId, cardId) => {
        let result = [ ...this.getCards(laneId) ];
        const findEmptyCard = _.find(result, card => card.sys_id === EMPTY_CARD);
        if (findEmptyCard) {
            return; // если пытается добавить вторую пустую карточку
        }
        const findCard = _.find(result, card => card.sys_id === cardId);
        if (!findCard) {
            return;
        }
        const card = { ...findCard, order: findCard.order + 1, sys_id: EMPTY_CARD };
        result.push(card);
        result.sort(this.compare);
        this.cards[laneId] = [ ...result ];
    };

    clearAllState = () => {
        this.lanes = [];
        this.cards = {};
    }

    constructor() {
        makeObservable(this, {
            lanes: observable,
            cards: observable,
        });
    }
}


export default new TaskBoardLanesState();
