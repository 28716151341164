import * as React from 'react';
import {observable, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import styles from './styles.module.scss';
import Button from 'components/button';
import Dropdown from 'components/dropdown';
import MobileModal from 'components/modalWrapper';
import Modal from 'components/modalWindow';
import Heading from "components/heading";
import {CardExport} from "./card";
import exportState, {ExportState} from 'globalState/exportState';
import langStore from "globalState/lang";
import userState from "globalState/user";
import {isMedia} from "helpers/html";
import {getSysIds, isFileLoaded} from "helpers/exports";
import {fetchDeleteExport, fetchDownloadUrl, fetchExport} from "actions/exportManager";
import IconDownloaded from "assets/img/icons/download.svg";
import IconMaximize from "assets/img/icons/size-maximize-2.svg";
import IconDownloadAll from "assets/img/icons/circle-down.svg";
import IconClose from "assets/img/icons/close-x.svg";
import IconTrash from "assets/img/icons/trash.svg";
import {ATTRIBUTES} from "constants/attributesForTests";
import type {CardData} from "types/components/exportManager";
import type {UserData} from "types/globalState/user";

export class ExportManagerComponent extends React.Component {
    showShadow = false;
    renderInFullMode = false;
    isMobile = false;
    refButton = React.createRef<HTMLButtonElement>();
    refDropdown = React.createRef<HTMLDivElement>();
    refContent = React.createRef<HTMLDivElement>();
    interval: NodeJS.Timeout;

    constructor(props) {
        super(props);

        makeObservable(this, {
            showShadow: observable,
            renderInFullMode: observable,
            isMobile: observable,
        });
    }

    componentDidMount() {
        document.addEventListener('click', this.onDocumentClick, true);
        document.addEventListener('scroll', this.onWindowScroll);
        window.addEventListener('resize', this.checkMedia);
        this.fetchData().catch(console.error);
        this.setIntervalFetch();
        this.checkMedia();
    }

    componentWillUnmount = () => {
        document.removeEventListener('click', this.onDocumentClick, true);
        document.removeEventListener('scroll', this.onWindowScroll);
        window.removeEventListener('resize', this.checkMedia);
        clearInterval(this.interval);
    };

    checkMedia = () => {
        const isMobile = isMedia('sm');
        const hasMobileChanged = this.isMobile !== isMobile;
        if (isMobile && hasMobileChanged && this.renderInFullMode) {
            this.handleCloseFullModal();
            exportState.isOpenDropDown = true;
        } else if(!isMobile && hasMobileChanged && exportState.isOpenDropDown) {
            exportState.isOpenDropDown = false;
            this.renderInFullMode = true;
        }
        this.isMobile = isMobile;
    };

    onDocumentClick = ({target}) => {
        const {current: dropdownEL} = this.refDropdown;
        const {current: buttonEL} = this.refButton;
        if (!dropdownEL || !buttonEL) return;

        if (!dropdownEL.contains(target) && !buttonEL.contains(target)) {
            exportState.isOpenDropDown = false;
        }
    };

    onWindowScroll = () => {
        exportState.isOpenDropDown = false;
    };

    onWindowScrollMenu = ({target}) => {
        const {cardsData} = exportState;

        this.showShadow = target.scrollTop > 0 && !!cardsData.length;
    };

    setIntervalFetch = (ms = 5000) => {
        this.interval = setInterval(async () => {
            if (this.renderInFullMode || exportState.isOpenDropDown) {
                await this.fetchData();
            }
        }, ms);
    }

    fetchData = async () => {
        const userId = (userState.user as UserData)?.sys_id;
        const {data: {exports, exportLimit = 20}} = await fetchExport({userId});
        ExportState.MAX_LENGTH = exportLimit;
        exportState.setCardsData(exports);
    }

    fetchDownloadUrl = async (sysIds: string[]): Promise<string[]> => {
        const userId = (userState.user as UserData)?.sys_id;
        const {data} = await fetchDownloadUrl({sysIds: sysIds, userId});
        return data.downloadUrls;
    }

    fetchDeleteExport = async (sysIds: string[]): Promise<void> => {
        const userId = (userState.user as UserData)?.sys_id;
        await fetchDeleteExport({sysIds: sysIds, userId});

    }

    handleOpenMenu = () => {
        exportState.isOpenDropDown = !exportState.isOpenDropDown;
        this.fetchData().catch(console.error);

        setTimeout(() => {
            const contentEl = this.refContent?.current;
            if (contentEl) {
                contentEl.addEventListener('scroll', this.onWindowScrollMenu);
            }
        }, 0);
    }

    handleCloseMenu = () => {
        exportState.isOpenDropDown = false;

        const contentEl = this.refContent?.current;
        if (contentEl) {
            contentEl.removeEventListener('scroll', this.onWindowScrollMenu);
        }
    }

    handleClickModalBtn = async () => {
        await exportState.fetchExportTable(true);
        this.onCloseWarningModal();
    }

    onCloseWarningModal = () => {
        exportState.showWarningModal = false;
    };

    handleOpenFullModal = () => {
        this.renderInFullMode = true;
        exportState.isOpenDropDown = false;
    }

    handleCloseFullModal = () => {
        this.renderInFullMode = false;
    }

    handleCancelSelected = () => {
        exportState.cancelSelectedCards();
    }

    handleRemoveCard = async (card) => {
        exportState.removeCard(card);
        await this.fetchDeleteExport([card.sysId]);
    }

    handleRemoveSelectedCards = async () => {
        const sysIds = exportState.selectedCardsIds;
        exportState.removeSelectedCards();
        await this.fetchDeleteExport(sysIds);
    }

    handleRemoveAllCards = async () => {
        const sysIds = getSysIds(exportState.cardsData);
        await this.fetchDeleteExport(sysIds);

        exportState.clearData();
    }

    handleDownloadCard = async (card: CardData) => {
        if (isFileLoaded(card.state)) {
            const urls = await this.fetchDownloadUrl([card.sysId]);
            this.openDownloadLinks(urls);
        }
    }

    handleDownloadSelectedCards = async () => {
        const sysIds = exportState.selectedCardsIds;
        const urls = await this.fetchDownloadUrl(sysIds);
        this.openDownloadLinks(urls);
    }

    handleDownloadAllCards = async () => {
        const sysIds = exportState.completeCardsIds;
        const urls = await this.fetchDownloadUrl(sysIds);
        this.openDownloadLinks(urls);
    }

    openDownloadLinks = (downloadUrls: string[]) => {
        downloadUrls.forEach(url => window.open(url, '_blank'));
    }

    renderFullModal = (content: JSX.Element) => {
        const {exports} = langStore.getTranslate();
        const {cardsLength} = exportState;

        const title = (
            <>
                {exports?.export_management}
                <span className={styles.ModalSubTitle}>
                   {`${cardsLength}/${ExportState.MAX_LENGTH}`}
                </span>
            </>
        );

        return (
            <Modal
                isShow={this.renderInFullMode}
                title={title}
                modalClassName={styles.FullModal}
                doClose={this.handleCloseFullModal}
            >
                {content}
            </Modal>
        );
    }

    renderContentControls = () => {
        const {exports} = langStore.getTranslate();
        const {selectedCardsIds} = exportState;
        const countSelected = selectedCardsIds.length;

        if (countSelected) {
            const downloadText = `${exports?.download} (${countSelected})`;
            const removeText = `${exports?.clear} (${countSelected})`;

            return (
                <div className={styles.ContentControls}>
                    <Button
                        buttonType={'primary'}
                        hint={downloadText}
                        svg={IconDownloadAll}
                        data-test={ATTRIBUTES.exportDownloadSelectedButton}
                        onClick={this.handleDownloadSelectedCards}
                    >
                        {this.renderInFullMode ? downloadText : `(${countSelected})`}
                    </Button>
                    <Button
                        hint={removeText}
                        svg={IconTrash}
                        data-test={ATTRIBUTES.exportRemoveSelectedButton}
                        onClick={this.handleRemoveSelectedCards}
                    >
                        {this.renderInFullMode ? removeText : `(${countSelected})`}
                    </Button>
                    <Button
                        onClick={this.handleCancelSelected}
                        data-test={ATTRIBUTES.exportCancelButton}
                    >
                        {exports?.cancel}
                    </Button>
                </div>
            );
        }

        return (
            <div className={styles.ContentControls}>
                <Button
                    buttonType={'primary'}
                    svg={IconDownloadAll}
                    data-test={ATTRIBUTES.exportDownloadAllButton}
                    onClick={this.handleDownloadAllCards}
                >
                    {exports?.download_all}
                </Button>
                <Button
                    svg={IconTrash}
                    data-test={ATTRIBUTES.exportRemoveAllButton}
                    onClick={this.handleRemoveAllCards}
                >
                    {exports?.clear_all}
                </Button>
            </div>
        );
    }

    renderContent = (isModal = false) => {
        const {exports} = langStore.getTranslate();
        const {cardsLength, cardsData, completeCardsIds} = exportState;
        if (!cardsLength) {
            return (
                <div className={styles.NotFoundContainer}>
                    <div className={`${styles.NotFoundIcon}`} dangerouslySetInnerHTML={{__html: IconDownloadAll}}/>
                    <span className={styles.NotFoundText}>
                        {exports?.no_files_prepared_for_export}
                    </span>
                </div>
            );
        }

        let classNames: string[] = [styles.ContentContainer];
        if (isModal) {
            classNames.push(styles.RowCards);
        } else {
            classNames.push(styles.ColumnCards);
        }

        const isFilesComplete = completeCardsIds.length > 1;

        return (
            <div
                className={classNames.join(' ')}
                ref={this.refContent}
            >
                {this.renderContentControls()}
                <div className={styles.CardsContainer}>
                    {cardsData.map(card => (
                            <CardExport
                                key={card.sysId}
                                card={card}
                                handleRemoveCard={this.handleRemoveCard}
                                handleClickCard={this.handleDownloadCard}
                                isMoreThanOne={isFilesComplete}
                            />
                        )
                    )}
                </div>
            </div>
        );
    }

    renderMobileDropdown = (content: JSX.Element) => {
        const {exports} = langStore.getTranslate();

        return (
            <MobileModal>
                <div className={styles.MobileDropdownContainer} ref={this.refDropdown}>
                    <div className={styles.MobileHeader}>
                        <div className={styles.MobileTitle}>
                            {exports?.export_management}
                        </div>
                        <Button
                            className={styles.MobileClose}
                            buttonType={'icon'}
                            svg={IconClose}
                            data-test={ATTRIBUTES.exportCloseModalButton}
                            onClick={this.handleCloseMenu}
                        />
                    </div>
                    <div className={styles.MobileMenu}>
                        {content}
                    </div>
                </div>
            </MobileModal>
        );
    }

    renderDropdown = (content: JSX.Element) => {
        const {exports} = langStore.getTranslate();
        const {cardsLength} = exportState;

        const headerStyles = [styles.Header];
        if (this.showShadow) {
            headerStyles.push(styles.Shadow);
        }

        return (
            <Dropdown
                refParent={this.refButton}
                ref={this.refDropdown}
                floatRight
            >
                <div className={styles.DropdownContainer}>
                    <div className={headerStyles.join(' ')}>
                        <Heading
                            noMargin
                            size={'h4'}
                        >
                            {exports?.export_management}
                            <span className={styles.SubTitle}>
                                {`${cardsLength}/${ExportState.MAX_LENGTH}`}
                            </span>
                        </Heading>
                        {
                            cardsLength ? (
                                <Button
                                    hint={exports?.maximize}
                                    buttonType={'icon'}
                                    svg={IconMaximize}
                                    data-test={ATTRIBUTES.exportFullSizeButton}
                                    onClick={this.handleOpenFullModal}
                                />
                            ) : null
                        }
                    </div>
                    {content}
                </div>
            </Dropdown>
        );
    }

    renderWarningModal = () => {
        const {exports} = langStore.getTranslate();

        return (
            <Modal
                isShow={exportState.showWarningModal}
                title={exports?.warning}
                modalClassName={styles.WarningModal}
                doClose={this.onCloseWarningModal}
            >
                <div>
                    <div className={styles.WarningModalText}>
                        {exports?.file_number_limit}
                    </div>
                    <div className={styles.WarningModalButtons}>
                        <Button
                            buttonType='secondary'
                            onClick={this.onCloseWarningModal}
                            data-test={ATTRIBUTES.exportCloseWarningModalButton}
                        >
                            {exports?.cancel}
                        </Button>
                        <Button
                            buttonType='primary'
                            onClick={this.handleClickModalBtn}
                            data-test={ATTRIBUTES.exportStartWarningModalButton}
                        >
                            {exports?.start}
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }

    render() {
        const {exports} = langStore.getTranslate();

        const renderMenu = this.isMobile ? this.renderMobileDropdown : this.renderDropdown;

        return (
            <div>
                <Button
                    hint={exports?.export_management}
                    buttonType={'icon'}
                    svg={IconDownloaded}
                    ref={this.refButton}
                    data-test={ATTRIBUTES.exportButton}
                    onClick={this.handleOpenMenu}
                />
                {exportState.isOpenDropDown && renderMenu(this.renderContent())}
                {this.renderInFullMode && this.renderFullModal(this.renderContent(true))}
                {this.renderWarningModal()}
            </div>
        );
    }
}

export const ExportManager = observer(ExportManagerComponent);
