import BaseFormFieldModel from './BaseFormFieldModel';
import { isChanged } from 'helpers/form';
import { getDisplayValueTranslate } from 'helpers/translations';

export default class DocumentIdModel extends BaseFormFieldModel {

    /**
     * строка поиска?
     * term
     *
     * @type {string}
     */
    term;

    columnType = 'id';

    constructor(data, parentFormSectionModel) {
        super(data, parentFormSectionModel);
        super.mergeData(data);
    }

    async uiSetValue(value) {
        if (value === '') {
            return this.uiClearValue();
        }

        const params = {
            table_name: this.sysTableName,
            column_id: this.columnId,
            value: value,
        };

        this.value = await getDisplayValueTranslate(params);
        this.changed = isChanged(this.defaultValue, this.value);
    }

    uiGetValue() {
        return this.value && this.value.document_id;
    }

    uiGetDisplayValue() {
        return this.value && this.value.record_title;
    }

    getValueForSave(){
        return this.value && this.value.document_id;
    }
}
