import apiRequest from 'lib/apiRequest';

export default class SimpleSystem {

    getTablePrefix = async () => {
        if (this.tablePrefix) {
            return this.tablePrefix;
        }
        const response = await new apiRequest('GET /system/prefix').send();
        if (response.status === 'OK') {
            this.tablePrefix = response.getData().prefix;
        }
        return this.tablePrefix;
    };

    getColumnPrefix = async (tableName) => {
        const response = await new apiRequest('GET /system/column-prefix').qs({'table_name': tableName}).send();
        let columnPrefix = '';
        if (response.status === 'OK') {
            columnPrefix = response.getData().column_prefix;
        }
        return columnPrefix;
    };
}