import React from 'react';
import langStore from "globalState/lang/index";
import styles from 'components/groupedTable/styles.module.scss';
import IconEyeOff from 'assets/img/icons/eye-off.svg';
import Thead from 'components/groupedTable/thead/index';
import { ATTRIBUTES } from 'constants/attributesForTests';
import Button from 'components/button/index';
import FixedHeadTable from 'components/groupedTable/fixedHead/index';
import { TableGroupsProps } from 'types/components/table/groups/index';
import GroupRow from 'components/groupedTable/groupRow/index';
import { observer } from 'mobx-react';
import GroupDataState from 'globalState/table/groupData/index';
import _ from 'lodash';
import TableState from 'globalState/table/index';

class TableGroups extends React.Component<TableGroupsProps> {
    renderGroups = () => {
        const groups = GroupDataState.getGroups();
        if (_.isEmpty(groups)) {
            return null;
        }
        const {
            tableState, conditionState, isBlankMode, classes, isMobile, onCheckRow, isShowAllGroups,
            usedByList, isWindow, table, isRelatedList, clientScripts, isServicePortal, handleChangeEditCol,
        } = this.props;
        const filterFields = conditionState ? conditionState.getFilterFields() : [];
        const tableName = TableState.getName();
        const openGroups = localStorage.getItem(`tableGroups${ tableName }`);
        const groupsHtml = _.map(groups, (group, index) => {
            return (
                <GroupRow
                    key={ `${ group.title }${ index }`}
                    tableState={ tableState }
                    onCheckRow={ onCheckRow }
                    table={ table }
                    isServicePortal={ isServicePortal }
                    isRelatedList={ isRelatedList }
                    isMobile={ isMobile }
                    isBlankMode={ isBlankMode }
                    isWindow={ isWindow }
                    usedByList={ usedByList }
                    filterFields={ filterFields }
                    conditionState={ conditionState }
                    clientScripts={ clientScripts }
                    classes={ classes }
                    group={ group }
                    isShowAllGroups={ isShowAllGroups }
                    openGroups={ openGroups ? JSON.parse(openGroups) : [] }
                    handleChangeEditCol={ handleChangeEditCol }
                />
            );
        });
        return (
            <tbody>
            { groupsHtml }
            </tbody>
        );
    };

    render() {
        const {
            tableState, conditionState, formId, isBlankMode, classes, isMobile, renderHeadConditionsMenu,
            isServicePortal, toggleSearchRowVisibility, isSearchRowVisible, isFixedHeadVisible,
            fixedHeadRef, fixedHeadRowRef, fixedContainerRef, usedByList, recordId, recordTableName,
            isWindow, maxLength, isHorizontalScrollVisible, fixedScrollRef, checkedRows, table,
            selectDictionary, tableWrapRef, tableRef, headRowRef, renderHeadListMenu, renderContextMenu,
        } = this.props;
        const { list_titles } = langStore.getTranslate();
        const filterFields = conditionState ? conditionState.getFilterFields() : [];
        const sortingFields = conditionState ? conditionState.getSortingFields() : [];

        const styleTableWrap = [ styles.tableWrap ];
        if (!isServicePortal) {
            styleTableWrap.push(styles.mainTable);
        }
        if (classes && classes.TableWrapper) {
            styleTableWrap.push(classes.TableWrapper);
        }

        const fixedHorizontalScroll = [ styles.fixedHorizontalScroll ];
        if (classes && classes.fixedHorizontalScroll) {
            fixedHorizontalScroll.push(classes.fixedHorizontalScroll);
        }

        let hiddenEntriesMessage;
        const pagination = tableState.getPagination();
        if (pagination.hidden_entries) {
            hiddenEntriesMessage = <div className={ styles.tableMessage }>
                <div className={ styles.icon } dangerouslySetInnerHTML={ { __html: IconEyeOff } } />
                { list_titles && list_titles.hidden_entries }
            </div>;
        }

        const headerClass = classes && classes.Header ? classes.Header : '';
        const selectButton = usedByList && checkedRows.length > 0 ? (
            <Button buttonType={ 'primary' } className={ styles.SelectButton } onClick={ selectDictionary }>
                { list_titles && list_titles.select_items }
            </Button>
        ) : null;
        return (
            <React.Fragment>
                <FixedHeadTable
                    isBlankMode={ isBlankMode }
                    classes={ classes }
                    isMobile={ isMobile }
                    filterFields={ filterFields }
                    sortingFields={ sortingFields }
                    toggleSearchRowVisibility={ toggleSearchRowVisibility }
                    isSearchRowVisible={ isSearchRowVisible }
                    isFixedHeadVisible={ isFixedHeadVisible }
                    fixedHeadRef={ fixedHeadRef }
                    fixedHeadRowRef={ fixedHeadRowRef }
                    fixedContainerRef={ fixedContainerRef }
                    table={ table }
                />
                <div className={ styleTableWrap.join(' ') } ref={ tableWrapRef }>
                    <table
                        className={ `${ styles.Table }` }
                        ref={ tableRef }
                        data-test={ ATTRIBUTES.table }
                    >
                        <Thead
                            reference={ headRowRef }
                            table={ table }
                            filterFields={ filterFields }
                            sortingFields={ sortingFields }
                            onSearchClick={ toggleSearchRowVisibility }
                            isSearchRowVisible={ isSearchRowVisible }
                            recordId={ recordId }
                            recordTableName={ recordTableName }
                            isBlankMode={ isBlankMode }
                            isWindow={ isWindow }
                            usedByList={ usedByList }
                            className={ headerClass }
                            formId={ formId }
                            maxLength={ maxLength }
                            isMobile={ isMobile }
                            data-test={ ATTRIBUTES.tableHeader }
                        />
                        { this.renderGroups() }
                    </table>
                    { renderHeadListMenu() }
                    { renderContextMenu() }
                    { renderHeadConditionsMenu() }
                    { hiddenEntriesMessage }
                </div>
                <div
                    className={ `${ fixedHorizontalScroll.join(' ') } ${ isHorizontalScrollVisible && !isMobile ? styles.visible : '' }` }
                    ref={ fixedScrollRef }>
                    <div />
                </div>
                { selectButton }
            </React.Fragment>
        );
    }
}

export default observer(TableGroups);
