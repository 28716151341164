import {TextEditorProps} from "./index";
import type { RefObject } from "react";

export interface NavigatorProps {
    editorRef: RefObject<HTMLDivElement>;
    fullSize: boolean;
    isAdditional: TextEditorProps["isAdditional"];
}

export interface MenuItem {
    title: string;
    link: string;
    isActive?: boolean;
    tag: Tag;
    key?: string;
}

export enum Tag {
    H1 = 'h1',
    H2 = 'h2'
}