import * as React from 'react';
import { observer } from 'mobx-react';
import BurgerMenu from 'components/burgerMenu';
import styles from './styles.module.scss';
import Heading from 'components/heading';
import actionsPanelState from 'globalState/actionsPanelState';
import { observable, makeObservable } from 'mobx';
import { isMedia } from 'helpers/html';
import sidebarState from 'globalState/sidebarState';
import { ATTRIBUTES } from 'constants/attributesForTests';

/**
 * Компонент Панель с действиями
 * @param {string} pageName - название страницы
 * @param {boolean} [isRelatedLists] - флаг, есть ли связанный список
 * @param {Object[]} contextMenu - контекстное меню для burger menu
 * @param {boolean} [hideBurgerMenu] - флаг скрытия burger menu
 * @param {string} recordId
 * @param {string} tableName
 * @method [onClickButton]
 * @param {boolean} [disableUiActions]
 * @param {*} headerButton
 * @param {*} center
 * @params {*} right
 */
class ActionsPanel extends React.Component {
    refPanel = React.createRef();
    showShadow = false;
    isMediaSm = false;

    constructor(props) {
        super(props);

        makeObservable(this, {
            showShadow: observable,
            isMediaSm: observable,
        });

        this.panelEl = null;
        this.spacerEl = null;
    }

    checkMedia = () => {
        this.isMediaSm = isMedia('sm');
    };

    setSpacerHeight = () => {
        if(!this.spacerEl) return;

        this.spacerEl.style.height = this.isMediaSm ? 0 : this.panelEl.offsetHeight + 'px';
    };

    componentDidMount() {
        if (!this.props.isRelatedLists) {
            this.panelEl = this.refPanel.current;
            this.spacerEl = document.createElement('div');

            const parent = this.panelEl.parentElement.parentElement;
            const firstChild = parent.firstChild;
            parent.insertBefore(this.spacerEl, firstChild);
            window.addEventListener('scroll', this.onWindowScroll);
            this.setSpacerHeight();

            actionsPanelState.panelEl = this.panelEl;
        }
        this.onWindowResize();
        window.addEventListener('resize', this.onWindowResize);
    }

    componentDidUpdate() {
        if (!this.props.isRelatedLists) {
            this.setSpacerHeight();
        }
    }

    componentWillUnmount() {
        if (!this.props.isRelatedLists) {
            window.removeEventListener('scroll', this.onWindowScroll);
            actionsPanelState.panelEl = null;
            this.spacerEl.remove();
        }
        window.removeEventListener('resize', this.onWindowResize);
    }

    onWindowScroll = () => {
        this.showShadow = window.pageYOffset > 0;
    };

    onWindowResize = () => {
        this.checkMedia();
    };

    getBackButton() {
        // todo вернуть, когда надо будет, сейчас story скрыть
        return null;
        // return <Button onClick={ () => { goBack(); } } buttonType='icon' svg={ ArrowLeft }/>;
    }

    render() {
        const burgerData = this.props.contextMenu || [];
        let panelClassList = [styles.Panel];
        if (!this.props.isRelatedLists) {
            if (!this.isMediaSm) {
                panelClassList.push(styles.FixedPanel);
                if (this.showShadow) {
                    panelClassList.push(styles.Shadow);
                }
            }
        }
        else {
            panelClassList.push(styles.RelatedPanel);
        }
        if(sidebarState.isPinned && sidebarState.isOpened) {
            panelClassList.push(styles.pinnedAside);
        }
        panelClassList = panelClassList.join(' ');

        return (
            <div className={ panelClassList } ref={ this.refPanel }>
                <div className={ styles.PanelLeft }>
                    { !this.props.isRelatedLists && this.getBackButton() }

                    { !this.props.hideBurgerMenu && <BurgerMenu data={ burgerData }
                                                                isRelatedLists={this.props.isRelatedLists}
                                                                pageName={ this.props.pageName }
                                                                recordId={ this.props.recordId }
                                                                tableName={ this.props.tableName }
                                                                onClickButton={ this.props.onClickButton }
                                                                disableUiActions={ this.props.disableUiActions } />
                    }

                    { !!this.props.pageName && !this.props.isRelatedLists &&
                    <Heading size={ 'h3' } noMargin isRegular className={ styles.PanelTitle } data-test={ ATTRIBUTES.headingTitle }>{ this.props.pageName }</Heading>
                    }

                    { this.props.headerButton }
                </div>
                <div className={ styles.PanelCenter }>
                    { this.props.center }
                </div>
                <div className={ styles.PanelRight }>
                    { this.props.right }
                </div>
            </div>
        );
    }
}

export default observer(ActionsPanel);
