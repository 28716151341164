/**
 * Описание: Список последних отредактированных пользователем workflow
 * */
import * as React from 'react';
import userState from 'globalState/user';

import styles from './styles.module.scss';
import langStore from "globalState/lang";
import { displayTimePassed } from './../helpers/datetime';

export default class WorkflowUserRecent extends React.Component {

    constructor(props) {
        super(props);
    }

    openWorkflow = (id) => {
        this.props.openWorkflow(id);
    };

    componentDidCatch(error, info) {
        console.error(error, info);
    }

    render(){
        const { user } = userState;
        const workflows = this.props.workflows
            .filter((elem) => (elem.edited_by === user.sys_id))
            .sort((elem1, elem2) => (elem2.edited_at - elem1.edited_at))
            .filter((elem, index) => (index < 5))
            .map((elem) => (
            <div key={ elem.sys_id } className={ styles.RecentWorkflow } onClick={ () => { this.openWorkflow(elem.sys_id) } }>
                <div className={ styles.RecentWorkflowImage }></div>
                <div className={ styles.RecentWorkflowName }>{ elem.name }</div>
                <div className={ styles.RecentWorkflowDate }>{ displayTimePassed(elem.edited_at) }</div>
            </div>
            ));

        const { workflow_titles } = langStore.getTranslate();
        return (
            <>
                <div className={ styles.RecentWorkflows }>
                    <div className={ styles.RecentWorkflowsText }>{ workflow_titles && workflow_titles.changed_by_me }</div>
                    <div className={ styles.RecentWorkflowsList }>{ workflows }</div>
                </div>
            </>
        );
    }
}