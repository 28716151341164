import InfoMessagesState from "globalState/infoMessages";
import {Messages} from "types/helpers/messages";

export const showInfoMessages = (messages: Array<Messages>) => {
    messages.map(({type, message}) => {
        switch (type) {
            case 'error':
                InfoMessagesState.pushError(message);
                break;
            case 'warning':
                InfoMessagesState.pushWarn(message);
                break;
            case 'success':
                InfoMessagesState.pushSuccess(message);
                break;
            default:
                InfoMessagesState.pushInfo(message);
        }
    });
};
