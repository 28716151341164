import styles from 'layouts/main/sidebar/menu/styles.module.scss';
import sidebarState from 'globalState/sidebarState';
import * as _ from 'lodash';
import { getIsSubmenuActive } from 'helpers/sidebar';
import { ATTRIBUTES } from 'constants/attributesForTests';
import MenuLink from 'layouts/main/sidebar/menu/menuLink';
import CategoryLink from 'layouts/main/sidebar/menu/categoryLink';
import { Link } from 'react-router-dom';
import IconEdit from 'assets/img/icons/edit-2.svg';
import IconStarFill from 'assets/img/icons/star-fill.svg';
import IconStar from 'assets/img/icons/star.svg';
import { MenuItemPropsType, MenuItemType } from 'types/globalState/menu';
import SubMenuItem from 'layouts/main/sidebar/menu/subMenuItem';
import * as React from 'react';
import { observer } from 'mobx-react';

function MenuItem({
                      item,
                      index,
                      level = 0,
                      filterValue,
                      renderMenuItems,
                      toggleFavorites,
                      handleClickEdit,
                      handleDynamicLinkClick,
                  }: MenuItemPropsType) {
    let favoritesClass: string[] | string = [ styles.linkBtn ];
    if (item.getIsFavorite()) {
        favoritesClass.push(styles.active);
    }
    favoritesClass = favoritesClass.join(' ');

    if ((!item.getItems().length && !item.getDynamic() && !sidebarState.getIsOpenDynamic()) || !item.getIsShow()) {
        return null;
    }

    const renderSubMenu = (item: MenuItemType, isActiveItem: boolean) => {
        if (!isActiveItem) {
            return null;
        }
        const subItems = _.map(item.getItems(), (subItem: MenuItemType, subIndex: number): JSX.Element | null => {
            return (
                <SubMenuItem
                    item={ item }
                    subItem={ subItem }
                    subIndex={ subIndex }
                    level={ level }
                    renderMenuItems={ renderMenuItems }
                    handleClickEdit={ handleClickEdit }
                    toggleFavorites={ toggleFavorites }
                    handleDynamicLinkClick={ handleDynamicLinkClick }
                    filterValue={ filterValue }
                    key={ `subItem${ subItem.getSysId() }${ subIndex }` }
                />
            );
        });
        const skeletonItem = sidebarState.getIsLoadingMenu() ? (
            <div className={ `${ styles.item } ${ styles.SkeletonListItem }` }>
                <div className={ styles.SkeletonListHolder } />
                <div className={ `${ styles.SkeletonListHolder } ${ styles.SkeletonListHolderWidth }` } />
            </div>
        ) : null;
        return (
            <>
                { subItems }
                { skeletonItem }
            </>
        );
    };

    const activeMenuItems = sidebarState.getIsOpenDynamic()
        ? sidebarState.getDynamicActiveMenuItems() : sidebarState.getActiveMenuItems();
    const findItem = _.find(activeMenuItems, cat => cat.sysId === item.getSysId());
    const isActiveFunc = (findItem, item, filterValue) => {
        if (item.getDynamic() || _.isEmpty(item.getItems())) {
            return false;
        }
        if (!findItem) {
            return item.getIsDefaultOpened();
        }
        return findItem.isOpen || (filterValue ? item.getIsDefaultOpened() : false);
    };
    const isActive = isActiveFunc(findItem, item, filterValue);
    const activeDynamicMenuId = window.localStorage.getItem('activeDynamicMenuId') || '';
    const isActiveSubmenuItem = getIsSubmenuActive(item);

    let itemClasses = [ styles.item ];
    if (isActive) {
        itemClasses.push(styles.active);
    }
    let menuId = new URLSearchParams(window.location.search).get('menu_id');
    if (!menuId) {
        menuId = sidebarState.getMenuId();
    }
    if (item.getSysId() === menuId
        || (isActiveSubmenuItem && !isActive) || (activeDynamicMenuId === item.getSysId())) {
        itemClasses.push(styles.activeSubmenuItem);
    }

    const renderMenuLink = () => {
        if (sidebarState.getIsOpenDynamic()) {
            if (item.getIsItem() || (item.getIsCategory() && _.isEmpty(item.getItems()))) {
                return (
                    <MenuLink
                        item={ item }
                        handleDynamicLinkClick={ handleDynamicLinkClick }
                    />
                );
            }
            return (
                <CategoryLink
                    item={ item }
                    filterValue={ filterValue }
                    handleDynamicLinkClick={ handleDynamicLinkClick }
                />
            );
        }
        if (!_.isEmpty(item.getItems())) {
            return (
                <CategoryLink
                    item={ item }
                    filterValue={ filterValue }
                    handleDynamicLinkClick={ handleDynamicLinkClick }
                />
            );
        }
        if ((_.isEmpty(item.getItems()) && item.getUrl() !== '' && item.getUrl() !== '#') || item.getDynamic()) {
            return (
                <MenuLink
                    item={ item }
                    handleDynamicLinkClick={ handleDynamicLinkClick }
                />
            );
        }
        return null;
    };

    return (
        <div
            key={ `Item${ item.getSysId() }${ index }` }
            className={ itemClasses.join(' ') }
            data-test={ ATTRIBUTES.sidebarItem }
        >
            <div className={ `${ styles.linkWrap }  ${ styles.linkCategory }` }
                 data-test={ level ? ATTRIBUTES.sidebarMenuItem : ATTRIBUTES.sidebarMenuCategory }
            >
                { renderMenuLink() }
                {
                    item.getIsEditAccess() && (
                        <Link
                            to={ item.getEditUrl() }
                            onClick={ handleClickEdit }
                            className={ styles.linkBtn }
                            dangerouslySetInnerHTML={ { __html: IconEdit } }
                            data-test={ ATTRIBUTES.sidebarLinkEdit }
                        />
                    )
                }
                { !item.getDynamic() && !sidebarState.getIsOpenDynamic() &&
                    <span
                        className={ favoritesClass }
                        onClick={ toggleFavorites(item) }
                        dangerouslySetInnerHTML={ { __html: item.getIsFavorite() ? IconStarFill : IconStar } }
                        data-test={ ATTRIBUTES.sidebarLinkFavorites }
                    />
                }
            </div>

            <div className={ styles.submenu }>
                { renderSubMenu(item, isActive) }
            </div>
        </div>
    );
}

export default observer(MenuItem);