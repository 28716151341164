import _ from 'lodash';
import { underscoreToCamelCase } from 'helpers/misc';
import { isEmpty } from 'helpers/isEmpty';

import { action, observable, makeObservable } from 'mobx';

import * as React from 'react';
import { runOnTypeScripts, TYPE_ON_CHANGE } from 'helpers/scriptClientHelper';
import { isChanged } from 'helpers/form';
import EventBusState from 'globalState/eventBus';
import { eventType } from 'constants/eventBusTypes';
import { BaseFormFieldType, Validate } from 'types/components/dynamicForms/baseFormField';
import { ScriptItem } from 'types/helpers/scriptClientHelper';

/**
 * Базовый класс модели поля формы
 */
export default class BaseFormFieldModel implements BaseFormFieldType {
    /**
     *
     * @type {{current: null}}
     */
    ref = React.createRef();

    /**
     *
     * maxLength
     *
     * @type {string}
     */
    maxLength;

    /**
     *
     * maxLength
     *
     * @type {string}
     */
    minLength;

    /**
     * sys_id колонки
     * column_id
     *
     * @type {string}
     */
    columnId;

    /**
     * тип поля
     * column_type
     *
     * @type {string}
     */
    columnType;

    /**
     * Доступность поля
     * hidden
     *
     * @type {boolean}
     */
    hidden;

    /**
     * class для компонента
     * className
     *
     * @type {string}
     */
    className;

    /**
     * индекс поля в секции
     *
     * @type {number}
     */
    index;

    /**
     * флаг который указывает обязательно ли поле для заполнения
     * is_mandatory
     *
     * @type {boolean}
     */
    _isMandatory;
    sourceMandatory;

    /**
     * выводит предупреждающее сообщение
     *
     * @type {boolean}
     */
    _isWarning = false;

    /**
     * флаг видимости поля
     *
     * @type {boolean}
     */
    isVisible = true;

    /**
     * сообщение которое выводится рядом с полем
     *
     * @type {{message: string, type: string} | null}
     */
    notice;

    /**
     * Имя поля, используется как дефолтное значение для label
     * @type {string}
     */
    name;

    /**
     * поле доступно только для чтения
     *
     * @type {boolean}
     */
    readonly;

    /**
     * изначальное значение readonly пришедшее с сервера
     */
    sourceReadOnly;

    /**
     * не отправлять в запросе на сохранение если true
     *
     * skip_in_request
     * @type {boolean}
     */
    skipInRequest = false;

    /**
     * Системное название поля
     * через него происходит обращение к полям из скриптов
     *
     * sys_column_name
     * @type {string}
     */
    sysColumnName;

    /**
     * Системное название таблицы
     *
     * sys_table_name
     * @type {string}
     */
    sysTableName;

    /**
     * конкатенация через точку системных названий таблицы и поля
     *
     * system_name
     * @type {string}
     */
    systemName;

    /**
     * правила валидации
     *
     * @type {*}
     */
    validate: Validate | null = null;

    /**
     * сообщение валидации в тултипе
     *
     * @type {*}
     */
    validateNotice: any = null;

    /**
     *
     * @type {boolean}
     */
    isValid = true;

    /**
     *
     * @type {*}
     */
    _value;

    /**
     * был ли изменен value
     */
    changed;

    /**
     *
     * @type []
     */
    _contextMenu;

    /**
     * компонент используется в редакторе ячеек листа
     * cellEditMode
     *
     * @type {boolean}
     */
    cellEditMode;

    /**
     *
     * @type {Boolean}
     */
    isUnavailable;

    /**
     * модель секции
     *
     * @type {FormSectionModel|null}
     */
    parentFormSectionModel;

    /**
     * @type {function}
     */
    onChange;

    /**
     * модель формы
     * form
     *
     * @type {DynamicFormModel}
     */
    form;

    /**
     * массив клиентских скриптов
     * @type {Array}
     */
    clientScripts: ScriptItem[] = [];

    /**
     * @type {function}
     */
    save; //метод для запуска сохранения значения при inline-редактировании

    /* DEF0010193 Без этого MobX не перерисовывает компонент после сохранения изменений существующей записи
     и смены значения поля в isMandatory через API */
    fieldId;

    /**
     *
     * Предыдущее значение поля
     *
     * @type {*}
     */
    previousValue;


    /**
     *
     * Дефолтное значение поля
     *
     * @type {*}
     */
    defaultValue;

    /**
     *
     * Флаг разрешает или запрещает выполнение клиент. скриптов
     *
     * @type {boolean}
     */
    allowRunScripts = true;

    /**
     *
     * Экста аттрибуты поля
     *
     * @type {string}
     */
    extraAttributes;

    /**
     * флаг который указывает показывать поле или нет
     *
     * @type {boolean}
     */
    notShow = false;

    separator;

    mask;

    format;

    messages;

    /**
     *
     * @param data {{}}
     * @param parentFormSectionModel {FormSectionModel|null}
     * @returns {BaseFormFieldModel}
     */
    constructor(data, parentFormSectionModel = null) {
        makeObservable(this, {
            className: observable,
            _isMandatory: observable,
            _isWarning: observable,
            isVisible: observable,
            notice: observable,
            name: observable,
            readonly: observable,
            sysColumnName: observable,
            validateNotice: observable,
            isValid: observable,
            _value: observable,
            changed: observable,
            _contextMenu: observable,
            cellEditMode: observable,
            isUnavailable: observable,
            notShow: observable,
            mergeData: action,
        });

        this.parentFormSectionModel = parentFormSectionModel; //parentFormSectionModel может приходить в data
        this.mergeData(data);
        if (this.parentFormSectionModel) {
            this.form = this.parentFormSectionModel.parentFormModel;
        }
        this.sourceReadOnly = data.read_only;
        this.sourceMandatory = data.is_mandatory;
        this.fieldId = Math.floor(Math.random() * Math.floor(Number.MAX_SAFE_INTEGER));
        this.defaultValue = data.value;
        EventBusState.on(eventType.VALIDATION, this.handleRequiredValidation);
        this.handleRequiredValidation();
    }

    /**
     * подпись рядом с полем
     *
     * @type {string}
     * @alias name
     */
    get label() {
        return this.name;
    }

    set label(value) {
        this.name = value;
    }

    /**
     * @alias readonly
     * @return {boolean}
     */
    get readOnly() {
        return this.readonly;
    }

    /**
     * @alias readonly
     * @param value
     */
    set readOnly(value) {
        this.readonly = value;
    }

    get tableName() {
        const section = this.parentFormSectionModel;
        return section && section.getTableName();
    }

    get sysId() {
        return this.parentFormSectionModel && this.parentFormSectionModel.sysId;
    }

    get contextMenu() {
        if (this._contextMenu) {
            return this._contextMenu;
        }

        const sectionModel = this.parentFormSectionModel;
        if (sectionModel) {
            if (sectionModel.contextMenu) {
                return sectionModel.contextMenu;
            }

            const formModel = sectionModel.parentFormModel;
            if (formModel && formModel.contextMenu) {
                return formModel.contextMenu;
            }
        }

        return [];
    }

    set contextMenu(value) {
        this._contextMenu = value;
    }

    get isMandatory() {
        return this._isMandatory;
    }

    set isMandatory(value) {
        if (this._isMandatory !== value) {
            this._isMandatory = value;
            if (this.parentFormSectionModel) {
                this.parentFormSectionModel.computeIsMandatory();
            }
        }
        this.isWarning = this.isMandatory && this.checkEmptyValue();
    }

    /**
     *
     * @return {boolean}
     */
    get isWarning() {
        return this._isWarning;
    }

    /**
     *
     * @param value {boolean}
     */
    set isWarning(value) {
        if (this._isWarning !== value) {
            this._isWarning = value;
            if (this.parentFormSectionModel) {
                this.parentFormSectionModel.computeIsWarning();
            }
        }
    }

    /**
     *
     * @param data
     * @param exclude
     */
    mergeData(data: any, exclude: string[] = []) {
        _.each(data, action((value, key) => {
            /**
             * для API
             * @type {*}
             */
            if (['tableName', 'model'].includes(key)) {
                return true;
            }
            this[key] = value;
            let property = underscoreToCamelCase(key);
            if (!exclude.includes(property) && !_.isEqual(this[property], value)) {
                this[property] = value;
            }
        }));
        this.setValidateRules();
    }

    /**
     *
     * @returns {*}
     */
    get value() {
        return this._value;
    }

    /**
     *
     * @param value {*}
     */
    set value(value) {
        if (value === undefined) {
            return;
        }
        const _value = this.setValue(value);
        const prevValue = this._value;

        if (!_.isEqual(this._value, _value)) {
            this.notice = null;
            this.previousValue = this.getValueForSave();
            this._value = _value;

            if (this.allowRunScripts) {
                this.runClientScripts(prevValue);
            }
        }
        this.isWarning = this.isMandatory && this.checkEmptyValue();
    }

    /**
     * Преобразует value к нужному виду
     */
    setValue(value) {
        return !_.isNil(value) ? value : '';
    }

    get databaseValue() {
        return this.value;
    }

    set databaseValue(value) {
        this.value = value;
    }

    get displayValue() {
        return this.value;
    }

    set displayValue(value) {
        this.value = value;
    }

    clear() {
        this.value = '';
    }

    /**
     *
     * @returns {boolean}
     */
    checkEmptyValue() {
       return isEmpty(this.value);
    }

    isSplit() {
        return false;
    }

    uiGetLabelOf() {
        return this.label;
    }

    uiSetLabelOf(value) {
        return this.label = value;
    }

    uiGetValue() {
        return this.value;
    }

    uiGetDisplayValue() {
        return this.value;
    }

    uiSetValue(value) {
        if (value === '') {
            return this.uiClearValue();
        }
        if (_.isEqual(this.defaultValue, value)) {
            this.changed = false;
        } else if (!_.isEqual(this.value, value)) {
            this.changed = true;
        }
        this.value = value;
    }

    uiClearValue() {
        if (this.sysColumnName === 'sys_id') {
            return false;
        }
        this.value = null;
        this.changed = isChanged(this.defaultValue, this.value);
        return true;
    }

    uiSetReadOnly(readOnly = true) {
        if (this.sourceReadOnly || (this.isMandatory && this.checkEmptyValue())) {
            return false;
        }

        this.readOnly = !!readOnly;
        return true;
    }

    uiSetMandatory(mandatory = true) {
        if (this.sourceMandatory || (this.readOnly && this.checkEmptyValue())) {
            return false;
        }

        this.isMandatory = !!mandatory;
        return true;
    }

    uiSetVisible(display) {
        if (display === undefined && this.isVisible === true) return true;
        this.isVisible = !!display;
    }

    getValueForSave(){
        return this.value;
    }


    showFieldMsg(message, type) {
        this.notice = {
            message: message,
            type: type,
        };
    }
    hideFieldMsg() {
        this.notice = null;
    }

    setValidateRules() {
        if(this.validate) {
            if (this.validate.separator) {
                this.separator = this.validate.separator;
            }
            if (this.validate.mask) {
                this.mask = this.validate.mask;
            }
            if (this.validate.format) {
                this.format = this.validate.format;
            }
            if (this.validate.max_length) {
                this.maxLength = this.validate.max_length;
            }
            if (this.validate.min_length) {
                this.minLength = this.validate.min_length;
            }
            if (this.validate.messages) {
                this.messages = this.validate.messages;
            }
        }

    }

    showValidateMsg(message) {
        this.validateNotice = {
            message: message,
        };
    }

    hideValidateMsg() {
        this.validateNotice = null;
    }

    handleRequiredValidation = (fields?: []) => {
        if (this.sysColumnName && _.includes(fields, this.sysColumnName)) {
            if (this.isMandatory) {
                EventBusState.emit(eventType.AFTER_VALIDATION, {
                    name: this.sysColumnName,
                    isValid: !!this.value,
                });
            }
        }
    };

    runClientScripts(prevValue) {
        const clientScripts = this.parentFormSectionModel && this.parentFormSectionModel.clientScripts;
        const parentFormModelId = this.parentFormSectionModel?.parentFormModel?.id || this.parentFormSectionModel?.parentFormId || this.parentFormSectionModel?.id;
        if (clientScripts && parentFormModelId && prevValue !== undefined) {
            runOnTypeScripts(clientScripts, TYPE_ON_CHANGE, this.columnId, null, parentFormModelId,
                this.parentFormSectionModel.parentFormModel?.isSubForm);
        }
    }
}
