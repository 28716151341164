import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import ListFilterSearchKeywords from './ListFilterSearchKeywords';
import * as React from 'react';
import styles from './styles.module.scss';
import ListFilterSearchDate from './ListFilterSearchDate';
import ListFilterSearchState from 'components/portalWidgetsComponents/ListWidget/ListFilterWidget/ListFilterSearchState';
import { fetchTableChoices } from 'actions/conditions';
import langStore from 'globalState/lang';
import IconChevron from 'assets/img/icons/chevron-down.svg';

/**
 * @method onChangeCondition
 * @param {string} choiceConditionName
 * @param {string} tableId
 * @param {string} choiceColumnId
 * @param {array} filterFields
 * @param {string} dateConditionName
 */
class ListFilterWidget extends React.Component {
    search = '';
    dateValue = {
        beginDate: null,
        endDate: null,
    };
    choiceOptions = [];
    choiceValues = [];
    isOpened = false;

    constructor(props) {
        super(props);

        makeObservable(this, {
            search: observable,
            dateValue: observable,
            choiceOptions: observable,
            choiceValues: observable,
            isOpened: observable,
        });

        if (props.choiceColumnId) {
            this.fetchChoiceOptions();
        }
        if (!_.isEmpty(props.filterFields)) {
            this.initValues();
        }
    }

    componentDidUpdate(prevProps) {
        const { choiceColumnId, filterFields } = this.props;
        if (choiceColumnId !== prevProps.choiceColumnId) {
            this.fetchChoiceOptions();
        }
        if (!_.isEmpty(filterFields) && !_.isEqual(filterFields, prevProps.filterFields)) {
            this.initValues();
        }
    }

    fetchChoiceOptions = async () => {
        const { choiceColumnId, tableId } = this.props;
        const params = {
            column_id: choiceColumnId,
            table_id: tableId,
        };
        const response = await fetchTableChoices(params);
        if (response.isOkStatus) {
            this.choiceOptions = response.data;
        }
    };

    initValues = () => {
        const { filterFields, choiceConditionName, dateConditionName } = this.props;
        _.forEach(filterFields, filterRow => {
            if (filterRow.getField().dot_walking_attribute === 'keywords') {
                this.search = filterRow.getValue();
            }
            if (filterRow.getField().dot_walking_attribute === choiceConditionName) {
                this.choiceValues = Array.isArray(filterRow.getValue())
                    ? _.map(filterRow.getValue(), value => value.database_value )
                    : [ filterRow.getValue().database_value ];
            }
            if (filterRow.getField().dot_walking_attribute === dateConditionName) {
                if (filterRow.getOperator().database_value === '>') {
                    this.dateValue.beginDate = filterRow.getValue();
                } else if (filterRow.getOperator().database_value === '<') {
                    this.dateValue.endDate = filterRow.getValue();
                } else if (filterRow.getOperator().database_value === 'BETWEEN') {
                    this.dateValue.beginDate = filterRow.getValue()[0];
                    this.dateValue.endDate = filterRow.getValue()[1];
                }
            }
        });
    };

    handleChangeDateValue = (data) => {
        this.dateValue = data;
    };

    handleChangeSearch = (search) => {
        this.search = search;
    };

    handleChangeChoice = (values) => {
        this.choiceValues = values;
        this.handleChangeCondition();
    };

    handleChangeCondition = () => {
        const { onChangeCondition, choiceConditionName, dateConditionName } = this.props;
        let condition = this.search ? `keywordsARE${this.search}` : '';
        if (!_.isEmpty(this.choiceValues)) {
            condition += `${condition ? '^' : ''}${choiceConditionName}IN${this.choiceValues.join('@')}`;
        }
        const beginDate = this.dateValue.beginDate;
        const endDate = this.dateValue.endDate;
        if (!_.isNil(beginDate) || !_.isNil(endDate)) {
            if (!_.isNil(beginDate) && !_.isNil(endDate)) {
                condition += `${condition ? '^' : ''}${dateConditionName}BETWEEN${beginDate}@${endDate}`;
            } else if (!_.isNil(endDate)) {
                condition += `${condition ? '^' : ''}${dateConditionName}<${endDate}`;
            } else {
                condition += `${condition ? '^' : ''}${dateConditionName}>${beginDate}`;
            }
        }
        if (onChangeCondition) {
            onChangeCondition(condition);
        }
    };

    renderFilterDate = () => {
        const { dateConditionName } = this.props;
        if (!dateConditionName) {
            return null;
        }
        const { data_picker_titles } = langStore.getTranslate();
        return (
            <div className={ styles.FilterItem }>
                <ListFilterSearchDate
                    value={ this.dateValue }
                    onChange={ this.handleChangeDateValue }
                    placeholder={ data_picker_titles && data_picker_titles.filter_by_date }
                    onSearch={ this.handleChangeCondition }
                    pickertype={'date'}
                />
            </div>
        );
    };

    renderFilterState = () => {
        const { choiceConditionName, choiceLabel } = this.props;
        if (!choiceConditionName) {
            return null;
        }
        return (
            <div className={ `${ styles.FilterItem }` }>
                <ListFilterSearchState
                    values={ this.choiceValues }
                    options={ this.choiceOptions }
                    onChange={ this.handleChangeChoice }
                    placeholderLabel={ choiceLabel }
                />
            </div>
        );
    };


    render() {
        const { filter_titles } = langStore.getTranslate();

        return (
            <div className={ styles.FilterWrap }>
                <div
                    className={ styles.FilterTrigger }
                    onClick={()=>{this.isOpened = !this.isOpened}}
                >
                    <div className={ styles.FilterTriggerText }>{ filter_titles && filter_titles.filters }</div>
                    <div className={ `${styles.FilterTriggerIcon} ${this.isOpened ? styles.active : ''}` } dangerouslySetInnerHTML={{__html: IconChevron}}/>
                </div>
                <div className={ `${styles.FilterItems} ${this.isOpened ? styles.active : ''}` }>
                    <div className={ styles.FilterItem }>
                        <ListFilterSearchKeywords
                            onChangeSearch={ this.handleChangeSearch }
                            onSearch={ this.handleChangeCondition }
                            search={ this.search }
                        />
                    </div>
                    { this.renderFilterDate() }
                    { this.renderFilterState() }
                </div>
            </div>
        );
    }
}

export default observer(ListFilterWidget);
