import {ErrorResponseType, FieldObject, FieldsData, FormValidatorType} from "types/helpers/formValidator";

export default class FormValidator implements FormValidatorType {
    isFormValid = true;
    formFields: Record<string, FieldObject>;
    serverErrorMessage = '';

    constructor(formFields: Record<string, FieldObject>) {
        this.formFields = formFields;
    }

    static createFormFieldObj(): FieldObject {
        return {
            value: '',
            errorMessage: '',
        };
    }

    applyServerValidationErrors(response: ErrorResponseType): boolean {
        let result = false;
        // Validation errors
        if (response.error_type === 'MODEL') {
            if (Array.isArray(response.errors)) {
                for (const { field, message } of response.errors) {
                    if (this.formFields[field]) {
                        this.formFields[field].errorMessage = message;
                    }
                }

                result = true;
            }
        }
        // Server text error
        else if (response.error_type === 'SERVER') {
            this.serverErrorMessage = response.errors[0].message;
        }

        return result;
    }

    async validateField(fieldObject: FieldObject, validationFunction: (value?: string) => string | void): Promise<void> {
        const { value } = fieldObject;
        const result = await validationFunction(value);
        if (result !== undefined) {
            fieldObject.errorMessage = result;
            this.isFormValid = false;
        } else {
            fieldObject.errorMessage = '';
        }
    }

    isFieldsValid(): boolean {
        return this.isFormValid;
    }

    getFieldsData(): FieldsData {
        const result = {};

        for (const fieldName in this.formFields) {
            if (this.formFields.hasOwnProperty(fieldName)) {
                result[fieldName] = this.formFields[fieldName].value;
            }
        }
        return result;
    }
}
