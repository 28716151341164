import apiRequest from 'lib/apiRequest';
import {handleResponse} from 'helpers/action';
import {getFileImage} from "helpers/data";
import type {UploadImageResponse} from "types/actions/textEditor";

export async function uploadImage(files: Blob[], tableName: string, recordId?: string): Promise<UploadImageResponse> {
    const formData = new FormData();
    files.forEach(file => getFileImage(file) && formData.append('files[]', file));
    if (!formData.getAll.length) {
        return Promise.reject();
    }
    let url = `POST /attachments/upload/${tableName}`;
    if (recordId) url += `/${ recordId }`;

    const params = {
        category: 'embedded_file',
    };

    const response = await new apiRequest(url).qs(params).send(formData).catch(console.error);
    return handleResponse(response);
}


