import {makeObservable, observable} from "mobx";
import {fetchExportTable} from "actions/exportManager";
import {getSysIds, isFileLoaded} from "helpers/exports";
import type {CardData, ExportParams} from "types/components/exportManager";
import userState from "../user";
import {UserData} from "../../types/globalState/user";

export class ExportState {
    static MAX_LENGTH = 20;

    private _cardsData: CardData[] = [];
    private _showWarningModal = false;
    private _isOpenDropDown = false;
    private _params = {} as ExportParams;

    constructor() {
        makeObservable(this, {
            _cardsData: observable,
            _showWarningModal: observable,
            _isOpenDropDown: observable,
            _params: observable,
        });
    }

    get cardsData() {
        return this._cardsData;
    }

    get cardsLength() {
        return this._cardsData.length;
    }

    get selectedCardsIds() {
        return getSysIds(this._cardsData.filter(card => card.selected));
    }

    get completeCardsIds() {
        return getSysIds(this._cardsData.filter(c => isFileLoaded(c.state)));
    }

    get showWarningModal() {
        return this._showWarningModal;
    }

    set showWarningModal(showWarningModal: boolean) {
        this._showWarningModal = showWarningModal;
    }

    get isOpenDropDown() {
        return this._isOpenDropDown;
    }

    set isOpenDropDown(isOpenDropDown) {
        this._isOpenDropDown = isOpenDropDown;
    }

    addParams = (params: ExportParams) => {
        this._params = params;
    }

    fetchExportTable = async (confirmExportLimitExceeded = false): Promise<boolean> => {
        const userId = (userState.user as UserData)?.sys_id;

        const data = {
            export: this._params,
            confirmExportLimitExceeded,
            userId,
        };
        const {data: {exportLimitExceeded}} = await fetchExportTable(data);
        if (!exportLimitExceeded) {
            this._isOpenDropDown = true;
        }
        return exportLimitExceeded;
    }

    setCardsData(cardData: CardData[]) {
        this._cardsData = cardData.map(newCard => {
            const findOldEl = this._cardsData.find(oldCard => oldCard.sysId === newCard.sysId);
            let oldProps = {};
            if (findOldEl && isFileLoaded(findOldEl.state)) {
                // Сохраняем состояние предыдущей карточки, чтоб не прыгал дизайн
                oldProps = {
                    selected: findOldEl.selected,
                    state: findOldEl.state,
                };
            }
            return {...newCard, ...oldProps};
        });
    }

    removeCard(card: CardData) {
        this._cardsData = this._cardsData.filter(c => c.sysId !== card.sysId);
    }

    removeSelectedCards() {
        this._cardsData = this._cardsData.filter(c => !c.selected);
    }

    cancelSelectedCards() {
        this._cardsData = this._cardsData.map(c => ({...c, selected: false}));
    }

    clearData() {
        this._cardsData = [];
    }
}

export default new ExportState();
