import * as React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment-timezone';

import FieldWrapper from 'components/dynamicForms/view/fieldWrapper';
import Multiselect from 'components/multiselect';
import DaysOfWeekModel from 'components/dynamicForms/model/field/DaysOfWeekModel';
import styles from './styles.module.scss';
import _ from 'lodash';
import { getTestNameField } from 'helpers/data';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { getDaysOfWeek } from 'helpers/date';
import { observable, makeObservable } from "mobx";
import userState from 'globalState/user';
import { isChanged } from 'helpers/form';

/**
 * Описание: компонент DaysOfWeek
 * Параметры:
 * onChange: {required, type: function} - метод для изменения
 * value: {type: string}
 * column_id: {type: string}
 */
class DaysOfWeek extends React.Component {
    model;
    daysList = [];

    constructor(props) {
        super(props);

        makeObservable(this, {
            model: observable,
            daysList: observable,
        });

        if (props.model) {
            this.model = props.model;
        } else {
            this.model = new DaysOfWeekModel(props);
        }
    }

    componentDidMount() {
        this.setWeekDays();
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props, prevProps)) {
            if (this.props.model) {
                this.model = this.props.model;
            } else {
                this.model.mergeData(this.props);
            }
        }
    }

    setWeekDays = () => {
        let daysList = [];
        moment.locale(userState.user.language.current_lang_key);
        const WEEKDAYS = moment.weekdays(true);
        for (let i = 0; i < WEEKDAYS.length; i++) {
            daysList.push({
                database_value: i,
                display_value: WEEKDAYS[i],
            });
        }
        this.daysList = daysList;
    }

    onChange = (data) => {
        let bitMask = 0;
        for (let v of data.value) {
            bitMask = bitMask | (1 << v);
        }
        this.model.value = bitMask;
        this.model.changed = isChanged(this.model.defaultValue, this.model.value);
    };

    // get value from every bit
    getValue = () => {
        const { value } = this.model;
        if (!value) {
            return null;
        }
        if (Array.isArray(value)) return value;
        return getDaysOfWeek(value);
    };

    render() {
        const { readonly } = this.model;

        const stylesStr = [ styles.Input ];
        if( this.model.isWarning && this.model.cellEditMode) {
            stylesStr.push(styles.warningInput);
        }

        const fieldName = getTestNameField(this.model);
        const dataTest = (fieldName !== 'unknown') ? `${ fieldName }-${ ATTRIBUTES.fieldDaysOfWeek }` : `${ ATTRIBUTES.fieldDaysOfWeek }`;
        return (
            <FieldWrapper model={ this.model }>
                <div
                    className={ stylesStr.join(' ') }
                    data-test={ this.props['data-test'] ? this.props['data-test'] : dataTest }
                    data-test-visible={ this.model.isVisible }
                    data-test-mandatory={ this.model.isMandatory }
                    data-test-readonly={ this.model.readonly }
                    data-test-field-type={ this.model.sys_column_name ? this.model.column_type : undefined }
                    data-test-field-name={ this.model.sys_column_name }
                >
                    <Multiselect
                        ref={ this.model.ref }
                        values={ this.getValue() }
                        options={ this.daysList }
                        onChange={ this.onChange }
                        isReadOnly={ readonly }
                        canExcludeAll
                    />
                </div>
            </FieldWrapper>
        );
    }
}

export default observer(DaysOfWeek);
