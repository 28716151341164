import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import Dropdown from 'components/dropdown';
import styles from './styles.module.scss';
import _ from 'lodash';
import IconArrow from 'assets/img/icons/chevron-down.svg';
import { ATTRIBUTES } from 'constants/attributesForTests';

/***
 * Описание: Кастомный селект языка
 * Праметры:
 * databaseValue: {required: true, type: string} - выбранное значение
 * disabled: {required: false, type: boolean} - readOnly
 * options: {required: true, type: array} - массив доступных значений(database_value/display_value)
 * onChange: {required: true, type: function} - метод вызываемый при изменении выбранного значения(принимает database_value)
 */
class SelectComponent extends React.Component {
    isOpened = false;
    isFocused = false;
    value = ''; // display_value
    current = ''; // database_value
    refDropdown = React.createRef();
    refInput = React.createRef();

    constructor(props) {
        super(props);

        makeObservable(this, {
            isOpened: observable,
            isFocused: observable,
            value: observable,
            current: observable,
        });
    }

    componentDidMount() {
        window.addEventListener('scroll', () => this.isOpened = false);
        document.addEventListener('keydown', this.onKeyDown);
        document.addEventListener('click', this.onDocumentClick);

        this.setValues();
    }

    componentDidUpdate(prevProps) {
        const { databaseValue, options } = this.props;
        if (databaseValue !== prevProps.databaseValue || !_.isEqual(prevProps.options, options)) {
            this.setValues();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', () => this.isOpened = false);
        document.removeEventListener('click', this.onDocumentClick);
        document.removeEventListener('keydown', this.onKeyDown);
    }

    setValues() {
        const { databaseValue, options } = this.props;

        if (this.isEmptyData()) return;
        if (databaseValue) {
            const find = options.find((option) => option.database_value === String(databaseValue));
            if (find) {
                this.value = find.display_value;
            }
            else {
                this.value = options[0] ? options[0].display_value : databaseValue;
            }
        }
        else {
            this.value = options[0].display_value;
        }

        this.current = databaseValue ? databaseValue : options[0].database_value;
    }

    isEmptyData = () => {
        return !this.props.options || !this.props.databaseValue && this.props.options.length === 0;
    };

    renderMenu() {
        const options = this.props.options.filter(lang => lang.database_value !== this.props.databaseValue);
        const items = options.map((option, index) => {
            return (
                <div
                    className={ `${ styles.menuItem } ${ option.database_value === this.current ? styles.active : '' }` }
                    key={ option.database_value + index }
                    onClick={ () => this.onItemClick(option.display_value, option.database_value) }
                    onMouseEnter={ () => this.onItemHover(option.database_value) }
                    data-test={ ATTRIBUTES.loginMenuItem }
                >
                    { option.display_value }
                </div>
            );
        });

        return <div className={ `${styles.menu}` }>{ items }</div>;
    }

    onItemClick = (display_value, database_value) => {
        this.value = display_value;
        this.current = database_value;
        this.isOpened = false;
        if (this.props.onChange) {
            this.props.onChange(database_value);
        }
    };

    onItemHover = (database_value) => {
        this.current = database_value;
    };

    onInputClick = () => {
        if (!this.isOpened) {
            this.current = this.props.options.find((option) => option.display_value === this.value).database_value;
        }
        this.isOpened = !this.isOpened;
    };

    onDocumentClick = (e) => {
        const dropdownEl = this.refDropdown ? this.refDropdown.current : null;
        const inputEl = this.refInput.current;
        if (!dropdownEl || !inputEl) return false;

        if (!dropdownEl.contains(e.target) && !inputEl.contains(e.target)) {
            this.isOpened = false;
        }
    };

    onKeyDown = (e) => {
        if (this.isFocused && this.isOpened) {
            // down 40, up 38
            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                e.preventDefault();
                let nextOptionIndex;
                for (let i = 0, options = this.props.options; i < options.length; i++) {
                    if (options[i].database_value === this.current) {
                        nextOptionIndex = (e.keyCode === 38) ? i - 1 : i + 1;
                        if (nextOptionIndex < 0) {
                            nextOptionIndex = options.length - 1;
                        }
                        else if (nextOptionIndex > options.length - 1) {
                            nextOptionIndex = 0;
                        }
                        break;
                    }
                }
                this.current = this.props.options[nextOptionIndex].database_value;
            }

            // enter
            if (e.key === 'Enter') {
                this.value = this.props.options.find((option) => option.database_value === this.current).display_value;
                if (this.props.onChange) {
                    this.props.onChange(this.current);
                }
            }

            // esc
            if (e.key === 'Escape') {
                this.isOpened = false;
            }
        }
    };

    render() {
        if (this.isEmptyData()) return null;
        return (
            <div className={ styles.wrap } data-test={ this.props['data-test'] ? this.props['data-test'] : ATTRIBUTES.loginSelectLang }>
                <div className={ `${ styles.container }` }>
                    <button
                        type='button'
                        className={ `${styles.input}` }
                        disabled={ this.props.disabled }
                        onClick={ this.onInputClick }
                        onFocus={ () => this.isFocused = true }
                        onBlur={ () => this.isFocused = false }
                        ref={ this.refInput }
                        data-test={ ATTRIBUTES.loginSelectLangButton }
                    >
                        { this.value }
                        <div className={ `${ styles.arrow }  ${ this.isOpened && styles.active }` } dangerouslySetInnerHTML={ { __html: IconArrow } } />
                    </button>
                    { this.isOpened &&
                    <Dropdown refParent={ this.refInput } ref={ this.refDropdown }>
                        { this.renderMenu() }
                    </Dropdown>
                    }
                </div>
            </div>
        );
    }
}

export default observer(SelectComponent);
