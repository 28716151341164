import * as React from 'react';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import ellipse from 'assets/img/servicePortal/ellipse.svg';
import { ATTRIBUTES } from 'constants/attributesForTests';

/**
 * Props:
 * img - Иконка (svg) не ссылка, а код svg
 * url - Ссылка на которую перейдем при клике на карточку
 * name - Заголовок
 * shortDescription - Описание
 */
class Card extends React.Component {
    render() {
        const { img, url = '/', name, shortDescription, className } = this.props;

        const iconHtml = !img || (img && img.includes('<svg')) ? (
            <div className={ styles.icon } dangerouslySetInnerHTML={ { __html: img || ellipse } } />
        ) : (
            <img className={ styles.icon } src={ img }  alt='' />
        );

        return (
            <Link
                to={ url }
                className={ `${ styles.item } ${ className || '' }` }
                data-test={ this.props['data-test'] ? this.props['data-test'] : `card-${ ATTRIBUTES.widget }` }
            >
                <div className={ styles.header }>
                    <div className={ styles.headerTitle }>
                        <div className={ styles.title }>{ name }</div>
                    </div>
                    <div className={ styles.headerIcon }>
                        { iconHtml }
                    </div>
                </div>
                <div className={ styles.text }>{ shortDescription }</div>
            </Link>
        );
    }
}

export default observer(Card);
