import React from 'react';
import styles from './styles.module.scss';
import IconChevronRight from 'assets/img/icons/chevron-right.svg';
import IconChevronDown from 'assets/img/icons/chevron-down.svg';
import IconChevronUp from 'assets/img/icons/chevron-up.svg';
import _ from 'lodash';
import Row from 'components/groupedTable/row/index';
import { GroupRowProps } from 'types/components/table/groupRow/index';
import GroupDataState from 'globalState/table/groupData/index';
import TableState from 'globalState/table/index';
import { observer } from 'mobx-react';
import { fetchListFilter } from 'actions/list';
import { tableGroupStateClass } from 'globalState/table/group/index';
import { observable, makeObservable } from 'mobx';
import { TableGroupType } from 'types/components/table/groups/index';
import langStore from "globalState/lang/index";
import { Link } from 'react-router-dom';
import { ATTRIBUTES } from 'constants/attributesForTests';

class GroupRow extends React.Component<GroupRowProps> {
    groupState: TableGroupType;
    isLoading = false;
    isLoadingShowMore = false;

    constructor(props) {
        super(props);

        makeObservable(this, {
            groupState: observable,
            isLoading: observable,
            isLoadingShowMore: observable,
        });

        const { group } = props;
        this.groupState = new tableGroupStateClass({
            title: group.title,
            condition: group.condition,
            count: group.count,
            rows: [],
            isShow: false,
        });
        TableState.addGroup(this.groupState);
    }

    componentDidMount() {
        const { isShowAllGroups, openGroups } = this.props;
        const findLSShowGroup = _.find(openGroups, group => group.title === this.groupState.getTitle() && !!group.isShow);
        this.getRows(isShowAllGroups || !!findLSShowGroup);
    }

    componentDidUpdate(prevProps: Readonly<GroupRowProps>) {
        const { isShowAllGroups } = this.props;
        if (isShowAllGroups !== prevProps.isShowAllGroups) {
            this.getRows(isShowAllGroups);
        }
    }

    getRows = async (isShow) => {
        const { tableState, group } = this.props;
        this.groupState.setIsShow(isShow);
        if (isShow) {
            this.isLoading = true;
            const response = await fetchListFilter(tableState.getName(), { condition: group.condition });
            if (response.isOkStatus) {
                this.groupState.setRows(response.data.items);
            }
            this.isLoading = false;
        }
    };

    handleClickShowLess = async () => {
        const countElements = this.groupState.getPerPage();
        this.groupState.setRows(_.filter(this.groupState.getRows(), (row, index) => index < countElements));
        this.groupState.setPage(1);
    }

    handleClickShowMore = async () => {
        this.isLoadingShowMore = true;
        const { tableState } = this.props;
        const newPage = this.groupState.getPage() + 1;
        const response = await fetchListFilter(tableState.getName(), {
            condition: this.groupState.getCondition(),
            page: newPage,
        });
        if (response.isOkStatus) {
            this.groupState.setRows([ ...this.groupState.getRows(), ...response.data.items ]);
            this.groupState.setPage(response.data.pagination.page);
            this.groupState.setPerPage(response.data.pagination.per_page);
            this.groupState.setCount(response.data.pagination.total);
        }
        this.isLoadingShowMore = false;
    };

    handleClickRow = () => {
        const isShow = this.groupState.getIsShow();
        if (isShow) {
            this.groupState.setRows([]);
        }
        const tableName = TableState.getName();
        const title = this.groupState.getTitle();
        const lsOpenGroups = localStorage.getItem(`tableGroups${ tableName }`);
        const currentGroup = { title, isShow: !isShow };
        const openGroups = lsOpenGroups
            ? [ ..._.filter(JSON.parse(lsOpenGroups), group => group.title !== title), currentGroup ]
            : [ currentGroup ];
        localStorage.setItem(`tableGroups${ tableName }`, JSON.stringify(openGroups));
        this.getRows(!isShow);
    };

    handleContextMenu = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        const { table } = this.props; // TODO не круто так делать, надо потом при рефакторинге, переделать
        table.menuCoordinates.x = evt.pageX;
        table.menuCoordinates.y = evt.pageY;
        table.isShowContextMenuCondition = true;
        table.isGroup = true;
    };

    getLoaderRow = () => {
        const { tableState, table: { tableWrapRef, tableRef } } = this.props;
        const columns = tableState.getColumns();
        const wrapper = tableWrapRef.current;
        const table = tableRef.current;
        const loaderStyles: any = {};
        if(wrapper && table){
            const wrapperWidth = wrapper.offsetWidth;
            const tableWidth = table.offsetWidth;
            if(wrapperWidth < tableWidth){
                loaderStyles.width = wrapperWidth + 'px';
                loaderStyles.marginLeft = wrapper.scrollLeft + 'px';
            }
        }
        return (
            <tr>
                <td className={ styles.LoadingCol } colSpan={ columns.length + 2 }>
                    <div style={ loaderStyles } className={ styles.Loading } />
                </td>
            </tr>
        );
    };

    renderGroupItems = () => {
        if (this.isLoading) {
            return this.getLoaderRow();
        }

        const rows = this.groupState.getRows();
        if (_.isEmpty(rows)) {
            return null;
        }
        const {
            table, filterFields, conditionState, isServicePortal, isRelatedList, handleChangeEditCol, tableState,
            onCheckRow, isBlankMode, isWindow, usedByList, classes, clientScripts, isMobile,
        } = this.props;
        return (
            _.map(rows, (item, index) => {
                return (
                    <Row
                        key={ `${ item.sys_id }-${ index }` }
                        rowIndex={ index }
                        items={ item }
                        table={ table }
                        filterFields={ filterFields }
                        conditionState={ conditionState }
                        isServicePortal={ isServicePortal }
                        isRelatedList={ isRelatedList }
                        onCheckRow={ onCheckRow }
                        isBlankMode={ isBlankMode }
                        isWindow={ isWindow }
                        usedByList={ usedByList }
                        classes={ classes }
                        clientScripts={ clientScripts }
                        isMobile={ isMobile }
                        handleChangeEditCol={ handleChangeEditCol }
                        tableState={ tableState }
                    />
                );
            })
        );
    }

    renderShowMore = () => {
        if (this.isLoadingShowMore) {
            return this.getLoaderRow();
        }

        const { tableState } = this.props;
        const columns = tableState.getColumns();
        const { list_titles } = langStore.getTranslate();
        const rows = this.groupState.getRows();
        if (_.isEmpty(rows) || (rows.length === this.groupState.getCount() && rows.length <= this.groupState.getPerPage())) {
            return null;
        }
        if (rows.length >= this.groupState.getCount() && rows.length > this.groupState.getPerPage()) {
            return (
                <tr className={ styles.GroupShowMore } onClick={ this.handleClickShowLess }>
                    <td colSpan={ columns.length + 2 }>
                        <div className={ styles.GroupShowMoreBlock } >
                            <div className={ styles.GroupShowMoreIcon }
                                 dangerouslySetInnerHTML={ { __html: IconChevronUp } } />
                            <div>
                                { list_titles && list_titles.hide_list }
                            </div>
                        </div>
                    </td>
                </tr>
            );
        }
        return (
            <tr className={ styles.GroupShowMore } onClick={ this.handleClickShowMore }>
                <td colSpan={ columns.length + 2 }>
                    <div className={ styles.GroupShowMoreBlock } >
                        <div className={ styles.GroupShowMoreIcon }
                             dangerouslySetInnerHTML={ { __html: IconChevronDown } } />
                        <div>
                            { list_titles && list_titles.show_more }
                        </div>
                    </div>
                </td>
            </tr>
        );
    };

    render() {
        const { tableState, group, isWindow } = this.props;
        const { list_titles, bool } = langStore.getTranslate();
        const title = typeof group.value === 'boolean' && bool ? (group.value ? bool.yes : bool.no) : group.title;
        const columns = tableState.getColumns();
        const icon = !_.isEmpty(this.groupState.getRows()) ? IconChevronDown : IconChevronRight;
        const linkGroup = `/list/${ tableState.getName() }?condition=${ this.groupState.getCondition() }`;
        return (
            <>
                <tr
                    className={ styles.GroupRow }
                    onClick={ this.handleClickRow }
                    onContextMenu={ this.handleContextMenu }
                    data-test={ ATTRIBUTES.tableGroupByFieldName }
                >
                    <td colSpan={ columns.length + 2 }>
                        <div className={ styles.GroupRowValues } >
                            <div className={ styles.GroupRowField }>
                                <div className={ styles.GroupRowIcon }
                                     dangerouslySetInnerHTML={ { __html: icon } } />
                                <div className={ styles.GroupColumnTitle } onClick={ this.handleContextMenu }>
                                    { GroupDataState.getColumnTitle() }
                                </div>
                            </div>
                            <div title={ title } className={ styles.GroupRowValue }>
                                { title }
                            </div>
                            <div className={ styles.GroupRowCount }>
                                { group.count }
                            </div>
                            { !isWindow && (
                                <div className={ styles.GroupRowOpenList }>
                                    <div className={ styles.VerticalDelimeter } />
                                    <Link to={ linkGroup }>
                                        { list_titles && list_titles.open_group_as_list_link }
                                    </Link>
                                </div>
                            )}
                        </div>
                    </td>
                </tr>
                { this.renderGroupItems() }
                { this.renderShowMore() }
            </>
        );
    }
}

export default observer(GroupRow);
