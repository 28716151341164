import BaseFormFieldModel from './BaseFormFieldModel';
import { observable, makeObservable } from 'mobx';
import _ from 'lodash';
import { isEmptyObject } from 'helpers/isEmpty';
import { isChanged } from 'helpers/form';
import { getChoiceTranslate, getDisplayValueTranslate } from 'helpers/translations';

export default class SelectModel extends BaseFormFieldModel {

    /**
    * объект, где values являются options для select
    * special
    *
    * @type {*}
    */
    special;

    /**
    * Множественный выбор
    * multiple
    *
    * @type {boolean}
    */
    multiple;

    columnType = 'choice';

    /***
     * Отображение в виде радиокнопок
     * radioButtonsMode
     *
     * @type {boolean}
     */
    radioButtonsMode = false;

    constructor(data, parentFormSectionModel) {
        super(data, parentFormSectionModel);

        makeObservable(this, {
            special: observable,
        });

        super.mergeData(data);
        const extraAttributes = this.getExtraAttributes();
        if (extraAttributes && extraAttributes['choice_decorator'] === 'radio'){
            this.radioButtonsMode = true;
        }
    }

    get databaseValue() {
        return this.value && this.value.database_value;
    }

    get displayValue() {
        return this.value && this.value.display_value;
    }

    async uiAddOption(databaseValue) {
        const params = {
            table_name: this.sysTableName,
            column_name: this.sysColumnName,
            database_value: databaseValue,
        };

        const { displayValue, order } = await getChoiceTranslate(params);
        if (displayValue === '') return;

        const findIndexElem = this.special.values.findIndex(item => item.database_value === databaseValue);

        if (~findIndexElem) { // Если index !== 0, то заменяем
            const findElem = this.special.values[findIndexElem];
            this.special.values[findIndexElem] = {
                ...findElem,
                display_value: displayValue,
                order: order,
            };
        } else { // Либо добавляем новый элемент
            this.special.values.push({
                database_value: databaseValue,
                display_value: displayValue,
                order: order,
            });
        }

        // Сортируем по ордеру DEF0011390
        // this.special.values = [...this.special.values].sort((a, b) => a.order > b.order ? 1 : -1);
    }

    uiRemoveOption(databaseValue) {
        this.special.values = this.special.values.filter(({ database_value }) => {
            return database_value !== databaseValue;
        });
    }

    uiClearOptions() {
        this.special.values = [];
    }

    uiGetValue() {
        return this.databaseValue;
    }

    uiGetDisplayValue() {
        const currentValue = this.special.values.find(el => el.database_value === this.databaseValue);
        return this.displayValue || currentValue ? currentValue.display_value : this.databaseValue;
    }

    async uiSetValue(value) {
        if (value === '' || _.isNil(value)) {
            return this.uiClearValue();
        }
        // Сеттим значение до запроса DEF0013497
        this.value = { database_value: value, display_value: "" };

        const params = {
            table_name: this.sysTableName,
            column_id: this.columnId,
            value: value,
        };

        this.value = await getDisplayValueTranslate(params);
        this.changed = isChanged(this.defaultValue, this.value);
    }

    uiClearValue() {
        const isNull = this.special.values.some(item => {
            return item.database_value === null;
        });
        if (isNull) {
            this.changed = isChanged(this.defaultValue, this.value);
            this.value = null;
            return true;
        }
        return false;
    }

    setValue(value) {
        if (_.isNil(value)) {
            return null;
        }
        if (_.isPlainObject(value)) {
            return value.database_value !== undefined ? { ...value } : null;
        } else {
            if (_.isNil(this.special) || !_.isArray(this.special.values)) {
                return value;
            }

            const findElem = this.special.values.find(el => el.database_value === value);
            return findElem  !== undefined ? findElem : value;
        }
    }

    checkEmptyValue() {
        return isEmptyObject(this.value);
    }

    getValueForSave(){
        return this.value && this.value.database_value;
    }

    getExtraAttributes = () => {
        const { extraAttributes } = this;
        if (!extraAttributes) {
            return [];
        }
        const attrs = extraAttributes.split(';');
        let result = [];
        attrs.forEach(elem => {
            const values = elem.split('=');
            if (values[0]){
                result[values[0]]=values[1];
            }
        });
        return result;
    };
}
