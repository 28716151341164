import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { observable, makeObservable } from 'mobx';
import { isEqual } from 'helpers/data';
import ContextMenu from 'components/contextMenu';
import { IS_UPDATE_CHART } from 'globalState/report';
import styles from './styles.module.scss';

import InfoMessagesState from 'globalState/infoMessages';
import SelectUiAction from 'components/uiButtons/selectUiAction';
import actionsPanelState from 'globalState/actionsPanelState';
import globalTableState from 'globalState/table/index';
import langStore from 'globalState/lang/index';

import Thead from 'components/groupedTable/thead/index';
import Tbody from 'components/groupedTable/tbody/index';
import Pagination from 'components/groupedTable/pagination/index';
import IconEyeOff from 'assets/img/icons/eye-off.svg';
import { getFieldType } from 'helpers/condition';
import Button from 'components/button/index';
import TablesState from 'globalState/tables/index';
import { ATTRIBUTES } from 'constants/attributesForTests';
import type { TableColumn, TablePropsTypes, TableTypes } from 'types/components/table';
import Dropdown from 'components/dropdown';
import FixedHeadTable from 'components/groupedTable/fixedHead/index';
import TableGroups from 'components/groupedTable/groups/index';
import GroupDataState from 'globalState/table/groupData/index';
import ContextMenuCondition from 'components/groupedTable/contextMenuCondition/index';
import type { TableGroupType } from 'types/components/table/groups';
import NumberOfElements from 'components/numberOfElements';
import type { IOpenGroup } from 'types/components/table/groupRow';

/**
 * компонент таблицы
 * используется на листах и для отчёта типа List
 *
 * @props essence
 * @props columns
 * @props items
 * @props parent
 * @props pagination
 * @props conditionState
 * @props onChangeCondition
 * @props contextMenuBody
 * @props contextMenuHeader
 * @props isServicePortal
 * @props recordId
 * @props recordTableName
 * @props relatedColumnName - название колонки, которая ссылается на рекорд. Заполняется в relatedList
 * @props doFetch
 * @props selectUiAction
 * @props isBlankMode режим без поиска, настроек, чекбоксов и т.д.
 * @props formId id формы record`а для передачи в таблицу релейт листов.
 * @props isWindow режим словаря
 * @props usedByList режим словаря, открытого через поле list *
 * @props isMobile отображение для мобилки
 * @props view текущий view
 */
export class GroupedTableClass extends React.Component<TablePropsTypes> implements TableTypes {
    editRow = null;
    editCol = null;
    items;
    isShowContextMenu = false;
    isShowHeadListMenu = false;
    menuCoordinates = {
        x: 0,
        y: 0,
    };
    selectUiActionCheck = false;
    headRowRef: React.RefObject<HTMLTableRowElement> = React.createRef();
    fixedHeadRowRef: React.RefObject<HTMLTableRowElement> = React.createRef();
    fixedHeadRef: React.RefObject<HTMLDivElement> = React.createRef();
    fixedContainerRef: React.RefObject<HTMLDivElement> = React.createRef();
    tableWrapRef: React.RefObject<HTMLDivElement> = React.createRef();
    tableRef: React.RefObject<HTMLTableElement> = React.createRef();
    fixedScrollRef: React.RefObject<HTMLDivElement> = React.createRef();
    isFixedHeadVisible = false;
    isSearchRowVisible = false;
    isHorizontalScrollVisible = false;
    isShowContextMenuCondition = false;
    isGroup = false;

    timeout: NodeJS.Timeout;
    reportState;
    refDropdown: React.RefObject<HTMLDivElement> = React.createRef();
    refParent: React.RefObject<HTMLDivElement> = React.createRef();
    isShowAllGroups = false;

    constructor(props: TablePropsTypes) {
        super(props);

        makeObservable(this, {
            editRow: observable,
            editCol: observable,
            items: observable,
            isShowContextMenu: observable,
            isShowHeadListMenu: observable,
            menuCoordinates: observable,
            selectUiActionCheck: observable,
            isFixedHeadVisible: observable,
            isSearchRowVisible: observable,
            isHorizontalScrollVisible: observable,
            isShowContextMenuCondition: observable,
            isGroup: observable,
            isShowAllGroups: observable,
        });

        this.initChecked();
        this.setInnerAttributes();
        //window.tableGlobal[this.props.essence] = this;
        TablesState.setTable(this.props.essence, this);
        if (props.reportState) {
            this.reportState = props.reportState;
        }
    }

    componentDidUpdate(prevProps: TablePropsTypes) {
        this.setInnerAttributes(prevProps);

        const tableState = this.getStateTable();
        if ((tableState.getGroups() && tableState.getGroups().length) || (tableState.getRows() && tableState.getRows().length)) {
            this.selectUiActionCheck = this.isAllChecked();
        }

        // обновление для репортов
        if (this.props.isUpdateChart && this.reportState) {
            this.reportState.stateFlags = this.reportState.stateFlags & ~IS_UPDATE_CHART;
        }

        this.initFixedHeaderAndHorizontalScroll();
    }

    componentDidMount() {
        this.initFixedHeaderAndHorizontalScroll();

        document.addEventListener('click', this.handleDocumentClick);
    }

    componentWillUnmount() {
        const { parent } = this.props;

        //window.tableGlobal[this.props.essence] && delete window.tableGlobal[this.props.essence];
        TablesState.deleteTable(this.props.essence);

        this.selectUiActionCheck = false;

        const parent_data = parent ?
            (parent.data ? parent.data.essence : parent.props.data.essence)
            : null;

        const tableState = this.getStateTable();

        if (parent_data === this.props.essence) {
            tableState.clear();
        }

        this.destroyFixedHeaderAndHorizontalScroll();
        GroupDataState.clear();
    }

    initChecked = () => {
        const fieldListValue = localStorage.getItem('fieldListValue');
        if (fieldListValue) {
            const values = JSON.parse(fieldListValue);
            _.forEach(values, value => this.getStateTable().addChecked(value.database_value));
            this.getStateTable().setSelectByList(values);
        }
        localStorage.removeItem('fieldListValue');
    }

    handleChangeEditCol = ({ editCol, editRow }) => {
        this.editCol = editCol;
        this.editRow = editRow;
    };

    handleClickGroupedButton = () => {
        const tableState = this.getStateTable();
        tableState.setIsShowListGroups(!tableState.getIsShowListGroups());
    };

    handleChangeIsShowAll = (isShowAll: boolean) => {
        this.isShowAllGroups = isShowAll;
        const tableName = this.getStateTable().getName();

        let openGroups: IOpenGroup[] = [];
        if (!isShowAll) {
            _.forEach(this.getStateTable().getGroups(), (group: TableGroupType) => {
                group.setIsShow(false);
                group.setRows([]);
                openGroups.push({ title: group.getTitle(), isShow: false });
            });
        } else {
            _.forEach(this.getStateTable().getGroups(), (group: TableGroupType) => {
                openGroups.push({ title: group.getTitle(), isShow: true });
            });
        }
        localStorage.setItem(`tableGroups${ tableName }`, JSON.stringify(openGroups));
    }

    handleDocumentClick = (evt) => {
        const dropdownEl = this.refDropdown ? this.refDropdown.current : null;
        const parentEl = this.refParent.current;
        if (!dropdownEl || !parentEl) {
            return false;
        }

        if (!dropdownEl.contains(evt.target) && !parentEl.contains(evt.target)) {
            this.getStateTable().setIsShowListGroups(false);
        }
    };

    handleClickGroup = (columnName: string) => async () => {
        const tableState = this.getStateTable();
        tableState.setIsShowListGroups(false);
        const { conditionState, onChangeCondition } = this.props;
        conditionState.clearGrouping();
        const groupingRow = conditionState.addGrouping();
        groupingRow.setField({
            dot_walking_attribute: columnName,
        });
        groupingRow.setDirection({
            database_value: 'GROUPBY',
        });
        if (onChangeCondition) {
            onChangeCondition();
        }
    };

    initFixedHeaderAndHorizontalScroll = () => {
        if (actionsPanelState.panelEl) {
            this.updateFixedHeadDimensions();
            this.checkFixedHead();
        }

        this.updateHorizontalScrollDimensions();
        this.checkHorizontalScroll();
        if (this.tableWrapRef && this.tableWrapRef.current) {
            this.tableWrapRef.current.addEventListener('scroll', this.onTableWrapScroll);
        }
        if (this.fixedScrollRef && this.fixedScrollRef.current) {
            this.fixedScrollRef.current.addEventListener('scroll', this.onHorizontalScroll);
        }
        window.addEventListener('scroll', this.onWindowScroll);
        window.addEventListener('resize', this.onWindowResize);

    };

    destroyFixedHeaderAndHorizontalScroll = () => {
        if (this.tableWrapRef && this.tableWrapRef.current) {
            this.tableWrapRef.current.removeEventListener('scroll', this.onTableWrapScroll);
        }
        if (this.fixedScrollRef && this.fixedScrollRef.current) {
            this.fixedScrollRef.current.removeEventListener('scroll', this.onHorizontalScroll);
        }
        window.removeEventListener('scroll', this.onWindowScroll);
        window.removeEventListener('resize', this.onWindowResize);
    };

    onWindowScroll = () => {
        if (actionsPanelState.panelEl) {
            this.checkFixedHead();
        }
        this.checkHorizontalScroll();
    };

    getStateTable = () => {
        return this.props.tableState || globalTableState;
    };

    onWindowResize = () => {
        if (actionsPanelState.panelEl) {
            this.updateFixedHeadDimensions();
        }
        this.updateHorizontalScrollDimensions();
    };

    onTableWrapScroll = (e) => {
        if (actionsPanelState.panelEl) {
            this.setFixedHeadContLeft();
        }
        // используем timeout для корректной работы скролла с помощью Shift + mouseWheel
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.setHorizontalScrollLeft();
        }, 200);

        const tableState = this.getStateTable();
        tableState.setScrollLeft(e.target.scrollLeft);
        // тригерим скролл для закрытия дропдаунов селектов в шапке таблицы
        window.dispatchEvent(new Event('scroll'));
    };

    onHorizontalScroll = () => {
        if (actionsPanelState.panelEl) {
            this.setFixedHeadContLeft();
        }
        this.setTableWrapScrollLeft();
    };

    toggleSearchRowVisibility = () => {
        this.isSearchRowVisible = !this.isSearchRowVisible;
    };

    setInnerAttributes = (prevProps: TablePropsTypes | null = null) => {
        const { columns, items, pagination } = this.props;
        const tableState = this.getStateTable();
        // ссылка, чтобы uiActions видели этот компонент
        TablesState.setTable(this.props.essence, this);
        if (
            prevProps &&
            isEqual(
                { essence: prevProps.essence, ...prevProps.items },
                { essence: this.props.essence, ...this.props.items },
            ) &&
            isEqual(pagination && prevProps.pagination)
        ) {
            return false;
        }

        tableState.setColumns(columns);
        if (!prevProps) {
            tableState.setRows(items);
        } else if (!isEqual(items, prevProps.items)) {
            tableState.setRows(items);
            this.updateCheckedRows();
        }

        tableState.setName(this.props.essence);
        tableState.setPagination(this.props.pagination);
        tableState.setTableId(this.props.tableId);
        tableState.setRecordColumnName(this.props.recordColumnName);
    };

    updateCheckedRows = () => {
        const oldCheckedRows = this.getCheckedRow();
        if (oldCheckedRows.length) {
            _.forEach(oldCheckedRows, (sysId) => {
                if (_.find(this.getStateTable().getRows(), item => item.sys_id === sysId)) {
                    this.getStateTable().addChecked(sysId);
                }
            });
        }
    };

    // Метод срабатывает каждый раз по вводу текста в фильтр
    handleValueChange = (id, fieldValue, operatorValue, options) => ({ value, submit }) => {
        const { conditionState, onChangeCondition } = this.props;
        const filterFields = conditionState ? conditionState.getFilterFields() : [];

        const newId = id || `filter${ conditionState.getKeyFilterIndex() + 1 }`;
        let filteringRow = filterFields.find(row => row.getId() === newId);
        if (!filteringRow) {
            filteringRow = this.props.conditionState.addFiltering('AND', 1);
        }
        let innValue = value;
        if (value !== null && value !== undefined) {
            if (value.target) {
                innValue = conditionState.isSorting(newId) || value.target ? value.target.value : options.find(option => option.database_value === value.target.value);
            }
            if (value === true || value === false) {
                innValue = value ? 1 : 0;
            }
        }

        const isNotChangeFilterFields = filterFields.find(
            item => {
                return item.getId() === id &&
                    item.getOperator().database_value === operatorValue &&
                    item.getField().dot_walking_attribute === fieldValue &&
                    item.getValue() === innValue;
            },
        );

        if (!isNotChangeFilterFields) { // Если значение изменилось в фильтре
            const dotWalkItem = this.findDotWalkItem(fieldValue);
            const element = dotWalkItem && dotWalkItem.condition_type === 'boolean' ? 'select' : 'text';
            if (!innValue) {
                conditionState.removeFilteringRow(newId);
            } else {
                const findObj = langStore.getConstant(getFieldType(dotWalkItem || {}));
                const findOperator = findObj ? findObj.options.find(option => option.database_value === operatorValue) : '';
                filteringRow.setField(dotWalkItem || fieldValue);
                filteringRow.setOperator(findOperator || {
                    database_value: operatorValue,
                    show_element: element,
                });
                filteringRow.setValue(innValue);
            }

            // изменяем когда выбор в поле select
            if (element === 'select' && onChangeCondition) {
                onChangeCondition();
            }
        }
        // изменяем когда нажат enter
        if (submit && onChangeCondition) {
            onChangeCondition();
        }
    };

    findDotWalkItem = (fieldValue) => {
        const { conditionState } = this.props;
        const dotWalkList = conditionState.getDotWalkList();
        const addDotWalkLists = conditionState.getColumnsDotWalkList();
        let dotWalkItem = dotWalkList.find(dotWalk => dotWalk.dot_walking_attribute === fieldValue);
        if (!dotWalkItem) {
            for (let index = 0; index < addDotWalkLists.length; index++) {
                dotWalkItem = addDotWalkLists[index].find(dotWalk => dotWalk.dot_walking_attribute === fieldValue);
                if (dotWalkItem) {
                    break;
                }
            }
        }
        return dotWalkItem;
    };

    handleSortingChange = (fieldValue, directionValue) => {
        const { conditionState, onChangeCondition } = this.props;
        conditionState.clearSorting();
        if (fieldValue) {
            const sortingRow = conditionState.addSorting();
            const dotWalkItem = this.findDotWalkItem(fieldValue);
            sortingRow.setField(dotWalkItem || fieldValue);
            sortingRow.setDirection( directionValue );
        }
        if (onChangeCondition) {
            onChangeCondition();
        }
    };

    handleGroupingChange = (fieldValue, groupType) => {
        const { conditionState, onChangeCondition } = this.props;
        conditionState.clearGrouping();
        if (fieldValue) {
            const groupingRow = conditionState.addGrouping();
            const dotWalkItem = this.findDotWalkItem(fieldValue);
            groupingRow.setField(dotWalkItem || fieldValue);
            groupingRow.setDirection({ database_value: groupType });
        }
        if (onChangeCondition) {
            onChangeCondition();
        }
    };

    // функция для удаления через внешние uiActions
    //todo После рефакторинга глобальных переменных вынести doFetch в клиентские крипты
    deleteCheckedRow = (rowId) => {
        const tableState = this.getStateTable();
        tableState.clearChecked();
        tableState.setRows(tableState.getRows().filter((item) => item.sys_id !== rowId));
        const pagination = tableState.getPagination();
        tableState.changePaginationTotal(tableState.isDisablePageCalculation() ? pagination.total : pagination.total - 1);
        tableState.changePaginationPerPage(pagination.per_page - 1);
        if (this.props.doFetch) {
            this.props.doFetch(false);
        }
    };

    checkAllRow = ({ value }) => {
        const tableState = this.getStateTable();
        const groups = tableState.getGroups();
        if (groups.length > 0) {
            _.forEach(tableState.getGroups(), group => {
                _.forEach(group.getRows(), (item) => {
                    this.selectAll(item, value);
                });
            });
        } else {
            _.forEach(tableState.getRows(), (item) => {
                this.selectAll(item, value);
            });
        }
        this.selectUiActionCheck = value;
    };

    selectAll = (item, value) => {
        const tableState = this.getStateTable();
        if (value) {
            tableState.addChecked(item.sys_id);

            if (this.props.usedByList) {
                const label = { value: item.__display_value };
                const display_value = label.value && typeof label.value === 'object' && 'display_value' in label.value ?
                    label.value.display_value || item.sys_id : label.value;
                let selectedValue;
                selectedValue = {
                    display_value,
                    database_value: item.sys_id,
                };
                if (item.__reference_state){
                    selectedValue.reference_state = item.__reference_state;
                }
                tableState.addSelectByList(selectedValue);
            }
        } else {
            tableState.deleteChecked(item.sys_id);

            if (this.props.usedByList) {
                tableState.setSelectByList(tableState.getSelectByList().filter(selected => selected.database_value !== item.sys_id));
            }
        }
    };

    isAllChecked() {
        const tableState = this.getStateTable();
        if (tableState.getChecked().size === 0) {
            return false;
        }
        if (tableState.getGroups().length > 0) {
            let hasGroupRowsCount = _.find(tableState.getGroups(), group => group.getRows().length > 0);
            if (!hasGroupRowsCount) {
                return false;
            }
            const hasUnchecked = _.some(tableState.getGroups(), group => {
                return _.some(group.getRows(), item => !tableState.getChecked().has(item.sys_id));
            });
            return !hasUnchecked;
        }
        if (tableState.getRows().length === 0) {
            return false;
        }
        const hasUnchecked = tableState.getRows().some(
            item => !tableState.getChecked().has(item.sys_id),
        );
        return !hasUnchecked;
    }

    onCheckRow = (id, value, display_value, reference_state) => {
        const tableState = this.getStateTable();
        if (value) {
            tableState.addChecked(id);
            this.selectUiActionCheck = this.isAllChecked();

            if (this.props.usedByList) {
                tableState.addSelectByList({
                    display_value,
                    database_value: id,
                    reference_state: reference_state || null,
                });
            }
        } else {
            tableState.deleteChecked(id);
            this.selectUiActionCheck = false;

            if (this.props.usedByList) {
                tableState.setSelectByList(tableState.getSelectByList().filter(item => item.database_value !== id));
            }
        }
    };

    //функция для добавления оповещений через uiActions
    addInfoMessages(message) {
        InfoMessagesState.pushInfo(message);
    }

    getCheckedRow = () => {
        return Array.from(this.getStateTable().getChecked());
    };

    clearCheckedRows = () => {
        this.getStateTable().clearChecked();
    }

    toString() {
        return this.props.essence;
    }

    handleToggleContextMenu = (isHeaderMenu = false) => () => {
        if (isHeaderMenu) {
            this.isShowHeadListMenu = !this.isShowHeadListMenu;
        } else {
            this.isShowContextMenu = !this.isShowContextMenu;
        }
    };

    handleToggleContextMenuCondition = () => {
        this.isShowContextMenuCondition = !this.isShowContextMenuCondition;
        if (!this.isShowContextMenuCondition) {
            this.isGroup = false;
        }
    }

    renderContextMenu = () => {
        if (!this.isShowContextMenu || !this.props.contextMenuBody || this.props.isWindow) {
            return null;
        }

        return (
            <ContextMenu
                items={ this.props.contextMenuBody }
                x={ this.menuCoordinates.x }
                y={ this.menuCoordinates.y }
                isShowContextMenu={ this.isShowContextMenu }
                onToggleContextMenu={ this.handleToggleContextMenu() }
            />
        );
    };

    renderHeadListMenu = () => {
        if (!this.isShowHeadListMenu || !this.props.contextMenuHeader || this.props.isWindow) {
            return null;
        }

        return <ContextMenu
            items={ this.props.contextMenuHeader }
            x={ this.menuCoordinates.x }
            y={ this.menuCoordinates.y }
            isShowContextMenu={ this.isShowHeadListMenu }
            onToggleContextMenu={ this.handleToggleContextMenu(true) }
        />;
    };

    renderHeadConditionsMenu = () => {
        if (!this.isShowContextMenuCondition) {
            return null;
        }
        const { conditionState, contextMenuHeader, isWindow } = this.props;
        return (
            <ContextMenuCondition
                groupingFields={ conditionState.getGroupingFields() }
                sortingFields={ conditionState.getSortingFields() }
                onToggleContextMenu={ this.handleToggleContextMenuCondition }
                x={ this.menuCoordinates.x }
                y={ this.menuCoordinates.y }
                isShowContextMenu={ this.isShowContextMenuCondition }
                onChangeSorting={ this.handleSortingChange }
                onChangeGrouping={ this.handleGroupingChange }
                uiActionItems={ contextMenuHeader }
                isGroup={ this.isGroup }
                onChangeIsShowAll={ this.handleChangeIsShowAll }
                isWindow={ isWindow }
            />
        );
    };

    updateFixedHeadDimensions = () => {
        const { current: fixedHead } = this.fixedHeadRef;
        const { current: tableWrap } = this.tableWrapRef;
        if (!tableWrap || !fixedHead) return;

        fixedHead.style.left = `${ tableWrap.getBoundingClientRect().left }px`;
        fixedHead.style.width = `${ tableWrap.offsetWidth }px`;

        this.setFixedHeadContLeft();
        this.setFixedHeadColSizes();
        this.setFixedHeadTop();
    };

    checkFixedHead = () => {
        if (!this.fixedHeadRef || !this.tableWrapRef || !this.fixedHeadRef.current || !this.tableWrapRef.current) {
            return;
        }
        const tableHeadHeight = this.fixedHeadRef.current.getBoundingClientRect().height;
        const tableWrapRect = this.tableWrapRef.current.getBoundingClientRect();
        const panelRect = actionsPanelState.panelEl.getBoundingClientRect();
        const scrollTop = window.pageYOffset;

        this.isFixedHeadVisible = scrollTop + panelRect.top + panelRect.height > tableWrapRect.top + scrollTop && scrollTop + panelRect.top + panelRect.height + tableHeadHeight < scrollTop + tableWrapRect.top + tableWrapRect.height
        && ((!this.isSearchRowVisible && tableWrapRect.bottom > 160) || (this.isSearchRowVisible && tableWrapRect.bottom > 205));
    };

    setFixedHeadTop = () => {
        const panelRect = actionsPanelState.panelEl.getBoundingClientRect();
        let fixedHead;
        if (this.fixedHeadRef && this.fixedHeadRef.current) {
            fixedHead = this.fixedHeadRef.current;
        }
        if (!fixedHead) {
            return;
        }
        fixedHead.style.top = panelRect.top + panelRect.height + 'px';
    };

    setFixedHeadColSizes = () => {
        const fixedHeader = this.fixedHeadRowRef ? this.fixedHeadRowRef.current : null;
        const header = this.headRowRef ? this.headRowRef.current : null;
        if (!header || !fixedHeader) {
            return;
        }
        for (let i = 0; i < header.children.length; i++) {
            if (!fixedHeader.children[i].children[0] || !header.children[i].children[0]) return;
            (fixedHeader.children[i].children[0] as HTMLElement).style.width = header.children[i].children[0].getBoundingClientRect().width + 'px';
        }
    };

    setFixedHeadContLeft = () => {
        const wrap = this.tableWrapRef ? this.tableWrapRef.current : null;
        const container = this.fixedContainerRef ? this.fixedContainerRef.current : null;
        if (!wrap || !container) {
            return;
        }
        container.style.left = -wrap.scrollLeft + 'px';
    };

    checkHorizontalScroll = () => {
        if (!this.fixedHeadRef || !this.tableWrapRef || !this.fixedHeadRef.current || !this.tableWrapRef.current) {
            return;
        }
        const tableWrapRect = this.tableWrapRef.current.getBoundingClientRect();
        const tableHeadHeight = this.fixedHeadRef.current.getBoundingClientRect().height;
        const scrollTop = window.pageYOffset;
        const windowHeight = window.document.documentElement.clientHeight;
        this.isHorizontalScrollVisible = tableWrapRect.top + scrollTop + tableWrapRect.height > scrollTop + windowHeight && tableWrapRect.top + scrollTop + tableHeadHeight < scrollTop + windowHeight;
    };

    setHorizontalScrollLeft = () => {
        if (!this.tableWrapRef || !this.fixedScrollRef || !this.tableWrapRef.current || !this.fixedScrollRef.current) {
            return;
        }
        this.fixedScrollRef.current.scrollLeft = this.tableWrapRef.current.scrollLeft;
        const tableState = this.getStateTable();
        tableState.setScrollLeft(this.tableWrapRef.current.scrollLeft);
    };

    setTableWrapScrollLeft = () => {
        if (!this.fixedScrollRef || !this.tableWrapRef || !this.fixedScrollRef.current || !this.tableWrapRef.current) {
            return;
        }
        this.tableWrapRef.current.scrollLeft = this.fixedScrollRef.current.scrollLeft;
        const tableState = this.getStateTable();
        tableState.setScrollLeft(this.tableWrapRef.current.scrollLeft);
    };

    updateHorizontalScrollDimensions = () => {
        const { current: fixedScroll } = this.fixedScrollRef;
        const { current: tableWrap } = this.tableWrapRef;
        const { current: table } = this.tableRef;
        if (!tableWrap || !fixedScroll || !table) return;

        fixedScroll.style.left = `${ tableWrap.getBoundingClientRect().left }px`;
        fixedScroll.style.width = `${ tableWrap.offsetWidth }px`;
        (fixedScroll.children[0] as HTMLElement).style.width = `${ table.offsetWidth }px`;
    };

    selectDictionary = () => {
        window.opener.postMessage(_.cloneDeep(this.getStateTable().getSelectByList()), '*');
    };

    checkPagination = () => {
        const tableState = this.getStateTable();
        const pagination = tableState.getCurrentPagination();
        const { total, perPage, pageTotal } = pagination;

        if (!total || !perPage || tableState.isDisablePageCalculation() || !pageTotal) {
            return false;
        }
        return pageTotal <= perPage;
    };

    getHeaderPaginationHtml = () => {
        const { isMobile, onChangePage, location } = this.props;
        const tableState = this.getStateTable();
        const pagination = tableState.getCurrentPagination();
        const checkPaginationBoolean = this.checkPagination();
        if (location && location.search.includes('GROUPBY') && !checkPaginationBoolean && !isMobile) {
            return (
                    <Pagination
                        isMobile={ isMobile }
                        pagination={ pagination }
                        onChangePage={ onChangePage }
                    />
            );
        }
        return checkPaginationBoolean || isMobile ? null : (
            <Pagination
                pagination={ pagination }
                onChangePage={ onChangePage }
            />
        );
    };

    renderHeaderTable = () => {
        const { isBlankMode, isServicePortal, isRelatedList, doFetch, chosenOptionsState } = this.props;
        const isTableBlankMode = isBlankMode === undefined ? false : !isServicePortal && isBlankMode;
        if (isTableBlankMode) {
            return null;
        }
        const tableState = this.getStateTable();
        const paginationHtml = this.getHeaderPaginationHtml();
        const { totalString, totalNumber } = tableState.getTotals();

        return (
            <div className={ styles.tableTop }>
                <NumberOfElements
                    total={ totalNumber }
                    totalString={ totalString }
                    type={ isRelatedList ? 'related' : 'table' }
                    name={ tableState.getName() }
                    doFetch={ doFetch }
                    chosenOptionsState={ chosenOptionsState }
                />
                { paginationHtml }
            </div>
        );
    };

    renderBody() {
        const {
            conditionState, formId, isBlankMode, classes, isMobile, isServicePortal, usedByList,
            recordId, recordTableName, maxLength, isWindow, isRelatedList, clientScripts, location, relatedListId,
        } = this.props;
        const tableState = this.getStateTable();
        if (location && location.search.includes('GROUPBY')) {
            return (
                <TableGroups
                    isBlankMode={ isBlankMode }
                    classes={ classes }
                    isMobile={ isMobile }
                    conditionState={ conditionState }
                    toggleSearchRowVisibility={ this.toggleSearchRowVisibility }
                    isSearchRowVisible={ this.isSearchRowVisible }
                    isFixedHeadVisible={ this.isFixedHeadVisible }
                    fixedHeadRef={ this.fixedHeadRef }
                    fixedHeadRowRef={ this.fixedHeadRowRef }
                    fixedContainerRef={ this.fixedContainerRef }
                    fixedScrollRef={ this.fixedScrollRef }
                    tableState={ tableState }
                    formId={ formId }
                    isServicePortal={ isServicePortal }
                    usedByList={ usedByList }
                    recordId={ recordId }
                    recordTableName={ recordTableName }
                    isHorizontalScrollVisible={ this.isHorizontalScrollVisible }
                    maxLength={ maxLength }
                    isWindow={ isWindow }
                    checkedRows={ this.getCheckedRow() }
                    selectDictionary={ this.selectDictionary }
                    tableWrapRef={ this.tableWrapRef }
                    tableRef={ this.tableRef }
                    headRowRef={ this.headRowRef }
                    renderHeadListMenu={ this.renderHeadListMenu }
                    renderContextMenu={ this.renderContextMenu }
                    renderHeadConditionsMenu={ this.renderHeadConditionsMenu }
                    table={ this }
                    onCheckRow={ this.onCheckRow }
                    clientScripts={ clientScripts }
                    isRelatedList={ isRelatedList }
                    isShowAllGroups={ this.isShowAllGroups }
                    handleChangeEditCol={ this.handleChangeEditCol }
                />
            );
        }
        const { list_titles } = langStore.getTranslate();
        const filterFields = conditionState ? conditionState.getFilterFields() : [];
        const sortingFields = conditionState ? conditionState.getSortingFields() : [];

        const styleTableWrap = [ styles.tableWrap ];
        if (!isServicePortal) {
            styleTableWrap.push(styles.mainTable);
        }
        if (classes && classes.TableWrapper) {
            styleTableWrap.push(classes.TableWrapper);
        }

        const fixedHorizontalScroll = [ styles.fixedHorizontalScroll ];
        if (classes && classes.fixedHorizontalScroll) {
            fixedHorizontalScroll.push(classes.fixedHorizontalScroll);
        }

        let hiddenEntriesMessage;
        const pagination = tableState.getCurrentPagination();
        if (pagination.hiddenEntries) {
            hiddenEntriesMessage = <div className={ styles.tableMessage }>
                <div className={ styles.icon } dangerouslySetInnerHTML={ { __html: IconEyeOff } } />
                { list_titles && list_titles.hidden_entries }
            </div>;
        } else if (pagination.pageTotal === 0 || pagination.total === 0) {
            hiddenEntriesMessage = <div className={ styles.tableMessage }>{ list_titles && list_titles.no_items }</div>;
        }

        const headerClass = classes && classes.Header ? classes.Header : '';
        return (
            <React.Fragment>
                <FixedHeadTable
                    isBlankMode={ isBlankMode }
                    classes={ classes }
                    isMobile={ isMobile }
                    filterFields={ filterFields }
                    sortingFields={ sortingFields }
                    toggleSearchRowVisibility={ this.toggleSearchRowVisibility }
                    isSearchRowVisible={ this.isSearchRowVisible }
                    isFixedHeadVisible={ this.isFixedHeadVisible }
                    fixedHeadRef={ this.fixedHeadRef }
                    fixedHeadRowRef={ this.fixedHeadRowRef }
                    fixedContainerRef={ this.fixedContainerRef }
                    table={ this }
                />
                <div className={ styleTableWrap.join(' ') } ref={ this.tableWrapRef }>
                    <table
                        className={ `${ styles.Table }` }
                        ref={ this.tableRef }
                        data-test={ ATTRIBUTES.table }
                    >
                        <Thead
                            reference={ this.headRowRef }
                            table={ this }
                            filterFields={ filterFields }
                            sortingFields={ conditionState ? conditionState.getSortingFields() : [] }
                            onSearchClick={ this.toggleSearchRowVisibility }
                            isSearchRowVisible={ this.isSearchRowVisible }
                            recordId={ recordId }
                            recordTableName={ recordTableName }
                            isBlankMode={ isBlankMode }
                            isWindow={ isWindow }
                            usedByList={ usedByList }
                            className={ headerClass }
                            formId={ formId }
                            maxLength={ maxLength }
                            isMobile={ isMobile }
                            data-test={ ATTRIBUTES.tableHeader }
                        />
                        <Tbody
                            key={ this.items }
                            items={ tableState.getRows() }
                            table={ this }
                            filterFields={ filterFields }
                            conditionState={ conditionState }
                            isServicePortal={ isServicePortal }
                            isRelatedList={ isRelatedList }
                            onCheckRow={ this.onCheckRow }
                            isBlankMode={ isBlankMode }
                            isWindow={ isWindow }
                            usedByList={ usedByList }
                            classes={ classes }
                            clientScripts={ clientScripts }
                            isMobile={ isMobile }
                            formId={ formId }
                            relatedListId={ relatedListId }
                            handleChangeEditCol={ this.handleChangeEditCol }
                            tableState={ tableState }
                        />
                    </table>
                    { this.renderHeadListMenu() }
                    { this.renderContextMenu() }
                    { this.renderHeadConditionsMenu() }
                    { hiddenEntriesMessage }
                </div>
                <div
                    className={ `${ fixedHorizontalScroll.join(' ') } ${ this.isHorizontalScrollVisible && !isMobile ? styles.visible : '' }` }
                    ref={ this.fixedScrollRef }>
                    <div />
                </div>
                { (usedByList && this.getCheckedRow().length > 0) && (
                    <Button buttonType={ 'primary' }
                            className={ styles.SelectButton }
                            onClick={ this.selectDictionary }>
                        { list_titles && list_titles.select_items }
                    </Button>
                )
                }
            </React.Fragment>
        );
    }

    renderDropdownGroups = () => {
        const { columns } = this.props;
        const items = _.map(columns, (column: TableColumn) => {
            return (
                <div
                    key={ `${ column.db_column_name }${ column.db_column_id }`}
                    className={ styles.MenuItem }
                    onClick={ this.handleClickGroup(column.db_column_name) }
                >
                    { column.label }
                </div>
            );
        });
        return (
            <div className={ styles.Menu }>
                { items }
            </div>
        );
    };

    renderDropdownItems = () => {
        const tableState = this.getStateTable();
        if (!tableState.getIsShowListGroups()) {
            return null;
        }
        return (
            <Dropdown refParent={ this.refParent } ref={ this.refDropdown }>
                { this.renderDropdownGroups() }
            </Dropdown>
        );
    };

    renderFooterTable = () => {
        const { isBlankMode, isServicePortal, isMobile, onChangePage, conditionState } = this.props;
        const isTableBlankMode = isBlankMode === undefined ? false : !isServicePortal && isBlankMode;
        if (isTableBlankMode) {
            return null;
        }
        const tableState = this.getStateTable();
        const pagination = tableState.getCurrentPagination();
        const checkPaginationBoolean = this.checkPagination();
        let showSelectUiAction = false;

        if (this.props.selectUiAction && pagination.total > 1) {
            if (this.props.isWindow) {
                showSelectUiAction = !!this.props.usedByList;
            } else {
                showSelectUiAction = true;
            }
        }
        const selectUiActionHtml = showSelectUiAction && !isMobile ? (
            <SelectUiAction
                value={ this.selectUiActionCheck }
                essence={ this.props.essence }
                items={ this.props.selectUiAction }
                onChangeCheckbox={ this.checkAllRow }
                className={ styles.SelectUiAction }
            />
        ) : null;
        const paginationHtml = !checkPaginationBoolean ? (
            <Pagination
                isMobile={ isMobile }
                pagination={ pagination }
                onChangePage={ onChangePage }
            />
        ) : null;
        const groupingFields = conditionState.getGroupingFields();
        if (!_.isEmpty(groupingFields) && !checkPaginationBoolean) {
            return (
                <div className={ styles.tableEnd }>
                    { selectUiActionHtml }
                        <Pagination
                            isMobile={ isMobile }
                            pagination={ pagination }
                            onChangePage={ onChangePage }
                        />
                </div>
            );
        }
        const selectGroups = null; // TODO сейчас не нужно, в будущем возможно нужно будет
        /*(
            <div
                className={ styles.GroupsButton }
                onClick={ this.handleClickGroupedButton }
                ref={ this.refParent }
            >
                <div dangerouslySetInnerHTML={ { __html: IconFolder } } />
                <div className={ styles.TextGroupsButton }>
                    { list_titles && list_titles.group_by }
                </div>
                <div dangerouslySetInnerHTML={ { __html: IconChevronDown } } />
            </div>
        );*/

        if(!selectUiActionHtml && !selectGroups && !this.renderDropdownItems() && (!pagination.perPage || (pagination.total < pagination.perPage))) {
            return null;
        }
        return (
            <div className={ styles.tableEnd }>
                { selectUiActionHtml }
                { selectGroups }
                { this.renderDropdownItems() }
                { paginationHtml }
            </div>
        );
    };

    render() {
        const tableState = this.getStateTable();
        if (!tableState.getRows()) {
            return null;
        }

        return (
            <React.Fragment>
                { this.renderHeaderTable() }
                { this.renderBody() }
                { this.renderFooterTable() }
            </React.Fragment>
        );
    }

    get globalTableState() { // Для проверки состояния в тестах
        return globalTableState;
    }
}


export const GroupedTable = withRouter(observer(GroupedTableClass));
