/**
 * Ключ у этого объекта будет fieldName
 * Значение этого объекта - ссылка на компонент
 */
//window.dynamicFormsGlobals = {};
window.listGlobal = {};
window.recordGlobal = {};
//window.tableGlobal = {};
window.currentFieldLabel = {};
window.currentCell = {};
window.currentUiAction = {};

import SimpleAjax from './simple-ajax';
import SimpleForm from './simple-form';
import SimpleUser from './simple-user';
import SimpleSystem from './simple-system';
import SimpleRecord from './simple-record';
import SimpleI18n from 'ui-actions-scripts/simple-i18n';
import SimpleGo from './simple-go';
import SimplePreferences from './simple-preferences';
// import SimpleTables from 'globalState/tables';
import SimpleMenu from 'ui-actions-scripts/simple-menu';
import SimpleEventBus from './simple-eventbus';
import SimpleStorage from './simple-storage';
import SimpleList from './simple-list';
import SimpleModals from './simple-modals';

window.SimpleAjax = SimpleAjax;
window.simpleUser = SimpleUser;
window.SimpleForm = SimpleForm;
window.SimpleRecord = SimpleRecord;
window.SimpleMenu = new SimpleMenu;
window.SimpleEventBus = new SimpleEventBus;
window.SimpleStorage = new SimpleStorage;
window.s_list = new SimpleList;
// window.SimpleTables = SimpleTables;
window.s_i18n = new SimpleI18n;
window.ss = new SimpleSystem;
window.s_go = new SimpleGo;
window.s_modals = new SimpleModals();
window.s_pref = new SimplePreferences();
