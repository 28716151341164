import { observable, action, makeObservable } from 'mobx';
import apiRequest from 'lib/apiRequest';

class DebuggerState {
    id = null;
    data = [];

    constructor() {
        makeObservable(this, {
            id: observable,
            fetchData: action,
        });
    }

    setId(id) {
        if (id) {
            this.fetchData(id);
        } else {
            this.data = [];
            this.id = null;
        }
    }

    isActive() {
        return this.id !== null;
    }

    async fetchData(sys_id) {
        const response = await new apiRequest('POST /page-debug/make-report').send({
            sys_id,
        });

        if (response.status === 'OK') {
            this.data.push(response.getData());
            this.id = sys_id;
        }
    }
}

export default new DebuggerState;
