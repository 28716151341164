import * as React from 'react';
import { observer } from 'mobx-react';
import {observable, makeObservable} from 'mobx';
import styles from './styles.module.scss';
import Select from 'components/conditionFilters/select';
import Button from 'components/button';
import ConditionField from 'components/conditionFilters/conditionField';
import langStore from 'globalState/lang';
import { showField } from 'helpers/condition';
import IconX from 'assets/img/icons/close-x.svg';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { ConditionFiltersProps } from 'types/components/conditionFilters/conditionFilters';

/**
 * Описание: компонент ConditionFilters
 * Параметры:
 * row - объект со значениями строки condition builder { field, operator, value }, { field, direction }
 * tableId - таблица
 * readOnly - поля доступны только для чтения
 * extraAttributes - дополнительные опции для поля
 * isActive - condition с активным выпадающим списком
 * data - список condition
 * onAddFiltering - обработчик добавления фильтров
 * block - Номер блока верхнего уровня
 * type - OR/AND
 * onRemoveFilteringRow - обработчик удаления фильтров
 * onRemoveSortingRow - обработчик удаления сортировки
 * onRemoveGroupingRow - обработчик удаления группировки
 * index - индекс строки
 * onConditionChange - родительский метод изменения (необязательный)
 * isMobile - мобильная версия
 * isWindow - открыто в словаре
 */

class ConditionFilters extends React.Component<ConditionFiltersProps> {
    timeout: NodeJS.Timeout;

    constructor(props) {
        super(props);

        makeObservable(this, {
            timeout: observable,
        });
    }

    isSorting = () => {
        return this.props.row.getId().includes('sorting');
    };

    isGrouping = () => {
        return this.props.row.getId().includes('grouping');
    };

    handleChangeFilteringField = async (resValue, referencedTableId) => {
        const { row, extraAttributes, tableId, data, onConditionChange } = this.props;
        const filteredData = data.filter(field => field.condition_type === row.getField().condition_type);
        await row.changeFilteringField(resValue, referencedTableId || tableId, extraAttributes, filteredData);
        if (onConditionChange) {
            onConditionChange();
        }
    };

    handleChangeSortingField = (resValue) => {
        const { row, onConditionChange } = this.props;
        row.setField(resValue);
        if (onConditionChange) {
            onConditionChange();
        }
    };

    handleChangeGroupingField = (resValue) => {
        const { row, onConditionChange } = this.props;
        row.setField(resValue);
        if (onConditionChange) {
            onConditionChange();
        }
    };

    handleChangeOperator = async (data) => {
        const { row, onConditionChange } = this.props;
        const filteredData = this.props.data.filter(field => field.condition_type === row.getField().condition_type);
        await row.changeFilteringOperator(data.value, filteredData, this.props.tableId);
        if (onConditionChange) {
            onConditionChange();
        }
    };

    handleChangeFieldValue = async (data) => {
        const { row, onConditionChange } = this.props;
        await row.changeFilteringValue(data.value);
        if (onConditionChange) {
            onConditionChange();
        }
    };

    handleChangeDirection = (data) => {
        const { row, onConditionChange } = this.props;
        row.setDirection(data.value);
        if (onConditionChange) {
            onConditionChange();
        }
    };

    handleRemoveFilteringRow = () => {
        const { row, onRemoveFilteringRow, onConditionChange } = this.props;
        if (onRemoveFilteringRow) {
            onRemoveFilteringRow(row.getId());
        }
        if (onConditionChange) {
            onConditionChange();
        }
    };

    handleRemoveSortingRow = () => {
        const { row, onRemoveSortingRow, onConditionChange } = this.props;
        if (onRemoveSortingRow) {
            onRemoveSortingRow(row.getId());
        }
        if (onConditionChange) {
            onConditionChange();
        }
    };

    handleRemoveGroupingRow = () => {
        const { row, onRemoveGroupingRow, onConditionChange } = this.props;
        if (onRemoveGroupingRow) {
            onRemoveGroupingRow(row.getId());
        }
        if (onConditionChange) {
            onConditionChange();
        }
    };

    setActive = (state) => {
        const { setActive, row } = this.props;
        const value = state ? row.getId() : null;
        setActive(value);
    };

    renderSorting = () => {
        const { row, data, isActive, readOnly, isMobile } = this.props;
        const sortOptions = {
            values: langStore.getConditionSortOption(),
        };
        const value = row.getField();
        return (
            <div className={ `${styles.Row} ${isMobile ? styles.MobileRow : ''}` }>
                <div className={ styles.Fields }>
                    <div className={ styles.Field }>
                        <ConditionField
                            data={ data }
                            onChange={ this.handleChangeSortingField }
                            value={ value }
                            readOnly={ readOnly }
                            setActive={ this.setActive }
                            isActive={ isActive }
                            data-test={ ATTRIBUTES.conditionRowSortField }
                        />
                    </div>
                    <div className={ styles.Field }>
                        <Select
                            value={ !value ? '' : row.getDirection() }
                            special={sortOptions}
                            className={ styles.Select }
                            onChange={ this.handleChangeDirection }
                            readOnly={ readOnly || !value }
                            data-test={ ATTRIBUTES.conditionRowDirection }
                        />
                    </div>
                    <div className={ `${ styles.Remove } ${ styles.sorting }` }>
                        <Button
                            onClick={ this.handleRemoveSortingRow }
                            buttonType="icon"
                            svg={ IconX }
                            disabled={ readOnly }
                            data-test={ ATTRIBUTES.conditionRowSortButtonRemove }
                        />
                    </div>
                </div>
            </div>
        );
    };

    renderGrouping = () => {
        const { row, data, isActive, readOnly, isMobile } = this.props;
        const groupOptions = {
            values: langStore.getConditionGroupOptions(),
        };
        const value = row.getField();
        return (
            <div className={ `${styles.Row} ${isMobile ? styles.MobileRow : ''}` }>
                <div className={ styles.Fields }>
                    <div className={ styles.Field }>
                        <ConditionField
                            data={ data }
                            onChange={ this.handleChangeGroupingField }
                            value={ value }
                            readOnly={ readOnly }
                            setActive={ this.setActive }
                            isActive={ isActive }
                            data-test={ ATTRIBUTES.conditionRowGroupField }
                        />
                    </div>
                    <div className={ styles.Field }>
                        <Select
                            value={ !value ? '' : row.getDirection() }
                            special={groupOptions}
                            className={ styles.Select }
                            onChange={ this.handleChangeDirection }
                            readOnly={ readOnly || !value }
                            data-test={ ATTRIBUTES.conditionRowDirection }
                        />
                    </div>
                    <div className={ `${ styles.Remove } ${ styles.sorting }` }>
                        <Button
                            onClick={ this.handleRemoveGroupingRow }
                            buttonType="icon"
                            svg={ IconX }
                            disabled={ readOnly }
                            data-test={ ATTRIBUTES.conditionRowGroupButtonRemove }
                        />
                    </div>
                </div>
            </div>
        );
    };

    renderFilterFieldValue = () => {
        const { row, readOnly, isWindow, maxLength: maxLengthFind } = this.props;
        const { filter_titles } = langStore.getTranslate();
        if (row.getOperator().show_element === 'none') {
            return null;
        }
        const special = {
            table_name: row.getField().referenced_table_name,
            table_id: row.getField().referenced_table_id,
        };
        const placeholder = [
            'date',
            'datetime',
            'datetime_specific',
            'time',
            'timestamp',
        ].includes(row.getOperator().show_element) ? filter_titles?.enter_date : filter_titles?.enter_value;
        const props = {
            isWindow,
            readOnly,
            placeholder,
            column_type: row.getField().column_type,
            column_id: row.getField().column_id,
            isCondition: true,
            validate: row.getField().validate,
            ['data-test']: 'condition-field-value',
        };
        const filteredData = this.props.data.filter(field => field.condition_type === row.getField().condition_type);
        return (
            <div className={ styles.Field }
                data-test={ ATTRIBUTES.conditionRowValue }
            >
                {
                    showField(
                        row.getOperator().show_element,
                        row.getValue(),
                        this.handleChangeFieldValue,
                        this.handleChangeFieldValue,
                        row.getOptions(filteredData),
                        special,
                        props,
                        false,
                        maxLengthFind,
                        row.getValueOpts(),
                    )
                }
            </div>
        );
    };

    render() {
        const { row, readOnly, data, isActive, index, conditionCount, isHideOrButton, onAddFiltering } = this.props;
        const { filter_titles } = langStore.getTranslate();

        if (this.isSorting()) {
            return this.renderSorting();
        }

        if (this.isGrouping()) {
            return this.renderGrouping();
        }

        const operatorSpecial = {
            values: row.getOperatorOptions(),
        };

        const orPrefix = row.getType() === 'OR' ? (
            <div className={ styles.BlockText }>{ filter_titles?.label_or }</div>
        ) : null;

        const removeButton = conditionCount !== 1 ?
            (
                <Button
                    className={ styles.Button }
                    onClick={ this.handleRemoveFilteringRow }
                    buttonType="icon"
                    svg={ IconX }
                    disabled={ readOnly }
                    data-test={ ATTRIBUTES.conditionRowButtonRemove }
                />
            ) : null;

        return (
            <div className={ `${styles.Row} ${this.props.isMobile ? styles.MobileRow : ''}` } data-test={ this.props['data-test'] }>
                <div className={ styles.Fields }>
                    { orPrefix }
                    <div className={ styles.Field }>
                        <ConditionField
                            isMobile={ this.props.isMobile }
                            data={ data }
                            onChange={ this.handleChangeFilteringField }
                            value={ row.getField() }
                            readOnly={ readOnly }
                            setActive={ this.setActive }
                            isActive={ isActive }
                            index={index}
                            data-test={ ATTRIBUTES.conditionRowField }
                        />
                    </div>
                    <div className={ styles.Field }>
                        <Select
                            value={ row.getOperator() }
                            special={ operatorSpecial }
                            onChange={ this.handleChangeOperator }
                            readOnly={ readOnly || operatorSpecial.values && operatorSpecial.values.length === 0 }
                            data-test={ ATTRIBUTES.conditionRowOperator }
                        />
                    </div>
                    { this.renderFilterFieldValue() }

                    <div className={ styles.Remove }>
                        <Button
                            className={ styles.Button }
                            onClick={ onAddFiltering ? onAddFiltering('AND', row.getBlock(), index as number) : () => {} }
                            disabled={ readOnly }
                            data-test={ ATTRIBUTES.conditionRowButtonAnd }
                        >
                            { filter_titles?.button_and }
                        </Button>
                        { !isHideOrButton ? (
                            <Button
                                className={ styles.Button }
                                onClick={ onAddFiltering ? onAddFiltering('OR', row.getBlock(), index as number) : () => {} }
                                disabled={ readOnly }
                                data-test={ ATTRIBUTES.conditionRowButtonOr }
                            >
                                { filter_titles?.button_or }
                            </Button>) : null
                        }
                        { removeButton }
                    </div>
                </div>
            </div>
        );
    }
}

export default observer(ConditionFilters);
