import apiRequest from 'lib/apiRequest';
import { handleResponse } from 'helpers/action';
import {BrandingResponse} from "types/globalState/branding";

/**
 * Получение данных для формы входа
 * */
export const fetchBranding = async (): Promise<BrandingResponse> => {
    const response = await new apiRequest('GET /sys-property/get-branding').caching().send();
    return handleResponse(response);
};