import * as React from 'react';
import { observer } from 'mobx-react';
import UiButton from 'components/uiButtons/uiButton';
import styles from './styles.module.scss';
import { observable, makeObservable } from 'mobx';
import Dropdown from 'components/dropdown';
import Button from 'components/button';
import IconMoreH from 'assets/img/icons/more-horizontal-kebab.svg';
import IconMoreV from 'assets/img/icons/more-vertical-kebab.svg';
import IconClose from 'assets/img/icons/close-x.svg';
import MobileModal from 'components/modalWrapper';

/**
 * Описание: бургер меню для ui-кнопок
 * Параметры:
 * data
 * recordId
 * tableName
 * buttonClass
 * isMobile
 * menuTitle
 */
class UiBurger extends React.Component {
    refButton = React.createRef();
    refDropdown = React.createRef();
    isMenuOpened = false;

    constructor(props) {
        super(props);

        makeObservable(this, {
            isMenuOpened: observable,
        });
    }

    componentDidMount() {
        window.addEventListener('scroll', this.onWindowScroll);
        window.addEventListener('resize', this.onWindowResize);
        document.addEventListener('mousedown', this.onDocumentClick);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onWindowScroll);
        window.removeEventListener('resize', this.onWindowResize);
        document.removeEventListener('mousedown', this.onDocumentClick);
    }

    onDocumentClick = (e) => {
        const dropdownEl = this.refDropdown ? this.refDropdown.current : null;
        const buttonEl = this.refButton.current;
        if (!dropdownEl || !buttonEl) return false;

        if (!dropdownEl.contains(e.target) && !buttonEl.contains(e.target)) {
            this.showMenu(false);
        }
    };

    onWindowScroll = () => {
        if (!this.props.isMobile) {
            this.showMenu(false);
        }
    };

    onWindowResize = () => {
        if (!this.props.isMobile) {
            this.showMenu(false);
        }
    };

    showMenu = (flag) => {
        this.isMenuOpened = flag;
    };

    renderMenu = () => {
        const items = this.props.data.map((item, index) => {
            return (
                <UiButton
                    key={ `${ index }_${ item.sys_id }` }
                    item={ item }
                    tableName={ this.props.tableName }
                    recordId={ this.props.recordId }
                    disableUiActions={ this.props.disableUiActions }
                    isBurgerItem
                    afterClick={ () => {
                        this.showMenu(false);
                    } }
                />
            );
        });

        if (this.props.isMobile) {
            return (
                <MobileModal>
                    <div className={ styles.Mobile } ref={ this.refDropdown }>
                        <div className={ styles.MobileHeader }>
                            <div className={ styles.MobileTitle }>
                                { this.props.menuTitle }
                            </div>
                            <Button
                                buttonType={ 'icon' }
                                svg={ IconClose }
                                onClick={ () => {
                                    this.showMenu(false);
                                } }
                                className={ styles.MobileClose }
                            />
                        </div>
                        <div className={ styles.MobileMenu }>
                            { items }
                        </div>
                    </div>
                </MobileModal>
            );
        }
        else {
            return (
                <Dropdown refParent={ this.refButton } ref={ this.refDropdown } floatRight disableMinWidth>
                    <div className={ styles.Menu }>
                        { items }
                    </div>
                </Dropdown>
            );
        }

    };

    onButtonClick = () => {
        this.showMenu(!this.isMenuOpened);
    };

    render() {
        if (!this.props.data || this.props.data.length === 0) {
            return null;
        }

        return (
            <>
                <Button
                    buttonType={ 'icon' }
                    svg={ this.props.isMobile ? IconMoreV : IconMoreH }
                    ref={ this.refButton }
                    onClick={ this.onButtonClick }
                    className={ this.props.buttonClass }
                />
                { this.isMenuOpened && this.renderMenu() }
            </>
        );
    }
}

export default observer(UiBurger);
