import FormsState from 'globalState/forms';
import _ from 'lodash';
import { historyGo, history, prevLocationLength, setPrevLocationLength } from 'helpers/history';
import { updateWidgets } from 'helpers/widget';
import { WIDGET_NAMES } from 'constants/widgets';
import activityFeeds from 'globalState/activityFeeds';

/**
 * @todo вынести приватные методы в отдельный класс не доступный из API
 * @private
 *
 * @param name
 * @param component
 * @param functionName
 * @return {boolean}
 */
function checkComponentAndFunctionOfComponentAvailable(name, component, functionName) {
    if (!component) {
        console.warn(`${ name } not found`);
        return false;
    }
    if (typeof component[functionName] !== 'function') {
        console.warn(`Function ${ functionName }, for ${ name } not found`);
        return false;
    }
    return true;
}

export default class SimpleForm {

    constructor(table, sysId, formView, formName, dynamicFormId) {
        this.table = table;
        this.sysId = sysId;
        this.formView = formView;
        this.formName = formName;
        this.dynamicFormId = dynamicFormId;
    }

    /**
     * Returns is fields on form changed
     * @return {boolean}
     */
    isChanged() {
        const allFields = FormsState.getFields();
        return _.some(allFields, f => f.changed);
    }

    hasChanges() {
        return this.isChanged();
    }

    /**
     * Returns an array of changed fields objects, with the field name, previous value and current value
     * @return {Object[] | []}
     */
    getChanges() {
        const sections = this.getSections();
        const fields = _.reduce(sections, (result, section) => {
            const secFields = section.getSectionFields();
            return [
                ...secFields,
                ...result,
            ];
        }, []);

        let result = [];
        _.forEach(fields, (field) => {
            if (field.changed) {
                result.push({
                    fieldName: field.sysColumnName,
                    previousValue: field.previousValue,
                    currentValue: field.getValueForSave(),
                });
            }
        });
        return result;
    }

    /**
     * Returns true / false if the field is changed
     * @param {string} fieldName
     * @return {boolean}
     */
    isFieldChanged(fieldName) {
        const field = this._getComponent(fieldName);
        if (field) {
            return !!field.changed;
        }
    }

    fieldHasChanges(fieldName) {
       return this.isFieldChanged(fieldName);
    }

    /**
     * Returns true / false if the field is visible
     * @param {string} fieldName
     * @return {boolean}
     */
    isVisible(fieldName) {
        const field = this._getComponent(fieldName);
        if (field) {
            return !!field.isVisible;
        }
    }

    /**
     * Returns true / false if the field is required
     * @param {string} fieldName
     * @return {boolean}
     */
    isMandatory(fieldName) {
        const field = this._getComponent(fieldName);
        if (field) {
            return !!field.isMandatory;
        }
    }

    /**
     * Returns true / false if the field is read-only
     * @param {string} fieldName
     * @return {boolean}
     */
    isReadonly(fieldName) {
        const field = this._getComponent(fieldName);
        if (field) {
            return !!field.readOnly;
        }
    }

    /**
     * Returns true / false if the form is valid
     * @return {boolean}
     */
    isValid() {
        const form = FormsState.getSection(this.dynamicFormId);
        if (form && form.parentFormModel) {
            return !form.parentFormModel.checkFormIsInvalid();
        }
        else if (form) {
            return !form.checkFormIsInvalid();
        }
    }

    // Go to the previous page, if this is not possible go to the list of the current entity
    goBack() {
        const nowLocationLength = history.length;

        if (!prevLocationLength) {
            window.s_go.open(`/list/${ this.table }`);
        }
        else {
            historyGo(prevLocationLength - nowLocationLength);
            setPrevLocationLength(null);
        }
    }

    //Returns the database value of the field
    getValue(fieldName) {
        const field = this._getComponent(fieldName);
        const widgets = FormsState.getWidgets();
        const activityFeedWidget = _.find(widgets, widget => widget.name === WIDGET_NAMES.activityFeed);
        const activityFeedState = activityFeeds.getItem(this.table, this.sysId);
        if (activityFeedWidget && activityFeedState && field) {
            const activityType = _.find(activityFeedState.getTypeTabs(), type => type.journalInputId === field.columnId);
            if (activityType) {
                return activityType ? activityType.comment : '';
            }
        }
        if (field) {
            return field.uiGetValue();
        }
    }

    //Returns the display value of the field
    getDisplayValue(fieldName) {
        const field = this._getComponent(fieldName);
        if (field) {
            return field.uiGetDisplayValue();
        }
    }

    //Sets the value of a field
    async setValue(fieldName, databaseValue) {
        const field = this._getComponent(fieldName);
        const widgets = FormsState.getWidgets();
        const activityFeedWidget = _.find(widgets, widget => widget.name === WIDGET_NAMES.activityFeed);
        if (activityFeedWidget && field) {
            const activityFeedState = activityFeeds.getItem(this.table, this.sysId);
            if (activityFeedState) {
                const activityType = _.find(activityFeedState.getTypeTabs(), type => type.journalInputId === field.columnId);
                if (activityType) {
                    activityType.comment = databaseValue;
                }
            }
        }

        if (field) {
            return field.uiSetValue(databaseValue);
        }
    }

    //Returns the plain text value of the field label
    getLabelOf(fieldName) {
        const field = this._getComponent(fieldName);
        if (field) {
            return field.uiGetLabelOf();
        }
    }

    //Sets the plain text value of the field label
    setLabelOf(fieldName, value) {
        const field = this._getComponent(fieldName);
        if (field) {
            return field.uiSetLabelOf(value);
        }
    }

    //Returns true if the record has never been saved
    isNewRecord() {
        return !this.sysId;
    }

    //Добавляет выбор в конец поля списка выбора.
    async addOption(fieldName, choiceValue) {
        const field = this._getComponent(fieldName);
        if (field && field.columnType === 'choice' && !_.isNil(choiceValue)) {
                await field.uiAddOption(choiceValue.toString());
        }
    }

    //Удаляет указанную опцию из списка выбора.
    removeOption = (fieldName, choiceValue) => {
        const field = this._getComponent(fieldName);
        if (field && field.columnType === 'choice') {
            field.uiRemoveOption(choiceValue);
        }
    };

    //Сохраняет запись без навигации (обновление и сохранение).
    save() {
        const form = FormsState.getSection(this.dynamicFormId);
        return form.save(this.formView).then((recordID) => {
            updateWidgets();
            return recordID;
        });
    }

    submit() {
        this.save();
    }

    //Делает указанное поле доступным только для чтения или редактируемым
    //По возможности используйте политику пользовательского интерфейса, а не этот метод.
    //Чтобы сделать обязательное поле доступным только для чтения, сначала нужно удалить требование обязательности поля с помощью метода setMandatory().
    setReadOnly(fieldName, readOnly = true) {
        const field = this._getComponent(fieldName);
        const widgets = FormsState.getWidgets();
        const activityFeedWidget = _.find(widgets, widget => widget.name === WIDGET_NAMES.activityFeed);
        const activityFeedState = activityFeeds.getItem(this.table, this.sysId);
        if (activityFeedWidget && activityFeedState && field) {
            const activityType = _.find(activityFeedState.getTypeTabs(), type => type.journalInputId === field.columnId);
            if (activityType) {
                activityType.isReadOnly = readOnly;
                if (activityType.isReadOnly && activityType.isActive) {
                    const nonFilteredTabs = _.filter(activityFeedState.getTypeTabs(), type => !type.isActive);
                    nonFilteredTabs[0] ? nonFilteredTabs[0].isActive = true : '';
                    activityType.isActive = false;
                }
            }
        }

        if (field) {
            return field.uiSetReadOnly(readOnly);
        }
    }

    //  Делает указанное поле обязательным.
    //  По возможности используйте политику пользовательского интерфейса, а не этот метод.
    setMandatory(fieldName, mandatory = true) {
        const field = this._getComponent(fieldName);
        const widgets = FormsState.getWidgets();
        const activityFeedWidget = _.find(widgets, widget => widget.name === WIDGET_NAMES.activityFeed);
        const activityFeedState = activityFeeds.getItem(this.table, this.sysId);
        if (activityFeedWidget && activityFeedState && field) {
            const activityType = _.find(activityFeedState.getTypeTabs(), type => type.journalInputId === field.columnId);
            if (activityType) {
                activityType.isRequired = mandatory;
                if (activityType.isRequired && !activityType.isActive) {
                    const activeTab = _.find(activityFeedState.getTypeTabs(), type => type.isActive);
                    activeTab ? activeTab.isActive = false : '';
                    activityType.isActive = true;
                }
            }
        }

        if (field) {
            return field.uiSetMandatory(mandatory);
        }
    }

    //  Отображает или скрывает поле.
    //  Если поле скрыто, пространство остается пустым. Этот метод не может скрыть обязательные поля без значения.
    //  По возможности используйте политику пользовательского интерфейса, а не этот метод.
    setVisible(fieldName, display) {
        const field = this._getComponent(fieldName);
        if (field) {
            field.uiSetVisible(display);
        }
    }

    // Отображает информационное сообщение или сообщение об ошибке в указанном поле формы.
    showFieldMsg(fieldName, message, type) {
        const field = this._getComponent(fieldName);
        if (field) {
            field.showFieldMsg(message, type);
        }
    }

    /**
     * Вывести сообщение об ошибке
     * @param {string} message
     */
    addErrorMessage(message) {
        //const component = window.dynamicFormsGlobals['FormMessages'];
        const formMessagesComponent = FormsState.getMessages();
        if (formMessagesComponent) {
            formMessagesComponent.uiAddMessage('error', message);
        }
    }

    /**
     * Вывести информационное сообщение
     * Когда duration равно нулю, сообщение не скрывается
     * @param {string} message
     * @param {number} [duration]
     */
    addInfoMessage(message, duration) {
        const formMessagesComponent = FormsState.getMessages();
        if (formMessagesComponent) {
            formMessagesComponent.uiAddMessage('info', message, duration);
        }
    }

    /**
     * Вывести сообщение о предупреждении
     * @param {string} message
     * @param {number} [duration]
     */
    addWarningMessage = (message, duration) => {
        const formMessagesComponent = FormsState.getMessages();
        if (formMessagesComponent) {
            formMessagesComponent.uiAddMessage('warning', message, duration);
        }
    };

    /**
     * Вывести сообщение об успехе
     * @param {string} message
     * @param {number} [duration]
     */
    addSuccessMessage = (message, duration) => {
        const formMessagesComponent = FormsState.getMessages();
        if (formMessagesComponent) {
            formMessagesComponent.uiAddMessage('success', message, duration);
        }
    };

    // Скрывает информационное сообщение или сообщение об ошибке на всех или в указанном поле формы .
    hideFieldMsg(fieldName, clearAll = false) {
        if (clearAll) {
            const sections = FormsState.getSections(this.dynamicFormId);
            if (Array.isArray(sections) && sections.length) {
                sections.forEach(section => section.uiHideFieldMsg(fieldName, clearAll));
            }
        }
        else {
            const field = this._getComponent(fieldName);
            if (field) {
                field.hideFieldMsg();
            }
        }

    }

    //Removes all informational and error messages from the top of the form.
    clearMessages() {
        const formMessagesComponent = FormsState.getMessages();
        if (checkComponentAndFunctionOfComponentAvailable('FormMessages', formMessagesComponent, 'uiClearMessages')) {
            formMessagesComponent.uiClearMessages();
        }
    }

    //Removes all options from the choice list.
    clearOptions(fieldName) {
        const field = this._getComponent(fieldName);
        if (checkComponentAndFunctionOfComponentAvailable(`Field ${ fieldName }`, field, 'uiClearOptions') && field.columnType === 'choice') {
            field.uiClearOptions();
        }
    }

    //Removes any value(s) from the field.
    clearValue(fieldName) {
        const field = this._getComponent(fieldName);
        if (field) {
            return field.uiClearValue();
        }
    }

    getSectionNames() {
        const sections = this.getSections();
        if (sections.length) {
            return sections.map((section) => section.name);
        }
        return [];
    }

    getSections() {
        return FormsState.getSections(this.dynamicFormId);
    }

    isSectionVisible(sectionName) {
        const tabs = FormsState.getTabs(this.dynamicFormId);
        if (sectionName && checkComponentAndFunctionOfComponentAvailable(`Tab ${ sectionName }`, tabs, 'uiIsSectionVisible')) {
            return tabs.uiIsSectionVisible(sectionName);
        }
    }

    setSectionDisplay(sectionName, display) {
        const tabs = FormsState.getTabs(this.dynamicFormId);
        if (sectionName && checkComponentAndFunctionOfComponentAvailable(`Tab ${ sectionName }`, tabs, 'uiSetSectionDisplay')) {
            tabs.uiSetSectionDisplay(sectionName, display);
        }
    }


    hideRelatedList(listTableName) {
        // const component = window.dynamicFormsGlobals[this.table + '.relatedList'][listTableName];
        if (listTableName) {
            const component = FormsState.getRelatedList(listTableName);
            if (checkComponentAndFunctionOfComponentAvailable(`Related list ${ listTableName }`, component, 'uiHideRelatedList')) {
                component.uiHideRelatedList();
            }
        }
    }

    hideRelatedLists() {
        // const component = window.dynamicFormsGlobals[this.table + '.relatedLists'];
        const component = FormsState.getRelatedLists();
        if (checkComponentAndFunctionOfComponentAvailable('Related lists', component, 'uiHideRelatedLists')) {
            component.uiHideRelatedLists();
        }
    }

    showRelatedLists() {
        // const component = window.dynamicFormsGlobals[this.table + '.relatedLists'];
        const component = FormsState.getRelatedLists();
        if (checkComponentAndFunctionOfComponentAvailable('Related lists', component, 'uiShowRelatedLists')) {
            component.uiShowRelatedLists();
        }
    }

    showRelatedList(listTableName) {
        // const component = window.dynamicFormsGlobals[this.table + '.relatedList'][listTableName];
        if (listTableName) {
            const component = FormsState.getRelatedList(listTableName);
            if (checkComponentAndFunctionOfComponentAvailable('Related list', component, 'uiShowRelatedList')) {
                component.uiShowRelatedList();
            }
        }
    }

    getAllFields(isAll) {
        const component = FormsState.getSection(this.dynamicFormId);
        return component ? FormsState.serialize(this.dynamicFormId, isAll) : {
            data: {},
            error: [],
            notChange: false,
        };
    }

    //Gets the primary key of the record, which is usually the sys_id unless otherwise specified.
    getUniqueValue() {
        return this.sysId;
    }

    //Gets the table name of the record
    getTableName() {
        return this.table;
    }

    //получить title атрибута
    //Returns the plain text value of the REM attribute
    getREMLabelOf(fieldName) {
        const field = this._getREMComponent(fieldName);
        if (field) {
            return field.uiGetLabelOf();
        }
    }

    //получить value атрибута
    //Returns the database value of the REM attribute
    getREMValue(fieldName) {
        const field = this._getREMComponent(fieldName);
        if (field) {
            return field.uiGetValue();
        }
    }

    //получить displayValue атрибута
    //Returns the display value of the REM attribute
    getREMDisplayValue(fieldName) {
        const field = this._getREMComponent(fieldName);
        if (field) {
            return field.uiGetDisplayValue();
        }
    }

    //изменить title атрибута (только для изменения отображения)
    //Sets the plain text value of a REM attribute label
    setREMLabelOf(fieldName, value) {
        const field = this._getREMComponent(fieldName);
        if (field) {
            return field.uiSetLabelOf(value);
        }
    }

    //изменить value атрибута
    //Sets the value of a REM attribute label
    async setREMValue(fieldName, databaseValue) {
        const field = this._getREMComponent(fieldName);
        if (field) {
            return field.uiSetValue(databaseValue);
        }
    }


    //сделать атрибут обязательным
    //  Set REM attribute as mandatory
    setREMMandatory(fieldName, mandatory = true) {
        const field = this._getREMComponent(fieldName);
        if (field) {
            return field.uiSetMandatory(mandatory);
        }
    }

    //заблокировать редактирование атрибута
    // Set REM attribure as read only
    setREMReadOnly(fieldName, readOnly = true) {
        const field = this._getREMComponent(fieldName);
        if (field) {
            return field.uiSetReadOnly(readOnly);
        }
    }

    // скрыть атрибут
    //  set visibility of a REM attribute
    setREMVisible(fieldName, display) {
        const field = this._getREMComponent(fieldName);
        if (field) {
            field.uiSetVisible(display);
        }
    }

    // перезагружает кнопки на форме, пересчитывает серверный condition
    // reloads buttons on the form, recalculates the server condition
    refreshUiActions() {
        window.recordGlobal[this.table].fetchUiActions();
    }

    /**
     * @todo вынести приватные методы в отдельный класс не доступный из API
     * @private
     *
     * Returns component Form from dynamicFormsGlobals by field name
     **/
    _getComponent(fieldName) {
        if (fieldName) {
            const sections = this.getSections();
            for (let key in sections) {
                const field = sections[key].getFieldByName(fieldName);
                if (field) {
                    return field;
                }
            }
        }
        return null;
    }

    // Найти REM компонент
    _getREMComponent(fieldName) {
        const section = FormsState.getRemSection(this.dynamicFormId, this.table, this.sysId);
        if (fieldName && section) {
            const field = section.getFieldByName(fieldName);
            if (field) {
                return field;
            }
        }
        return null;
    }
}
