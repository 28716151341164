import React, { useState, useRef, useEffect } from 'react';
import styles from './styles.module.scss';
import LinkIcon from 'assets/img/icons/link.svg';
import EditIcon from 'assets/img/icons/edit-2.svg';
import {KeyboardKeys} from "types/components/dynamicForms/maskInput";

const MAX_LENGTH = 37;

export default function LinkInput({ linkUrl, setLinkUrl, onClose }) {
    const [ isEdit, setIsEdit ] = useState(!linkUrl);
    const [ isFocused, setIsFocused ] = useState(false);
    const [ inputValue, setInputValue ] = useState(linkUrl);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const onKeyPress = (evt) => {
            if ([KeyboardKeys.Enter, KeyboardKeys.Escape].includes(evt.key)) {
                evt.preventDefault();
                onClose();
            }
        };

        document.addEventListener('keydown', onKeyPress);
        return () => {
            document.removeEventListener('keydown', onKeyPress);
        };
    }, []);


    useEffect(() => {
        setInputValue(linkUrl);
        setIsEdit(!linkUrl);
    }, [ linkUrl ]);

    const getShortValue = (value: string) => {
        return value.length > MAX_LENGTH ? `${ value.slice(0, MAX_LENGTH) }...` : value;
    };

    const onEditClick = () => {
        setIsEdit(true);
        setTimeout(() => inputRef?.current?.focus(), 0);
    };

    const onChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
    };

    const onBlur = () => {
        let value = inputValue;
        setIsFocused(false);
        const domains = [ 'ftp://', 'http://', 'https://' ];
        const isShortLink = !domains.some(domain => value.includes(domain));
        if (value) {
            setIsEdit(false);
            if (isShortLink) {
                value = `${ window.location.origin }/${ value }`;
            }
        }
        setLinkUrl(value);
    };

    const renderLink = () => {
        if (linkUrl.trim() === '') {
            return null;
        }

        return (
            <>
                <a
                    className={ styles.Link }
                    href={ linkUrl }
                    target="_blank"
                >
                    { getShortValue(linkUrl) }
                </a>
                <div
                    className={ `${ styles.Icon } ${ styles.EditIcon }` }
                    dangerouslySetInnerHTML={ { __html: EditIcon } }
                />
            </>
        );
    };

    const renderInput = () => {
        return (
            <input
                placeholder={ 'https://' }
                className={ styles.Input }
                value={ inputValue }
                onChange={ onChange }
                ref={ inputRef }
                onFocus={ () => {
                    setIsFocused(true);
                } }
            />
        );
    };

    return (
        <div
            className={ `${ styles.Container } ${ isFocused ? styles.Focus : '' }` }
            onClick={ onEditClick }
            onBlur={ onBlur }
        >
            <div
                className={ `${ styles.Icon } ${(inputValue || isFocused) ? styles.FocusIcon : ''}` }
                dangerouslySetInnerHTML={ { __html: LinkIcon } }
            />
            {
                isEdit ? renderInput() : renderLink()
            }
        </div>
    );
}
