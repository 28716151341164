import apiRequest from 'lib/apiRequest';
import { handleResponse } from 'helpers/action';
import {
    DeleteFavoriteResponse,
    SaveFavoriteResponse,
} from 'types/components/menu';
import { DataMenuType } from 'types/globalState/menu';

/**
 * @param params
 * @returns {Promise<{data: *, response: *, isOkStatus: boolean}|{data: {}, response: *, isOkStatus: boolean}>}
 */
export const saveFavorite = async (params: DataMenuType): Promise<SaveFavoriteResponse> => {
    const response = await new apiRequest(`POST /menu/save-favorite`).sendJSON(params);
    return handleResponse(response);
};

/**
 * @param params
 * @returns {Promise<{data: *, response: *, isOkStatus: boolean}|{data: {}, response: *, isOkStatus: boolean}>}
 */
export const deleteFavorite = async (params: DataMenuType): Promise<DeleteFavoriteResponse> => {
    const response = await new apiRequest(`POST /menu/delete-favorite`).sendJSON(params);
    return handleResponse(response);
};

export const fetchMenuList = async (getFromCache: boolean) => {
    const response = await new apiRequest(`GET /menu/get-list`)
        .caching(true, getFromCache)
        .send();
    return handleResponse(response);
};

export const fetchFavorites = async (getFromCache: boolean) => {
    const response = await new apiRequest(`GET /menu/get-favorites`)
        .caching(true, getFromCache)
        .send();
    return handleResponse(response);
};

export const fetchDynamicMenuList = async (dynamicCategodyId) => {
    const response = await new apiRequest(`GET /menu-dynamic/get-nested/${ dynamicCategodyId }`).send();
    return handleResponse(response);
};

export const fetchDynamicMenuShortList = async (dynamicCategodyId) => {
    const response = await new apiRequest(`GET /menu-dynamic/get-nested-short/${ dynamicCategodyId }`).send();
    return handleResponse(response);
};
