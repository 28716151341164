/**
 * Описание: Класс Конец - элемент диаграммы рабочих процессов
 * Параметры:
 * sys_id - внутренний идентификатор объекта (хранится в БД)
 * type_id - тип активности
 * x - положение объекта на диаграмме
 * y - положение объекта на диаграмме
 * height - высота объекта на диаграмме
 * width - ширина объекта на диаграмме
 * name - наименование активности
 * description - описание активности
 * exits - массив выходов
 * updatePosition - метод обновления положения объекта в state после его перетаскивания
 * selectObject - метод переключения текущего объекта (выделен/не выделен).
 * selected - выделенный объект на диаграмме объект
 * activeExit - выделенный на диаграмме выход активности
 * isActive - блок является активным (создаваемая/редактируемая связь направлена на него)
 * setActiveActivity - выбрать активность, как конечную, при создании свзяи
 * togglePropertiesPanel - метод открытия панели свойств
 * getIcon - метод получения иконки (по типу активности)
 * readOnly - запрет на редактирование
 * state - статус выполнения активности при режиме viewer
 * */

import * as React from 'react';
import { Rect, Group, Text, Image } from 'react-konva';

const regularBgColor = '#FFFFFF';
const regularImageColor = '#05C270';
const regularTextColor = '#17181D';

const completedBgColor = '#05C270';
const completedImageColor = '#FFFFFF';
const completedTextColor = '#FFFFFF';

const selectedColor = '#5927d3';

export default class End extends React.Component {

    bgColor;
    color;
    imageColor;

    constructor(props) {
        super(props);
        this.loadColors();
        this.updateIcon();
    }

    loadColors = () => {
        switch (this.props.state) {
            case 'finished':
                this.bgColor = completedBgColor;
                this.color = completedTextColor;
                this.imageColor = completedImageColor;
                break;
            default:
                this.bgColor = regularBgColor;
                this.color = regularTextColor;
                this.imageColor = regularImageColor;
        }
    };

    handleClick = (evt) => {
        this.props.selectObject('wf_activity', this.props.sys_id); // toggle selection
        evt.cancelBubble = true;
    };

    async updateIcon() {
        this.icon = await this.props.getIcon(this.props.typeId, this.imageColor);
    }

    isSelected = () => {
        return this.props.selected.type === 'wf_activity' && this.props.selected.sys_id === this.props.sys_id;
    };

    componentDidCatch(error, info) {
        console.error(error, info);
    }

    handleDragMove = (evt) => {
        this.props.updatePosition(
            this.props.sys_id,
            {
                x: Math.round(evt.target.x()),
                y: Math.round(evt.target.y()),
            },
            false,
        );
        evt.cancelBubble = true;
    };

    handleDragEnd = (evt) => {
        this.props.updatePosition(this.props.sys_id, {
            x: Math.round(evt.target.x()),
            y: Math.round(evt.target.y()),
        });
        evt.cancelBubble = true;
    };

    render() {
        return (
            <Group
                draggable={ !this.props.activeExit && !this.props.readOnly }
                onDragMove={ this.handleDragMove }
                onDragEnd={ this.handleDragEnd }
                x={ this.props.x }
                y={ this.props.y }
                width={ this.props.width }
                height={ this.props.height }
                id={ 'wf_activity' + this.props.sys_id }
                onMouseOver={ () => {
                    this.props.setActiveActivity(this.props.sys_id);
                } }
                onMouseOut={ () => {
                    this.props.setActiveActivity(null);
                } }
                onClick={ this.handleClick }
                onContextMenu={ (e) => {
                    e.evt.preventDefault(true);
                } }
            >
                <Rect
                    x={ 0 }
                    y={ 0 }
                    width={ this.props.width + 2 }
                    height={ this.props.height + 2 }
                    fill={ !!this.isSelected() || this.props.isActive ? selectedColor : null }
                    cornerRadius={ 9 }
                />
                <Rect
                    x={ 1 }
                    y={ 1 }
                    width={ this.props.width }
                    height={ this.props.height }
                    fill={ this.bgColor }
                    cornerRadius={ 8 }
                    listening={ false }
                />
                { this.icon &&
                <Image image={ this.icon }
                       x={ 35 }
                       y={ 24 }
                       listening={ false }
                /> }
                <Text
                    x={ 0 }
                    y={ this.props.height / 2 + 8 }
                    width={ this.props.width }
                    height={ this.props.height }
                    text={ this.props.name }
                    fontSize={ 15 }
                    fontFamily='Open Sans'
                    fill={ this.color }
                    fontStyle='600'
                    align='center'
                    listening={ false }
                />
            </Group>
        );
    }
}