import apiRequest from 'lib/apiRequest';
import { handleResponse } from 'helpers/action';
import { HandleResponse } from 'types/actions/main';
import {ColumnInfoParamsType, RuntimeCellValueResponse} from 'types/actions/record';


/**
 * Запрос данных для рекорда
 */
export const fetchRecord = async (tableName: string, recordId: string, params: object, handleForbiddenError = false): Promise<HandleResponse> => {
    const request = recordId ?
        ('GET /record/' + tableName + '/' + recordId) :
        ('GET /record/' + tableName);
    const response = await new apiRequest(request).qs(params).send(null, handleForbiddenError);
    return handleResponse(response);
};

/**
 * Запрос ui actions для рекорда
 */
export const fetchRecordUiActions = async (tableName: string, recordId: string, params: object): Promise<HandleResponse> => {
    const request = recordId ?
        ('GET /ui-action/record-actions/' + tableName + '/' + recordId) :
        ('GET /ui-action/record-actions/' + tableName);
    const response = await new apiRequest(request).qs(params).caching().send();
    return handleResponse(response);
};

/**
 * Запрос данных для рем набора
 */
export const fetchRemAttr = async (tableName: string, recordId: string, modelId: string, isServicePortal: boolean): Promise<HandleResponse> => {
    type ParamsType = {
        table_name?: string;
        record_id?: string;
        model_id?: string;
        is_service_portal?: number;
    }

    const request = 'GET /rem/get-data';
    let params: ParamsType = {};
    if (tableName){
        params.table_name = tableName;
    }
    if (recordId){
        params.record_id = recordId;
    }
    if (modelId){
        params.model_id = modelId;
    }
    if (isServicePortal) {
        params.is_service_portal = 1;
    }
    const response = await new apiRequest(request).qs(params).send();
    return handleResponse(response);
};

export async function deleteRecord(table: string, sys_ids: string[]): Promise<HandleResponse> {
    const response = await new apiRequest(`POST /delete/${ table }`).send({
        sys_ids,
    }).catch(console.error);
    return handleResponse(response);
}

export const fetchColumnInfo = async (params: ColumnInfoParamsType): Promise<HandleResponse> => {
    const response = await new apiRequest(`GET /selection/record`).qs(params).send().catch(console.error);
    return handleResponse(response);
};

// получение списка значений
export const fetchCellData = async (tableName: string, columnName: string, sysId: string): Promise<HandleResponse> => {
    const response = await new apiRequest(`GET /list-cell/${tableName}/${columnName}/${sysId}`).send();
    return handleResponse(response);
};

// обновление значения ячейки
export async function updateCellData(table: string, column: string, id: string, value: any): Promise<HandleResponse> {
    const data = {
        value: value,
    };
    const response = await new apiRequest(`PUT /list-cell/${ table }/${ column }/${ id }`)
        .send(data)
        .catch(console.error);
    return handleResponse(response);
}

export async function getRuntimeCellValue(tableName: string, columnName: string, recordId: string): Promise<RuntimeCellValueResponse> {
    const response = await new apiRequest(`GET /list-cell-value/${ tableName }/${ columnName }/${ recordId }`).send().catch(console.error);
    return handleResponse(response);
}



