import apiRequest from 'lib/apiRequest';
import { handleResponse } from 'helpers/action';


/**
 * Запрос для сброса кэша у бэкенда
 * @returns {Promise<{data: *, response: *, isOkStatus: boolean}|{data: {}, response: *, isOkStatus: boolean}>}
 */
export const resetCache = async () => {
    const response = await new apiRequest(`POST /cache/reset-cache`).send();
    return handleResponse(response);
};
