import * as React from 'react';
import { observer } from 'mobx-react';
import IconExternal from 'assets/img/icons/external-link.svg';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import tableStyles from 'components/table/styles.module.scss';
import { observable, makeObservable } from 'mobx';
import { beforeUnload } from 'helpers/form';


class RefLink extends React.Component {
    refText = React.createRef();
    refLink = React.createRef();
    timeout = null;
    isVisible = false;

    handleLinkClick = (evt) => {
        const { isRelatedList } = this.props;
        const unload = beforeUnload({});
        if (isRelatedList && unload && !confirm(unload)) {
            evt.preventDefault();
            return;
        }
    }

    constructor(props) {
        super(props);

        makeObservable(this, {
            isVisible: observable,
        });
    }

    renderIcon() {
        const { isOuter, url } = this.props;
        const link = isOuter ?
            <a
                href={ url }
                target="_blank"
                className={ `${ styles.Link }` }
                dangerouslySetInnerHTML={ { __html: IconExternal } }
                onClick={ this.handleLinkClick }
            /> :
            <Link
                to={ url }
                className={ `${ styles.Link }` }
                dangerouslySetInnerHTML={ { __html: IconExternal } }
                onClick={ this.handleLinkClick }
            />;
        return (
            <div className={`${styles.LinkWrap} ${tableStyles.refIconLink}`}>
                { link }
            </div>
        );
    }

    render() {
        return (
            <div className={styles.Text}>
                { this.props.children }
                { this.renderIcon() }
            </div>
        );
    }
}

export default observer(RefLink);
