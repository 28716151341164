import FormsState from 'globalState/forms/index';
import langStore from "globalState/lang/index";
import _ from 'lodash';
import {FieldsType} from "types/globalState/forms";
import { LINK_TITLE_UNAVAILABLE, LINK_NOT_FOUND, LINK_UNAVAILABLE } from 'constants/strings';
import {BadgeValue} from "types/components/dynamicForms/view/field/list/badges";

export const beforeUnload = (evt: Record<string,any>): string | null => {
    const { browser_utilities = {} } = langStore.getTranslate();
    const fields = FormsState.getFields();
    const findChanged = _.filter(fields, f => (f as FieldsType).changed);
    if (findChanged.length) {
        evt.returnValue = browser_utilities.unsaved_changes;
        return evt.returnValue;
    }
    return null;
};

export const isChanged = (defaultValue: any, value: any): boolean => {
    if (defaultValue === 0 || value === 0) {
        return defaultValue !== value;
    }
    if (typeof defaultValue === 'number') {
        return !_.isEqual(defaultValue, value);
    }
    const isEmpty = _.isEmpty(defaultValue) && _.isEmpty(value);
    return isEmpty ? false : !_.isEqual(defaultValue, value);
};

export const getLinkDisplayValue = (value: BadgeValue, useParentheses = false): string => {
    const { reference_titles } = langStore.getTranslate();
    let output = '';
    if (value) {
        if (value.reference_state === LINK_NOT_FOUND){
            output = reference_titles.badge_record_not_found;
        } else if (value.reference_state === LINK_UNAVAILABLE) {
            output = reference_titles.badge_unavailable_data;
        } else if (value.reference_state === LINK_TITLE_UNAVAILABLE) {
            output = reference_titles.badge_unavailable_title;
        } else {
            output = value.display_value;
        }
        if (useParentheses && [LINK_NOT_FOUND, LINK_UNAVAILABLE, LINK_TITLE_UNAVAILABLE].includes(value.reference_state as string)){
            output = '(' + output + ')';
        }
    }
    return output;
};

export const isEqualDynamicForm = (form, tableName, sysId = '', view = '') => {
    const isEqualTableName = form.tableName === tableName;
    if (view && sysId) {
        return isEqualTableName && form.sysId === sysId && form.formView === view;
    }
    if (sysId) {
        return isEqualTableName && form.sysId === sysId;
    }
    return isEqualTableName;
};
