import * as React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { observer } from "mobx-react";
import { observable, makeObservable } from "mobx";
import styles from "./styles.module.scss";
import PageLoader from "components/pageLoader";
import Container from "components/container/index";
import SmartTitle from "components/smartTitle";
import Button from "components/button";
import QuickButtonReturn from 'components/quickButtonReturn';
import RecordPopupButton from 'components/recordPopup/infoButton';
import langStore from "globalState/lang/index";
import searchState from 'globalState/search';
import ErrorWrapperHoc from "helpers/hoc/errorWrapperHOC";
import { getUrlParams } from "helpers/data";
import { getFormatValue, getParseText, simpleDecodeUri } from "helpers/search";
import { isMedia } from 'helpers/html';
import { fetchSearchCategories, fetchSearchResultsData } from "actions/search";
import IconArrow from "assets/img/icons/chevron-right.svg";
import IconSearch from "assets/img/icons/search.svg";
import Info from 'assets/img/icons/info.svg';
import { MatchProps } from "types/pages/search";
import { Group, GroupTable, Record } from 'types/actions/search';
import { ATTRIBUTES } from 'constants/attributesForTests';

/**
 * Описание: страница поиска => В дальнейшем перепишится на виджеты
 * Параметры:
 * searchParam: {required: true, type: string} - параметр для поиска
 * */
class SearchPage extends React.Component<RouteComponentProps<MatchProps>> {
    groups: Group[] = [];
    data: Record[] = [];
    displayedData: Record[] = [];
    isFetching = false;
    isLastPost = false;
    isFetchingMore = false;
    isFetchCategory = false;
    isCategoryEmpty = false;
    currentCategory = '';
    page = 0;
    perPage: number;
    lastRouteParams: string;

    constructor(props) {
        super(props);

        makeObservable(this, {
            groups: observable,
            data: observable,
            displayedData: observable,
            isFetching: observable,
            isLastPost: observable,
            isFetchingMore: observable,
            isFetchCategory: observable,
            isCategoryEmpty: observable,
        });

        searchState.setSearchParam(this.getGlobalSearchParam() ? simpleDecodeUri(this.getGlobalSearchParam()) : '');
    }

    componentDidMount() {
        this.fetchData().catch(this.errorFetchData);
    }

    componentDidUpdate(prevProps) {
        const { global_search_param } = prevProps.match.params;
        const prevGlobalSearchParam = decodeURIComponent(global_search_param);

        if (this.getGlobalSearchParam() !== prevGlobalSearchParam) {
            searchState.setSearchParam(this.getGlobalSearchParam() ? simpleDecodeUri(this.getGlobalSearchParam()) : '');
            this.restParams();
            this.fetchData().catch(this.errorFetchData);
        }
    }

    fetchData = async () => {
        if (!searchState.getSearchParam().length) return;
        const { match, location } = this.props;
        const parsedSearchParams = getUrlParams(location.search);
        const routeParamsString = JSON.stringify({
            ...match.params,
            ...parsedSearchParams,
        });
        if (this.lastRouteParams === routeParamsString) {
            return false;
        }
        this.lastRouteParams = routeParamsString;

        try {
            this.isFetching = true;
            const { data } = await fetchSearchCategories();

            this.groups = data.groups;

            const { records } = await this.fetchDataResult();
            this.data = records;
            this.displayedData = this.data.slice(0, this.perPage);

            this.isFetching = false;
            this.cleanCategory();
        } catch (e) {
            this.errorFetchData(e);
        }
    };

    fetchDataResult = async (table?: GroupTable) => {
        try {
            const query = simpleDecodeUri(searchState.getSearchParam());
            const sysId = table?.sys_id || '';

            const { data } = await fetchSearchResultsData(query, this.page, this.perPage, undefined, sysId);

            this.perPage = data.per_page;
            this.page = data.page + 1;

            if (data.records.length < this.perPage) {
                this.isLastPost = true;
            }

            return data;
        } catch (e) {
            throw e;
        }
    };

    cleanCategory = async () => {
        await Promise.all(this.groups.map(async (group) => {
            if (group.tables.length) {
                await this.readCategory(group);
            }
        }));
    }

    readCategory = async (group: Group) => {
        const tables = group.tables;
        await Promise.all(tables.map(async (table) => {
            const query = simpleDecodeUri(searchState.getSearchParam());
            const sysId = table?.sys_id;

            const { data: { records } } = await fetchSearchResultsData(query, 0, this.perPage, undefined, sysId);

            table.isNotEmpty = !!records.length;
        }));
    }

    errorFetchData = (e) => {
        console.error(e.message);
    };

    handleLoadMore = async () => {
        if (this.data.length > this.displayedData.length) {
            this.displayedData = this.data.slice(0, this.perPage + this.displayedData.length);
        } else {
            // Записи кончились в "кэше"
            this.isFetchingMore = true;
            const { records } = await this.fetchDataResult().finally(() => this.isFetchingMore = false);
            if (records.length) {
                this.data.push(...records);
                this.displayedData = this.data.slice(0, this.perPage + this.displayedData.length);
            }
        }
    }

    handleValueChange = (e) => {
        searchState.setSearchParam(e.target.value);
    };

    handleClickMenu = (item: Group) => (e) => {
        const parent = e.target.closest("." + styles.item);
        parent.classList.toggle(styles.active);
        item.default_opened = !item.default_opened;
    };

    handleClickItemMenu = async (item?: GroupTable) => {
        const chosenCategory = item?.sys_id || '';
        if (this.currentCategory === chosenCategory) return;

        this.currentCategory = chosenCategory;
        this.page = 0;
        this.isLastPost = false;

        this.isFetchCategory = true;

        const { records } = await this.fetchDataResult(item);
        this.data = records;
        this.displayedData = this.data.slice(0, this.perPage);

        this.isCategoryEmpty = !records.length;

        this.isFetchCategory = false;
    }

    restParams = () => {
        this.currentCategory = '';
        this.page = 0;
        this.isLastPost = false;
        this.data = [];
        this.groups = [];
        this.displayedData = [];
    }

    getGlobalSearchParam() {
        const { global_search_param = "" } = this.props.match.params;
        return decodeURIComponent(global_search_param);
    }

    getRecordUrl(record: Record) {
        return `/record/${ record.table_name }/${ record.sys_id }`;
    }

    getListUrl(record: Record) {
        const searchParam = searchState.getSearchParam();

        return `/list/${ record.table_name }?condition=(keywordsARE${ encodeURIComponent(searchParam) })`;
    }

    calculateLoadPercent() {
        let itemsLength = 0;
        let loadItemsLength = 0;

        for (const group of this.groups) {
            group.tables.forEach(table => {
                itemsLength++;
                if (table.hasOwnProperty('isNotEmpty')) {
                    loadItemsLength++;
                }
            });
        }

        return loadItemsLength * 100 / itemsLength;
    }

    getRecordDescription({ displayed_columns }: Record) {
        return displayed_columns.map((col) => {
            let value = col.value;
            if (value.includes("&lt") || value.includes("srch_plh")) {
                const replacer = (match, p1) => {
                    return `<span class='${
                        styles.matching
                    }' data-test='${ ATTRIBUTES.searchDynamicMatchValue }'>${ getFormatValue(
                        col.column_type,
                        p1
                    ) }</span>`;
                };
                value = getParseText(col.value, replacer);
            } else {
                value = getFormatValue(col.column_type, value);
            }
            value = `<span class='${ styles.ItemValue }' data-test='${ ATTRIBUTES.searchDynamicFieldValue }'>${ value }</span>`;

            return (
                <span
                    key={ col.title }
                    className={ styles.ItemWrapper }
                    dangerouslySetInnerHTML={ { __html: col.title + ": " + value } }
                    data-test={ ATTRIBUTES.searchDynamicField }
                />
            );
        });
    }

    recordSubInfo({ sub_info_columns }: Record) {
        return sub_info_columns.map(col => {
            const value = `<span class='${ styles.ItemValue }' data-test='${ ATTRIBUTES.searchStaticFieldValue }'>${ getFormatValue(col.column_type, col.value) }</span>`;

            return (
                <span
                    key={ col.title }
                    className={ [ styles.ItemWrapper, styles.SubItems ].join(' ') }
                    dangerouslySetInnerHTML={ { __html: `${ col.title }: ${ value }` } }
                    data-test={ ATTRIBUTES.searchStaticField }
                >
                </span>
            );
        });
    }

    renderSubMenu(item: Group) {
        if (item.default_opened) {
            return (
                <div className={ styles.submenu }>
                    { item.tables?.map((subItem: GroupTable) => {
                        return (
                            <div
                                key={ subItem.sys_id }
                                className={ this.currentCategory === subItem.sys_id
                                    ? `${ styles.link } ${ styles.ChosenCategory }`
                                    : styles.link }
                                data-test={ ATTRIBUTES.searchSubCategory }
                            >
                                <div className={ styles.linkWrap }>
                                    <div
                                        className={ `${ styles.link } ${ styles.subItemLink } ${ !subItem.isNotEmpty ? `${styles.disableItem} ${styles.noHover}` : '' }` }
                                        onClick={ () => subItem.isNotEmpty && this.handleClickItemMenu(subItem) }
                                    >
                                        <div
                                            className={ styles.label }
                                            title={ subItem.title }
                                        >
                                            { subItem.title }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }) }
                </div>
            );
        }
        return null;
    }

    renderProgressBar() {
        const loadPercent = this.calculateLoadPercent();
        let stylesStr = [ styles.progressBarLine ];
        if (loadPercent === 100) {
            stylesStr.push(styles.hideBar);
        }

        return (
            <div className={ styles.progressBar }>
                <div className={ stylesStr.join(' ') } style={ { width: `${ loadPercent }%` } } />
            </div>
        );
    }

    renderMenu() {
        const { search_widget_titles } = langStore.getTranslate();

        if (!this.data.length && !this.isCategoryEmpty) return;

        return (
            <div
                className={ styles.searchMenu }
                data-test={ ATTRIBUTES.searchCategory }
            >
                { this.renderProgressBar() }
                <div className={ styles.item } data-test={ ATTRIBUTES.searchAllResults }>
                    <div className={ styles.linkWrap }>
                        <div className={ this.currentCategory === ''
                            ? `${ styles.link } ${ styles.ChosenCategory }`
                            : styles.link
                        } onClick={ () => this.handleClickItemMenu() }>
                            <div
                                className={ styles.SearchIcon }
                                dangerouslySetInnerHTML={ { __html: IconSearch } }
                            />
                            <div className={ styles.label }>
                                { search_widget_titles?.all_results }
                            </div>
                        </div>
                    </div>
                </div>
                { this.groups.map(item => {
                    const isFilledGroup = item.tables.some(item => item.isNotEmpty);
                    const dataTestName = `${ATTRIBUTES.searchCategoryLink}${!isFilledGroup ? '-disabled' : ''}`;
                    return (
                        <div
                            key={ item.title }
                            className={
                                item.default_opened
                                    ? `${ styles.active } ${ styles.item }`
                                    : styles.item
                            }
                            data-test={ ATTRIBUTES.searchCategoryItem }
                        >
                            <div
                                className={ styles.linkWrap }
                                data-test={ dataTestName }
                            >
                                <div
                                    className={ `${ styles.link } ${ !isFilledGroup ? styles.disableItem : '' }` }
                                    onClick={ this.handleClickMenu(item) }
                                >
                                    <div
                                        className={ styles.ArrowIcon }
                                        dangerouslySetInnerHTML={ { __html: IconArrow } }
                                        data-test={ ATTRIBUTES.searchCategoryArrow }
                                    />
                                    <div
                                        className={ styles.label }
                                        data-test={ ATTRIBUTES.searchCategoryName }
                                    >
                                        { item.title }
                                    </div>
                                </div>
                            </div>
                            { this.renderSubMenu(item) }
                        </div>
                    );
                }) }
                {/*{ this.renderSkeleton() }*/ }
            </div>
        );
    }

    renderRecord(record: Record) {
        return (
            <div key={ record.sys_id } className={ styles.record }>
                <div className={ styles.LinkWrapper }>
                    { !isMedia('sm') &&
                        <RecordPopupButton
                            className={ styles.BtnIcon }
                            essence={ record.table_name }
                            buttonType={ 'icon-mini' }
                            sys_id={ record.sys_id }
                            svg={ Info }
                        /> }
                    { this.renderLink(record) }
                </div>
                <div className={ styles.descriptionItems }>
                    { this.getRecordDescription(record) }
                </div>
                <div className={ styles.SubItems }>
                    { this.recordSubInfo(record) }
                </div>
            </div>
        );
    }

    renderLink(record: Record) {
        const title = record.title.replace(/<srch_plh>|<\/srch_plh>/g, '');
        const replacer = (match, text) => {
            return `<span class='${
                styles.matching
            }'>${ text }</span>`;
        };

        return (
            <Link to={ this.getRecordUrl(record) }
                  title={ title }
                  className={ styles.Link }
                  dangerouslySetInnerHTML={ { __html: getParseText(record.title, replacer) } }
                  data-test={ ATTRIBUTES.searchRecordLink }
            />
        );
    }

    renderFooter() {
        if (!this.data.length) return;
        const { search_widget_titles } = langStore.getTranslate();

        const lastPost = <div
            className={ styles.FooterEndText }
            data-test={ ATTRIBUTES.searchNoResults }
        >
            { search_widget_titles?.no_more_results }
        </div>;

        const moreBtn = this.isFetchingMore ?
            (
                <div className={ styles.MoreLoader } />
            ) :
            (
                <Button
                    onClick={ this.handleLoadMore }
                    data-test={ ATTRIBUTES.searchLoadMore }
                >
                    { search_widget_titles?.load_more }
                </Button>
            );

        return (
            <div className={ styles.Footer }>
                { this.isLastPost ? lastPost : moreBtn }
            </div>
        );
    }

    renderResults() {
        if (this.isFetchCategory) {
            return <PageLoader />;
        }

        if (!this.data.length)
            return this.renderEmptyRequest();

        return this.displayedData.map((record, index) => {
            const title = (
                <div
                    className={ `${ styles.title } ${ this.currentCategory ? styles.OneTitle : '' }` }
                    data-test={ ATTRIBUTES.searchRecordTitle }
                >
                    { record.ts_group_title }
                    <span className={ styles.split }>/</span>
                    <Link
                        className={ styles.resultsBtn }
                        to={ this.getListUrl(record) }
                    >
                        { record.ts_table_title }
                    </Link>
                </div>
            );

            const isChosenCategory = this.currentCategory && index;

            return (
                <div
                    key={ record.sys_id }
                    className={ styles.item }
                    data-test={ ATTRIBUTES.searchRecord }
                >
                    { !!isChosenCategory || title }
                    { this.renderRecord(record) }
                </div>
            );
        });
    }

    renderEmptyRequest() {
        const { search_widget_titles } = langStore.getTranslate();

        const message = this.isCategoryEmpty ?
            search_widget_titles?.no_results_change_category :
            search_widget_titles?.nothing_found_change_query;

        return (
            <div
                className={ styles.EmptyMessage }
                data-test={ ATTRIBUTES.searchNotFound }
            >
                { message }
            </div>
        );
    }

    render() {
        const { search_titles } = langStore.getTranslate();

        if (this.isFetching) return <PageLoader />;

        return (
            <Container>
                <SmartTitle hidden>
                    { search_titles?.page_title }
                </SmartTitle>
                <div className={ styles.searchMain }>
                    <div className={ styles.resultsMain }>
                        { this.renderResults() }
                        { this.renderFooter() }
                    </div>
                    { this.renderMenu() }
                </div>
                <QuickButtonReturn
                    className={ isMedia('sm') ? '' : styles.QuickButtonOffset }
                />
            </Container>
        );
    }
}

export const SearchPageComponent = SearchPage;
export default ErrorWrapperHoc(observer(SearchPage));
