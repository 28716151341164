/**
 *
 * @param containers
 * @returns {*}
 */
export function containerSort(containers) {
    return containers.sort((containerA, containerB) => {
        const isHeaderA = containerA.isHeader || containerA.is_header;
        const isFooterA = containerA.isFooter || containerA.is_footer;
        const isHeaderB = containerB.isHeader || containerB.is_header;
        const isFooterB = containerB.isFooter || containerB.is_footer;
        const a = isHeaderA ? 1 : (isFooterA ? 3 : 2);
        const b = isHeaderB ? 1 : (isFooterB ? 3 : 2);
        return a - b;
    });
}
