import { observer } from 'mobx-react';
import * as React from 'react';
import stylesTabs from '../styles.module.scss';
import { CommentTabProps } from 'types/components/activityFeed';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { getClassNameAF } from 'helpers/activityFeed';

class CommentTab extends React.Component<CommentTabProps> {
    render() {
        const {
            isActiveTab, columnName, tab, onChangeActiveTab, isRequired, innerRef,
            isNotStylized, classes,
        } = this.props;

        const isRequiredReact = isRequired ? <span
            className={ getClassNameAF(isNotStylized, stylesTabs.CommentTabIsRequired, classes?.CommentTabIsRequired) }
        >*</span> : '';
        return (
            <div
                onClick={ onChangeActiveTab }
                className={ `${ getClassNameAF(isNotStylized, stylesTabs.CommentTabHeadItem, classes?.CommentTabHeadItem) } ${ isActiveTab ? getClassNameAF(isNotStylized, stylesTabs.CommentTabHeadItemActive, classes?.CommentTabHeadItemActive) : '' } ${ isRequired ? getClassNameAF(isNotStylized, stylesTabs.CommentTabIsRequired, classes?.CommentTabIsRequired) : '' }` }
                data-test={ ATTRIBUTES.activityFeedCommentTab }
                data-test-af-tab-name={ columnName }
                ref={ innerRef }
            >
                { isRequiredReact } { tab.name }
            </div>
        );
    }
}

const ObserverComponent = observer(CommentTab);
export default React.forwardRef((props: CommentTabProps, ref?: React.Ref<HTMLDivElement> | null) => <ObserverComponent innerRef={ ref } { ...props } />);
