/**
 * Описание: Панель отображения текущего статуса рабочего процесса
 * Параметры:
 * state - состояние процесса
 * started_at - дата старта
 * ended_at - дата окончания
 * */
import * as React from 'react';
import langStore from "globalState/lang";
import styles from './styles.module.scss';
import { getLocalTime } from 'components/workflow/helpers/datetime';
import { shortDateTimeToString } from 'helpers/date';

export default class StatePanel extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidCatch(error, info) {
        console.error(error, info);
    }

    render() {
        const { workflow_titles } = langStore.getTranslate();

        let started_at, ended_at;
        if (this.props.started_at){
            const date = new Date(getLocalTime(this.props.started_at));
            started_at = shortDateTimeToString(date);
        }
        if (this.props.ended_at){
            const date = new Date(getLocalTime(this.props.ended_at));
            ended_at = shortDateTimeToString(date);
        }
        return (
            <div className={ styles.StatePanel }>
                {
                    this.props.state && <div className={ styles.ListItem } >
                        { `${ workflow_titles.state }: ${ this.props.state }` }
                    </div>
                }
                {
                    !!started_at && <div className={ styles.ListItem } >
                        { `${ workflow_titles.started_at }: ${ started_at }` }
                    </div>
                }
                {
                    !!ended_at && <div className={ styles.ListItem } >
                        { `${ workflow_titles.ended_at }: ${ ended_at }` }
                    </div>
                }
            </div>
        );
    }
}
