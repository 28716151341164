
import * as React from 'react';
import { CategoriesOpenType, CategoryLinkPropsType, MenuItemType } from 'types/globalState/menu';
import styles from 'layouts/main/sidebar/menu/styles.module.scss';
import IconArrow from 'assets/img/icons/chevron-right.svg';
import IconCircleRight from 'assets/img/icons/circle-right.svg';
import sidebarState from 'globalState/sidebarState';
import Label from 'layouts/main/sidebar/label';
import ItemIcon from 'layouts/main/sidebar/menu/ItemIcon';
import { helperRedirectReplace } from 'helpers/history';
import * as _ from 'lodash';
import { observer } from 'mobx-react';
import { ATTRIBUTES } from 'constants/attributesForTests';

function CategoryLink({ item, filterValue }: CategoryLinkPropsType) {
    const handleClick = (item: MenuItemType): React.MouseEventHandler<HTMLDivElement> => (evt: React.MouseEvent<HTMLDivElement>) => {
        item.setIsActive(true);
        _.forEach(item.getItems(), sItem => sItem.setIsActive(false));

        if (sidebarState.getIsOpenDynamic()) {
            sidebarState.setMenuId(item.getSysId());
            window.localStorage.setItem('activeDynamicMenuId', '');
            sidebarState.prepareItems(sidebarState.getIsOpenDynamic() ? sidebarState.getDynamicMenuItems() : sidebarState.getMenuItems());
            helperRedirectReplace(item.getUrl());
            return;
        }

        handleClickRow(item, true)(evt);
    };

    const handleClickRow = (item: MenuItemType, isClick: boolean): React.MouseEventHandler<HTMLDivElement> => (evt: React.MouseEvent<HTMLDivElement>) => {
        if (!isClick) {
            return;
        }
        evt.preventDefault();
        evt.stopPropagation();
        if (filterValue) {
            const parent = (evt.target as any).closest('.' + styles.item);
            parent.classList.toggle(styles.active);
            return;
        }
        const sidebarCategoriesOpen: CategoriesOpenType[] = sidebarState.getIsOpenDynamic()
            ? sidebarState.getDynamicActiveMenuItems() : sidebarState.getActiveMenuItems();
        let newCategories: CategoriesOpenType[] = [];
        const findCategory = _.find(sidebarCategoriesOpen, cat => cat.sysId === item.getSysId());
        if (findCategory) {
            newCategories = _.filter(sidebarCategoriesOpen, cat => cat.sysId !== item.getSysId());
            newCategories.push({
                sysId: item.getSysId(),
                isOpen: !findCategory.isOpen,
            });
        } else {
            newCategories = sidebarCategoriesOpen;
            newCategories.push({
                sysId: item.getSysId(),
                isOpen: !item.getIsDefaultOpened(),
            });
        }
        if (sidebarState.getIsOpenDynamic()) {
            window.localStorage.setItem('dynamicCategoriesOpen', JSON.stringify(newCategories));
            sidebarState.setDynamicActiveMenuItems(newCategories);
        } else {
            window.localStorage.setItem('categoriesOpen', JSON.stringify(newCategories));
            sidebarState.setActiveMenuItems(newCategories);
        }

        sidebarState.prepareItems(sidebarState.getIsOpenDynamic() ? sidebarState.getDynamicMenuItems() : sidebarState.getMenuItems());
    };

    const handleDynamicLinkClick = (item: MenuItemType): React.MouseEventHandler<HTMLDivElement> => () => {
        window.localStorage.setItem('openDynamicMenuId', item.getSysId());
        sidebarState.setIsOpenDynamic(true);
        sidebarState.setDynamicTitle(item.getLabel());
        sidebarState.preventCloseNav = true;
        sidebarState.fetchDynamicMenuList(item.getSysId());
    };

    if (item.getDynamic()) {
        return (
            <div
                onClick={ handleDynamicLinkClick(item) }
                className={ styles.link }
                key={ `Item${ item.getSysId() }` }
                data-test={ ATTRIBUTES.sidebarCategoryDynamicLink }
            >
                <div className={ styles.linkContainer }>
                    <div
                        className={ styles.arrowContainer }
                        data-test={ ATTRIBUTES.sidebarArrowContainer }
                    >
                        <span dangerouslySetInnerHTML={ { __html: IconCircleRight } } className={ styles.icon } />
                    </div>
                    <div className={ styles.labelContainer }>
                        <Label data-test={ ATTRIBUTES.sidebarCategoryLinkLabel }>
                            { item.getLabel() }
                        </Label>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div
            className={ styles.link }
            onClick={ handleClick(item) }
            key={ `Item${ item.getSysId() }` }
            data-test={ ATTRIBUTES.sidebarCategoryLink }
        >
            <div className={ styles.linkContainer }>
                <div
                    className={ styles.arrowContainer }
                    onClick={ handleClickRow(item, sidebarState.getIsOpenDynamic()) }
                    data-test={ ATTRIBUTES.sidebarArrowContainer }
                >
                    <div
                        className={ `${ styles.arrow } ${ sidebarState.getIsOpenDynamic() ? styles.dynamicArrow : '' }` }
                        dangerouslySetInnerHTML={ { __html: IconArrow } }
                    />
                </div>
                <div className={ styles.labelContainer }>
                    <Label
                        hasIcon={ item.getIcon() || item.getIsCategory() }
                        data-test={ ATTRIBUTES.sidebarCategoryLinkLabel }
                    >
                        { item.getLabel() }
                    </Label>
                    <ItemIcon item={ item } className={ styles.mainLinkIcon } />
                </div>
            </div>
        </div>
    );
}

export default observer(CategoryLink);