import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import GlobalPortal from 'components/globalPortal';
import ModalWrapper from 'components/modalWrapper';
import Button from 'components/button';
import { beforeUnload } from 'helpers/form';
import { isMedia } from 'helpers/html';
import IconClose from 'assets/img/icons/close-x.svg';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { PopupProps } from 'types/components/popup';

class Popup extends React.Component<PopupProps> {
    initialStyles = {
        top: 0,
        left: 0,
        visibility: 'hidden',
    };
    windowScrollTop = 0;
    nodeRef: React.RefObject<HTMLDivElement> = React.createRef();
    popupRef: React.RefObject<HTMLDivElement> = React.createRef();
    triangleRef: React.RefObject<HTMLDivElement> = React.createRef();
    popupTitleRef: React.RefObject<HTMLDivElement> = React.createRef();
    popupContentRef: React.RefObject<HTMLDivElement> = React.createRef();

    componentDidMount() {
        if (this.props.freezeScreen) {
            this.windowScrollTop = window.pageYOffset;
            this.initContentWheel();
        }
        document.addEventListener('click', this.doClose);
        window.addEventListener('scroll', this.onWindowScroll, { passive: false });
        window.addEventListener('wheel', this.onWindowWheel, { passive: false });
    }

    componentDidUpdate() {
        this.updatePopupPosition();
    }

    componentWillUnmount = () => {
        document.removeEventListener('click', this.doClose);
        window.removeEventListener('scroll', this.onWindowScroll);
        window.removeEventListener('wheel', this.onWindowWheel);
    };

    onWindowWheel = (e) => {
        if (!this.props.freezeScreen) return;

        const popupContent = this.popupContentRef.current;
        if (!popupContent) return;

        if (!popupContent.contains(e.target) || popupContent.scrollHeight === popupContent.clientHeight) {
            e.preventDefault();
        }
    };

    initContentWheel = () => {
        const popupContent = this.popupContentRef.current;
        if (!popupContent) return;

        let i = 0;
        popupContent.onwheel = function (e) {
            const max = (this as HTMLDivElement).scrollHeight;
            const min = 0;

            if (e.deltaY > 0) {
                i += 100;
                if (i > max) i = max;
            }

            if (e.deltaY < 0) {
                i -= 100;
                if (i < min) i = min;
            }

            if (i >= max || i <= min) return false;
        };
    };

    handleLinkClick = (evt) => {
        const unload = beforeUnload({});
        if (unload && !confirm(unload)) {
            evt.preventDefault();
            return;
        }
    };

    cutText = (text: string, maxLength = 70): string => {
        const stringText = text.toString();
        if (stringText.length > maxLength) {
            return stringText.slice(0, maxLength) + '...';
        }
        return stringText;
    };

    onWindowScroll = (e) => {
        const { isShow, freezeScreen, onToggle } = this.props;
        if (!isShow) return;

        if (freezeScreen) {
            e.preventDefault();
            window.scrollTo(0, this.windowScrollTop);
        } else {
            onToggle && onToggle(e);
        }
    };

    updatePopupPosition = () => {
        if (isMedia('sm')) return;

        let node: HTMLDivElement | HTMLElement | null = this.nodeRef.current;
        let popup = this.popupRef.current;
        let triangle = this.triangleRef.current;

        if (this.props.parent) {
            node = this.props.parent;
        }

        if (!node || !popup || !triangle) return;

        const offset = 10;
        const popupRect = popup.getBoundingClientRect();
        const nodeRect = node.getBoundingClientRect();
        const triangleRect = triangle.getBoundingClientRect();
        const windowHeight = document.documentElement.clientHeight;
        const windowWidth = document.documentElement.clientWidth;

        // рассчитываем top для попапа
        if (popupRect.height + offset > windowHeight) { // если высота попапа больше высоты окна
            let popupContent = this.popupContentRef.current;
            let popupTitle = this.popupTitleRef.current;

            const titleHeight = popupTitle ? popupTitle.getBoundingClientRect().height : 0;
            popup.style.top = `${ window.scrollY + offset / 2 }px`;
            if (popupContent) {
                popupContent.style.height = `${ windowHeight - offset - titleHeight }px`;
            }
        } else {
            let top = window.scrollY + nodeRect.top + nodeRect.height / 2 - popupRect.height / 2;
            if (top - window.scrollY < 0) { // если попап вылезает за верх окна
                top = window.scrollY + offset / 2;
            } else if (top + popupRect.height - window.scrollY > windowHeight) { // если попап вылезает за низ окна
                top = top - (top + popupRect.height - window.scrollY - windowHeight) - offset / 2;
            }
            // проставляем top для попапа
            popup.style.top = `${ top }px`;
        }
        // расчитываем и проставляем top для уголка
        triangle.style.top = `${ window.scrollY + nodeRect.top + nodeRect.height / 2 - triangleRect.height / 2 }px`;

        // рассчитываем left для попапа и уголка
        let left = nodeRect.left + nodeRect.width + triangleRect.width;
        let leftTriangle = nodeRect.left + nodeRect.width;
        if (left + popupRect.width > windowWidth) { // если попап вылезает за правый край окна
            if (nodeRect.left > popupRect.width) { // если попап помещается слева от иконки
                left = nodeRect.left - (popupRect.width + triangleRect.width);
                leftTriangle = nodeRect.left - (triangleRect.width);
                triangle.style.transform = 'rotateY(180deg) translate(1px, 0)';

                setTimeout(() => { // используем небольшой таймаут чтобы проверить есть ли у попапа скроллбар и соответственно поправить left
                    if (!popup || !triangle) return;

                    const oldWidth = popupRect.width;
                    const newWidth = popup.getBoundingClientRect().width;
                    if (newWidth > oldWidth) { // если есть скроллбар - корректируем left
                        left = left - (newWidth - oldWidth);
                    }

                    // проставляем left для попапа и уголка
                    popup.style.left = `${ left }px`;
                    triangle.style.left = `${ leftTriangle }px`;

                    // отображаем попап и уголок
                    popup.style.visibility = 'visible';
                    triangle.style.visibility = 'visible';
                    popup.classList.add(styles.animated);
                    triangle.classList.add(styles.animated);
                }, 100);
            } else { // если попап не помещается слева целиком - сдвигаем его влево так, чтобы он не залезал за правый край окна
                left = left - (left + popupRect.width - windowWidth) - offset / 2;

                // проставляем left для попапа и уголка
                popup.style.left = `${ left }px`;
                triangle.style.left = `${ leftTriangle }px`;

                // отображаем попап, уголок остаётся скрытым
                popup.style.visibility = 'visible';
                triangle.style.visibility = 'hidden';
                popup.classList.add(styles.animated);
            }
        } else {
            // проставляем left для попапа и уголка
            popup.style.left = `${ left }px`;
            triangle.style.left = `${ leftTriangle }px`;

            // отображаем попап и уголок
            popup.style.visibility = 'visible';
            triangle.style.visibility = 'visible';
            popup.classList.add(styles.animated);
            triangle.classList.add(styles.animated);
        }
    };

    doClose = (e) => {
        const { isShow, onToggle, parent } = this.props;

        if (!isShow) return;

        let popup = this.popupRef.current;

        if (!document.body.contains(e.target)
            || parent?.contains(e.target)
            || popup?.contains(e.target)) {
            return;
        }

        onToggle && onToggle(e);
    };

    renderPopup = () => {
        const { isShow, popupClassName, title, button, onToggle, children } = this.props;
        if (!isShow) return null;

        const classNames = [ styles.Popup ];
        if (popupClassName) {
            classNames.push(popupClassName);
        }

        return (
            <>
                <div
                    className={classNames.join(' ')}
                    ref={ this.popupRef }
                    style={ this.initialStyles }
                    data-test={ ATTRIBUTES.popup }
                >
                    {
                        title && (
                            <div
                                className={ styles.PopupTitle }
                                ref={ this.popupTitleRef }
                            >
                                <span
                                    className={ styles.PopupText }
                                    data-test={ ATTRIBUTES.popupText }
                                >
                                    { this.cutText(this.props.title) }
                                </span>
                                {
                                    (button?.title && button?.link)
                                    && <Link
                                        className={ styles.PopupLink }
                                        to={ button.link }
                                        onClick={ this.handleLinkClick }
                                        data-test={ ATTRIBUTES.previewRecordButton }
                                    >
                                        <Button buttonSize='sm'>{ button.title }</Button>
                                    </Link>
                                }
                                <Button
                                    className={ styles.PopupMobClose }
                                    buttonType={'icon'}
                                    svg={ IconClose }
                                    onClick={ (e) => { onToggle && onToggle(e) }}
                                />
                            </div>
                        )
                    }

                    <div className={ styles.PopupContent } ref={ this.popupContentRef }>
                        { children || <div className={ styles.preloader } /> }
                    </div>

                    {
                        (button?.title && button?.link)
                        && <Link
                            className={ styles.PopupMobLink }
                            to={ button.link }
                            onClick={ this.handleLinkClick }
                            data-test={ ATTRIBUTES.previewRecordButton }
                        >
                            <Button buttonType={'primary'}>{ button.title }</Button>
                        </Link>
                    }
                </div>
                <div ref={ this.triangleRef } className={ styles.PopupTriangle } style={ this.initialStyles } />
            </>
        );
    };

    render() {
        const { parent, nodeClassName } = this.props;

        const popup = (
            isMedia('sm') ?
            <ModalWrapper>
                { this.renderPopup() }
            </ModalWrapper> :
            <GlobalPortal>
                { this.renderPopup() }
            </GlobalPortal>
        );

        if (parent) {
            return popup;
        }

        return (
            <div ref={ this.nodeRef } className={ nodeClassName ? nodeClassName : '' }>
                { popup }
            </div>
        );
    }
}

export default observer(Popup);
