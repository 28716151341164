import apiRequest from 'lib/apiRequest';
import { handleResponse } from 'helpers/action';
import { HandleResponse } from 'types/actions/main';
import { IPortalMenuPrams } from 'types/actions/widgets';

/**
 * Получение списка элементов для бокового меню
 */
export const fetchWidgetMenuList = async (params: IPortalMenuPrams): Promise<any> => {
    const response = await new apiRequest('GET /portal/get-menu').qs(params).send();
    return response.getData();
};
/**
 * Получение списка элементов для хлебных крошек
 */
export const fetchBreadcrumbs = async (params): Promise<any> => {
    const response = await new apiRequest('GET /portal/get-breadcrumbs').qs(params).send();
    if (response.getData()) {
        return response.getData().breadcrumbs;
    }
    return [];
};

export const fetchListAData = async (params): Promise<HandleResponse> => {
    return handleResponse(await new apiRequest(`GET /portal/list-a`).qs(params).send());
};
