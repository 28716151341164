import * as React from 'react';
import _ from 'lodash';

import IconArrow from 'assets/img/icons/chevron-right.svg';
import styles from './styles.module.scss';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import BreadcrumbsState from 'globalState/widgets/breadcrumbsState';
import { withRouter } from 'react-router-dom';
import { ATTRIBUTES } from 'constants/attributesForTests';

/**
 * Виджет "Хлебные крошки"
 * **/
class BreadCrumbs extends React.Component {
    state;

    constructor(props) {
        super(props);

        makeObservable(this, {
            state: observable,
        });

        this.state = new BreadcrumbsState(props._location || props.location);
    }

    componentDidMount() {
        this.state.onNavigate();
    }

    componentWillUnmount() {
        this.state.clearState();
    }

    renderBreadcrumb = (element) => {
        return (
            <div className={ styles.item } key={ element.url }>
                <span className={ styles.itemText } title={ element.title_or_id } onClick={ this.state.beforeElementChange(element) }>
                    { element.title_or_id }
                </span>
                <span className={ styles.arrow } dangerouslySetInnerHTML={ { __html: IconArrow } } />
            </div>
        );
    };

    render() {
        const data = _.cloneDeep(this.state.data);
        if (!data || !Array.isArray(data) || data.length === 0) return null;

        const lastElement = data.pop();
        const { className } = this.props;
        return (
            <div
                className={ `${ styles.breadcrumb } ${ className }` }
                data-test={ this.props['data-test'] ? this.props['data-test'] : `breadcrumbs-${ ATTRIBUTES.widget }` }
            >
                { data.map(this.renderBreadcrumb) }
                <div className={ styles.item } key={ lastElement.url }>
                    <span className={ styles.itemText }  title={ lastElement?.title_or_id }>
                        { lastElement && lastElement.title_or_id }
                    </span>
                </div>
            </div>
        );
    }
}

export default withRouter(observer(BreadCrumbs));
