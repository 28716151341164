import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import _ from 'lodash';
import styles from './styles.module.scss';
import Button from 'components/button';
import ContextMenu from 'components/contextMenu';
import BreadcrumbsLink from 'components/filter/breadcrumbsLink';
import sidebarState from 'globalState/sidebarState';
import langStore from 'globalState/lang';
import { copyToClipboard, getUrlParams } from 'helpers/data';
import { getConditionString } from 'helpers/condition';
import { helperRedirect } from 'helpers/history';
import { saveFavorite } from 'actions/menu';
import Filter from 'assets/img/icons/filter.svg';
import ChevronRight from 'assets/img/icons/chevron-right.svg';
import { ATTRIBUTES } from 'constants/attributesForTests';
import {BreadcrumbsProps, DraggedItem, RowType} from 'types/components/filter/breadcrumbs';

class Breadcrumbs extends React.Component<BreadcrumbsProps> {
    itemSelectForRemove = '';
    dragged: DraggedItem | null = null;
    isShowContextMenu = false;
    forCopy = '';
    menuCoordinates = {
        x: 0,
        y: 0,
    };

    constructor(props) {
        super(props);

        makeObservable(this, {
            itemSelectForRemove: observable,
            dragged: observable,
            isShowContextMenu: observable,
            menuCoordinates: observable,
            forCopy: observable,
        });

        document.addEventListener('dragover', this.onDragOver);
        document.addEventListener('drop', this.onDrop);
        document.addEventListener('dragend', this.onDragEnd);
    }

    componentWillUnmount() {
        document.removeEventListener('dragover ', this.onDragOver);
        document.removeEventListener('drop ', this.onDrop);
        document.removeEventListener('dragend ', this.onDragEnd);
    }

    handleForRemove = (id) => () => {
        this.itemSelectForRemove = id;
    };

    handleLeaveForRemove = () => {
        this.itemSelectForRemove = '';
    };

    handleRemove = (id) => () => {
        this.props.onRemoveCondition(id);
    };

    handleClickAll = () => {
        const { onClickAll, conditionState } = this.props;
        conditionState.clearData();
        if (onClickAll) {
            onClickAll();
        }
    };

    onClick = () => {
        const { onClickHandler } = this.props;
        if (onClickHandler) {
            onClickHandler();
        }
    };

    onDragStart = (item: DraggedItem) => () => {
        return this.dragged = item;
    };

    onDragEnd = () => {
        this.dragged = null;
    };

    onDragOver = (e) => {
        e.preventDefault();
    };

    getUrl = (isHost = true, id = '') => {
        const { location, conditionState } = this.props;
        const url = new URL(location.pathname, window.location.origin);
        if (id !== 'All') {
            let conditionString = conditionState.getConditionString();
            if (this.forCopy) {
                const filterFields = conditionState.getFilterFields().filter(row => row.getId() === this.forCopy);
                const sortingFields = conditionState.getSortingFields().filter(row => row.getId() === this.forCopy);
                const groupingFields = conditionState.getGroupingFields().filter(row => row.getId() === this.forCopy);
                conditionString = getConditionString(filterFields, sortingFields, groupingFields);
            }
            url.searchParams.set('condition', conditionString);
        }
        return (isHost ? url.hostname : '') + url.pathname + url.search;
    };

    onDrop = async (e) => {
        const { conditionState } = this.props;
        if (this.isFavoriteDropArea(e.target) && this.dragged) {
            const label = this.getLabel([
                ...conditionState.getFilterFields(),
                ...conditionState.getSortingFields(),
                ...conditionState.getGroupingFields(),
            ]);

            const response = await saveFavorite({
                'label': label,
                'url': this.getUrl(false, this.dragged.getId ? this.dragged.getId() : this.dragged.id),
            });
            if (response.isOkStatus) {
                sidebarState.fetchFavoritesList(false).catch(console.error);
            }
        }
    };

    getLabel = (item, delimeter = ' ', parentText = '') => {
        const { filter_titles } = langStore.getTranslate();
        const { tableName } = this.props;
        let mainText = parentText ? '' : tableName + ': All';
        const draggedID = this.dragged?.getId ? this.dragged.getId() : this.dragged?.id;
        if (draggedID === 'All') {
            return mainText;
        }
        if (!item || (Array.isArray(item) && item.length === 0)) {
            return '';
        }
        if (Array.isArray(item)) {
            item.forEach(it => {
                mainText += this.getLabel(it, ' ', mainText);
            });
            return mainText;
        }
        if (item.getId() === 'All') {
            return mainText;
        }
        if (item.getId().includes('sorting')) {
            return mainText + ' > ' + item.getField().dot_walking_attribute + delimeter + 'order by ' + (item.getDirection().value || item.getDirection());
        }

        if (item.getId().includes('grouping')) {
            return mainText + ' > ' + filter_titles.group + delimeter + item.getField().dot_walking_attribute;
        }
        if (Array.isArray(item.getValue())) {
            const arrValue = _.map(item.getValue(), val => {
                const nullValue = val.display_value === null ? 'null' : val.display_value;
                return nullValue || val;
            });
            return mainText + ' > ' + (item.getField().column_title || item.getField().dot_walking_attribute) + delimeter +
                (item.getOperator().database_value || item.getOperator()) + delimeter + arrValue;
        }
        return mainText + ' > ' + (item.getField().column_title || item.getField().dot_walking_attribute) + delimeter +
            (item.getOperator().database_value || item.getOperator()) + delimeter +
            (item.getValue().display_value || item.getValue());
    };

    isFavoriteDropArea = (node) => {
        while (node && node.nodeType === document.ELEMENT_NODE) {
            if (node.classList.contains('favoriteDropArea')) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    };

    isFiltering = (item: RowType) => {
        return item.getId() && item.getId().includes('filter');
    };

    isSorting = (item: RowType) => {
        return item.getId() && item.getId().includes('sorting');
    };

    isGrouping = (item: RowType) => {
        return item.getId() && item.getId().includes('grouping');
    };

    handleToggleContextMenu = () => {
        if (this.isShowContextMenu) {
            this.forCopy = '';
        }
        this.isShowContextMenu = !this.isShowContextMenu;
    };

    handleContextMenu = (link: string) => (e) => {
        e.preventDefault();
        this.forCopy = link;
        this.menuCoordinates.x = e.pageX;
        this.menuCoordinates.y = e.pageY;
        this.handleToggleContextMenu();
    };

    handleGoTo = (id: string, isDisabled: boolean) => (e) => {
        const { location }= this.props;
        const params = getUrlParams(location.search);
        e.preventDefault();
        if (isDisabled || params.is_fixed) {
            return;
        }
        this.forCopy = id;
        helperRedirect(this.getUrl(false));
    };

    renderContextMenu = () => {
        if (!this.isShowContextMenu) {
            return null;
        }

        const menu = [
            {
                name: 'Copy',
                reactScript: copyToClipboard,
            },
        ];
        return (
            <ContextMenu
                items={ menu }
                x={ this.menuCoordinates.x }
                y={ this.menuCoordinates.y }
                isShowContextMenu={ this.isShowContextMenu }
                onToggleContextMenu={ this.handleToggleContextMenu }
                addParams={ this.getUrl(true, this.forCopy) }
            />
        );
    };

    renderNotEditCondition = () => {
        const { notEditCondition } = this.props;
        if (!notEditCondition) {
            return null;
        }
        return (
            <React.Fragment key={ 'notEdit' }>
                <li draggable="true" onDragStart={ this.onDragStart({id: 'notEdit'}) }>
                    <div
                        className={ styles.BreadcrumbsLinkDisabled }
                        onClick={ this.handleClickAll }
                        data-test={ ATTRIBUTES.conditionFilterAllBreadcrumb }
                    >
                        <span title={ notEditCondition }>{ notEditCondition }</span>
                    </div>
                </li>
            </React.Fragment>
        );
    };

    renderChevronItem = (item: RowType) => {
        if (this.props.readOnly || item.getField().disabled || item.getDisabled()) {
            return (
                <div className={ styles.BreadcrumbsChevronDisabled }
                     dangerouslySetInnerHTML={ { __html: ChevronRight } }
                />
            );
        }
        return (
            <div
                className={ styles.BreadcrumbsChevron }
                onMouseEnter={ this.handleForRemove(item.getId()) }
                onMouseLeave={ this.handleLeaveForRemove }
                onClick={ this.handleRemove(item.getId()) }
                dangerouslySetInnerHTML={ { __html: ChevronRight } }
            />
        );
    };

    renderFilteringItems = () => {
        return this.props.filterFields.map((item) => ( this.renderItem(item)));
    };

    renderSortingItems = () => {
        return this.props.sortingFields.map((item) => ( this.renderItem(item)));
    };

    renderGroupingItems = () => {
        return this.props.groupingFields.map((item) => ( this.renderItem(item)));
    };

    renderItem = (item: RowType) => {
        if (!item.getField()) {
            return null;
        }
        const { location, data, readOnly }= this.props;
        const params = getUrlParams(location.search);
        const linkClassname = styles.BreadcrumbsLink + (item.getId() === this.itemSelectForRemove ? ` ${ styles.ForRemove }` : '');
        let dataTest;
        if (this.isFiltering(item)){
            dataTest = ATTRIBUTES.conditionFilterFilteringBreadcrumb;
        }
        if (this.isSorting(item)){
            dataTest = ATTRIBUTES.conditionFilterSortingBreadcrumb;
        }
        if (this.isGrouping(item)){
            dataTest = ATTRIBUTES.conditionFilterGroupingBreadcrumb;
        }
        return (
            <React.Fragment key={ item.getId() }>
                <li>
                    { this.renderChevronItem(item) }
                </li>
                <li
                    draggable="true"
                    onDragStart={ this.onDragStart(item) }
                    onContextMenu={ this.handleContextMenu(item.getId()) }
                    onClick={ this.handleGoTo(item.getId(), item.getDisabled()) }
                >
                    <div
                        className={ readOnly || item.getDisabled() || params.is_fixed
                            ? styles.BreadcrumbsLinkDisabled : linkClassname }
                        data-test={ dataTest }
                    >
                        <BreadcrumbsLink
                            item={ item }
                            delimeter=" "
                            data={ data }
                        />
                    </div>
                </li>
            </React.Fragment>
        );
    };

    renderAll = () => {
        const { location, notEditCondition, readOnly } = this.props;
        const { filter_titles } = langStore.getTranslate();
        if (notEditCondition) {
            return null;
        }
        const params = getUrlParams(location?.search);
        const all = readOnly || params.is_fixed
            ? (
                <div
                    className={ styles.BreadcrumbsOperatorDisabled }
                    data-test={ ATTRIBUTES.conditionFilterAllBreadcrumb }
                >
                    { filter_titles?.main_breadcrumb }
                </div>
            )
            : (
                <div
                    className={ styles.BreadcrumbsLink }
                    onClick={ this.handleClickAll }
                    data-test={ ATTRIBUTES.conditionFilterAllBreadcrumb }
                    onContextMenu={ this.handleContextMenu('All') }
                >
                    { filter_titles && filter_titles.main_breadcrumb }
                </div>
            );
        return (
            <li draggable="true" onDragStart={ this.onDragStart({ id: 'All' }) }>
                { all }
            </li>
        );
    };

    render() {
        const { system_buttons_hints } = langStore.getTranslate();
        return (
            <div className={ styles.Breadcrumbs }>
                <Button
                    hint={ system_buttons_hints?.condition_builder }
                    className={ styles.Filter }
                    buttonType='icon'
                    onClick={ this.onClick }
                    key="button"
                    svg={ Filter }
                    data-test={ ATTRIBUTES.conditionFilterIconButton }
                />

                <ul className={ styles.BreadcrumbsList } key="list">
                    { this.renderAll() }
                    { this.renderNotEditCondition() }
                    { this.renderFilteringItems() }
                    { this.renderSortingItems() }
                    { this.renderGroupingItems() }
                </ul>
                { this.renderContextMenu() }
            </div>
        );
    }
}

export default observer(Breadcrumbs);
