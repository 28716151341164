import apiRequest from 'lib/apiRequest';
import * as _ from 'lodash';
import {handleResponse} from "helpers/action";
import {
    ResponseI18nMessage,
    ResponseI18nChoice,
    ResponseI18nDisplayValue,
    I18nChoiceParams,
    I18nValueParams,
} from "types/actions/i18n";
import {I18nMessagesParams} from 'types/clientAPI/simpleI18n';

/**
 * // Получение перевода слова или массива слов из messages
 * @param {Object} params
 * @param {string | string[]}  params.messages - значения, которые необходимо
 * @param {string | null} params.langCode - код языка
 */
export const fetchI18nMessages = async (params: I18nMessagesParams): Promise<ResponseI18nMessage> => {
    const response = await new apiRequest('POST /ajax-script/get-message-translation').sendJSON(params);
    return handleResponse(response);
};

/**
 * // Получение перевода слова из Choices
 * @param {string}  table_name
 * @param {string}  column_name
 * @param {string}  database_value
 */
export const fetchI18nChoice = async ({table_name, column_name, database_value}: I18nChoiceParams): Promise<ResponseI18nChoice> => {
    const response = await new apiRequest(`GET /ajax-script/get-choice-translation/${table_name}/${column_name}${database_value ? ('/' + database_value) : ''}`).send();
    return handleResponse(response);
};

/**
 * // Получение перевода по database_value
 * @param {Object}  params
 * @param {string}  params.table_name
 * @param {string}  params.column_name
 * @param {string}  params.database_value
 */
export const fetchI18nDisplayValue = async (params: I18nValueParams): Promise<ResponseI18nDisplayValue> => {
    const filteredParams = _.omitBy(params, _.isEmpty);
    const response = await new apiRequest(`GET /field/display-value`).qs(filteredParams).send();
    return handleResponse(response);
};
