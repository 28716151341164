import {HandleResponse} from "../actions/main";

export enum FILE_STATUS {
    LOADING = 'running',
    LOADED = 'completed',
    DOWNLOADED = 'downloaded',
    PENDING = 'pending',
    FAILED = 'failed'
}

export type FileType = 'json' | 'excel';

export interface CardData {
    sysId: string;
    objectName: string;
    title: string;
    type: FileType;
    sysCreatedAt?: string;
    sysUpdatedAt?: string;
    /* размер в bytes */
    objectSize?: string;
    state: FILE_STATUS;
    selected?: boolean;
}

export interface CardProps {
    card: CardData;
    handleRemoveCard: (card: CardData) => Promise<void>;
    handleClickCard: (card: CardData) => Promise<void>;
    isMoreThanOne?: boolean;
}

export interface ExportsParams {
    userId: string;
}
export interface ExportsResponse extends HandleResponse {
    data: {
        exports: CardData[];
        exportLimit: number;
    };
}

export interface CreateExportResponse extends HandleResponse {
    data: {
        export?: CardData;
        /*Флаг определяет, нажал ли пользователь на модалку для перезаписи*/
        exportLimitExceeded: boolean;
    };
}

export interface CreateExportParams {
    export: ExportParams;
    /* Флаг определяет, нажал ли пользователь на модалку для перезаписи */
    confirmExportLimitExceeded?: boolean;
    userId: string;
}

export interface ExportParams {
    tableName: string;
    condition?: string;
    columns: string[];
    type: FileType;
}

export interface ExportIDsParams {
    userId: string;
    sysIds: string[];
}

export interface DownloadUrlResponse extends HandleResponse {
    data: {
        downloadUrls: string[];
    };
}
