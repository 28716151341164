import * as React from 'react';
import Container from 'components/container';

import LayoutEditor from 'components/layoutEditor';
import SectionSelect from 'components/layoutSelect/sectionSelect';
import ViewSelect from 'components/layoutSelect/viewSelect';
import Button from 'components/button';
import apiRequest from 'lib/apiRequest';
import langStore from 'globalState/lang';

import styles from './styles.module.scss';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { helperRedirect } from 'helpers/history';
import SmartTitle from 'components/smartTitle';
import PageLoader from 'components/pageLoader';
import Heading from 'components/heading';
import ConfirmModal from 'components/modalWindow';
import ErrorWrapperHoc from 'helpers/hoc/errorWrapperHOC';
import { ATTRIBUTES } from 'constants/attributesForTests';
import _ from 'lodash';

class FormLayoutEdit extends React.Component {
    data;
    errorMessage = '';
    isSavingData = false;
    isSavingSectionsOrder = false;
    showModal = false;
    initialSection = [];
    lastRouteParams = '';
    selected = [];
    widgetOptions = {};
    initialSelected = [];
    sections = [];
    current = {};
    prevLocation = '';

    constructor(props) {
        super(props);

        makeObservable(this, {
            data: observable,
            errorMessage: observable,
            isSavingData: observable,
            isSavingSectionsOrder: observable,
            showModal: observable,
            initialSection: observable,
        });

        this.prevLocation = props.location.state;
    }

    componentDidMount() {
        this.fetchData()
            .then(() => this.updateSections(this.data.available_sections))
            .catch(this.errorFetchData);
    }

    getSelectedItems(parseItems) {
        let selected = [];

        for (let item of parseItems) {
            let id;
            if (item.isSplit) {
                id = item.splitType;
            }
            else {
                id = item.id;
            }
            selected.push(id);
        }
        return selected;
    }

    fetchData = async (forceUpdate = false) => {
        const routeParams = window.location.pathname + window.location.search;
        if ((routeParams.localeCompare(this.lastRouteParams) === 0) && !forceUpdate) return false;
        this.lastRouteParams = window.location.pathname + window.location.search;

        const response = await new apiRequest('GET ' + routeParams).send();
        if (response.status === 'OK'){
            this.data = response.getData();
            this.initialSelected = this.getSelectedItems(this.data.selected_items);

            this.widgetOptions = this.getWidgetOptions();
        }
    };

    getWidgetOptions() {
        if (!this.data['selected_items']) return {};
        const options = {};

        this.data['selected_items'].forEach((item) => {
            if (item.id && item.id.startsWith('widget')) {
                options[item.id] = item.widget_options ? item.widget_options : {};
            }
        });
        return options;
    }

    errorFetchData = (e) => {
        this.errorMessage = e.message;
    };

    updateSelected = (selected) => {
        this.selected = selected;
    };

    updateWidgetOption = (options) => {
        this.widgetOptions = options;
    };

    saveSelected = async () => {
        const data = {
            view: this.data.current_view,
            section: this.data.current_section,
            selected_ids: this.selected,
            widget_options: this.widgetOptions,
            sections_titles: this.sections,
        };
        this.isSavingData = true;

        const result = await new apiRequest('POST /form-layout/' + this.data.essence + '/update').sendJSON(data).catch(console.error);
        if (result) {
            helperRedirect(
                this.prevLocation !== undefined
                    ? `/record/${ this.data.essence }/${ this.prevLocation ? this.prevLocation : '' }`
                    : `/record/${ this.data.essence }`,
            );
        }
        this.isSavingData = false;
    };

    addView = async (view) => {
        const data = {
            'view': view,
        };

        let result = await new apiRequest('POST /form-layout/' + this.data.essence + '/create').sendJSON(data).catch(console.error);
        if (result) {
            const redirect = '/form-layout/' + this.data.essence + '?view=' + view;
            helperRedirect(redirect);
            this.fetchData(true)
                .then(() => this.updateSections(this.data.available_sections))
                .catch(this.errorFetchData);
        }
    };

    switchView = async (view) => {
        const isChanged = this.isChangedSelected('view', view);
        if (!isChanged) {
            const redirect = `/form-layout/${ this.data.essence }?view=${ view }`;
            helperRedirect(redirect);
            this.fetchData()
                .then(() => this.updateSections(this.data.available_sections))
                .catch(this.errorFetchData);
        }
    };

    updateSections = (sections) => {
        this.sections = sections.map(item => item.db_title);
        if (!_.isEqual(this.initialSection, sections)) {
            this.initialSection = sections;
        }
    };

    changeSectionsOrder = async (sections) => {
        this.isSavingSectionsOrder = true;

        const data = {
            view: this.data.current_view,
            section: this.data.current_section,
            only_sections: true,
            sections_titles: sections.map(item => item.db_title),
        };
        const result = await new apiRequest('POST /form-layout/' + this.data.essence + '/update').sendJSON(data).catch(console.error);

        if (result) {
            await this.updateSections(sections);
            this.isSavingSectionsOrder = false;
        }
    };

    addSection = async (section) => {
        const data = {
            'view': this.data.current_view,
            'section': section,
        };

        const response = await new apiRequest('POST /form-layout/' + this.data.essence + '/create-section').sendJSON(data).catch(console.error);

        if (response) {
            const redirect = '/form-layout/' + this.data.essence + '?view=' + this.data.current_view + '&section=' + section;
            helperRedirect(redirect);

            await this.fetchData(true);
            await this.updateSections(this.data.available_sections);
        }
    };

    removeSection = async ({ sys_id, db_title }) => {
        const data = {
            'sys_ids': [ sys_id ],
        };

        let result = await new apiRequest('POST /delete/sys_ui_form_section').sendJSON(data).catch(console.error);
        if (result) {

            if (this.data.current_section === db_title) {
                const redirect = '/form-layout/' + this.data.essence + '?view=' + this.data.current_view || '';
                helperRedirect(redirect);
            }
            this.fetchData(true)
                .then(() => this.updateSections(this.data.available_sections))
                .catch(this.errorFetchData);
        }
    };

    switchSection = async (section) => {
        const isChanged = this.isChangedSelected('section', section);
        if (!isChanged) {
            const redirect = `/form-layout/${ this.data.essence }?view=${ this.data.current_view }&section=${ section }`;
            helperRedirect(redirect);
            this.fetchData().catch(this.errorFetchData);
        }
    };

    handleShowModal = () => {
        this.showModal = !this.showModal;
    };

    isChangedSelected(type, value) {
        if (!_.isEqual(this.initialSelected, this.selected)) {
            this.current = {
                type,
                value,
            };

            this.showModal = true;
            return true;
        }
        return false;
    }

    renderConfirmModal() {
        const { modal_window_titles = {} } = langStore.getTranslate();

        let modalText = '';
        if (this.current.type === 'view') {
            modalText = modal_window_titles.change_view && modal_window_titles.change_view.replace(/{viewName}/gi, `<b>${this.data.current_view}</b>`).replaceAll('"','');
        }
        else if (this.current.type === 'section') {
            modalText = modal_window_titles.change_view && modal_window_titles.change_section.replace(/{sectionName}/gi, `<b>${this.data.current_section}</b>`).replaceAll('"','');
        }

        return (
            <div>
                <div className={ styles.Text } dangerouslySetInnerHTML={{__html:modalText}}/>
                <div className={ styles.Buttons }>
                    <Button
                        buttonType="secondary"
                        onClick={ this.onDiscard }>
                        { modal_window_titles.no }
                    </Button>

                    <Button
                        onClick={ this.onSave }
                        buttonType="primary"
                    >
                        { modal_window_titles.yes }
                    </Button>
                </div>

            </div>
        );
    }

    onSave = async () => {
        const data = {
            view: this.data.current_view,
            section: this.data.current_section,
            selected_ids: this.selected,
            widget_options: this.widgetOptions,
            sections_titles: this.sections,
        };
        this.isSavingData = true;

        await new apiRequest('POST /form-layout/' + this.data.essence + '/update').sendJSON(data).catch(console.error);
        this.isSavingData = false;
        this.onDiscard();
    };

    onDiscard = () => {
        let redirect;
        if (this.current.type === 'view') {
            redirect = '/form-layout/' + this.data.essence + '?view=' + this.current.value;
        }
        else {
            redirect = '/form-layout/' + this.data.essence + '?view=' + this.data.current_view + '&section=' + this.current.value;
        }
        this.showModal = false;
        helperRedirect(redirect);
        this.fetchData().catch(this.errorFetchData);
    };

    render() {
        const { form_layout_titles = {}, modal_window_titles = {} } = langStore.getTranslate();

        if (this.errorMessage) return <div>{ this.errorMessage }</div>;
        if (!this.data) return <PageLoader />;

        return (
            <Container>
                <div className={ styles.All }>
                    <div className={ styles.ActionsTop }>
                        <div className={ styles.Title }>
                            <Heading size={ 'h3' } isRegular noMargin data-test={ ATTRIBUTES.formlayoutTitle } ><SmartTitle>{ form_layout_titles.form_layout_configure }</SmartTitle></Heading>
                        </div>
                        <Button
                            className={ styles.Button }
                            onClick={ this.saveSelected }
                            buttonType="primary"
                            data-loader={ this.isSavingData }
                            data-test={ ATTRIBUTES.formlayoutSaveButton }
                        >
                            { form_layout_titles.save_button }
                        </Button>
                        <Button
                            onClick={ () => {
                                helperRedirect(
                                    this.prevLocation
                                        ? `/record/${ this.data.essence }/${ this.prevLocation }`
                                        : `/record/${ this.data.essence }`,
                                );
                            } }
                            data-test={ ATTRIBUTES.formlayoutCancelButton }
                        >
                            { form_layout_titles.cancel_button }
                        </Button>
                    </div>
                    <LayoutEditor
                        selectedItems={ this.data.selected_items }
                        availableItems={ this.data.available_items }
                        showSplitButtons={ this.data.show_split_buttons }
                        onChange={ this.updateSelected }
                        onChangeWidgetOptions={ this.updateWidgetOption }
                        lang={ form_layout_titles }
                        showWidgetOption
                        location={ this.props.location }
                    />
                    <Heading data-test={ ATTRIBUTES.layoutViewTitle } size={ 'h4' }>{ form_layout_titles.form_view_and_section }</Heading>
                    <div className={ styles.Bottom }>
                        <ViewSelect
                            current={ this.data.current_view }
                            onAdd={ this.addView }
                            onSelect={ this.switchView }
                            items={ this.data.available_views }
                            lang={ form_layout_titles }
                        />
                        <SectionSelect
                            onChange={ this.changeSectionsOrder }
                            onAdd={ this.addSection }
                            onRemove={ this.removeSection }
                            onSelect={ this.switchSection }
                            current={ this.data.current_section }
                            items={ this.initialSection }
                            isSaveRun={ this.isSavingSectionsOrder }
                        />
                    </div>
                </div>

                <ConfirmModal
                    isShow={ this.showModal }
                    title={ modal_window_titles.title }
                    doClose={ this.handleShowModal }
                >
                    { this.renderConfirmModal() }
                </ConfirmModal>
            </Container>
        );
    }

}


export default ErrorWrapperHoc(observer(FormLayoutEdit));
