import _ from 'lodash';
import {
    CategoryItemType,
    CategoryType,
    DataCategoriesItemsType,
    ItemType,
} from 'types/components/widgets/categoryWidget';

export const getCurrentItemsByItemId = (itemId, data: DataCategoriesItemsType | CategoryItemType[]): CategoryItemType[] => {
    let items: CategoryItemType[] = [];
    _.some(getDataArray(data), (element: CategoryType | DataCategoriesItemsType) => {
        if (element.items) {
            const findElement = _.find(element.items, el => el.sys_id === itemId);
            if (findElement) {
                const categories = element.categories || [];
                const elementItems = element.items || [];
                items = [
                    ...categories,
                    ...elementItems,
                ];
                return true;
            }
        }
        else if (element.categories && getCurrentItemsByItemId(itemId, element.categories).length > 0) {
            items = getCurrentItemsByItemId(itemId, element.categories);
            return true;
        }
    });
    return items;
};

export const getCurrentItemsByCategoryId = (categoryId, data: DataCategoriesItemsType | CategoryItemType[]): CategoryItemType[] => {
    let items: CategoryItemType[] = [];
    _.some(getDataArray(data), (element: CategoryType | DataCategoriesItemsType) => {
        if (element.sys_id === categoryId) {
            const categories = element.categories || [];
            const elementItems = element.items || [];
            items = [
                ...categories,
                ...elementItems,
            ];
            return true;
        } else if (element.categories) {
            const findElement = findCategory(element.categories, categoryId);
            if (findElement) {
                const categories = findElement.categories || [];
                const elementItems = findElement.items || [];
                items = [
                    ...categories,
                    ...elementItems,
                ];
                return true;
            }
        }
        else if (element.items && getCurrentItemsByCategoryId(categoryId, element.items).length > 0) {
            items = getCurrentItemsByCategoryId(categoryId, element.items);
            return true;
        }
    });
    return items;
};

export const getCurrentItems = (currentUrl, data: DataCategoriesItemsType | CategoryItemType[]): CategoryItemType[] => {
    let items: CategoryItemType[] = [];
    _.some(getDataArray(data), (element: CategoryType | DataCategoriesItemsType) => {
        if (currentUrl === element.url) {
            const categories = element.categories || [];
            const elementItems = element.items || [];
            items = [
                ...categories,
                ...elementItems,
            ];
            return true;
        }
        else if (getCurrentItems(currentUrl, element.categories).length > 0) {
            items = getCurrentItems(currentUrl, element.categories);
            return true;
        }
        else if (getCurrentItems(currentUrl, element.items).length > 0) {
            items = getCurrentItems(currentUrl, element.items);
            return true;
        }
    });
    return items;
};

export const getDataArray = (data: DataCategoriesItemsType | (CategoryType | ItemType)[]): (CategoryType | ItemType | DataCategoriesItemsType)[] => {
    if (!data) {
        return [];
    } else if (!Array.isArray(data) && !data.url) {
        return [ ...data.categories, ...data.items ];
    } else if (!Array.isArray(data)) {
        return [ data ];
    } else {
        return data;
    }
};

export const findCategory = (categories: CategoryType[], categoryId): CategoryType | undefined => {
    let findElement = _.find(categories, el => el.sys_id === categoryId);
    if (findElement) {
        return findElement;
    }
    for (const category of categories) {
        if (!_.isEmpty(category.categories)) {
            findElement = findCategory(category.categories, categoryId);
            if (findElement) {
                return findElement;
            }
        }
    }
    return undefined;
};
