import TablesState from 'globalState/tables';
import TableState from 'globalState/table';
import InfoMessagesState from 'globalState/infoMessages';
import exportState from 'globalState/exportState';
import { deleteRecord } from 'actions/record';


export default class SimpleList {
    query = '';

    getQuery = () => {
        return this.query;
    };

    /**
     * Получить массив имён таблиц
     *  @returns {Array<string>}
     * */
    getTablesName = () => {
        return TablesState.getTablesName();
    };


    /**
     *  Получить массив отмеченных строк в таблице
     *  @param {string} tableName
     *  @returns {Array<string>}
     * */
    getCheckedRow = (tableName) => {
        const table = TablesState.getTable(tableName);
        return table.getCheckedRow();
    };

    /**
     * Удалить список строк
     * @param {string} tableName
     * @param {Array<string>} sysIds
     * @returns {Promise<*>}
     */
    deleteRows = async (tableName, sysIds) => {
        const table = TablesState.getTable(tableName);
        table.clearCheckedRows();
        await deleteRecord(tableName, sysIds);
    };

    /**
     * Обновить данные на листе
     * @param {string} tableName
     */
    refresh = (tableName) => {
        window.listGlobal[tableName].fetchUiActions();
        window.listGlobal[tableName].fetchData(false);
    };

    /**
     * Добавляет экспорт в очередь
     * @param {'excel' | 'json'} type
     */
    fetchExportTable = async (type = "excel") => {
        const columns = TableState.getColumns();
        const columnsName = columns.map(c => c.attribute);
        const tableName = TableState.getName();

        const params = {
            condition: s_list.getQuery(),
            type,
            tableName,
            columns: columnsName,
        };
        exportState.addParams(params);

        const exportLimitExceeded = await exportState.fetchExportTable();
        if (exportLimitExceeded) {
            exportState.showWarningModal = true;
        }
    }

    /**
     * Вывести информационное сообщение
     * @param {string} message
     * @param {number} [duration]
     */
    addInfoMessage = (message, duration) => {
        InfoMessagesState.pushInfo(message, duration);
    };

    /**
     * Вывести сообщение об ошибке
     * @param {string} message
     */
    addErrorMessage = (message) => {
        InfoMessagesState.pushError(message);
    };

    /**
     * Вывести сообщение о предупреждении
     * @param {string} message
     * @param {number} [duration]
     */
    addWarningMessage = (message, duration) => {
        InfoMessagesState.pushWarn(message, duration);
    };

    /**
     * Вывести сообщение об успехе
     * @param {string} message
     * @param {number} [duration]
     */
    addSuccessMessage = (message, duration) => {
        InfoMessagesState.pushSuccess(message, duration);
    };

    //Removes all informational and error messages from the top of the form.
    clearMessages() {
        InfoMessagesState.removeAll();
    }
}
