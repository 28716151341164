import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import IconCross from 'assets/img/icons/close-x.svg';
import styles from './styles.module.scss';

/**
 *  Описание: Значки в мультиселекте
 *  Параметры:
 *  values: {required: true,  type: array} - массив значений
 *  onRemoveItem: {required: true,  type: function} - метод вызываемый при удалении значка
 *  canExcludeAll: {type: boolean} - флаг можно ли снять выбор со всех
 */
class BadgesComponent extends React.Component {
    refContent = React.createRef();
    refContainer = React.createRef();
    hasOverflow = false;

    constructor(props) {
        super(props);

        makeObservable(this, {
            hasOverflow: observable,
        });
    }

    componentDidMount() {
        this.checkOverflow();
        window.addEventListener('resize', this.checkOverflow);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkOverflow);
    }

    componentDidUpdate() {
        this.checkOverflow();
    }

    checkOverflow = () => {
        const contentRect = this.refContent.current.getBoundingClientRect();
        const containerRect = this.refContainer.current.getBoundingClientRect();
        this.hasOverflow = containerRect.height > contentRect.height;
    };

    onRemoveClick(e, item) {
        e.stopPropagation();
        if (this.props.onRemoveItem) {
            this.props.onRemoveItem(item);
        }
    }

    render() {
        // если выбран всего один пункт и нельзя снять выбор со всех
        const hideCross = this.props.values.length === 1 && !this.props.canExcludeAll;

        const badges = this.props.values.map((item, index) => {
            return (
                <div key={ index } className={ `${ styles.Badge } ${ hideCross ? styles.noCross : '' }` }>
                    { item.display_value }
                    { !hideCross && (
                        <div
                            className={ styles.BadgeCross }
                            dangerouslySetInnerHTML={ { __html: IconCross } }
                            onClick={ (e) => {
                                this.onRemoveClick(e, item);
                            } }
                        />
                    ) }
                </div>
            );
        });

        return (
            <div className={ `${styles.Badges}` }>
                <div className={ styles.BadgesContent } ref={ this.refContent }>
                    <div className={ styles.BadgesContainer } ref={ this.refContainer }>
                        { badges }
                    </div>
                </div>
                { this.hasOverflow && <div className={ styles.BadgesTotal }>
                    { this.props.values.length }
                </div> }
            </div>
        );
    }
}

export default observer(BadgesComponent);
