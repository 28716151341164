export default class SimpleRecord {
    /*
    *   SimpleRecord используется для операций с базой данных.
    *   Клиентский SimpleRecord позволяет использовать некоторые функциональные возможности SimpleRecord
    *   в клиентских сценариях, таких как клиентские сценарии и сценарии политики пользовательского интерфейса.
    *   SimpleRecord содержит как записи, так и поля.
    *   Запросы, выполненные с помощью SimpleRecord на стороне клиента, выполняются на сервере.
    *   Поэтому из клиентского браузера делается запрос на получение данных записи.
    *   Клиентская SimpleRecord не поддерживается в приложениях с областью действия.
    *   Вместо этого создайте сценарий include и используйте SimpleAjax или используйте API REST.
    */
    AJAX_PROCESSOR = "AjaxSimpleRecord";

    /* Создает экземпляр класса SimpleRecord для указанной таблицы. */
    constructor(tableName) {
        this.__currentRow = -1;
        this.__tableName = tableName;
        this.__orderByColumns = [];
        this.__rows = [];
        this.__conditions = [];
        this.__fieldValues = [];
        this.___excludedAttributes = ['__currentRow', '__tableName', '__orderByColumns', '__rows', '__conditions', '__fieldValues', '___excludedAttributes', '__ajax', 'AJAX_PROCESSOR'];
        this.__ajax = new SimpleAjax(this.AJAX_PROCESSOR);
    }

    /* Задает имя таблицы */
    setTableName(tableName) {
        this.constructor(tableName);
        this._initAttrs();
    }

    /* Возвращает имя таблицы */
    getTableName() {
        return this.__tableName;
    }

    /* Выставляет заданные строки */
    setRows(rows) {
        this.__rows = rows;
        this._initAttrs();
    }

    /* возвращает строки  */
    getRows() {
        return this.__rows;
    }

    /* Добавляет фильтр для возврата записей, где поле соответствует указанному условию (поле, оператор, значение). */
    addOrderBy(column) {
        this.__orderByColumns.push(column);
        this.__ajax.addParam("sysparm_orderBy", this.__orderByColumns);
    }

    /* Определяет столбец orderBy. Может вызываться более одного раза для заказа по нескольким столбцам. */
    orderBy(column) {
        this.__orderByColumns.push(column);
        this.__ajax.addParam("sysparm_orderBy", this.__orderByColumns);
    }

    /* Добавляет фильтр для возврата записей, где поле соответствует указанному условию (поле, оператор, значение).
     * Добавляет фильтр для возврата записей, где поле равно значению (или находится в списке значений).
     */
    addQuery(...args) {
        if (args.length === 2) {
            this.__conditions.push({ 'name' : args[0], 'operator' : '=', 'value' : args[1]});
        } else if (args.length > 2) {
            this.__conditions.push({ 'name' : args[0], 'operator' : args[1], 'value' : args[2]});
        }
    }

    /* Определяет, есть ли еще записи в SimpleRecord. */
    hasNext() {
        return (this.__currentRow + 1 < this.__rows.length);
    }

    /* Извлекает все условия запроса в виде закодированной строки запроса. */
    getEncodedQuery() {
        let ec = '';
        this.__conditions.forEach(function (condition) {
            ec += "^" + condition['name'] + condition['operator'] + condition['value'];
        });
        return ec;
    }

    /* Удаляет текущую запись. */
    deleteRecord(responseFunction) {
        this.__ajax.addParam("sysparm_table_name", this.getTableName());
        this.__ajax.addParam("sysparm_name", 'deleteRecord');
        this.__ajax.addParam("sysparm_conditions", this.__conditions);
        this.__ajax.addParam("sysparm_currentRow", this.__currentRow);
        this.__ajax.getXML(responseFunction);
        if (!this.next() && this.__currentRow > -1) {
            this.__currentRow--;
            this._initAttrs();
        }
    }


    /*  Выполняет запрос.
        Принимает ноль или более параметров.
        Параметры могут быть в любом порядке.
        Любая функция считается функцией ответа.
        Любая пара литералов считается парой запросов (field: value).
        Не делайте синхронных запросов запросов.
        Выполнение запроса без функции ответа делает вызов синхронным, что означает,
        что дисплей будет ожидать ответа на запрос, прежде чем продолжить.
        */
    query(...args)  {
        const responseFunction = this._parseArgs(args);
        this.__ajax.addParam("sysparm_table_name", this.getTableName());
        this.__ajax.addParam("sysparm_name", 'query');
        this.__ajax.addParam("sysparm_conditions", this.__conditions);
        if (responseFunction) {
            this.__ajax.getXML((response)=>{
                const xml = response.responseXML.documentElement.children;
                const records = [];
                for (const item of xml) {
                    const record = [];
                    const attrs = item.attributes;
                    for (const attr of attrs) {
                        record[attr.name] = attr.value;
                    }
                    records.push(record);
                }
                this.setRows(records);
                this._initAttrs();
                responseFunction(this);
            });
        } else {
            this.__ajax.getXMLWait((response) => {
                const xml = response.responseXML.documentElement.children;
                const records = [];
                for (const item of xml) {
                    const record = [];
                    const attrs = item.attributes;
                    for (const attr of attrs) {
                        record[attr.name] = attr.value;
                    }
                    records.push(record);
                }
                this.setRows(records);
                this._initAttrs();
            });
        }
    }

    _parseArgs(args) {
        let responseFunction = null;
        let i =0;
        while (i < args.length) {
            if (typeof args[i] == 'function') {
                responseFunction = args[i];
                i++;
                continue;
            }
            if (i+1 < args.length) {
                this.__conditions.push({ 'name' : args[i], 'operator' : '=', 'value' : args[i+1]});
                i+=2;
            } else
                i++;
        }
        return responseFunction;
    }

    /* Возвращает true, если запись есть. */
    get(id, callback) {
        if (!(/^\d+$/).test(id)) return;
        this.__conditions = [];
        this.__conditions.push({'name' : 'sys_id', 'operator' : '=', 'value' : id});
        this.query(callback);
        this._initAttrs();
    }

    _initAttrs() {
        if (this.__rows.length !== 0) {
            let row = this.__currentRow;
            if (row === -1)
                row++;
            const arr = this.__rows[row];
            for (const prop in arr) {
                if (typeof arr[prop] !== "function")
                    this[prop] = arr[prop];
            }
        } else {
            const attrs = Object.keys(this);
            const difference = attrs.filter(attr => !this.___excludedAttributes.includes(attr));
            difference.forEach((attr)=>{
                delete this[attr];
            });
        }
    }

    /* Переходит к следующей записи в SimpleRecord. */
    next() {
        if (this.hasNext()) {
            this.__currentRow++;
            this._initAttrs();
            return true;
        }
        return false;
    }

    /* Проставляет значение аттрибуту */
    setValue(fieldName, value) {
        this[fieldName] = value;
    }

    /* Вставляет новую запись, используя значения полей, которые были установлены для текущей записи. */
    insert(responseFunction) {
        const attrs = Object.keys(this);
        const difference = attrs.filter(attr => !this.___excludedAttributes.includes(attr));
        difference.forEach((attr)=>{
            this.__fieldValues.push({ 'property' : attr, 'value' : this[attr]});
        });
        this.__ajax.addParam("sysparm_table_name", this.getTableName());
        this.__ajax.addParam("sysparm_name", 'insert');
        this.__ajax.addParam("sysparm_attributes", this.__fieldValues);
        this.__ajax.getXML(responseFunction);
    }

    /* Возвращает ограничение на количество записей, возвращаемых запросом SimpleRecord. */
    setLimit(maxQuery) {
        this.limit = maxQuery;
        this.__ajax.addParam("sysparm_limit", this.limit);
    }
}
