import * as React from 'react';
import { observer } from 'mobx-react';

import BaseElement from 'components/portalDesigner/components/base';
import WidgetComponent from 'components/widget';
import DragAndDropState from 'components/portalDesigner/components/dragAndDrop/state';

import PageData from 'components/portalDesigner/state/pageData';

import styles from 'components/portalDesigner/styles.module.scss';
import langStore from 'globalState/lang';

class WidgetInstance extends React.Component {
    breadcrumbsIds = [];

    constructor(props) {
        super(props);

        this.breadcrumbsIds = this.props.breadcrumbsIds.concat([
            {
                id: props.id,
                model: props.model,
                // @todo translate
                name: 'Widget',
                type: 'widget',
            },
        ]);
    }

    handleDragEnter = (e) => {
        e.preventDefault();
        const { index, parentId } = this.props;

        if (DragAndDropState.type === PageData.TYPE_WIDGET_INSTANCE) {
            PageData.addTempWidgetInstance(index, parentId);
        }
    };

    handleDrop = (e) => {
        e.preventDefault();
        const { parentId } = this.props;

        if (DragAndDropState.type === PageData.TYPE_WIDGET_INSTANCE) {
            PageData.insertWidgetInstance(PageData.TYPE_WIDGET_INSTANCE, parentId, DragAndDropState.sysId);
        }
    };

    handleDragOver = (e) => {
        e.preventDefault();
    };

    render() {
        const { id, widget } = this.props.model;

        return <BaseElement
            key={ id }
            id={ id }
            type="widget"
            model={ this.props.model }
            onDrop={ this.handleDrop }
            onDragEnter={ this.handleDragEnter }
            onDragOver={ this.handleDragOver }
            index={ this.props.index }
            breadcrumbsIds={ this.breadcrumbsIds }
            className={ styles.WidgetInstance }>
            {

                this.props.model.isTemp ? <div className={ styles.WidgetInstanceTemp } />
                    : <WidgetComponent
                        key={ id }
                        pathname={ PageData.portal ? PageData.portal.path_name : '/' }
                        template={ widget.template }
                        options={ widget.schema_options }
                        widget_instance_id={ id }
                        css={ widget.css }
                        dictionary={ langStore.getTranslate() }
                        // client_script={ widget.client_script }
                    />
            }

        </BaseElement>;
    }
}

export default observer(WidgetInstance);

