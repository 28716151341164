import React, {useRef, useState, useEffect, useCallback} from 'react';
import {
    SELECTION_CHANGE_COMMAND,
    $getSelection,
    $isRangeSelection,
    COMMAND_PRIORITY_LOW,
} from 'lexical';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import styles from './styles.module.scss';
import {mergeRegister } from '@lexical/utils';
import LinkInput from 'components/linkInput';
import {getSelectedNode, setPopupPosition} from "../ToolbarPlugin/helpers";
import {GridSelection, NodeSelection, RangeSelection} from "lexical/LexicalSelection";
import {ATTRIBUTES} from "constants/attributesForTests";

export function FloatingLinkEditor({editor}) {
    const editorRef = useRef<null | HTMLDivElement>(null);
    const [linkUrl, setLinkUrl] = useState('');
    const [lastSelection, setLastSelection] = useState<null | RangeSelection | NodeSelection | GridSelection>(null);

    useEffect(() => {
        const onDocumentClick = ({target}) => {
            const editorElem = editorRef.current;
            const nativeSelection = window.getSelection();
            const rootElement = editor.getRootElement();

            if (rootElement === null || editorElem === null || nativeSelection === null) return;

            if (!editorElem.contains(target)) {
                setPopupPosition(editorElem, null, rootElement);
            }
        };

        document.addEventListener('mousedown', onDocumentClick);
        return () => {
            document.removeEventListener('mousedown', onDocumentClick);
        };
    }, []);

    const updateLinkEditor = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent)) {
                setLinkUrl(parent.getURL());
            } else if ($isLinkNode(node)) {
                setLinkUrl(node.getURL());
            } else {
                setLinkUrl('');
            }
        }
        const editorElem = editorRef.current;
        const nativeSelection = window.getSelection();
        const activeElement = document.activeElement;

        if (editorElem === null) {
            return;
        }

        const rootElement = editor.getRootElement();
        if (
            selection !== null &&
            nativeSelection !== null &&
            !nativeSelection.isCollapsed &&
            rootElement !== null &&
            rootElement.contains(nativeSelection.anchorNode)
        ) {
            const domRange = nativeSelection.getRangeAt(0);
            let rect;
            if (nativeSelection.anchorNode === rootElement) {
                let inner = rootElement;
                while (inner.firstElementChild != null) {
                    inner = inner.firstElementChild;
                }
                rect = inner.getBoundingClientRect();
            } else {
                rect = domRange.getBoundingClientRect();
            }

            setPopupPosition(editorElem, rect, rootElement);
            setLastSelection(selection);
        } else if (!activeElement || activeElement.className !== styles.LinkEditor) {
            if (rootElement !== null) {
                setPopupPosition(editorElem, null, rootElement);
            }
            setLastSelection(null);
            setLinkUrl('');
        }

        return true;
    }, [editor]);

    useEffect(() => {
        const onResize = () => {
            editor.getEditorState().read(() => {
                updateLinkEditor();
            });
        };
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [editor, updateLinkEditor]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({editorState}) => {
                editorState.read(() => {
                    updateLinkEditor();
                });
            }),

            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                () => {
                    updateLinkEditor();
                    return true;
                },
                COMMAND_PRIORITY_LOW,
            ),
        );
    }, [editor, updateLinkEditor]);

    useEffect(() => {
        editor.getEditorState().read(() => {
            updateLinkEditor();
        });
    }, [editor, updateLinkEditor]);


    const onChangeInput = (linkUrl) => {
        setLinkUrl(linkUrl);
        if (lastSelection !== null) {
            if (linkUrl !== '') {
                editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
            } else {
                editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
            }
        }
    };

    const onClose = () => {
        const editorElem = editorRef.current;
        const rootElement = editor.getRootElement();

        if (rootElement === null || editorElem === null) return;
        setPopupPosition(editorElem, null, rootElement);
    };

    return (
        <div
            ref={ editorRef }
            className={styles.LinkEditor}
            data-test={ ATTRIBUTES.editorLinkInput }
        >
            <LinkInput
                linkUrl={ linkUrl }
                setLinkUrl={ onChangeInput }
                onClose={ onClose }
            />
        </div>
    );
}