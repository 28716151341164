import BaseFormFieldModel from './BaseFormFieldModel';
import { observable, makeObservable } from 'mobx';
import * as _ from 'lodash';

export default class OptionsInputModel extends BaseFormFieldModel {

    /**
     * Параметры
     * special
     *
     * @type {*}
     */
    special;

    /**
     * Параметры
     * database_value текущего выбираемОГО значения опции
     *
     * @type {String}
     */
    currentOpt = null;

    columnType = 'optionsinput';

    /**
     * список опций для выбора (для CB)
     * @type {[{
     *     display_value: {string},
     *     database_value: {string},
     * }]|[]}
     */
    valueOpts = [];

    constructor(data, parentFormSectionModel) {
        super(data, parentFormSectionModel);

        makeObservable(this, {
            currentOpt: observable,
            valueOpts: observable,
        });

        this.mergeData(data,['value']);
        this.value = this.convertValueToOption(data.value);
    }

    isEmptyValue() {
        return !this.value ||
            !this.value.database_value &&
            !this.value.display_value;
    }

    convertValueToOption = (value) => {
        let result;
        if (_.isObject(value)){
            if (value.hasOwnProperty('is_option')){
                result = value;
            } else {
                result = {
                    database_value: value.database_value,
                    display_value: value.display_value,
                    is_option: true,
                };
            }
        } else {
            if (value && typeof value === 'string' && value.search(/^opt:/) === 0){
                let opt;
                if (Array.isArray(this.valueOpts)){
                    opt = this.valueOpts.find((opt) => (opt.database_value === value));
                }
                result = {
                    database_value: value,
                    display_value: opt && opt.display_value || '',
                    is_option: true,
                };
            } else {
                result = {
                    database_value: value,
                    display_value: value,
                    is_option: false,
                };
            }
        }
        return result;
    };
}