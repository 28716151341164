import { getUserDateTimeFormat, getUserTimezone } from 'helpers/getUserTimeZone';
import moment from 'moment-timezone';
import * as _ from 'lodash';
import userState from 'globalState/user';

import BaseFormFieldModel from './BaseFormFieldModel';
import { observable, makeObservable } from 'mobx';
import { isEmptyObject } from 'helpers/isEmpty';
import {
    DEFAULT_DATE_FORMAT,
    DEFAULT_DATE_TIME_FORMAT,
    DEFAULT_TIME_FORMAT,
    DEFAULT_TIMESTAMP_FORMAT,
    DEFAULT_DATE_REGEXP,
    DEFAULT_DATE_TIME_REGEXP,
    DEFAULT_TIME_REGEXP,
    DEFAULT_TIMESTAMP_REGEXP,
} from 'constants/dateTime';
import { getFormatFromPHP } from "helpers/date";
import { isChanged } from 'helpers/form';

/**
 * @todo все методы перенесены без изменений
 * нужно будет просмотреть и привести в порядок в рамках другой задачи
 */
export default class DateTimeInputModel extends BaseFormFieldModel {

    /**
     * строка формата
     * format
     *
     * @type {string}
     */
    format;

    /**
     * placeholder для строки ввода
     * placeholder
     *
     * @type {string}
     */
    placeholder;

    /**
     * Маска для поля
     *
     * @type {String}
     */
    mask;


    /**
     * дата начала
     * beginDate
     *
     * @type {Date}
     */
    beginDate;

    /**
     * дата окончания
     * endDate
     *
     * @type {Date}
     */
    endDate;

    columnType = 'datetime';

    pickertype;

    isCondition = false;

    valueOpts = [];

    constructor(data, parentFormSectionModel) {
        super(data, parentFormSectionModel);

        makeObservable(this, {
            format: observable,
            placeholder: observable,
        });

        this.pickertype = data.column_type;
        super.mergeData(data);
        this.allowRunScripts = this.isCondition;
    }

    getRegExp() {
        switch (this.pickertype || this.columnType) {
            case 'date':
                return DEFAULT_DATE_REGEXP;

            case 'datetime':
            case 'datetime_specific':
                return DEFAULT_DATE_TIME_REGEXP;

            case 'time':
                return DEFAULT_TIME_REGEXP;

            case 'timestamp':
                return DEFAULT_TIMESTAMP_REGEXP;

            default:
                return DEFAULT_DATE_TIME_REGEXP;
        }
    }

    getFormat() {
        switch (this.pickertype || this.columnType) {
            case 'date':
                return DEFAULT_DATE_FORMAT;

            case 'datetime':
            case 'datetime_specific':
                return DEFAULT_DATE_TIME_FORMAT;

            case 'time':
                return DEFAULT_TIME_FORMAT;

            case 'timestamp':
                return DEFAULT_TIMESTAMP_FORMAT;

            default:
                return DEFAULT_DATE_TIME_FORMAT;
        }
    }

    getDisplayFormat() {
        const { user = {} } = userState;
        if (this.format) {
            return getFormatFromPHP(this.format);
        } else if(user.datetime_format) {
            return getUserDateTimeFormat(this.pickertype || this.columnType);
        } else {
            return this.getFormat();
        }
    }

    /**
     * @param {moment} value
     * @param {string} format
     * @return {string} - дата с нужным форматам и с пользовательской таймзоной
     */
    applyTimeZone(value, format) {
        if (getUserTimezone().toLowerCase() !== 'utc' &&
            !['datetime_specific', 'date'].includes(this.pickertype)) {
            return value.tz('UTC').format(format);
        }
        return value.format(format);
    }

    /**
     * @param {string} date - дата в системном формате
     * @param {boolean} applyTimezone
     * @param {string} defaultTimeZone - таймзона даты
     * @return {string} - дата с нужным форматам и с пользовательской таймзоной
     */
    getFormatValue(date, applyTimezone, defaultTimeZone = 'UTC') {
        const sysFormat = this.getFormat();

        if (date.match(this.getRegExp())) {
            let value;
            if (sysFormat === DEFAULT_TIME_FORMAT) {
                const time = date.split(':');
                value = moment.tz({
                    hour: time[0],
                    minute: time[1],
                    seconds: time[2],
                }, defaultTimeZone);
            } else {
                //для даты таймзона не применяется
                if (['datetime_specific', 'date'].includes(this.pickertype)) {
                    defaultTimeZone = 'UTC';
                }
                value = moment.tz(date, defaultTimeZone);
            }
            if (applyTimezone && sysFormat !== DEFAULT_DATE_FORMAT) {
                return this.applyTimeZone(value, sysFormat);
            }

            return value.tz('UTC').format(sysFormat);
        }

        return date;
    }

    getDisplayValue() {
        let value = this.value;
        if (this.isCondition && _.isObject(value)){
            if (typeof value.database_value === 'string' && value.database_value.search(/^opt:/) === 0){
                return value.display_value;
            }
            value = value.database_value;
        }
        return this.convertToDisplayValue(value);
    }

    convertToDisplayValue = (value) => {
        if (_.isNil(value) || !value.match(this.getRegExp())) {
            return value;
        }
        const sysFormat = this.getFormat();
        const displayFormat = this.getDisplayFormat();

        if (sysFormat === DEFAULT_TIME_FORMAT) {
            const time = value.split(':');
            return moment.tz({
                hour: time[0],
                minute: time[1],
                seconds: time[2],
            }, 'UTC').tz(getUserTimezone()).format(displayFormat);
        }
        //для даты таймзона не применяется
        if (['datetime_specific', 'date'].includes(this.pickertype)) {
            return moment.tz(value, 'UTC').format(displayFormat);
        }
        return moment.tz(value, 'UTC').tz(getUserTimezone()).format(displayFormat);
    };

    /**
     * @return {moment} - сущность moment с учётом timezone
     */
    getInitialValue() {
        let value = this.value;
        if (this.isCondition && _.isObject(value)){
            if (typeof value.database_value === 'string' && value.database_value.search(/^opt:/) === 0){
                return null;
            }
            value = value.database_value;
        }
        const tz = getUserTimezone();
        const sysFormat = this.getFormat();

        if (_.isNil(value) || !value.match(this.getRegExp())) {
            return moment.tz(tz);
        }
        if (sysFormat === DEFAULT_TIME_FORMAT) {
            const time = value.split(':');
            return moment.tz({
                hour: time[0],
                minute: time[1],
                seconds: time[2],
            }, 'UTC').tz(tz);
        }
        if (['datetime_specific', 'date'].includes(this.pickertype)) {
            return moment.tz(value, 'UTC');
        }
        return moment.tz(value, 'UTC').tz(tz);
    }

    /**
     * @param {string} value - дата в системном формате UTC
     * @return {moment} - сущность moment с учётом timezone
     */
    setValue(value) {
        let result;
        if (value){
            if (this.isCondition && _.isObject(value)){
                result = value;
            } else {
                result = this.getFormatValue(value, false);
            }
        } else {
            result = '';
        }
        return result;
    }

    checkEmptyValue() {
        return isEmptyObject(this.value);
    }

    uiGetDisplayValue() {
        return this.getDisplayValue();
    }

    uiSetValue(value) {
        if (value === '') {
            const isClear = this.uiClearValue();
            this.runClientScripts(this.value);
            return isClear;
        }

        if (!_.isEqual(this.value, value)) {
            if (this.isCondition && _.isObject(this.value)){
                this.value = value;
                this.runClientScripts(this.value);
            }
            const isValidDate = moment(value, this.getFormat(), true).isValid();

            if (isValidDate) {
                this.value = this.getFormatValue(value, true);
                this.runClientScripts(this.value);
                this.changed = isChanged(this.defaultValue, this.value);
            }
        }
    }

    getNewMaskValue = (value, mask) => {
        return {
            newValue: value,
            newMask: mask.substr(value.length),
        };
    };

    checkValidFormatValue = (value) => {
        if (!value) {
            this.hideValidateMsg();
            this.isValid = true;
        } else {
            const isValidDate = moment(value, this.getDisplayFormat(), true).isValid();

            if (!isValidDate) {
                this.isValid = false;
            } else {
                this.hideValidateMsg();
                this.isValid = true;
            }
        }
        return this.isValid;
    };

    checkValidEnteredValue = (enteredValue) => {
        if (!enteredValue.match(/^\d+$/)) {
            this.showValidateMsg(this.messages && this.messages.pattern_message);
            return false;
        }
        return true;
    }

    showWrongDataMsg = () => {
        this.showValidateMsg(this.messages && this.messages.wrong_data_message);
    }

    hideAllValidateMsg = () => {
        this.hideValidateMsg();
    }

    convertValueToOption = (value) => {
        let result;
        if (_.isObject(value)){
            if (value.hasOwnProperty('is_option')){
                result = value;
            } else {
                result = {
                    database_value: value.database_value,
                    display_value: this.convertToDisplayValue(value.database_value),
                    is_option: true,
                };
            }
        } else {
            if (value && typeof value === 'string' && value.search(/^opt:/) === 0){
                let opt;
                if (Array.isArray(this.valueOpts)){
                    opt = this.valueOpts.find((opt) => (opt.database_value === value));
                }
                result = {
                    database_value: value,
                    display_value: opt && opt.display_value || '',
                    is_option: true,
                };
            } else {
                result = {
                    database_value: value,
                    display_value: this.convertToDisplayValue(value),
                    is_option: false,
                };
            }
        }
        return result;
    };
}
