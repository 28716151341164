import { observable, action, makeObservable } from 'mobx';

import _ from 'lodash';

import { fetchSelectionList } from 'actions/portalDesigner';

/**
 * базоый state  для данных полученных через GET /selection/list
 */
export default class ListBaseStorage {
    data = [];
    table_name = 'sys_db_table';
    columns = [
        'name',
        'sys_id',
    ].join(',');
    per_page = 0;

    fetch = async () => {
        const requestOptions = {
            table_name: this.table_name,
            columns: this.columns,
            per_page: this.per_page,
        };

        const { data } = await fetchSelectionList(requestOptions);
        this.set(data.list);
    };

    constructor() {
        makeObservable(this, {
            data: observable,
            set: action,
        });
    }

    set(data) {
        this.data.replace(data);
    }

    get() {
        return _.clone(this.data);
    }

    getBy(field, value) {
        return _.clone(this.data.find((item) => item[field] && (item[field] === value)));
    }

    filter(field, value) {
        return this.data.filter((item) => item[field] && item[field].toLowerCase().includes(value.toLowerCase()));
    }
}
