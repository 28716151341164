import apiRequest from 'lib/apiRequest';

// получение списка активностей
export const fetchActivities = async (tableName, sysId, params) => {
    const response = await new apiRequest('GET /activities' + '/' + tableName + '/' + sysId).qs(params).send();
    return response.getData();
};

export default {
    fetchActivities,
};
