import * as React from 'react';
import { action, reaction, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import ConditionFilters from 'components/conditionFilters';
import Button from 'components/button';
import FieldWrapper from 'components/dynamicForms/view/fieldWrapper';
import langStore from 'globalState/lang';

import _ from 'lodash';
import styles from './styles.module.scss';
import Popup from 'components/dynamicForms/view/field/conditions/popup';
import Crumb from 'components/dynamicForms/view/field/conditions/crumb';
import IconChevronRight from 'assets/img/icons/chevron-right.svg';
import IconFilter from 'assets/img/icons/filter.svg';
import { Fragment } from 'react';
import ConditionsModel from 'components/dynamicForms/model/field/ConditionsModel';
import { isMedia } from 'helpers/html';
import { getTestNameField } from 'helpers/data';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { isChanged } from 'helpers/form';
import { getConditionDictionary } from 'helpers/condition';

/**
 * Описание: компонент Conditions
 * Параметры:
 * onChange: {type: function} - метод для изменения
 * tableId: {type: string} - id таблицы
 * tableName: {type: string} - имя таблицы
 * value: {type: string}
 * dependentOnColumn: {type: string} - связь с полем с таблицей
 * extra_attributes: {type: string} - дополнительные аттрибуты
 */
class Conditions extends React.Component {
    model;
    isMediaSm = false;
    refField = React.createRef();

    constructor(props) {
        super(props);

        makeObservable(this, {
            model: observable,
            isMediaSm: observable,
            handleAddFiltering: action,
            handleAddSorting: action,
            handleClearAll: action,
            setActiveConditionFilter: action,
        });

        getConditionDictionary();

        if (props.model) {
            this.model = props.model;
        } else {
            this.model = new ConditionsModel(props);
        }
        this.model.onChange = this.props.onChange;

        this.model.setConditionState(props);

        this.events(this.model);
    }

    componentDidMount() {
        this.checkFieldWidth();
        window.addEventListener('resize', this.checkFieldWidth);
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props, prevProps)) {
            if (this.props.model) {
                this.model = this.props.model;

                this.model.setConditionState(this.props);

                this.checkFieldWidth();
                this.model.onChange = this.props.onChange;
            } else {
                this.model.mergeData(this.props);
            }

            if ((!this.model.value || _.isNil(this.model.value)) && prevProps.value && this.model.conditionState.isEmpty()) {
                this.model.conditionState.clearData();
            }

            if (this.model.tableId !== prevProps.tableId || this.model.value !== prevProps.value) {
                this.model.fetchData(this.model.getTableId());
            }
        }
    }

    componentWillUnmount() {
        this.model.conditionState.clearData();
        window.removeEventListener('resize', this.checkFieldWidth);
    }

    events = (model) => {
        const { dependentOnColumn } = model;
        if (!dependentOnColumn) {
            return;
        }
        reaction(
            () => {
                return this.model.getDependentOnColumn();
            },
            (tableId) => {
                if (tableId) {
                    this.model.fetchData(tableId);
                    if (this.model.tableId) this.model.uiClearValue();
                    this.model.conditionState.addFiltering('AND');
                }
            },
        );
    };

    handleAddFiltering = (type, block, index) => () => {
        this.model.isEmpty = this.model.data.length === 0;
        if (this.model.isEmpty) {
            return;
        }
        this.model.conditionState.addFiltering(type, block, index);
    };

    handleAddSorting = () => {
        this.model.isEmpty = this.model.data.length === 0;
        if (this.model.isEmpty) {
            return;
        }
        this.model.conditionState.addSorting();
    };

    handleConditionChange = () => {
        const { onChange } = this.props;
        this.model.value = this.model.conditionState.getConditionString();
        this.model.changed = isChanged(_.replace(this.model.defaultValue, /[()]/g, ''),
            _.replace(this.model.value, /[()]/g, ''));

        if (onChange) {
            onChange({
                value: this.model.value,
                component: this,
            });
        }
    };

    isSorting = (id) => {
        return id.includes('sorting');
    };

    handleClearAll = () => {
        const { onChange } = this.props;
        this.model.value = '';
        this.model.changed = isChanged(this.model.defaultValue, this.model.value);
        this.model.conditionState.clearData();
        if (onChange) {
            onChange({
                value: this.model.value,
                component: this,
            });
        }
    };

    getExtraAttributes = () => {
        const { extra_attributes } = this.model;
        if (!extra_attributes) {
            return [];
        }
        const extraAttributesString = extra_attributes.replace(/[^=]*=/, '');
        return extraAttributesString.split(';');
    };

    setActiveConditionFilter = (id) => {
        this.model.activeConditionFilter = id;
    };

    renderFiltering = () => {
        if (!this.model.conditionState.isShowFilter()) {
            return null;
        }
        const { filter_titles } = langStore.getTranslate();

        let prevBlock = this.model.conditionState.getMinBlockNumber();
        const filterFields = this.model.conditionState.getFilterFields();
        const fields = filterFields.map((row, index) => {
            const orHead = row.getBlock() > prevBlock ? (
                <div className={ styles.FilterLabel }>
                    { filter_titles && `${ filter_titles.label_or } ${ filter_titles.conditions_must_be_met.toLowerCase() }` }
                </div>
            ) : null;
            prevBlock = row.getBlock();
            const isActive = this.model.activeConditionFilter === row.getId();
            return (
                <React.Fragment key={ row.getId() }>
                    { orHead }
                    <ConditionFilters
                        row={ row }
                        tableId={ this.model.tableId }
                        key={ row.getId() }
                        onRemoveFilteringRow={ this.model.conditionState.removeFilteringRow }
                        className={ row.getType() === 'OR' ? styles.BorderBottom : '' }
                        onAddFiltering={ this.handleAddFiltering }
                        index={ index }
                        data={ this.model.data }
                        readOnly={ this.model.readOnly }
                        extraAttributes={ this.getExtraAttributes() }
                        isActive={ isActive }
                        setActive={ this.setActiveConditionFilter }
                        onConditionChange={ this.handleConditionChange }
                        conditionCount={ filterFields.length }
                        isMobile={ this.isMediaSm }
                        data-test={ ATTRIBUTES.conditionFilteringRow }
                    />
                </React.Fragment>
            );
        });

        return (
            <div className={ styles.FilterConditions } data-test={ ATTRIBUTES.conditionFilteringRows } >
                {
                    this.model.conditionState.getFilterFields().length > 0 &&
                    (
                        <div className={ styles.FilterLabel }>
                            { filter_titles && filter_titles.conditions_must_be_met }
                        </div>
                    )
                }
                { fields }
            </div>
        );
    };

    renderSorting = () => {
        if (!this.model.conditionState.isShowSorting()) {
            return null;
        }
        const { filter_titles } = langStore.getTranslate();
        const fields = this.model.conditionState.getSortingFields().map((row) => {
            return (
                <ConditionFilters
                    row={ row }
                    key={ row.getId() }
                    tableId={ this.model.tableId }
                    onRemoveSortingRow={ this.model.conditionState.removeSortingRow }
                    data={ this.model.data && this.model.data.filter(row => row.condition_type !== 'keywords') }
                    isActive={ this.model.activeConditionFilter === row.getId() }
                    setActive={ this.setActiveConditionFilter }
                    readOnly={ this.model.isReadOnly || this.model.readOnly }
                    onConditionChange={ this.handleConditionChange }
                    isMobile={ this.isMediaSm }
                    data-test={ ATTRIBUTES.conditionSortingRow }
                />
            );
        });

        return (
            <div className={ styles.FilterSorts } data-test={ ATTRIBUTES.conditionSortingRows } >
                <div className={ styles.FilterLabel }>
                    { filter_titles && filter_titles.order }
                </div>
                { fields }
            </div>
        );
    };

    renderField = () => {
        const { filter_titles } = langStore.getTranslate();
        const filter = (
            <div
                className={ `${ styles.Filter } ${ this.isMediaSm ? styles.MobileFilter : '' }` }
                data-test={ ATTRIBUTES.conditionFilter }
            >
                <div className={ styles.FilterControls }>
                    <div className={ styles.ControlBlock }>
                        <div className={ styles.FilterLabel }>
                            { filter_titles && filter_titles.filter_main_label }
                        </div>
                        <Button
                            className={ styles.Button }
                            onClick={ this.handleAddFiltering('AND') }
                            disabled={ this.model.isReadOnly || this.model.readOnly }
                            data-test={ ATTRIBUTES.conditionButtonAnd }
                        >
                            { filter_titles && filter_titles.button_and }
                        </Button>
                        <Button
                            className={ styles.Button }
                            onClick={ this.handleAddFiltering('OR') }
                            disabled={ this.model.isReadOnly || this.model.readOnly }
                            data-test={ ATTRIBUTES.conditionButtonOr }
                        >
                            { filter_titles && filter_titles.button_or }
                        </Button>
                        <Button
                            className={ styles.Button }
                            onClick={ this.handleAddSorting }
                            disabled={ this.model.isReadOnly || this.model.readOnly }
                            data-test={ ATTRIBUTES.conditionButtonSort }
                        >
                            { filter_titles && filter_titles.button_sort }
                        </Button>
                    </div>
                    <div className={ styles.ControlBlock }>
                        <Button
                            className={ styles.Button }
                            onClick={ this.handleClearAll }
                            disabled={ this.model.isReadOnly || this.model.readOnly }
                            data-test={ ATTRIBUTES.conditionButtonClearAll }
                        >
                            { filter_titles && filter_titles.clear_all }
                        </Button>
                    </div>
                </div>
                <div className={ styles.FilterContent }>
                    { this.renderFiltering() }
                    { this.renderSorting() }
                </div>
            </div>
        );

        let layout = null;

        if (this.model.isCompact) {
            layout = (
                <>
                    { this.isMediaSm ? <Button
                        onClick={ this.setPopupState(true) }
                        className={ styles.ShowButton }
                        svg={ IconFilter }
                        buttonType={ 'icon-border' }
                    /> : <Button
                        onClick={ this.setPopupState(true) }
                        className={ styles.ShowButton }
                    >
                        <div className={ styles.ShowButtonIcon } dangerouslySetInnerHTML={ { __html: IconFilter } } />
                        { filter_titles && filter_titles.filter }
                    </Button>
                    }
                    { this.renderCrumbs(this.model.conditionState.getFilterFields()) }
                    { this.model.isPopupOpened &&
                    <Popup onClose={ this.setPopupState(false) }>
                        { filter }
                    </Popup>
                    }
                </>
            );
        }
        else {
            layout = filter;
        }

        return (
            <div
                ref={ this.refField }
                className={ styles.ClearFix }
                data-test={ `${ getTestNameField(this.model) }-${ ATTRIBUTES.fieldCondition }` }
                data-test-visible={ this.model.isVisible }
                data-test-mandatory={ this.model.isMandatory }
                data-test-readonly={ this.model.readOnly }
                data-test-field-type={ this.model.sys_column_name ? this.model.column_type : undefined }
                data-test-field-name={ this.model.sys_column_name }
            >
                { layout }
            </div>
        );
    };

    renderCrumbs = (fields) => {
        const items = fields.map((item, index) => {
            if (!item._field.column_title || !item._operator.display_value) return false;
            return (
                <Fragment key={ index }>
                    { index !== 0 && <li>
                        <div className={ styles.ChevronRight } dangerouslySetInnerHTML={ { __html: IconChevronRight } } />
                    </li> }
                    <li>
                        <Crumb
                            item={ item }
                            delimeter=" "
                            tableId={ this.model.tableId }
                            data={ this.model.data }
                        />
                    </li>
                </Fragment>
            );
        });
        return (
            <ul className={ styles.BreadcrumbsList }>
                { items }
            </ul>
        );
    };

    setPopupState = () => (flag) => {
        this.model.isPopupOpened = flag;
    };

    checkFieldWidth = () => {
        this.isMediaSm = isMedia('sm');

        const { current: field } = this.refField;
        if (!field) return;

        this.model.isCompact = field.getBoundingClientRect().width < 700;
    };

    render() {
        return (
            <FieldWrapper model={ this.model }>
                { this.renderField() }
            </FieldWrapper>
        );
    }
}

export default observer(Conditions);
