import _ from 'lodash';

import { observable, reaction, makeObservable } from 'mobx';
import BasePortalDesignerModel from 'components/portalDesigner/model/base';

/**
 * базовый класс для коллекций всех сущностей дизайнера страниц портала
 */
export default class BasePortalDesignerCollection {
    /**
     *
     * @type [BasePortalDesignerModel]
     */
    items = [];

    /**
     * @type BasePortalDesignerModel
     */
    model = BasePortalDesignerModel;

    /**
     *
     * @param items []
     * @param model BasePortalDesignerModel
     * @param parent {BasePortalDesignerModel}
     */
    constructor(items, model, parent) {
        makeObservable(this, {
            items: observable,
        });

        this.model = model;
        this.parent = parent;
        this.items = items ? this.prepareItems(items, this.model) : [];

        /**
         * пересортировать если был добавлен или удалён элемент
         */
        reaction(
            () => this.items.length,
            () => {
                this.items = this.sortByOrder(this.items);
            },
        );
    }

    /**
     * преобразует массив данных в массив моделей
     * @param items
     * @returns {*}
     */
    prepareItems(items) {
        return this.sortByOrder(items).map((item) => new this.model({
            ...item,
            collection: this,
            parent: this.parent,
        }));
    }

    /**
     * сортировка по полю order
     * @param items []
     */
    sortByOrder(items) {
        return _.sortBy(items, 'order');
    }

    /**
     * возвращает модель по её индексу
     * @param index
     * @returns {*}
     */
    getItems(index) {
        return index === undefined ? this.items : this.items[index];
    }

    /**
     * добавляет в список новую модель, созаднную на основе данных params
     * @param params {*}
     * @returns {BasePortalDesignerModel}
     */
    insert(params) {
        this.clearTemp();
        let item;
        if (Array.isArray(params)) {
            item = params.map((_params) => new this.model({
                collection: this,
                parent: this.parent,
                ..._params,
            }));

            this.items = [
                ...this.items,
                ...item,
            ];
        }
        else {
            item = new this.model({
                collection: this,
                parent: this.parent,
                ...params,
            });
            this.items = [
                ...this.items,
                item,
            ];
        }
        return item;
    }

    /**
     * обновляет данные в существующих моделях коллекции
     * @param data [*]
     */
    updateItems(data) {
        if (_.isNil(data)) {
            return;
        }

        data.forEach((data) => {
            const model = this.items.find(({ sysId }) => sysId === data.sys_id);
            if (model) {
                model.setData(data.widget_info || data);
            }
            else {
                this.insert(data.widget_info || data);
            }
        });
    }

    /**
     * удаляет блоки с флагом isTemp
     */
    clearTemp() {
        this.items = this.items.filter((item) => !item.isTemp);
    }
}
