import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import { ATTRIBUTES } from 'constants/attributesForTests';
import React, { createRef } from 'react';
import Dropdown from 'components/dropdown';
import {makeObservable, observable} from 'mobx';
import IconUserDefault from 'assets/img/user-ava.svg';
import Hint from 'components/hint';
import DropdownUser from 'components/otherUser/dropdownUser';
import _ from 'lodash';
import { CentrifugeConnect } from 'helpers/centrifuge/centrifuge';
import { IOtherUserProps } from 'types/components/otherUser';
import { updateMenuScrollTop } from 'helpers/html';
import langStore from 'globalState/lang';

const TIMEOUT_HALF_HOUR = 1800000;
const TIMEOUT_HALF_MINUTE = 30000;

class OtherUser extends React.Component<IOtherUserProps> {
    refParent = createRef<HTMLDivElement>();
    refDropdown = createRef<HTMLDivElement>();
    refMenu = createRef<HTMLDivElement>();
    refActiveItem = createRef<HTMLDivElement>();
    isShow = false;

    widthParent: string | number | undefined = 'auto';

    centrifuge: any = null;
    isOpened = false;

    focusTimeout: NodeJS.Timeout;
    clickTimeout: NodeJS.Timeout;

    current;

    isKeyboardOn = false;
    indicatePresence;
    isFocused = false;
    isWorkOnPage = true;

    constructor(props) {
        super(props);

        makeObservable(this, {
            isShow: observable,
            widthParent: observable,
            isOpened: observable,
            current: observable,
            isKeyboardOn: observable,
            indicatePresence: observable,
            isFocused: observable,
            isWorkOnPage: observable,
        });
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        this.handleShowHidePage();
        document.addEventListener('keydown', this.onKeyDown);
        document.addEventListener('mousemove', this.onMouseMove);
        window.addEventListener('scroll', this.onWindowScroll);
        if (_.isNil(this.centrifuge)) {
            this.workCentrifuge();
        }
    }

    componentDidUpdate(prevProps: Readonly<IOtherUserProps>) {
        const { tableName, sysId, userSysId, indicatePresence } = this.props;
        if (tableName !== prevProps.tableName || sysId !== prevProps.sysId) {
            this.workCentrifuge();
        }
        if (userSysId !== prevProps.userSysId) {
            if (!_.isNil(this.centrifuge)) {
                this.centrifuge.disconnect();
                this.hideDropdown();
            }
            this.workCentrifuge();
        }
        if (!_.isNil(indicatePresence) && _.isNil(prevProps.indicatePresence)) {
            this.workCentrifuge();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('keydown', this.onKeyDown);
        document.removeEventListener('mousemove', this.onMouseMove);
        window.removeEventListener('scroll', this.onWindowScroll);
        if (!_.isNil(this.centrifuge)) {
            this.centrifuge.disconnect();
        }
    }

    handleShowHidePage = () => {
        const delta = 1000;

        setInterval(() => {
            if (document.hidden) {
                this.handleHideWindow();
            } else {
                this.handleShowWindow();
            }
        }, delta);
    };

    handleSleepAndWakeUpUser = () => {
        if (!_.isNil(this.centrifuge)) {
            if (this.isWorkOnPage) {
                clearTimeout(this.clickTimeout);
                this.clickTimeout = setTimeout(() => {
                    this.unsubscribe();
                }, TIMEOUT_HALF_HOUR);
                this.isWorkOnPage = false;
            } else {
                clearTimeout(this.clickTimeout);
                this.subscribe();
                this.isWorkOnPage = true;
            }
        }
    }

    subscribe = () => {
        const sub = this.centrifuge.getSubscription(this.getChannelName());
        if (sub) {
            sub.subscribe();
        } else {
            this.workCentrifuge();
        }
    }

    unsubscribe = () => {
        const { indicatePresence } = this.props;
        const sub = this.centrifuge.getSubscription(this.getChannelName());
        if (sub) {
            sub.unsubscribe();
            indicatePresence?.setUsers([]);
        }
    }

    onWindowScroll = (event) => {
        if (!this.refDropdown || !this.refParent) {
            return null;
        }
        const { current: el } = this.refDropdown;
        const { current: parentEl } = this.refParent;

        if (!el || !parentEl) {
            return false;
        }

        if (!el.contains(event.target) && !parentEl.contains(event.target)) {
            this.hideDropdown();
        }
    }

    onKeyDown = (event) => {
        if (this.isOpened) {
            if (!this.indicatePresence?.getIsEnabled()) {
                return;
            }
            const users = this.indicatePresence?.getUsers();
            if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                event.preventDefault();
                let nextOptionIndex = 0;
                for (let i = 0, usersFor = users; i < users.length; i++) {
                    if (this.current === undefined) {
                        nextOptionIndex = 0;
                        break;
                    }
                    if (usersFor[i].id === this.current) {
                        nextOptionIndex = (event.keyCode === 38) ? i - 1 : i + 1;
                        if (nextOptionIndex < 0) {
                            nextOptionIndex = usersFor.length - 1;
                        } else if (nextOptionIndex > usersFor.length - 1) {
                            nextOptionIndex = 0;
                        }
                        break;
                    }
                }
                this.current = users[nextOptionIndex].id;
                this.isKeyboardOn = true;
                updateMenuScrollTop(this.refMenu.current, this.refActiveItem.current);
                this.refActiveItem.current?.focus();
                this.refParent.current?.blur();
            }

            if (event.key === 'Enter') {
                const user = users.find((user) => user.id === this.current);
                if (!user || user?.is_anonymous) {
                    return;
                }
                window.open(`/record/user/${ user.id }`, '_blank');
            }

            // esc
            if (event.key === 'Escape') {
                this.hideDropdown();
            }
        }
    }

    onMouseMove = () => {
        this.isKeyboardOn = false;
    };

    hideDropdown = () => {
        this.current = undefined;
        this.isOpened = false;
    }

    workCentrifuge = () => {
        const { tableName, sysId, indicatePresence } = this.props;
        if (!tableName || !sysId) {
            return;
        }
        if (!indicatePresence?.getIsEnabled()) {
            return;
        }
        this.centrifuge = new CentrifugeConnect();

        this.centrifuge.on('message', (message) => {
            if (Array.isArray(message.data)) {
                indicatePresence.setUsers(_.uniqBy([ ...indicatePresence.getUsers(), ...message.data ], 'id'));
            } else {
                indicatePresence.setUsers([ ...indicatePresence.getUsers(), message.data ]);
            }
        });

        const sub = this.centrifuge.newSubscription(this.getChannelName());
        this.subscriptionEvents(sub);
        // Move subscription to subscribing state.
        sub.subscribe();
        this.centrifuge.connect();
        this.handleSleepAndWakeUpUser();
    }

    subscriptionEvents = (sub) => {
        const { indicatePresence } = this.props;
        if (!indicatePresence?.getIsEnabled()) {
            return;
        }
        sub.on('publication', (ctx) => {
            if (ctx.data.action && ctx.data.action === 'disconnect') {
                indicatePresence.setUsers(_.filter(indicatePresence.getUsers(), user => user.id !== ctx.data.user_id));
            } else {
                if (Array.isArray(ctx.data)) {
                    indicatePresence.setUsers(_.uniqBy([ ...indicatePresence.getUsers(), ...ctx.data ], 'id'));
                } else {
                    indicatePresence.setUsers(_.uniqBy([ ...indicatePresence.getUsers(), ctx.data ], 'id'));
                }
            }
        });
    }

    handleShowWindow = () => {
        if (this.isFocused) {
            return;
        }
        clearTimeout(this.focusTimeout);
        if (!_.isNil(this.centrifuge)) {
            this.subscribe();
        } else {
            this.workCentrifuge();
        }
        this.isFocused = true;
    }

    handleHideWindow = () => {
        if (!this.isFocused) {
            return;
        }
        if (!_.isNil(this.centrifuge)) {
            this.focusTimeout = setTimeout(() => {
                this.unsubscribe();
            }, TIMEOUT_HALF_MINUTE);
        }
        this.isFocused = false;
    }

    getChannelName = () => {
        const { tableName, sysId, userSysId } = this.props;
        return `${ tableName }${ sysId }:${ userSysId }`;
    }

    handleClickOutside = (event) => {
        if (!this.refDropdown || !this.refParent) {
            return null;
        }
        this.handleSleepAndWakeUpUser();
        const { current: el } = this.refDropdown;
        const { current: parentEl } = this.refParent;

        if (!el || !parentEl) {
            return false;
        }

        if (!el.contains(event.target) && !parentEl.contains(event.target)) {
            this.hideDropdown();
        }
    };

    renderMoreElement = () => {
        const { indicatePresence } = this.props;
        const countUsers = indicatePresence?.getUsers().length || 0;
        if (countUsers <= 1) {
            return null;
        }
        const stylesMore = {
            width: countUsers - 1 < 10 ? '25px' : '32px',
        };
        let classAnimation = '';
        if (indicatePresence?.getIsAddUser()) {
            classAnimation = countUsers - 1 === 1 ? styles.SlideInRightMore : styles.Dissolve;
        } else {
            classAnimation = styles.MoreBlockShow;
        }
        return (
            <div
                key={ `more${countUsers}` }
                className={ `${ styles.MoreBlock } ${ classAnimation }` }
                style={ stylesMore }
            >
                <div className={ styles.MoreText }>
                    +{ countUsers - 1 }
                </div>
            </div>
        );
    };

    handleClickOtherUser = () => {
        this.isOpened = !this.isOpened;
    };

    handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            this.isOpened = !this.isOpened;
        }
    }

    getWidth = (countUsers) => {
        if (countUsers === 0) {
            return styles.Width0;
        }
        if (countUsers === 1) {
            return styles.Width32;
        }
        if (countUsers > 1 && countUsers < 11) {
            return styles.Width49;
        }
        return styles.Width56;
    }

    renderHint = (firstUser, countUsers) => {
        const { indicatePresence } = this.props;
        if (this.isOpened || !indicatePresence?.getIsEnabled() || _.isEmpty(indicatePresence.getUsers())) {
            return null;
        }
        const { presence } = langStore.getTranslate();
        return (
            <Hint refParent={ this.refParent }>
                { presence?.opened_by } { firstUser?.name } { countUsers >= 1 ? `+${ countUsers }` : '' }
            </Hint>
        );
    }

    renderDropdown = () => {
        const { indicatePresence } = this.props;
        if (!this.isOpened || !indicatePresence?.getIsEnabled() || _.isEmpty(indicatePresence.getUsers())) {
            return null;
        }
        const users = indicatePresence.getUsers();
        const menuTpl = (
            <div className={ styles.DropDown }>
                <div
                    className={ styles.List }
                    ref={ this.refMenu }
                >
                    { _.map(users, user => {
                        return (
                            <DropdownUser
                                user={ user }
                                key={ user.id }
                                ref={ user.id === this.current ? this.refActiveItem : null }
                                current={ this.current }
                            />
                        );
                    }) }
                </div>
            </div>
        );
        return (
            <Dropdown
                data-test={ATTRIBUTES.otherUserDropdown}
                refParent={ this.refParent }
                ref={ this.refDropdown }
                floatRight
            >
                { menuTpl }
            </Dropdown>
        );
    };

    render() {
        const { className, dataTest, indicatePresence } = this.props;
        if (!indicatePresence?.getIsEnabled()) {
            return null;
        }
        const countUsers = indicatePresence.getUsers().length;
        const firstUser = countUsers ? indicatePresence.getUsers()[0] : null;
        const avatar = firstUser?.avatar ? (
            <div
                className={ `${ styles.Ava } ${ countUsers < 2 && indicatePresence.getIsAddUser() ? styles.Dissolve : '' }` }
                style={ { backgroundImage: `url("${ firstUser.avatar }")` } }
                data-test={ATTRIBUTES.otherUserAva}
            />
        ) : (
            <div
                className={ `${ styles.Ava } ${ countUsers < 2 && indicatePresence.getIsAddUser() ? styles.Dissolve : '' }` }
                dangerouslySetInnerHTML={ { __html: IconUserDefault } }
                data-test={ATTRIBUTES.otherUserAva}
            />
        );
        const classWidth = this.getWidth(countUsers);
        return (
            <div
                className={ `${ styles.OtherUserBlock } ${ countUsers > 0 ? styles.SlideInRight : '' } ${ classWidth } ${ className || '' }` }
                data-test={ dataTest ? dataTest : ATTRIBUTES.otherUser }
            >
                <div
                    className={ `${ styles.OtherUserContainer } ${ this.isOpened ? styles.Selected : '' }` }
                    onClick={ this.handleClickOtherUser }
                    onKeyDown={ this.handleKeyDown }
                    ref={ this.refParent }
                    tabIndex={ 0 }
                >
                    { avatar }
                    { this.renderMoreElement() }
                </div>
                { this.renderHint(firstUser, countUsers - 1) }
                { this.renderDropdown() }
            </div>
        );
    }
}

export default observer(OtherUser);
