import userState from 'globalState/user/index';
import langStore from 'globalState/lang/index';
import { getPreference, savePreference } from 'actions/preferences';
import _ from 'lodash';
import {ResponsePreferencesSave} from "types/actions/preferences";
import {UiUserData} from "types/uiActions/index";

export default class SimpleUser {
    user: UiUserData;
    firstName: string | undefined;
    lastName: string | undefined;
    userID: string | undefined;
    userName: string | undefined;
    accessToken: null | string | undefined;

    constructor() {
        const clonedUserState = JSON.parse(JSON.stringify(userState || {}));

        this.user = clonedUserState.user;
        if (!_.isEmpty(this.user)) {
            this.user.language = langStore.getLang();
            this.firstName = this.user.first_name;
            this.lastName = this.user.last_name;
            this.userID = this.user.sys_id;
            this.userName = this.user.username;
            this.accessToken = clonedUserState.accessToken;
        }
    }

    getFullName(): string {
        return this.firstName + ' ' + this.lastName;
    }

    async setPreference(name: string, value: string): Promise<ResponsePreferencesSave> {
        return await savePreference(name, value);
    }

    async getPreference(name: string | string[]): Promise<void | string[] | string> {
        const { isOkStatus, data } = await getPreference(name);
        if (isOkStatus) {
            let result: string[] | string;
            if (Array.isArray(name)) {
                result = _.map(name, key => {
                    return data[key];
                });
            } else {
                result = data[name];
            }
            return result;
        }
    }
}
