import * as React from 'react';
import { observer } from 'mobx-react';
import Button from 'components/button/index';
import IconCheck from 'assets/img/icons/check.svg';
import IconX from 'assets/img/icons/close-x.svg';
import GlobalPortal from 'components/globalPortal';
import styles from './styles.module.scss';
import { CellEditorProps } from 'types/components/table/cellEditor';
import { ATTRIBUTES } from 'constants/attributesForTests';

/**
 * Описание: редактор ячеек в таблице
 * Параметры:
 * cellRef: {required: true, type: dom element} - ссылка на ячейку таблицы
 * clickCoordinates: {required: true, type: object} - координаты клика на ячейку
 * onSave: {required: true, type: function} - метод вызываемый при сохранении
 * onReset: {required: true, type: function} - метод вызываемый при отмене изменений
 * isBooleanCol: { type: boolean } - флаг если тип колонки boolean
 * hideButtons: { type: boolean } - флаг скрывать кнопки
 *
 * */
class CellEditorComponent extends React.Component<CellEditorProps> {
    refEditor: React.RefObject<HTMLDivElement> = React.createRef();

    componentDidMount() {
        this.setPosition();
    }

    onOverlayClick = () => {
        this.onReset();
    };

    setPosition() {
        const offset = 4;
        const editorEl = this.refEditor.current;
        const cellEl = this.props.cellRef.current;
        if (!editorEl || !cellEl) {
            return;
        }

        const editorRect = editorEl.getBoundingClientRect();
        const cellRect = cellEl.getBoundingClientRect();
        const windowWidth = document.documentElement.clientWidth;
        const windowHeight = document.documentElement.clientHeight;
        const scrollTop = window.pageYOffset;
        const { y: clickTop } = this.props.clickCoordinates;
        let left = cellRect.left;
        let top = clickTop;

        if (left + editorRect.width + offset > windowWidth) {
            left = left - (left + editorRect.width + offset - windowWidth);
        }

        if (top - scrollTop + editorRect.height + offset > windowHeight) {
            top = top - (top - scrollTop + editorRect.height + offset - windowHeight);
        }

        editorEl.style.left = left + 'px';
        editorEl.style.top = top + 'px';
        editorEl.style.position = 'absolute';
    }

    onSave = () => {
        if (this.props.onSave) {
            this.props.onSave();
        }
    };

    onReset = () => {
        if (this.props.onReset) {
            this.props.onReset();
        }
    };

    render() {
        return (
            <GlobalPortal>
                <div
                    className={ styles.CellEditorOverlay }
                    onClick={ this.onOverlayClick }
                />
                <div
                    className={ `${ styles.CellEditor } ${ this.props.isBooleanCol ? styles.smallWidth : '' }` }
                    ref={ this.refEditor }
                    data-test={ ATTRIBUTES.tableCellEditor }
                >
                    <div className={ styles.content }>
                        { this.props.children }
                    </div>
                    { !this.props.hideButtons && (
                        <div className={ styles.controls }>
                            <Button className={ styles.button } buttonType='icon-primary' svg={ IconCheck } onClick={ this.onSave } disabled={ this.props.isDisabled } />
                            <Button className={ styles.button } buttonType='icon-border' svg={ IconX } onClick={ this.onReset } />
                        </div>
                    )}
                </div>
            </GlobalPortal>
        );
    }
}

export default observer(CellEditorComponent);
