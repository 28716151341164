import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import * as _ from 'lodash';
import TaskBoard from 'components/taskBoard';
import ErrorWrapperHoc from 'helpers/hoc/errorWrapperHOC';
import TaskBoardLanesState from 'components/taskBoard/taskBoardLanes/state';
import { fetchBoard, fetchBoardCards } from 'actions/taskBoard';

class TaskBoardList extends React.Component {
    board;
    loadingCards = false;

    constructor(props) {
        super(props);

        makeObservable(this, {
            board: observable,
            loadingCards: observable,
        });

        this.fetchBoard().catch(console.error);
    }

    fetchBoard = async () => {
        const { match } = this.props;
        const boardId = match.params.id;
        const response = await fetchBoard({ board_id: boardId });
        if (response.isOkStatus) {
            const data = response.data && response.data ? response.data : {};
            if (data && data.board) {
                this.board = data.board;
            }
            if (data && data.lanes) {
                TaskBoardLanesState.setLanes(data.lanes);
            }
            await this.fetchBoardCards(boardId);
        }
    };

    fetchBoardCards = async (boardId) => {
        this.loadingCards = true;
        const response = await fetchBoardCards({ board_id: boardId });
        const cards = response.isOkStatus && response.data ? response.data.cards : [];
        if (!_.isEmpty(cards)) {
            const laneCards = _.groupBy(cards, card => card.lane_id);
            _.forEach(laneCards, (lCards, key) => {
                let result = [ ...lCards ];
                result.sort(TaskBoardLanesState.compare);
                TaskBoardLanesState.setCards(key, result);
            });
        }
        this.loadingCards = false;
    };

    render() {
        if (!this.board || _.isEmpty(this.board)) {
            return null;
        }

        return (
            <TaskBoard
                board={ this.board }
                loadingCards={ this.loadingCards }
            />
        );
    }
}

export default ErrorWrapperHoc(observer(TaskBoardList));

