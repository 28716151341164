import * as React from 'react';
import { observer } from 'mobx-react';

import { FormSection } from 'components/dynamicForms/view/form';
import * as _ from 'lodash';
import Tabs from 'components/tabs';
import DynamicFormModel from 'components/dynamicForms/model/DynamicFormModel';
import { runOnTypeScripts, TYPE_ON_LOAD } from 'helpers/scriptClientHelper';
import FormsState from 'globalState/forms';
import { DynamicFormProps } from 'types/components/dynamicForms/dynamicForm';
import activityFeeds from 'globalState/activityFeeds';
import { FormSectionType } from 'types/components/dynamicForms/formSection';

class DynamicForm extends React.Component<DynamicFormProps> {
    /**
     * @type {DynamicFormModel}
     */
    model: DynamicFormModel;

    constructor(props) {
        super(props);
        this.model = new DynamicFormModel(props);
        FormsState.addDynamicForm(this.model.id, this.model);
        if (!window.form) {
            window.form = this.model;
        }
    }

    componentDidMount() {
        this.runOnLoadScript();
        this.updateActivityFeed();
    }

    componentWillUnmount() {
        FormsState.deleteDynamicForm(this.model.id);
    }

    componentDidUpdate(prevProps) {
        if (this.model.isFormLoaded) {
            this.runOnLoadScript();
            this.model.isFormLoaded = false;
        }
        if (this.props.id !== this.model.id) { //Если обновился id, удаляем старый state
            if (this.model.id){
                FormsState.deleteDynamicForm(this.model.id);
            }
        }
        if (!_.isEqual(this.props.fields, prevProps.fields)) { //если изменился набор полей при переключении view
            this.model.setData(this.props);
            this.model.isFormLoaded = true;
        }
        else if (!_.isEqual(this.props, prevProps)) {
            this.model.mergeData(this.props, ['sections']);
            const dynamicForm = FormsState.getDynamicForm(this.model.id);
            if (!dynamicForm){ //Перерегистрация формы в FormState, если формы в нём нет (FormState мог быть очищен)
                FormsState.addDynamicForm(this.model.id, this.model);
            }
            this.updateActivityFeed();
        }
    }

    updateActivityFeed = () => {
        const activityFeedState = activityFeeds.getItem(this.model.tableName, this.model.sysId);
        if (activityFeedState) {
            activityFeedState.setActivityTypesFromFields(this.model.sections);
        }
    };

    runOnLoadScript() {
        const { clientScripts } = this.props;
        if (clientScripts) {
            runOnTypeScripts(clientScripts, TYPE_ON_LOAD, null, null, this.model.id, this.model.isSubForm);
        }
    }

    getTabs(sections) {
        const { formName, widgetId, remId } = this.props;
        return sections.length ? _.map(sections, (model) => {
            const key = model.id;
            model.remId = remId;
            return {
                name: model.name,
                serviceName: model.serviceName,
                isMandatory: model.isMandatory,
                isWarning: model.isWarning,
                value: (
                    <FormSection
                        key={ key }
                        model={ model }
                        formName={ formName }
                        widgetId={ widgetId }
                    />
                ),
            };
        }) : null;
    }

    getWidgetTabs(sections) {
        const { formName, widgetId, remId } = this.props;
        return sections.length ? _.map(sections, (model) => {
            model.remId = remId;
            return {
                name: model.name,
                value: (
                    <FormSection
                        key={ 'form_' + model.name }
                        model={ model }
                        formName={ formName }
                        widgetId={ widgetId }
                    />
                ),
            };
        }) : null;
    }

    renderWidgetForms() {
        const { sysId, widgetId, tableName, formName } = this.props;
        const { sections, isServicePortal } = this.model;

        const tabs = this.getWidgetTabs(sections);
        return tabs ? (
            <Tabs
                key={ sysId }
                sysId={ sysId || widgetId }
                tableName={ tableName }
                widgetId={widgetId}
                tabs={ tabs }
                formName={ formName }
                dynamicFormId={ this.model.id }
                isPortal={ isServicePortal }
            />
        ) : null;
    }


    render() {
        const { formName, widgetId, remId, sysId, tableName } = this.props;
        let output;
        if (this.model.isServicePortal){
            output = this.renderWidgetForms();
        } else {
            const firstSection: FormSectionType = this.model.sections[0];
            const sections = this.model.sections.slice(1);
            const tabs = this.getTabs(sections);
            const key = this.props.sysId ? '0-' + this.props.sysId : '0';
            firstSection.remId = remId;
            output = <React.Fragment>
                {
                    firstSection ? <FormSection
                        key={ key }
                        model={ firstSection }
                        formName={ formName }
                        widgetId={ widgetId }
                    /> : null
                }
                {
                    tabs && <Tabs
                        key={ sysId }
                        sysId={ sysId }
                        tableName={ tableName }
                        tabs={ tabs }
                        formName={ formName }
                        dynamicFormId={ this.model.id }
                    />
                }
            </React.Fragment>;
        }
        return output;
    }
}

export default observer(DynamicForm);
