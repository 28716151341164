import * as React from 'react';
import ChartComponent from 'components/chart/index';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import apiRequest from 'lib/apiRequest';

class KindChart extends React.Component {
    data = {};

    constructor(props) {
        super(props);

        makeObservable(this, {
            data: observable,
        });

        this.fetchData(props.tableName, props.column, props.chartType, props.condition);
    }

    fetchData = async (essence, column, chartType, condition) => {
        const url = new URL(`/chart/${essence}/${column}/${chartType}`, window.location.origin);
        if (condition) {
            url.searchParams.set('condition', condition);
        }
        const response = await new apiRequest(`GET ${url.pathname + url.search}`).send();
        this.data = response.getData();
    };

    render() {
        return (
            <ChartComponent data={ this.data } />
        );
    }
}

export default observer(KindChart);
