import React from 'react';
import _ from 'lodash';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import { IDecorationElementProps } from 'types/components/portalWidgetsComponents/dropdownWidget';
import { ATTRIBUTES } from 'constants/attributesForTests';

const MAX_LIMIT_LENGTH_WORD = 3;

class DecorationElement extends React.Component<IDecorationElementProps> {
    refDecoration: React.RefObject<HTMLDivElement> = React.createRef();

    render() {
        const { decoration } = this.props;
        if (!decoration) {
            return null;
        }
        const { text, hint = '', color, backgroundColor, borderRadius } = decoration;

        const style = _.omitBy({
            color,
            backgroundColor,
            borderRadius,
        }, _.isEmpty);

        const arrText = text
            ? [ ...text].filter((item, index) => index >= 0 && index < MAX_LIMIT_LENGTH_WORD)
            : [];
        
        const hasDecorationPadding = !!text?.trim().length;

        const classes = [styles.Decoration];

        if (hasDecorationPadding){
            classes.push(styles.hasPadding);
        }

        return (
            <span
                className={ classes.join(' ') }
                ref={ this.refDecoration }
                style={ style }
                title={ hint }
                data-test={ ATTRIBUTES.dropdownWidgetDecorationText }
            >
                { arrText.join('') }
            </span>
        );
    }
}

export default observer(DecorationElement);