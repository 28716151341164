import * as React from 'react';
import styles from 'components/dateTimePicker/styles.module.scss';
import DatePickerDayModel from 'components/dateTimePicker/datePickerDayModel';

/**
 * Описание: Ячейка с датой
 * Параметры:
 * picker - ссылка на датапикер
 * day - день
 * beginDate - начальная дата
 * endDate - конечная дата
 * className - класс
 */
export default class DatePickerCell extends React.Component {
    constructor(props) {
        super(props);
        this.state = { date: props.date };

        if (!(props.day instanceof DatePickerDayModel)) {
            throw new Error('day must be DateTimePickerDayModel');
        }
    }

    changeDate = () => {
        const date = this.props.picker.state.date;
        const { beginDate, endDate, day, picker: { props: { readonly } } } = this.props;

        if(readonly) return;

        if (beginDate && endDate) {
            if (day.moment.isBefore(beginDate) || day.moment.isAfter(endDate)) {
                return;
            }
        }
        else if (beginDate && day.moment.isBefore(beginDate)) {
            return;
        }
        else if (endDate && day.moment.isAfter(endDate)) {
            return;
        }
        day.moment.add({
            hours: date.hours(),
            minutes: date.minutes(),
            seconds: date.seconds(),
        });
        this.props.picker.state.date = day.moment;
    };

    render() {
        let className = '';
        if (this.props.className) {
            className = Array.isArray(this.props.className) ? this.props.className.join(' ') : this.props.className;
        }
        className += this.props.day.className ? ' ' + this.props.day.className : '';
        return (
            <div
                className={ `${ styles.Col } ${ className }` }
                key={ this.props.index }
                onClick={ this.changeDate }
                data-test={ this.props['data-test'] ? this.props['data-test'] : '' }
            >
                { this.props.day.value }
            </div>
        );
    }
}
