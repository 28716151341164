/**
 * Описание: Панель слева диаграммы рабочих процессов (содержит панель инструментов и список workflow)
 * Для редактирования свойств объекта
 * Параметры:
 * toggleLeftPanel - метод включения/выключения панели
 * isOpened - открыта ли панель
 * workflows - список workflow
 * activityTypes - список типов активностей
 * activityTypeCategories - список категорий типов активностей
 * currentWorkflow - текущий открытый workflow
 * loadWorkflow - метод загрузки workflow
 * newWorkflow - метод создания нового workflow
 * copyWorkflow - метод копирования workflow
 * deleteWorkflow - метод удаления workflow
 * checkOutWorkflow - метод checkout workflow
 * publishWorkflow - метод публикации workflow
 * validateWorkflow - метод валидации workflow
 * showWorkflowProperties - метод для показа свойств
 * isLocked - workflow закрыт для редактирования
 * isPublished - текущий workflow опубликован
 * isCheckedOut - текущий workflow checked out
 * */
import * as React from 'react';
import { observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';

import langStore from 'globalState/lang';
import IconMenu from 'assets/img/icons/burger-menu.svg';
import Tools from 'components/workflow/leftPanel/tools';
import WorkflowList from 'components/workflow/leftPanel/workflowList';
import IconChevronLeft from 'assets/img/icons/chevron-left.svg';
import IconChevronRight from 'assets/img/icons/chevron-right.svg';
import IconSearch from 'assets/img/icons/search.svg';
import IconClear from 'assets/img/icons/close-x.svg';
import styles from './styles.module.scss';

const TOOLS = 0;
const WORKFLOWS = 1;

class LeftPanel extends React.Component {

    activePanel;
    isShowMenu = false;
    filterValue = '';
    filterWorkflows;

    menuRef = React.createRef();

    constructor(props) {
        super(props);

        makeObservable(this, {
            activePanel: observable,
            isShowMenu: observable,
            filterValue: observable,
            filterWorkflows: observable,
            handleMenu: action,
            handleClickOutside: action,
        });

        this.activePanel = this.props.currentWorkflow ? TOOLS : WORKFLOWS;
    }

    handleMenu = (e) => {
        e.preventDefault();
        this.isShowMenu = true;
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentWorkflow !== prevProps.currentWorkflow || this.props.isCheckedOut !== prevProps.isCheckedOut) {
            const activePanel = this.activePanel;
            if (this.props.currentWorkflow && this.props.isCheckedOut) {
                this.activePanel = TOOLS;
            }
            else {
                this.activePanel = WORKFLOWS;
            }
            if (activePanel !== this.activePanel) { //Сбрасываем значение фильтра при переходе на другой таб
                this.filterValue = '';
                this.handleFilter();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    togglePanels = (id) => () => {
        if (this.props.currentWorkflow && this.props.isCheckedOut) { //переключение табов только для режима редактирования
            if (this.activePanel !== id) { // Если сменилась вкладка, сбрасываем фильтр
                this.filterValue = '';
                this.handleFilter();
            }
            this.activePanel = id;
        }
    };

    handleCloseClick = () => {
        this.props.toggleLeftPanel(false);
    };

    handleOpenClick = () => {
        this.props.toggleLeftPanel(true);
    };

    handleChangeFilter = (e) => {
        this.filterValue = e.target.value;
        this.handleFilter();
    };

    filtering = (items, filterValue) => {
        if (!items) return null;
        const filterItems = items.filter((item) => {
            return item.name.toLowerCase().includes(filterValue.toLowerCase());
        });
        return filterItems;
    };

    handleFilter = () => {
        if (this.filterValue && this.activePanel) {
            this.filterWorkflows = this.filtering(this.props.workflows, this.filterValue);
            return;
        }
        else if (this.filterValue && !this.activePanel) {
            this.filterWorkflows = this.filtering(this.props.activityTypes, this.filterValue);
            return;
        }
        this.filterWorkflows = '';
    };

    handleClickOutside = (e) => {
        const { current: menu } = this.menuRef;
        if (!menu) return false;
        if (!menu.contains(e.target)) {
            this.isShowMenu = false;
        }
    };

    handleClearSearch = () => {
        this.filterValue = '';
        this.handleFilter();
    };

    handleKeyDownFilter = (event) => {
        if (event.key === 'Escape') {
            this.handleClearSearch();
        }
    };

    handleNewWorkflowClick = () => {
        this.props.newWorkflow();
        this.isShowMenu = false;
    };

    handleDeleteWorkflowClick = () => {
        this.props.deleteWorkflow();
        this.isShowMenu = false;
    };

    handleCheckoutWorkflowClick = () => {
        this.props.checkOutWorkflow().then(
            () => {
                this.activePanel = TOOLS;
                this.isShowMenu = false;
            },
        );
    };

    handlePublishWorkflowClick = () => {
        this.props.publishWorkflow().then(() => {
            this.activePanel = WORKFLOWS;
            this.isShowMenu = false;
        });
    };

    renderMenu = () => {
        const { workflow_titles } = langStore.getTranslate();
        return (
            <ul className={ styles.Menu } ref={ this.menuRef }>
                <li onClick={ this.handleNewWorkflowClick }>
                    { workflow_titles && workflow_titles.new_workflow }
                </li>
                { !!this.props.currentWorkflow && !this.props.isLocked &&
                < li onClick={ this.handleDeleteWorkflowClick }>
                    { workflow_titles && workflow_titles.delete }
                </li>
                }
                { !!this.props.currentWorkflow && !this.props.isCheckedOut && !this.props.isLocked &&
                <li onClick={ this.handleCheckoutWorkflowClick }>
                    { workflow_titles && workflow_titles.check_out }
                </li>
                }
                { !!this.props.currentWorkflow && this.props.isCheckedOut && !this.props.isLocked &&
                <li onClick={ this.handlePublishWorkflowClick }>
                    { workflow_titles && workflow_titles.publish }
                </li>
                }
                { !!this.props.currentWorkflow &&
                <li onClick={ () => {
                    this.props.showWorkflowProperties(this.props.currentWorkflow);
                    this.isShowMenu = false;
                } }>
                    { workflow_titles && workflow_titles.properties }
                </li>
                }
            </ul>
        );
    };

    componentDidCatch(error, info) {
        console.error(error, info);
    }

    render() {
        const { workflow_titles, user_menu_titles } = langStore.getTranslate();
        return (
            <>
                { this.props.isOpened &&
                <div className={ styles.LeftPanel }>
                    <div className={ styles.UpperPanel }>
                        <div className={ styles.BurgerIcon }
                             onClick={ this.handleMenu }
                             dangerouslySetInnerHTML={ { __html: IconMenu } }
                        />
                        <div className={ styles.Tabs }>
                            <div className={ `${ this.props.isCheckedOut ? styles.SelectableTab : null } ${ this.activePanel === TOOLS ? styles.ActiveTab : styles.Tab }` }
                                 onClick={ this.props.currentWorkflow ? this.togglePanels(TOOLS) : null }>
                                { workflow_titles && workflow_titles.activities_title }
                            </div>
                            <div className={ `${ this.props.isCheckedOut ? styles.SelectableTab : null } ${ this.activePanel === WORKFLOWS ? styles.ActiveTab : styles.Tab }` }
                                 onClick={ this.togglePanels(WORKFLOWS) }>
                                { workflow_titles && workflow_titles.workflows_title }
                            </div>
                        </div>
                    </div>
                    <label className={ styles.Search }>
                        <input
                            type="text"
                            placeholder={ user_menu_titles && user_menu_titles.search }
                            onChange={ this.handleChangeFilter }
                            onKeyDown={ this.handleKeyDownFilter }
                            value={ this.filterValue }
                        />
                        { this.filterValue ? <span className={ styles.IconSearch } dangerouslySetInnerHTML={ { __html: IconClear } } onClick={ this.handleClearSearch } />
                            : <span className={ styles.IconSearch } dangerouslySetInnerHTML={ { __html: IconSearch } } />
                        }
                    </label>
                    { !!this.props.currentWorkflow && this.activePanel === TOOLS && this.props.isCheckedOut
                    && <Tools
                        activityTypes={ this.filterWorkflows || this.props.activityTypes }
                        activityTypeCategories={ this.props.activityTypeCategories }
                        isFilter={ this.filterWorkflows && this.filterWorkflows.length > 0 }
                        setSelected={ this.props.setSelected }
                    /> }
                    { this.activePanel === WORKFLOWS
                    && <WorkflowList
                        workflows={ this.filterWorkflows || this.props.workflows }
                        loadWorkflow={ this.props.loadWorkflow }
                    /> }
                </div>
                }
                { this.props.isOpened &&
                <div className={ styles.CloseButton }
                     onClick={ this.handleCloseClick }
                     dangerouslySetInnerHTML={ { __html: IconChevronLeft } }
                />
                }
                { !this.props.isOpened &&
                <div className={ styles.OpenButton }
                     onClick={ this.handleOpenClick }
                     dangerouslySetInnerHTML={ { __html: IconChevronRight } }
                />
                }
                { this.isShowMenu && this.renderMenu() }
            </>
        );
    }
}

export default observer(LeftPanel);