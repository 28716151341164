/**
 * Описание:
 * Заголовок главной страницы
 * mode - текущая страница ('new', 'open', 'index', 'workspace')
 * description - описание
 * goBack - метод перехода на главную страницу
 * goNew - метод перехода на страницу для создания workflow
 * goOpen - метод перехода на страницу открытия workflow
 * workflows - список workflow
 * */
import * as React from 'react';

import styles from './styles.module.scss';
import IconArrowLeft from 'assets/img/icons/arrow-left.svg';
import { action, observable, makeObservable } from "mobx";
import { observer } from 'mobx-react';
import langStore from "globalState/lang";

const blockWidth=251;
const blockHorizontalMargin=12;
const pageRatio=0.56;
const scrollbarWidth=13;
const docsLink='https://docs.simpleone.ru/display/SAPI/Workflow';

class WorkflowTitle extends React.Component {
    windowWidth = document.documentElement.clientWidth;

    constructor(props) {
        super(props);

        makeObservable(this, {
            windowWidth: observable,
            setWidth: action,
        });
    }

    componentDidMount() {
        window.addEventListener('resize', this.setWidth);
    }

    componentWillMount() {
        window.removeEventListener('resize', this.setWidth);
    }

    setWidth = () => {
        this.windowWidth = document.documentElement.clientWidth;
    };

    componentDidCatch(error, info) {
        console.error(error, info);
    }

    render(){
        let additionalStyle;
        let dynamicStyle;
        let aboutStyle;
        switch (this.props.mode) {
            case 'index':
                additionalStyle = styles.NavigationIndex;
                aboutStyle = { margin: 0 };
                break;
            case 'new':
                additionalStyle = styles.NavigationNew;
                aboutStyle = { margin: 0 };
                break;
            case 'open':
                additionalStyle = styles.NavigationOpen;
                //Расчёт ширины блока так, чтобы он точно соответствовал размерам содержимого списка (будет заменено на media запросы)
                // Количество блоков умещающихся при текущем разрешении, но не меньше 2 (увеличиваем до 2 при необходимости)
                const widthInBlocks = Math.max(Math.floor(this.windowWidth*pageRatio/(blockWidth+blockHorizontalMargin)),2);
                // Если рабочих процессов меньше, чем можно было бы разместить по ширине, то сокращаем ширину до количества процессов, но не меньше 2
                const correctedWidthInBlocks = (this.props.workflows && this.props.workflows.length < widthInBlocks) ? Math.max(this.props.workflows.length, 2) : widthInBlocks;
                //Расчёт ширины списка так, чтобы он точно соответствовал размерам содержимого без пустых областей (будет заменено на media запросы)
                const width = correctedWidthInBlocks *(blockWidth+blockHorizontalMargin)+scrollbarWidth+'px'; // точная ширина листа для умещающихся по ширине в нём блоков
                dynamicStyle = { width: width };
                break;
            default:
                additionalStyle = styles.NavigationIndex;
        }
        const { workflow_titles } = langStore.getTranslate();
        return (
            <>
                <div className={ `${ styles.Navigation } ${ additionalStyle }` } style={ dynamicStyle }>
                    { this.props.mode !== 'index' &&
                        <span className={ styles.GoBackPanel } >
                            <span className={ styles.GoBackIcon } dangerouslySetInnerHTML={ { __html: IconArrowLeft } } onClick={ this.props.goBack } />
                            <span className={ `${styles.NavLink} ${styles.GoBackLink}` } onClick={ this.props.goBack } >{ workflow_titles && workflow_titles.go_back }</span>
                        </span>
                    }
                    <a className={ `${styles.NavLink} ${styles.AboutNavLink}` } style={ aboutStyle } href={docsLink}>{ workflow_titles && workflow_titles.about }</a>
                </div>
                <div className={ styles.MainTitle }>{ workflow_titles && workflow_titles.heading }</div>
                <div className={ styles.WorkflowDescription }>{this.props.description}</div>
                { this.props.mode === 'index' &&
                    <>
                        <div className={ styles.MainPageInstructions }>{ workflow_titles && workflow_titles.new_text }<br/>{ workflow_titles && workflow_titles.open_text }</div>
                        <div className={ styles.MainPageActionsPanel }>
                            <div onClick={ this.props.goNew } className={ styles.MainPageActionsButtonPrimary } >{ workflow_titles && workflow_titles.button_new }</div>
                            <div onClick={ this.props.goOpen } className={ styles.MainPageActionsButtonDefault } >{ workflow_titles && workflow_titles.button_open }</div>
                        </div>
                    </>
                }
            </>
        );
    }
}

export default observer(WorkflowTitle);