import type {WidgetData} from 'globalState/widgets/widgetData';
import { IIndicatePresenceClass } from 'types/globalState/indicatePresence';

export enum WidgetTypes {
    String = 'string',
    Reference = 'reference',
    AuthFull = 'authfull',
    Attachment = 'attachment',
    GetList = 'getlist',
    DropDownMenu = 'dropdownmenu',
    DropDownMenuItems = 'dropdownmenuitems',
    AuthHeader = 'authheader',
    Card = 'card',
    Search = 'search',
    Results = 'results',
    Color = 'color',
    ColoredText = 'coloredtext',
    Button = 'button',
    Textarea = 'textarea',
    Checkbox = 'checkbox',
    Select = 'select',
    SideMenu = 'sidemenu',
    Codemirror = 'codemirror',
    Conditions = 'conditions',
    Template = 'template',
    List = 'list',
    Chart = 'chart',
    Date = 'date',
    Datetime = 'datetime',
    DaysOfWeek = 'daysofweek',
    Duration = 'duration',
    Modal = 'modal',
    HtmlEditor = 'htmleditor',
    Report = 'report',
    PreviewList = 'previewlist',
    Form = 'form',
    Breadcrumbs = 'breadcrumbs',
    ActivityFeed = 'activityfeed',
    Multiselect = 'multiselect',
    File = 'file',
    ListItems = 'listitems',
    Category = 'category',
    CategoryFlat = 'categoryflat',
    Rem = 'rem',
    RemForm = 'remform',
    StatesFlow = 'statesflow',
    Badge = 'badge',
    DurationInput = 'durationinput',
    Toggle = 'toggle',
    WysiwygEditor = 'wysiwygeditor',
    IndicatePresence = 'indicatepresence',
}

export enum DirectiveClass {
    DIRECTIVE_CLASS = 'simple-class',
    CLASS = 'class',
    CLASS_NAME = 'classname',
    CLASS_NAME_CC = 'className',
}

export interface WidgetProps extends TempProps {
    type: WidgetTypes;
    model?: string;
    widgetDataState: WidgetData;
}

export interface CommonWidgetProps {
    style?: string;
    type?: string;
    value?: any;
    'simple-class'?: string;
    class?: string;
    className?: string;
}

export interface FieldWidgetProps {
    name?: string;
    fieldinfo?: string;
    readOnly?: boolean;
}

export interface ButtonWidgetProps {
    buttontype?: string;
    buttonsize?: string;
}

export interface StatesFlowWidgetProps {
    states?: string;
}

export interface SideMenuWidgetProps {
    parentsdepth?: string;
    childrendepth?: string;
    includecategories?: string;
    includeitems?: string;
}

export interface FormWidgetProps {
    tablename?: string;
    sysid?: string;
    titlehide?: boolean;
    uiactions?: boolean;
    userscripts?: boolean;
    name?: string;
}

export interface CardWidgetProps {
    shortdescription?: string;
}

export interface AuthFullWidgetProps {
    isregistration?: boolean;
    usesourceurl?: boolean;
    isswitchlang?: boolean;
    isrememberme?: boolean;
    isresetpassword?: boolean;
    redirectto?: string;
    usernamevalidation?: string;
    passwordvalidation?: string;
}

export interface ChartWidgetProps {
    tablename?: string;
    charttype?: string;
}

export interface ListWidgetProps extends FieldWidgetProps {
    tablename?: string;
    tableid?: string;
    dependencymaplink?: string;
    isfixed?: boolean;
    cancreate?: boolean;
    canread?: boolean;
    condition?: string;
}

export interface ReferenceWidgetProps extends FieldWidgetProps {
    tablename?: string;
    tableid?: string;
    dependencymaplink?: string;
    isfixed?: boolean;
    cancreate?: boolean;
    canread?: boolean;
    condition?: string;
}

export interface ConditionsWidgetProps extends FieldWidgetProps {
    tablename?: string;
    tableid?: string;
}

export interface SelectWidgetProps extends FieldWidgetProps {
    radiobuttonsmode?: boolean;
    options?: string;
}

export interface MultiselectWidgetProps extends FieldWidgetProps {
    values?: string[];
    options?: string;
}

export interface CodemirrorWidgetProps extends FieldWidgetProps {
    options?: string;
}

export interface DateTimeWidgetProps extends FieldWidgetProps {
    begindate?: string;
    enddate?: string;
}

export interface DateWidgetProps extends FieldWidgetProps {
    begindate?: string;
    enddate?: string;
    validate?: string;
}

export interface ActivityFeedWidgetProps {
    tablename?: string;
    sysid?: string;
    config?: string;
    isalwaysopened?: boolean;
}

export interface PopupWidgetProps {
    doClose?: string;
    classnameactive?: string;
}

export interface ModalWidgetProps {
    doclose?: string;
    doClose?: string;
    isfullscreenmobile?: boolean;
    isshow?: boolean;
}

export interface AuthHeaderWidgetProps {
    profileurl?: string;
}

export interface ListItemsWidgetProps {
    condition?: string;
    tablename?: string;
    choiceconditionname?: string;
    dateconditionname?: string;
    perpage?: string;
    listview?: string;
    displaycolumnnumber?: string;
    itempage?: string;
    itemview?: string;
    fixedcondition?: boolean;
}

export interface CategoryWidgetProps {
    nodeid?: string;
    categorydescription?: string;
    categoryicon?: string;
    categorysubject?: string;
    itemdescription?: string;
    itemicon?: string;
    itemsubject?: string;
    hidedescription?: boolean;
}

export interface SearchWidgetProps {
    resultpage?: string;
    tsgroupid?: string;
    showtitle?: boolean;
    searchurl?: string;
    searchquery?: string;
    itempage?: string;
    itemview?: string;
}

export interface SearchResultsWidgetProps {
    searchquery?: string;
    istreeshown?: boolean;
    tsgroupid?: string;
    itempage?: string;
    itemview?: string;
}

export interface PreviewListWidgetProps {
    tablename?: string;
    elementcount?: number;
    subjectcolumn?: string;
    statecolumn?: string;
    rowstyle?: string;
    itempage?: string;
    itemview?: string;
    alllinktitle?: string;
    alllinkpage?: string;
    datecolumn?: string;
}

export interface RemWidgetProps {
    tablename?: string;
    recordid?: string;
    modelid?: string;
    isuserscripts?: boolean;
    isportal?: boolean;
    name?: string;
    parentformsectionmodel?: any;
}

export interface AttachmentWidgetProps {
    tablename?: string;
    recordid?: string;
    parentformsectionmodel?: any;
}

export interface RemFormWidgetProps {
    tablename?: string;

    recordid?: string;
    modelid?: string;
    titlehide?: boolean;
    isuserscripts?: boolean;
    isportal?: boolean;
    classname?: string;
    savebuttoncaption?: string;
    readonly?: boolean;
    name?: string;
    hidesavebutton?: boolean;
    parentformsectionmodel?: any;
}

export interface DurationInputWidgetProps {
    onchange?: string;
    maxvalue?: string;
    exclude?: string;
    alignright?: string;
}

export interface ToggleWidgetProps {
    boldtext?: boolean;
    disabled?: boolean;
    checked?: boolean;
    "event-change"?: string;
}

export type TempProps =
    CommonWidgetProps &
    FieldWidgetProps &
    ButtonWidgetProps &
    StatesFlowWidgetProps &
    SideMenuWidgetProps &
    FormWidgetProps &
    CardWidgetProps &
    AuthFullWidgetProps &
    ChartWidgetProps &
    ReferenceWidgetProps &
    ListWidgetProps &
    ConditionsWidgetProps &
    SelectWidgetProps &
    MultiselectWidgetProps &
    CodemirrorWidgetProps &
    DateTimeWidgetProps &
    DateWidgetProps &
    ActivityFeedWidgetProps &
    PopupWidgetProps &
    ModalWidgetProps &
    AuthHeaderWidgetProps &
    ListItemsWidgetProps &
    CategoryWidgetProps &
    SearchWidgetProps &
    SearchResultsWidgetProps &
    PreviewListWidgetProps &
    RemWidgetProps &
    AttachmentWidgetProps &
    RemFormWidgetProps &
    DurationInputWidgetProps &
    ToggleWidgetProps;

export interface ClassDirectiveAttr {
    className: string;
}

export interface FieldProps {
    sysColumnName?: string;
    fieldInfo?: string;
    readonly?: boolean;
}

export interface ButtonProps {
    buttonType?: string;
    buttonSize?: string;
}

export interface StatesFlowProps {
    states?: object;
}

export interface SideMenuProps {
    parentsDepth?: string;
    childrenDepth?: string;
    includeCategories?: string;
    includeItems?: string;
}

export interface FormProps {
    tableName?: string;
    sysId?: string;
    formName?: string;
    titleHide?: boolean;
    uiActions?: boolean;
    userScripts?: boolean;
}

export interface CardProps {
    shortDescription?: string;
}

export interface AuthFullProps {
    isRegistration?: boolean;
    useSourceUrl?: boolean;
    isSwitchLang?: boolean;
    isRememberMe?: boolean;
    isResetPassword?: boolean;
    redirectTo?: string;
    usernameValidation?: string;
    passwordValidation?: string;
}

export interface ChartProps {
    tableName?: string;
    chartType?: string;
}

export interface ListProps extends FieldProps {
    special: {
        table_name: string;
        table_id: string;
        dependency_map_link: string;
        reference_qualifier: {
            condition: string;
            is_fixed?: boolean;
        };
        can_create: boolean;
        can_read: boolean;
    };
}

export interface ReferenceProps extends FieldProps {
    special: {
        table_name: string;
        table_id: string;
        dependency_map_link: string;
        reference_qualifier: {
            condition: string;
            is_fixed?: boolean;
        };
        can_create: boolean;
        can_read: boolean;
    };
}

export interface ConditionsProps extends FieldProps {
    tableName?: string;
    tableId?: string;
}

export interface SelectProps extends FieldProps {
    special: {
        values?: any[];
    };
    radioButtonsMode?: boolean;
}

export interface MultiselectProps extends FieldProps {
    options: [];
    values?: string[];
}

export interface CodemirrorProps extends FieldProps {
    options: any;
}

export interface DateTimeProps extends FieldProps {
    beginDate: string;
    endDate: string;
}

export interface DateProps extends FieldProps {
    beginDate: string;
    endDate: string;
    validate?: object;
    pickertype: 'date';
}

export interface ActivityFeedProps {
    tableName?: string;
    sysId?: string;
    activities: any[];
    activityTypes: any[];
    historyFields: any[];
    hasNotForm: boolean;
    widgetId: string;
    config: any;
    journal?: string[];
    columns?: string[];
    title?: string[];
    classes?: string;
    isNotStylized: boolean;
    isAlwaysOpened?: boolean;
}

export interface PopupProps {
    doClose?: () => void;
    classNameActive?: string;
    modelItem?: string;
}

export interface ModalProps {
    doClose?: () => void;
    classNameActive?: string;
    isFullScreenMobile: boolean;
    isServicePortal: boolean;
    isShow: boolean;
}

export interface AuthHeaderProps {
    profileUrl?: string;
}

export interface ListItemsProps {
    condition?: string;
    tableName?: string;
    choiceConditionName?: string;
    dateConditionName?: string;
    perPage?: string;
    listView?: string;
    displayColumnNumber?: string;
    itemPage?: string;
    itemView?: string;
    fixedCondition?: boolean;
}

export interface CategoryProps {
    nodeId?: string;
    categoryDescription?: string;
    categoryIcon?: string;
    categorySubject?: string;
    itemDescription?: string;
    itemIcon?: string;
    itemSubject?: string;
    hideDescription?: boolean;
}

export interface SearchProps {
    resultPage?: string;
    tsGroupId?: string;
    showTitle?: boolean;
    searchUrl?: string;
    searchQuery?: string;
    itemPage?: string;
    itemView?: string;
}

export interface SearchResultsProps {
    searchQuery?: string;
    isTreeShown?: boolean;
    tsGroupId?: string;
    itemPage?: string;
    itemView?: string;
}

export interface PreviewListProps {
    tableName?: string;
    elementCount?: number;
    subjectColumn?: string;
    stateColumn?: string;
    rowStyle?: string;
    itemPage?: string;
    itemView?: string;
    allLinkTitle?: string;
    allLinkPage?: string;
    dateColumn?: string;
}

export interface RemProps {
    tableName?: string;
    recordId?: string;
    modelId?: string;
    isUserScripts?: boolean;
    isPortal?: boolean;
    formName?: string;
    parentFormSectionModel?: any;
}

export interface AttachmentProps {
    tableName?: string;
    recordId?: string;
    parentFormSectionModel?: any;
}

export interface RemFormProps {
    tableName?: string;
    recordId?: string;
    modelId?: string;
    titleHide?: boolean;
    isUserScripts?: boolean;
    isPortal?: boolean;
    className?: string;
    saveButtonCaption?: string;
    readOnly?: boolean;
    formName?: string;
    hideSaveButton?: boolean;
    parentFormSectionModel?: any;
}

export interface DurationInputProps {
    onChange?: (value: any) => void;
    maxValue?: string;
    exclude?: string;
    alignRight?: string;
}

export interface ToggleProps {
    onChange?: (value: any) => void;
    boldText?: boolean;
    disabled?: boolean;
    checked?: boolean;
}

interface CustomProps {
    value?: any;
    term?: string;
    widgetId: string;
}

export type AttrForWidget =
    CustomProps &
    ButtonProps &
    StatesFlowProps &
    SideMenuProps &
    FormProps &
    CardProps &
    AuthFullProps &
    ChartProps &
    ListProps &
    ReferenceProps &
    ConditionsProps &
    SelectProps &
    MultiselectProps &
    CodemirrorProps &
    DateTimeProps &
    DateProps &
    ActivityFeedProps &
    PopupProps &
    ModalProps &
    AuthHeaderProps &
    ListItemsProps &
    CategoryWidgetProps &
    SearchWidgetProps &
    SearchResultsWidgetProps &
    PreviewListWidgetProps &
    RemProps &
    AttachmentProps &
    RemFormProps &
    ToggleProps &
    DurationInputProps;

export interface ActivityFeedParams {
    table_name: string;
    record_id: string;
}

export interface SideMenuElement {
    url: string;
    type: number;
    id: string;
    title: string;
    children?: SideMenuElement;
    nodeId: string;
}

export interface MatchParams {
    params?: {
        portalSuffix?: string;
        pageTemplatePathName?: string;
    };
}

export interface IIndicatePresenceWidgetProps {
    tablename?: string;
    recordid?: string;
}

export interface IIndicatePresenceProps {
    tableName?: string;
    sysId?: string;
    userSysId?: string;
    indicatePresence?: IIndicatePresenceClass;
}
