import * as React from 'react';
import InfoMessages from 'components/infoMessages';
import styles from './styles.module.scss';
import ErrorBoundary from 'components/errorBoundary';

export default function Layout({ children, ...restParams }) {
    return (
        <span>
            <div className={ styles.main }>
                <ErrorBoundary>
                    { React.cloneElement(children, restParams) }
                </ErrorBoundary>
            </div>
            <ErrorBoundary>
                <InfoMessages />
            </ErrorBoundary>
        </span>
    );
}
