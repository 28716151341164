import * as React from 'react';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import HistoryItem from 'components/activityFeed/historyItem';
import CommentItem from 'components/activityFeed/comment';
import ActivityTabs from 'components/activityFeed/activityTab';
import * as _ from 'lodash';
import { ActivityFeedState } from 'globalState/activityFeed';
import ActivityFeedWindow from 'components/activityFeed/activityFeedWindow';
import MenuSettings from 'components/activityFeed/menuSettings';
import { observable, makeObservable } from 'mobx';
import Heading from 'components/heading';
import {
    Activity,
    ActivityFeedProps,
    ActivityStateProps,
    ActivityType,
    ActivityUrlParams,
} from 'types/components/activityFeed';
import IconChevronR from 'assets/img/icons/chevron-right.svg';
import IconChevronD from 'assets/img/icons/chevron-down.svg';
import IconMessage from 'assets/img/icons/message-circle.svg';
import CommentTabs from 'components/activityFeed/commentTabs';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { withRouter } from 'react-router-dom';
import { WIDGET_NAMES } from 'constants/widgets';
import { getUrlParams } from 'helpers/data';
import { getDateValueByString, getTranslateDateFormat, getUserTimezone } from 'helpers/getUserTimeZone';
import { savePreference } from 'actions/preferences';
import moment from 'moment-timezone';
import { globalEventEmitter } from 'lib/EventEmitter';
import { getClassNameAF, getFilteredActivities, getFilteredHistoryFields } from 'helpers/activityFeed';
import activityFeeds from 'globalState/activityFeeds';
import formsState from 'globalState/forms';
import EventBusState from 'globalState/eventBus';
import { eventType } from 'constants/eventBusTypes';

/**
 * Описание: компонент ActivityFeed
 * Параметры:
 * tableName: {type: string} - имя таблицы к какой будем делать запрос
 * sysId: {type: string} - id значения по которому будем делать запрос
 * activities: {type: array} - список активной
 * activityTypes: {type: array} - список баблов
 * historyFields: {type: array} - поля истории
 * mode: {type: string} - режим вызова
 */
export class ActivityFeed extends React.Component<ActivityFeedProps> {
    activityState: ActivityStateProps;
    isShowSettings = false;
    refMore = React.createRef<HTMLDivElement>();
    refContainer = React.createRef<HTMLDivElement>();
    containerHasScroll = false;

    constructor(props: ActivityFeedProps) {
        super(props);

        makeObservable(this, {
            isShowSettings: observable,
            containerHasScroll: observable,
        });

        this.activityState = new ActivityFeedState();
        this.activityState.clear();
        this.activityState.setHasNotForm(!!props.hasNotForm);
        this.activityState.setJournalFilter(props.journal || []);
        this.activityState.setColumnFilter(props.columns || []);
        const params = props.match.params;
        const { location } = props;
        let locationParams: ActivityUrlParams | null | {} = null;
        if (location && location.search) {
            locationParams = getUrlParams(location.search);
        }
        this.activityState.setWidgetId(props.widgetId);
        if (props.tableName && props.tableName !== 'undefined') {
            this.activityState.setTableName(props.tableName);
            const sysId = props.sysId || props.recordId;
            if (sysId && sysId !== 'undefined') {
                this.activityState.setSysId(sysId);
            }
        } else if (params && params.table_name) {
            this.activityState.setTableName(params.table_name);
            if (params && (params.number || params.record_id)) {
                this.activityState.setSysId(params.number ? params.number : '');
            }
        } else if (locationParams) {
            const locParams = (locationParams as ActivityUrlParams);
            if (locParams.table_name) {
                this.activityState.setTableName(locParams.table_name);
                if (locParams.record_id || locParams.sys_id) {
                    this.activityState.setSysId(locParams.record_id || locParams.sys_id);
                }
            }
        }
        formsState.addWidget(WIDGET_NAMES.activityFeed, this.activityState);
        this.activityState.fetchResponseData();
        globalEventEmitter.subscribe([ 'impersonate', 'deImpersonate' ], this.handleImpersonate);
    }

    componentDidMount() {
        activityFeeds.addItem(this.activityState);
        const form = formsState.getDynamicFormBySysId(this.activityState.getTableName(), this.activityState.getSysId());
        if (form) {
            this.activityState.setActivityTypesFromFields(form.sections);
        }
    }

    componentDidUpdate(prevProps: ActivityFeedProps) {
        const prevParams = prevProps.match.params;
        const { params } = this.props.match;
        const { location, hasNotForm, journal, columns } = this.props;
        let locationParams: ActivityUrlParams | null | {} = null;
        if (location && location.search) {
            locationParams = getUrlParams(location.search);
        }
        const { location: prevLocation } = prevProps;
        let prevLocationParams: ActivityUrlParams | null | {} = null;
        if (prevLocation && prevLocation.search) {
            prevLocationParams = getUrlParams(prevLocation.search);
        }
        const locParams = locationParams as ActivityUrlParams;
        const { sysId, tableName, recordId } = this.props;
        let isUpdate = false;
        if (sysId && sysId !== prevProps.sysId && sysId !== 'undefined') {
            this.activityState.setSysId(sysId);
            isUpdate = true;
        } else if (recordId && recordId !== prevProps.recordId && recordId !== 'undefined') {
            this.activityState.setSysId(recordId);
            isUpdate = true;
        } else if (params.number && params.number !== prevParams.number) {
            this.activityState.setSysId(params.number);
            isUpdate = true;
        } else if (locParams && prevLocationParams && !_.isEqual(locParams, prevLocationParams)
            && (locParams.record_id || locParams.sys_id)) {
            this.activityState.setSysId(locParams.record_id || locParams.sys_id);
            isUpdate = true;
        }
        if (tableName && tableName !== prevProps.tableName && tableName !== 'undefined') {
            this.activityState.setTableName(tableName);
            isUpdate = true;
        } else if (params.table_name && params.table_name !== prevParams.table_name) {
            this.activityState.setTableName(params.table_name);
            isUpdate = true;
        } else if (locParams && prevLocationParams && !_.isEqual(locParams, prevLocationParams)
            && locParams.table_name) {
            this.activityState.setTableName(locParams.table_name);
            isUpdate = true;
        }
        if (hasNotForm !== prevProps.hasNotForm) {
            this.activityState.setHasNotForm(!!hasNotForm);
        }
        if (!_.isEqual(journal, prevProps.journal)) {
            this.activityState.setJournalFilter(journal || []);
        }
        if (!_.isEqual(columns, prevProps.columns)) {
            this.activityState.setColumnFilter(columns || []);
        }
        if (isUpdate) {
            this.activityState.fetchResponseData();
        }
    }

    componentWillUnmount() {
        activityFeeds.removeItem(this.activityState);
        EventBusState.deleteEvent(eventType.AF_COMMENT_TAB_CHANGED);
    }

    handleImpersonate = () => {
        this.activityState.fetchResponseData();
    };

    handleToggleShowSettings = () => {
        this.isShowSettings = !this.isShowSettings;
    };

    handleToggleActivityFeed = () => {
        const { isAlwaysOpened } = this.props;
        if (isAlwaysOpened) return;

        const isFolded = !this.activityState.getIsFolded();
        this.activityState.setIsFolded(isFolded);
        savePreference('activity_feed.is_folded', isFolded.toString());
    };

    getGroupActivities = () => {
        const filteredHistoryFields = getFilteredHistoryFields(this.activityState);
        const hasHistory = !_.isEmpty(filteredHistoryFields);
        const activities = hasHistory ? this.activityState.getActivities()
            : _.filter(this.activityState.getActivities(), activity => !('operation' in activity.content && !hasHistory));
        if (_.isEmpty(activities)) {
            return [];
        }
        const filteredActivities = getFilteredActivities(activities, this.activityState);
        const children = _.groupBy(_.filter(filteredActivities, (activity: Activity) => activity.parent_id), (activity: Activity) => activity.parent_id);
        const parents = _.filter(filteredActivities, (activity: Activity) => !activity.parent_id);
        const resultActivities = _.map(parents, (activity: Activity) => {
            if (children[activity.sys_id] && children[activity.sys_id].length) {
                return {
                    ...activity,
                    children: children[activity.sys_id],
                };
            }
            const newDateGroup = getDateValueByString(activity.timestamp, getUserTimezone(), getTranslateDateFormat());
            return {
                ...activity,
                date_group: newDateGroup,
            };
        });
        return _.groupBy(resultActivities, (activity: Activity) => activity.date_group);
    };

    isShowActivityType = (activityType) => {
        const activeTab = this.activityState.getActiveTab();
        return activeTab === 'all' || activeTab === activityType.name;
    };

    isShowGroup = (activities) => {
        const activityTypes = this.activityState.getActivityTypes();
        const activeTab = this.activityState.getActiveTab();
        const findActivityTypes = _.filter(activityTypes, (activityType: ActivityType) => {
            const isActivityType = activeTab === activityType.name && _.find(activities,
                (activity: Activity) => activity.type_id === activityType.sys_id);
            return activeTab === 'all' || isActivityType;
        });
        return findActivityTypes.length > 0;
    };

    getItems = (groupActivities) => {
        const { widgetId, isNotStylized, classes } = this.props;
        const activityTypes = this.activityState.getActivityTypes();
        const dictionary = this.activityState.getDictionary();

        const items = _.map(groupActivities, (item: Activity) => {
            const activityType = _.find(activityTypes, (activityType: ActivityType) => activityType.sys_id === item.type_id);
            if (!activityType || !this.isShowActivityType(activityType)) {
                return null;
            }
            const style: React.CSSProperties = {
                color: activityType.title_color,
                backgroundColor: activityType.title_background_color,
            };
            if ('operation' in item.content) {
                return (
                    <HistoryItem
                        key={ `history${ item.sys_id }` }
                        item={ item }
                        title={ activityType.title }
                        dictionary={ dictionary }
                        style={ style }
                        activityState={ this.activityState }
                        isNotStylized={ isNotStylized }
                        classes={ classes }
                    />
                );
            }
            if ('message' in item.content) {
                return (
                    <CommentItem
                        key={ `additional_comment${ item.sys_id }` }
                        item={ item }
                        childrenComments={ [] }
                        tableName={ this.activityState.getTableName() }
                        recordId={ this.activityState.getSysId() }
                        widgetId={ widgetId }
                        dictionary={ dictionary }
                        title={ activityType.title }
                        style={ style }
                        isNotStylized={ isNotStylized }
                        classes={ classes }
                    />
                );
            }
            return null;
        });
        return items.filter(item => !_.isEmpty(item));
    };

    getGroup = (groupKey, groupActivities) => {
        if (!this.isShowGroup(groupActivities)) {
            return null;
        }
        const { isNotStylized, classes } = this.props;
        const todayValue = getDateValueByString(`${ moment().format() }`, getUserTimezone(), getTranslateDateFormat());

        return (
            <div key={ groupKey }>
                { groupKey !== todayValue && (
                    <div
                        className={ getClassNameAF(isNotStylized, styles.GroupHeader, classes?.GroupHeader) }
                        data-test={ ATTRIBUTES.activityFeedGroupHeader }
                    >
                        <span>{ groupKey }</span>
                    </div>
                ) }
                { this.getItems(groupActivities) }
            </div>
        );
    };

    getActivities = () => {
        const activities = this.activityState.getActivities();
        const dictionary = this.activityState.getDictionary();
        const { isNotStylized, classes } = this.props;
        const emptyFilter = (
            <div className={ getClassNameAF(isNotStylized, styles.NoActivities, classes?.NoActivities) }>
                <div
                    dangerouslySetInnerHTML={ { __html: IconMessage } }
                    className={ getClassNameAF(isNotStylized, styles.IconNoActivities, classes?.IconNoActivities) }
                />
                <span>{ dictionary && dictionary.form && dictionary.form.no_activity }</span>
            </div>
        );

        if (_.isEmpty(activities)) {
            return emptyFilter;
        }

        const groupedActivities = this.getGroupActivities();
        const htmlGroups = _.map(groupedActivities, (item, key) => {
            return this.getGroup(key, item);
        });
        const filteredGroups = _.filter(htmlGroups, group => !!group);
        if (_.isEmpty(filteredGroups)) {
            return emptyFilter;
        }
        return filteredGroups;
    };

    isModal = () => {
        const { mode } = this.props;
        return mode === 'modal';
    };

    renderData = () => {
        const { widgetId, isNotStylized, classes } = this.props;
        if (this.isModal() && this.isShowSettings) {
            return (
                <MenuSettings
                    isShowSettings={ this.isShowSettings }
                    activityState={ this.activityState }
                    isModal={ this.isModal() }
                    widgetId={ widgetId }
                    tableName={ this.activityState.getTableName() }
                    recordId={ this.activityState.getSysId() }
                    onToggleShowSettings={ this.handleToggleShowSettings }
                    dictionary={ this.activityState.getDictionary() }
                    refParent={ this.refMore }
                    isNotStylized={ isNotStylized }
                    classes={ classes }
                />
            );
        }

        return this.getActivities();
    };

    renderHeading = () => {
        const dictionary = this.activityState.getDictionary();
        const filteredActivities = getFilteredActivities(this.activityState.getActivities(), this.activityState);
        const countItems = !_.isEmpty(filteredActivities) ? filteredActivities.length.toString() : '0';
        if (this.isModal()) {
            return null;
        }
        const { title, classes, isNotStylized, isAlwaysOpened } = this.props;
        return (
            <div
                className={ `${getClassNameAF(isNotStylized, styles.Heading, classes?.Heading)} ${isAlwaysOpened ? styles.defaultCursor : ''}` }
                onClick={ this.handleToggleActivityFeed }
                data-test={ ATTRIBUTES.activityFeedHead }
            >
                <Heading
                    noMargin
                    size={ 'h4' }
                    data-test={ ATTRIBUTES.activityFeedHeadH4 }
                    isNotStylized={ isNotStylized }
                >
                    { title || dictionary.widget_title } ({ countItems })
                </Heading>
                { !isAlwaysOpened && <div
                    className={ getClassNameAF(isNotStylized, styles.ChevronActivityFeed, classes?.ChevronActivityFeed) }
                    data-test={ ATTRIBUTES.activityFeedChevron }
                    dangerouslySetInnerHTML={ { __html: this.activityState.getIsFolded() ? IconChevronR : IconChevronD } }
                />}
            </div>
        );
    };

    renderActivityFeed = () => {
        const { widgetId, isNotStylized, classes, isAlwaysOpened } = this.props;

        return (
            <div
                className={ getClassNameAF(isNotStylized, styles.ActivityFeed, classes?.ActivityFeed) }
                data-test={ ATTRIBUTES.activityFeed }
            >
                { this.renderHeading() }
                <CommentTabs
                    widgetId={ widgetId }
                    tableName={ this.activityState.getTableName() }
                    recordId={ this.activityState.getSysId() }
                    activityState={ this.activityState }
                    isNotStylized={ isNotStylized }
                    classes={ classes }
                />
                {
                    this.activityState.getIsFolded() && !this.isModal() && !isAlwaysOpened ? null : (
                        <>
                            <div className={ getClassNameAF(isNotStylized, styles.Separator, classes?.Separator) } />
                            <ActivityTabs
                                activityState={ this.activityState }
                                isModal={ this.isModal() }
                                onToggleShowSettings={ this.handleToggleShowSettings }
                                isShowSettings={ this.isShowSettings }
                                widgetId={ widgetId }
                                tableName={ this.activityState.getTableName() }
                                recordId={ this.activityState.getSysId() }
                                dictionary={ this.activityState.getDictionary() }
                                refParent={ this.refMore }
                                isNotStylized={ isNotStylized }
                                classes={ classes }
                            />
                            <div
                                className={ getClassNameAF(isNotStylized, styles.ActivitiesContainerWrap, classes?.ActivitiesContainerWrap) }
                                data-test={ ATTRIBUTES.activityFeedActivitiesContainer }
                            >
                                <div
                                    ref={ this.refContainer }
                                    onScroll={ this.checkContainerScroll }
                                    className={ getClassNameAF(isNotStylized, styles.ActivitiesContainer, classes?.ActivitiesContainer) }
                                >
                                    { this.renderData() }
                                </div>
                                { this.containerHasScroll && <div
                                    className={ getClassNameAF(isNotStylized, styles.ActivitiesContainerShadow, classes?.ActivitiesContainerShadow) }
                                /> }
                            </div>
                        </>
                    )
                }
            </div>
        );
    };

    renderSkeleton = () => {
        const { classes, isNotStylized } = this.props;
        return (
            <div
                className={ getClassNameAF(isNotStylized, `${ styles.ActivityFeed } ${ styles.Skeleton }`, `${ classes?.ActivityFeed } ${ classes?.Skeleton }`) }
            >
                <div className={ getClassNameAF(isNotStylized, styles.SkeletonHeading, classes?.SkeletonHeading) } />
                <div className={ getClassNameAF(isNotStylized, styles.SkeletonTabs, classes?.SkeletonTabs) }>
                    <div className={ getClassNameAF(isNotStylized, styles.SkeletonTabsArea, classes?.SkeletonTabsArea) } />
                    <div className={ getClassNameAF(isNotStylized, styles.SkeletonTabsButton, classes?.SkeletonTabsButton) } />
                </div>
                <div className={ getClassNameAF(isNotStylized, styles.SkeletonTags, classes?.SkeletonTags) }>
                    { _.times(4, (i) => (<div
                        key={ i }
                        className={ getClassNameAF(isNotStylized, styles.SkeletonTagsItem, classes?.SkeletonTagsItem) }
                    />)) }
                </div>
                <div className={ getClassNameAF(isNotStylized, styles.SkeletonAct, classes?.SkeletonAct) }>
                    { _.times(4, (i) => (
                        <div key={ i } className={ getClassNameAF(isNotStylized, styles.SkeletonActItem, classes?.SkeletonActItem) }>
                            <div className={ getClassNameAF(isNotStylized, styles.SkeletonActHeader, classes?.SkeletonActHeader) }>
                                <div className={ getClassNameAF(isNotStylized, styles.SkeletonActUserPic, classes?.SkeletonActUserPic) } />
                                <div className={ getClassNameAF(isNotStylized, styles.SkeletonActUserName, classes?.SkeletonActUserName) } />
                                <div className={ getClassNameAF(isNotStylized, styles.SkeletonActType, classes?.SkeletonActType) } />
                            </div>
                            <div className={ getClassNameAF(isNotStylized, styles.SkeletonActCont, classes?.SkeletonActCont) }>
                                { _.times(4, (i) => (<div
                                    key={ i }
                                    className={ getClassNameAF(isNotStylized, styles.SkeletonActInf, classes?.SkeletonActInf) }
                                />)) }
                            </div>
                        </div>
                    )) }
                </div>
            </div>
        );
    };

    checkContainerScroll = () => {
        const { current: containerEl } = this.refContainer;
        if (!containerEl) return;
        this.containerHasScroll = containerEl.clientHeight < containerEl.scrollHeight && containerEl.scrollTop > 0;
    };

    render() {
        if (!this.activityState.getSysId()) {
            return null;
        }
        const { classes, isNotStylized } = this.props;
        const dictionary = this.activityState.getDictionary();
        if (this.isModal()) {
            return (
                <ActivityFeedWindow
                    className={ getClassNameAF(isNotStylized, styles.Modal, classes?.Modal) }
                    isShow={ true }
                    title={ dictionary.widget_title }
                    isNotStylized={ isNotStylized }
                    classes={ classes }
                >
                    { this.activityState.getIsFetchRun() ? this.renderSkeleton() : this.renderActivityFeed() }
                </ActivityFeedWindow>
            );
        }
        return this.activityState.getIsFetchRun() ? this.renderSkeleton() : this.renderActivityFeed();
    }
}

export default withRouter(observer(ActivityFeed));
