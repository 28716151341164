import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { injectData } from 'helpers/widget';

/**
 * Описание: компонент ChildrenComponent
 * Параметры:
 * data: {type: object} - данные с переменными из хранилица
 * options: {type: object} - данные с опциями из хранилица
 * children: {type: string | object | array | }
 */
class ChildrenComponent extends React.Component {
    getObjectValue = (object) => {
        if (object.link) {
            return (
                <div>
                    <Link to={ object.link }>
                        { object.display_value }
                    </Link>
                </div>
            );
        }
        return object.display_value || object;
    };

    getValue = (data, key, children, replaceRegex) => {
        if (Array.isArray(data[key])) {
            return data[key].map(v => {
                if (typeof v === 'object' && v !== null) {
                    return this.getObjectValue(v);
                }
                return v;
            });
        }
        if (typeof data[key] === 'object' && data[key] !== null) {
            return this.getObjectValue(data[key]);
        }
        return children.replace(replaceRegex, data[key]);
    };

    replaceOptions = (text) => {
        const { options, children } = this.props;
        let result = text;
        for (const key in options) {
            const replaceRegex = new RegExp('{\\s?options\\.' + key + '\\s?}', 'g');
            const match = children.match(replaceRegex);
            if (options.hasOwnProperty(key) && match && match[0]) {
                result = typeof options[key] === 'object' || Array.isArray(options[key]) ? options[key] : children.replace(replaceRegex, options[key]);
                break;
            }
        }
        return result;
    };

    replaceText = () => {
        const { data, children } = this.props;
        let finalText = '';
        if (!data) {
            return finalText;
        }
        const replaceRegex = new RegExp('{\\s?data\\.[\\w\\.]+\\s?}', 'g');
        const searchData = children.search(replaceRegex);
        const replaceOptRegex = new RegExp('{\\s?options\\.\\w+\\s?}', 'g');
        const searchOptions = children.search(replaceOptRegex);
        if (searchData === -1 && searchOptions === -1) {
            return children;
        }
        if (searchData !== -1) {
            finalText = injectData(data, children);
        }
        if (searchOptions !== -1) {
            finalText = this.replaceOptions(finalText);
        }
        return finalText;
    };

    render() {
        const { children } = this.props;
        if (!children) {
            return null;
        }
        return this.replaceText();
    }
}

export default observer(ChildrenComponent);
