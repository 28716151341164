import { HandleResponse } from 'types/actions/main';

export interface RecordPopupProps {
    parent?: HTMLElement | null;
    isShow: boolean;
    essence: string;
    sys_id: string;
    isServicePortal?: boolean;
    isWindow?: boolean;
    onToggle: (evt: any) => void;
    freezeScreen: boolean;
    refColumnId?: string;
}

export interface RecordPopupParams {
    reference_column_id?: string;
}

export interface RecordPopupResponse extends HandleResponse {
    data: {
        sections: Section[];
        form_labels: [];
        page_name: string;
        table_id: string;
        record_id: string;
        form_id: string;
        form_title: string;
        entity_name: string;
        url_table_name: string;
        delegates_info: DelegationType;
    };
}

export interface DelegationType {
    title: string;
    delegates: Delegate[];
}

export interface Delegate {
    record_table_name: string;
    record_id: string;
    display_name: string;
}

export interface Section {
    elements: Element[];
    index: number;
    name: string;
    service_name: string;
}

export interface Element {
    index: number;
    column_id: string;
    name: string;
    column_type: FieldTypes;
    is_mandatory: boolean;
    read_only: boolean;
    value: any;
    dependent_on_column: null | string;
    extra_attributes: null | string;
}

export enum FieldTypes {
    String = 'string',
    Varchar = 'varchar',
    Password = 'password',
    Integer = 'integer',
    SmallInteger = 'smallinteger',
    Biginteger = 'biginteger',
    Float = 'float',
    Decimal = 'decimal',
    PercentComplete = 'percent_complete',
    Char = 'char',
    Json = 'json',
    Phone = 'phone',
    EnumFormSplitElementType = 'enum_form_split_element_type',
    EncryptedPassword = 'encrypted_password',
    Text = 'text',
    TranslatedText = 'translated_text',
    JournalInput = 'journal_input',
    Date = 'date',
    Datetime = 'datetime',
    DatetimeSpecific = 'datetime_specific',
    Time = 'time',
    Timestamp = 'timestamp',
    Reference = 'reference',
    RecordClass = 'record_class',
    Image = 'image',
    Choice = 'choice',
    Boolean = 'boolean',
    DaysOfWeek = 'days_of_week',
    Url = 'url',
    List = 'list',
    Script = 'script',
    Id = 'id',
    Html = 'html',
    Duration = 'duration',
    Conditions = 'conditions',
    FieldName = 'field_name',
    Template = 'template',
    Color = 'color',
    Widget = 'widget',
}
