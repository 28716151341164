import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import Tooltip from 'components/tooltip';
import styles from './styles.module.scss';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { CellDescriptionProps } from 'types/components/table/cellDescription/index';

/**
 * Описание: сокращённое описание и тултип для ячеек таблицы
 * Праметры:
 * gradientClassName: {required: true,  type: string} - цсс класс, описывает цвет для градиента, скрывающего текст.
 * tooltipText: {required: true,  type: string/html} - текст для тултипа
 * columnName: {type: string} - имя колонки
 * hideTooltip: {type: boolean} - флаг отображения тултипа
 * isMobile
 * */
class CellDescriptionComponent extends React.Component<CellDescriptionProps> {
    contentRef: React.RefObject<HTMLDivElement> = React.createRef();
    containerRef: React.RefObject<HTMLDivElement> = React.createRef();
    isShowPopup = false;
    hasOverflow = false;
    timeout: NodeJS.Timeout;

    constructor(props: CellDescriptionProps) {
        super(props);

        makeObservable(this, {
            isShowPopup: observable,
            hasOverflow: observable,
        });
    }

    componentDidMount() {
        this.checkOverflow();
        window.addEventListener('resize', this.checkOverflow);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkOverflow);
    }

    componentDidUpdate() {
        this.checkOverflow();
    }

    hidePopup = () => {
        clearTimeout(this.timeout);
        this.isShowPopup = false;
    };

    showPopup = () => {
        if (this.props.hideTooltip) return;
        this.timeout = setTimeout(() => {
            this.isShowPopup = true;
        }, 300);
    };

    checkOverflow = () => {
        if (this.contentRef.current === null || this.containerRef.current === null) {
            return;
        }
        const contentRect = this.contentRef.current.getBoundingClientRect();
        const containerRect = this.containerRef.current.getBoundingClientRect();
        this.hasOverflow = containerRect.height > contentRect.height;
    };

    cutText = (text, cutLength) => {
        if (typeof text === 'string') {
            let textCut = text.slice(0, cutLength);
            if (text.length > textCut.length) {
                textCut = textCut.trim() + '...';
            }
            return textCut;
        }
        else {
            return text;
        }
    };

    render() {
        const contAddClass = this.props.columnName === 'subject' ? styles.subject : '';

        return (
            <div className={ `${ styles.content } ${ contAddClass }` } ref={ this.contentRef } onMouseLeave={ this.hidePopup } onMouseEnter={ this.showPopup } data-test={ ATTRIBUTES.tableCellDescr }>
                <div className={ styles.container } ref={ this.containerRef }>
                    { this.props.children }
                </div>
                { this.hasOverflow && <div className={ `${ styles.gradient } ${ this.props.gradientClassName }` } /> }
                {
                    this.hasOverflow && !this.props.isMobile && this.isShowPopup &&(
                        <Tooltip
                            onClose={ this.hidePopup }
                            parent={ this.contentRef.current }
                        >
                            { this.cutText(this.props.tooltipText, 500) }
                        </Tooltip>
                    )
                }
            </div>
        );
    }
}

export default observer(CellDescriptionComponent);
