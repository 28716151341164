import * as React from 'react';
import Container from 'components/container';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import apiRequest from 'lib/apiRequest';
import Dropdown from './dropdown';
import langStore from 'globalState/lang';
import ErrorWrapperHoc from 'helpers/hoc/errorWrapperHOC';


class UnTranslatePage extends React.Component {
    showData = [];
    data = [];


    constructor(props) {
        super(props);

        makeObservable(this, {
            showData: observable,
            data: observable,
        });

        this.fetchData().catch(this.errorFetchData);
    }


    fetchData = async () => {
        const response = await new apiRequest('GET ' + '/translation/untranslated-records').send();
        if (response.status === 'OK') {
            this.data = response.getData();
            this.addRecords(this.data.records);
        }
    };

    renderResults() {
        return (
            <div>
                {
                    this.showData.map((item, index) => {
                        return (
                            <div key={index}>
                                <div className={styles.item} ref={`element${item.table_name}`}>
                                    <Dropdown item = { item } />
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    addRecords = (item) => {
        if(Array.isArray(item))
            this.showData= Object.assign([], item);
        else {
           this.showData= [];
           this.showData.push(item);
        }
    };

    renderMenu() {
        if(!this.data.records) return null;
        const { table, show_all } = langStore.getTranslate();
        return (
            <div>
                <header>{ table }</header>
                <div>
                    {
                        this.data.records.map((item, index) => {
                            return (
                                <div key={index} className={styles.item}>
                                    <div className={styles.linkWrap}>
                                        <div className={styles.link} onClick={ () => this.addRecords(item)}>
                                            <div className={styles.label}>
                                                {item.table_name} ({item.records.length})
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                    <div className={styles.linkWrap}>
                        <div className={styles.link} onClick={ () => this.addRecords(this.data.records)}>
                            <div className={styles.label}>
                               { show_all }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <Container>
                <div className={styles.searchMain}>
                    <div className={ styles.resultsMain }>
                        <div>{ this.renderResults() }</div>
                    </div>
                    <div className={ styles.menu }>{ this.renderMenu() }</div>
                </div>
            </Container>
        );
    }
}


export default ErrorWrapperHoc(observer(UnTranslatePage));
