export const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_TIME_FORMAT = 'HH:mm:ss';
export const DEFAULT_TIMESTAMP_FORMAT = 'X';
export const DEFAULT_DATE_REGEXP = /\d{4}-\d{2}-\d{2}/;
export const DEFAULT_DATE_TIME_REGEXP = /\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}/;
export const DEFAULT_TIME_REGEXP = /\d{2}:\d{2}:\d{2}/;
export const DEFAULT_TIMESTAMP_REGEXP = /\d{1,13}/;

export const SP_DATE_TIME_FORMAT = 'D MMMM YYYY, HH:mm';
