import BaseFormFieldModel from './BaseFormFieldModel';
import { observable, makeObservable } from 'mobx';
import FormsState from 'globalState/forms';
import { conditionStateClass } from "globalState/conditions/condition";
import { fetchDotWalkList } from "actions/conditions";
import { isChanged } from 'helpers/form';

export default class ConditionsModel extends BaseFormFieldModel {
    columnType = 'conditions';

    dependentOnColumn;

    data = [];

    conditionState = {};

    activeConditionFilter = null;

    isEmpty = false;

    isCompact = true;

    isPopupOpened = false;

    onChange;

    constructor(data, parentFormSectionModel) {
        super(data, parentFormSectionModel);

        makeObservable(this, {
            dependentOnColumn: observable,
            data: observable,
            conditionState: observable,
            activeConditionFilter: observable,
            isEmpty: observable,
            isCompact: observable,
            isPopupOpened: observable,
        });

        this.mergeData(data);
    }

    mergeData(data, exclude){
        super.mergeData(data, exclude);
        this.tableId = data.tableId || this.getDependentOnColumn();
        if (this.form && this.form.tableName){
            this.sysTableName = this.form.tableName;
        }
    }

    getDependentOnColumn() {
        const tablesComponent = FormsState.getField(this.dependentOnColumn);
        if (tablesComponent) {
            if (Array.isArray(tablesComponent.value) && tablesComponent.value.length) {
                return tablesComponent.value[0].database_value;
            }
            return tablesComponent.databaseValue;
        }
        return null;
    }

    // метод проверять есть ли в extra_attributes source_table
    getTableId = () => {
        const { extra_attributes } = this;

        if (extra_attributes && extra_attributes.includes('source_table')) {
            return extra_attributes.replace(/[^=]*=/, '');
        }
        return this.tableId;
    };

    fetchData = async (tableId) => {
        if (!tableId || Array.isArray(tableId)) {
            return;
        }
        const params = {
            referenced_table_id: tableId,
        };
        const response = await fetchDotWalkList(params);
        const data = response.isOkStatus && response.data ? response.data : {};
        this.data = data.items || data;
        this.tableId = data.table_id || ''; // DEF0009310
        this.isEmpty = data.length === 0;
        if (this.value && this.value !== this.conditionState.getConditionString()) {
            this.conditionState.parseConditionString(this.value, this.tableName, this.tableId);
        }
    };

    setConditionState(dataProps) {
        const tableId = dataProps.tableId || this.getTableId();
        // управление стейтом делегируем на более верхний уровень
        // в частности для репортов, чтобы не отправлять лишних запросов
        if (!dataProps.conditionState) {
            this.conditionState = new conditionStateClass();
            this.fetchData(tableId);
        }
        else {
            this.conditionState = dataProps.conditionState;
            this.conditionState.parseConditionString(this.value, this.tableName, tableId);
        }
    }

    uiSetValue(value) {
        this.value = value;

        this.changed = isChanged(this.defaultValue, value);
        if (this.onChange) {
            this.onChange({
                value,
                component: this,
            });
        }
        if (this.conditionState && this.conditionState.parseConditionString) {
            this.conditionState.parseConditionString(value, this.tableName, this.getTableId());
        }
    }

    uiClearValue() {
        this.value = '';
        this.changed = isChanged(this.defaultValue, this.value);
        if (this.onChange) {
            this.onChange({
                value: this.value,
                component: this,
            });
        }
        this.conditionState.clearData();
    }
}
