import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import { fetchListData } from 'actions/list';
import { Link, withRouter } from 'react-router-dom';
import { formatDate } from 'helpers/servicePortal';
import styles from './styles.module.scss';
import { getTimeZoneDate } from 'helpers/getUserTimeZone';
import { getRecordLink } from 'helpers/widget';
import { ATTRIBUTES } from 'constants/attributesForTests';
import Subject from './Subject';

const REGEX_TYPE = /^.*=([-]?\d+)/;

/**
 * @param {string} tableName
 * @param {string} condition
 * @param {string} view
 * @param {number} elementCount
 * @param {string} wrap
 * @param {string} transparent
 * @param {string} header
 * @param {string} itemPage
 * @param {string} itemView
 * @param {string} allLinkTitle
 * @param {string} allLinkPage
 * @param {string} title
 */
export class PreviewListWidget extends React.Component {
    data;
    styleColumn;
    isWrap = false;
    isTransparent = false;

    constructor(props) {
        super(props);

        makeObservable(this, {
            data: observable,
            styleColumn: observable,
            isWrap: observable,
            isTransparent: observable,
        });

        this.isWrap = props.wrap;
        this.isTransparent = props.transparent;

        this.getStyleRuleColumn().catch(err => console.error(err));
        this.getList().catch(err => console.error(err));
    }

    getStyleRuleColumn = async () => {
        const response = await fetchListData('sys_style_rule_column');
        this.styleColumn = response.data;
    };

    getList = async () => {
        const { condition, tableName, view = 'Default', elementCount } = this.props;
        const perPage = elementCount ? parseInt(elementCount, 10) : 20;
        const params = {
            view,
            per_page: perPage,
        };
        if (condition) {
            params.condition = condition;
        }
        const response = await fetchListData(tableName, params);
        this.data = _.cloneDeep(response.data);
    };

    getStyleColor = (type, columnId) => {
        const { items } = this.styleColumn;

        for (let item of items) {
            const match = item.condition.value.match(REGEX_TYPE);
            if (match && match[1]) {
                const typeCondition = match[1];
                if (item.column_id.value.database_value === columnId && type === typeCondition) {
                    return item.color.value.database_value;
                }
            }
        }
        return false;
    };

    getStyleIcon = (type, columnId) => {
        const { items } = this.styleColumn;

        for (let item of items) {
            const match = item.condition.value.match(REGEX_TYPE);
            if (match && match[1]) {
                const typeCondition = match[1];
                if (item.column_id.value.database_value === columnId && type === typeCondition && item.image_id && item.image_id.value && item.image_id.value.url) {
                    return item.image_id.value.url;
                }
            }
        }
        return '';
    };

    getStateHtml = (stateValue, columns, state) => {
        const { stateColumn = 'state' } = this.props;
        if (!stateValue || !this.isWrap || this.isTransparent) {
            return null;
        }
        const findColumn = _.find(columns, column => column.attribute === stateColumn);
        const columnName = findColumn ? `${ findColumn.label }: ` : '';
        let color = '';
        const columnId = findColumn ? findColumn.db_column_id : '';
        if (this.styleColumn) {
            color = this.getStyleColor(state, columnId);
        }
        return (
            <>
                <span className={ styles.Dot } />
                { columnName }
                <span className={ `${ styles.ColorBlock } ${ styles[color] || styles.dark }` } >{ stateValue }</span>
            </>
        );
    };

    renderDate = (item, columns = [], format = '') => {
        const { dateColumn = 'sys_created_at', stateColumn = 'state' } = this.props;
        const date = (item[dateColumn] && item[dateColumn].value) || false;
        if (!date) {
            return null;
        }
        const stateValue = (item[stateColumn] && item[stateColumn].value && item[stateColumn].value.display_value) || false;
        const state = (item[stateColumn] && item[stateColumn].value && item[stateColumn].value.database_value) || false;
        const findColumn = !this.isTransparent ? _.find(columns, column => column.attribute === dateColumn) : undefined;
        const formattedData = format ? getTimeZoneDate(date).format(format) : formatDate(date);
        const columnName = findColumn ? `${ findColumn.label }: ` : '';
        return (
            <div className={ styles.Date }>
                { columnName }{ formattedData }{ this.getStateHtml(stateValue, columns, state) }
            </div>
        );
    };

    renderSubject = (item, columns = null) => {
        const { subjectColumn = 'subject', match, tableName, itemPage, itemView, stateColumn = 'state' } = this.props;
        const subject = (item[subjectColumn] && item[subjectColumn].value) || false;
        if (!subject) {
            return null;
        }
        const link = getRecordLink(match, tableName, item.sys_id, itemPage, itemView);
        let textClassNames = [styles.Text];
        const state = (item[stateColumn] && item[stateColumn].value && item[stateColumn].value.database_value) || false;
        if(state && columns){
            const findColumn = _.find(columns, column => column.attribute === stateColumn);
            const columnId = findColumn ? findColumn.db_column_id : '';
            const color = this.styleColumn ? this.getStyleColor(state, columnId) : '';
            if(color === 'red'){
                textClassNames.push(styles[color]);
            }
        }
        textClassNames = textClassNames.join(' ');

        return (
            <Subject linkTo={ link }
                     linkClassName={ styles.Link }
                     textClassNames={ textClassNames }
            >
                { subject }
            </Subject>
        );
    };

    renderState = (item, columns) => {
        const { stateColumn = 'state' } = this.props;
        const state = (item[stateColumn] && item[stateColumn].value && item[stateColumn].value.database_value) || false;
        if (!state) {
            return null;
        }
        let color = '';
        let icon = '';
        const findColumn = _.find(columns, column => column.attribute === stateColumn);
        const columnId = findColumn ? findColumn.db_column_id : '';
        if (this.styleColumn) {
            color = this.getStyleColor(state, columnId);
            icon = this.getStyleIcon(state, columnId);
        }
        if (this.isTransparent) {
            return (
                <div className={ `${ styles.State } ${ styles[color] || styles.dark }` } />
            );
        }
        const stateText = item[stateColumn].value.display_value;
        const stateHtml = !this.isWrap ? <div className={ `${ styles.FullStateText } ${ styles[color] || styles.dark }` }>{ stateText }</div> : null;
        return (
            <div className={ styles.FullState }>
                { stateHtml }
                { this.renderIcon(color, icon) }
            </div>
        );
    };

    renderIcon = (color, icon) => {
        if (!icon) {
            return (
                <div />
            );
        }
        return (
            <div className={ styles.Icon }>
                <img className={ styles[color] || styles.dark } src={ icon } />
            </div>
        );
    };

    renderAnnouncementItems = (items, columns) => {
        const { elementCount } = this.props;
        const intElementCount = elementCount ? parseInt(elementCount, 10) : 0;
        let data = items;
        if (intElementCount) {
            data = items.slice(0, intElementCount);
        }
        return _.map(data, (item) => {
            return (
                <div className={styles.AnnounceRow} key={ item.sys_id }>
                    <div className={styles.AnnounceDate}>
                        { this.renderDate(item) }
                    </div>
                    <div className={styles.AnnounceState}>
                        { this.renderState(item, columns) }
                    </div>
                    <div className={styles.AnnounceText}>
                        { this.renderSubject(item, columns) }
                    </div>
                </div>
            );
        });
    };

    renderAnnouncement = () => {
        const { className, allLinkTitle, allLinkPage, title } = this.props;
        const { items, page_name: pageName, columns } = this.data;
        const classNameWrap = this.isTransparent ? styles.TransparentList : styles.WhiteList;
        const headlineLink = allLinkTitle && allLinkPage ? <Link to={ allLinkPage }>{ allLinkTitle }</Link> : null;
        const headlineTitle = title ? title : pageName;

        return (
            <div className={ `${ className || '' }` } data-test={ this.props['data-test'] ? this.props['data-test'] : `previewlist-${ ATTRIBUTES.widget }` }>
                <div className={ classNameWrap }>
                    <div className={ styles.AnnounceList }>
                        <div className={styles.AnnounceHeadline}>
                            <div className={ styles.HeadlineText }>
                                { headlineTitle }
                            </div>
                            { headlineLink && <div className={ styles.HeadlineLink}>{ headlineLink }</div> }
                        </div>
                        { this.renderAnnouncementItems(items, columns) }
                        <div className={ styles.AnnounceFooter }>
                            { headlineLink }
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderSeparetedItems = (items, columns) => {
        const { elementCount } = this.props;
        const intElementCount = elementCount ? parseInt(elementCount, 10) : 0;
        let data = items;
        if (intElementCount) {
            data = items.slice(0, intElementCount);
        }
        return _.map(data, (item) => {
            return (
                <div key={ item.sys_id } className={ styles.SeparatedRow }>
                    <div>
                        { this.renderSubject(item) }
                        { this.renderDate(item, columns, 'D MMMM YYYY') }
                    </div>
                    <div>
                        { this.renderState(item, columns) }
                    </div>
                </div>
            );
        });
    };

    renderHeader = () => {
        const { children } = this.props;
        if (_.isEmpty(children) || children[0].props.type !== 'listheader') {
            return null;
        }
        return children[0].props.children;
    };

    renderSeparetedList = () => {
        const { className } = this.props;
        const { items, columns } = this.data;
        const classNameTransparent = this.isTransparent ? styles.TransparentList : styles.WhiteList;
        const classNameWrap = this.isWrap ? styles.SmallList : styles.BigList;
        return (
            <div className={ `${ styles.Wrap } ${ className || '' }` } data-test={ this.props['data-test'] ? this.props['data-test'] : `previewlist-${ ATTRIBUTES.widget }` }>
                <div className={ `${ classNameTransparent } ${ classNameWrap }` }>
                    <div className={ styles.List }>
                        { this.renderHeader() }
                        { this.renderSeparetedItems(items, columns) }
                    </div>
                </div>
            </div>
        );
    };

    render() {
        if (!this.data) {
            return null;
        }
        if (this.isTransparent) {
            return this.renderAnnouncement();
        }
        return this.renderSeparetedList();
    }
}

export default withRouter(observer(PreviewListWidget));
