import * as React from 'react';
import styles from 'components/dynamicForms/view/field/stringInput/styles.module.scss';
import IconX from 'assets/img/icons/close-circle.svg';

import { observer } from 'mobx-react';
import { observable, makeObservable } from "mobx";

import _ from 'lodash';

import FieldWrapper from 'components/dynamicForms/view/fieldWrapper';
import StringInputModel from 'components/dynamicForms/model/field/StringInputModel';
import { getTestNameField } from 'helpers/data';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { isChanged } from 'helpers/form';

/**
 * Описание: компонент StringInput
 * Параметры:
 * onChange: {function} - метод для изменения значения
 * value: {string} - значение
 * className: {string} - class css
 * save: {function} - метод сохранения формы
 * maxLength: {number} - ограничение ввода
 * showCleanBtn: {boolean} - отображение кнопки быстрой очистки поля
 * isServicePortal: {boolean}
 **/

export class StringInput extends React.Component {
    model;

    constructor(props) {
        super(props);

        makeObservable(this, {
            model: observable,
        });

        if (props.model) {
            this.model = props.model;
        } else {
            this.model = new StringInputModel(props);
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props, prevProps)) {
            if (this.props.model) {
                this.model = this.props.model;
            } else {
                this.model.mergeData(this.props);
            }
        }
        this.setFocusInput();
    }

    setFocusInput = () => {
        if (this.model.isEdit && this.model.ref.current) {
            setTimeout(() => {
                if (this.model.ref.current){
                    this.model.ref.current.focus();
                }
            });
        }
    }

    getClasses = () => {
        const { className } = this.model;

        const stylesStr = [ styles.Input ];

        if (className) {
            stylesStr.push(className);
        }
        if (this.model.showCleanBtn) {
            stylesStr.push(styles.HasCleanBtn);
        }
        if( this.model.isWarning && this.model.cellEditMode) {
            stylesStr.push(styles.warningInput);
        }
        return stylesStr.join(' ');
    };

    checkDecimalPlaceLength = (value, type) => {
        const decimalPlaceLength = value.includes('.', ',') ? value.split(/[.|,]/).pop().length : 0;
        if (type === 'decimal' || type === 'percent_complete') {
            return decimalPlaceLength <= 2;
        }
        else if (type === 'float') {
            return decimalPlaceLength <= 7;
        }
    };

    getRightValue = (value, max, min, type) => {
        if (Number.isNaN(Number(value))) return value;
        if (min) {
            const isMoreMax = Number(value) <= max;
            const isMoreMin = Number(value) >= min;
            return (isMoreMax && isMoreMin) ? value : this.model.value;
        }
        else if (type) {
            return (Number(value) <= max && this.checkDecimalPlaceLength(value, type)) ? value : this.model.value;
        }
        return Number(value) <= max ? value : this.model.value;
    };

    //TODO: метод служит для ограничения ввода для цифровых значений с типами decimal и smallinteger,
    // когда будет писаться валидация его нужно будет убрать.
    checkValue = ({ value }) => {
        if (this.model.column_type === 'decimal' || this.model.column_type === 'float') {
            if(!this.isDouble(value)) return;
            value = value.replace(/,/, '.');
            return this.getRightValue(value, 9999999999, null, this.model.column_type);
        }
        if (this.model.column_type === 'percent_complete') {
            if(!this.isDouble(value)) return;
            return this.getRightValue(value, 100, 0, this.model.column_type);
        }
        if (this.model.column_type === 'integer') {
            if (!this.isNumeric(value)) return;

            return this.getRightValue(value, 2147483647, -2147483647);
        }
        if (this.model.column_type === 'smallinteger') {
            if (!this.isNumeric(value)) return;

            return this.getRightValue(value, 32767, -32767);
        }
        if (this.model.column_type === 'biginteger') {
            return (this.isNumeric(value) && value.length < 20) ? value : this.model.value;
        }
        return value;
    };

    isNumeric(value) {
        if (value === '') return true;
        return /^-?\d*?$/.test(value);
    }

    isDouble(value) {
        if (value === '') return true;
        return /^-?(\d+(\.\d*)?|\.\d+)?$/.test(value);
    }

    onChange = (event) => {
        this.model.value = this.checkValue(event.target);
        this.model.changed = isChanged(this.model.defaultValue, this.model.value);
        if (this.props.onChange) {
            this.props.onChange(this.model);
        }
    };

    handleKeyDown = (e) => {
        if (this.model.save && this.model.cellEditMode && !this.model.readonly && e.key === 'Enter') {
            e.preventDefault();
            this.model.save();
        }
    };

    cleanInput = () => {
        this.model.value = '';
        this.model.changed = isChanged(this.model.defaultValue, this.model.value);
        if (this.props.onChange) {
            this.props.onChange({ ...this.model, isClean: true });
        }
        if (this.model.ref && this.model.ref.current) {
            this.model.ref.current.focus();
        }
    };

    handlePaste = (e) => {
        if (this.model.column_type === 'phone'){
            e.preventDefault();
            let paste = e.clipboardData.getData('text');
            if (paste && this.model.maxLength > 0){
                paste = paste.substring(0, this.model.maxLength);
            }
            this.model.value = paste.replace(/\s/g, '');
            this.model.changed = isChanged(this.model.defaultValue, this.model.value);
        }
        if (this.props.onPaste) {
            this.props.onPaste(e);
        }
    };

    render() {
        if (_.isEmpty(this.model)) {
            return null;
        }

        if (this.props.innerRef) {
            this.model.ref = this.props.innerRef;
        }
        const fieldName = getTestNameField(this.model);
        const dataTest = (fieldName !== 'unknown') ? `${ fieldName }-${ ATTRIBUTES.fieldString }` : `${ ATTRIBUTES.fieldString }`;
        const _value = _.isNil(this.model.value) ? '' : this.model.value.toString();
        return (
            <FieldWrapper model={ this.model }>
                <div className={ this.getClasses() } >
                    <input
                        key={ `${ this.model.sysColumnName }-${ this.model.columnId }` }
                        placeholder={ !this.model.readonly ? this.model.placeholder : '' }
                        required={ this.model.isMandatory }
                        readOnly={ this.model.readonly }
                        disabled={ this.model.readonly && !_value }
                        value={ _value }
                        max={ this.model.max }
                        type={ this.model.getType ? this.model.getType() : 'text' }
                        onChange={ this.onChange }
                        ref={ this.model.ref }

                        onFocus={ this.model.onFocus }
                        onBlur={ this.model.onBlur }

                        onKeyUp={ this.props.onKeyUp }
                        onKeyDown={ this.props.onKeyDown ? this.props.onKeyDown : this.handleKeyDown }
                        onKeyPress={ this.props.onKeyPress }
                        onPaste={ this.handlePaste }

                        autoFocus={ this.model.cellEditMode }
                        autofill={ this.model.autofill }
                        autoComplete={ this.model.autoComplete }
                        maxLength={ this.model.maxLength }

                        data-test={ this.props['data-test'] ? this.props['data-test'] : dataTest }
                        data-test-visible={ this.model.isVisible }
                        data-test-mandatory={ this.model.isMandatory }
                        data-test-readonly={ this.model.readonly }
                        data-test-field-type={ this.model.sys_column_name ? this.model.column_type : undefined }
                        data-test-field-name={ this.model.sys_column_name }
                    />
                    {
                        !!this.model.value && this.model.showCleanBtn && !this.model.readonly &&
                        (
                            <div
                                className={ styles.CleanBtn }
                                dangerouslySetInnerHTML={ { __html: IconX } }
                                onClick={ this.cleanInput }
                            />
                        )
                    }

                </div>
            </FieldWrapper>
        );
    }
}

const ObserverComponent = observer(StringInput);
export default React.forwardRef((props, ref) => <ObserverComponent
    innerRef={ ref } { ...props }
/>);
