import { ContainerType } from 'types/pages/servicePortal/index';
import { action, observable, makeObservable } from 'mobx';
import { PortalContainerClassType, PortalRowClassType } from 'types/pages/servicePortal/container/index';
import _ from 'lodash';
import PortalRow from 'globalState/servicePortalWidgets/containers/rows/index';

export default class PortalContainer implements PortalContainerClassType {
    private _backgroundColor: string;
    private _backgroundImage: string | null;
    private _cssClassNames: string | null;
    private _fixedWidth: boolean;
    private _isFooter: boolean;
    private _isHeader: boolean;
    private _rows: PortalRowClassType[];
    private _sysId: string;
    private _sysOrder: number;

    constructor(data: ContainerType) {
        makeObservable(this, {
            _backgroundColor: observable,
            _backgroundImage: observable,
            _cssClassNames: observable,
            _fixedWidth: observable,
            _isFooter: observable,
            _isHeader: observable,
            _rows: observable,
            _sysId: observable,
            _sysOrder: observable,
            setBackgroundColor: action,
            setBackgroundImage: action,
            setCssClassNames: action,
            setFixedWidth: action,
            setIsFooter: action,
            setIsHeader: action,
            setRows: action,
            setSysId: action,
            setSysOrder: action,
            updateRows: action,
        });

        this._backgroundColor = data.background_color;
        this._backgroundImage = data.background_image;
        this._cssClassNames = data.css_class_names;
        this._fixedWidth = data.fixed_width;
        this._isFooter = data.is_footer;
        this._isHeader = data.is_header;
        this._sysId = data.sys_id;
        this._sysOrder = data.sys_order;
        this._rows = _.map(data.rows, row => new PortalRow(row));
    }

    getBackgroundColor(): string {
        return this._backgroundColor;
    }

    setBackgroundColor(value: string) {
        this._backgroundColor = value;
    }

    getBackgroundImage(): string | null {
        return this._backgroundImage;
    }

    setBackgroundImage(value: string | null) {
        this._backgroundImage = value;
    }

    getCssClassNames(): string | null {
        return this._cssClassNames;
    }

    setCssClassNames(value: string | null) {
        this._cssClassNames = value;
    }

    getFixedWidth(): boolean {
        return this._fixedWidth;
    }

    setFixedWidth(value: boolean) {
        this._fixedWidth = value;
    }

    getIsFooter(): boolean {
        return this._isFooter;
    }

    setIsFooter(value: boolean) {
        this._isFooter = value;
    }

    getIsHeader(): boolean {
        return this._isHeader;
    }

    setIsHeader(value: boolean) {
        this._isHeader = value;
    }

    getRows(): PortalRowClassType[] {
        return this._rows;
    }

    setRows(value: PortalRowClassType[]) {
        this._rows = value;
    }

    getSysId(): string {
        return this._sysId;
    }

    setSysId(value: string) {
        this._sysId = value;
    }

    getSysOrder(): number {
        return this._sysOrder;
    }

    setSysOrder(value: number) {
        this._sysOrder = value;
    }

    updateRows = (rows: PortalRowClassType[]) => {
        if (_.isEmpty(this._rows)) {
            this._rows = rows;
            return;
        }
        const newRows = [
            ..._.filter(this._rows, (row, index) => index <= rows.length - 1),
            ..._.filter(rows, (row, index) => index > this._rows.length - 1),
        ];
        _.forEach(rows, (row, index) => {
            if (!newRows[index].isEqualSomeWidgets(row.getColumns())) {
                newRows[index] = row;
            } else {
                newRows[index].updateColumns(row.getColumns());
            }
        });
        this._rows = newRows;
    };

    isEqualSomeWidgets = (rows: PortalRowClassType[]) => {
        let result = true;
        _.forEach(this._rows, row => {
            const findEqualWidget = !!_.find(rows, r => row.isEqualSomeWidgets(r.getColumns()));
            if (!findEqualWidget) {
                result = false;
            }
        });
        return result;
    }
}
