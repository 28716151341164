import BaseFormFieldModel from './BaseFormFieldModel';
import { isChanged } from 'helpers/form';

export default class CheckboxModel extends BaseFormFieldModel {
    columnType = 'boolean';

    constructor(data, parentFormSectionModel) {
        super(data, parentFormSectionModel);
        this.mergeData(data);
    }

    uiClearValue() {
        this.value = 0;
        this.changed = isChanged(this.defaultValue, this.value);
    }

    setValue(value) {
        return value === true || value === '1' || value === 1 ? 1 : 0;
    }
}
