import BasePortalDesignerModel from './base';
import RowsPortalDesignerCollection from '../collection/rows';
import { observable, makeObservable } from 'mobx';

/**
 * модель контейнера
 */
export default class ContainerPortalDesignerModel extends BasePortalDesignerModel {
    TABLE_NAME = 'page_container';
    /**
     * тип блока
     * @type {string}
     */
    TYPE = 'container';

    /**
     *
     * @type {RowsPortalDesignerCollection}
     */
    rows = new RowsPortalDesignerCollection([], this);

    isFooter = false;
    isHeader = false;

    constructor(params) {
     super(params);

     makeObservable(this, {
      rows: observable,
     });

     this.setData(params);
    }

    setData(params) {
        super.setData(params);

        this.rows.updateItems(params.rows);
        this.isFooter = !!params.is_footer;
        this.isHeader = !!params.is_header;
    }
}
