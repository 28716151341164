import _ from 'lodash';
import apiRequest from 'lib/apiRequest';
import { handleResponse } from 'helpers/action';
import { isEmpty } from 'helpers/isEmpty';
import { SearchCategoriesResponse, SearchResponse } from 'types/actions/search';

export const fetchSearchCategories = async (portal?: string): Promise<SearchCategoriesResponse> => {
    const params = _.omitBy({ portal }, _.isEmpty);
    const response = await new apiRequest(`GET /search/groups`).qs(params).send(null, true);
    return handleResponse(response);
};

export const fetchSearchResultsData = async (query: string, page: number, per_page?: number, portal?: string, ts_table_id?: string): Promise<SearchResponse> => {
    let params = _.omitBy({ query, portal, ts_table_id, page, per_page }, isEmpty);
    return handleResponse(await new apiRequest(`GET /search`).qs(params).send(null, true));
};
