import * as React from 'react';
import { observer } from 'mobx-react';
import UiButtons from 'components/uiButtons';
import UiBurger from 'components/uiBurger';
import styles from './styles.module.scss';
import { observable, makeObservable } from 'mobx';

/**
 * Описание:
 * контейнер для ui-кнопок.
 * кнопки, которые не влезают в контейнер, отображаются в бургер-меню.
 *
 * Параметры:
 * data
 * recordId
 * tableName
 * disableUiActions
 * actionsContextMenu
 */
class UiButtonsContainer extends React.Component {
    refContent = React.createRef();
    burgerData = [];

    constructor(props) {
        super(props);

        makeObservable(this, {
            burgerData: observable,
        });
    }

    componentDidMount() {
        this.checkVisibleButtons();
        window.addEventListener('resize', this.checkVisibleButtons);

        // проверяем второй раз, поскольку кнопки могут перестраиваться чуть позже после рендера, в силу их естественного переноса на странице
        setTimeout(this.checkVisibleButtons, 1000);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkVisibleButtons);
    }

    checkVisibleButtons = () => {
        const { current: content } = this.refContent;
        if (!content) return;

        this.burgerData = Array.isArray(this.props.data) ? this.props.data : [];
        const contentTop = content.getBoundingClientRect().top;
        const buttons = content.children;
        const buttonsLength = buttons.length;
        let isAllVisible = true;

        for (let i = 0; i < buttonsLength; i++) {
            // проверяем видна ли кнопка в блоке
            if (buttons[i].getBoundingClientRect().top - contentTop > 10) {
                // если кнопка не видна, значит не видны и все последующие. добавляем их в бургер-меню
                this.burgerData = Array.isArray(this.props.data) ? this.props.data.slice(i) : [];
                isAllVisible = false;
                break;
            }
        }

        if (isAllVisible) {
            // если все кнопки видны - не отображаем бургер-меню
            this.burgerData = [];
        }
    };

    render() {
        return (
            <div className={ styles.Base }>
                <div className={ styles.ButtonsContainer }>
                    <div className={ styles.ButtonsContent } ref={ this.refContent }>
                        <UiButtons
                            data={ this.props.data }
                            recordId={ this.props.recordId }
                            tableName={ this.props.tableName }
                            disableUiActions={ this.props.disableUiActions }
                            actionsContextMenu={ this.props.actionsContextMenu }
                        />
                    </div>
                </div>
                <UiBurger
                    data={ this.burgerData }
                    recordId={ this.props.recordId }
                    tableName={ this.props.tableName }
                    disableUiActions={ this.props.disableUiActions }
                    buttonClass={ styles.Burger }
                />
            </div>
        );
    }
}

export default observer(UiButtonsContainer);
