// Хранилище для объектов используемых бизнес решением
class BusinessSolutionStorage {

    _storage = new Map();

    setItem = (key, value) => {
        this._storage.set(key, value);
    };

    getItem = (key) => {
        return this._storage.get(key);
    };

    getKeys = () => {
        return Array.from(this._storage.keys());
    };

    getEntries = () => {
        return Array.from(this._storage.entries());
    };

    hasItem = (key) => {
        return this._storage.has(key);
    };

    getSize = () => {
        return this._storage.size;
    };

    reset = () => {
        this._storage.clear();
    };
}

export default new BusinessSolutionStorage();
