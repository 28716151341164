/**
 * Модель виджета
 */
export default class ActivitiesModel {
    formatter;

    constructor({formatter}) {
        this.formatter = formatter;
    }

    isSplit() {
        return false;
    }
}