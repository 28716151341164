import React, {useState, useRef} from 'react';
import styles from './styles.module.scss';
import './custom.css';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {AutoScrollPlugin} from '@lexical/react/LexicalAutoScrollPlugin';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {LinkPlugin} from '@lexical/react/LexicalLinkPlugin';
import {ListPlugin} from '@lexical/react/LexicalListPlugin';
import {AutoLinkPlugin} from './plugins/AutoLinkPlugin';
import {CodeHighlightPlugin} from './plugins/CodeHighlightPlugin';
import {ToolbarPlugin} from './plugins/ToolbarPlugin';
import {ImagesPlugin} from './plugins/ImagePlugin';
import {ListMaxIndentLevelPlugin} from './plugins/ListMaxIndentLevelPlugin';
import {PopupToolbarPlugin} from './plugins/ToolbarPlugin/PopupToolbarPlugin';
import {editorConfig} from "./config/editorConfig";
import {OnChangePlugin} from "./plugins/OnChangePlugin";
import {ResizePlugin} from "./plugins/ResizePlugin";
import {NavigationPlugin} from "./plugins/NavigationPlugin";
import {noop} from "helpers/cancellable";
import {ATTRIBUTES} from "constants/attributesForTests";
import langStore from "globalState/lang";
import type {TextEditorProps} from "types/components/textEditor";

const Placeholder = ({readOnly}: { readOnly: TextEditorProps["readOnly"] }) => {
    if (readOnly) return null;

    const {rich_text} = langStore.getTranslate();

    return (
        <div className={styles.Placeholder}>
            {rich_text?.enter_your_message}
        </div>
    );
};

export const TextEditor: React.FC<TextEditorProps> = ({
                                                          model,
                                                          readOnly,
                                                          value,
                                                          onChange = noop,
                                                          setRawEditor = noop,
                                                          isAdditional = false,
                                                      }) => {
    const [isShowToolbar, setIsShowToolbar] = useState<boolean>(false);
    const [fullSize, setFullSize] = useState(false);
    const editorRef = useRef<HTMLDivElement>(null);

    const editorClassName: string[] = [styles.EditorContainer];
    if (readOnly) {
        if (isAdditional) {
            editorClassName.push(styles.DisabledAdditionalEditor);
        } else {
            editorClassName.push(styles.DisabledEditor);
        }
    }

    const mainContainerClassName: string[] = [styles.MainContainer];
    if (fullSize) {
        mainContainerClassName.push(styles.FullSize);
    } else if (isAdditional) {
        mainContainerClassName.push(styles.AdditionalEditor);
    }

    return (
        <LexicalComposer initialConfig={editorConfig}>
            <OnChangePlugin
                value={value}
                isReadOnly={readOnly}
                setValue={onChange}
                setRawEditor={setRawEditor}
            />
            <div className={`${mainContainerClassName.join(' ')}`}>
                <NavigationPlugin
                    editorRef={editorRef}
                    fullSize={fullSize}
                    isAdditional={isAdditional}
                />
                <div className={`${editorClassName.join(' ')}`}>
                    <ToolbarPlugin
                        isShowToolbar={isShowToolbar}
                        setIsShowToolbar={setIsShowToolbar}
                        fullSize={fullSize}
                        setFullSize={setFullSize}
                        isAdditional={isAdditional}
                    />
                    <div
                        className={styles.EditorInner}
                        data-test={ATTRIBUTES.editorInput}
                        ref={editorRef}
                    >
                        <RichTextPlugin
                            contentEditable={<ContentEditable
                                className={styles.EditorInput}
                            />}
                            placeholder={<Placeholder readOnly={readOnly}/>}
                        />
                        <HistoryPlugin/>
                        <ImagesPlugin model={model}/>
                        <CodeHighlightPlugin/>
                        <ListPlugin/>
                        <LinkPlugin/>
                        <AutoLinkPlugin/>
                        <AutoScrollPlugin scrollRef={editorRef}/>
                        <PopupToolbarPlugin/>
                        <ListMaxIndentLevelPlugin/>
                    </div>
                    <ResizePlugin
                        editorRef={editorRef}
                        isAdditional={isAdditional}
                    />
                </div>
            </div>
        </LexicalComposer>
    );
};
