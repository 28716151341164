import * as React from 'react';
import { observer } from 'mobx-react';

import DragAndDropState from 'components/portalDesigner/components/dragAndDrop/state';

class DragAndDrop extends React.Component {

    constructor(props) {
        super(props);
    }

    handleDragStart = ({ target, dataTransfer }) => {
        if (DragAndDropState.type) {
            return;
        }
        DragAndDropState.type = this.props.type;
        DragAndDropState.sysId = this.props.sysId;
        DragAndDropState.isNew = this.props.isNew;
        DragAndDropState.dragged = true;

        target.style.opacity = '0.4';
        dataTransfer.effectAllowed = 'move';
    };

    onDragEnd = ({ target }) => {
        DragAndDropState.clean();
        target.style.opacity = '1';
    };

    render() {
        return <div draggable="true" onDragStart={ this.handleDragStart } onDragEnd={ this.onDragEnd }>
            { this.props.children }
        </div>;
    }
}

export default observer(DragAndDrop);
