import { ContainerColumnType } from 'types/pages/servicePortal/index';
import { action, observable, makeObservable } from 'mobx';
import _ from 'lodash';
import { PortalColumnType, PortalWidgetInstanceType } from 'types/pages/servicePortal/container/index';
import PortalWidgetInstance from 'globalState/servicePortalWidgets/containers/rows/columns/widgetInstances/index';

export default class PortalColumn implements PortalColumnType {
    private _cssClassNames: string | null;
    private _sizeLg: number;
    private _sizeMd: number;
    private _sizeSm: number;
    private _sizeXs: number;
    private _sysId: string;
    private _sysOrder: number;
    private _widgetInstances: PortalWidgetInstanceType[];

    constructor(data: ContainerColumnType) {
        makeObservable(this, {
            _cssClassNames: observable,
            _sizeLg: observable,
            _sizeMd: observable,
            _sizeSm: observable,
            _sizeXs: observable,
            _sysId: observable,
            _sysOrder: observable,
            _widgetInstances: observable,
            setCssClassNames: action,
            setSizeLg: action,
            setSizeMd: action,
            setSizeSm: action,
            setSizeXs: action,
            setSysId: action,
            setSysOrder: action,
            setWidgetInstances: action,
            updateWidgetInstances: action,
        });

        this._cssClassNames = data.css_class_names;
        this._sizeLg = data.size_lg;
        this._sizeMd = data.size_md;
        this._sizeSm = data.size_sm;
        this._sizeXs = data.size_xs;
        this._sysId = data.sys_id;
        this._sysOrder = data.sys_order;
        this._widgetInstances = _.map(data.widget_instances, instance => new PortalWidgetInstance(instance));
    }

    getCssClassNames(): string | null {
        return this._cssClassNames;
    }

    setCssClassNames(value: string | null) {
        this._cssClassNames = value;
    }

    getSizeLg(): number {
        return this._sizeLg;
    }

    setSizeLg(value: number) {
        this._sizeLg = value;
    }

    getSizeMd(): number {
        return this._sizeMd;
    }

    setSizeMd(value: number) {
        this._sizeMd = value;
    }

    getSizeSm(): number {
        return this._sizeSm;
    }

    setSizeSm(value: number) {
        this._sizeSm = value;
    }

    getSizeXs(): number {
        return this._sizeXs;
    }

    setSizeXs(value: number) {
        this._sizeXs = value;
    }

    getSysId(): string {
        return this._sysId;
    }

    setSysId(value: string) {
        this._sysId = value;
    }

    getSysOrder(): number {
        return this._sysOrder;
    }

    setSysOrder(value: number) {
        this._sysOrder = value;
    }

    getWidgetInstances(): PortalWidgetInstanceType[] {
        return this._widgetInstances;
    }

    setWidgetInstances(value: PortalWidgetInstanceType[]) {
        this._widgetInstances = value;
    }

    updateWidgetInstances = (widgetInstances: PortalWidgetInstanceType[]) => {
        if (_.isEmpty(this._widgetInstances)) {
            this._widgetInstances = widgetInstances;
            return;
        }
        const newWidgetInstances = [
            ..._.filter(this._widgetInstances, (widgetInstance, index) => index <= widgetInstances.length - 1),
            ..._.filter(widgetInstances, (widgetInstance, index) => index > this._widgetInstances.length - 1),
        ];
        _.forEach(widgetInstances, (widgetInstance, index) => {
            if (!newWidgetInstances[index].isEqualWidget(widgetInstance)) {
                newWidgetInstances[index] = widgetInstance;
            } else {
                newWidgetInstances[index].updateWidget(widgetInstance);
            }
        });
        this._widgetInstances = newWidgetInstances;
    };

    isEqualSomeWidgets = (widgetInstances: PortalWidgetInstanceType[]) => {
        let result = true;
        _.forEach(this._widgetInstances, widgetInstance => {
            const findEqualWidget = !!_.find(widgetInstances, wi => widgetInstance.isEqualWidget(wi));
            if (!findEqualWidget) {
                result = false;
            }
        });
        return result;
    }
}
