import { observable, makeObservable } from 'mobx';

class DragAndDropState {
    draggedType = ''; // 'lane'|'card'
    dragged = false;
    draggedSysId = null;
    draggedCategoryId = null;
    draggedOrder = -1;
    enterId = '';
    firstLaneId = null;

    constructor() {
        makeObservable(this, {
            draggedType: observable,
            dragged: observable,
            draggedSysId: observable,
            draggedCategoryId: observable,
            draggedOrder: observable,
            enterId: observable,
            firstLaneId: observable,
        });
    }

    setSysId(sysId) {
        this.draggedSysId = sysId;
    }

    getSysId() {
        return this.draggedSysId;
    }

    setCategoryId(sysId) {
        this.draggedCategoryId = sysId;
    }

    getCategoryId() {
        return this.draggedCategoryId;
    }

    setType(type) {
        this.draggedType = type;
    }

    getType() {
        return this.draggedType;
    }

    setDragged(isDragged) {
        this.dragged = isDragged;
    }

    getDragged() {
        return this.dragged;
    }

    setEnterId(enterId) {
        this.enterId = enterId;
    }

    getEnterId() {
        return this.enterId;
    }

    setOrder = (order) => {
        this.draggedOrder = order;
    };

    getOrder = () => {
        return this.draggedOrder;
    };

    setFirstLaneId = (laneId) => {
        this.firstLaneId = laneId;
    };

    getFirstLaneId = () => {
        return this.firstLaneId;
    };

    clean() {
        this.draggedSysId = null;
        this.draggedType = '';
        this.draggedCategoryId = null;
        this.dragged = false;
        this.enterId = '';
        this.draggedOrder = -1;
        this.firstLaneId = null;
    }
}

export default new DragAndDropState();
