import _ from 'lodash';
import {IsEmptyObjectType, IsEmptyPhoto} from "types/helpers/isEmpty";

export const isEmpty = (value?: any): boolean => {
    return _.isNil(value) || value === '';
};

export const isEmptyArray = (value?: Array<any>): boolean => {
    const isValueEmpty = isEmpty(value);
    const isArrayEmpty = Array.isArray(value) && value.length === 0;
    return isValueEmpty || isArrayEmpty;
};

export const isEmptyObject = (value?: IsEmptyObjectType): boolean => {
    const isValueEmpty = isEmpty(value);
    const isObjectEmpty = _.isObject(value) &&
        ((value[0])
            ? (_.isNil(value[0].database_value) || value[0].database_value === '')
            : (_.isNil(value.database_value) || value.database_value === ''));
    return isValueEmpty || isObjectEmpty;
};

export const isEmptyPhoto = (value?: IsEmptyPhoto): boolean => {
    const isValueEmpty = isEmpty(value);
    const isObjectEmpty = _.isObject(value) && (_.isNil(value.sys_id) || value.sys_id === '');
    return isValueEmpty || isObjectEmpty;
};
