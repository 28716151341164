import * as React from 'react';


export  default class UnknownField extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div>{ `"${ this.props.column_type }" unknown field type` }</div>;
    }
}