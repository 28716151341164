import React from 'react';
import ErrorPage from 'pages/ErrorPage';
import userState from 'globalState/user';
import { CODE_403_FORBIDDEN, CODE_404_NOTFOUND } from 'lib/apiRequest';
import { observer } from 'mobx-react';

const ErrorWrapperHoc = WrappedComponent => {
    class ErrorWrappedComponent extends React.Component {

        render() {
            const codeError = userState.getAccessError();
            if (codeError === CODE_404_NOTFOUND) {
                return <ErrorPage codeError={ codeError } />;
            }
            if (codeError === CODE_403_FORBIDDEN) {
                return <ErrorPage codeError={ codeError } />;
            }
            return <WrappedComponent { ...this.props } />;
        }
    }


    ErrorWrappedComponent.displayName = `ErrorWrapped(${ WrappedComponent.displayName || WrappedComponent.name || 'Component' })`;

    return observer(ErrorWrappedComponent);
};

export default ErrorWrapperHoc;
