import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import {KeyboardKeys} from "types/components/dynamicForms/maskInput";
import {DateTimeInputProps} from "types/components/dynamicForms/dateTimeInput";

/**
 * Описание: Поле ввода времени датапикера
 * Параметры:
 * value - значение
 * onChange - метод вызываемый при изменении значения
 * maxLengthTime - максимальное количество вводимых чисел.
 * className - класс
 */
class DatePickerTimeInput extends React.Component<DateTimeInputProps> {
    isFocused = false;
    maxLengthTime = 2;

    constructor(props) {
        super(props);

        makeObservable(this, {
            isFocused: observable,
        });
    }

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { onChange } = this.props;
        if (!onChange) return;
        onChange(e.target.value);
    };

    onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const { onChange } = this.props;
        if (!onChange) return;
        let value = parseInt((e.target as HTMLInputElement).value);
        if (e.key === KeyboardKeys.ArrowUp) {
            e.preventDefault();
            value += 1;
            onChange(value);
        }
        if (e.key === KeyboardKeys.ArrowDown) {
            e.preventDefault();
            value -= 1;
            onChange(value);
        }
    };

    prepareValue(value: string): string {
        if (this.isFocused) return value;
        const symLength = value.toString().split('').length;
        switch (symLength) {
            case 0:
                return '00';
            case 1:
                return '0' + value;
            default:
                return value;
        }
    }

    render() {
        const { className } = this.props;
        const value = this.prepareValue(this.props.value);
        return (
            <input
                type="text"
                value={ value }
                maxLength={ this.maxLengthTime }
                onFocus={ () => {
                    this.isFocused = true;
                } }
                onBlur={ () => {
                    this.isFocused = false;
                } }
                onChange={ this.onChange }
                onKeyDown={ this.onKeyPress }
                className={ className }
                data-test={ this.props['data-test'] ? this.props['data-test'] : '' }
            />
        );
    }
}

export default observer(DatePickerTimeInput);
