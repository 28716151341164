import * as React from 'react';
import { observer } from 'mobx-react';

import styles from 'components/portalDesigner/styles.module.scss';

import PageData from 'components/portalDesigner/state/pageData';
import DragAndDropState from 'components/portalDesigner/components/dragAndDrop/state';

import BaseElement from 'components/portalDesigner/components/base';
import WidgetInstance from 'components/portalDesigner/components/widgetInstance';


class Col extends React.Component {
    breadcrumbsIds = [];
    containerModel;
    rowModel;

    constructor(props) {
        super(props);

        this.rowModel = this.props.model.parent;
        this.containerModel = this.rowModel && this.props.model.parent.parent;
        this.breadcrumbsIds = this.props.breadcrumbsIds.concat([
            {
                id: props.id,
                model: props.model,
                // @todo translate
                name: 'Column',
                type: 'column',
            },
        ]);
    }

    handleDragEnter = (e) => {
        e.preventDefault();

        if (DragAndDropState.type === PageData.TYPE_LAYOUT) {
            PageData.setTempRow(this.containerModel);
        }

        if (DragAndDropState.type === PageData.TYPE_WIDGET_INSTANCE) {
            PageData.setTempWidget(this.props.model);
        }
    };

    handleDragOver = (e) => {
        e.preventDefault();
    };

    handleDragLeave = (e) => {
        e.preventDefault();
    };

    handleDrop = (e) => {
        e.preventDefault();

        if (DragAndDropState.type === PageData.TYPE_WIDGET_INSTANCE) {
            PageData.fetchSave(PageData.tempWidget, this.props.model.id, DragAndDropState.sysId, DragAndDropState.isNew)
                    .catch(console.error);
        }
    };

    render() {
        /**
         this.sizeXS = params.size_xs;
         this.sizeSM = params.size_sm;
         this.sizeMD = params.size_md;
         this.sizeLG = params.size_lg;
         */
        let style = [
            styles.Columns,
            styles[`Columns-xs-${this.props.model.sizeXS}`],
            styles[`Columns-sm-${this.props.model.sizeSM}`],
            styles[`Columns-md-${this.props.model.sizeMD}`],
            styles[`Columns-lg-${this.props.model.sizeLG}`],
        ];
        return <BaseElement className={ style.join(' ') }
                            type="column"
                            model={ this.props.model }
                            handleDrop={ this.handleDrop }
                            handleDragEnter={ this.handleDragEnter }
                            handleDragOver={ this.handleDragOver }
                            handleDragLeave={ this.handleDragLeave }
                            breadcrumbsIds={ this.breadcrumbsIds }
                            key={ this.props.id }
                            index={ this.props.index }
                            id={ this.props.id }>
            {
                this.props.widgetInstances.items.map((model, index) => (
                        <WidgetInstance key={ model.id }
                                        index={ index }
                                        model={ model }
                                        parentId={ this.props.id }
                                        breadcrumbsIds={ this.breadcrumbsIds }
                                        id={ model.id } />
                    ),
                )
            }
        </BaseElement>;
    }
}

export default observer(Col);
