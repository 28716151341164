import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, reaction, makeObservable } from 'mobx';
import { helperRedirect } from 'helpers/history';

import StringInput from 'components/dynamicForms/view/field/stringInput';

import Button from 'components/button';

import styles from 'components/table/styles.module.scss';

import IconChevronR from 'assets/img/icons/chevron-right.svg';
import IconChevronDoubleR from 'assets/img/icons/chevrons-right.svg';
import IconChevronL from 'assets/img/icons/chevron-left.svg';
import IconChevronDoubleL from 'assets/img/icons/chevrons-left.svg';
import langStore from 'globalState/lang';
import { ATTRIBUTES } from 'constants/attributesForTests';
import GlobalPortal from 'components/globalPortal';

class Pagination extends React.Component {
    maxPage;
    value;
    refInput = React.createRef();
    refInputContainer = React.createRef();

    constructor(props) {
        super(props);

        makeObservable(this, {
            maxPage: observable,
            value: observable,
        });

        this.calcParams();
        this.value = this.props.pagination.page;

        reaction(
            () => this.value,
            () => {
                this.setInputWidth();
            },
        );
    }

    calcParams() {
        this.maxPage = Math.ceil(this.props.pagination.total / this.props.pagination.per_page);
    }

    componentDidMount() {
        this.setInputWidth();
    }

    componentDidUpdate(prevProps) {
        if (this.props.pagination.page !== prevProps.pagination.page) {
            this.value = this.props.pagination.page;
        }
        this.calcParams();
    }

    toPrev = () => this.toPage(this.props.pagination.page - 1);
    toNext = () => this.toPage(parseInt(this.props.pagination.page, 10) + 1);
    toFirst = () => this.toPage(1);
    toLast = () => this.toPage(this.maxPage);

    onChange = ({ value }) => {
        this.value = value;
    };

    changePage = (e) => {
        if (e.keyCode === 13) {
            this.toPage(this.value);
        }
    };

    toPage = (page) => {
        const { onChangePage } = this.props;
        if (page < 1) page = 1;
        if (page > this.maxPage) page = this.maxPage;

        if (onChangePage) {
            onChangePage(page);
            return;
        }

        const searchParams = new URLSearchParams(location.search);
        searchParams.set('page', page);
        helperRedirect({
            search: searchParams.toString(),
        });
    };

    setInputWidth = () => {
        const inputMaxWidth = 68;
        const inputEl = this.refInput ? this.refInput.current ? this.refInput.current : null : null;
        const containerEl = this.refInputContainer ? this.refInputContainer.current ? this.refInputContainer.current : null : null;

        if (!inputEl || !containerEl) return;

        containerEl.innerHTML = this.value.toString();
        const containerWidth = containerEl.getBoundingClientRect().width;
        const inputWidth = containerWidth > inputMaxWidth ? inputMaxWidth : containerWidth;
        inputEl.style.width = `${ inputWidth }px`;
    };

    render() {
        const { list_titles } = langStore.getTranslate();
        const { page } = this.props.pagination;
        if (!this.props.pagination.per_page || this.props.pagination.total < this.props.pagination.per_page) {
            return null;
        }
        return (
            <div className={ `${ styles.Pagination } ${ this.props.isMobile ? styles.mobile : '' }` }>
                { page !== 1 &&
                    <>
                        <Button
                            buttonType='icon'
                            svg={ IconChevronDoubleL }
                            onClick={ this.toFirst }
                            data-test={ ATTRIBUTES.tablePaginationToFirstPage }
                        />
                        <Button
                            buttonType='icon'
                            svg={ IconChevronL }
                            onClick={ this.toPrev }
                            data-test={ ATTRIBUTES.tablePaginationPrevPage }
                        />
                    </>
                }
                <span className={ styles.PaginationText }>
                    <StringInput
                        isServicePortal={ this.props.isServicePortal }
                        onChange={ this.onChange }
                        value={ this.value }
                        onKeyDown={ this.changePage }
                        ref={ this.refInput }
                        className={ `${ styles.PaginationInput }` }
                        data-test={ ATTRIBUTES.tablePagintaionInput }
                    />
                    { list_titles && list_titles.of } <span data-test={ ATTRIBUTES.tablePaginationTotalPages }>{ this.maxPage }</span>
                </span>
                { page !== this.maxPage &&
                    <>
                        <Button
                            buttonType='icon'
                            svg={ IconChevronR }
                            onClick={ this.toNext }
                            data-test={ ATTRIBUTES.tablePaginationNextPage }
                        />
                        <Button
                            buttonType='icon'
                            svg={ IconChevronDoubleR }
                            onClick={ this.toLast }
                            data-test={ ATTRIBUTES.tablePaginationToLastPage }
                        />
                    </>
                }
                <GlobalPortal>
                    <div className={ styles.PaginationInputContainer } ref={ this.refInputContainer } />
                </GlobalPortal>
            </div>
        );
    }
}

export default observer(Pagination);


