import * as React from 'react';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import RecordPopupButton from 'components/recordPopup/infoButton';
import { getLinkDisplayValue } from 'helpers/form';
import IconCross from 'assets/img/icons/close-x.svg';
import IconEye from 'assets/img/icons/eye-off.svg';
import UsersIcon from 'assets/img/icons/user-many.svg';
import { LINK_TITLE_UNAVAILABLE, LINK_NOT_FOUND, LINK_UNAVAILABLE } from 'constants/strings';
import { ATTRIBUTES } from 'constants/attributesForTests';
import {BadgesProps, BadgeValue} from "types/components/dynamicForms/view/field/list/badges";

class BadgesComponent extends React.Component<BadgesProps> {
    constructor(props) {
        super(props);
    }

    onRemoveClick(event, item: BadgeValue) {
        const { onRemoveItem } = this.props;
        event.stopPropagation();
        event.preventDefault();
        if (onRemoveItem) {
            onRemoveItem(item);
        }
    }

    onBtnClick = () => {
        const { onClick } = this.props;

        if (onClick){
            onClick();
        }
    };

    renderBadge = (item: BadgeValue, index) => {
        const {
            isWindow,
            readOnly,
            tableName,
            pureBadge,
            columnId,
        } = this.props;
        const isDisabled = item?.reference_state === LINK_UNAVAILABLE || item?.reference_state === LINK_NOT_FOUND;
        const isHasDelegators = item?.has_delegate;
        const isUnavailable = item?.reference_state === LINK_UNAVAILABLE || item?.reference_state === LINK_TITLE_UNAVAILABLE;
        const badgeEye = <div className={ styles.BadgeEye } dangerouslySetInnerHTML={ { __html: IconEye } } data-test={ ATTRIBUTES.badgeEye } />;

        const displayValue = getLinkDisplayValue(item);

        if (pureBadge) {
            const isLastElem = this.props.values.length - 1 === index;
            return (
                <div
                    key={ item.database_value }
                    className={ styles.PureBadge }
                >
                    { isUnavailable &&
                        <span className={ styles.PureBadgeEye } dangerouslySetInnerHTML={ { __html: IconEye } } data-test={ ATTRIBUTES.badgeEye } />
                    }
                    <span className={isUnavailable || isDisabled ? styles.UnavailableText : undefined }>
                        { displayValue }
                        { isLastElem ? '' : ',' }
                    </span>
                </div>
            );

        }
        const isHaveIcon = isUnavailable || isHasDelegators;

        if (isWindow) {
            return (
                <button
                    key={ item.database_value }
                    className={ `${styles.Badge} ${styles.cursorDefault} ${readOnly ? styles.readOnly : ''} ${isDisabled ? styles.disabled : ''} ${isHaveIcon ? styles.hasTwoIcons : ''}` }
                    onClick={ this.onBtnClick }
                    type={ 'button' }
                >
                    { isUnavailable && badgeEye }
                    { isHasDelegators && (
                        <div
                            className={ styles.BadgeDelegation }
                            dangerouslySetInnerHTML={ { __html: UsersIcon } }
                            data-test={ ATTRIBUTES.badgeDelegationIcon }
                        />
                    )}
                    { displayValue }
                    { !readOnly && <div
                        className={ styles.BadgeCross }
                        dangerouslySetInnerHTML={ { __html: IconCross } }
                        onClick={ (e) => {
                            this.onRemoveClick(e, item);
                        } }
                    />
                    }
                </button>
            );
        }
        return (
            <RecordPopupButton
                key={ item.database_value }
                fieldType="record_field"
                essence={ tableName }
                sys_id={ item.database_value }
                refColumnId={columnId}
                className={ `${styles.Badge} ${readOnly ? styles.readOnly : ''} ${isDisabled ? styles.disabled : ''}  ${isUnavailable ? styles.hasTwoIcons : ''}` }
                onClick={ this.onBtnClick }
                isDisabled={ isDisabled }
            >
                { isUnavailable && (
                    <div
                        className={ styles.BadgeEye }
                        dangerouslySetInnerHTML={ { __html: IconEye } }
                        data-test={ ATTRIBUTES.badgeEye }
                    />
                )}
                { isHasDelegators && (
                    <div
                        className={ styles.BadgeDelegation }
                        dangerouslySetInnerHTML={ { __html: UsersIcon } }
                        data-test={ ATTRIBUTES.badgeDelegationIcon }
                    />
                )}
                { displayValue }
                { !readOnly && <div
                    className={ styles.BadgeCross }
                    dangerouslySetInnerHTML={ { __html: IconCross } }
                    onClick={ (e) => {
                        this.onRemoveClick(e, item);
                    } }
                />
                }
            </RecordPopupButton>
        );
    };

    render() {
        const badges = this.props.values.map(this.renderBadge);

        return (
            <div className={ `${ styles.Badges }` }>
                { badges }
            </div>
        );
    }
}

export default observer(BadgesComponent);
