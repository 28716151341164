import reportState, { IS_LOAD_DATA, IS_UPDATE_CHART } from 'globalState/report';
import { REPORT_ERROR_KEY } from 'helpers/report/report';
import ChartLoader from 'components/chart/chartLoader';
import * as React from 'react';
import langStore from 'globalState/lang';
import styles from 'pages/report/styles.module.scss';
import BurgerMenu from 'components/burgerMenu';
import Button from 'components/button';
import { getUrlParams } from 'helpers/data';
import { ATTRIBUTES } from 'constants/attributesForTests';
import sidebarState from 'globalState/sidebarState';

/**
 * Функция, которая рендерит текст при слишком большом кол-ве данных. Со специальной ссылкой, при нажатии на которую, открывается фильтр.
 * @returns {*}
 */
const renderFilterLink = () => {
    const { report } = langStore.getTranslate();
    const filterLink = (<span className={ styles.LinkFilter } onClick={ reportState.toggleIsOpen }>{ report && report.filter }</span>);

    let textFilter = report && report.too_much_data;
    let filterTextLink;
    if (textFilter) {
        textFilter = textFilter.split('{filterLink}');
        if (Array.isArray(textFilter) && textFilter.length > 1) {
            return (
                <div className={ styles.LoadingViewText }>
                    { textFilter[0] } { filterLink } { textFilter[1] }
                </div>);
        }
    }
    return (
        <div className={ styles.LoadingViewText }>
            { filterTextLink }
        </div>);
};

/**
 * Функция, которая рендерит ошибки репорта (когда слишком много данных или данных нет вообще)
 * @returns {*}
 */
export const renderReportError = () => {
    // переводы
    const { report } = langStore.getTranslate();
    let textHeader,
        textLoading;
    switch (reportState.indexReportError) {
        case REPORT_ERROR_KEY.tooMuchData:
            textHeader = report && report.ooops;
            textLoading = renderFilterLink();
            break;
        case REPORT_ERROR_KEY.noData:
            textLoading = report && report.there_is_no_data;
            break;
        case REPORT_ERROR_KEY.hiddenColumn:
            textHeader = report && report.report_can_not_be_displayed;
            textLoading = report && report.check_source_data;
            break;
    }
    return <ChartLoader textHeader={ textHeader } textLoading={ textLoading } isMasterReport />;
};

/**
 * Функция, которая рендерит заглушку для фильтра.
 */
export const renderReportLoader = (location) => {
    // переводы
    const { report_type } = langStore.getTranslate();
    if (!reportState.table || !!(reportState.stateFlags & IS_LOAD_DATA)) {
        const params = getUrlParams(location.search);
        if (params.table_id && !!(reportState.stateFlags & IS_LOAD_DATA)) {
            return <ChartLoader />;
        }
        return <ChartLoader
            textLoading={ report_type && report_type.fill_in_the_data_tab } />;
    }
};

export const renderUpperRightBar = (burgerMenuItems, save, updateChart) => {
    // переводы
    const { report_type } = langStore.getTranslate();
    const { type, name, chart } = reportState;
    return (
        <div className={ `${styles.FixedHeader} ${sidebarState.isPinned && sidebarState.isOpened ? styles.pinnedAside : ''}` }>
            <div className={ styles.ViewHeader }>
                <div>
                    {
                        <BurgerMenu data={ burgerMenuItems } isDisable={ !burgerMenuItems || !burgerMenuItems.length } />
                    }
                    <span className={ styles.ViewTitle } dangerouslySetInnerHTML={ { __html: name } } />
                </div>

                <div className={ styles.ViewHeaderRightSide }>
                    <div>
                        <Button
                            key="button-update"
                            className={ styles.Button }
                            disabled={ !(reportState.table && type && name) || !!(reportState.stateFlags & IS_UPDATE_CHART) }
                            buttonType="primary"
                            onClick={ () => updateChart() }
                            data-test={ ATTRIBUTES.reportButtonRefresh }
                        >
                            { report_type && report_type.refresh_button }
                        </Button>
                        { (!chart || !chart.read_only) && <Button
                            key="button-save"
                            className={ styles.Button }
                            disabled={ !(reportState.table && type && name) }
                            onClick={ save }
                            data-test={ ATTRIBUTES.reportButtonSave }
                        >
                            { report_type && report_type.save_button }
                        </Button> }
                    </div>
                </div>
            </div>
            <div
                data-test={ ATTRIBUTES.reportViewTableName }
                className={ styles.ViewTableName }
            >
                { report_type && report_type.table }: { reportState.table && reportState.table.display_value }
            </div>
        </div>
    );
};
