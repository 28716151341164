import BaseFormFieldModel from './BaseFormFieldModel';
import _ from 'lodash';
import { action, observable, reaction, makeObservable } from 'mobx';
import FormsState from 'globalState/forms';
import { fetchDotWalkList } from 'actions/conditions';
import { isChanged } from 'helpers/form';

export default class TemplateModel extends BaseFormFieldModel {

    /**
     * id таблицы
     * tableId
     *
     * @type {string}
     */
    tableId;

    /**
     * связь с полем
     * dependentOnColumnId
     *
     * @type {String}
     */
    dependentOnColumnId;

    /**
     * тип поля
     * columnType
     *
     * @type {String}
     */
    columnType = 'template';

    /**
     * массив колонок
     * data
     *
     * @type {Array}
     */
    data = [];

    /**
     * состояние Template
     * templateState
     *
     * @type {Template}
     */
    templateState = {};

    /**
     * активный шаблон
     * activeTemplate
     *
     * @type {String}
     */
    activeTemplate = null;

    /**
     * поле не содержит значения
     * isEmpty
     *
     * @type {Boolean}
     */
    isEmpty = false;

    constructor(data, parentFormSectionModel) {
        super(data, parentFormSectionModel);

        makeObservable(this, {
            tableId: observable,
            dependentOnColumnId: observable,
            columnType: observable,
            data: observable,
            templateState: observable,
            activeTemplate: observable,
            isEmpty: observable,
            fetchData: action,
            removeTemplateField: action,
            setActiveTemplate: action,
        });

        super.mergeData(data);
    }

    uiGetValue() {
        if (_.isEmpty(this.value)) {
            return null;
        }
        return this.value;
    }

    uiSetValue(value) {
        if (value === '') {
           return this.uiClearValue();
        }

        try {
            const val = typeof value === 'string' ? JSON.parse(value) : value;
            let templateValue = {};
            _.forEach(val, (v) => {
                templateValue = { ...templateValue, ...v };
            });
            this.value = templateValue;
            this.changed = isChanged(this.defaultValue, this.value);
        }
        catch (error) {
            console.error(error);
        }
    }

    getValueForSave(){
        return typeof this.value === 'string' ? this.value : JSON.stringify(this.value);
    }

    async events() {
        if (!this.dependentOnColumn) {
            return;
        }
        reaction(
            () => {
                let result;
                const component = FormsState.getField(this.dependentOnColumn);
                if (component){
                    result = component.value;
                }
                return result;
            },
            (tableId) => {
                if (tableId) {
                    this.fetchData(tableId, true);
                }
            },
        );

        const component = FormsState.getField(this.dependentOnColumn);
        if (component){
            const tableId = component.value;
            if (tableId) {
                this.fetchData(tableId, true);
            }
        }
    }

    fetchData = async (table) => {
        if (!table || Array.isArray(table) || table && !table.database_value ) {
            this.isEmpty = true;
            return;
        }
        const params = {
            referenced_table_id: table.database_value,
        };
        const response = await fetchDotWalkList(params);
        this.data = this.getItems(response.data.items);
        this.tableId = response.data.table_id || '';
        this.isEmpty = response.data.items.length === 0;
        const _value = this.templateState.parseValue(this.value, this.data, this.tableId);
        this._value = undefined;   // Хак, чтобы не срабатывал onChange в клиент. скриптах при загрузке сериализованных данных из сервера
        this.value = _value;
        const { onChange } = this;
        if (onChange) {
            onChange({
                value: JSON.stringify(this.value),
                component: this,
            });
        }
    };

    getItems = (items) => {
        const notAllowedItems = [
            'Application',
            'Policy',
            'ID',
            'Created at',
            'Created by',
            'Updated at',
            'Updated by',
            'Number',
        ];
        return items.filter(item => !!item.column_id && !notAllowedItems.includes(item.column_title));
    };

    filteringItems = () => {
        const columnIds = this.templateState.getActiveColumnIds();
        return this.data.length > 0 ? this.data.filter(row => !columnIds.includes(row.column_id)) : [];
    };

    removeTemplateField = (id) => {
        this.templateState.removeTemplateRow(id);
        this.value = this.templateState.getResult();
        this.changed = isChanged(this.defaultValue, this.value);
        const { onChange } = this;
        if (onChange) {
            onChange({
                value: JSON.stringify(this.value),
                component: this,
            });
        }
    };

    changeValue = (isValid = true) => {
        this.value = this.templateState.getResult();
        this.changed = isChanged(this.defaultValue, this.value);
        this.isValid = isValid;
        const { onChange } = this;
        if (onChange) {
            onChange({
                value: JSON.stringify(this.value),
                component: this,
            });
        }
    };

    setActiveTemplate = (id) => {
        this.activeTemplate = id;
    };
}
