import { DataMenuType, MenuItemType } from 'types/globalState/menu';
import _ from 'lodash';
import { observable, makeObservable } from 'mobx';

export class MenuItem implements MenuItemType {
    private _isDefaultOpened: boolean;
    private _isEditAccess: boolean;
    private _editUrl: string;
    private _isFavorite: boolean;
    private _label: string;
    private _order: number;
    private _sysId: string;
    private _url: string;
    private _items: MenuItem[] = [];
    private _dynamic = false;
    private _isActive = false;
    private _icon = '';
    private _isCategory = false;
    private _isItem = false;
    private _isShow = true;

    constructor(data: DataMenuType) {
        makeObservable(this, {
            _isFavorite: observable,
            _isActive: observable,
            _isShow: observable,
        });

        if (data.default_opened !== undefined) {
            this._isDefaultOpened = data.default_opened;
        }
        if (data.edit_access !== undefined) {
            this._isEditAccess = data.edit_access;
        }
        if (data.edit_url !== undefined) {
            this._editUrl = data.edit_url;
        }
        if (data.is_favorite !== undefined) {
            this._isFavorite = data.is_favorite;
        }
        if (data.label !== undefined) {
            this._label = data.label;
        }
        if (data.order !== undefined) {
            this._order = data.order;
        }
        if (data.sys_id !== undefined) {
            this._sysId = data.sys_id;
        }
        if (data.url !== undefined) {
            this._url = data.url;
        }
        if (data.dynamic !== undefined) {
            this._dynamic = data.dynamic;
        }
        if (data.items?.length) {
            this._items = _.map(data.items, item => new MenuItem(item));
        }
        if (data.icon !== undefined) {
            this._icon = data.icon;
        }
        if (data.isCategory !== undefined) {
            this._isCategory = data.isCategory;
        }
        if (data.isItem !== undefined) {
            this._isItem = data.isItem;
        }
    }

    getIsDefaultOpened(): boolean {
        return this._isDefaultOpened;
    }

    setIsDefaultOpened(value: boolean) {
        this._isDefaultOpened = value;
    }

    getIsEditAccess(): boolean {
        return this._isEditAccess;
    }

    setIsEditAccess(value: boolean) {
        this._isEditAccess = value;
    }

    getEditUrl(): string {
        return this._editUrl;
    }

    setEditUrl(value: string) {
        this._editUrl = value;
    }

    getIsFavorite(): boolean {
        return this._isFavorite;
    }

    setIsFavorite(value: boolean) {
        this._isFavorite = value;
    }

    getLabel(): string {
        return this._label;
    }

    setLabel(value: string) {
        this._label = value;
    }

    getOrder(): number {
        return this._order;
    }

    setOrder(value: number) {
        this._order = value;
    }

    getSysId(): string {
        return this._sysId;
    }

    setSysId(value: string) {
        this._sysId = value;
    }

    getUrl(): string {
        return this._url;
    }

    setUrl(value: string) {
        this._url = value;
    }

    getItems(): MenuItem[] {
        return this._items;
    }

    setItems(value: MenuItem[]) {
        this._items = value;
    }

    getDynamic(): boolean {
        return this._dynamic;
    }

    setDynamic(value: boolean) {
        this._dynamic = value;
    }

    getIsActive(): boolean {
        return this._isActive;
    }

    setIsActive(value: boolean) {
        this._isActive = value;
    }

    getIcon(): string {
        return this._icon;
    }

    setIcon(value: string) {
        this._icon = value;
    }

    getIsCategory(): boolean {
        return this._isCategory;
    }

    setIsCategory(value: boolean) {
        this._isCategory = value;
    }

    getIsItem(): boolean {
        return this._isItem;
    }

    setIsItem(value: boolean) {
        this._isItem = value;
    }

    getIsShow(): boolean {
        return this._isShow;
    }

    setIsShow(value: boolean) {
        this._isShow = value;
    }
}
