import apiRequest from 'lib/apiRequest';
import { handleResponse } from 'helpers/action';
import { AutoSuggestParams, AutoSuggestResponse } from 'types/components/suggestDropDown';

export const fetchAutoSuggestData = async (params: AutoSuggestParams): Promise<AutoSuggestResponse> => {
    const response = await new apiRequest(`GET /autosuggest/reference/`).qs(params).send();
    return handleResponse(response);
};

export const fetchAutoSuggestDelegationData = async (params: AutoSuggestParams): Promise<AutoSuggestResponse> => {
    const response = await new apiRequest(`GET /autosuggest/user`).qs(params).send();
    return handleResponse(response);
};
