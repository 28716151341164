import * as React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import _, { isNull } from 'lodash';

import { observable, makeObservable, runInAction, action } from 'mobx';
import FieldFactory from 'components/dynamicForms/view/form/fieldFactory';
import QuickMessage from 'components/quickMessage';
import styles from 'components/groupedTable/styles.module.scss';

import globalTableState from 'globalState/table/index';
import langStore from 'globalState/lang/index';
import CellEditor from 'components/groupedTable/cellEditor';
import TableTitle from 'components/groupedTable/tableTitle';
import CellDescription from 'components/groupedTable/cellDescription/index';
import { getUrlParams, isEqual } from 'helpers/data';
import IconEyeOff from 'assets/img/icons/eye-off.svg';
import InfoMessagesState from 'globalState/infoMessages';
import { runOnTypeScripts, TYPE_ON_CELL_EDIT } from 'helpers/scriptClientHelper';
import IconChevronUp from 'assets/img/icons/chevron-up.svg';
import IconChevronDown from 'assets/img/icons/chevron-down.svg';
import Tooltip from 'components/tooltip';
import mapper from 'components/dynamicForms/model/field/mapper';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { filterArrayForList, getCellValue, isNotSet } from 'helpers/tableHelpers';
import RefLink from 'components/groupedTable/refLink/index';
import type {
    CellProps,
    CurrentCellDataType,
    CustomAttrType,
    DatabaseValueType,
    ValueType,
} from 'types/components/table/cell';
import { OneOrManyOrNull } from 'types/common';
import { fetchCellData, getRuntimeCellValue, updateCellData } from 'actions/record';
import { fetchListFilter } from 'actions/list';
import currentCellState from 'globalState/table/currentCell';
import subFormState from 'globalState/subForm';
import reportState from 'globalState/report';
import type { ParamsOfScript } from 'types/helpers/scriptClientHelper';
import BaseFormFieldModel from 'components/dynamicForms/model/field/BaseFormFieldModel';
import { LINK_TITLE_UNAVAILABLE, LINK_NOT_FOUND, LINK_UNAVAILABLE } from 'constants/strings';
import { getLinkDisplayValue } from 'helpers/form';
import { EXCLUDE_TABLES } from "./config";

/**
 * Cell - компонент ячейки в таблице.
 * */
export class Cell extends React.Component<CellProps> {
    sortType = 'desc';
    message = null;
    isShowHeadListMenu = false;
    isShowPopup = false;
    value: OneOrManyOrNull<ValueType | boolean | string | number> = null;
    isValidField = true;
    validateMessage = '';
    disabledButtonSave = false;
    database_value: DatabaseValueType | string | null = '';
    timeout: NodeJS.Timeout;

    cellRef: React.RefObject<HTMLElement> = React.createRef();
    field: React.RefObject<HTMLElement> = React.createRef();
    cursorPosition;
    badgeColor = '';
    response;
    cellType = 'td';
    writeAccess = false;
    isMandatory = false;
    special;
    dependentOnColumn;

    constructor(props) {
        super(props);

        makeObservable(this, {
            message: observable,
            isShowHeadListMenu: observable,
            isShowPopup: observable,
            value: observable,
            isValidField: observable,
            validateMessage: observable,
            disabledButtonSave: observable,
            database_value: observable,
            badgeColor: observable,
            cellType: observable,
            writeAccess: observable,
            isMandatory: observable,
            special: observable,
            dependentOnColumn: observable,
            handleDoubleClick: action,
            tooltip: action,
            hidePopup: action,
            showPopup: action,
            resetEdit: action,
            onChange: action,
            save: action,
            errorFetchData: action,
        });

        runInAction(() => {
            this.cellType = this.isHead() ? 'th' : 'td';
            const { data } = props;

            this.badgeColor = this.getStateColor(data.color);
        });
    }

    componentDidUpdate(prevProps) {
        const { data } = this.props;
        if (!isEqual(prevProps.data, data)) {
            runInAction(() => {
                this.badgeColor = this.getStateColor(data.color);
            });
        }

        if (this.props.isFixedHead) {
            this.props.table.setFixedHeadColSizes();
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.onDocumentClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onDocumentClick);
    }

    getStateColor(color) {
        return color ? color : '';
    }

    onDocumentClick = ({ target }) => {
        const { current: cellEl } = this.cellRef;
        if (!cellEl || !cellEl.contains || this.props.table.props.isWindow || this.props.isMobile) return;

        if (cellEl.contains(target)) {
            cellEl.classList.add(styles.focus);
        } else {
            cellEl.classList.remove(styles.focus);
        }
    };

    errorFetchData = (message) => {
        this.disabledButtonSave = false;
        InfoMessagesState.pushError(message);
    };

    hidePopup = () => {
        clearTimeout(this.timeout);
        this.isShowPopup = false;
    };

    showPopup = () => {
        this.timeout = setTimeout(() => {
            this.isShowPopup = true;
        }, 300);
    };


    handleClick = (evt) => {
        const { location } = this.props;
        if (location && location.pathname && location.pathname === '/report') {
            return false;
        }

        if (this.isHead() && this.canSorted()) {
            const currentCell: CurrentCellDataType = {
                attribute: this.props.field,
                value: this.getValue(),
                tableName: this.props.table.props.essence,
                fieldName: this.props.head_column_name,
                tableId: globalTableState.getTableId(),
                columnId: this.props.db_column_id,
                originColumnId: this.props.origin_column_id,
                recordColumnName: globalTableState.getRecordColumnName(),
                recordId: this.props.id,
                columnType: this.props.column_type,
            };
            const isSame = _.isEqual((window as any).currentCell, currentCell);
            (window as any).currentCell = currentCell;
            currentCellState.setData(currentCell);

            let x = evt.pageX;
            let y = evt.pageY;
            if (evt.target.closest('th')) {
                const title = evt.target.closest('th').getElementsByTagName("div")[0];
                if (title) {
                    const titleRect = title.getBoundingClientRect();
                    x = titleRect.left;
                    y = titleRect.top + titleRect.height + window.pageYOffset + 4;
                }
            }

            this.props.table.menuCoordinates.x = x;
            this.props.table.menuCoordinates.y = y;
            this.props.table.isShowContextMenuCondition = isSame ? !this.props.table.isShowContextMenuCondition : true;
        } else {
            if (this.props.table.props.isWindow) {
                this.selectDictionary();
            }
        }
    };


    handleContextMenu = (e) => {
        if ((!this.isHead() && !this.props.data.read_access) || this.props.table.editCol) {
            return;
        }
        e.preventDefault();
        const currentCell: CurrentCellDataType = {
            attribute: this.props.field,
            value: this.getValue(),
            tableName: this.props.table.props.essence,
            fieldName: this.props.head_column_name,
            tableId: globalTableState.getTableId(),
            columnId: this.props.db_column_id,
            originColumnId: this.props.origin_column_id,
            recordColumnName: globalTableState.getRecordColumnName(),
            recordId: this.props.id,
            columnType: this.props.column_type,
        };
        (window as any).currentCell = currentCell;
        currentCellState.setData(currentCell);
        this.props.table.menuCoordinates.x = e.pageX;
        this.props.table.menuCoordinates.y = e.pageY;
        if (this.isHead() && this.canSorted()) {
            this.props.table.isShowContextMenuCondition = true;
        }
        this.props.table.isShowContextMenu = !this.isHead();
    };

    getInlineValue(value): JSX.Element | string | null {
        const { list_titles, template_titles, reference_titles } = langStore.getTranslate();

        const { data, db_column_name, column_type, database_value, validate } = this.props;

        if (this.isHead()) {
            return data;
        }
        let unavailableDataText;

        if ([ LINK_UNAVAILABLE, LINK_TITLE_UNAVAILABLE, LINK_NOT_FOUND ].includes(value?.reference_state)) {
            if (value?.reference_state === LINK_NOT_FOUND && column_type === 'field_name') {
                unavailableDataText = _.lowerCase(reference_titles?.placeholder_column_not_found);
            } else {
                unavailableDataText = getLinkDisplayValue(value);
            }
        }
        if (unavailableDataText) {
            if ([ LINK_UNAVAILABLE, LINK_TITLE_UNAVAILABLE ].includes(value?.reference_state)) {
                return <>
                    <span className={ styles.BadgeEye }
                          data-test={ ATTRIBUTES.badgeEye }
                          dangerouslySetInnerHTML={ { __html: IconEyeOff } } />
                    <span className={ styles.NotSet }>{ unavailableDataText }</span>
                </>;
            } else {
                return <span className={ styles.NotSet }>{ unavailableDataText }</span>;
            }
        }

        const NOT_SET = <span className={ styles.NotSet }>({ list_titles && list_titles.not_set })</span>;
        let result = NOT_SET;
        if (db_column_name === 'sys_id') {
            if (typeof database_value === 'object' && !_.isNil(database_value)) {
                if (isNotSet(database_value.value)) {
                    return NOT_SET;
                } else {
                    return database_value.value;
                }
            } else {
                return database_value;
            }
        }

        const cellValue = getCellValue(value, column_type, validate);
        if (column_type === 'template') {
            if (!isNotSet(value)) {
                result = <span className={ styles.NotSet }>{ template_titles && template_titles.filled }</span>;
            }
        } else if (!isNull(cellValue)) {
            result = cellValue;
        }

        if (result !== NOT_SET) {
            if (this.badgeColor) {
                result = (
                    <div className={ [
                        styles.stateBadge,
                        styles[this.badgeColor],
                    ].join(' ') }>
                        { result }
                    </div>
                );
            }
        }
        return result;
    }


    tooltip = (message) => {
        this.message = message;
        setTimeout(() => this.message = null, 1000);
    };

    changeSortingByDoubleClick = () => {
        const { location, onChangeSorting } = this.props;
        if (location && location.pathname && location.pathname === '/report') {
            return;
        }
        if (this.isHead() && this.canSorted()) {
            const { filter_titles } = langStore.getTranslate();
            const desc = filter_titles && filter_titles.z_to_a ? filter_titles.z_to_a : 'desc';
            const { field } = this.props;
            const sortRow = this.props.sortingFields.find(sortRow => sortRow.getField().dot_walking_attribute === field);
            if (sortRow && sortRow.getDirection()) {
                return;
            }
            this.sortType = desc;
            if (onChangeSorting) {
                onChangeSorting(field, this.sortType);
            }
        }
    };

    handleDoubleClick = async (e) => {
        const {
            field,
            column_type,
            isMobile,
            table,
            table_name,
            db_column_name,
            id,
            data,
            database_value,
            handleChangeEditCol,
        } = this.props;
        const isDotWalking = typeof field === 'string' && field.includes('.');
        const { list_titles } = langStore.getTranslate();
        if (isDotWalking) {
            this.tooltip(list_titles.read_only);
            return;
        }
        if (column_type === 'template') {
            return;
        }

        e.persist();
        e.preventDefault();

        if (!table || this.isHead() || this.disabledButtonSave || isMobile || this.isEditColOpened()) {
            return;
        }

        const response = await fetchCellData(table_name || '', db_column_name || '', id || '');
        this.writeAccess = response.data.write_access;
        this.isMandatory = !!response.data.column?.is_mandatory;
        this.special = response.data.column?.special;
        this.dependentOnColumn = response.data.column?.dependent_on_column;

        const editCol = table.props.columns.find((col) => field === col.attribute);
        if (!editCol) return;

        if (column_type === 'choice' && this.writeAccess) {
            editCol.special = response.data.column?.special;
        }

        if (editCol.read_only
            || !this.writeAccess
            || editCol.type === 'html'
            || editCol.deny_editing) {
            this.tooltip(list_titles.read_only);
            return;
        }
        this.database_value = database_value;

        editCol.clickCoordinates = {
            x: e.pageX,
            y: e.pageY,
        };

        this.value = data.value;

        if (handleChangeEditCol) {
            handleChangeEditCol({
                editCol: editCol,
                editRow: id,
            });
        }
        subFormState.setEditCell(this);
        (window as any).listEditReferenceCell = this;// пока у нас доп листы открываются в окне (/dictionary)
    };

    getValue = () => {
        const { column_type, database_value, data } = this.props;
        if (data.value === undefined) {
            return data;
        }
        const value = data.value;

        if ((Array.isArray(value) && value.length === 0) || _.isNil(column_type)) {
            return null;
        }
        if (column_type === 'choice') {
            return database_value === '' ? null : database_value;
        }
        if ([
            'reference',
            'record_class',
            'field_name',
        ].includes(column_type)) {
            return value.database_value;
        }
        if (column_type === 'list') {
            return value.map((item) => item.database_value).join(',');
        }
        if (column_type === 'id') {
            return value.document_id;
        }
        if (this.value === 0) {
            return '0';
        }
        return value;
    };


    onContextMenu = (e) => {
        if (!this.props.data.read_access) {
            return;
        }
        e.preventDefault();
        const currentCell: CurrentCellDataType = {
            attribute: this.props.field,
            value: this.getValue(),
            tableName: this.props.table.props.essence,
            fieldName: this.props.db_column_name,
            tableId: this.props.table.props.tableId,
            columnId: this.props.db_column_id,
            columnType: this.props.column_type,
            originColumnId: this.props.origin_column_id,
            recordColumnName: globalTableState.getRecordColumnName(),
            recordId: this.props.id,
        };
        (window as any).currentCell = currentCell;
        currentCellState.setData(currentCell);
    };

    resetEdit = (isNotReset) => () => {
        if (isNotReset) {
            return;
        }
        const { handleChangeEditCol } = this.props;
        if (handleChangeEditCol) {
            handleChangeEditCol({
                editCol: null,
                editRow: null,
            });
        }
        this.value = null;
    };

    getValueForSave() {
        const { column_type } = this.props;
        if (typeof this.value === 'undefined' || this.value === null) {
            return null;
        }
        if (this.value && _.isPlainObject(this.value)) {
            const valueObj: ValueType = (this.value as ValueType);
            if ('database_value' in valueObj) {
                return valueObj.database_value;
            }
            if ('sys_id' in valueObj) {
                return valueObj.sys_id;
            }
            if (column_type === 'id' && 'document_id' in valueObj) {
                return valueObj.document_id;
            }
        }

        if (column_type === 'list' && Array.isArray(this.value)) {
            return this.value.map((item: ValueType) => item.database_value).join(',');
        }
        return ([
            'boolean',
            'object',
        ].includes(typeof this.value)) ? this.value : this.value.toString();
    }

    save = async () => {
        const {
            clientScripts,
            db_column_id,
            db_column_name,
            table_name,
            record_table_name,
            id,
            data,
            formId,
            field,
            rowIndex,
        } = this.props;

        if (_.isNil(db_column_name)) {
            return;
        }
        const value = this.getValueForSave();

        if (!this.isValidField) {
            this.showPopup();
            return;
        }

        if (this.isMandatory) {
            const { list_titles = {} } = langStore.getTranslate();
            const REACT_NOT_SET = (
                <span className={ styles.NotSet }>({ list_titles.not_set })</span>
            );
            const checkedNewValue = this.getInlineValue(this.value);

            if (_.isEqual(checkedNewValue, REACT_NOT_SET)) {
                this.validateMessage = list_titles && list_titles.is_mandatory ? list_titles.is_mandatory : '';
                this.showPopup();
                return;
            }
        }

        this.disabledButtonSave = true;

        if (Array.isArray(clientScripts) && clientScripts.length) {
            const param = {
                oldValue: data.value,
                newValue: value,
                table: table_name,
                recordTableName: record_table_name,
                sysId: id,
                isContinue: true,
                current:
                    {
                        [db_column_name]: value,
                    },
                callback(isContinue = true) {
                    param.isContinue = isContinue;
                },
            };

            runOnTypeScripts(clientScripts, TYPE_ON_CELL_EDIT, db_column_id, (param as ParamsOfScript));
        }
        const { condition } = getUrlParams(this.props.location.search);

        const view = new URLSearchParams(window.location.search).get('view');

        let { isOkStatus} = await updateCellData(table_name || '',
            db_column_name || '',
            id || '',
            value);
        if (isOkStatus) {
            if (table_name && EXCLUDE_TABLES.includes(table_name)) {
                // Харкод по таблице в рамках задачи STSK0003739
                const { data } = await getRuntimeCellValue(table_name, db_column_name, id || '');
                if (!_.isEmpty(data)) {
                    globalTableState.updateCell(rowIndex, field, data);
                } else {
                    globalTableState.deleteRow(id);
                }
            } else {
                const params = _.omitBy({
                    condition: `sys_id=${ id }`,
                    view: view,
                    form_id: formId || '',
                }, _.isEmpty);
                const { data } = await fetchListFilter(table_name || '', params);
                const items = data?.items;

                if (Array.isArray(items) && items.length > 0) {
                    globalTableState.setRowByID(id, items[0]);
                } else {
                    globalTableState.deleteRow(id);
                }

                if (condition && condition.includes('GROUPBY')) {
                    globalTableState.updateGroupCells(id, items);
                }
            }
            this.disabledButtonSave = false;
            this.resetEdit(false)();
            this.badgeColor = this.getStateColor(data.value.color);
            //this.setNewValueCell();
        }
    };

    setNewValueCell = () => {
        const { rowIndex, field } = this.props;
        const stateValue = globalTableState.getCellValue(rowIndex, field);
        let value: OneOrManyOrNull<ValueType | string | boolean | number> = this.value;
        if (stateValue && typeof field !== 'string') {
            if (typeof stateValue.sys_id !== 'undefined') {
                value = {
                    sys_id: field.value,
                    display_value: field.display_value,
                };
            }
            if (typeof stateValue.database_value !== 'undefined') {
                value = {
                    database_value: field.value,
                    display_value: field.display_value,
                };
            }
            if (Array.isArray(stateValue)) {
                value = field.list;
            }
        }
        globalTableState.setCellValue(rowIndex, field, value);
    };


    isHead = () => {
        return this.props.type === 'head';
    };


    canSorted = () => {
        return this.props.fieldType !== 'journal_input';
    };


    isSortedField = () => {
        const { field } = this.props;
        const { filter_titles } = langStore.getTranslate();
        const asc = filter_titles && filter_titles.a_to_z;
        const sortRow = this.props.sortingFields.find(sortRow => sortRow.getField().dot_walking_attribute === field);
        this.sortType = sortRow ? sortRow.getDirection() : asc;
        return this.canSorted() && sortRow;
    };

    isEditColOpened = () => {
        return !!this.props.table.editCol;
    };

    getSortIcon = () => {
        const { filter_titles } = langStore.getTranslate();
        const asc = filter_titles && filter_titles.a_to_z;

        const getSortIconASC = <span className={ styles.TableSort }><span className={ styles.TableSortIcon }
                                                                          dangerouslySetInnerHTML={ { __html: IconChevronUp } } /></span>;
        const getSortIconDESC = <span className={ styles.TableSort }><span className={ styles.TableSortIcon }
                                                                           dangerouslySetInnerHTML={ { __html: IconChevronDown } } /></span>;

        return this.sortType === asc ?
            getSortIconASC :
            getSortIconDESC;
    };

    checkDisplayValue = (value) => {
        if (_.isPlainObject(value) && 'display_value' in value) {
            return value.display_value;
        } else if (_.isArray(value)) {
            return value.length ? filterArrayForList(value) : '';
        }
        return value;
    };

    selectDictionary = () => {
        const { label } = this.props;
        const displayValue = !_.isNil(label) ? this.checkDisplayValue(label.value) : '';
        let selectedValue;
        selectedValue = {
            database_value: this.props.id,
            display_value: displayValue || this.props.id,
            has_delegate: label?.has_delegate,
            reference_state: label?.reference_state ? label.reference_state : null,
        };
        window.opener.postMessage(selectedValue, '*');
    };

    /**
     * custom attributes
     */
    getCustomAttr = () => {
        const attr: CustomAttrType = {};

        if (this.props.column_type === 'field_name' && this.props.table.editCol) {
            attr.dependent_on_column = this.dependentOnColumn;
            attr.sys_table_name = this.props.table.props.essence;
            attr.sys_column_name = this.props.db_column_name;
            attr.recordId = this.props.id;
            attr.allElements = this.props.columns;
        }
        if (this.props.column_type && [ 'list', 'reference' ].includes(this.props.column_type)) {
            attr.special = this.props.special;
            attr.sysColumnName = this.props.db_column_name;
            attr.label = this.props.columnLabel;
            attr.sysColumnId = this.props.db_column_id;
        }
        return attr;
    };

    onChange = ({ value, isValid, validate: messages = { wrong_data_message: '' } }) => {
        this.isValidField = isValid;
        if (!isValid) {
            this.validateMessage = messages.wrong_data_message;
        }

        if (value && _.isPlainObject(value) && 'database_value' in value && isNull(value.database_value)
            && 'display_value' in value) {
            this.value = { ...value, display_value: null };
        } else {
            this.value = value;
        }
    };

    getChildren = () => {
        const { table } = this.props;
        const children = Array.isArray(this.props.children) ? this.props.children : [ this.props.children ];

        const isEdited = !!this.props.table
            && !!table.editCol
            && !table.editCol.read_only
            && table.editRow === this.props.id
            && table.editCol.attribute === this.props.field
            && this.writeAccess
            && !table.editCol.deny_editing;


        if (isEdited) {
            let model = {} as BaseFormFieldModel;
            const forReference = {
                sys_column_name: this.props.db_column_name,
                current: this.props.rowValues,
                table_name: this.props.table_name,
                column_id: this.props.db_column_id,
            };

            const fieldProps = {
                tableId: this.props.id,
                is_mandatory: this.isMandatory,
                column_type: this.props.column_type,
                special: this.special,
                validate: this.props.validate,
                ref: this.field,
                cellEditMode: true,
                cellSysId: this.props.id,
                forReference: forReference,
                value: this.value,
                ...this.getCustomAttr(),
            };

            try {
                model = new mapper[this.props.column_type](fieldProps);
                model.save = this.save;
            } catch (e) {
                console.error(e);
            }

            children.push(
                <CellEditor
                    key={ `edit${ this.props.field }` }
                    onSave={ this.save }
                    onReset={ this.resetEdit(_.isEmpty(reportState.getTypes()) && this.props.special?.can_create && subFormState.getIsShow()) }
                    cellRef={ this.cellRef }
                    clickCoordinates={ this.props.table.editCol.clickCoordinates }
                    isBooleanCol={ this.props.column_type === 'boolean' }
                    hideButtons={ this.props.column_type === 'boolean' || this.props.column_type === 'choice' }
                    isDisabled={ this.disabledButtonSave }
                >
                    <>
                        <FieldFactory
                            model={ model }
                            onChange={ this.onChange }
                        />
                        { this.isShowPopup && <Tooltip
                            onClose={ this.hidePopup }
                            parent={ this.field.current }
                        >
                            { this.validateMessage }
                        </Tooltip> }
                    </>
                </CellEditor>,
            );
        }

        if ((this.props.column_type && this.props.column_type.toLowerCase() === 'url' && this.value)
            || this.props.refLink
            || this.props.recordLink) {

            if (this.props.table.props.isWindow) { // таблица открыта в словаре - значит ссылка не нужна. у нас кликабельны строки целиком
                children.push(<span
                    key="value"
                >
                   { this.getInlineValue(this.props.data.value) }
                </span>);
            } else {
                if (this.props.column_type && this.props.column_type.toLowerCase() === 'url' && this.value && typeof this.value === 'string') {
                    let linkUrl: string = this.value;
                    const { id } = this.props;
                    linkUrl = linkUrl.replace(new RegExp('{CURRENT_ID}', 'g'), id ? id : '');
                    linkUrl = linkUrl.replace(new RegExp('{CURRENT_TABLE}', 'g'), this.props.table.props.essence);
                    linkUrl = linkUrl.replace(new RegExp('{CURRENT_TABLE_ID}', 'g'), (this.props.table.props.id || this.props.table.props.essence));

                    linkUrl = linkUrl.replace(new RegExp('{CURRENT_LIST_VIEW}', 'g'), '');
                    linkUrl = linkUrl.replace(new RegExp('{CURRENT_RELATED_LIST_VIEW}', 'g'), '');
                    linkUrl = linkUrl.replace(new RegExp('{CURRENT_FORM_VIEW}', 'g'), '');

                    const urlStarts = linkUrl.split('://')[0];
                    if (urlStarts === 'http' || urlStarts === 'https') {
                        children.push(<RefLink
                            key="link"
                            url={ linkUrl }
                            isOuter
                        >
                            { this.getInlineValue(this.props.data.value) }
                        </RefLink>);
                    }

                } else {
                    if (this.props.recordLink) {
                        // ссылка на запись
                        children.push(<Link
                            key="link"
                            to={ this.props.recordLink }
                            className={ `${ styles.RelativeLink }` }
                        >
                            { this.getInlineValue(this.props.data.value) }
                        </Link>);
                    } else if (this.props.refLink) {
                        // референсная ссылка
                        children.push(<RefLink
                            key="link"
                            url={ this.props.refLink }
                        >
                            { this.getInlineValue(this.props.data.value) }
                        </RefLink>);
                    }

                }
            }
        } else {
            children.push(<span key="value"
                                className={ styles.ForContextMenu + ((this.isHead()) ? (' ' + styles.headerSort) : '') }
                                onContextMenu={ this.handleContextMenu }>
                        { this.getInlineValue(this.props.data.value) }
            </span>);
        }

        if (this.isHead()) {
            const sortIcon = this.isSortedField() ? this.getSortIcon() : null;
            const isActive = this.props.table.isShowContextMenuCondition && (window as any).currentCell && (window as any).currentCell.columnId === this.props.db_column_id;
            return (
                <TableTitle
                    gradientClassName={ styles.fadeGradient }
                    sortIcon={ sortIcon }
                    isActive={ isActive }
                    value={ this.props.data }
                    hint={ this.props.fieldInfo?.hint }
                >
                    { children }
                </TableTitle>
            );
        } else {
            if (typeof this.props.data.read_access === 'boolean' && !this.props.data.read_access) {
                const { list_titles } = langStore.getTranslate();

                return (
                    <div className={ styles.unavailableData }>
                        <div className={ styles.icon } dangerouslySetInnerHTML={ { __html: IconEyeOff } } />
                        <div className={ styles.text }>{ list_titles && list_titles.unavailable_data }</div>
                    </div>
                );
            } else {
                const isEdited = !!table
                    && !!table.editCol
                    && !table.editCol.read_only
                    && table.editRow === this.props.id
                    && table.editCol.attribute === this.props.field
                    && !table.editCol.deny_editing;

                const { isMobile, db_column_name } = this.props;
                return (
                    <CellDescription
                        isMobile={ !!isMobile }
                        hideTooltip={ isEdited }
                        columnName={ db_column_name || '' }
                        tooltipText={ this.getInlineValue(this.props.data.value) }
                        gradientClassName={ styles.fadeGradient }
                    >
                        { children }
                    </CellDescription>
                );
            }

        }
    };

    render() {
        const { className, columnLabel } = this.props;
        const classes: string[] = [];
        if (!this.isHead()) {
            classes.push(styles.hasHover);
        }

        if (className) {
            classes.push(className);
        }

        if (this.props.table.props.isWindow) {
            classes.push(styles.CursorPointer);
        }

        const refFunctionContextMenu = this.props.table.props.isWindow ? this.onContextMenu : this.handleContextMenu;
        const EnhancedClickableBox: any = this.cellType;
        const dataTest = this.cellType === 'th' ? ATTRIBUTES.tableHeaderCell : ATTRIBUTES.tableCell;
        return (
            <EnhancedClickableBox
                key={ this.props.field }
                onClick={ this.handleClick }
                onDoubleClick={ this.handleDoubleClick }
                onContextMenu={ refFunctionContextMenu }
                className={ classes.join(' ') }
                ref={ this.cellRef }
                data-test={ this.props['data-test'] ? this.props['data-test'] : dataTest }
                data-test-column-title={ columnLabel }
            >
                { this.getChildren() }
                {
                    this.message && <QuickMessage>{ this.message }</QuickMessage>
                }
            </EnhancedClickableBox>
        );
    }
}

export default withRouter<CellProps, any>(observer(Cell));
