import apiRequest from 'lib/apiRequest';
import sysDBTables from 'globalState/sysDBTables';
import { fetchError} from 'helpers/report/reportMaster';

/**
 * Получение всех возможных типов репорта
 */
export const fetchTypes = async () => {
    return await new apiRequest('GET /report/get-types').send();
};

/**
 * Первоначальные запросы на получение списка всех таблиц и получения всех типов репорта
 * @returns {Promise<*>}
 */
export const fetch = async () => {
    return Promise.all([fetchTypes(), sysDBTables.fetchSysDbTable()]);
};

/**
 * Получение сохраненного отчета по его айди
 * @param reportId - айди отчета
 * @param page - страница для листов
 * @returns {Promise<*|undefined>}
 */
export const getChartByReportId = async({reportId, page}) => {
    return new apiRequest(`GET /report/get-report/${ reportId }`).send({ page });
};

/**
 * Запрос на получение данных любого репорта из МО (/report/get-report)
 * @param requestToReport - запрос на получение репорта (Существующего или только что созданного)
 * @param paramsReport - параметры запроса
 * @returns {Promise<{data: *, response: void | any}>}
 */
export const fetchChart = async (requestToReport, paramsReport) => {
    let response = await (requestToReport(paramsReport)).catch(fetchError);
    if (response && response.status === "OK") {
        return {response, data: response.getData()};
    }
    return null;
};

/**
 * Получение построенного репорта по данным формы в МО
 * @param additionalGroupValue - параметр группировки
 * @param page - страница (для листов)
 * @param data - данные с формы МО
 * @returns {Promise<unknown>}
 */
export const getReportByData = async(additionalGroupValue, page, data) => {
    const request = new apiRequest('POST /report/get-report', false);

    if (additionalGroupValue) {
        request.qs({
            group_by: additionalGroupValue.database_value || additionalGroupValue,
        });
    }

    if (page) {
        data.page = page;
    }
    return request.send(data);
};


/**
 * Запрос на сохранение составленного репорта
 * @param data - данные репорта
 * @param type - тип репорта
 * @param report_id - айди если есть
 * @param table - таблица, по которой строим репорт
 */
export const actionSaveReport = async(data, type, report_id, table) => {
    if (report_id) {
        return await new apiRequest(`POST /report/save/${ table.name }/${ report_id }`).sendJSON(data);
    }
    return await new apiRequest(`PUT /record/${ table.name }`).sendJSON(data);
};

/**
 * Запрос на получение экшенов репортов
 * @param type - тип репорта
 * @param report_id - айди если есть
 */
export const actionGetUiAction = async(type, report_id) => {
    const response = await new apiRequest(`GET /ui-action/record-actions/${ type }${ report_id ? '/' + report_id : '' }`).send();
    if (response && response.status === "OK") {
        return {response, data: response.getData()};
    }
    return null;
};


/**
 * Получение формы репорта по типу отчета
 * @param table - таблица из которой получаем отчет
 * @param report_id - айди репорта, если это сохраненный отчет
 * @param params - параметры
 * @returns {Promise<void>}
 */
export const actionFetchRecords = async (table, report_id = null, params = {}, reportState) => {
    const url = `GET /record/${ table.name }${ report_id ? '/' + report_id : '' }`;

    if (reportState.params.group_by) {
        params.field_group_by = reportState.params.group_by;
    }
    if (params.open_first_rel_list === undefined) {
        params.open_first_rel_list = 0;
    }

    return await new apiRequest(url).qs(params).send().catch(fetchError);
};

/**
 * Получение репорта по его айди
 * @param reportId - айди репорта
 * @param params
 * @param params.additionalGroupValue - значения по которым группируем
 * @param params.page - страница отображения, для листов
 * @param params.condition - условие фильтрации
 * @returns {Promise<void>}
 */
export const actionFetchReportById = async(reportId, params) => {
    return await new apiRequest(`GET /report/get-report/${ reportId }`).qs(params).send();
};
