import { ContainerRowType } from 'types/pages/servicePortal/index';
import { action, observable, makeObservable } from 'mobx';
import { PortalColumnType, PortalRowClassType } from 'types/pages/servicePortal/container/index';
import PortalColumn from 'globalState/servicePortalWidgets/containers/rows/columns/index';
import _ from 'lodash';

export default class PortalRow implements PortalRowClassType {
    private _columns: PortalColumnType[];
    private _cssClassNames: string | null;
    private _sysId: string;
    private _sysOrder: number;

    constructor(data: ContainerRowType) {
        makeObservable(this, {
            _columns: observable,
            _cssClassNames: observable,
            _sysId: observable,
            _sysOrder: observable,
            setColumns: action,
            setCssClassNames: action,
            setSysId: action,
            getSysOrder: action,
            setSysOrder: action,
            updateColumns: action,
        });

        this._sysId = data.sys_id;
        this._sysOrder = data.sys_order;
        this._cssClassNames = data.css_class_names;
        this._columns = _.map(data.columns, column => new PortalColumn(column));
    }

    getColumns(): PortalColumnType[] {
        return this._columns;
    }

    setColumns(value: PortalColumnType[]) {
        this._columns = value;
    }

    getCssClassNames(): string | null {
        return this._cssClassNames;
    }

    setCssClassNames(value: string | null) {
        this._cssClassNames = value;
    }

    getSysId(): string {
        return this._sysId;
    }

    setSysId(value: string) {
        this._sysId = value;
    }

    getSysOrder(): number {
        return this._sysOrder;
    }

    setSysOrder(value: number) {
        this._sysOrder = value;
    }

    updateColumns = (columns: PortalColumnType[]) => {
        if (_.isEmpty(this._columns)) {
            this._columns = columns;
            return;
        }
        const newColumns = [
            ..._.filter(this._columns, (column, index) => index <= columns.length - 1),
            ..._.filter(columns, (column, index) => index > this._columns.length - 1),
        ];
        _.forEach(columns, (column, index) => {
            if (!newColumns[index].isEqualSomeWidgets(column.getWidgetInstances())) {
                newColumns[index] = column;
            } else {
                newColumns[index].updateWidgetInstances(column.getWidgetInstances());
            }
        });
        this._columns = newColumns;
    };

    isEqualSomeWidgets = (columns: PortalColumnType[]) => {
        let result = true;
        _.forEach(this._columns, column => {
            const findEqualWidget = !!_.find(columns, col => column.isEqualSomeWidgets(col.getWidgetInstances()));
            if (!findEqualWidget) {
                result = false;
            }
        });
        return result;
    }
}
