import React, { FC } from 'react';
import styles from "./styles.module.scss";
import Button from "components/button";
import {isMedia} from "helpers/html";
import type {ToolbarItemProps} from "types/components/textEditor/toolbarPlugin";

export const ToolbarItem: FC<ToolbarItemProps> = ({children, onClick, icon, isActive, disabled, dataTest, ...rest}) => {
    return (
        <>
            <span
                className={styles.ToolbarItem}
                {...rest}
            >
                 <Button
                     buttonType='icon'
                     className={isActive ? styles.Active : ''}
                     onClick={onClick}
                     svg={icon}
                     hint={ !isMedia('sm') ? children : undefined}
                     data-test={dataTest}
                     disabled={disabled}
                 />
            </span>
        </>
    );
};