import {action, makeObservable, observable, runInAction} from 'mobx';
import moment from 'moment-timezone';
import _ from "lodash";
import Translate from 'globalState/lang/translate';
import {
    BooleanValue,
    ConditionGroupOption,
    ConditionGroupOptionDB,
    ConditionOption,
    DataTranslateType,
    DaysOfWeekValues,
    MonthValues,
    UserLang,
} from 'types/translate';
import {SelectOption} from "types/actions/preferences";

class Lang {
    translate = {} as Translate;
    lang = 'en';
    conditionOptions: ConditionOption[] = [];
    booleanValues: BooleanValue[] = [];

    constructor() {
        makeObservable(this, {
            translate: observable,
            lang: observable,
            conditionOptions: observable,
            booleanValues: observable,
            addTranslate: action,
            getTranslate: action,
            setLanguage: action,
            getLang: action,
            setAllConstants: action,
            getConstant: action,
            setBooleanValues: action,
            getBooleanValue: action,
            getMonthValues: action,
            getDaysOfWeekValues: action,
        });

        runInAction(() => {
            this.translate = new Translate();
        });
    }

    addTranslate = (translate: DataTranslateType) => {
        this.translate.merge(translate);

        if (!_.isEmpty(this.translate.condition_operator_titles)) {
            this.setAllConstants();
        }

        if (!_.isEmpty(this.translate.bool)) {
            this.setBooleanValues();
        }
    };

    getTranslate(): Translate {
        return this.translate;
    }

    setLanguage = (lang: UserLang) => {
        if (lang) {
            this.lang = lang.current_lang_key;
            window.localStorage.setItem('lang', this.lang);
            moment.updateLocale(this.lang, {
                week : {
                    dow: 1,
                },
            });
        }
    };

    getLang(): string {
        return this.lang;
    }

    setAllConstants() {
        const condOperTitle = this.translate.condition_operator_titles;
        const listCondition = {
            types: [
                'list',
            ],
            options: [
                {
                    database_value: 'CHANGESTO',
                    display_value: condOperTitle?.changes_to,
                    show_element: 'reference',
                    is_extra: true,
                },
                {
                    database_value: 'CHANGESFROM',
                    display_value: condOperTitle?.changes_from,
                    show_element: 'reference',
                    is_extra: true,
                },
                {
                    database_value: 'VALCHANGES',
                    display_value: condOperTitle?.changes,
                    show_element: 'none',
                    is_extra: true,
                },
                {
                    database_value: 'HAS',
                    display_value: condOperTitle?.has,
                    show_element: 'list',
                },
                {
                    database_value: 'HASNOT',
                    display_value:  condOperTitle?.has_not,
                    show_element: 'list',
                },
                {
                    database_value: '=',
                    display_value:  condOperTitle?.is,
                    show_element: 'list',
                },
                {
                    database_value: '!=',
                    display_value:  condOperTitle?.is_not,
                    show_element: 'list',
                },
                {
                    database_value: 'LIKE',
                    display_value: condOperTitle?.contains,
                    show_element: 'text',
                },
                {
                    database_value: 'NOTLIKE',
                    display_value: condOperTitle?.does_not_contain,
                    show_element: 'text',
                },
                {
                    database_value: 'ISEMPTY',
                    display_value: condOperTitle?.is_empty,
                    show_element: 'none',
                },
                {
                    database_value: 'ISNOTEMPTY',
                    display_value: condOperTitle?.is_not_empty,
                    show_element: 'none',
                },
                {
                    database_value: 'CONTAINS_DYNAMIC',
                    display_value: condOperTitle?.contains_dynamic,
                    show_element: 'select',
                },
                {
                    database_value: 'DOESNOTCONTAIN_DYNAMIC',
                    display_value: condOperTitle?.does_not_contain_dynamic,
                    show_element: 'select',
                },
                {
                    database_value: 'IS_CALCULATED',
                    display_value: condOperTitle?.is_calculated,
                    show_element: 'text',
                    is_extra: true,
                },
            ],
        };

        const fieldNameCondition = {
            types: [
                'field_name',
            ],
            options: [
                {
                    database_value: 'CHANGESTO',
                    display_value: condOperTitle?.changes_to,
                    show_element: 'text',
                    is_extra: true,
                },
                {
                    database_value: 'CHANGESFROM',
                    display_value: condOperTitle?.changes_from,
                    show_element: 'text',
                    is_extra: true,
                },
                {
                    database_value: 'VALCHANGES',
                    display_value: condOperTitle?.changes,
                    show_element: 'none',
                    is_extra: true,
                },
                {
                    database_value: 'LIKE',
                    display_value: condOperTitle?.contains,
                    show_element: 'text',
                },
                {
                    database_value: 'NOTLIKE',
                    display_value: condOperTitle?.does_not_contain,
                    show_element: 'text',
                },
                {
                    database_value: 'STARTSWITH',
                    display_value: condOperTitle?.starts_with,
                    show_element: 'text',
                },
                {
                    database_value: 'ENDSWITH',
                    display_value: condOperTitle?.ends_with,
                    show_element: 'text',
                },
                {
                    database_value: 'ISEMPTY',
                    display_value: condOperTitle?.is_empty,
                    show_element: 'none',
                },
                {
                    database_value: 'ISNOTEMPTY',
                    display_value: condOperTitle?.is_not_empty,
                    show_element: 'none',
                },
                {
                    database_value: 'ANYTHING',
                    display_value: condOperTitle?.is_anything,
                    show_element: 'none',
                },
                {
                    database_value: 'IS_CALCULATED',
                    display_value: condOperTitle?.is_calculated,
                    show_element: 'text',
                    is_extra: true,
                },
            ],
        };

        const referenceCondition = {
            types: [
                'reference',
            ],
            options: [
                {
                    database_value: 'CHANGESTO',
                    display_value: condOperTitle?.changes_to,
                    show_element: 'reference',
                    is_extra: true,
                },
                {
                    database_value: 'CHANGESFROM',
                    display_value: condOperTitle?.changes_from,
                    show_element: 'reference',
                    is_extra: true,
                },
                {
                    database_value: 'VALCHANGES',
                    display_value: condOperTitle?.changes,
                    show_element: 'none',
                    is_extra: true,
                },
                {
                    database_value: '=',
                    display_value: condOperTitle?.is,
                    show_element: 'reference',
                },
                {
                    database_value: '!=',
                    display_value: condOperTitle?.is_not,
                    show_element: 'reference',
                },
                {
                    database_value: 'IN',
                    display_value: condOperTitle?.is_one_of,
                    show_element: 'list',
                },
                {
                    database_value: 'LIKE',
                    display_value: condOperTitle?.contains,
                    show_element: 'text',
                },
                {
                    database_value: 'NOTLIKE',
                    display_value: condOperTitle?.does_not_contain,
                    show_element: 'text',
                },
                {
                    database_value: 'STARTSWITH',
                    display_value: condOperTitle?.starts_with,
                    show_element: 'text',
                },
                {
                    database_value: 'ENDSWITH',
                    display_value: condOperTitle?.ends_with,
                    show_element: 'text',
                },
                {
                    database_value: 'ISEMPTY',
                    display_value: condOperTitle?.is_empty,
                    show_element: 'none',
                },
                {
                    database_value: 'ISNOTEMPTY',
                    display_value: condOperTitle?.is_not_empty,
                    show_element: 'none',
                },
                {
                    database_value: 'ANYTHING',
                    display_value: condOperTitle?.is_anything,
                    show_element: 'none',
                },
                {
                    database_value: 'SAMEAS',
                    display_value: condOperTitle?.is_same,
                    show_element: 'select',
                },
                {
                    database_value: 'NSAMEAS',
                    display_value: condOperTitle?.is_different,
                    show_element: 'select',
                },
                {
                    database_value: 'DYNAMIC',
                    display_value: condOperTitle?.is_dynamic,
                    show_element: 'select',
                },
                {
                    database_value: 'ISNOT_DYNAMIC',
                    display_value: condOperTitle?.is_not_dynamic,
                    show_element: 'select',
                },
                {
                    database_value: 'IS_CALCULATED',
                    display_value: condOperTitle?.is_calculated,
                    show_element: 'text',
                    is_extra: true,
                },
            ],
        };

        const numericCondition = {
            types: [
                'numeric',
                'decimal',
                'precent_decimal',
                'float',
                'smallinteger',
                'integer',
                'biginteger',
                'percent_complete',
            ],
            options: [
                {
                    database_value: 'CHANGESTO',
                    display_value: condOperTitle?.changes_to,
                    show_element: 'text',
                    is_extra: true,
                },
                {
                    database_value: 'CHANGESFROM',
                    display_value: condOperTitle?.changes_from,
                    show_element: 'text',
                    is_extra: true,
                },
                {
                    database_value: 'VALCHANGES',
                    display_value: condOperTitle?.changes,
                    show_element: 'none',
                    is_extra: true,
                },
                {
                    database_value: '=',
                    display_value: condOperTitle?.is,
                    show_element: 'text',
                },
                {
                    database_value: '!=',
                    display_value: condOperTitle?.is_not,
                    show_element: 'text',
                },
                {
                    database_value: 'IN',
                    display_value: condOperTitle?.is_one_of,
                    show_element: 'multiText',
                },
                {
                    database_value: '<',
                    display_value: condOperTitle?.less_than,
                    show_element: 'text',
                },
                {
                    database_value: '>',
                    display_value: condOperTitle?.greater_than,
                    show_element: 'text',
                },
                {
                    database_value: '<=',
                    display_value: condOperTitle?.less_than_or_is,
                    show_element: 'text',
                },
                {
                    database_value: '>=',
                    display_value: condOperTitle?.greater_than_or_is,
                    show_element: 'text',
                },
                {
                    database_value: 'BETWEEN',
                    display_value: condOperTitle?.between,
                    show_element: 'between',
                },
                {
                    database_value: 'SAMEAS',
                    display_value: condOperTitle?.is_same,
                    show_element: 'select',
                },
                {
                    database_value: 'NSAMEAS',
                    display_value: condOperTitle?.is_different,
                    show_element: 'select',
                },
                {
                    database_value: 'GT_FIELD',
                    display_value: condOperTitle?.greater_than_field,
                    show_element: 'select',
                },
                {
                    database_value: 'LT_FIELD',
                    display_value: condOperTitle?.less_than_field,
                    show_element: 'select',
                },
                {
                    database_value: 'GT_OR_EQUALS_FIELD',
                    display_value: condOperTitle?.greater_than_or_is_field,
                    show_element: 'select',
                },
                {
                    database_value: 'LT_OR_EQUALS_FIELD',
                    display_value: condOperTitle?.less_than_or_is_field,
                    show_element: 'select',
                },
                {
                    database_value: 'ISEMPTY',
                    display_value: condOperTitle?.is_empty,
                    show_element: 'none',
                },
                {
                    database_value: 'ISNOTEMPTY',
                    display_value: condOperTitle?.is_not_empty,
                    show_element: 'none',
                },
                {
                    database_value: 'ANYTHING',
                    display_value: condOperTitle?.is_anything,
                    show_element: 'none',
                },
                {
                    database_value: 'IS_CALCULATED',
                    display_value: condOperTitle?.is_calculated,
                    show_element: 'text',
                    is_extra: true,
                },
                {
                    database_value: 'DYNAMIC',
                    display_value: condOperTitle?.is_dynamic,
                    show_element: 'select',
                },
            ],
        };

        const booleanCondition = {
            types: [
                'boolean',
            ],
            options: [
                {
                    database_value: 'CHANGESTO',
                    display_value: condOperTitle?.changes_to,
                    show_element: 'select',
                    is_extra: true,
                },
                {
                    database_value: 'CHANGESFROM',
                    display_value: condOperTitle?.changes_from,
                    show_element: 'select',
                    is_extra: true,
                },
                {
                    database_value: 'VALCHANGES',
                    display_value: condOperTitle?.changes,
                    show_element: 'none',
                    is_extra: true,
                },
                {
                    database_value: '=',
                    display_value: condOperTitle?.is,
                    show_element: 'select',
                },
                {
                    database_value: '!=',
                    display_value: condOperTitle?.is_not,
                    show_element: 'select',
                },
                {
                    database_value: 'SAMEAS',
                    display_value: condOperTitle?.is_same,
                    show_element: 'select',
                },
                {
                    database_value: 'NSAMEAS',
                    display_value: condOperTitle?.is_different,
                    show_element: 'select',
                },
                {
                    database_value: 'IS_CALCULATED',
                    display_value: condOperTitle?.is_calculated,
                    show_element: 'text',
                    is_extra: true,
                },
            ],
        };

        const choiceCondition = {
            types: [
                'choice',
            ],
            options: [
                {
                    database_value: 'CHANGESTO',
                    display_value: condOperTitle?.changes_to,
                    show_element: 'select',
                    is_extra: true,
                },
                {
                    database_value: 'CHANGESFROM',
                    display_value: condOperTitle?.changes_from,
                    show_element: 'select',
                    is_extra: true,
                },
                {
                    database_value: 'VALCHANGES',
                    display_value: condOperTitle?.changes,
                    show_element: 'none',
                    is_extra: true,
                },
                {
                    database_value: '=',
                    display_value: condOperTitle?.is,
                    show_element: 'select',
                },
                {
                    database_value: '!=',
                    display_value: condOperTitle?.is_not,
                    show_element: 'select',
                },
                {
                    database_value: 'IN',
                    display_value: condOperTitle?.is_one_of,
                    show_element: 'multiSelect',
                },
                {
                    database_value: 'NOT IN',
                    display_value: condOperTitle?.is_not_one_of,
                    show_element: 'multiSelect',
                },
                {
                    database_value: 'LIKE',
                    display_value: condOperTitle?.contains,
                    show_element: 'text',
                },
                {
                    database_value: 'NOTLIKE',
                    display_value: condOperTitle?.does_not_contain,
                    show_element: 'text',
                },
                {
                    database_value: 'STARTSWITH',
                    display_value: condOperTitle?.starts_with,
                    show_element: 'text',
                },
                {
                    database_value: 'ENDSWITH',
                    display_value: condOperTitle?.ends_with,
                    show_element: 'text',
                },
                {
                    database_value: 'ISEMPTY',
                    display_value: condOperTitle?.is_empty,
                    show_element: 'none',
                },
                {
                    database_value: 'ISNOTEMPTY',
                    display_value: condOperTitle?.is_not_empty,
                    show_element: 'none',
                },
                {
                    database_value: '<',
                    display_value: condOperTitle?.less_than,
                    show_element: 'select',
                },
                {
                    database_value: '>',
                    display_value: condOperTitle?.greater_than,
                    show_element: 'select',
                },
                {
                    database_value: '<=',
                    display_value: condOperTitle?.less_than_or_is,
                    show_element: 'select',
                },
                {
                    database_value: '>=',
                    display_value: condOperTitle?.greater_than_or_is,
                    show_element: 'select',
                },
                {
                    database_value: 'BETWEEN',
                    display_value: condOperTitle?.between,
                    show_element: 'selectBetween',
                },
                {
                    database_value: 'ANYTHING',
                    display_value: condOperTitle?.is_anything,
                    show_element: 'none',
                },
                {
                    database_value: 'SAMEAS',
                    display_value: condOperTitle?.is_same,
                    show_element: 'select',
                },
                {
                    database_value: 'NSAMEAS',
                    display_value: condOperTitle?.is_different,
                    show_element: 'select',
                },
                {
                    database_value: 'IS_CALCULATED',
                    display_value: condOperTitle?.is_calculated,
                    show_element: 'text',
                    is_extra: true,
                },
            ],
        };

        const imageCondition = {
            types: [
                'image',
            ],
            options: [
                {
                    database_value: 'CHANGESTO',
                    display_value: condOperTitle?.changes_to,
                    show_element: 'text',
                    is_extra: true,
                },
                {
                    database_value: 'CHANGESFROM',
                    display_value: condOperTitle?.changes_from,
                    show_element: 'text',
                    is_extra: true,
                },
                {
                    database_value: 'VALCHANGES',
                    display_value: condOperTitle?.changes,
                    show_element: 'none',
                    is_extra: true,
                },
                {
                    database_value: 'ISEMPTY',
                    display_value: condOperTitle?.is_empty,
                    show_element: 'none',
                },
                {
                    database_value: 'ISNOTEMPTY',
                    display_value: condOperTitle?.is_not_empty,
                    show_element: 'none',
                },
                {
                    database_value: 'IS_CALCULATED',
                    display_value: condOperTitle?.is_calculated,
                    show_element: 'text',
                    is_extra: true,
                },
            ],
        };

        const stringCondition = {
            types: [
                'string',
                'text',
                'phone',
                'translated_text',
                'url',
            ],
            options: [
                {
                    database_value: 'CHANGESTO',
                    display_value: condOperTitle?.changes_to,
                    show_element: 'text',
                    is_extra: true,
                },
                {
                    database_value: 'CHANGESFROM',
                    display_value: condOperTitle?.changes_from,
                    show_element: 'text',
                    is_extra: true,
                },
                {
                    database_value: 'VALCHANGES',
                    display_value: condOperTitle?.changes,
                    show_element: 'none',
                    is_extra: true,
                },
                {
                    database_value: 'LIKE',
                    display_value: condOperTitle?.contains,
                    show_element: 'text',
                },
                {
                    database_value: 'NOTLIKE',
                    display_value: condOperTitle?.does_not_contain,
                    show_element: 'text',
                },
                {
                    database_value: 'STARTSWITH',
                    display_value: condOperTitle?.starts_with,
                    show_element: 'text',
                },
                {
                    database_value: 'ENDSWITH',
                    display_value: condOperTitle?.ends_with,
                    show_element: 'text',
                },
                {
                    database_value: '=',
                    display_value: condOperTitle?.is,
                    show_element: 'text',
                },
                {
                    database_value: '!=',
                    display_value: condOperTitle?.is_not,
                    show_element: 'text',
                },
                {
                    database_value: 'ISEMPTY',
                    display_value: condOperTitle?.is_empty,
                    show_element: 'none',
                },
                {
                    database_value: 'ISNOTEMPTY',
                    display_value: condOperTitle?.is_not_empty,
                    show_element: 'none',
                },
                {
                    database_value: 'ANYTHING',
                    display_value: condOperTitle?.is_anything,
                    show_element: 'none',
                },
                {
                    database_value: 'SAMEAS',
                    display_value: condOperTitle?.is_same,
                    show_element: 'select',
                },
                {
                    database_value: 'NSAMEAS',
                    display_value: condOperTitle?.is_different,
                    show_element: 'select',
                },
                {
                    database_value: 'IS_CALCULATED',
                    display_value: condOperTitle?.is_calculated,
                    show_element: 'text',
                    is_extra: true,
                },
            ],
        };

        const jsonCondition = {
            types: [
                'json',
            ],
            options: [
                {
                    database_value: 'CHANGESTO',
                    display_value: condOperTitle?.changes_to,
                    show_element: 'text',
                    is_extra: true,
                },
                {
                    database_value: 'CHANGESFROM',
                    display_value: condOperTitle?.changes_from,
                    show_element: 'text',
                    is_extra: true,
                },
                {
                    database_value: 'VALCHANGES',
                    display_value: condOperTitle?.changes,
                    show_element: 'none',
                    is_extra: true,
                },
                {
                    database_value: 'LIKE',
                    display_value: condOperTitle?.contains,
                    show_element: 'text',
                },
                {
                    database_value: 'NOTLIKE',
                    display_value: condOperTitle?.does_not_contain,
                    show_element: 'text',
                },
                {
                    database_value: '=',
                    display_value: condOperTitle?.is,
                    show_element: 'text',
                },
                {
                    database_value: '!=',
                    display_value: condOperTitle?.is_not,
                    show_element: 'text',
                },
                {
                    database_value: 'ISEMPTY',
                    display_value: condOperTitle?.is_empty,
                    show_element: 'none',
                },
                {
                    database_value: 'ISNOTEMPTY',
                    display_value: condOperTitle?.is_not_empty,
                    show_element: 'none',
                },
                {
                    database_value: 'ANYTHING',
                    display_value: condOperTitle?.is_anything,
                    show_element: 'none',
                },
                {
                    database_value: 'EMPTYSTRING',
                    display_value: condOperTitle && condOperTitle.is_empty_string,
                    show_element: 'none',
                },
                {
                    database_value: 'SAMEAS',
                    display_value: condOperTitle?.is_same,
                    show_element: 'select',
                },
                {
                    database_value: 'NSAMEAS',
                    display_value: condOperTitle?.is_different,
                    show_element: 'select',
                },
                {
                    database_value: 'IS_CALCULATED',
                    display_value: condOperTitle?.is_calculated,
                    show_element: 'text',
                    is_extra: true,
                },
            ],
        };

        const datetimeCondition = {
            types: [
                'datetime',
                'datetime_specific',
            ],
            options: [
                {
                    database_value: 'CHANGESTO',
                    display_value: condOperTitle?.changes_to,
                    show_element: 'datetime',
                    is_extra: true,
                },
                {
                    database_value: 'CHANGESFROM',
                    display_value: condOperTitle?.changes_from,
                    show_element: 'datetime',
                    is_extra: true,
                },
                {
                    database_value: 'VALCHANGES',
                    display_value: condOperTitle?.changes,
                    show_element: 'none',
                    is_extra: true,
                },
                {
                    database_value: 'ON',
                    display_value: condOperTitle?.on,
                    show_element: 'datetime',
                },
                {
                    database_value: 'NOTON',
                    display_value: condOperTitle?.not_on,
                    show_element: 'datetime',
                },
                {
                    database_value: '<',
                    display_value: condOperTitle?.before,
                    show_element: 'datetime',
                },
                {
                    database_value: '>',
                    display_value: condOperTitle?.after,
                    show_element: 'datetime',
                },
                {
                    database_value: '<=',
                    display_value: condOperTitle?.at_or_before,
                    show_element: 'datetime',
                },
                {
                    database_value: '>=',
                    display_value: condOperTitle?.at_or_after,
                    show_element: 'datetime',
                },
                {
                    database_value: 'BETWEEN',
                    display_value: condOperTitle?.between,
                    show_element: 'betweenDatetime',
                },
                {
                    database_value: 'LIKE',
                    display_value: condOperTitle?.contains,
                    show_element: 'text',
                },
                {
                    database_value: 'NOTLIKE',
                    display_value: condOperTitle?.does_not_contain,
                    show_element: 'text',
                },
                {
                    database_value: 'ISEMPTY',
                    display_value: condOperTitle?.is_empty,
                    show_element: 'none',
                },
                {
                    database_value: 'ISNOTEMPTY',
                    display_value: condOperTitle?.is_not_empty,
                    show_element: 'none',
                },
                {
                    database_value: 'ANYTHING',
                    display_value: condOperTitle?.is_anything,
                    show_element: 'none',
                },
                {
                    database_value: 'SAMEAS',
                    display_value: condOperTitle?.is_same,
                    show_element: 'select',
                },
                {
                    database_value: 'NSAMEAS',
                    display_value: condOperTitle?.is_different,
                    show_element: 'select',
                },
                {
                    database_value: 'IS_CALCULATED',
                    display_value: condOperTitle?.is_calculated,
                    show_element: 'text',
                    is_extra: true,
                },
                {
                    database_value: 'YEAR_IS',
                    display_value: condOperTitle?.year_is,
                    show_element: 'text',
                },
                {
                    database_value: 'MONTH_IS',
                    display_value: condOperTitle?.month_is,
                    show_element: 'select',
                },
                {
                    database_value: 'QUARTER_IS',
                    display_value: condOperTitle?.quarter_is,
                    show_element: 'select',
                },
                {
                    database_value: 'WEEK_IS',
                    display_value: condOperTitle?.week_is,
                    show_element: 'text',
                },
                {
                    database_value: 'DAY_IS',
                    display_value: condOperTitle?.day_is,
                    show_element: 'text',
                },
                {
                    database_value: 'DAY_OF_WEEK_IS',
                    display_value: condOperTitle?.day_of_week,
                    show_element: 'select',
                },
                {
                    database_value: 'HOUR_IS',
                    display_value: condOperTitle?.hour_is,
                    show_element: 'select',
                },
            ],
        };

        const dateCondition = {
            types: [
                'date',
            ],
            options: [
                {
                    database_value: 'ON',
                    display_value: condOperTitle?.on,
                    show_element: 'date',
                },
                {
                    database_value: 'NOTON',
                    display_value: condOperTitle?.not_on,
                    show_element: 'date',
                },
                {
                    database_value: '<',
                    display_value: condOperTitle?.before,
                    show_element: 'date',
                },
                {
                    database_value: '>',
                    display_value: condOperTitle?.after,
                    show_element: 'date',
                },
                {
                    database_value: '<=',
                    display_value: condOperTitle?.at_or_before,
                    show_element: 'date',
                },
                {
                    database_value: '>=',
                    display_value: condOperTitle?.at_or_after,
                    show_element: 'date',
                },
                {
                    database_value: 'BETWEEN',
                    display_value: condOperTitle?.between,
                    show_element: 'betweenDate',
                },
                {
                    database_value: 'LIKE',
                    display_value: condOperTitle?.contains,
                    show_element: 'text',
                },
                {
                    database_value: 'NOTLIKE',
                    display_value: condOperTitle?.does_not_contain,
                    show_element: 'text',
                },
                {
                    database_value: 'ISEMPTY',
                    display_value: condOperTitle?.is_empty,
                    show_element: 'none',
                },
                {
                    database_value: 'ISNOTEMPTY',
                    display_value: condOperTitle?.is_not_empty,
                    show_element: 'none',
                },
                {
                    database_value: 'ANYTHING',
                    display_value: condOperTitle?.is_anything,
                    show_element: 'none',
                },
                {
                    database_value: 'SAMEAS',
                    display_value: condOperTitle?.is_same,
                    show_element: 'select',
                },
                {
                    database_value: 'NSAMEAS',
                    display_value: condOperTitle?.is_different,
                    show_element: 'select',
                },
                {
                    database_value: 'CHANGESTO',
                    display_value: condOperTitle?.changes_to,
                    show_element: 'date',
                    is_extra: true,
                },
                {
                    database_value: 'CHANGESFROM',
                    display_value: condOperTitle?.changes_from,
                    show_element: 'date',
                    is_extra: true,
                },
                {
                    database_value: 'VALCHANGES',
                    display_value: condOperTitle?.changes,
                    show_element: 'none',
                    is_extra: true,
                },
                {
                    database_value: 'IS_CALCULATED',
                    display_value: condOperTitle?.is_calculated,
                    show_element: 'text',
                    is_extra: true,
                },
                {
                    database_value: 'YEAR_IS',
                    display_value: condOperTitle?.year_is,
                    show_element: 'text',
                },
                {
                    database_value: 'MONTH_IS',
                    display_value: condOperTitle?.month_is,
                    show_element: 'select',
                },
                {
                    database_value: 'QUARTER_IS',
                    display_value: condOperTitle?.quarter_is,
                    show_element: 'select',
                },
                {
                    database_value: 'WEEK_IS',
                    display_value: condOperTitle?.week_is,
                    show_element: 'text',
                },
                {
                    database_value: 'DAY_IS',
                    display_value: condOperTitle?.day_is,
                    show_element: 'text',
                },
                {
                    database_value: 'DAY_OF_WEEK_IS',
                    display_value: condOperTitle?.day_of_week,
                    show_element: 'select',
                },
            ],
        };

        const timeCondition = {
            types: [
                'time',
            ],
            options: [
                {
                    database_value: 'ON',
                    display_value: condOperTitle?.on,
                    show_element: 'time',
                },
                {
                    database_value: 'NOTON',
                    display_value: condOperTitle?.not_on,
                    show_element: 'time',
                },
                {
                    database_value: '<',
                    display_value: condOperTitle?.before,
                    show_element: 'time',
                },
                {
                    database_value: '>',
                    display_value: condOperTitle?.after,
                    show_element: 'time',
                },
                {
                    database_value: '<=',
                    display_value: condOperTitle?.at_or_before,
                    show_element: 'time',
                },
                {
                    database_value: '>=',
                    display_value: condOperTitle?.at_or_after,
                    show_element: 'time',
                },
                {
                    database_value: 'BETWEEN',
                    display_value: condOperTitle?.between,
                    show_element: 'betweenTime',
                },
                {
                    database_value: 'LIKE',
                    display_value: condOperTitle?.contains,
                    show_element: 'text',
                },
                {
                    database_value: 'NOTLIKE',
                    display_value: condOperTitle?.does_not_contain,
                    show_element: 'text',
                },
                {
                    database_value: 'ISEMPTY',
                    display_value: condOperTitle?.is_empty,
                    show_element: 'none',
                },
                {
                    database_value: 'ISNOTEMPTY',
                    display_value: condOperTitle?.is_not_empty,
                    show_element: 'none',
                },
                {
                    database_value: 'ANYTHING',
                    display_value: condOperTitle?.is_anything,
                    show_element: 'none',
                },
                {
                    database_value: 'SAMEAS',
                    display_value: condOperTitle?.is_same,
                    show_element: 'select',
                },
                {
                    database_value: 'NSAMEAS',
                    display_value: condOperTitle?.is_different,
                    show_element: 'select',
                },
                {
                    database_value: 'CHANGESTO',
                    display_value: condOperTitle?.changes_to,
                    show_element: 'time',
                    is_extra: true,
                },
                {
                    database_value: 'CHANGESFROM',
                    display_value: condOperTitle?.changes_from,
                    show_element: 'time',
                    is_extra: true,
                },
                {
                    database_value: 'VALCHANGES',
                    display_value: condOperTitle?.changes,
                    show_element: 'none',
                    is_extra: true,
                },
                {
                    database_value: 'IS_CALCULATED',
                    display_value: condOperTitle?.is_calculated,
                    show_element: 'text',
                    is_extra: true,
                },
                {
                    database_value: 'HOUR_IS',
                    display_value: condOperTitle?.hour_is,
                    show_element: 'select',
                },
            ],
        };

        const timestampCondition = {
            types: [
                'timestamp',
            ],
            options: [
                {
                    database_value: 'ON',
                    display_value: condOperTitle?.on,
                    show_element: 'timestamp',
                },
                {
                    database_value: 'NOTON',
                    display_value: condOperTitle?.not_on,
                    show_element: 'timestamp',
                },
                {
                    database_value: '<',
                    display_value: condOperTitle?.before,
                    show_element: 'timestamp',
                },
                {
                    database_value: '>',
                    display_value: condOperTitle?.after,
                    show_element: 'timestamp',
                },
                {
                    database_value: '<=',
                    display_value: condOperTitle?.at_or_before,
                    show_element: 'timestamp',
                },
                {
                    database_value: '>=',
                    display_value: condOperTitle?.at_or_after,
                    show_element: 'timestamp',
                },
                {
                    database_value: 'BETWEEN',
                    display_value: condOperTitle?.between,
                    show_element: 'betweenTimestamp',
                },
                {
                    database_value: 'LIKE',
                    display_value: condOperTitle?.contains,
                    show_element: 'text',
                },
                {
                    database_value: 'NOTLIKE',
                    display_value: condOperTitle?.does_not_contain,
                    show_element: 'text',
                },
                {
                    database_value: 'ISEMPTY',
                    display_value: condOperTitle?.is_empty,
                    show_element: 'none',
                },
                {
                    database_value: 'ISNOTEMPTY',
                    display_value: condOperTitle?.is_not_empty,
                    show_element: 'none',
                },
                {
                    database_value: 'ANYTHING',
                    display_value: condOperTitle?.is_anything,
                    show_element: 'none',
                },
                {
                    database_value: 'SAMEAS',
                    display_value: condOperTitle?.is_same,
                    show_element: 'select',
                },
                {
                    database_value: 'NSAMEAS',
                    display_value: condOperTitle?.is_different,
                    show_element: 'select',
                },
                {
                    database_value: 'CHANGESTO',
                    display_value: condOperTitle?.changes_to,
                    show_element: 'timestamp',
                    is_extra: true,
                },
                {
                    database_value: 'CHANGESFROM',
                    display_value: condOperTitle?.changes_from,
                    show_element: 'timestamp',
                    is_extra: true,
                },
                {
                    database_value: 'VALCHANGES',
                    display_value: condOperTitle?.changes,
                    show_element: 'none',
                    is_extra: true,
                },
                {
                    database_value: 'IS_CALCULATED',
                    display_value: condOperTitle?.is_calculated,
                    show_element: 'text',
                    is_extra: true,
                },
            ],
        };

        const keywordsCondition = {
            types: [
                'keywords',
            ],
            options: [
                {
                    database_value: 'ARE',
                    display_value: condOperTitle?.are,
                    show_element: 'text',
                },
            ],
        };

        const scriptCondition = {
            types: [
                'script',
            ],
            options: [
                {
                    database_value: 'CHANGESTO',
                    display_value: condOperTitle?.changes_to,
                    show_element: 'text',
                    is_extra: true,
                },
                {
                    database_value: 'CHANGESFROM',
                    display_value: condOperTitle?.changes_from,
                    show_element: 'text',
                    is_extra: true,
                },
                {
                    database_value: 'VALCHANGES',
                    display_value: condOperTitle?.changes,
                    show_element: 'none',
                    is_extra: true,
                },
                {
                    database_value: 'LIKE',
                    display_value: condOperTitle?.contains,
                    show_element: 'text',
                },
                {
                    database_value: 'NOTLIKE',
                    display_value: condOperTitle?.does_not_contain,
                    show_element: 'text',
                },
                {
                    database_value: 'ISNOTEMPTY',
                    display_value: condOperTitle?.is_not_empty,
                    show_element: 'none',
                },
                {
                    database_value: 'ANYTHING',
                    display_value: condOperTitle?.is_anything,
                    show_element: 'none',
                },
                {
                    database_value: 'IS_CALCULATED',
                    display_value: condOperTitle?.is_calculated,
                    show_element: 'text',
                    is_extra: true,
                },
            ],
        };

        const htmlCondition = {
            types: [
                'html',
            ],
            options: [
                {
                    database_value: 'CHANGESTO',
                    display_value: condOperTitle?.changes_to,
                    show_element: 'text',
                    is_extra: true,
                },
                {
                    database_value: 'CHANGESFROM',
                    display_value: condOperTitle?.changes_from,
                    show_element: 'text',
                    is_extra: true,
                },
                {
                    database_value: 'VALCHANGES',
                    display_value: condOperTitle?.changes,
                    show_element: 'none',
                    is_extra: true,
                },
                {
                    database_value: 'ANYTHING',
                    display_value: condOperTitle?.is_anything,
                    show_element: 'none',
                },
                {
                    database_value: 'ISNOTEMPTY',
                    display_value: condOperTitle?.is_not_empty,
                    show_element: 'none',
                },
                {
                    database_value: 'ISEMPTY',
                    display_value: condOperTitle?.is_empty,
                    show_element: 'none',
                },
                {
                    database_value: 'LIKE',
                    display_value: condOperTitle?.contains,
                    show_element: 'text',
                },
                {
                    database_value: 'NOTLIKE',
                    display_value: condOperTitle?.does_not_contain,
                    show_element: 'text',
                },
                {
                    database_value: 'IS_CALCULATED',
                    display_value: condOperTitle?.is_calculated,
                    show_element: 'text',
                    is_extra: true,
                },
            ],
        };

        const recordClassCondition = {
            types: [
                'record_class',
            ],
            options: [
                {
                    database_value: '=',
                    display_value: condOperTitle?.is,
                    show_element: 'reference',
                },
                {
                    database_value: '!=',
                    display_value: condOperTitle?.is_not,
                    show_element: 'reference',
                },
                {
                    database_value: 'IN',
                    display_value: condOperTitle?.is_one_of,
                    show_element: 'list',
                },
                {
                    database_value: 'LIKE',
                    display_value: condOperTitle?.contains,
                    show_element: 'text',
                },
                {
                    database_value: 'NOTLIKE',
                    display_value: condOperTitle?.does_not_contain,
                    show_element: 'text',
                },
                {
                    database_value: 'STARTSWITH',
                    display_value: condOperTitle?.starts_with,
                    show_element: 'text',
                },
                {
                    database_value: 'ENDSWITH',
                    display_value: condOperTitle?.ends_with,
                    show_element: 'text',
                },
                {
                    database_value: 'IS_CALCULATED',
                    display_value: condOperTitle?.is_calculated,
                    show_element: 'text',
                    is_extra: true,
                },
            ],
        };

        const documentIdCondition = {
            types: [
                'document_id',
            ],
            options: [
                {
                    database_value: '=',
                    display_value: condOperTitle?.is,
                    show_element: 'documentId',
                },
                {
                    database_value: '!=',
                    display_value: condOperTitle?.is_not,
                    show_element: 'documentId',
                },
                {
                    database_value: 'LIKE',
                    display_value: condOperTitle?.contains,
                    show_element: 'text',
                },
                {
                    database_value: 'NOTLIKE',
                    display_value: condOperTitle?.does_not_contain,
                    show_element: 'text',
                },
                {
                    database_value: 'ISEMPTY',
                    display_value: condOperTitle?.is_empty,
                    show_element: 'none',
                },
                {
                    database_value: 'ISNOTEMPTY',
                    display_value: condOperTitle?.is_not_empty,
                    show_element: 'none',
                },
            ],
        };

        const journalInputCondition = {
            types: [
                'journal_input',
            ],
            options: [
                {
                    database_value: 'VALCHANGES',
                    display_value: condOperTitle?.changes,
                    show_element: 'none',
                    is_extra: true,
                },
            ],
        };

        const conditionsCondition = {
            types: [
                'conditions',
            ],
            options: [
                {
                    database_value: 'LIKE',
                    display_value: condOperTitle?.contains,
                    show_element: 'text',
                },
                {
                    database_value: 'NOTLIKE',
                    display_value: condOperTitle?.does_not_contain,
                    show_element: 'text',
                },
            ],
        };


        this.conditionOptions = [
            stringCondition,
            jsonCondition,
            booleanCondition,
            numericCondition,
            referenceCondition,
            datetimeCondition,
            dateCondition,
            timeCondition,
            timestampCondition,
            choiceCondition,
            listCondition,
            fieldNameCondition,
            keywordsCondition,
            imageCondition,
            scriptCondition,
            htmlCondition,
            recordClassCondition,
            documentIdCondition,
            journalInputCondition,
            conditionsCondition,
        ];
    }

    getConstant(type: string): ConditionOption | undefined {
        return this.conditionOptions.find(cond => cond.types.includes(type));
    }

    getConditionSortOption(): SelectOption[] {
        const filter_titles = this.translate.filter_titles;

        return [
            {
                database_value: filter_titles?.a_to_z as string,
                display_value: filter_titles?.a_to_z as string,
            },
            {
                database_value: filter_titles?.z_to_a as string,
                display_value: filter_titles?.z_to_a as string,
            },
        ];
    }

    getConditionGroupOptions(id?: ConditionGroupOptionDB) {
        const filter_titles = this.translate.filter_titles;

        const conditionGroupOptions: ConditionGroupOption[] = [
            {
                database_value: 'GROUPBY',
                display_value: filter_titles?.group_by as string,
            },
            {
                database_value: 'GROUPBYDESC',
                display_value: filter_titles?.group_by_desc as string,
            },
            {
                database_value: 'GROUPBYCOUNT',
                display_value: filter_titles?.group_by_count as string,
            },
            {
                database_value: 'GROUPBYCOUNTDESC',
                display_value: filter_titles?.group_by_count_desc as string,
            },
        ];

        if (id){
            return conditionGroupOptions.find(cond => cond.database_value === id);
        } else {
            return conditionGroupOptions;
        }
    }

    setBooleanValues() {
        this.booleanValues = [
            {
                database_value: '0',
                display_value: this.translate.bool && this.translate.bool.no,
            },
            {
                database_value: '1',
                display_value: this.translate.bool && this.translate.bool.yes,
            },
        ];
    }

    getBooleanValue(): BooleanValue[] {
        return this.booleanValues;
    }

    getMonthValues(): MonthValues[] {
        const condOperTitle = this.translate.condition_operator_titles;
        return [
            {
                database_value: '1',
                display_value: condOperTitle?.january,
            },
            {
                database_value: '2',
                display_value: condOperTitle?.february,
            },
            {
                database_value: '3',
                display_value: condOperTitle?.march,
            },
            {
                database_value: '4',
                display_value: condOperTitle?.april,
            },
            {
                database_value: '5',
                display_value: condOperTitle?.may,
            },
            {
                database_value: '6',
                display_value: condOperTitle?.june,
            },
            {
                database_value: '7',
                display_value: condOperTitle?.july,
            },
            {
                database_value: '8',
                display_value: condOperTitle?.august,
            },
            {
                database_value: '9',
                display_value: condOperTitle?.september,
            },
            {
                database_value: '10',
                display_value: condOperTitle?.october,
            },
            {
                database_value: '11',
                display_value: condOperTitle?.november,
            },
            {
                database_value: '12',
                display_value: condOperTitle?.december,
            },
        ];
    }

    getDaysOfWeekValues(): DaysOfWeekValues[] {
        const condOperTitle = this.translate.condition_operator_titles;
        return [
            {
                database_value: '1',
                display_value: condOperTitle?.monday,
            },
            {
                database_value: '2',
                display_value: condOperTitle?.tuesday,
            },
            {
                database_value: '3',
                display_value: condOperTitle?.wednesday,
            },
            {
                database_value: '4',
                display_value: condOperTitle?.thursday,
            },
            {
                database_value: '5',
                display_value: condOperTitle?.friday,
            },
            {
                database_value: '6',
                display_value: condOperTitle?.saturday,
            },
            {
                database_value: '7',
                display_value: condOperTitle?.sunday,
            },
        ];
    }
}

export default new Lang();
