import apiRequest from 'lib/apiRequest';
import {handleResponse} from "helpers/action";
import {AjaxRunScriptParams, AjaxGetXMLParams} from 'types/clientAPI/simpleAjax';
import {HandleResponse} from "types/actions/main";

/**
 * // Отправка серверного скрипта на бэк
 */
export const fetchAjaxRunScript = async (params: AjaxRunScriptParams): Promise<HandleResponse> => {
    const response = await new apiRequest(`POST /ajax-script/run`).sendJSON(params);
    return handleResponse(response);
};

/**
 * // Отправка серверного скрипта на бэк без acl
 */
export const fetchAjaxAdminRunScript = async (params: AjaxRunScriptParams): Promise<HandleResponse> => {
    const response = await new apiRequest(`POST /admin-script/run`).sendJSON(params);
    return handleResponse(response);
};

/**
 * // Получение XML документа
 */
export const fetchAjaxGetXML = async (params: AjaxGetXMLParams): Promise<Document | undefined> => {
    const response = await new apiRequest(`POST /ajax-script/script-to-xml`).sendJSON(params);
    if (response) {
        return new DOMParser().parseFromString(response, "text/xml");
    }
};