import BaseFormFieldModel from './BaseFormFieldModel';
import { observable, makeObservable } from 'mobx';
import { isEmptyObject } from "helpers/isEmpty";
import langStore from "globalState/lang";
import { isChanged } from 'helpers/form';
import { getDisplayValueTranslate } from 'helpers/translations';

export default class FieldNameModel extends BaseFormFieldModel {

    /**
     * dependentOnColumn - при использовании на форме: id поля хранящего id таблицы, для которой данный компонент отображает список полей
     *                     - при использовании на листе: значение поля хранящего id таблицы
     * dependentOnColumn
     *
     * @type {string}
     */
    dependentOnColumn;

    /**
     * Список имён колонок
     * values
     *
     * @type {Array}
     */
    values = [];

    columnType = 'field_name';

    /**
     * параметр для поиска table_id
     * forReference
     *
     * @type {string}
     */
    forReference;

    constructor(data, parentFormSectionModel) {
        super(data, parentFormSectionModel);

        makeObservable(this, {
            values: observable,
        });

        super.mergeData(data);
    }

    updateData = (fields) => {
        const { filter_titles = {} } = langStore.getTranslate();

        let values = Object.entries(fields).map((data) => {
            return {
                database_value: data[0],
                display_value: data[1],
            };
        });
        values.unshift({
            database_value: null,
            display_value: filter_titles.none_filter_name,
        });
        this.values = values;
    };

    checkEmptyValue() {
        return isEmptyObject(this.value);
    }

    uiGetValue() {
        return this.value && this.value.database_value;
    }

    async uiSetValue(value) {
        if (value === '') {
            return this.uiClearValue();
        }

        const params = {
            table_name: this.sysTableName,
            column_id: this.columnId,
            value: value,
        };

        this.value = await getDisplayValueTranslate(params);
        this.changed = isChanged(this.defaultValue, this.value);
    }

    uiGetDisplayValue() {
        return this.value && this.value.display_value;
    }

    getValueForSave() {
        return this.value && this.value.database_value;
    }
}
