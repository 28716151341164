import {observable, makeObservable} from "mobx";
import _ from 'lodash';
import { IIndicatePresenceClass, IIndicatePresenceUser } from 'types/globalState/indicatePresence';

export class IndicatePresence implements IIndicatePresenceClass {
    private _isEnabled = false;
    private _users: IIndicatePresenceUser[] = [];
    private _isAddUser = false;
    private _tableName = '';
    private _recordId = '';

    constructor(tableName, recordId, isEnabled) {
        this._tableName = tableName;
        this._recordId = recordId;
        this._isEnabled = isEnabled;

        makeObservable(this, {
            _isEnabled: observable,
            _users: observable,
            _isAddUser: observable,
            _tableName: observable,
            _recordId: observable,
        });
    }

    getIsEnabled(): boolean {
        return this._isEnabled;
    }

    setIsEnabled(value: boolean) {
        this._isEnabled = value;
    }

    getUsers(): IIndicatePresenceUser[] {
        return this._users;
    }

    setUsers(value: IIndicatePresenceUser[]) {
        this._isAddUser = value.length > this._users.length;
        this._users = _.sortBy(value, ['connected_at']);
    }

    getIsAddUser(): boolean {
        return this._isAddUser;
    }

    getTableName(): string {
        return this._tableName;
    }

    setTableName(value: string) {
        this._tableName = value;
    }

    getRecordId(): string {
        return this._recordId;
    }

    setRecordId(value: string) {
        this._recordId = value;
    }
}
