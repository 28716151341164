import * as React from 'react';
import styles from './styles.module.scss';
import IconMore from 'assets/img/icons/more-vertical-kebab.svg';
import _ from 'lodash';
import { observer } from 'mobx-react';
import IconChevronLeft from 'assets/img/icons/chevron-left.svg';
import Button from 'components/button';
import { ActivityTabProps } from 'types/components/activityFeed';
import MenuSettings from 'components/activityFeed/menuSettings';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { getClassNameAF, getFilteredHistoryFields } from 'helpers/activityFeed';

/**
 * Описание: компонент рабочих заметок в activity feed
 * Параметры:
 * activityState: {type: object} - глобальное хранилище
 * isModal: {type: boolean} - в модальном окне или нет
 * onToggleShowSettings: {type: function} - метод для изменения видимости настроек
 * isShowSettings: {type: boolean} - флаг видимости настроек
 */
class ActivityTabs extends React.Component<ActivityTabProps> {
    handleToggleShowSettings = () => {
        const { onToggleShowSettings } = this.props;
        onToggleShowSettings();
    };

    handleChangeTab = (tab) => () => {
        const { activityState } = this.props;
        activityState.changeTab(tab);
    };

    renderTabs = () => {
        const { activityState, isNotStylized, classes } = this.props;
        const activityMenuItems = activityState.getActiveMenuItems();
        const isOnlyOnType = _.filter(activityMenuItems, activityType => activityType.name !== 'all' && activityType.value === 1);
        const hasHistory = !_.isEmpty(getFilteredHistoryFields(activityState));
        const items = _.map(activityState.getActivityTypes(), activityType => {
            if (activityType.name === 'history' && !hasHistory) {
                return null;
            }
            const findShowActivityType = _.find(activityMenuItems, item => {
                return (item.id === activityType.sys_id && item.value === 1) || (item.name === 'all' && item.value === 1);
            });
            if ((activityType.name !== 'all' && !findShowActivityType) || (activityType.name === 'all' && isOnlyOnType.length === 1)) {
                return null;
            }
            return (
                <div
                    key={ `activityType${ activityType.sys_id }${ activityType.name }` }
                    className={ `${ getClassNameAF(isNotStylized, styles.Tab, classes?.Tab) } ${ activityState.getActiveTab() === activityType.name ? getClassNameAF(isNotStylized, styles.TabActive, classes?.TabActive) : '' }` }
                    onClick={ this.handleChangeTab(activityType.name) }
                    data-test={ ATTRIBUTES.activityFeedTypeTab }
                >
                    { activityType.title_plural || activityType.title }
                </div>
            );
        });
        return (
            <React.Fragment>
                { items }
            </React.Fragment>
        );
    };

    renderSettings = () => {
        const {
            isModal, activityState, isShowSettings, widgetId, tableName, recordId, dictionary, refParent,
            isNotStylized, classes,
        } = this.props;
        if (isModal && isShowSettings) {
            return (
                <Button
                    buttonType={ 'icon-border' }
                    svg={ IconChevronLeft }
                    onClick={ this.handleToggleShowSettings }
                    data-test={ ATTRIBUTES.activityFeedToggleSettings }
                />
            );
        }
        return (
            <React.Fragment>
                <div
                    className={ `${ getClassNameAF(isNotStylized, styles.SettingsMore, classes?.SettingsMore) } ${ isShowSettings ? getClassNameAF(isNotStylized, styles.SettingsActiveMore, classes?.SettingsActiveMore) : '' }` }
                    dangerouslySetInnerHTML={ { __html: IconMore } }
                    onClick={ this.handleToggleShowSettings }
                    data-test={ ATTRIBUTES.activityFeedToggleSettings }
                    ref={ refParent }
                />
                <MenuSettings
                    isShowSettings={ isShowSettings }
                    activityState={ activityState }
                    widgetId={ widgetId }
                    tableName={ tableName }
                    recordId={ recordId }
                    onToggleShowSettings={ this.handleToggleShowSettings }
                    dictionary={ dictionary }
                    refParent={ refParent }
                    isNotStylized={ isNotStylized }
                    classes={ classes }
                />
            </React.Fragment>
        );
    };

    render() {
        const { isNotStylized, classes } = this.props;
        return (
            <div className={ `${ getClassNameAF(isNotStylized, styles.TabsBlock, classes?.TabsBlock) }` }>
                <div className={ getClassNameAF(isNotStylized, styles.Tabs, classes?.Tabs) }>
                    { this.renderTabs() }
                </div>
                <div className={ getClassNameAF(isNotStylized, styles.Settings, classes?.Settings) }>
                    { this.renderSettings() }
                </div>
            </div>
        );
    }
}

export default observer(ActivityTabs);
