import {action, observable, makeObservable, reaction} from 'mobx';
import { fetchChoices, fetchDynamics, fetchOpts, getConditionValue, getFieldType, getHours, getQuarters } from 'helpers/condition';
import { fetchReferenceDisplayName } from 'actions/conditions';
import langStore from 'globalState/lang';
import * as _ from 'lodash';

export class FilteringRow {
    _id = '';
    _type = 'AND';
    _block = 1;
    _field = {};
    _operator = {};
    _value = '';
    _extraAttributes = '';
    _operatorOptions = [];
    _choiceOptions = [];
    _disabled = false;
    _index = 0;
    _valueOpts = [];

    constructor(data) {
        makeObservable(this, {
            _id: observable,
            _type: observable,
            _block: observable,
            _field: observable,
            _operator: observable,
            _value: observable,
            _operatorOptions: observable,
            _choiceOptions: observable,
            _disabled: observable,
            _index: observable,
            _valueOpts: observable,
            setId: action,
            setType: action,
            setBlock: action,
            setField: action,
            setOperator: action,
            setValue: action,
            setOperatorOptions: action,
            setChoiceOptions: action,
            setValueOpts: action,
            setDisabled: action,
            setIndex: action,
            changeFilteringField: action,
            changeFilteringOperator: action,
            changeFilteringValue: action,
            updateFilterValue: action,
            fetchReferenceValue: action,
            updateFilterOperator: action,
        });

        if (data) {
            this._id = data.id;
            this._type = data.type;
            this._block = data.block;
            this._field = data.field;
            this._operator = data.operator;
            this._value = data.value;
            this._operatorOptions = data.operatorOptions;
            this._choiceOptions = data.choiceOptions;
            this._disabled = data.disabled || false;
            this._index = data.index || 0;
        }

        reaction(
            () => langStore.conditionOptions,
            () => this.updateFilterOperator()
        );
    }

    getId() {
        return this._id;
    }

    setId(value) {
        this._id = value;
    }

    getType() {
        return this._type;
    }

    setType(value) {
        this._type = value;
    }

    getBlock() {
        return this._block;
    }

    setBlock(value) {
        this._block = value;
    }

    getField() {
        return this._field;
    }

    setField(value) {
        this._field = value;
    }

    getOperator() {
        return this._operator;
    }

    setOperator(value) {
        this._operator = value;
    }

    getValue() {
        return this._value;
    }

    setValue(value) {
        this._value = value;
    }

    getOperatorOptions() {
        return this._operatorOptions;
    }

    setOperatorOptions(value) {
        this._operatorOptions = value;
    }

    getChoiceOptions() {
        return this._choiceOptions;
    }

    setChoiceOptions(value) {
        this._choiceOptions = value;
    }

    setValueOpts(value) {
        this._valueOpts = value;
    }

    getDisabled() {
        return this._disabled;
    }

    setDisabled(disabled) {
        this._disabled = disabled;
    }

    getIndex() {
        return this._index;
    }

    setIndex(index) {
        this._index = index;
    }
    
    getExtraAttributes() {
        return this._extraAttributes;
    }
    
    setExtraAttributes(extraAttributes) {
        this._extraAttributes = extraAttributes;
    }

    changeFilteringField = async (fieldValue, tableId, extraAttributes, filteredData) => {
        this.setExtraAttributes(extraAttributes);
        const isOptChosen = this._value && this._value.database_value
            && typeof this._value.database_value === 'string' && this._value.database_value.search(/^opt:/) === 0;
        const needToClear = fieldValue && isOptChosen // если выбрана опция - значение сбрасываем, т.к. в другом поле может не быть такой опции
            || (this._field && !_.isEqual(fieldValue.column_type, this._field.column_type)); // если новый тип поля не совпадает с существующим - очищаем поля Оператор и Значение
        this._field = typeof fieldValue === 'object' ? { ...fieldValue } : {};
        if (needToClear) {
            if ([
                'list',
                'multiselect',
            ].includes(fieldValue.condition_type)) {
                this._value = [];
            }
            else {
                this._value = '';
            }
        }
        if (this._field && this._field.condition_type) {
            await this.updateFilterOperator();
        }
        this._choiceOptions = await this.updateChoiceOptions(tableId); // в конец метода, чтобы не тормозило работу
        await this.updateValueOpts(tableId);
        if (this._operator && this._operator.show_element) {
            await this.updateFilterValue(this._operator.show_element, false, filteredData);
        }
    };

    changeFilteringOperator = async (value, filteredData, tableId) => {
        const findValue = this._operatorOptions.find(opt => opt.database_value === value);
        const isShowElementChanged = this._operator.show_element !== value.show_element;
        const isOptChosen = this._value && this._value.database_value
            && typeof this._value.database_value === 'string' && this._value.database_value.search(/^opt:/) === 0;
        this._operator = findValue ? findValue : value;
        const prevValueOpts = _.cloneDeep(this._valueOpts);
        await this.updateValueOpts(tableId);
        const optsChanged = !_.isEqual(this._valueOpts, prevValueOpts);
        if (this._operator.show_element) {
            this.updateFilterValue('', isShowElementChanged || isOptChosen && optsChanged, filteredData);
        }
    };

    changeFilteringValue = (value) => {
        if (this._operator && this._operator.show_element) {
            if (typeof value === 'string' && value !== '' && this._operator.show_element === 'reference' && this._field.referenced_table_name) {
                this.fetchReferenceValue(value);
            }
            else {
                this._value = getConditionValue(this._operator.show_element, value);
            }
        }
        else {
            this._value = value;
        }
    };

    updateChoiceOptions = async (tableId) => {
        const { column_id, condition_type, referenced_table_id, dot_walking_attribute } = this._field;
        let choiceOptions = [];
        if (column_id && condition_type === 'choice') {
            choiceOptions = await fetchChoices(column_id, tableId || referenced_table_id);
        }
        if (column_id && ([
            'list',
            'reference',
        ].includes(condition_type) || this.isSysId(condition_type, dot_walking_attribute))) {
            if (dot_walking_attribute === 'sys_id' && tableId) {
                choiceOptions = await fetchDynamics(column_id, tableId);
            }
            else {
                choiceOptions = await fetchDynamics(column_id);
            }
        }
        return choiceOptions;
    };

    updateValueOpts = async (tableId) => { // FT0000135 должна вызываться при смене поля или оператора
        const { column_id } = this._field;
        const operator = this._operator.database_value;
        if (operator && column_id && tableId){
            this._valueOpts = await fetchOpts(operator, column_id, tableId);
        }
    };

    isSysId = (condType, dwa) => {
        return condType === 'numeric' && dwa === 'sys_id';
    };

    updateFilterValue = (resElement, clean = false, filteredData) => { // получение значения для поля Значение
        const element = resElement || this._operator.show_element;
        const options = [
            'select',
            'multiSelect',
            'selectBetween',
        ].includes(element) ? this.getOptions(filteredData) : [];
        if (options.length > 0) {
            if (!clean && (typeof this._value === 'object' || Array.isArray(this._value))) {
                return;
            }
            const resultValue = options.find(option => option.database_value === this._value) || options[0];
            if (element === 'select') {
                this._value = resultValue;
            }
            if (element === 'multiSelect') {
                this._value = resultValue;
            }
            if (element === 'selectBetween') {
                this._value = [
                    resultValue,
                    resultValue,
                ];
            }
            return;
        }
        if (clean || element === 'none' || (element === 'text' && typeof this._value !== 'string' && typeof this._value.display_value !== 'string')) {
            this._value = element === 'selectBetween' ? ['', ''] : '';
        }
    };

    fetchReferenceValue = async (refValue) => {
        if (refValue && typeof refValue === 'string' && refValue.search(/^opt:/) === 0){
            //ищем значение среди опций
            let opt;
            if (Array.isArray(this._valueOpts)){
                opt = this._valueOpts.find((opt) => (opt.database_value === refValue));
            }
            this._value = {
                database_value: refValue,
                display_value: opt && opt.display_value || '',
                is_option: true,
            };
        } else {
            const params = {
                table_name: this._field.referenced_table_name,
                sys_id: refValue,
            };
            const response = await fetchReferenceDisplayName(params);
            const data = response.isOkStatus && response.data ? response.data : {};
            this._value = {
                display_value: data.display_name || '',
                database_value: refValue,
            };
        }
    };

    updateReferenceValue = async (operator) => {
        if (this._value && typeof this._value === 'string' && operator &&
            operator.show_element === 'reference' && this._field.referenced_table_name) {
            await this.fetchReferenceValue(this._value);
        }
    };

    getOptions = (filteredData) => {
        const sameOptions = this.getSameOptions(filteredData);
        const typeOptions = this.getTypeOptions();
        return sameOptions.length > 0 ? sameOptions : typeOptions;
    };

    getSameOptions = (filteredData) => {
        const isSameOptions = [
            'SAMEAS',
            'NSAMEAS',
            'GT_FIELD',
            'LT_FIELD',
            'GT_OR_EQUALS_FIELD',
            'LT_OR_EQUALS_FIELD',
        ].includes(this._operator.database_value);
        if (this._operator && this._operator.database_value && isSameOptions && !_.isEmpty(filteredData)) {
            return filteredData.map(row => ({
                database_value: row.dot_walking_attribute,
                display_value: row.column_title,
            }));
        }
        return [];
    };

    getTypeOptions = () => {
        if (this._operator.database_value === 'QUARTER_IS') {
            return getQuarters();
        }
        if (this._operator.database_value === 'HOUR_IS') {
            return getHours();
        }
        if (this._operator.database_value === 'MONTH_IS') {
            return langStore.getMonthValues();
        }
        if (this._operator.database_value === 'DAY_OF_WEEK_IS') {
            return langStore.getDaysOfWeekValues();
        }
        if (!this._field.condition_type) {
            return [];
        }

        switch (this._field.condition_type) {
            case 'boolean':
                return langStore.getBooleanValue();
            case 'choice':
            case 'reference':
            case 'list':
                return this._choiceOptions;
            default:
                return [];
        }
    };

    getValueOpts = () => {
        return this._valueOpts;
    };

    updateFilterOperator = async () => {
        const extraAttributes = this.getExtraAttributes();
        const type = getFieldType(this._field);
        const findObj = langStore.getConstant(type);
        if (findObj) {
            this._operatorOptions = [
                ...findObj.options.filter(option => !option.is_extra),
                ...findObj.options.filter(option => option.is_extra && extraAttributes && extraAttributes.includes(option.database_value)),
            ];
            const operatorValue = this._operator.database_value ? this._operator.database_value : this._operator;
            const findOperator = this._operatorOptions.find(option => option.database_value === operatorValue);
            const firstOperator = findOperator ? findOperator : this._operatorOptions[0] || {};
            this._operator = firstOperator;
            await this.updateReferenceValue(firstOperator);
        }
    };
}

export const FilteringRowState = FilteringRow;

export default new FilteringRow();
