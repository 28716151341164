import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';

import langStore from 'globalState/lang';

import Layouts from 'components/portalDesigner/view/leftPanel/layouts';
import Widgets from 'components/portalDesigner/view/leftPanel/widgets';
import Pages from 'components/portalDesigner/view/leftPanel/pages';

import IconSearch from 'assets/img/icons/search.svg';

import styles from 'components/portalDesigner/styles.module.scss';

class LeftPanel extends React.Component {
    panels = [
        'Widgets',
        'Portal pages',
    ];

    activePanel = 0;
    filterValue = '';

    constructor(props) {
        super(props);

        makeObservable(this, {
            activePanel: observable,
            filterValue: observable,
        });
    }

    togglePanels = (index) => () => {
        this.activePanel = index;
        this.filterValue = '';
    };

    handleChangeFilter = (e) => {
        this.filterValue = e.target.value;
    };

    render() {
        const { filter_name } = langStore.getTranslate();

        return (
            <>
                {
                    <div className={ styles.LeftPanel }>
                        <div className={ styles.Tabs } key="tabs">
                            {
                                this.panels.map((name, index) => <div
                                    key={ `tabs_items_${ index }` }
                                    className={ index === this.activePanel ? styles.ActiveTab : styles.Tab }
                                    onClick={ this.togglePanels(index) }
                                >
                                    { name }
                                </div>)
                            }
                        </div>

                        <div className={ styles.Search } key="search">
                            <input className={ styles.SearchInput }
                                   key="search_input"
                                   type="text"
                                   placeholder={ filter_name ? filter_name : '' }
                                   value={ this.filterValue }
                                   onChange={ this.handleChangeFilter }
                            />
                            <span key="search_input_icon" className={ styles.IconSearch } dangerouslySetInnerHTML={ { __html: IconSearch } } />
                        </div>
                        {
                            this.activePanel === 0 && <Layouts key="layouts" />
                        }

                        {
                            this.activePanel === 0 && <Widgets
                                key="widgets"
                                filterValue={ this.filterValue }
                            />
                        }

                        {
                            this.activePanel === 1 && <Pages
                                key="pages"
                                filterValue={ this.filterValue }
                            />
                        }
                    </div>
                }
            </>
        );
    }
}

export default observer(LeftPanel);