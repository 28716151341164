import { observable, makeObservable } from 'mobx';

class ActionsPanelState {
    panelEl = null;

    constructor() {
        makeObservable(this, {
            panelEl: observable,
        });
    }
}

export default new ActionsPanelState();
