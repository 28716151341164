import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import { Link } from 'react-router-dom';

/***
 * Когда весь текст не вмещается, показывает title с полным текстом
 * Параметры:
 * children
 * linkClassName
 * linkTo
 * textClassNames
 */

class Subject extends React.Component {
    refText = React.createRef();
    showTitle = false;

    constructor() {
        super();
        makeObservable(this, {
            showTitle: observable,
        });

    }

    checkOverflow = ({ target: linkEl }) => {
        const { children } = this.props;
        const textEl = this.refText?.current;

        if (!linkEl || !textEl || !children) return;

        this.showTitle = linkEl.offsetWidth > textEl.offsetWidth;
    };

    render() {
        const { children, linkClassName, linkTo, textClassNames } = this.props;

        return (
            <div ref={ this.refText } className={ textClassNames }>
                <Link to={ linkTo }
                      title={ this.showTitle ? children : undefined }
                      onMouseEnter={ this.checkOverflow }
                      className={ linkClassName }>
                    { children }
                </Link>
            </div>
        );
    }
}

export default observer(Subject);
