import BasePortalDesignerCollection from './base';
import RowPortalDesignerModel from '../model/row';

/**
 * Коллекция строк
 * @extends BasePortalDesignerCollection
 */
export default class RowsPortalDesignerCollection extends BasePortalDesignerCollection {

    /**
     *
     * @param rows [RowPortalDesignerModel]
     * @param parent {ContainerPortalDesignerModel}
     */
    constructor(rows, parent) {
        super(rows, RowPortalDesignerModel, parent);
    }
}
