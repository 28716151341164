import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, reaction, makeObservable } from 'mobx';
import * as _ from 'lodash';

import Select from 'components/dynamicForms/view/field/select';

import styles from 'components/dynamicForms/view/field/fieldName/styles.module.scss';
import FieldWrapper from 'components/dynamicForms/view/fieldWrapper';
import FieldNameModel from 'components/dynamicForms/model/field/FieldNameModel';
import { fetchOptions, fetchCondition } from 'actions/components/fieldName';
import FormsState from 'globalState/forms';
import { getTestNameField } from 'helpers/data';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { isChanged } from 'helpers/form';
import langStore from 'globalState/lang';
import { LINK_NOT_FOUND } from 'constants/strings';

/**
 * Описание: компонент FieldName
 * Параметры:
 * onChange: {required, type: function} - метод для изменения
 * value: {type: string}
 * column_id: {type: string}
 * special: {type: object} // теперь special содержит только структуру reference_qualifier: {is_need_request, condition, is_fixed}
 * sys_table_name - имя таблицы
 * sys_column_name - имя поля
 * recordId - id записи
 * dependent_on_column - при использовании на форме: id поля хранящего id таблицы, для которой данный компонент отображает список полей
 *                     - при использовании на листе: значение поля хранящего id таблицы
 */
class FieldName extends React.Component {
    model;

    constructor(props) {
        super(props);

        makeObservable(this, {
            model: observable,
        });

        if (props.model) {
            this.model = props.model;
        } else {
            this.model = new FieldNameModel(props);
        }
        this.events();
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props, prevProps)) {
            if (this.props.model) {
                this.model = this.props.model;
                this.events();
            } else {
                this.model.mergeData(this.props);
            }
        }
    }

    events = async () => {
        const { dependentOnColumn, recordId, special, tableName, columnId } = this.model;
        const { reference_qualifier } = special;
        if (!dependentOnColumn) {
            return;
        }
        let condition;
        if (reference_qualifier) {
            if (reference_qualifier.is_need_request) {
                const response = await fetchCondition(tableName, columnId, recordId);
                condition = response.data.condition;
            }
            else {
                condition = reference_qualifier.condition;
            }
        }
        if (this.model.cellEditMode) { // в режиме инлайн редактирования
            fetchOptions(this.model.forReference?.current?.table_id, condition)
                .then((response) => {
                    this.model.updateData(response.data);
                });
        }
        else {
            reaction(
                () => {
                    let result;
                    const tablesComponent = FormsState.getField(dependentOnColumn);
                    if (tablesComponent) {
                        result = tablesComponent.databaseValue;
                    }
                    return result;
                },
                (table_id) => {
                    if (table_id) {
                        fetchOptions(table_id, condition)
                            .then((response) => {
                                this.model.updateData(response.data);
                            });
                    }
                },
            );
            const tablesComponent = FormsState.getField(dependentOnColumn);
            if (tablesComponent && tablesComponent.databaseValue) {
                const response = await fetchOptions(tablesComponent.databaseValue, condition);
                await this.model.updateData(response.data);
            }
        }
    };

    onChange = ({ value }) => {
        this.model.value = value;
        this.model.changed = isChanged(this.model.defaultValue, this.model.value);

        if (this.props.onChange) {
            this.props.onChange(this.model);
        }
    };

    render() {
        const { reference_titles } = langStore.getTranslate();
        const fieldName = getTestNameField(this.model);
        const dataTest = (fieldName !== 'unknown') ? `${ fieldName }-${ ATTRIBUTES.fieldName }` : `${ ATTRIBUTES.fieldName }`;
        return (
            <div className={ styles.ComponentName }>
                <FieldWrapper model={ this.model }>
                    <Select
                        special={ { values: this.model.values } }
                        onChange={ this.onChange }
                        value={ this.model.value }
                        readOnly={ this.model.readonly }
                        ref={ this.model.ref }
                        data-test={ dataTest }
                        data-test-field-type={ this.model.sys_column_name ? this.model.column_type : undefined }
                        data-test-field-name={ this.model.sys_column_name }
                        data-test-visible={ this.model.isVisible }
                        data-test-mandatory={ this.model.isMandatory }
                        data-test-readonly={ this.model.readonly }
                        validateNotice={ this.model.isDeleted && reference_titles.title_record_not_found }
                        placeholder={ this.model.value?.reference_state === LINK_NOT_FOUND ? reference_titles.placeholder_column_not_found : null  }
                    />
                </FieldWrapper>
            </div>
        );
    }
}

export default observer(FieldName);
