import * as _ from 'lodash';
import InfoMessages from "globalState/infoMessages";

export function sleep(ms = 0) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
}

export function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function arrayChunk(array = [], chunkSize = 2) {
    const result = [];
    let i, j;
    for (i = 0, j = array.length; i < j; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }

    return result;
}

export function getAllFields(sections) {
    if (!sections) return null;
    let fields = [];
    sections.forEach(section => {
        fields = [
            ...fields,
            ...section.elements,
        ];
    });
    return _.clone(fields);
}

/***
 number - число
 decimals - количество знаков после разделителя
 dec_point - символ разделителя
 separator - разделитель тысячных
 Source: https://artkiev.com/blog/number_format-in-javascript.htm
 ***/
export function numberFormat(number, decimals, dec_point, separator) {
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    let n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof separator === 'undefined') ? ',' : separator,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            const k = Math.pow(10, prec);
            return '' + (Math.round(n * k) / k)
                .toFixed(prec);
        };
    // Фиксим баг в IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n))
        .split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '')
        .length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1)
            .join('0');
    }
    return s.join(dec);
}


export function getCookie(name) {
    const matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function setCookie(name, value, options) {
    options = options || {};

    let expires = options.expires;

    if (typeof expires == "number" && expires) {
        const d = new Date();
        d.setTime(d.getTime() + expires * 1000);
        expires = options.expires = d;
    }
    if (expires && expires.toUTCString) {
        options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);

    let updatedCookie = name + "=" + value;

    for (const propName in options) {
        updatedCookie += "; " + propName;
        const propValue = options[propName];
        if (propValue !== true) {
            updatedCookie += "=" + propValue;
        }
    }

    document.cookie = updatedCookie;
}

export function deleteCookie(name) {
    setCookie(name, "", {
        expires: -1,
    });
}

/**
 * https://stackoverflow.com/questions/6122571/simple-non-secure-hash-function-for-javascript
 * простой и быстрый хэш строки
 * можно использовать для генерации key
 * @returns {number}
 */
export function simpleHash(string) {
    let hash = 0;
    if (string.length === 0) {
        return hash;
    }
    for (let i = 0; i < string.length; i++) {
        const char = string.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}

/**
 * https://stackoverflow.com/questions/6122571/simple-non-secure-hash-function-for-javascript
 * хэш сериализованного объекта
 * @returns {number}
 */
export function simpleHashFromObject(object) {
    return simpleHash(JSON.stringify(object));
}

export function getServerErrorsString(responseData) {
    const result = [];
    // Validation errors
    if (responseData.error_type === 'MODEL') {
        if (Array.isArray(responseData.errors)) {
            for (const { message } of responseData.errors) {
                result.push(message);
            }
        }
    }
    // Server text error
    else if (responseData.error_type === 'SERVER') {
        result.push(responseData.errors[0].message);
    }

    return result.join(', ');
}


/**
 * сонвертирует строку из under_score в camelCase
 *
 * @todo вынести в хэлпер
 * @param string {string}
 * @returns {string}
 */
export function underscoreToCamelCase(string) {
    return string.replace(/_\w/g, (match) => match[1].toUpperCase());
}


export function errorListener(event) {
    InfoMessages.pushError(event.message);
}

export const isForbiddenSymbol = (string) => {
    return /^(?=.*[!@#$%^&(),.+=/\-\\\]\[{}?><"':;|№*`])/.test(string);
};


// Функция throttle будет принимать 2 аргумента:
// - callee, функция, которую надо вызывать;
// - timeout, интервал в мс, с которым следует пропускать вызовы.
export const throttle = (callee, timeout) => {
    // Таймер будет определять,
    // надо ли нам пропускать текущий вызов.
    let timer = null;

    // Как результат возвращаем другую функцию.
    // Это нужно, чтобы мы могли не менять другие части кода,
    // чуть позже мы увидим, как это помогает.
    return function perform(...args) {
        // Если таймер есть, то функция уже была вызвана,
        // и значит новый вызов следует пропустить.
        if (timer) return;

        // Если таймера нет, значит мы можем вызвать функцию:
        timer = setTimeout(() => {
            // Аргументы передаём неизменными в функцию-аргумент:
            callee(...args);

            // По окончании очищаем таймер:
            clearTimeout(timer);
            timer = null;
        }, timeout);
    };
};
