import moment from 'moment-timezone';
import userState from 'globalState/user';
import { DEFAULT_DATE_TIME_FORMAT } from "constants/dateTime";
import { getFormatFromPHP } from 'helpers/date';
import langStore from 'globalState/lang';
import listboxState from "globalState/listboxState";
import * as _ from 'lodash';

export function getUserTimezone() {
    let timeZone = moment.tz.guess();
    if (!_.isEmpty(userState.user)) {
        if (userState.user.timezone.toLowerCase() !== 'local') timeZone = userState.user.timezone;
    }

    return timeZone;
}

export function convertServerDateToISO(value) {
    const splittedValue = value.split(' ');
    return splittedValue[0] + 'T' + splittedValue[1] + '.000Z';
}

export function convertServerTimeToISO(value) {
    const splittedValue = value.split(':');
    return {
        hour: splittedValue[0],
        minute: splittedValue[1],
        seconds: splittedValue[2],
    };
}

export const isSameOptions = (item) => {
    return item.getOperator() &&
        [
            'SAMEAS',
            'NSAMEAS',
            'GT_FIELD',
            'LT_FIELD',
            'GT_OR_EQUALS_FIELD',
            'LT_OR_EQUALS_FIELD',
        ].includes(item.getOperator().database_value);
};

export function getTimeZoneDate(date) {
    return moment.tz(date, 'UTC').tz(getUserTimezone());
}

export function convertToServerDateTimeFormat(date) {
    const timeZone = getUserTimezone();
    const timeZoneOffset = moment(date).tz(timeZone).utcOffset() * -1;
    return moment(date).add(timeZoneOffset, 'm').format('YYYY-MM-DD HH:mm:ss');
}

export function getDateValueByString(value, timeZone, format, fromFormat) {
    let tempValue = null;
    if (fromFormat) {
        tempValue = moment(value, fromFormat).format(DEFAULT_DATE_TIME_FORMAT);
    } else {
        tempValue = moment(value).format(DEFAULT_DATE_TIME_FORMAT);
    }
    const tz = moment(convertServerDateToISO(tempValue)).tz(timeZone);
    return moment(tz).format(format);
}

export function getUserDateTimeFormat(type) {
    const format = !_.isEmpty(userState.user) && userState.user.datetime_format ? userState.user.datetime_format : '';
    const dateFormat = type === 'date' ? format.replace(' HH:mm:ss', '').replace(' H:i:s', '') : format;
    return getFormatFromPHP(dateFormat);
}

export function getTranslateDateFormat() {
    const { date_time} = langStore.getTranslate();
    let dateFormat = listboxState.getDateFormat();
    if (date_time) {
        _.forEach(date_time, (value, key) => {
            dateFormat = _.replace(dateFormat, value, key);
        });
    }
    return dateFormat;
}
