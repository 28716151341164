import { WidgetDataType } from 'types/components/widgets/index';
import { ContainerWidgetInstanceType } from 'types/pages/servicePortal/index';
import { action, observable, makeObservable } from 'mobx';
import { PortalWidgetInstanceType, PortalWidgetType } from 'types/pages/servicePortal/container/index';
import PortalWidget from 'globalState/servicePortalWidgets/containers/rows/columns/widgetInstances/widgets/index';
import _ from 'lodash';

export default class PortalWidgetInstance implements PortalWidgetInstanceType {
    private _schemaOptions: WidgetDataType;
    private _sysOrder: number;
    private _widget: PortalWidgetType;
    private _widgetInstanceId: string;

    constructor(data: ContainerWidgetInstanceType) {
        makeObservable(this, {
            _schemaOptions: observable,
            _sysOrder: observable,
            _widget: observable,
            _widgetInstanceId: observable,
            setSchemaOptions: action,
            setSysOrder: action,
            setWidget: action,
            setWidgetInstanceId: action,
            updateWidget: action,
        });

        this._sysOrder = data.sys_order;
        this._widgetInstanceId = data.widget_instance_id;
        this._schemaOptions = data.schema_options;
        this._widget = new PortalWidget(data.widget);
    }

    getSchemaOptions(): WidgetDataType {
        return this._schemaOptions;
    }

    setSchemaOptions(value: WidgetDataType) {
        this._schemaOptions = value;
    }

    getSysOrder(): number {
        return this._sysOrder;
    }

    setSysOrder(value: number) {
        this._sysOrder = value;
    }

    getWidget(): PortalWidgetType {
        return this._widget;
    }

    setWidget(value: PortalWidgetType) {
        this._widget = value;
    }

    getWidgetInstanceId(): string {
        return this._widgetInstanceId;
    }

    setWidgetInstanceId(value: string) {
        this._widgetInstanceId = value;
    }

    updateWidget = (widgetInstance: PortalWidgetInstanceType) => {
        const widget = widgetInstance.getWidget();
        if (_.isEmpty(this._widget)) {
            this._widget = widget;
            return;
        }
        if (!this.isEqualWidget(widgetInstance)) {
            this._widget = widget;
        } else {
            this._widget.updateData(widget.getTemplate(), widget.getClientScript(), widget.getCss());
        }
    };

    isEqualWidget = (widgetInstance: PortalWidgetInstanceType) => {
        const widget = widgetInstance.getWidget();
        return this._widgetInstanceId === widgetInstance.getWidgetInstanceId() && this._schemaOptions === widgetInstance.getSchemaOptions()
            && this._widget.getSysId() === widget.getSysId() && this._widget.getClientScript() === widget.getClientScript()
            && this._widget.getTemplate() === widget.getTemplate() && this._widget.getCss() === widget.getCss();
    };
}
