import { helperRedirect, smartRedirect } from 'helpers/history';
import apiRequest from 'lib/apiRequest';
import globalTableState from 'globalState/table';
import TablesState from 'globalState/tables';
import FormsState from 'globalState/forms';

export function showScriptInfo(response, tableName = null) {
    if (response.data && (response.data.result || response.data.info) && tableName && window.recordGlobal[tableName]) {
        window.recordGlobal[tableName].setScriptInfo(response.data.result, response.data.info);
    }
}

export async function executeScripts(item, tableName = null, recordId = null, currentRowId = null, addParams = null) {
    if (item.client) {
        if (item.script) {
            let promise;
            try {
                const AsyncFunction = new Function(`return Object.getPrototypeOf(async function(){}).constructor`)();
                const args = ['__resolveServerResponse', '__rejectServerResponse', 'rowId', 'rowIds'];

                const fn = new AsyncFunction(...args, item.script);
                const table = TablesState.getTable(tableName);
                const rowIds = table ? table.getCheckedRow(): null;
                let rowId = rowIds && rowIds.length ? rowIds[0] : undefined;
                if (currentRowId) {
                    rowId = currentRowId;
                }
                promise = new Promise((__resolveServerResponse, __rejectServerResponse) => {
                    fn.call(window, __resolveServerResponse, __rejectServerResponse, rowId, rowIds);
                    if (!item.wait_server_response) {
                        __resolveServerResponse();
                    }
                });
            } catch (e) {
                console.warn(item.script);
                console.error(e.message);
            }
            return promise;
        }
    }
    else if (item.url && item.url.length) {
        helperRedirect(item.url, null, recordId);
    }
    else if (typeof item.script !== 'undefined') {
        let requestOptions = {}, component, table;
        requestOptions.ui_action_id = item.sys_id;
        requestOptions.row_id = recordId;
        requestOptions.table_name = tableName;
        component = FormsState.getSection(recordId);
        if (!component) {
            component = window.listGlobal[tableName];
            if (component) {
                component.data.items = globalTableState.getRows();
                table = TablesState.getTable(tableName);
                requestOptions.row_ids = table ? table.getCheckedRow() : null;
            }
        }
        if (addParams) {
            requestOptions = Object.assign(requestOptions, addParams);
        }
        if (item.wait_server_response) {
            return new Promise((__resolveServerResponse) => {
                requestRunUiAction(requestOptions, component, tableName)
                    .then(__resolveServerResponse)
                    .catch((e) => {
                        console.error(e.message);
                    });
            });
        } else {
            requestRunUiAction(requestOptions, component, tableName).catch((e) => {
                console.error(e.message);
            });
        }
    } else if (item.reactScript) {
        item.reactScript(addParams);
    }
}

async function requestRunUiAction(requestData, component, tableName) {
    FormsState.clearChangedFields();
    const response = await new apiRequest('POST /ui-action/run').sendJSON(requestData);
    if (response.status === 301) {
        if (response.location === "" || response.location === window.location.pathname) {
            if (tableName && window.recordGlobal[tableName]) {
                window.recordGlobal[tableName].fetchData(true);
                if (response.messages) {
                    //window.dynamicFormsGlobals['FormMessages'].scrollToMessages();
                    FormsState.getMessages()?.scrollToMessages();
                }
            }
        } else {
            smartRedirect(response.location);
        }
    }
}
