import * as React from 'react';
import * as ReactDOM from 'react-dom';


export default class GlobalPortal extends React.Component {
    children = [];

    constructor(props) {
        super(props);
        this.nodeHeap = document.getElementById('node-heap');
    }

    render() {
        const { children } = this.props;
        if (!children) {
            return null;
        }
        if (!this.nodeHeap) {
            console.warn('Element \'node-heap\' doesn\'t exists');
            return null;
        }
        return ReactDOM.createPortal(
            children,
            this.nodeHeap,
        );
    }
}
