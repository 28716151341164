import * as React from 'react';

import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { getUrlParams } from 'helpers/data';

import HeaderPanel from 'components/portalDesigner/view/header';
import LeftPanel from 'components/portalDesigner/view/leftPanel';
import Main from 'components/portalDesigner/view/main';

import LayoutsStorage from 'components/portalDesigner/storage/layouts';
import PortalPageTemplateStorage from 'components/portalDesigner/storage/portalPageTemplate';
import SysWidgetStorage from 'components/portalDesigner/storage/sysWidget';
import PortalStorage from 'components/portalDesigner/storage/portal';
import PageData from 'components/portalDesigner/state/pageData';
import ErrorWrapperHoc from 'helpers/hoc/errorWrapperHOC';


class PortalDesigner extends React.Component {
    isLoading = true;

    constructor(props) {
        super(props);

        makeObservable(this, {
            isLoading: observable,
        });

        this.init();
    }

    async init() {
        await this.fetch();

        let parsedSearchParams = getUrlParams(location.search);
        if (parsedSearchParams.page) {
            await PageData.setCurrentPage(parsedSearchParams.page);
        }
        this.isLoading = false;
    }

    componentDidCatch(error, info) {
        console.error(error, info);
    }

    fetch() {
        return Promise.all([
            LayoutsStorage.fetch(),
            PortalPageTemplateStorage.fetch(),
            SysWidgetStorage.fetch(),
            PortalStorage.fetch(),
        ]);
    }


    render() {
        return (
            <div>
                <LeftPanel key="leftPanel" />
                <HeaderPanel key="headerPanel" />
                <Main key="main" isLoading={ this.isLoading } />
            </div>
        );
    }
}


export default ErrorWrapperHoc(observer(PortalDesigner));
