import * as React from 'react';
import { sideMenuState } from 'globalState/widgets/sideMenuState';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import { withRouter } from 'react-router-dom';
import { fetchListAData } from 'actions/widgets';
import { observable, makeObservable } from 'mobx';
import _ from 'lodash';
import { helperRedirect } from 'helpers/history';
import EventBusState from 'globalState/eventBus';
import { eventType } from 'constants/eventBusTypes';
import IconCalendar from 'assets/img/icons/calendar.svg';
import IconUser from 'assets/img/user-ava.svg';
import { formatDate } from 'helpers/servicePortal';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { getUrlParams } from 'helpers/data';
import { getCurrentItems, getCurrentItemsByCategoryId, getCurrentItemsByItemId, getDataArray } from 'helpers/widgets/categoryWidget';

/**
 * Виджет для отображения списка категорий и элементов в текущей категории
 */
class CategoryFlatWidget extends React.Component {
    data = [];
    items = [];

    constructor(props) {
        super(props);

        makeObservable(this, {
            data: observable,
            items: observable,
        });

        this.fetchListAData();

        EventBusState.on(eventType.NAVIGATE, this.onElementChange);
    }

    componentDidUpdate(prevProps) {
        const { location } = this.props;
        if (!_.isEqual(location, prevProps.location)) {
            const urlParams = location.search ? getUrlParams(location.search) : {};
            this.fetchListAData(urlParams.category);
        }
    }

    fetchListAData = async (parentCategoryId = null) => {
        const { nodeId, categorySubject, categoryDescription, categoryIcon, itemSubject, itemDescription, itemIcon, location } = this.props;
        const urlParams = location.search ? getUrlParams(location.search) : {};
        const arrPath = location.pathname.split('/');
        const urlSuffix = arrPath[1];
        const pathName = arrPath[2];
        const params = _.omitBy({
            url_suffix: urlSuffix,
            path_name: pathName,
            node: nodeId,
            category_description: categoryDescription,
            category_icon: categoryIcon,
            category_subject: categorySubject,
            item_description: itemDescription,
            item_icon: itemIcon,
            item_subject: itemSubject,
            short_data: '1',
            parent_category_id: parentCategoryId || urlParams.category,
        }, _.isEmpty);
        const response = await fetchListAData(params);
        if (response.isOkStatus) {
            this.data = response.data;
            if (!(parentCategoryId || urlParams.category)) {
                this.setItems();
            } else {
                this.items = getDataArray(this.data);
            }
        }
    };

    onElementChange = (data) => {
        this.fetchListAData(data.category_id);
    };

    handleClickItem = (item) => () => {
        if (item.url.includes('?category')) {
            helperRedirect(item.url, null, { preventReload: true });
            EventBusState.emit(eventType.NAVIGATE, item.url.includes('?category') ? { category_id: item.sys_id } : { item_id: item.sys_id });
        } else {
            helperRedirect(item.url);
        }
    };

    setItems = (categoryId, itemId) => {
        const { location } = this.props;
        const params = location.search ? getUrlParams(location.search) : {};
        if (itemId || params.item) {
            this.items = getCurrentItemsByItemId(itemId || params.item, this.data);
        } else if (categoryId || params.category) {
            this.items = getCurrentItemsByCategoryId(categoryId || params.category, this.data);
        } else {
            if (!location.search) {
                this.items = getDataArray(this.data);
            } else if (sideMenuState.getCurrentUrl()) {
                this.items = getCurrentItems(sideMenuState.getCurrentUrl(), this.data);
            } else {
                const currentUrl = location.pathname + location.search;
                this.items = getCurrentItems(currentUrl, this.data);
            }
        }
    };

    renderIcon = (item) => {
        const { categoryIcon, itemIcon } = this.props;
        const icon = item[categoryIcon] || item[itemIcon];
        if (!icon) {
            return null;
        }
        return (
            <div className={ styles.HeaderIcon }>
                <img src={ icon } />
            </div>

        );
    };

    renderUserIcon = (icon) => {
        if (!icon) {
            return (
                <div className={ styles.UserIcon } dangerouslySetInnerHTML={ { __html: IconUser } } />
            );
        }
        return (
            <div className={ styles.UserIcon } style={{ backgroundImage: `url(${icon})` }}/>
        );
    };

    renderUser = (userColumn) => {
        if (!userColumn) {
            return null;
        }
        return (
            <div className={ styles.User }>
                { this.renderUserIcon(userColumn.photo) }
                <div className={ styles.UserText }>{ userColumn.first_name || '' } { userColumn.last_name || '' }</div>
            </div>
        );
    };

    renderDate = (dateColumn) => {
        if (!dateColumn) {
            return null;
        }
        return (
            <div className={ styles.DateBlock }>
                <div className={ styles.DateIcon } dangerouslySetInnerHTML={{ __html: IconCalendar }} />
                <div className={ styles.DateText }>{ formatDate(dateColumn) }</div>
            </div>
        );
    };

    renderFooter = (item) => {
        const { user, date } = this.props;
        const userColumn = item[user] || null;
        const dateColumn = item[date] || null;
        if (!userColumn && !dateColumn) {
            return null;
        }
        return (
            <div className={ styles.Footer }>
                { this.renderDate(dateColumn) }
                { this.renderUser(userColumn) }
            </div>
        );
    };

    render() {
        if (_.isEmpty(this.data)) {
            return null;
        }
        const { categorySubject, categoryDescription, itemSubject, itemDescription, preview, className } = this.props;
        const categoryItems = _.map(this.items, item => {
            const html = item[preview] || item[categoryDescription] || item[itemDescription] || '';
            return (
                <div key={ item.sys_id } className={ styles.CategoryItem } onClick={ this.handleClickItem(item) }>
                    <div className={ styles.Header }>
                        { this.renderIcon(item) }
                        <div className={ styles.HeaderSubject }>
                            { item[categorySubject] || item[itemSubject] || '' }
                        </div>
                    </div>
                    { html && (
                        <div className={ styles.Text }>
                            <div dangerouslySetInnerHTML={ { __html: html } } />
                        </div>
                    )}
                    { this.renderFooter(item) }
                </div>
            );
        });
        return (
            <div
                className={ className || '' }
                data-test={ this.props['data-test'] ? this.props['data-test'] : `categoryflat-${ ATTRIBUTES.widget }` }
            >
                <div className={ styles.CategoryList }>
                    { categoryItems }
                </div>
            </div>
        );
    }
}

export default withRouter(observer(CategoryFlatWidget));
