import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import { executeScripts } from 'helpers/uiActionsHelper';
import { isEqual } from 'helpers/data';
import langStore from 'globalState/lang';

import styles from './styles.module.scss';
import Checkbox from 'components/dynamicForms/view/field/checkbox';
import * as _ from 'lodash';

class SelectUiAction extends React.Component {
    items = [];

    constructor(props) {
        super(props);

        makeObservable(this, {
            items: observable,
        });

        this.items = this.props.items;
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.items, this.props.items)) {
            this.items = this.props.items;
        }
    }

    onChangeSelect = (value) => {
        if (value.database_value === 'null' || value.database_value === '-1') return;
        const findItem = this.items.find(item => item.sys_id === value.database_value);
        executeScripts(findItem, this.props.essence);
    };

    onChangeCheckbox = (e) => {
        if (this.props.onChangeCheckbox) {
            this.props.onChangeCheckbox(e);
        }
    };

    onLabelClick = () => {
        this.onChangeCheckbox({ value: this.props.value ? 0 : 1 });
    };

    render() {
        const { className } = this.props;
        const { list_titles } = langStore.getTranslate();
        if (_.isEmpty(list_titles)) {
            return null;
        }

        const itemOptions = this.items.map((item, index) => ({
            database_value: String(index),
            display_value: item,
        }));

        itemOptions.unshift({
            database_value: String(-1),
            display_value: `${ list_titles && list_titles.action_on_selected }...`,
        });

        return (
            <div className={ `${ styles.wrap } ${ className ? className : '' }` }>
                <div className={ styles.selectCheck }>
                    <Checkbox value={ this.props.value } onChange={ this.onChangeCheckbox } />
                </div>

                <div onClick={ this.onLabelClick } className={ styles.text }>{ list_titles.select_all }</div>

                {/*<CustomSelect*/}
                {/*    // className={ styles.selectAction }*/}
                {/*    // databaseValue={ this.value }*/}
                {/*    options={ itemOptions }*/}
                {/*    onChange={ this.onChangeSelect }*/}
                {/*/>*/}
            </div>
        );
    }
}

export default observer(SelectUiAction);
