import type {
    ChoiceTitlesType,
    ConditionOperatorTitles,
    DataPickerTitles,
    DataTranslateType,
    DocumentIdTitles,
    DurationTitles,
    FilterTitles,
    ListErrorMessages,
    ListTitles,
    MultiSelectTitles,
    Page403Titles,
    Page404Titles,
    SearchTitlesType,
    SpMainPageType,
    SpSearchType,
    TranslateAuthSsoPageType,
    TranslateBoolType,
    TranslateBrowserUtilitiesType,
    TranslateDateTimeType,
    TranslateFormType,
    TranslateHelpType,
    TranslateItemType,
    TranslateLoginPageType,
    TranslateLogoutPageType,
    TranslateNavigationPlaceholder,
    TranslateNavigationSearchType,
    TranslateNewPasswordPageType,
    TranslatePlaceholdersType,
    TranslatePreferencesType,
    TranslateRecordActions,
    TranslateReference,
    TranslateResetPageType,
    TranslateRichTextType,
    TranslateSearchWidgetTitlesType,
    TranslateSettingsType,
    TranslateSignUpPageType,
    TranslateTemplate,
    TranslateType,
    TranslateWidgetTitlesType,
    UserMenuTitlesType,
    TranslateFormNotificationType,
    TranslateButtonsHints,
    TranslatePresence,
    TranslateExportType,
} from 'types/translate';
import { observable, makeObservable } from 'mobx';
import _ from 'lodash';

export default class Translate implements TranslateType {
    choice_titles = {} as ChoiceTitlesType;
    search_titles = {} as SearchTitlesType;
    sp_main_page = {} as SpMainPageType;
    sp_search = {} as SpSearchType;
    user_menu_titles = {} as UserMenuTitlesType;
    form = {} as TranslateFormType;
    item = {} as TranslateItemType;
    settings = {} as TranslateSettingsType;
    preferences = {} as TranslatePreferencesType;
    bool = {} as TranslateBoolType;
    condition_operator_titles = {} as ConditionOperatorTitles;
    data_picker_titles = {} as DataPickerTitles;
    date_time = {} as TranslateDateTimeType;
    document_id_titles = {} as DocumentIdTitles;
    duration_titles = {} as DurationTitles;
    filter_titles = {} as FilterTitles;
    list_error_messages = {} as ListErrorMessages;
    list_titles = {} as ListTitles;
    multi_select_titles = {} as MultiSelectTitles;
    logout_page = {} as TranslateLogoutPageType;
    help = {} as TranslateHelpType;
    login_page = {} as TranslateLoginPageType;
    template_titles = {} as TranslateTemplate;
    page_403_titles = {} as Page403Titles;
    page_404_titles = {} as Page404Titles;
    auth_sso_page = {} as TranslateAuthSsoPageType;
    placeholders = {} as TranslatePlaceholdersType;
    reset_page = {} as TranslateResetPageType;
    new_password_page = {} as TranslateNewPasswordPageType;
    sign_up_page = {} as TranslateSignUpPageType;
    browser_utilities = {} as TranslateBrowserUtilitiesType;
    record_actions = {} as TranslateRecordActions;
    navigation_placeholder = {} as TranslateNavigationPlaceholder;
    widget_titles = {} as TranslateWidgetTitlesType;
    search_widget_titles = {} as TranslateSearchWidgetTitlesType;
    reference_titles = {} as TranslateReference;
    navigation_search = {} as TranslateNavigationSearchType;
    rich_text = {} as TranslateRichTextType;
    system_buttons_hints = {} as TranslateButtonsHints;
    form_notification_data = {} as TranslateFormNotificationType;
    presence = {} as TranslatePresence;
    exports = {} as TranslateExportType;
    widget_title = '';
    no_items = '';
    configure = '';
    validation_field_mandatory = '';
    open_record = '';
    not_set = '';
    all_types = '';
    only_fields = '';
    only_models = '';
    only_attributes = '';
    no_results = '';

    constructor() {
        makeObservable(this, {
            choice_titles: observable,
            search_titles: observable,
            sp_main_page: observable,
            sp_search: observable,
            user_menu_titles: observable,
            form: observable,
            item: observable,
            settings: observable,
            preferences: observable,
            bool: observable,
            condition_operator_titles: observable,
            data_picker_titles: observable,
            date_time: observable,
            document_id_titles: observable,
            duration_titles: observable,
            filter_titles: observable,
            list_error_messages: observable,
            list_titles: observable,
            multi_select_titles: observable,
            logout_page: observable,
            help: observable,
            login_page: observable,
            template_titles: observable,
            page_403_titles: observable,
            page_404_titles: observable,
            auth_sso_page: observable,
            placeholders: observable,
            reset_page: observable,
            new_password_page: observable,
            sign_up_page: observable,
            browser_utilities: observable,
            record_actions: observable,
            navigation_placeholder: observable,
            widget_titles: observable,
            search_widget_titles: observable,
            reference_titles: observable,
            navigation_search: observable,
            rich_text: observable,
            system_buttons_hints: observable,
            form_notification_data: observable,
            widget_title: observable,
            no_items: observable,
            configure: observable,
            validation_field_mandatory: observable,
            open_record: observable,
            not_set: observable,
            all_types: observable,
            only_fields: observable,
            only_models: observable,
            only_attributes: observable,
            no_results: observable,
            presence: observable,
            exports: observable,
        });
    }

    merge(data: DataTranslateType) {
        _.forEach(data, (item, key) => {
            this[key] = item;
        });
    }
}
