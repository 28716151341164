import modalsDataState from 'globalState/modals';

export default class SimpleModals {
    addModal(id) {
        return modalsDataState.addModal(id);
    }

    getModal(id) {
        return modalsDataState.getModal(id);
    }

    getModalIds() {
        return modalsDataState.getModalIds();
    }
}
