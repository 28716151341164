import * as React from 'react';
import Row from 'components/table/row';
import { ATTRIBUTES } from 'constants/attributesForTests';

export default class Tbody extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { items, classes } = this.props;

        const classBody = classes && classes.Body ? `${ classes.Body }` : '';
        return (
            <tbody
                className={ classBody }
                data-test={ ATTRIBUTES.tableBody }
            >
            {
                items.map((item, index) => {
                    return (
                        <Row
                            key={ `${ item.sys_id }-${ index }` }
                            rowIndex={ index }
                            items={ item }
                            table={ this.props.table }
                            filterFields={ this.props.filterFields }
                            conditionState={ this.props.conditionState }
                            isServicePortal={ this.props.isServicePortal }
                            isRelatedList={ this.props.isRelatedList }
                            onCheckRow={ this.props.onCheckRow }
                            isBlankMode={ this.props.isBlankMode }
                            isWindow={ this.props.isWindow }
                            usedByList={ this.props.usedByList }
                            classes={ this.props.classes }
                            clientScripts={ this.props.clientScripts }
                            isMobile={ this.props.isMobile }
                        />
                    );
                })
            }
            </tbody>
        );


    }
}
