import * as React from 'react';
import InfoMessages from 'components/infoMessages';

import styles from './styles.module.scss';
import ErrorBoundary from 'components/errorBoundary';
import brandingState from 'globalState/branding';


export default function Layout({ children, ...restParams }) {
    const getBrandingData = async () => {
        await brandingState.getBrandingData();
        brandingState.setPlatformFavicon();
    };
    getBrandingData();

    return (
        <span>
            <div className={ `${styles.wrap} ${!restParams.noDefaultBackground ? styles.defaultBg : ''}` }>
                <ErrorBoundary>
                    { React.cloneElement(children, restParams) }
                </ErrorBoundary>
            </div>
            <ErrorBoundary>
                <InfoMessages />
            </ErrorBoundary>
        </span>
    );
}
