import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import Button from 'components/button';
import CustomSelect from 'components/customselect';
import CreateModal from 'components/modalWindow';
import _ from 'lodash';

import styles from './styles.module.scss';
import { ATTRIBUTES } from 'constants/attributesForTests';
import Tooltip from 'components/tooltip';
import { isForbiddenSymbol } from 'helpers/misc';

/**
 * Описание: класс для добавления и выбора view при редактировании Layout
 * Параметры:
 * current: {type: string} - информация о текущем выбранном view
 * items: {type: array} - массив доступных view
 */
class ViewSelect extends React.Component {
    contentRef = React.createRef();
    value;
    inputValue = '';
    items;
    showModal = false;
    isShowPopup = false;
    timeout = null;

    constructor(props) {
        super(props);

        makeObservable(this, {
            value: observable,
            inputValue: observable,
            items: observable,
            showModal: observable,
            isShowPopup: observable,
        });

        this.items = this.props.items.slice();
        this.value = this.props.current;
    }

    componentDidUpdate(prevProps) {
        const { items, current } = this.props;
        if (!_.isEqual(items, prevProps.items)) {
            this.items = this.props.items.slice();
        }

        if (current !== prevProps.current) {
            this.value = this.props.current;
        }
    }

    componentDidCatch(error, info) {
        console.error(error, info);
    }

    handleChange = (value) => {
        this.value = value.display_value;
        this.props.onSelect(this.value);
    };

    handleShowModal = () => {
        this.props.onSelect(this.props.current);
        this.inputValue = '';
        this.showModal = !this.showModal;
    };

    handleChangeInput = (e) => {
        const { value } = e.target;
        if (isForbiddenSymbol(value)) {  // т.к. информация парситься с урла, запретить спец. символы
            this.showPopup();
        }
        else {
            this.inputValue = value;
            this.hidePopup();
        }
    };

    hidePopup = () => {
        clearTimeout(this.timeout);
        this.isShowPopup = false;
    };

    showPopup = () => {
        this.timeout = setTimeout(() => {
            this.isShowPopup = true;
        }, 300);
    };

    renderCreateModal() {
        const { lang = {} } = this.props;

        return (
            <div data-test={ ATTRIBUTES.layoutViewModal }>
                <div className={ styles.Label } data-test={ ATTRIBUTES.layoutViewModalLabel }>
                    { lang.view_name }
                </div>
                <input
                    type="text"
                    value={this.inputValue}
                    ref={ this.contentRef }
                    onChange={ this.handleChangeInput }
                    className={ styles.Input }
                    data-test={ ATTRIBUTES.layoutViewModalInput }
                />
                <div className={ styles.Buttons }>
                    <Button
                        buttonType={'secondary'}
                        onClick={ this.handleShowModal }
                        data-test={ ATTRIBUTES.layoutViewModalCancelBtn }
                    >
                        { lang.cancel_button }
                    </Button>
                    <Button
                        onClick={ this.addItem }
                        buttonType='primary'
                        disabled={!this.inputValue}
                        data-test={ ATTRIBUTES.layoutViewModalAddBtn }
                    >
                        { lang.create_button }
                    </Button>
                </div>
                {
                    this.isShowPopup && (
                        <Tooltip
                            onClose={ this.hidePopup }
                            parent={ this.contentRef.current }
                        >
                            { lang.title_message }
                        </Tooltip>
                    )
                }
            </div>
        );
    }

    addItem = () => {
        this.showModal = false;
        if (this.inputValue === undefined || this.inputValue.trim() === '') return;
        this.props.onAdd(this.inputValue);
        this.inputValue = '';
    };

    render() {
        const { lang } = this.props;

        const itemOptions = this.items.map((item) => ({
            database_value: Object.keys(item)[0],
            display_value: Object.values(item)[0],
        }));

        return (
            <div data-test={ ATTRIBUTES.layoutView }  className={ styles.SelectWrap }>
                <div className={ styles.Label } data-test={ ATTRIBUTES.layoutViewLabel }>
                    { lang.view_name }
                </div>

                <CustomSelect
                    className={ styles.Select }
                    databaseValue={ this.value }
                    options={ itemOptions }
                    onChange={ this.handleChange }
                    data-test={ ATTRIBUTES.layoutViewSelect }
                />

                { this.props.doNotShowAddButton !== true &&
                <div>
                    <Button
                        onClick={ this.handleShowModal }
                        data-test={ ATTRIBUTES.layoutViewAddNewButton }
                    >
                        { lang.add_new_button }
                    </Button>
                </div>
                }


                <CreateModal
                    isShow={ this.showModal }
                    title={ lang.create_new_view }
                    doClose={ this.handleShowModal }
                >
                    { this.renderCreateModal() }
                </CreateModal>

            </div>
        );
    }
}

export default observer(ViewSelect);
