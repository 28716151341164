import * as React from "react";
import { observable, makeObservable } from "mobx";
import { observer } from "mobx-react";
import { withRouter } from 'react-router-dom';
import styles from './styles.module.scss';
import PageLoader from "components/pageLoader/index";
import SmartTitle from "components/smartTitle";
import Select from "components/login/select";
import langStore from 'globalState/lang/index';
import userState from 'globalState/user/index';
import { parseUrlParams } from 'helpers/data';
import AuthHOC from "helpers/hoc/authHoc";
import { getAuthTokenFromIdp } from 'actions/auth';
import { ATTRIBUTES } from "constants/attributesForTests";
import { AuthHocProps } from "types/components/auth/auth";
import Button from "components/button/index";
import IconCheck from "assets/img/icons/check-circle-2.svg";
import {helperRedirect} from "helpers/history";
import {ROUTES} from "constants/routers";

const SERVER_ERROR = 500;
const NOT_FOUND_ERROR = 404;

export class AuthSSORedirect extends React.Component<AuthHocProps> {
    isLoading = false;
    serverErrorMessage: number;

    constructor(props) {
        super(props);

        makeObservable(this, {
            isLoading: observable,
            serverErrorMessage: observable,
        });

        this.setUserToken().catch(this.handleErrorResponse).finally(() => this.isLoading = false);
    }

    setUserToken = async () => {
        const { search } = this.props.location;
        const searchParam = parseUrlParams(new URLSearchParams(search));
        this.isLoading = true;

        const { data, isOkStatus } = await getAuthTokenFromIdp(searchParam);

        if (isOkStatus) {
            userState.setAccessToken(data.authKey).then((isSetToken) => {
                if (!isSetToken) userState.logout();
            });
        }
    };

    handleErrorResponse = ({response}) => {
        const { status } = response;
        if (status === NOT_FOUND_ERROR) {
            this.serverErrorMessage = NOT_FOUND_ERROR;
        } else {
            this.serverErrorMessage = SERVER_ERROR;
        }
    };

    renderNotFoundTemplate = () => {
        const { auth_sso_page = {}, help = {} } = langStore.getTranslate();
        return (
            <>
                <div className={ styles.title }>
                    { auth_sso_page.not_registered_user_message }
                </div>
                <div className={styles.buttonWrap}>
                    <Button
                        buttonType={'default'}
                        onClick={()=>{ helperRedirect(help.site)}}
                    >
                        { auth_sso_page.contact_support_button }
                    </Button>
                </div>
            </>
        );
    };

    renderServerErrorTemplate = () => {
        const { login_page = {}, auth_sso_page = {} } = langStore.getTranslate();
        return (
            <>
                <div className={ styles.title }>
                    { auth_sso_page.system_unavailable_message }
                </div>
                <div className={styles.text}>{ auth_sso_page.try_back_later_message }</div>
                <div className={styles.buttonWrap}>
                    <Button
                        buttonType={'default'}
                        onClick={()=>{ helperRedirect(ROUTES.LOGIN)}}
                    >
                        { login_page.submit_button }
                    </Button>
                </div>
            </>
        );
    };

    render() {
        const { langData, onFetchTranslate } = this.props;
        const { logout_page = {}, auth_sso_page = {}, help = {} } = langStore.getTranslate();
        if (this.isLoading) {
            return (
                <div className={ styles.WrapLoader }>
                    <PageLoader />
                </div>
            );
        }
        return (
            <div className={styles.wrapper}>
                <div className={styles.main}>
                    <div className={ styles.form }>
                        <div className={ styles.container }>
                            <SmartTitle hidden>{ logout_page && logout_page.title }</SmartTitle>
                            <Select
                                onChange={ onFetchTranslate }
                                databaseValue={ langData.langValue }
                                options={ langData.langSpecial }
                                data-test={ ATTRIBUTES.loginSelectLang }
                            />
                            <div className={styles.info}>
                                <div className={styles.infoIcon} dangerouslySetInnerHTML={{__html:IconCheck}}/>
                                { auth_sso_page.adfs_signed }
                            </div>
                            { this.serverErrorMessage === NOT_FOUND_ERROR ?
                                this.renderNotFoundTemplate() :
                                this.renderServerErrorTemplate()
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.contacts}>
                    <div className={styles.contactsItem}>
                        <a href={`mailto:+${help.email}`}>{ help.email }</a>
                    </div>
                    <div className={styles.contactsItem}>
                        <a href={`tel:${help.phone}`}>{ help.phone }</a>
                    </div>
                </div>
            </div>

        );
    }
}

export default withRouter(AuthHOC(observer(AuthSSORedirect)));
