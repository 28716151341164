import * as React from "react";
import { observer } from 'mobx-react';
import styles from "./styles.module.scss";
import langStore from "globalState/lang";
import { SearchResultsTreeProps } from "types/components/portalWidgetsComponents/searchResult/searchResultsTree";
import { GroupTable } from 'types/actions/search';
import { ATTRIBUTES } from 'constants/attributesForTests';

/**
 * Props:
 * groups - [{group: 'name', count: 1, chosen: true}] - Массив групп результатов поиска
 * chosenGroup - идентификатор выбранной группы
 * chooseGroup - метод смены текущей группы
 */
class SearchResultsTree extends React.Component<SearchResultsTreeProps> {
    constructor(props) {
        super(props);
    }

    onGroupClick = (id: string) => {
        const { chooseGroup } = this.props;
        chooseGroup(id);
    };

    renderGroups(groups: GroupTable[]) {
        const { chosenGroup } = this.props;
        return groups?.map((elem) => {
            const dataTestName = `${ATTRIBUTES.searchCategoryLink}${!elem.isNotEmpty ? '-disabled' : ''}`;

            return (
                <div
                    key={ elem.sys_id }
                    className={ `${ styles.Group } ${
                        chosenGroup === elem.sys_id ? styles.current : ""
                    }
                    ${ !elem.isNotEmpty ? styles.disabled : '' }
                    ` }
                    onClick={ () => elem.isNotEmpty && this.onGroupClick(elem.sys_id) }
                    data-test={ dataTestName }
                >
                    <div className={ styles.Bullet } />
                    <div className={ `${ styles.Name }` }>{ elem.title }</div>
                </div>
            );
        });
    }

    render() {
        const { sp_search } = langStore.getTranslate();
        const { groups, renderProgressBar } = this.props;
        return (
            <div>
                <div className={ styles.Title }>{ sp_search?.categories }</div>
                { renderProgressBar() }
                { this.renderGroups(groups) }
            </div>
        );
    }
}

export default observer(SearchResultsTree);
