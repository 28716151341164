import { IIndicatePresenceClass, IIndicatePresencesClass } from 'types/globalState/indicatePresence';
import {observable, makeObservable} from 'mobx';
import _ from 'lodash';

class IndicatePresences implements IIndicatePresencesClass {
    private _indicatePresences: IIndicatePresenceClass[] = [];

    constructor() {
        makeObservable(this, {
            _indicatePresences: observable,
        });
    }

    addIndicatePresence(indicatePresence) {
        this._indicatePresences = [ ...this._indicatePresences, indicatePresence ];
    }

    removeIndicatePresence(indicatePresence) {
        if (indicatePresence) {
            this._indicatePresences = _.filter(this._indicatePresences, (indPresence) => {
                return indPresence.getTableName() !== indicatePresence.getTableName() && indPresence.getRecordId() !== indicatePresence.getRecordId();
            });
        }
    }

    getIndicatePresences() {
        return this._indicatePresences;
    }

    getIndicatePresence(tableName, recordId) {
        return _.find(this._indicatePresences, (indicatePresence) => {
            return indicatePresence.getTableName() === tableName && indicatePresence.getRecordId() === recordId;
        });
    }
}

export default new IndicatePresences();
