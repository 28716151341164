import * as React from 'react';
import styles from 'components/report/multiLevelPivotTable/styles.module.scss';
import { renderFieldDisplayName, renderValueCell } from 'helpers/report/report';
import langStore from 'globalState/lang';

/**
 * Описание: Таблица с исходными данными для отчёта
 * Параметры:
 * data - данные
 * groupByField - значение поля группировки
 * aggregationColHeader - название колонки с агрегированными значениями
 * sliceByField - значение поля для разбиения внутри групп
 * aggregationType - способ агрегирования
 * displayAggregationType - имя для отображения способа агрегирования
 * */
export default class ReportTable extends React.Component {
    constructor(props) {
        super(props);
    }

    getPrecision() {
        const { reportState } = this.props;
        return reportState.styleOptions &&
            reportState.styleOptions.decimalPrecision;
    }

    renderRowData(item) {
        return <>
            <th className={ styles.rowHeader }>
                { renderFieldDisplayName(item.rowName) }
            </th>
            <td>
                { renderValueCell(item, this.getPrecision()) }
            </td>
            <td>
                { item.percentage }
            </td>
        </>;
    }

    renderRootRow(item, index) {
        return <tr key={ index } className={ styles.dataRow }>
            { this.renderRowData(item) }
        </tr>;
    }

    renderSliceRow(rootName, item, isFirstInBlock, childrenAmount, key) {
        const firstInBlockClass = isFirstInBlock ? styles.firstRow : '';
        return <tr key={ key } className={ `${ styles.dataRow } ${ firstInBlockClass }` }>
            {
                isFirstInBlock &&
                <th rowSpan={ childrenAmount } className={ styles.rowHeader }>
                    { renderFieldDisplayName(rootName) }
                </th>
            }
            { this.renderRowData(item) }
        </tr>;
    }

    renderSliceBlock(item, blockIndex) {
        return <React.Fragment key={ blockIndex }>
            {
                item.subRows.map((child, index) =>
                    this.renderSliceRow(item.rowName, child, index === 0, item.subRows.length, blockIndex + '_' + index))
            }
            {
                this.renderTotalRow(item)
            }
        </React.Fragment>;
    }

    renderHeader(key) {
        const {
            groupByField,
            sliceByField,
            aggregationColHeader,
        } = this.props;
        const { report } = langStore.getTranslate();

        return <thead>
        <tr key={ key }>
            <th className={ styles.rowCategory }>{ groupByField } </th>
            {
                sliceByField &&
                <th className={ styles.rowCategory }>
                    { sliceByField }
                </th>
            }
            <th className={ styles.colCategory }>{ aggregationColHeader }</th>
            <th className={ styles.totalColTd }>
                { report?.percentage }
            </th>
        </tr>
        </thead>;
    }

    renderTotalRow(row, rowName) {
        const { report } = langStore.getTranslate();
        return (
            <tr className={ styles.totalRow }>
                <th colSpan={ this.props.sliceByField ? 2 : 1 }>
                    { rowName || report?.total }
                </th>
                <td>
                    { renderValueCell(row, this.getPrecision()) }
                </td>
                <td>{ row.percentage }</td>
            </tr>
        );
    }

    render() {
        const {
            data,
            sliceByField,
            displayAggregationType,
        } = this.props;

        return <div className={ styles.multiPivot }>
            <table>
                {
                    this.renderHeader()
                }
                <tbody>
                {
                    sliceByField ?
                        data.subRows.map((item, index) => this.renderSliceBlock(item, index)) :
                        data.subRows.map((item, index) => this.renderRootRow(item, index))
                }
                {
                    this.renderTotalRow(data, displayAggregationType)
                }
                </tbody>
            </table>
        </div>;
    }
}
