import * as React from 'react';

import styles from './styles.module.scss';
import IconInfo from 'assets/img/icons/info.svg';
import { ATTRIBUTES } from 'constants/attributesForTests';

export default function FormServerErrors({ msg = null }) {
    if (!msg) return null;

    return (
        <div className={ styles.Container }>
            <div className={ styles.MessageText }  data-test={ ATTRIBUTES.loginError }><span className={ styles.Icon } dangerouslySetInnerHTML={ { __html: IconInfo } } />{ msg }</div>
        </div>
    );
}
