import * as React from 'react';
import styles from 'components/dynamicForms/view/field/textArea/styles.module.scss';

import FieldWrapper from 'components/dynamicForms/view/fieldWrapper';
import GlobalPortal from 'components/globalPortal';
import { observer } from 'mobx-react';
import _ from 'lodash';
import TextAreaModel from 'components/dynamicForms/model/field/TextAreaModel';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { getTestNameField } from 'helpers/data';
import { observable, makeObservable } from 'mobx';
import { isChanged } from 'helpers/form';

/**
 * Описание: компонент TextArea
 * Параметры:
 * cell: {type: object} -
 * onChange: {type: function} - метод изменения
 * onKeyDown: {type: function}
 * value: {type: string}
 * save: {type: function} - метод сохранения
 * cellEditMode: {type: boolean} - компонент используется в редакторе ячеек листа
 * className: {type: string} - class для компонента
 * placeholder: {type: string} - placeholder
 * maxHeight: {type: number} - мксимальная высота для расширения
 */
class TextArea extends React.Component {
    model;

    constructor(props) {
        super(props);

        makeObservable(this, {
            model: observable,
        });

        if (props.model) {
            this.model = props.model;
        } else {
            this.model = new TextAreaModel(props);
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props, prevProps)) {
            if (this.props.model) {
                this.model = this.props.model;
            } else {
                this.model.mergeData(this.props);
            }
        }
        if (this.model.isVisible) {
            this.model.setAreaHeight();
        }

    }

    componentDidMount() {
        if(document.fonts) {
            document.fonts.ready.then(() => {
                this.model.setAreaHeight();
            });
        }
        this.model.setAreaHeight();
        this.setCursorPosition();
        window.addEventListener('resize',this.onWindowResize);
    }

    componentWillUnmount() {
        if (this.model.cell) {
            this.model.cell.cursorPosition = this.model.ref.selectionStart;
        }
        window.removeEventListener('resize', this.onWindowResize);
    }

    onWindowResize = () => {
        this.model.setAreaHeight();
    };

    onChange = (event) => {
        this.model.value = event.target.value;
        this.model.changed = isChanged(this.model.defaultValue, this.model.value);
        if (this.props.onChange) {
            this.props.onChange(this.model);
        }
        this.model.setAreaHeight();
    };

    handleKeyDown = (e) => {
        if (this.model.cellEditMode && !this.model.readonly && this.model.save && e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            this.model.save();
        }
        if(this.props.onKeyDown) {
            this.props.onKeyDown(e);
        }
    };



    setCursorPosition = () => {
        if (this.model.refCont && this.model.ref) {
            if (!this.model.refCont.current || !this.model.ref.current) {
                console.warn(`Field ${ this.model.name } doesn't exists`);
                return;
            }
            this.model.ref.current.selectionStart = this.model.ref.current.value.length;
        }
    };

    render() {
        const stylesStr = [styles.input_container];

        if (this.model.className) {
            stylesStr.push(this.model.className);
        }

        if (this.model.isWarning && this.model.cellEditMode) {
            stylesStr.push(styles.warningInput);
        }

        const fieldName = getTestNameField(this.model);
        const dataTest = (fieldName !== 'unknown') ? `${ fieldName }-${ ATTRIBUTES.fieldTextArea }` : `${ ATTRIBUTES.fieldTextArea }`;
        return (
            <FieldWrapper model={ this.model }>
                <div
                    className={ stylesStr.join(' ') }
                    data-test={ dataTest }
                    data-test-visible={ this.model.isVisible }
                    data-test-mandatory={ this.model.isMandatory }
                    data-test-readonly={ this.model.readonly }
                    data-test-field-type={ this.model.sys_column_name ? this.model.column_type : undefined }
                    data-test-field-name={ this.model.sys_column_name }
                >
                    <textarea
                        required={ this.model.isMandatory }
                        ref={ this.model.ref }
                        readOnly={ this.model.readonly }
                        disabled={ this.model.readonly && !this.model.value }
                        value={ this.model.value }
                        onChange={ this.onChange }
                        onKeyDown={ this.handleKeyDown }
                        autoFocus={ this.model.cellEditMode }
                        placeholder={ !this.model.readonly ? this.model.placeholder : '' }
                    />
                </div>
                <GlobalPortal>
                    <div ref={ this.model.refCont } className={ styles.textContainer } />
                </GlobalPortal>
            </FieldWrapper>
        );
    }
}

export default observer(TextArea);
