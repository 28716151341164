import _ from 'lodash';
import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import langStore from "globalState/lang";
import DurationMsConverter from 'helpers/DurationMsConverter';
import { getDateValueByString, getUserDateTimeFormat, getUserTimezone } from 'helpers/getUserTimeZone';
import { getLinkDisplayValue } from 'helpers/form';
import IconEye from 'assets/img/icons/eye-off.svg';
import { LINK_TITLE_UNAVAILABLE, LINK_NOT_FOUND, LINK_UNAVAILABLE } from 'constants/strings';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { Element, FieldTypes } from 'types/components/recordPopup/recordPopup';

interface PreviewFormProps {
    // массив с полями формы
    fields: Element[];
}

class PreviewFormComponent extends React.Component<PreviewFormProps> {
    constructor(props) {
        super(props);
    }

    cutText = (text: string, maxLength = 70) => {
        const stringText = text?.toString();
        if (stringText.length > maxLength) {
            return stringText.slice(0, maxLength) + '...';
        } else {
            return stringText;
        }
    };

    isValueEmpty = (value): boolean => {
        if (_.isNil(value)) return true;

        if (_.isPlainObject(value)) {
            return Object.keys(value).length === 0 && (value?.display_value === null || value?.display_value === "");
        }
        if (Array.isArray(value)) {
            return value.length === 0;
        }
        return value === '';
    };

    getRefDisplayValue = (fieldType: FieldTypes, value) => {
        const { reference_titles } = langStore.getTranslate();

        const isUnavailable = value?.reference_state === LINK_UNAVAILABLE || value?.reference_state === LINK_TITLE_UNAVAILABLE;
        const badgeEye = <div className={ styles.BadgeEye } dangerouslySetInnerHTML={ { __html: IconEye } } data-test={ ATTRIBUTES.badgeEye } />;

        let displayValue = '';
        if (value?.reference_state === LINK_NOT_FOUND && fieldType === 'field_name'){
            displayValue = reference_titles.placeholder_column_not_found;
        } else if ([LINK_NOT_FOUND, LINK_UNAVAILABLE, LINK_TITLE_UNAVAILABLE].includes(value?.reference_state)){
            displayValue = getLinkDisplayValue(value);
        } else {
            if (fieldType === 'id'){
                displayValue = this.cutText(value?.record_title);
            } else {
                displayValue = value?.display_value;
            }
        }
        return <div className={ `${ styles.Text } ${ value.reference_state ? styles.Hidden : '' }` }>
            { isUnavailable && badgeEye }
            { displayValue }
        </div>;
    };

    renderFieldContent = (fieldType: FieldTypes, fieldValue) => {
        const { bool, template_titles } = langStore.getTranslate();

        switch (fieldType) {
            case FieldTypes.Boolean:
                return <div className={ styles.Text }>{ fieldValue.toString() === '1' ? bool?.yes : bool?.no }</div>;
            case FieldTypes.List:
                return this.renderLinksList(fieldType, fieldValue);
            case FieldTypes.Url:
                return this.renderLinksList(fieldType,[ {
                        display_value: fieldValue,
                        link: fieldValue,
                    } ]
                );
            case FieldTypes.Choice:
            case FieldTypes.RecordClass:
                return <div className={ styles.Text }>{ fieldValue.display_value }</div>;
            case FieldTypes.Reference:
            case FieldTypes.FieldName:
                return this.getRefDisplayValue(fieldType, fieldValue);
            case FieldTypes.Html:
                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = fieldValue.replace(/<table .*>.*<\/table>/gi, '');
                return <div className={ styles.Text }>{ this.cutText(tempDiv.innerText) }</div>;
            case FieldTypes.Duration:
                const durationStr = DurationMsConverter.msToString(fieldValue);
                return <div className={ styles.Text }>{ this.cutText(durationStr) }</div>;
            case FieldTypes.Id:
                const recordTitle = fieldValue.record_title ? fieldValue.record_title : '';
                return <div className={ styles.Text }>{ this.cutText(recordTitle) }</div>;
            case FieldTypes.Image:
                return <div className={ styles.Image } style={ { backgroundImage: `url("${ fieldValue.url }")` } } />;
            case FieldTypes.Datetime:
                return <div className={ styles.Text }>{ this.cutText(getDateValueByString(fieldValue, getUserTimezone(), getUserDateTimeFormat())) }</div>;
            case FieldTypes.Template:
                return <div className={ styles.Text }>{ template_titles.filled }</div>;
            default:
                return <div className={ styles.Text }>{ this.cutText(fieldValue) }</div>;
        }
    }

    renderContent = (fieldData: Element) => {
        const { not_set } = langStore.getTranslate();
        let content: JSX.Element;
        const { column_type: fieldType, value: fieldValue } = fieldData;
        if (this.isValueEmpty(fieldValue)) {
            content = <div className={ styles.NotSet }>({ not_set ? not_set : '' })</div>;
        } else {
            content = this.renderFieldContent(fieldType, fieldValue);
        }
        return content;
    };

    renderLinksList = (fieldType, values) => {
        const items = values.map((item, index) => {
            let link;
            if (!item.link) {
                link = this.getRefDisplayValue(fieldType, item);
            } else if (item.link.match(/http[s]?:\/\//)) {
                link = <a href={ item.link }> { item.display_value } </a>;
            } else {
                link = <Link className={ styles.Link } to={ item.link }>
                    { this.getRefDisplayValue(fieldType, item) }
                </Link>;
            }

            return (
                <div className={ styles.Item } key={ item.display_value + index.toString() }>
                    { link }
                </div>
            );
        });

        return <div className={ styles.LinksList }>{ items }</div>;
    };

    renderField = (fieldData: Element) => {
        if (fieldData.hasOwnProperty('split') || fieldData.hasOwnProperty('formatter') || fieldData.hasOwnProperty('widget_instance_id')) return;
        return (
            <div className={ styles.Field } key={ fieldData.column_id }>
                <div className={ styles.FieldName } data-test={ ATTRIBUTES.popupFieldName }>
                    { fieldData.name }
                </div>
                <div className={ styles.FieldContent } data-test={ ATTRIBUTES.popupFieldContent }>
                    { this.renderContent(fieldData) }
                </div>
            </div>
        );
    };


    render() {
        const fields = this.props.fields.map(this.renderField);
        return (
            <div className={ styles.Preview }>
                { fields }
            </div>
        );
    }
}

export default observer(PreviewFormComponent);
