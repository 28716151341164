import * as React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import styles from './styles.module.scss';

/***
 * Описание: Каркас страницы листа, страницы словаля, и просто листа
 *
 * Параметры:
 * isWindow: {type: boolean} - страница словаря
 * isMobile: {type: boolean} - мобильное отображение
 * isList: {type: boolean} - только лист
 */
class ListPageSkeleton extends React.Component {
    constructor(props) {
        super(props);
    }

    getTable = () => {
        const { isMobile, isList } = this.props;
        const colsLength = isMobile ? 2 : 6;
        const rowsLength = isMobile ? 10 : 14;

        return (
            <>
                { !isList ? <div className={ styles.Filter }>
                    <div className={ `${ styles.Placeholder } ${ styles.FilterTitle }` } />
                    { !isMobile && <div className={ `${ styles.Placeholder } ${ styles.FilterPag }` } /> }
                </div> : <div className={styles.Pagination}>
                    <div className={ `${ styles.Placeholder } ${ styles.PaginationTitle }` } />
                    { !isMobile && <div className={ `${ styles.Placeholder } ${ styles.FilterPag }` } /> }
                </div>}
                <div className={ styles.Table }>
                    { _.times(colsLength, (index) => {
                        return (
                            <div key={ 'col' + index } className={ isMobile ? styles.MobCol : styles.TableCol }>
                                <div className={ `${ styles.TablePlaceholder } ${ styles.TableTitle }` } />
                                <div className={ `${ styles.TablePlaceholder } ${ styles.TableSearch }` } />
                                { _.times(rowsLength, (index) => <div key={ 'cont' + index } className={ `${ styles.TablePlaceholder } ${ styles.TableCont }` } />) }
                            </div>
                        );
                    }) }
                </div>
            </>
        );
    };

    getPanel = () => {
        const { isMobile } = this.props;

        return (
            <div className={ styles.Panel }>
                <div className={ `${ styles.Placeholder } ${ styles.PanelTitle }` } />
                { !isMobile && (
                    <>
                        <div className={ `${ styles.Placeholder } ${ styles.PanelBtn }` } />
                        <div className={ `${ styles.Placeholder } ${ styles.PanelBtnRight }` } />
                    </>
                ) }
            </div>
        );
    };

    render() {
        const { isMobile, isList, isWindow } = this.props;

        return (
            <div className={ `${ styles.Skeleton } ${ isMobile ? styles.mobile : '' } ${ isList ? styles.list : '' }` }>
                { (!isWindow && !isList) && this.getPanel() }
                { this.getTable() }
                <div className={ styles.SkeletonShadow } />
            </div>
        );
    }
}

export default observer(ListPageSkeleton);
