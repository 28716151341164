import BaseFormFieldModel from './BaseFormFieldModel';
import DurationMsConverter from 'helpers/DurationMsConverter';
import { isEmpty } from 'helpers/isEmpty';

const max = {
    days: 99999,
    hours: 23,
    minutes: 59,
    seconds: 59,
};
export default class DurationModel extends BaseFormFieldModel {
    columnType = 'duration';

    constructor(data, parentFormSectionModel) {
        super(data, parentFormSectionModel);
        super.mergeData(data);

        if (typeof this.value === 'undefined') {
            this.value = {
                days: '',
                hours: '',
                minutes: '',
                seconds: '',
            };
        }
    }

    set(key, value) {
        if (value > max[key]) {
            return;
        }

        let tempValue =  { ...this.value };
        tempValue[key] = value;

        this.value = tempValue;
    }

    checkEmptyValue() {
        if (isEmpty(this.value)) return true;

        const { days, hours, minutes, seconds } = this.value;
        return [days, hours, minutes, seconds].every(elem => elem === '');
    }

    /**
     *
     * @param value {number | string | null | object} - кол-во мл. сек | duration.
     */
    setValue(value) {
        if (typeof value === 'number' || typeof value === 'string' || value === null) {
            const parseValue = Number(value);
            if(Number.isNaN(parseValue))  {
                return this.value;
            }
            return DurationMsConverter.toDuration(value);
        }
        return {...value };
    }

    uiGetValue() {
        return this.getValueForSave();
    }

    uiGetDisplayValue() {
        return this.getValueForSave();
    }

    getValueForSave() {
        return DurationMsConverter.toMs(this.value);
    }
}
