import * as React from 'react';
import styles from 'components/dynamicForms/view/field/select/styles.module.scss';

import { observer } from 'mobx-react';
import _ from 'lodash';

import FieldWrapper from 'components/dynamicForms/view/fieldWrapper';
import CustomSelect from 'components/customselect';
import SelectModel from 'components/dynamicForms/model/field/SelectModel';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { getTestNameField } from 'helpers/data';
import { observable, makeObservable } from "mobx";
import { isChanged } from 'helpers/form';
import RadioButtons from 'components/radioButtons';

/**
 * Описание: компонент Select
 * Параметры:
 * special: {required, type: object} - объект, где values являются options для select
 * value: {required, type: string or object} - значение
 * onChange: {required, type: function} - метод для изменения значения
 * label: {type: string} - label
 * className: {type: string} - class css
 * multiple: {type: boolean}
 * cellEditMode: {type: boolean} - компонент используется в редакторе ячеек листа
 */
class Select extends React.Component {
    model;

    constructor(props) {
        super(props);

        makeObservable(this, {
            model: observable,
        });

        if (props.model) {
            this.model = props.model;
        } else {
            this.model = new SelectModel(props);
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props, prevProps)) {
            if (this.props.model) {
                this.model = this.props.model;
            } else {
                this.model.mergeData(this.props);
            }
        }
    }

    onChange = (value) => {
        this.model.value = value;
        this.model.changed = isChanged(this.model.defaultValue, this.model.value);
        const { onChange } = this.props;
        if (onChange) {
            onChange(this.model);
        }

        // в режиме редактора ячеек сохраняем изменённое значение
        if (this.model.cellEditMode && !this.model.readonly && this.model.save){
            this.model.save();
        }
    };

    render() {
        const {
            special,
            className,
            value,
            readonly,
        } = this.model;
        const values = _.clone(special.values);

        const stylesStr = [ styles.Input ];
        if (className) {
            stylesStr.push(className);
        }

        let selectValue;
        if (_.isPlainObject(value)) {
            selectValue = !_.isNil(value.database_value) ? value.database_value : null;
        }
        else {
            selectValue = value;
        }

        if (value && (value.database_value === '' || value.database_value === null)) {
            if (values.findIndex((val) => val.database_value === null) === -1) {
                values.push({
                    database_value: null,
                    display_value: '',
                    disabled: true,
                    color: 'default',
                });
            }
        }

        const fieldName = getTestNameField(this.model);
        const dataTest = (fieldName !== 'unknown') ? `${ fieldName }-${ ATTRIBUTES.fieldSelect }` : `${ ATTRIBUTES.fieldSelect }`;
        return (
            <FieldWrapper model={ this.model }>
                <div
                    className={ stylesStr.join(' ') }
                    data-test={ this.props['data-test'] ? this.props['data-test'] : dataTest }
                    data-test-visible={ !!(this.props['data-test-visible'] || this.model.isVisible) }
                    data-test-mandatory={ !!(this.props['data-test-mandatory'] || this.model.isMandatory) }
                    data-test-readonly={ !!(this.props['data-test-readonly'] || this.model.readonly) }
                    data-test-field-type={ this.props['data-test-field-type'] || this.model.column_type }
                    data-test-field-name={ this.props['data-test-field-name'] || this.model.sys_column_name }
                >
                    { this.model.radioButtonsMode ?
                        <RadioButtons
                            options={ values }
                            onChange={ this.onChange }
                            disabled={ readonly }
                            value={ selectValue !== undefined ? selectValue : null }
                        />
                        :
                        <CustomSelect
                            disabled={ readonly }
                            databaseValue={ selectValue !== undefined ? selectValue : null }
                            options={ values }
                            onChange={ this.onChange }
                            isWarning={ this.model.isWarning && this.model.cellEditMode }
                            placeholder={ this.model.placeholder }
                        />
                    }
                </div>
            </FieldWrapper>
        );
    }
}

export default observer(Select);
