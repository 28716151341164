import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import Tooltip from 'components/tooltip';

import styles from './styles.module.scss';

/**
 * Описание: короткое описание и тултип для заголовков таблицы
 * Праметры:
 * gradientClassName: {required: true,  type: string} - цсс класс, описывает цвет для градиента, скрывающего текст.
 * sortIcon: {required: false,  type: html} - иконка сортировки
 * */
class TableTitleComponent extends React.Component {
    contentRef = React.createRef();
    containerRef = React.createRef();
    isShowPopup = false;
    hasOverflow = false;
    timeout = null;

    constructor(props) {
        super(props);

        makeObservable(this, {
            isShowPopup: observable,
            hasOverflow: observable,
        });
    }

    componentDidMount() {
        this.checkOverflow();
        window.addEventListener('resize', this.checkOverflow);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkOverflow);
    }

    hidePopup = () => {
        clearTimeout(this.timeout);
        this.isShowPopup = false;
    };

    showPopup = () => {
        this.timeout = setTimeout(() => {
            this.isShowPopup = true;
        }, 300);
    };

    checkOverflow = () => {
        const contentRect = this.contentRef && this.contentRef.current ? this.contentRef.current.getBoundingClientRect() : null;
        const containerRect = this.containerRef && this.containerRef.current ? this.containerRef.current.getBoundingClientRect() : null;
        this.hasOverflow = contentRect && containerRect && containerRect.width > contentRect.width;
    };

    render() {
        return (
            <div className={ styles.content } onMouseLeave={ this.hidePopup } onMouseEnter={ this.showPopup }>
                <div className={ styles.containerWrap } ref={ this.contentRef }>
                    <div className={ styles.container } ref={ this.containerRef }>
                        { this.props.children }
                    </div>
                    { this.hasOverflow && <div className={ `${ styles.gradient } ${ this.props.gradientClassName }` } /> }
                </div>
                { this.props.sortIcon }
                {
                    this.hasOverflow && this.isShowPopup && (
                        <Tooltip
                            onClose={ this.hidePopup }
                            parent={ this.contentRef.current }
                            >
                            { this.props.children }
                        </Tooltip>
                    )
                }
            </div>
        );
    }
}

export default observer(TableTitleComponent);
