import React, {useEffect, FC} from 'react';
import styles from './styles.module.scss';
import IconResizer from 'assets/img/icons/resizer.svg';
import type {ResizePluginProps} from "types/components/textEditor/resizePlugin";

export const ResizePlugin: FC<ResizePluginProps> = ({editorRef, isAdditional}) => {
    if (isAdditional) {
        return null;
    }

    const [initialPos, setInitialPos] = React.useState<number>(Infinity);
    const [dims, setDims] = React.useState<number>(Infinity);
    const step = 5;

    const initResize = (evt: React.MouseEvent) => {
        evt.preventDefault();
        evt.stopPropagation();

        if (!editorRef.current) return;

        setInitialPos(evt.clientY);
        const {height} = window.getComputedStyle(editorRef.current);
        setDims(parseInt(height, 10));
    };

    useEffect(() => {
        const getValue = (input: number) => Math.ceil(input / step) * step;

        const doDrag = (evt) => {
            if (!editorRef.current) return;
            const maxHeight = 580;
            const offset = 20;

            const height = getValue(dims + evt.clientY - initialPos);
            if (height <= maxHeight) {
                editorRef.current.style.height = height + 'px';
                // Меняем ещё размер блока ввода для текста
                if (editorRef.current.children[0].scrollHeight <= editorRef.current.clientHeight) {
                    (editorRef.current.children[0] as HTMLElement).style.height = (height - offset) + 'px';
                } else {
                    (editorRef.current.children[0] as HTMLElement).style.height = (editorRef.current.clientHeight - offset) + 'px';
                }
            }
        };

        const stopDrag = () => {
            document.removeEventListener('mousemove', doDrag, false);
            document.removeEventListener('mouseup', stopDrag, false);
        };

        document.addEventListener('mousemove', doDrag, false);
        document.addEventListener('mouseup', stopDrag, false);
    }, [dims, initialPos, editorRef]);

    return (
        <div
            className={styles.ResizeContainer}
            onMouseDown={initResize}
        >
            <div
                className={styles.Icon}
                dangerouslySetInnerHTML={{__html: IconResizer}}
            />
        </div>
    );
};

