import _ from 'lodash';

class EventBusState {

    _subscriptions = {};
    _currentId = 0;

    _generateID = () => {
        return this._currentId++;
    };

    //Инициировать событие
    emit = (eventType, data) => {
        if (this._subscriptions[eventType]) {
            this._subscriptions[eventType].forEach((callback) => {
                callback(data);
            });
        }
    };

    //Подписаться на событие
    on = (eventType, callback) => {
        if (!this._subscriptions[eventType]) {
            this._subscriptions[eventType] = [];
        }
        if (typeof callback === 'function') {
            const id = this._generateID();
            this._subscriptions[eventType][id] = callback;
            return {
                unsubscribe: this.unsubscribe(eventType, id),
            };
        }
        else {
            throw 'Callback is not a function!';
        }
    };

    //Отписаться
    unsubscribe = (eventType, id) => () => {
        if (!_.isEmpty(this._subscriptions[eventType])) {
            delete this._subscriptions[eventType][id];
            if (this._subscriptions[eventType].length === 0) {
                delete this._subscriptions[eventType];
            }
        }
    };

    //Удалить событие с подписками
    deleteEvent = (eventType) => {
        delete this._subscriptions[eventType];
    };

    //Получить все подписки
    getSubscriptions = () => (this._subscriptions);

    //Сбросить состояние
    reset = () => {
        this._subscriptions = {};
        this._currentId = 0;
    };
}


export default new EventBusState();
