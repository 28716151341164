import * as React from 'react';

import styles from './styles.module.scss';
import { getUrlParams } from 'helpers/data';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import _ from 'lodash';
import SmartTitle from 'components/smartTitle';

import sysDBTables from 'globalState/sysDBTables';
import conditionState from 'globalState/conditions/condition';

import { fetchRecords, getParamField, REPORT_ERROR_KEY } from 'helpers/report/report';
import { CODE_403_FORBIDDEN } from 'lib/apiRequest';
import { FormSection } from 'components/dynamicForms/view/form';

import ReportView from './view/';
import langStore from 'globalState/lang';
import { extractStylesFromRecord, conversionToCommonData } from 'helpers/report/reportStylesHelper';
import reportState, { IS_LOAD_DATA, IS_UPDATE_CHART } from 'globalState/report';
import {
    renderSectionsTabs,
    generateReportNameFromType,
    formStyles,
    getSteps,
    renderFirstSection,
    parseChartData,
    renderFooterButtons,
    renderTypeSection,
    fetchError,
    getChartByData,
    saveReport,
    renderStyleSection,
    getReportTitle, getUiActions,
} from 'helpers/report/reportMaster';
import { fetch, fetchChart, getChartByReportId } from 'actions/report';
import { getFieldGroupBy } from 'helpers/report/reportMaster';
import InfoMessagesState from 'globalState/infoMessages';
import ErrorPage from 'pages/ErrorPage';
import ErrorWrapperHoc from 'helpers/hoc/errorWrapperHOC';
import FormMessages from 'components/formMessages';
import { calculateCondtionString } from 'helpers/condition';
import FormsState from 'globalState/forms';
import moment from 'moment-timezone';
import { removeConditionSortAndGroup } from 'helpers/report/reportComponent';
import { globalEventEmitter } from 'lib/EventEmitter';
import { EnhancedComponent } from 'components/EnhancedComponent';
import SubFormModal from 'components/subForm/subFormModal';

/**
 * Report - страница для отображения мастера отчетов
 */
class Report extends EnhancedComponent {
    styleTab = new Set;
    isChartLoaded;
    formData;

    constructor(props) {
        super(props);

        makeObservable(this, {
            styleTab: observable,
            isChartLoaded: observable,
        });

        this.updateLocales();
        this.onStartEdit();
        this.useEffect(this.useImpersonateHandler);

    }

    // Update page data when we do impersonate or deImpersonate
    useImpersonateHandler = () => {
        const handleImpersonate = () => {
            reportState.clearType();
            fetchRecords(reportState.report_id);
            this.getChart(reportState.report_id);
        };

        return globalEventEmitter.subscribe([
            'impersonate',
            'deImpersonate',
        ], handleImpersonate);
    };

    componentDidUpdate(prevProps) {
        const { match, location } = this.props;

        // Если перешли к созданию/редактированию другого отчёта, очищаем стейт
        if ((reportState.report_id && reportState.report_id !== match.params.report_id)
            || (prevProps.location.search !== location.search && location.search === '' && !reportState.report_id)) {
            reportState.clearData();
            this.onStartEdit();
            return;
        }

        if (reportState.configForm.current) {
            const { data } = reportState.getData();
            const params = getUrlParams(location.search);
            const prevParams = getUrlParams(prevProps.location.search);
            if (!reportState.report_id && !match.params.report_id && !reportState.additionalGroupValue) {
                const groupBy = getFieldGroupBy(reportState.record);
                if (groupBy && groupBy.value && groupBy.value[0] && params.group_by !== groupBy.value[0].database_value) {
                    reportState.setGroupByToUrl(groupBy.value[0].database_value);
                }
            }
            // чистка параметров, когда все стираем с верхнего юрла. Например, переходим по ссылке
            if ((prevParams.table_id || prevParams.group_by || prevParams.type || prevParams.name)
                && (params.menu_id && Object.keys(params).length === 1)) {
                reportState.clearCommonData();
                reportState.updateUrl();
            }
            this.formData = data;
        }

        if (match.params.report_id && match.params.report_id !== reportState.report_id && reportState.types) {
            reportState.report_id = match.params.report_id;
            reportState.sFormSysId = match.params.report_id;
            this.getChart(reportState.report_id);
        }

        reportState.params = getUrlParams(location.search);
    }

    /**
     *
     * @param error
     * @param info
     */
    componentDidCatch(error, info) {
        console.error(error, info);
    }

    componentWillUnmount() {
        reportState.conditionState.clearData();
        reportState.clearData();
    }

    onStartEdit() {
        const { search } = this.props.location;
        let tempParams = getUrlParams(search);
        if (tempParams.condition) {
            tempParams = { ...tempParams, condition: removeConditionSortAndGroup(tempParams.condition) };
        }
        reportState.params = tempParams;
        reportState.setParentFormId();

        reportState.stateFlags = reportState.stateFlags | IS_LOAD_DATA;
        reportState.conditionState = conditionState;
        const { report_id, table_id } = reportState.params;

        if (report_id) {
            reportState.report_id = report_id;
            reportState.sFormSysId = report_id;
        }
        const { condition } = this.props;

        reportState.fixedCondition = condition;
        reportState.params.condition = calculateCondtionString(condition, tempParams.condition, search);
        if (table_id && reportState.params.condition) {
            reportState.conditionState
                       .parseConditionString(reportState.params.condition, null, table_id)
                       .catch(fetchError(reportState));
        }

        reportState.clearRecord();
        reportState.clearChart();
        FormsState.clear(false);

        this.fetchAllTypes();
    }

    updateLocales = () => {
        if (langStore.lang === 'en') {
            moment.updateLocale('en', {
                relativeTime : {
                    future: "in %s",
                    past: "%s ago",
                    s: "seconds",
                    m: "%d minute",
                    mm: "%d minutes",
                    h: "%d hour",
                    hh: "%d hours",
                    d: "%d day",
                    dd: "%d days",
                    M: "%d month",
                    MM: "%d months",
                    y: "%d year",
                    yy: "%d years",
                },
            });
        }
        if (langStore.lang === 'ru') {
            moment.updateLocale('ru', {
                relativeTime : {
                    future : 'через %s',
                    past : '%s назад',
                    s : 'несколько секунд',
                    ss : this.relativeTimeWithPlural,
                    m : this.relativeTimeWithPlural,
                    mm : this.relativeTimeWithPlural,
                    h : '%d час',
                    hh : this.relativeTimeWithPlural,
                    d : '%d день',
                    dd : this.relativeTimeWithPlural,
                    M : '%d месяц',
                    MM : this.relativeTimeWithPlural,
                    y : '%d год',
                    yy : this.relativeTimeWithPlural,
                },
            });
        }
    };

    relativeTimeWithPlural = (number, withoutSuffix, key) => {
        const format = {
            'ss': withoutSuffix ? 'секунда_секунды_секунд' : 'секунду_секунды_секунд',
            'mm': withoutSuffix ? 'минута_минуты_минут' : 'минуту_минуты_минут',
            'hh': 'час_часа_часов',
            'dd': 'день_дня_дней',
            'MM': 'месяц_месяца_месяцев',
            'yy': 'год_года_лет',
        };
        if (key === 'm') {
            return withoutSuffix ? '%d минута' : 'минуту';
        }
        else {
            return number + ' ' + this.plural(format[key], +number);
        }
    };

    plural = (word, num) => {
        const forms = word.split('_');
        return num % 10 === 1 && num % 100 !== 11 ? forms[0] : (num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20) ? forms[1] : forms[2]);
    };

    /**
     * Получение всех типов
     * @returns {Promise<void>}
     */
    fetchAllTypes = async () => {
        const [responseType] = await fetch().catch(fetchError(reportState));
        if (responseType && responseType.status === 'OK') {
            const data = responseType.getData();
            reportState.types = data.elements;
        }
        await this.loadChartFromURLData();
    };

    /**
     * загрузка чарта на основе данных из URL
     * @example /report?table_id=156636392003126252&type=bar&group_by=156636381102624914
     */
    loadChartFromURLData = async () => {
        if (reportState.report_id) {
            this.isChartLoaded = true;
        }
        const { table_id, type, group_by, name } = reportState.params;

        if (name) {
            reportState.setName(name);
        }

        if (table_id) {
            const table = sysDBTables.getById(table_id);
            reportState.setTable({
                database_value: table.sys_id,
                display_value: table.title,
                reference_state: null,
            }, true);
        }

        if (type) {
            reportState.setName(name ?
                name :
                generateReportNameFromType(reportState.type ? reportState.type.type_name : type),
            );

            await reportState.setReportType({ name: type }, reportState.types);
        }

        await fetchRecords();
        if (table_id && type && ![
            'Line',
            'Trends',
            'Multilevel pivot',
            'Heatmap',
        ].some((elementType) => type === elementType)) {
            if (group_by || [
                'Digits',
                'Gauge',
                'List',
            ].some((elementType) => type === elementType)) {
                return this.getChart();
            }
        }
        reportState.stateFlags = reportState.stateFlags & ~IS_LOAD_DATA;
        if (this.isChartLoaded) {
            this.isChartLoaded = false;
        }
        return Promise.resolve();
    };


    /**
     * Формирование запроса к данным на сервер
     * @param reportId
     * @param additionalGroupValue
     * @param page
     * @returns {Promise<void>}
     */
    getChart = async (reportId, additionalGroupValue, page) => {
        reportState.stateFlags = reportState.stateFlags | IS_UPDATE_CHART;
        this.isChartLoaded = true;
        reportState.indexReportError = false;
        if (reportId) {
            reportState.stateFlags = reportState.stateFlags | IS_LOAD_DATA;
        }
        const { location } = this.props;
        const { requestToChart, paramsToChart } = reportId ? {
            requestToChart: getChartByReportId,
            paramsToChart: {
                reportId,
                page,
            },
        } : {
            requestToChart: getChartByData(reportState),
            paramsToChart: {
                additionalGroupValue,
                page,
                location,
            },
        };
        const { error } = reportState.getData();
        if (error && error.length) {
            InfoMessagesState.pushError(error.join('<br />'));
            reportState.stateFlags = reportState.stateFlags & ~(IS_LOAD_DATA | IS_UPDATE_CHART);
            return;
        }

        const result = await fetchChart(requestToChart, paramsToChart);
        if (result) {
            let { data } = result;
            if (data) {
                reportState.chart = data;
                if (reportId && _.isEqual(reportState.styles, {}) && reportState.chart.record_data) {
                    const styles = extractStylesFromRecord(reportState.chart.record_data);
                    reportState.styles = conversionToCommonData(styles);
                }
                parseChartData(reportState.chart, !!reportId, reportState);
            }
        }

        // обнуляем флаги IS_LOAD_DATA и IS_UPDATE_CHART
        reportState.stateFlags = reportState.stateFlags & ~(IS_LOAD_DATA | IS_UPDATE_CHART);
        reportState.updateCurrentStyles();
        this.isChartLoaded = false;
    };

    /**
     *
     * @returns {Promise<never>}
     */
    save = async () => {
        this.isChartLoaded = true;
        let { data, error } = reportState.getData();

        data = {
            record: data,
        };
        await saveReport(data, error, this.getChart, reportState).finally(() => {
            this.isChartLoaded = false;
            getUiActions(reportState, reportState.report_id);
            reportState.sFormSysId = reportState.report_id;
            window.s_form = new SimpleForm(reportState.typeTable && reportState.typeTable.name, reportState.report_id, undefined, undefined, reportState.getParentFormId());
        });
    };

    /**
     *
     * @returns {*}
     */
    settingsSections() {
        const index = reportState.step;
        const { report_type } = langStore.getTranslate();
        const forReference = reportState.table ? {
            current: { table_id: reportState.table.database_value },
        } : null;


        let elements = [];
        let hiddenElements = [];
        if (reportState.table && reportState.record && reportState.record.sections && reportState.record.data) {
            const typeId = getParamField(reportState.record.data, 'type_id');
            const condition = getParamField(reportState.record.data, 'condition');
            elements = [
                typeId,
                condition,
            ];
            // TODO надо будет отрефакторить по нормальному
            hiddenElements = _.map(elements, element => {
                const newElement = _.cloneDeep(element);
                if (newElement.sys_column_name === 'type_id') {
                    newElement.value = {
                        database_value: reportState.type?.type_id,
                        display_value: reportState.type?.type_name,
                    };
                }
                return { ...newElement, notShow: true, hidden: true, read_only: true };
            });
        }

        return <>
            <SmartTitle hidden isWithoutPageTitle>{ getReportTitle(reportState) }</SmartTitle>
            <FormMessages />
            <div className={ styles.hide }>
                {
                    reportState.table && reportState.record && reportState.record.data && <FormSection
                        key="hideData"
                        tableName={ reportState.typeTable && reportState.typeTable.name }
                        sysId={ reportState.sFormSysId }
                        index={ reportState.record.data.index }
                        name={ reportState.record.data.name }
                        service_name={ reportState.record.config.service_name }
                        fields={ elements }
                        conditionState={ reportState.conditionState }
                        forReference={ forReference }
                        parentFormId={ reportState.getParentFormId() }
                    />
                }
            </div>

            { renderFirstSection(report_type, index, reportState) }

            { renderTypeSection(index, reportState) }

            <div className={ `${ index !== 2 ? styles.hide : '' } ${ styles.SettingBlockContent }` }>
                <div className={ styles.SettingConfig }>
                    {/*config*/ }
                    {
                        reportState.table && reportState.record && reportState.record.config && <FormSection
                            className={ formStyles }
                            clientScripts={ reportState.record.client_scripts }
                            key="configForm"
                            tableName={ reportState.typeTable && reportState.typeTable.name }
                            sysId={ reportState.sFormSysId }
                            index={ reportState.record.config.index }
                            name={ reportState.record.config.name }
                            fields={ [ ...reportState.record.config.elements, ...hiddenElements ] }
                            forReference={ forReference }
                            ref={ reportState.configForm }
                            service_name={ reportState.record.config.service_name }
                            onChangeField={ reportState.onChangeField }
                            parentFormId={ reportState.getParentFormId() }
                        />
                    }
                </div>
            </div>

            { renderStyleSection(index, this.styleTab, reportState) }
        </>;
    }

    render() {
        const table = reportState.table && sysDBTables.getById(reportState.table.database_value);

        const field = reportState.configForm.current && reportState.configForm.current.model.uiGetValue('group_by');
        const { report_type, report } = langStore.getTranslate();
        const steps = getSteps();
        if (reportState.indexReportError && (reportState.indexReportError === REPORT_ERROR_KEY.hiddenReport || reportState.indexReportError === REPORT_ERROR_KEY.hiddenTable)) {
            const tableName = reportState.table && reportState.table.display_value;
            return <ErrorPage codeError={ CODE_403_FORBIDDEN } pageTitle={ report && report.current_report_hidden } headerTitle={ tableName || (report && report.unavailable_data) } burgerMenuItems={ reportState.menuItems } />;
        }

        return (
            <div className={ styles.Wrapper }>

                <div key="settings" className={ styles.Setting }>
                    { renderSectionsTabs(steps, reportState) }

                    <div key="settings-tab" className={ styles.SettingBlock }>
                        { this.settingsSections() }
                    </div>

                    { renderFooterButtons(report_type, this.getChart, reportState) }
                </div>

                <div key="view" className={ styles.View }>
                    <ReportView
                        tableName={ table && table.name }
                        field={ field }
                        chart={ reportState.chart }
                        type={ reportState.type }
                        name={ reportState.name }
                        save={ this.save }
                        formData={ this.formData || {} }
                        getChart={ this.getChart }
                        location={ this.props.location }
                        isUpdateChart={ this.isChartLoaded }
                    />
                </div>
                <SubFormModal />
            </div>
        );
    }
}


export default ErrorWrapperHoc(observer(Report));
