import EventBusState from 'globalState/eventBus';

export default class SimpleEventBus {
    emit = (eventType, data) => {
        EventBusState.emit(eventType, data);
    };

    on = (eventType, callback) => {
        return EventBusState.on(eventType, callback);
    };

    deleteEvent = (eventType) => {
        EventBusState.deleteEvent(eventType);
    };

    reset = () => {
        EventBusState.reset();
    };
}
