import * as React from 'react';
import { observer } from 'mobx-react';

import styles from 'components/portalDesigner/styles.module.scss';
import PageData from 'components/portalDesigner/state/pageData';

/**
 * компонент для пустой страницы
 */
class PortalPageDesignerEmptyPage extends React.Component {

    addContainer = () => {
        const container = PageData.containers.insert({
            isLoaded: true,
        });
        PageData.fetchSave(container, PageData.currentPageSysId).catch(console.error);
    };

    render() {
        return <div className={ styles.View }>
            <div>Для создания структуры страницы добавьте контейнер</div>
            <button onClick={ this.addContainer }>Добавить</button>
        </div>;
    }
}

export default observer(PortalPageDesignerEmptyPage);
