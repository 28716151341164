import { observable, action, reaction, makeObservable } from 'mobx';
import * as _ from 'lodash';
import {InfoMessage, Message} from "types/globalStore/baseMessages";

export default class Messages {
    messages: Message[] = [];
    counter = 0;

    constructor() {
        makeObservable(this, {
            messages: observable,
            pushError: action,
            pushInfo: action,
            pushWarn: action,
            pushSuccess: action,
            push: action,
            remove: action,
            removeAll: action,
            removeFirst: action,
        });

        reaction(
            () => JSON.stringify(this.messages),
            () => {
                this.counter++;
            },
        );
    }

    pushError(message: string, duration = 0): number {
        return this.push({
            message,
            type: 'error',
            duration,
        });
    }

    pushInfo(message: string, duration = 0): number {
        return this.push({
            message,
            type: 'info',
            duration,
        });
    }

    pushWarn(message: string, duration = 0): number {
        return this.push({
            message,
            type: 'warning',
            duration,
        });
    }

    pushSuccess(message: string, duration = 0): number {
        return this.push({
            message,
            type: 'success',
            duration,
        });
    }

    push = ({message, type, duration}: InfoMessage): number => {
        const id = this.counter;

        if (type === 'error' && _.isNil(duration)) {
            duration = 0;
        }
        const isJsonString = (str: string) => {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        };
        const jsonObj = isJsonString(message) ? JSON.parse(message) : '';
        let jsonMessage = '';
        if (_.isArray(jsonObj)) {
            jsonMessage = jsonObj.join(' ');
        } else if (typeof jsonObj === 'number') {
            jsonMessage = message;
        } else if (typeof jsonObj === 'string') {
            jsonMessage = jsonObj;
        } else {
            jsonMessage = JSON.stringify(jsonObj);
        }

        const resultMessage = jsonMessage ? jsonMessage : message;

        this.messages.push({
            id,
            type,
            visible: true,
            duration,
            message: resultMessage,
        });

        return id;
    };

    remove = (id: number) => {
        this.messages = this.messages.filter(message => message.id !== id);
    };

    removeAll = () => {
        this.messages = [];
    };

    removeFirst = () => {
        this.messages.shift();
    };
}
