import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import FieldWrapper from 'components/dynamicForms/view/fieldWrapper';
import CustomSelect from 'components/customselect';
import { isEqual } from 'helpers/data';
import { SelectProps } from 'types/components/conditionFilters/select';
import { SelectOption } from 'types/actions/preferences';

/**
 * Описание: компонент Select
 * Параметры:
 * value - значение
 * getRef - передача ref элемента select родительскому компоненту
 * className - стиль родительского блока для select
 * special.values - варианты выбора
 * readOnly - режим чтения
 * onChange - обработчик изменения значения select
 */

class Select extends React.Component<SelectProps> {
    options: SelectOption[] = [];
    isReadOnly = false;
    isVisible = false;
    value;
    node;

    constructor(props) {
        super(props);

        makeObservable(this, {
            options: observable,
            isReadOnly: observable,
            isVisible: observable,
            value: observable,
        });

        if (props.multiple) {
            this.value = [];
        }

        this.isReadOnly = typeof props.readOnly !== 'undefined' ? props.readOnly : false;
        this.isVisible = typeof props.isVisible !== 'undefined' ? props.isVisible : true;
        this.options = props.special.values || [];
    }

    componentDidMount() {
        if (this.props.value) {
            this.value = this.props.value;
        }
    }

    componentDidUpdate(prevProps) {
        const { special, value, readOnly } = this.props;
        if (special && special.values && !isEqual(special.values, prevProps.special.values)) {
            this.options = special.values;
        }
        if (!isEqual(value, prevProps.value)) {
            this.value = value;
        }
        if (readOnly !== prevProps.readOnly) {
            this.isReadOnly = readOnly;
        }
    }

    onChange = (value) => {
        const { onChange } = this.props;
        this.value = value;
        if (onChange) {
            onChange({ value: this.value });
        }
    };

    getRef = (node) => {
        this.node = node;
        if (this.props.getRef) {
            this.props.getRef(node);
        }
    };

    render() {
        if (!this.isVisible) {
            return null;
        }

        let value;

        if (this.value) {
            if (typeof this.value.database_value !== 'undefined') {
                value = this.value.database_value;
            } else if(typeof this.value.sys_id !== 'undefined') {
                value = this.value.sys_id;
            } else {
                value = this.value;
            }
        } else {
            value = '';
        }

        return (
            <FieldWrapper { ...this.props }
                          isVisible={ this.isVisible }
                          value={ this.value }
                          column_type="choice">
                <CustomSelect
                    disabled={ this.isReadOnly || !this.options }
                    ref={ this.getRef }
                    className={ this.props.className }
                    databaseValue={ value }
                    options={ this.options }
                    onChange={ this.onChange }
                    data-test={ this.props['data-test'] }
                />
            </FieldWrapper>
        );
    }
}

export default observer(Select);
