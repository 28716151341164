import * as React from 'react';
import { observer } from 'mobx-react';
import StringInput from 'components/dynamicForms/view/field/stringInput';
import TextArea from 'components/dynamicForms/view/field/textArea';
import Select from 'components/dynamicForms/view/field/select';
import Checkbox from 'components/dynamicForms/view/field/checkbox';
import DateTimeInput from 'components/dynamicForms/view/field/dateTimeInput';
import DaysOfWeek from 'components/dynamicForms/view/field/daysOfWeek';
import URL from 'components/dynamicForms/view/field/url';
import Reference from 'components/dynamicForms/view/field/reference';
import DocumentId from 'components/dynamicForms/view/field/documentId';
import List from 'components/dynamicForms/view/field/list';
import HtmlInput from 'components/dynamicForms/view/field/htmlInput';
import CodeMirror from 'components/dynamicForms/view/field/codeMirror';
import Duration from 'components/dynamicForms/view/field/duration';
import FieldName from 'components/dynamicForms/view/field/fieldName';
import Conditions from 'components/dynamicForms/view/field/conditions';
import Image from 'components/dynamicForms/view/field/image';
import Template from 'components/dynamicForms/view/field/template';
import Color from 'components/dynamicForms/view/field/color';
import RichText from 'components/dynamicForms/view/field/richText';
import UnavailableField from 'components/dynamicForms/view/form/unavailableField';
import UnknownField from 'components/dynamicForms/view/form/unknownField';
import LoaderField from 'components/dynamicForms/view/form/LoaderField';
const Widget = React.lazy(() => import('components/widget'));
import WidgetModel from 'components/dynamicForms/model/field/WidgetModel';
import BaseFormFieldModel from 'components/dynamicForms/model/field/BaseFormFieldModel';
import { FieldFactoryProps } from 'types/components/dynamicForms/view/form/fieldFactory';

export const getComponent = (type: string, props) => {
    switch (type) {
        case 'unavailable':
            return <UnavailableField { ...props } />;
        case 'string':
        case 'varchar':
        case 'password':
        case 'integer':
        case 'smallinteger':
        case 'biginteger':
        case 'float':
        case 'decimal':
        case 'percent_complete':
        case 'char':
        case 'json':
        case 'phone':
        case 'enum_form_split_element_type':
        case 'encrypted_password':
            return <StringInput { ...props } />;
        case 'text':
        case 'translated_text':
        case 'journal_input':
            return <TextArea { ...props } />;
        case 'date':
        case 'datetime':
        case 'datetime_specific':
        case 'time':
        case 'timestamp':
            return <DateTimeInput { ...props } />;
        case 'reference':
        case 'record_class':
            return <Reference { ...props } />;
        case 'image':
            return <Image { ...props } />;
        case 'choice':
            return <Select { ...props } />;
        case 'boolean':
            return <Checkbox { ...props } />;
        case 'days_of_week':
            return <DaysOfWeek { ...props } />;
        case 'url':
            return <URL { ...props } />;
        case 'list':
            return <List { ...props } />;
        case 'script':
            return <CodeMirror { ...props } />;
        case 'id':
            return <DocumentId { ...props } />;
        case 'html':
            return <HtmlInput { ...props } />;
        case 'color':
            return <Color { ...props } />;
        case 'rich_text':
            return <RichText { ...props } />;
        case 'widget':
            return <Widget { ...props } />;
        case 'template':
            return <Template { ...props } />;
        case 'field_name':
            return <FieldName { ...props } />;
        case 'conditions':
            return <Conditions { ...props } />;
        case 'duration':
            return <Duration { ...props } />;
        default:
            return <UnknownField { ...props } />;
    }
};

class FieldFactory extends React.Component<FieldFactoryProps> {
    constructor(props) {
        super(props);
    }

    getTypeField = () => {
        const { columnType, cellEditMode } = this.props.model as BaseFormFieldModel;
        if (columnType === 'conditions' && cellEditMode) {
            return 'string';
        }
        if (this.isWidget()) {
            return 'widget';
        }
        return columnType;
    };

    getComponent() {
        if (this.getTypeField() === undefined) {
            return <LoaderField { ...this.getAttr() } />;
        }

        if ((this.props.model as BaseFormFieldModel).notShow) {
            return null;
        }

        if ((this.props.model as BaseFormFieldModel).hidden) {
            return <UnavailableField { ...this.getAttr() } />;
        }
        return getComponent(this.getTypeField(), { ...this.getAttr() });
    }

    getAttr() {
        let attr = { ...this.props };
        if (this.isWidget()) {
            const { model } = this.props;
            attr = (model as WidgetModel).data;
            (attr as any).tableName = (model as WidgetModel).parentFormSectionModel.getTableName();
            (attr as any).recordId = (model as WidgetModel).parentFormSectionModel.getSysId();
            (attr as any).parentFormSectionModel = (model as WidgetModel).parentFormSectionModel;
            (attr as any).isModal = (attr as any).parentFormSectionModel?.parentFormModel?.isSubForm;
        }
        (attr as any).className = `${ (attr as any).className || '' }`;
        return attr;
    }

    isWidget() {
        return this.props.model instanceof WidgetModel;
    }

    render() {
        const component = this.getComponent();
        return component ?
            <React.Suspense fallback={ <LoaderField /> }>
                { component }
            </React.Suspense> : null;
    }
}

export default observer(FieldFactory);
