import * as React from 'react';
import apiRequest from 'lib/apiRequest';
import {helperRedirect} from 'helpers/history';
import { getUrlParams } from 'helpers/data';
import ErrorWrapperHoc from 'helpers/hoc/errorWrapperHOC';


class UrlActionRunPage extends React.Component {
    constructor(props) {
        super(props);
        this.key = getUrlParams(this.props.location.search).key;
    }

    componentDidMount() {
        if (this.key) {
            this.runUrlAction().catch(console.error);
        }
    }

    runUrlAction = async () => {
        const response = await new apiRequest(`GET /url-action/run`).qs({
            key: this.key,
        }).send();
        const data = response.getData();
        helperRedirect(data.url);
    };

    render() {
        return null;
    }
}


export default ErrorWrapperHoc(UrlActionRunPage);
