import * as React from 'react';
import styles from "./styles.module.scss";
import ChartLoaderLogo from 'assets/img/chart-loader.svg';

/**
 * Заглушка, которая выводится в случае отсуствия чарта
 * @param textLoading - текст в теле
 * @param textHeader - текст в заголовке
 */
export default class ChartLoader extends React.Component {
    constructor(props) {
        super(props);
    }

    renderLoading(textLoading, textHeader) {
        if (textLoading && textHeader) {
            return (
                <div className={styles.loadingText}>
                    {textLoading}
                </div>);
        }

        return (
            <div className={styles.defaultLoadingText}>
                {textLoading}
            </div>);
    }

    render() {
        const {textHeader, textLoading, isMasterReport} = this.props;
        const styleLoader = [styles.loader, styles.commonLoader];
        if (isMasterReport) {
            styleLoader.push(styles.masterReport);
        }


        if (!textLoading && !textHeader) {
            return (
                <div className={`${styleLoader.join(' ')}`}>
                    <div className={ styles.preloader } />
                </div>);
        }

        return (
            <div className={`${styleLoader.join(' ')}`}>
                <div className={styles.logo} dangerouslySetInnerHTML={{__html: ChartLoaderLogo}}/>
                {textHeader && <div className={styles.headerText}>{textHeader}</div>}
                { this.renderLoading(textLoading, textHeader) }
            </div>
        );
    }
}
